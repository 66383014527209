import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-backups-massive-transaction',
  templateUrl: './backups-massive-transaction.component.html',
  styleUrls: ['./backups-massive-transaction.component.scss']
})
export class BackupsMassiveTransactionComponent implements OnInit {

  @Input() process = 'Transacción masiva';
  @Input() data: any;

  public dataErrorRegisters: any = [
    {
      DIRECCION: 'AV. AMERICAS No. 36 - 37',
      DOCUMENTO: 9003517362,
      TELEFONO_2: 3504526949,
      CREATED_AT: '2020-10-29T15:52:31.814-05:00',
      OBLIGACION: 287028,
      TELEFONO_1: 3504501436,
      DOCUMENTO_CLIENTE: 8300533408,
      CONTRATO: 850498,
      NOMBRE_DEUDOR: 'DREAM REST COLOMBIA S.A.S.',
      EMAIL: 'leonardo.reyes@colchonesparaiso.com',
      MENSAJE: { CIUDAD_DIRECCION: 'el campo ciudad_dirección es requerido' },
      CIUDAD_DIRECCION: 0,
      VALOR_DEUDA: 3005279,
      GRUPO_CARGA: 1604004751814
    },
    {
      DIRECCION: 'AV. AMERICAS No. 36 - 37',
      DOCUMENTO: 9003517362,
      TELEFONO_2: 3504526949,
      CREATED_AT: '2020-10-29T15:52:31.814-05:00',
      OBLIGACION: 287028,
      TELEFONO_1: 3504501436,
      DOCUMENTO_CLIENTE: 8300533408,
      CONTRATO: 850498,
      NOMBRE_DEUDOR: 'DREAM REST COLOMBIA S.A.S.',
      EMAIL: 'leonardo.reyes@colchonesparaiso.com',
      MENSAJE: { CIUDAD_DIRECCION: 'el campo ciudad_dirección es requerido' },
      CIUDAD_DIRECCION: 0,
      VALOR_DEUDA: 3005279,
      GRUPO_CARGA: 1604004751814
    }
  ];
  public dataCorrectRegisters: any = [
    {
      DIRECCION: 'AV. AMERICAS No. 36 - 37',
      DOCUMENTO: 9003517362,
      TELEFONO_2: 3504526949,
      CREATED_AT: '2020-10-29T15:52:31.814-05:00',
      OBLIGACION: 287028,
      TELEFONO_1: 3504501436,
      DOCUMENTO_CLIENTE: 8300533408,
      CONTRATO: 850498,
      NOMBRE_DEUDOR: 'DREAM REST COLOMBIA S.A.S.',
      EMAIL: 'leonardo.reyes@colchonesparaiso.com',
      MENSAJE: { CIUDAD_DIRECCION: 'el campo ciudad_dirección es requerido' },
      CIUDAD_DIRECCION: 0,
      VALOR_DEUDA: 3005279,
      GRUPO_CARGA: 1604004751814
    },
    {
      DIRECCION: 'AV. AMERICAS No. 36 - 37',
      DOCUMENTO: 9003517362,
      TELEFONO_2: 3504526949,
      CREATED_AT: '2020-10-29T15:52:31.814-05:00',
      OBLIGACION: 287028,
      TELEFONO_1: 3504501436,
      DOCUMENTO_CLIENTE: 8300533408,
      CONTRATO: 850498,
      NOMBRE_DEUDOR: 'DREAM REST COLOMBIA S.A.S.',
      EMAIL: 'leonardo.reyes@colchonesparaiso.com',
      MENSAJE: { CIUDAD_DIRECCION: 'el campo ciudad_dirección es requerido' },
      CIUDAD_DIRECCION: 0,
      VALOR_DEUDA: 3005279,
      GRUPO_CARGA: 1604004751814
    }
  ];

  public errorTitles: any = ['DOCUMENTO', 'DIRECCION', 'OBLIGACION', 'EMAIL', 'VALOR_DEUDA', 'CONTRATO', 'NEGADA'];
  public correctTitles: any = ['DOCUMENTO', 'DIRECCION', 'OBLIGACION', 'EMAIL', 'VALOR_DEUDA', 'CONTRATO'];

  public togglePopOver = false;
  public messageTooltip: string;

  // Effects
  public hoverEffect = false;
  public hoverDiv: number;

  public activeContent = true;

  constructor(public spinner: NgxSpinnerService,
    public modal: ModalService,
    private toastr: ToastrService,
    public subject: SubjectService) { }

  ngOnInit() {
  }

  downloadExcel() {

  }

  validateRegisters(event?: any, row?: any, id?: any, type?: string) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.toastr.warning('mensaje personalizado', '', {
        timeOut: 3000,
      });
    }, 300);
  }

  mouseEnter(index: any) {
    this.hoverEffect = true; this.hoverDiv = index;
  }

  mouseLeave(index: any) {
    this.hoverEffect = false; this.hoverDiv = index;
  }

  deleteRow(id: string) {
    this.dataErrorRegisters.splice(id, 1);
  }

  deleteRows() {
    this.dataCorrectRegisters = [];
  }

  save() {

  }

  charge() {
    this.activeContent = false;
  }

  enterTd(item: any, title: string, event: any) {
    if (item.MENSAJE[title] !== undefined) {
      this.messageTooltip = item.MENSAJE[title];
      this.subject.sendText(this.messageTooltip);
    } else {
      this.messageTooltip = '';
      this.subject.sendText(this.messageTooltip);
    }
  }

  leaveTd() {
    this.messageTooltip = '';
    this.subject.sendText(this.messageTooltip);
  }
}
