import { Component, OnInit } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClaimsFile, DataSearchMultipleFiles } from '../backups/models/request/claims';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fau-digital',
  templateUrl: './fau-digital.component.html',
  styleUrls: ['./fau-digital.component.scss']
})
export class FauDigitalComponent implements OnInit {
  docTypes: any;
  dataSearchMultipleFiles: DataSearchMultipleFiles = new DataSearchMultipleFiles() ;
  totalPages: number = 0;
  numberPageFile = 1;
  dataFile: any = [];
  tableHeaders = ClaimsFile;
  pdfSrc: string;

  constructor(
    private listCovinoc: ListsCovinocService, 
    public helper: HelperService,
    private backups: BackupsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getDoccuments();

  }

  getDoccuments() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesNew;
    });
  }

  searchDocuments() {
    this.dataSearchMultipleFiles.currentPage = this.numberPageFile;
    this.dataSearchMultipleFiles.category = 'FAU DIGITAL';
    this.searchFiles();
  }

  searchFiles() {
    this.spinner.show();
    
    this.backups.postSearchFiles(this.dataSearchMultipleFiles).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.dataFile = data.data;
        this.totalPages = data.totalPages;
        
        if(data.data.length === 0 || (data.data === null)) {
          Swal.fire({
            title: ``,
            text: `no se encuentra información`,
            type: 'info',
          });
          this.dataFile = [];
        }
        //console.log(data,'files');
     
      }, (error: any) => {
        this.dataFile = [];
        this.spinner.hide();
        //console.log(error)
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      }
    );
  }

  visualize(item: any) {
    this.pdfSrc = item.infoFile;
   /**  let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(item.infoFile) + "'></iframe>")
  */
    
  }

  goToPage(event) {
    this.dataSearchMultipleFiles.currentPage = event.currentPage;
    this.searchFiles();
  }

  closeModal(event: boolean) {
    this.pdfSrc = undefined;
  }

}
