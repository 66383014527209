import { Injectable } from '@angular/core';
import { LegalNatural } from '@app/models/legalNatural.model';
import { BehaviorSubject } from 'rxjs';
import { FacturasNegociadas } from '../models/NegotiatedInvoices';

@Injectable({
  providedIn: "root",
})
export class ParamsAffiliateStoreService {
  private stateLegalNatural: BehaviorSubject<LegalNatural[]> =
    new BehaviorSubject<LegalNatural[]>([]);

  public stateLegalNatural$ = this.stateLegalNatural;

  private stateInvoices: BehaviorSubject<FacturasNegociadas[]> =
    new BehaviorSubject<FacturasNegociadas[]>([]);

  public stateInvoices$ = this.stateInvoices;

  constructor() {}

  public changeLegalNatural(listLegaNatural: LegalNatural[]) {
    this.stateLegalNatural.next(listLegaNatural);
  }

  public changeInvoices(listInvoices:  FacturasNegociadas[]) {
    this.stateInvoices.next(listInvoices);
  }

  public alterListInvoices(invoicesResponse: FacturasNegociadas){
    //let invoicesIndex = this.stateInvoices.value.findIndex((element) => invoicesResponse.id == element.id );
    let invoices = this.stateInvoices.value.map((element) => {
      if(invoicesResponse.id == element.id){
        return element = invoicesResponse
      }
      return element
    });
    this.stateInvoices.next(invoices)
  }
}
