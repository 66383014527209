import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectModel } from '@app/models/select.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-filter-select",
  templateUrl: "./filter-select.component.html",
  styleUrls: ["./filter-select.component.scss"],
})
export class FilterSelectComponent {
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Input() defaultText: string = "";
  @Input() selectOptions: SelectModel[] = [new SelectModel()];
  @Input() selectedOption: string = "";
  @Input() label: string = "";
  @Input() disabled: boolean = false;

  public testSelection: string = "empty";
  public arrTest: string[] = ["test1", "test2", "test3"];

  constructor(public spinner: NgxSpinnerService) {}

  handleSelect(selectedOption: string) {
    this.onSelect.emit(selectedOption);
  }
}