import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-legal-person-alerts-table',
  templateUrl: './legal-person-alerts-table.component.html',
  styleUrls: ['./legal-person-alerts-table.component.scss']
})
export class LegalPersonAlertsTableComponent implements OnInit {

  @Input() dataTable: any;

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
  }

}
