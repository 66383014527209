import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditHistoryService } from '../../services/credit-history.service';
import { IQueryForm } from '../../components/query-form-history/query-form-history.component';
import { GetCreditHistoryRequest, GetCreditHistoryResponse } from '../../models/credit-history.model';
import { HelperService } from '@app/services/helper.service';
import Swal from 'sweetalert2';
import { IResponseCodeExperian } from '../../constants/global-credit-info';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-recognize',
  templateUrl: './recognize.component.html',
  styleUrls: ['./recognize.component.scss']
})
export class RecognizeComponent implements OnInit {

  public showResults: boolean = false;

  public recognizeData: any;

    // Scroll
    @ViewChild("recognizeBasicInfoTable") recognizeBasicInfoTable: ElementRef<HTMLElement>;
    @ViewChild("recognizeAddressesTable") recognizeAddressesTable: ElementRef<HTMLElement>;
    @ViewChild("recognizeDirectionsVectorTable") recognizeDirectionsVectorTable: ElementRef<HTMLElement>;
    @ViewChild("recognizePhonesTable") recognizePhonesTable: ElementRef<HTMLElement>;
    @ViewChild("recognizeEmailsTable") recognizeEmailsTable: ElementRef<HTMLElement>;

    public scrollButtons: Array<{
      description: string;
      target: HTMLElement | string;
    }> = [];

    // offset page
    public isShowAnFixedBar: boolean;
    topPosToStartShowing = 100;
    @HostListener("window:scroll")
    checkScroll() {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      // console.log('scrollPosition', scrollPosition);
      if (window && window.pageYOffset >= 110) {
        this.isShowAnFixedBar = true;
      } else {
        this.isShowAnFixedBar = false;
      }
    }
    // ./Scroll

  constructor(
    private spinner: NgxSpinnerService,
    private creditHistoryService: CreditHistoryService,
    public helper: HelperService,
    private helperCreditInfo: HelperCreditInfoService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.handleInitializeScrollButtons();
  }

  handleInitializeScrollButtons() {
    this.scrollButtons = [
      {
        description: "Información básica",
        target: this.recognizeBasicInfoTable.nativeElement,
      },
      {
        description: "Direcciones",
        target: this.recognizeAddressesTable.nativeElement,
      },
      {
        description: "Vectores de direcciones",
        target: this.recognizeDirectionsVectorTable.nativeElement,
      },
      {
        description: "Teléfonos",
        target: this.recognizePhonesTable.nativeElement,
      },
      {
        description: "Correos electrónicos",
        target: this.recognizeEmailsTable.nativeElement,
      },
    ];
  }

  handleSubmitQueryRecognizeForm(queryForm: any) {
    const { documentType, document, lastname } = queryForm;
    const request: GetCreditHistoryRequest = {
      identificationType: documentType,
      identification: document,
      firstSurname: lastname,
      force: false,
      service: 'CONSULTAR_DATOS_LOCALIZACION'
    };
    this.getRecognizeData(request);
  }

  getRecognizeData(request) {
    this.spinner.show();
    this.creditHistoryService.getRecognizeData(request).subscribe((response: any) => {
      this.spinner.hide();
      // this.showResults = true;
      this.handleValidateError(response);
      console.log(response)
    }, (error: any) => {
      this.handleServiceError(error);
    });
  }

  handleValidateError(response: any) {
    // const { basicInfo, addressesInfo, phonesInfo, emailInfo, directionsVector } = response;
    // const data = [basicInfo, addressesInfo, phonesInfo, emailInfo, directionsVector];
    const { codigoRespuesta = '' } = response;
    const responseCode: IResponseCodeExperian = this.helperCreditInfo.findResponseCodeFromReconcer(codigoRespuesta);

    // console.log('test', basicInfo, addressesInfo, phonesInfo, emailInfo, directionsVector )
    // console.log('data ---> ', response);
    // console.log('data ok ---> ', data.some((item) => item));
    if (responseCode.isValid) {
        console.log('ok');
        this.showResults = true;
        this.recognizeData = response;
        this.spinner.hide();
      } else {
        console.log('error al consultar');
        this.showResults = false;
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          html: `No hay resultados para la consulta realizada <br/><br/> Error Reconocer_${responseCode.code}: ${responseCode.description}`,
        });
        this.spinner.hide();
      }

  }

  handleServiceError(error: any) {
    console.log('Error al procesar servicio de Reconocer', error);
    this.spinner.hide();
    this.showResults = false;
    this.recognizeData = {};

    Swal.fire({
      type: 'error',
      title: 'Alerta:',
      html: `Este servicio no se encuentra disponible en este momento.`,
    });
  }
}
