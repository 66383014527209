import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BackupsTableHeadersService } from "@app/pages/backups/services/backups-table-headers.service";
import { GloballBillingService } from "@app/services/globall-billing.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import * as moment from "moment";
import Swal from "sweetalert2";

@Component({
  selector: "app-factura-individual",
  templateUrl: "./factura-individual.component.html",
  styleUrls: ["./factura-individual.component.scss"],
})
export class FacturaIndividualComponent implements OnInit {
  public form: FormGroup;

  public min = new Date();

  constructor(
    private fb: FormBuilder,
    private modal: ModalService,
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.createForm();
    this.setForm();
    this.getConsecutive();
  }

  createForm() {
    this.form = this.fb.group({
      facturaIndividual: [{ value: null, disabled: true }, Validators.required],
      fechaCuota: [{ value: null, disabled: true }, Validators.required],
      fechaFactura: [null, Validators.required],
      fechaLimite: [null, Validators.required],
    });
  }

  setForm() {
    this.f.fechaCuota.setValue(this.modal.payload["fecha_factura"]);
  }

  getConsecutive() {
    this.globalBillingService
      .consecutive(this.modal.payload["contrato"])
      .subscribe((resp) => {
        this.f.facturaIndividual.setValue(resp.data[0]);
      });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.globalBillingService.individualBill(this.mapper()).subscribe(
      () => {
        Swal.fire({
          title: "Facturación global",
          text: "Facturación enviada con éxito",
          type: "success",
          confirmButtonText: "OK",
        });
        this.backupsHeaders.setLoad(true);
        this.modal.close();
      },
      () => {
        Swal.fire({
          title: "Ocurrió un error intentelo mas tarde",
          type: "error",
          confirmButtonText: "OK",
        });
      }
    );
  }

  mapper() {
    const { facturaIndividual, fechaCuota, fechaFactura, fechaLimite } =
      this.form.getRawValue();

    const { user } = JSON.parse(localStorage.getItem("user"));
    console.log(typeof fechaCuota);
    return {
      id_bolsa: this.modal.payload["id_bolsa"],
      factura_individual: facturaIndividual,
      fecha_cuota: moment(fechaCuota).format("YYYY-MM-DD HH:mm:ss"),
      fecha_facturacion: moment(fechaFactura).format("YYYY-MM-DD HH:mm:ss"),
      fecha_limite: moment(fechaLimite).format("YYYY-MM-DD HH:mm:ss"),
      usuario: user,
    };
  }

  closeModal() {
    this.modal.close();
  }
}
