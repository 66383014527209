
export class PaymentsPostBackingRequestModel {
    affiliateDocumentType:     string;
    affiliateDocument:         string;
    issuerDocumentType:        string;
    issuerDocument:            string;
    draftNumber:               string;
    filingNumber:              number;
    contract:                  number;
    currentPage:           number = 1;
    perPage:              number = 10;
}
