import { Component, Output, Input, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: [DecimalPipe]
})
export class PaginationComponent implements OnChanges, OnInit {
  public pages = [];
  public selectedPage = 0;
  public show: boolean = true;
  public invalidCustomInput: boolean = false;

  @Output() idSelectedPage: EventEmitter<object> = new EventEmitter();
  @Output() onOpenSelect: EventEmitter<void> = new EventEmitter();
  @Input() cantPages: number;
  @Input() currentPages: number;
  @Input() typeSelect = false;

  constructor(public helper: HelperService, public decimalPipe: DecimalPipe) {
    this.togglePagination();
  }
  ngOnInit(): void {
    this.selectedPage = this.currentPages ? this.currentPages - 1 : 0;  
  }

  reset() {
    this.selectedPage = 0;
  }

  ngOnChanges() {
    this.createPages();
    this.togglePagination();
    this.showInputNumber();
  }

  togglePagination() {
    if (!this.cantPages) {
      this.show = false;
    } else {
      this.show = true;
      this.createPages();
    }
  }

  selectPage(idx: number) {
    if (this.invalidCustomInput === true) return;
    if (this.isBiggerThanMax() && this.selectedPage > 0) {
      this.selectedPage + 1;
      this.idSelectedPage.emit({
        currentPage: Number(this.selectedPage),
      });
    } else {
      this.selectedPage = idx;
      this.emitPageIncrementedOne();
    }
  }

  onClickSelectPage() {
    this.onOpenSelect.emit();
  }

  nextPage() {
    if (this.selectedPage >= this.pages.length - 1) {
      return;
    }
    this.selectedPage++;
    this.emitPageIncrementedOne();
  }

  previousPage() {
    if (!this.selectedPage) {
      return;
    }
    this.selectedPage--;
    this.emitPageIncrementedOne();
  }

  limitChange() {
    this.emitPageIncrementedOne();
  }

  showInputNumber() {
    if (this.isBiggerThanMax()) {
      this.selectedPage = 1;
      return true;
    }
    else {
      //this.reset();
      return false;
    }
  }

  isBiggerThanMax(): boolean {
    return (this.pages && this.pages.length > 100) ? true : false;
  }

  validateCustomInput(value: number) {
    if (value > this.pages.length) {
      this.invalidCustomInput = true;
      this.helper.showToastMessage(
        'warning',
        `La página no puede se mayor a ${this.decimalPipe.transform(this.pages.length)}`,
        ''
      );
      return;
    }
    this.invalidCustomInput = false;
  }

  createPages() {
    this.pages = this.helper.createArray(this.cantPages || 4);
  }

  emitPageIncrementedOne() {
    this.idSelectedPage.emit({
      currentPage: Number(this.selectedPage) + 1,
    });
  }
}
