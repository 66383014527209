import { Component, Input, OnChanges, OnInit } from '@angular/core';
// import { HolderValidationResponse, QuotaDataForm } from 'src/app/models/quote-form-model';
import { QuotaHeadingTablesService } from '../../services/quota-heading-tables.service';

@Component({
  selector: 'app-holder-validation',
  templateUrl: './holder-validation.component.html',
  styleUrls: ['./holder-validation.component.scss']
})
export class HolderValidationComponent implements OnInit {
 
  @Input() quotaData: any;

  constructor(public tableHeadings : QuotaHeadingTablesService) { }

  ngOnInit() {
  }
}
