// Directives
import { } from '@angular/cdk/keycodes';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from 'ng2-charts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { PasswordComponent } from './auth/password/password.component';
import { CiCdConfigService } from './cicd/ci-cd-config.service';
import { ComponentsModule } from './components/components.module';
import { HelpComponent } from './components/help/help.component';
import { ChartStateComponent } from './components/layout/chart-state/chart-state.component';
import { InterceptorModule } from './interceptors/interceptor.module';
import { ModalModule } from './modals/modal.module';
import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';
import { BgUrlPipe } from './pipes/bg-url.pipe';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { RolByIdPipe } from './pipes/rol-by-id.pipe';
import { AuthService } from './services/auth.service';
import { SharedModule } from './shared/shared.module';
import { TableModule } from './tables/table/table.module';
@NgModule({
  declarations: [
    AppComponent,
    ChartStateComponent,
    HelpComponent,
    PagesComponent,
    AuthComponent,
    LoginComponent,
    PasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    TableModule,
    ModalModule,
    SharedModule,
    InterceptorModule,
    ChartsModule,
    ScrollingModule,
    Ng2SearchPipeModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    //ComponentsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 1,
      autoDismiss: true,
      closeButton: true
    })
  ],
  providers: [
    AuthService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: CiCdConfigService) => () => configService.loadConfigurations().toPromise(),
      deps: [CiCdConfigService],
      multi: true
    },

  ],
  exports: [
    BgUrlPipe,
    DocumentTypePipe,
    RolByIdPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
