import { Component, Input, OnInit } from '@angular/core';
import { ActiveSavingAndCheckingAccounts } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-active-saving-and-checking-accounts',
  templateUrl: './legal-active-saving-and-checking-accounts.component.html',
  styleUrls: ['./legal-active-saving-and-checking-accounts.component.scss']
})
export class LegalActiveSavingAndCheckingAccountsComponent implements OnInit {
  
  public headers: string[] = [
    "Estado cuenta",
    "Tipo de cuenta",
    "Entidad informante",
    "Fecha de apertura",
    "Fecha act.",
    "Número de cuenta",
    "Ciudad",
    "Oficina",
    "Cheques devueltos",
    "Cupo de sobregiro",
    "Días aut.de sobregiro",
    "Desacuerdo con la información",
    "Persona"
  ];

  @Input() activeSavingAndCheckingAccounts: ActiveSavingAndCheckingAccounts[] = [];
  @Input() isAPerson: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  isNumber(value: any): boolean {
    return !isNaN(Number(value));
  }

}
