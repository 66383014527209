import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { ModalService } from "@services/modal.service";

@Component({
  selector: "app-modal-template-response-contract",
  templateUrl: "./modal-template-response-contract.component.html",
  styleUrls: ["./modal-template-response-contract.component.scss"],
})
export class ModalTemplateResponseContractComponent
  implements OnInit, OnDestroy
{
  @Output() confirm: EventEmitter<object> = new EventEmitter();

  constructor(public modal: ModalService) {}

  ngOnDestroy(): void {
    this.modal.close();
  }

  ngOnInit() {}

  closeModal() {
    this.modal.close();
  }
}
