import { Component, Input, OnInit } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-main-customers',
  templateUrl: './main-customers.component.html',
  styleUrls: ['./main-customers.component.scss']
})
export class MainCustomersComponent implements OnInit {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {

    console.log('quotaData: debug 1', this.quotaData);
  }
}
