import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@app/models/responseUtil.model';
import { HelperService } from '@app/services/helper.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NonPaymentNotice } from '../models/response/non-payment-notice';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class NonPaymentNoticeService {

  public API_URL: string;
  public apiUrl: string
  public tokenDownload: string;

  constructor(private http: HttpClient, public helper: HelperService, private ciCd: CiCdConfigService) {
    this.API_URL = environment.apiUrl + 'quota-manager/nonpaymentNotices/findNonPayments';
    this.apiUrl = environment.dev07ApiUtl;
    this.tokenDownload = environment.tokenDownload;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, tokenDownload, dev07ApiUtl} = apis
      this.API_URL = apiUrl + 'quota-manager/nonpaymentNotices/findNonPayments';
      this.apiUrl = dev07ApiUtl;
      this.tokenDownload = tokenDownload;
       console.log('config ci cd', apiUrl);
    });
  }

  findAllNotices(body: string): Observable<Response<NonPaymentNotice>> {
    let requestUri = this.API_URL;
    return this.http.post<Response<NonPaymentNotice>>(`${requestUri}`, body);
  }

  searchNotifyEmail(params) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.tokenDownload),
    });
    const options = {
      headers,
      params
    }
    return this.http.get(`${this.apiUrl}/AffiliateLink/sendLink/searchNotifyEmail`, options)
  }

  addNotificationMail(body) {
    let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: "Basic " + btoa(this.tokenDownload),
  });
    const options = {
      headers,
    }
    return this.http.post(`${this.apiUrl}/AffiliateLink/sendLink/addNotificationMail`, body, options)
  }
}
