import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { RisksProcessesChargesService } from '@app/services/risks-processes-charges.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RisksProcessesChargesHeadersService } from '../risks-processes-charges-headers.service';

@Component({
  selector: 'app-mass-quota-load',
  templateUrl: './mass-quota-load.component.html',
  styleUrls: ['./mass-quota-load.component.scss']
})
export class MassQuotaLoadComponent implements OnInit {

  public navItemTabs: any[] = [];
  public searchNavItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  historicPerpage: number = 10;
  historicCurrentPage: number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  docTypes: any;
  public file: File;
  public stringFile: string;
  public isSearch: boolean = false;

  public isValidData: boolean = false;
  public isQuerySuccess: boolean = false;

  public errorLog: Array<any> = [];

  public bodyFormPromisoryNotes = {
    contract: '',
  }

  buyer: string = '';

  data: any = [];

  constructor(private tabs: TabsService, public helper: HelperService, private quotaGreater: QuotaGreaterService, private risksProcessesHeaders: RisksProcessesChargesHeadersService,
    private spinner: NgxSpinnerService, private modal: ModalService, private risksProcesses: RisksProcessesChargesService, private toastr: ToastrService) { }

  ngOnInit() {
    this.navItemTabs = this.risksProcessesHeaders.massQuotaLoadHeaders;
    console.log(this.navItemTabs);
    
  }

  search() {
    this.spinner.show();
    const params: any = {
      contract: this.bodyFormPromisoryNotes.contract,
    }
    
    this.risksProcesses.affiliateInfo(params).subscribe(
      (res: any) => {
        console.log(res);
        
        this.spinner.hide();
        this.toastr.success(res.message);
        this.isSearch = res.ok;
        this.totalPages = res.totalPages;
        this.notices = res.data;
        this.data = res.data[0];
        this.previewFile(this.currentPage);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message, error)
      } 
    );
  }

  downloadTemplate() {
    this.spinner.show();
    const params = {
      type: 'CARGUE_CUPOS',
    };
    this.risksProcesses.downloadTemplate(params).subscribe((response: any) => {
      this.spinner.hide();
      this.helper.downloadFromBase64(response.data[0], 'cargue_masivo_plantilla', '.xlsx');
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  seleccionArchivo(archivo: File) {
    this.file = archivo;
    this.toBase64(this.file).then((response: any) => {
      this.stringFile = response.split(',')[1];
    })
  }

  toBase64(file: File) {

    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = error => reject(error);

    });
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes")
  }

  previewFile(currentPage) {
    this.spinner.show();
    const body = {
      contract: this.bodyFormPromisoryNotes.contract,
      option: "CARGUE_CUPOS",
      base64: this.stringFile
    }

    this.risksProcesses.previewFile(body).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.data = response.data[0];
      this.isQuerySuccess = true
      this.isSearch = false;
      this.totalPages = response.totalPages;
      this.currentPage = response.currentPage;
    },(error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  saveAll() {
    this.spinner.show();
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const body = {
        user: user.user,
        listToLoad: this.data
      }
      this.risksProcesses.quotasMasiveLoad(body).subscribe((response: any) => {
        this.spinner.hide();
        this.data = [];
        this.stringFile = "";
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
        
      }, (error) => {
        this.spinner.hide();
        console.log(error);
        
        if(error.error.data.failure.length > 0) {
          console.log(error.error.data.failure);
          this.errorLog = error.error.data.failure;
          this.modal.show('chargue-error');
        }
        /* Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        }); */
      });
    }
  }

  goToPage($event: any){
    this.previewFile($event.currentPage)
  }

}
