import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPercentage]'
})
export class PercentageMask {
  private regex: RegExp = new RegExp(/^100(\.00?)?$|^\d{0,2}(\.\d{0,2})?$/);
 // private regex: RegExp = new RegExp(/^\d{0,2}(\.\d{0,2})?$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Escape'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 ||
        (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
        (event.key === 'c' && (event.ctrlKey || event.metaKey)) ||
        (event.key === 'v' && (event.ctrlKey || event.metaKey)) ||
        (event.key === 'x' && (event.ctrlKey || event.metaKey))) {
      return;
    }

    const current: string = this.el.nativeElement.value.replace('%', '').trim();
    const position: number = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' || event.key === '.' ? '.' : event.key,
      current.slice(position)
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    let current: string = this.el.nativeElement.value;
    if (current && !isNaN(parseFloat(current))) {
      this.el.nativeElement.value = parseFloat(current).toFixed(2) + ' %';
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    let current: string = this.el.nativeElement.value;
    if (current.includes('%')) {
      this.el.nativeElement.value = current.replace('%', '').trim();
    }
  }
}
