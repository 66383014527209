import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-quota-create',
  templateUrl: './quota-create.component.html',
  styleUrls: ['./quota-create.component.scss']
})
export class QuotaCreateComponent implements OnInit {

  public navItemTabs: any[] = [];

  constructor(private tabs: TabsService, public helper: HelperService) {
    this.navItemTabs = this.tabs.quotasTabs;
   }

  ngOnInit() {
  }

}
