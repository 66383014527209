import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataFileTable, DataFileTableReceived, DataSearchMultipleFilesBuyer, DataSendImages } from './models/buyer-report.model';
import { ServicesBuyerReportService } from './services/services-buyer-report.service';
import Swal from 'sweetalert2';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-buyer-report',
  templateUrl: './buyer-report.component.html',
  styleUrls: ['./buyer-report.component.scss']
})
export class BuyerReportComponent implements OnInit {
  @Output() filterSelect  =  new EventEmitter<any>();
  dataGetFiles: DataSearchMultipleFilesBuyer = new DataSearchMultipleFilesBuyer();
  filterData = '1';
  dataFile: DataFileTable[] = [];
  docTypes: any;
  filter: string;
  cv: string;
  totalPages = 0;
  files: File[] = [];
  fileSelected: File;
  selectedAssignationFileData: any = [];
  category: string = 'SOLICITUD INFORMACION COMPRADOR';
  dataUser: any;
  isChangeValue: boolean = false;
  filtersOptions = [
    {
      id:'2',
      name:'Consulta por Documentos'
    },
    {
      id:'3',
      name:'Cargue Documentos'
    },
  ]

  assignationFileDataList = [
    {
      document: null,
      documentType: '',
      uploads: []
    }
  ];

  constructor(
    private servicesBuyerReportService: ServicesBuyerReportService,
    private listCovinoc: ListsCovinocService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private roles: RolesService
    ) {
      //this.filtersOptions.filter((item) => item.name !== 'Cargue Documentos' && )
    }

  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem('identity'));
    this.changeFilter(this.filterData)
    this.getDoccuments();
    this.findPermissions();
  }


  findPermissions() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);

    if(user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {
        console.log("HERE")
        response.data.forEach(item => {
          if(item.hasOwnProperty('actions') && item.description === 'buyer-report') {
            console.log("Item actions: ", item.actions);

            if(!item.actions.hasOwnProperty('loadBuyerInformation')) {
              this.filtersOptions = this.filtersOptions.filter((item) => item.name !== 'Cargue Documentos');
            }
          }
        })
      })
    }
  }

  getDoccuments() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesNew;
      //console.log(this.docTypes)
    });
  }


  searchDocuments() {
    this.spinner.show();
    this.dataGetFiles.category = 'SOLICITUD INFORMACION COMPRADOR';
    this.servicesBuyerReportService.postSearchFilesBuyer(this.dataGetFiles).subscribe(
      (data: DataFileTableReceived) => {
        this.spinner.hide();
        this.dataFile = data.data;
        this.totalPages = data.totalPages;
        console.log(data)
        if(data.data.length === 0 || (data.data === null)) {
          Swal.fire({
            title: ``,
            text: `no se encuentra información`,
            type: 'info',
          });
        }
        //console.log(data,'files');

      }, (error: any) => {
        this.dataFile = [];
        this.spinner.hide();
        //console.log(error)
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      }
    );


  }

  changeValue(event: any) {
    this.filter = event;
    //this.filter = this.quotaVsAffiliateSearchForm.get('idPayer').value;
  }

  changeFilter(filter: any) {
    this.dataGetFiles = new DataSearchMultipleFilesBuyer();
    this.dataFile = [];
    this.selectedAssignationFileData = null;
    this.filterSelect.emit(filter);
    //console.log(filter)
  }

  openModal(assignationFileData: any) {
    this.selectedAssignationFileData = assignationFileData;
  }

  closeModal() {
    this.selectedAssignationFileData = null;
  }

  deletefractionate(id: number) {
    this.assignationFileDataList.splice(id, 1);
  }

  addFractionate() {
     const fractionate = {
      document: null,
      documentType: "C",
      uploads: []
    };

    this.assignationFileDataList.push(fractionate);
  }

  saveDataFile(uploads: any) {
     this.selectedAssignationFileData.uploads = uploads;
     //console.log(this.assignationFileDataList);
  }

  postSaveFiles() {
    this.spinner.show();
    const sendFilesMultiples: DataSendImages = {
      images:  [],
    }
    for(let element of this.assignationFileDataList) {
      for(let file of element.uploads) {
        //console.log('type',file.fileExtension)

        switch (file.fileExtension) {
          case 'application/pdf':
            file.fileExtension = '.pdf';
            break;
          case 'image/jpg':
            file.fileExtension = '.jpg';
            break;
          case 'image/jpeg':
            file.fileExtension = '.jpeg';
            break;
          case 'text/xml':
            file.fileExtension = '.xml';
            break;
          case 'image/png':
            file.fileExtension = '.png';
            break;

          default:
            break;
        }
        sendFilesMultiples.images.push({
          document: element.document,
          documentType: element.documentType,
          userName: this.dataUser.username,
          fileName: file.fileName,
          fileExtension: file.fileExtension,
          category: this.category,
          source: "ASR",
          image: file.image
        })
      }

    }

    this.servicesBuyerReportService.postSaveFilesBuyer(sendFilesMultiples).subscribe(
      (data: any) => {
        this.spinner.hide();
        //console.log(data,'files');
        this.files = [];
        this.assignationFileDataList= [
          {
            document: null,
            documentType: '',
            uploads: []
          }
        ];
        Swal.fire({
          title: `Los datos se guardaron correctamente`,
          text: ``,
          type: 'info',
        });

      }, (error: any) => {
        this.spinner.hide();
        //console.log(error)
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
        this.files = [];
        this.assignationFileDataList= [
          {
            document: null,
            documentType: '',
            uploads: []
          }
        ];
      }
    );
  }

  changePage(page: number) {
    this.dataGetFiles.currentPage = page;
    this.searchDocuments();
  }



}
