import { Component, OnInit } from '@angular/core';
import { ComunicationService } from '@app/pages/affiliates/billing-cut/comunication.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-consumption-variable-modal',
  templateUrl: './consumption-variable-modal.component.html',
  styleUrls: ['./consumption-variable-modal.component.scss'],
})
export class ConsumptionVariableModalComponent implements OnInit {
  consumptionDate: Date[]=[];
  convertedDate: string = '';
  arrayCutDate =[
    "Fecha 1",
    "Fecha 2",
    "Fecha 3"
  ]
  constructor(
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public getData: ComunicationService,
    public helper: HelperService,
    public subjectService: SubjectService
  ) {}

  ngOnInit(): void {
    this.getData.sendDataHistoryEvent.subscribe((data) => {
      this.consumptionDate = data;
    });
  }

  closeModal() {
    this.modal.close();
  }

  saveConsumption(){
    this.modal.close();

  }


}





