import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalClassifiedDebt, GlobalClassifiedDebtSector } from '../../services/subject-credit-history.service';

@Component({
  selector: 'app-table-global-classified-debt-history',
  templateUrl: './table-global-classified-debt-history.component.html',
  styleUrls: ['./table-global-classified-debt-history.component.scss']
})
export class TableGlobalClassifiedDebtHistoryComponent implements OnInit, OnChanges {

  public headersTable: string[] = ['Entidad Informante', 'Calificación', 'Número', 'Saldo total', 'Comercial - Número', 'Comercial - Miles $',
    'Hipotecario - Número', 'Hipotecario - Miles $', 'Consumo y Tarjeta de Crédito - Número', 'Consumo y Tarjeta de Crédito - Miles $',
    'Microcrédito - Número', 'Microcrédito - Miles $', 'Garantías - Tipo', 'Garantías - Fecha Avalúo', 'Garantías - Valor',
    'Moneda', 'Fuente'];

  @Input() globalClassifiedDebtSector: any;


  groupedData: any = {};

  constructor() { }

  ngOnInit() {
    console.log('Global Debt Classified Data:', this.globalClassifiedDebtSector);
    this.groupDataBySector();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes:', changes);
    if (changes.globalDebtClassifiedDataTable && changes.globalDebtClassifiedDataTable.currentValue) {
      this.groupDataBySector();
    }
  }

  groupDataBySector() {
    const sortedData = this.globalClassifiedDebtSector.sort((a: any, b: any) => {
      const dateA = new Date(a.fechaReporte);
      const dateB = new Date(b.fechaReporte);
      return dateA.getTime() - dateB.getTime();
    });
    this.groupedData = sortedData.reduce((acc: any, curr: any) => {
      const reportDate = curr.fechaReporte.trim();
      if (!acc[reportDate]) {
        acc[reportDate] = {};
      }
      const sector = curr.Entidad.sector.toString().trim();
      if (!acc[reportDate][sector]) {
        acc[reportDate][sector] = { data: [], totalCreditos: 0, totalSaldoPendiente: 0 };
      }
      acc[reportDate][sector].data.push(curr);
      acc[reportDate][sector].totalCreditos += curr.numeroCreditos || 0;
      acc[reportDate][sector].totalSaldoPendiente += curr.saldoPendiente || 0;
      return acc;
    }, {});
  }


}
