import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-backups-massive-transaction-status',
  templateUrl: './backups-massive-transaction-status.component.html',
  styleUrls: ['./backups-massive-transaction-status.component.scss']
})
export class BackupsMassiveTransactionStatusComponent implements OnInit {


  // Transacciones negadas

  public DataTableFake = [
    {
      documento: 9999999, tipo_documento: 'C', nombre: 'Felipe Manchego',
      reserva: 'K-123', valor: 123456, vencimiento: '3 horas', check: false
    },
    {
      documento: 9999999, tipo_documento: 'C', nombre: 'Felipe Manchego',
      reserva: 'K-123', valor: 123456, vencimiento: '20 horas', check: false
    },
    {
      documento: 9999999, tipo_documento: 'C', nombre: 'Felipe Manchego',
      reserva: 'K-123', valor: 123456, vencimiento: '1 días', check: false
    },
    {
      documento: 9999999, tipo_documento: 'C', nombre: 'Felipe Manchego',
      reserva: 'K-123', valor: 123456, vencimiento: '3 días', check: false
    },
    {
      documento: 9999999, tipo_documento: 'C', nombre: 'Felipe Manchego',
      reserva: 'K-123', valor: 123456, vencimiento: '5 días', check: false
    },
  ];

  constructor(public router: Router,
    public spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  back() {
    this.router.navigateByUrl('/pages/respaldos');
  }

  downloadTransactions() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }
}
