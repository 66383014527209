import { Injectable } from '@angular/core';
import { GetCreditHistoryResponse, Reclamo } from '../models/credit-history.model';
import { DisagreementWithInformation, ImpersonationClaim } from './natural-person-adapter.service';
import { HelperCreditInfoService } from './helper-credit-info.service';

@Injectable({
  providedIn: 'root'
})
export class CommonAdapterFunctionsService {

  constructor(
    private helperInfoService: HelperCreditInfoService
  ) { }

  /**
   * Función que extrae los Reclamos o desacuerdos de la información de la respuesta de un HDC+PN o un HDC+PJ
   * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
   * @returns Retorna un listado de todas las reclamaciones recibidas incluyendo desacuerdos de la información y suplantaciones
   */
  getClaimsfromOriginalResponse(response: GetCreditHistoryResponse | any): Reclamo[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.Reclamo
    ) return [];

    const result: Reclamo[] = response.data[0].Informes.Informe.Reclamo;

    return !Array.isArray(result) ? [result] : result;
  }

  /**
   * Función que retorna exclusivamente los desacuerdos de la información a partir de todas las reclamaciones
   * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
   * @returns Retorna exlusivamente los descauerdos de la información
   */
  getDisagreementWithInformation(response: GetCreditHistoryResponse | any): Reclamo[] {
    const claims: Reclamo[] = this.getClaimsfromOriginalResponse(response);
    const disagreements: Reclamo[] = claims.filter((claim: Reclamo) => (claim.numero));
    return disagreements;
  }

  /**
 * Función que retorna exclusivamente las reclamaciones por suplantación de identidad a partir de todas las reclamaciones
 * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
 * @returns Retorna exclusivamente las reclamaciones por suplantación de identidad
 */
  getImpersonationClaims(response: GetCreditHistoryResponse | any): Reclamo[] {
    const claims: Reclamo[] = this.getClaimsfromOriginalResponse(response);
    const disagreements: Reclamo[] = claims.filter((claim: Reclamo) => (claim.numeroCuenta));
    return disagreements;
  }

  /**
 * Función que extrae los Reclamos o desacuerdos de la información de una cuenta especifica a partir de un listado de reclamaciones
 * @param claims Es un array de reclamaciones
 * @param obligationNumber Número de cuenta o obligación a la cual se le debe de determinar los reclamos o desacuerdos de la información
 * @returns Retorna las reclamaciones que se encuentran asociadas a un número de cuenta
 */
  printClaimsByObligationNumber(claims: Reclamo[], obligationNumber: string): string {
    const foundedClaims: Reclamo[] = claims.filter((claim: Reclamo) => claim.numeroCuenta === obligationNumber);

    const array: string[] = foundedClaims.map((claim: Reclamo) => {
      const { texto: description = '-', numero: disagreementNumber = '-', numeroCuenta: accountNumber = '-' } = claim;
      const finalDescription: string = description.trim();

      return `${finalDescription}/${disagreementNumber}`;
    });

    return array.toString() || '-';
  }

  /**
   * Función que genera la data necesaria para generar la tabla de desacuerdos de la información
   * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
   * @returns Retorna un array con una estructura adecuada para inicializar la tabla de desacuerdo de la información
   */
  getDisagreementWithInformationFromOriginalResponse(response: GetCreditHistoryResponse | any): DisagreementWithInformation[] {
    if (!response) return [];

    const disagreementWithInformation: Reclamo[] = this.getDisagreementWithInformation(response);

    const result: DisagreementWithInformation[] = disagreementWithInformation.map((claim: Reclamo) => {
      const {
        texto: disagreement = '-', numero: disagreementNumber = '-',
        entidad: entity = '-', fecha: date = '-', estado: statusCode = '-'
      } = claim;

      const status: string = this.helperInfoService.findClaimStatusCodeByCode(statusCode);

      return {
        disagreement: disagreement.trim() || '-',
        disagreementNumber,
        status,
        date,
        entity
      }
    });

    return result;
  }


  /**
   * Función que genera la data necesaria para generar la tabla de desacuerdos de la información
   * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
   * @returns Retorna un array con una estructura adecuada para inicializar la tabla de suplantaciones de identidad
   */
  getImpersonationClaimsFromOriginalResponse(response: GetCreditHistoryResponse | any): ImpersonationClaim[] {
    if (!response) return [];

    const impersonationClaims: Reclamo[] = this.getImpersonationClaims(response);

    const result: ImpersonationClaim[] = impersonationClaims.map((impersonationClaim: Reclamo) => {
      const {
        texto: legend = '-',
        numeroCuenta: accountNumber = '-',
        entidad: entity = '-'
      } = impersonationClaim;

      return {
        legend,
        accountNumber,
        entity
      }

    })

    return result;
  }

  /**
   * Función que valida si un tipo de documento pertenece a una persona y no a un nit empresarial
   * @param response Es el response propio del servicio de un HDC+PN o HDC+PJ
   * @returns 
   */
  determineIfIsAPerson(response: GetCreditHistoryResponse | any): boolean {
    if (!response ||
      !response.data[0] ||
      !response.data[0].tipoIdentificacion
    ) return false;

    const documentType: string = response.data[0].tipoIdentificacion || '';

    return this.helperInfoService.determineIfIsAPerson(documentType);
  }

}
