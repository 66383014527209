import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ViewChildren, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { SelectsService } from '@app/services/selects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { SearchAffiliateModel } from '../models/request/SearchAffiliateModel';
import { AffiliatesListModel, AffiliatesResponse } from '../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../services/backups-store.service';


@Component({
  selector: 'app-search-affiliate',
  templateUrl: './search-affiliate.component.html',
  styleUrls: ['./search-affiliate.component.scss']
})
export class SearchAffiliateComponent implements OnInit {

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('formSearchAffiliate') formSearchAffiliate: NgForm;

  public cantPages = 0;
  public currentPage = 1;

  public searchAffiliate: SearchAffiliateModel = new SearchAffiliateModel();
  @Output() isFormValidate: EventEmitter<boolean> = new EventEmitter();
  public affiliateList: AffiliatesListModel[] = [];
  public documentTypes$: Observable<any> = this.selects.getSelectTypes('documentTypes');


  constructor(public helper: HelperService, public selects: SelectsService, public spinner: NgxSpinnerService,
    private backups: BackupsService, public backupsStore: BackupsStoreService, public toastr: ToastrService) {
    // this.searchAffiliate.type = 'document';
  }

  ngOnInit() {
  }


  handleSearch() {
    this.getAffiliate(1);
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getAffiliate(currentPage);
  }

  getIsValidateForm(): void {
    this.isFormValidate.emit(this.formSearchAffiliate.form.invalid)
  }

  getAffiliate(currentPage: number): void {
    this.spinner.show();
    this.searchAffiliate.currentPage = currentPage;
    this.backupsStore.changeStateSearchAffiliate(this.searchAffiliate);
    this.backups.getAffiliate(this.searchAffiliate).subscribe(
      (affiliateResponse: AffiliatesResponse) => {
        if (affiliateResponse && affiliateResponse.ok == false) {
          this.handleErrorSearch();
          return;
        }
        this.affiliateList = affiliateResponse['data'];
        this.cantPages = affiliateResponse['totalPages'];
        this.backupsStore.changeStateAffiliateList(affiliateResponse);
        console.log(affiliateResponse);
        this.spinner.hide();
      }, (error: any) => {
        this.handleErrorSearch(error);
      }
    );
  }

  handleErrorSearch(error?: any) {
    console.error(error);
    this.backupsStore.changeStateAffiliateList(new AffiliatesResponse());
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({ type: 'info', title: 'Buscar afiliado', text: `${message}` });
    } else {
      this.helper.handleUndefinedError();
    }
    this.affiliateList = [];
    this.cantPages = 0;
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
    this.spinner.hide();
  }

  cleanLetters() {
    this.searchAffiliate.document = (this.searchAffiliate.documentType === 'P')
      ? this.searchAffiliate.document
      : this.searchAffiliate.document.replace(/[^0-9]+/g, "");
  }

}
