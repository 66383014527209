
import { SelectModel } from "@app/models/select.model";
import { createReducer, on } from "@ngrx/store";
import { getSelects, getSelectsSuccess, getSelectsFailure } from "../actions";
import { SelectInterfaceResponseModel } from '../../interfaces/response/select.interface';

export interface SelectsState {
    selects: SelectModel[] | SelectInterfaceResponseModel;
    loaded: boolean;
    loading: boolean;
    error: any;
}

const initialState: SelectsState = {
    selects: [],
    loaded: false,
    loading: false,
    error: null
};

export const _selectReducer = createReducer(initialState,
    on(getSelects, state => ({ ...state, loading: true })),
    on(getSelectsSuccess, (state, { selects }) => ({ ...state, selects, loading: false, loaded: true })),
    on(getSelectsFailure, (state, { error }) => ({ ...state, error, loading: false })),
);

export function selectReducer(state: SelectsState, action: any) {
    return _selectReducer(state, action);
}