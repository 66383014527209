import { Component, OnInit } from '@angular/core';
import { CutBillingService } from '@app/models/cutBillingmodel';
import { ComunicationService } from '@app/pages/affiliates/billing-cut/comunication.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-cut-billing-modal',
  templateUrl: './cut-billing-modal.component.html',
  styleUrls: ['./cut-billing-modal.component.scss'],
})
export class CutBillingModalComponent implements OnInit {
  public dataReceived: CutBillingService[] = [];
  public isLoading: boolean = true;
  public isNullRegister: boolean;
  constructor(
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public getData: ComunicationService
  ) {}

  ngOnInit() {
    this.getData.sendNullRegister.subscribe((data) => {
      this.isNullRegister = data;
      this.spinnerHide();
    });
    this.getData.sendDataEvent.subscribe((data) => {
      this.dataReceived = data.data[0].response;
      this.spinnerHide();
      console.log('Arreglo', this.dataReceived);
    });
  }

  spinnerShow() {
    this.isLoading = true;
  }
  spinnerHide() {
    this.isLoading = false;
  }
  closeModal() {
    this.dataReceived = [];
    this.spinnerShow();
    this.modal.close();
  }
}
