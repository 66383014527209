import { Component, OnInit } from "@angular/core";
import { ModalService } from "@app/services/modal.service";

@Component({
  selector: "app-modal-amortization",
  templateUrl: "./modal-amortization.component.html",
  styleUrls: ["./modal-amortization.component.scss"],
})
export class ModalAmortizationComponent implements OnInit {
  constructor(
    public modal:ModalService
  ) {}

  ngOnInit() {}
  closeModal() {
    this.modal.close();
  }
}
