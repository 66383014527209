import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private selectedError = new Subject<any>();
  public eventSelectedError$ = this.selectedError.asObservable();

  private selectedProfileImg = new Subject<string>();
  public eventSelectedProfileImg$ = this.selectedProfileImg.asObservable();

  private stateToggleMenuBS = new BehaviorSubject<boolean>(false);
  public stateMenu$ = this.stateToggleMenuBS.asObservable();

  private stateIsMobileBS$ = new BehaviorSubject<boolean>(false);
  public stateIsMobile$ = this.stateIsMobileBS$.asObservable();

  private stateMassiveOperation = new Subject<any>();
  public stateMassiveOperation$ = this.stateMassiveOperation.asObservable();

  private stateFileModal = new Subject<File[]>();
  public stateFileModal$ = this.stateFileModal.asObservable();

  private stateMacroType = new BehaviorSubject<string>("");
  public stateMacroType$ = this.stateMacroType.asObservable();

  private authorizationList = new BehaviorSubject<Array<any>>([]);
  public authorizationList$ = this.authorizationList.asObservable();

  private handleIsResetAddress = new BehaviorSubject<boolean>(false);
  public handleIsResetAddress$ = this.handleIsResetAddress.asObservable();

  private branchList = new BehaviorSubject<Array<any>>([]);
  public branchList$ = this.branchList.asObservable();

  private sendBranchList = new BehaviorSubject<Array<any>>([]);
  public sendBranchList$ = this.sendBranchList.asObservable();

  private sendVariableDate = new BehaviorSubject<string>("");
  public sendVariableDate$ = this.sendVariableDate.asObservable();

  constructor() { }

  changeHandleIsResetAddress(value) {
    this.handleIsResetAddress.next(value)
  }

  changeAuthorizationList(list) {
    console.log(list);

    this.authorizationList.next(list)
  }

  changeBranchList(data: any[]) {
    this.branchList.next(data)
  }

  updateBranchList(data: any) {
    this.sendBranchList.next(data)
  }

  changeStateMacroType(string) {
    this.stateMacroType.next(string)
  }

  variableDate(string) {
    this.sendVariableDate.next(string)
  }

  sendText(selected: any) {
    this.selectedError.next(selected);
  }

  sendProfileImg(profileImg: any) {
    this.selectedProfileImg.next(profileImg);
  }

  menuCollapse(show: boolean) {
    this.stateToggleMenuBS.next(show);
  }

  isMobile(isMobile: boolean) {
    this.stateIsMobileBS$.next(isMobile);
  }

  returnToPrincipal(state: boolean) {
     this.stateMassiveOperation.next(state);
  }

  addFiles(files: File[]) {
    this.stateFileModal.next(files)
  }

  removeFiles() {
    this.stateFileModal.next();
  }
}
