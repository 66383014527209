import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgSelectModule } from "@ng-select/ng-select";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MunicipalityNamePipe } from './../pipes/municipality-name';
import { ModalConfirmDeleteAddressComponent } from './get-quota/quota-vs-affiliate/modal-confirm-delete-address/modal-confirm-delete-address.component';
import { ModalCreateAddressComponent } from './get-quota/quota-vs-affiliate/modal-create-address/modal-create-address.component';
// redux
import { FormsModule } from '@angular/forms';
import { ModalConfirmComponent } from '@app/components/modals/modal-confirm/modal-confirm.component';
import { ModalModule } from '@app/modals/modal.module';
import { BankNamePipe } from '@app/pipes/bank-name.pipe';
import { CityNamePipe } from '@app/pipes/city-name.pipe';
import { DepartmentNamePipe } from '@app/pipes/department-name.pipe';
import { DocTypeNamePipe } from '@app/pipes/doc-type-name.pipe';
import { FilterTablePipe } from '@app/pipes/filter-table.pipe';
import { FormatBooleanPipe } from '@app/pipes/format-boolean.pipe';
import { FormatNumberPipe } from '@app/pipes/format-number.pipe';
import { GetBankNamePipe } from '@app/pipes/get-bank-name.pipe';
import { PhoneTypeNamePipe } from '@app/pipes/phone-type-name.pipe';
import { RequestTypePipe } from '@app/pipes/request-type.pipe';
import { StateInvoicesPipe } from '@app/pipes/state-invoices.pipe';
import { TermNamePipe } from '@app/pipes/term-name.pipe';
import { TableModule } from '@app/tables/table/table.module';
import { ComponentsModule } from '@components/components.module';
import { AdministrationComponent } from '@pages/administration/administration.component';
import { DocumentsComponent } from '@pages/administration/documents/documents.component';
import { ModalPdfComponent } from '@pages/administration/my-payments/modal-pdf/modal-pdf.component';
import { MyPaymentsComponent } from '@pages/administration/my-payments/my-payments.component';
import { TabBillHistoryComponent } from '@pages/administration/my-payments/tab-bill-history/tab-bill-history.component';
import { TabBillToPayComponent } from '@pages/administration/my-payments/tab-bill-to-pay/tab-bill-to-pay.component';
import { ChargeProfileImgComponent } from '@pages/administration/profile/charge-profile-img/charge-profile-img.component';
import { HeaderComponent } from '@pages/administration/profile/header/header.component';
import { ProfileComponent } from '@pages/administration/profile/profile.component';
import { EmptySearchComponent } from '@pages/shared/empty-search/empty-search.component';
import { ModalLogoutComponent } from '@pages/shared/modals/modal-logout/modal-logout.component';
import { SharedModule } from '@shared/shared.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from '../app-routing.module';
import { AdminCovifacturaUsersComponent } from './admin-covifactura-users/admin-covifactura-users.component';
import { AffiliateBuyerComponent } from './affiliate-buyer/affiliate-buyer.component';
import { ReportAffiliateComponent } from './affiliate-buyer/components/report-affiliate/report-affiliate.component';
import { AfiliateLinkComponent } from './affiliate-link/afiliate-link.component';
import { AffiliateNotifyEmailComponent } from './affiliates/affiliate-notify-email/affiliate-notify-email.component';
import { AffiliatesSearchComponent } from './affiliates/affiliates-search/affiliates-search.component';
import { AffiliatesTableComponent } from './affiliates/affiliates-table/affiliates-table.component';
import { AffiliatesTabsComponent } from './affiliates/affiliates-tabs/affiliates-tabs.component';
import { AffiliatesComponent } from './affiliates/affiliates.component';
import { BagsManagerComponent } from './affiliates/bags-manager/bags-manager.component';
import { BillingCutComponent } from './affiliates/billing-cut/billing-cut.component';
import { ComunicationService } from './affiliates/billing-cut/comunication.service';
import { BillingRulesComponent } from './affiliates/billing-rules/billing-rules.component';
import { BillingTrafficLightComponent } from './affiliates/billing-traffic-light/billing-traffic-light.component';
import { CurrentBillingComponent } from './affiliates/current-billing/current-billing.component';
import { DownloadReportComponent } from './affiliates/download-report/download-report.component';
import { GlobalBillingRecordedComponent } from './affiliates/global-billing-recorded/global-billing-recorded.component';
import { GlobalBillingComponent } from './affiliates/global-billing/global-billing.component';
import { PendingBillingComponent } from './affiliates/pending-billing/pending-billing.component';
import { QuotasLinkComponent } from './affiliates/quotas-link/quotas-link.component';
import { RulesCreatedComponent } from './affiliates/rules-created/rules-created.component';
import { SetupAffiliateLinkComponent } from './affiliates/setup-affiliate-link/setup-affiliate-link.component';
import { SimulationTableComponent } from './affiliates/simulation-table/simulation-table.component';
import { SpecialCaseBillingComponent } from './affiliates/special-case-billing/special-case-billing.component';
import { BackingInvoicesComponent } from './backups/backings-invoices/backing-invoices.component';
import { BackingsInvoicesTableComponent } from './backups/backings-invoices/backings-invoices-table/backings-invoices-table.component';
import { BackupsAuditComponent } from './backups/backups-audit/backups-audit.component';
import { BackupsReportTableComponent } from './backups/backups-report-table/backups-report-table.component';
import { BackupsSearchComponent } from './backups/backups-search/backups-search.component';
import { BackupsTableComponent } from './backups/backups-table/backups-table.component';
import { BackupsTabsComponent } from './backups/backups-tabs/backups-tabs.component';
import { BackupsComponent } from './backups/backups.component';
import { ClaimsComponent } from './backups/claims/claims.component';
import { CvTableComponent } from './backups/cv-table/cv-table.component';
import { DeductibleComponent } from './backups/deductible/deductible.component';
import { DownloadBackingsToProcessComponent } from './backups/download-backings-to-process/download-backings-to-process.component';
import { DownloadGetRefundsComponent } from './backups/download-get-refunds/download-get-refunds.component';
import { DownloadReturnedBackingsComponent } from './backups/download-returned-backings/download-returned-backings.component';
import { DownloadComponent } from './backups/download/download.component';
import { FileTableComponent } from './backups/file-table/file-table.component';
import { FilterClaimsComponent } from './backups/filter-claims/filter-claims.component';
import { IncomeRecoveryCancellationsComponent } from './backups/income-recovery/components/income-recovery-cancellations/income-recovery-cancellations.component';
import { IncomeRecoveryFillingNumberComponent } from './backups/income-recovery/components/income-recovery-filling-number/income-recovery-filling-number.component';
import { IncomeRecoveryForDatesComponent } from './backups/income-recovery/components/income-recovery-for-dates/income-recovery-for-dates.component';
import { IncomeRecoveryIncomesComponent } from './backups/income-recovery/components/income-recovery-incomes/income-recovery-incomes.component';
import { IncomeRecoveryComponent } from './backups/income-recovery/income-recovery.component';
import { MassiveChangeStatusComponent } from './backups/massive-change-status/massive-change-status.component';
import { ModalUploadImagesComponent } from './backups/modal-upload-images/modal-upload-images.component';
import { ModalVisualizeComponent } from './backups/modal-visualize/modal-visualize.component';
import { CharguedNetsuiteInvoicesComponent } from './backups/netsuite-invoices/components/chargued-netsuite-invoices/chargued-netsuite-invoices.component';
import { NonCharguedNetsuiteInvoicesComponent } from './backups/netsuite-invoices/components/non-chargued-netsuite-invoices/non-chargued-netsuite-invoices.component';
import { NetsuiteInvoicesComponent } from './backups/netsuite-invoices/netsuite-invoices.component';
import { PaymentsTableComponent } from './backups/payments-table/payments-table.component';
import { RefundsTableComponent } from './backups/refunds-table/refunds-table.component';
import { ReturnedBackingsComponent } from './backups/returned-backings/returned-backings.component';
import { SearchAffiliateComponent } from './backups/search-affiliate/search-affiliate.component';
import { SearchAutocompleteAffiliateComponent } from './backups/search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { SettlementTableComponent } from './backups/settlement-table/settlement-table.component';
import { TestComponent } from './backups/test/test.component';
import { BackupsMassiveTransactionStatusComponent } from './backups/title-value/backups-massive-transaction-status/backups-massive-transaction-status.component';
import { BackupsMassiveTransactionComponent } from './backups/title-value/backups-massive-transaction/backups-massive-transaction.component';
import { TitleValueIndividualComponent } from './backups/title-value/title-value-individual/title-value-individual.component';
import { TitleValueMasiveComponent } from './backups/title-value/title-value-masive/title-value-masive.component';
import { TitleValueComponent } from './backups/title-value/title-value.component';
import { BackupsTransactionsComponent } from './backups/transactions/backups-transactions.component';
import { DownloadTransactionsComponent } from './backups/transactions/download-transactions/download-transactions.component';
import { TransactionsTableComponent } from './backups/transactions/transactions-table/transactions-table.component';
import { BuyerReportComponent } from './buyer-report/buyer-report.component';
import { ModalImageUploadComponent } from './buyer-report/modal-image-upload/modal-image-upload.component';
import { ModalVisualizeFilesComponent } from './buyer-report/modal-visualize-files/modal-visualize-files.component';
import { TableFileComponent } from './buyer-report/table-file/table-file.component';
import { BuyersComponent } from './buyers/buyers.component';
import { AcceptTermsComponent } from './client-study/accept-terms/accept-terms.component';
import { AddressInputComponent } from './client-study/address-input/address-input.component';
import { ClientStudyComponent } from './client-study/client-study.component';
import { CommercialReferenceComponent } from './client-study/commercial-reference/commercial-reference.component';
import { ContactChannelsComponent } from './client-study/contact-channels/contact-channels.component';
import { DisplayLegalPersonComponent } from './client-study/display-legal-person/display-legal-person.component';
import { DisplayNaturalPersonComponent } from './client-study/display-natural-person/display-natural-person.component';
import { OwnerInformationFormComponent } from './client-study/owner-information-form/owner-information-form.component';
import { AddAffiliateDetailComponent } from './contracts/add-affiliate-detail/add-affiliate-detail.component';
import { AddContractComponent } from './contracts/add-contract/add-contract.component';
import { AddQuearyCodeComponent } from './contracts/add-queary-code/add-queary-code.component';
import { AddTaxpayerComponent } from './contracts/add-taxpayer/add-taxpayer.component';
import { AnotherYesComponentComponent } from './contracts/another-yes-component/another-yes-component.component';
import { ConsultIndexComponent } from './contracts/consult-index/consult-index.component';
import { ContractDetailComponent } from './contracts/contract-detail/contract-detail.component';
import { OperativeDelimiterComponent } from './contracts/operative-delimiter/operative-delimiter.component';
import { SpecialConditionsComponent } from './contracts/special-conditions/special-conditions.component';
import { StepperContractComponent } from './contracts/stepper-contract/stepper-contract.component';
import { ExcessRiskComponent } from './excess-risk/excess-risk.component';
import { ExpressReportComponent } from './express-report/express-report.component';
import { FauDigitalComponent } from './fau-digital/fau-digital.component';
import { ActivateQuotaComponent } from './get-quota/activate-quota/activate-quota.component';
import { AdminQuotaComponent } from './get-quota/admin-quota/admin-quota.component';
import { CoviResourceComponent } from './get-quota/covi-resource/covi-resource.component';
import { GetQuotaComponent } from './get-quota/get-quota.component';
import { BackingStudyComponent } from './get-quota/query-quota/backing-study/backing-study.component';
import { BuyerInformationComponent } from './get-quota/query-quota/buyer-information/buyer-information.component';
import { CentralRiskSummaryComponent } from './get-quota/query-quota/central-risk-summary/central-risk-summary.component';
import { CurrentExhibitionsComponent } from './get-quota/query-quota/current-exhibition/current-exhibitions.component';
import { DescriptionChangeComponent } from './get-quota/query-quota/description-change/description-change.component';
import { FinalExhibitionComponent } from './get-quota/query-quota/final-exhibition/final-exhibition.component';
import { FinancialInformationComponent } from './get-quota/query-quota/financial-information/financial-information.component';
import { FinancialReasonsComponent } from './get-quota/query-quota/financial-reasons/financial-reasons.component';
import { HolderValidationComponent } from './get-quota/query-quota/holder-validation/holder-validation.component';
import { InitialExhibitionComponent } from './get-quota/query-quota/initial-exhibition/initial-exhibition.component';
import { LocationContactResultComponent } from './get-quota/query-quota/location-contact-result/location-contact-result.component';
import { MainCustomersComponent } from './get-quota/query-quota/main-customers/main-customers.component';
import { ObservationsMacroComponent } from './get-quota/query-quota/observations-macro/observations-macro.component';
import { ProfitAndLossComponent } from './get-quota/query-quota/profit-and-loss/profit-and-loss.component';
import { QueryQuotaComponent } from './get-quota/query-quota/query-quota.component';
import { ReferencesComponent } from './get-quota/query-quota/references/references.component';
import { RequestDescriptionComponent } from './get-quota/query-quota/request-description/request-description.component';
import { RunningProyectsComponent } from './get-quota/query-quota/running-proyects/running-proyects.component';
import { QuotaCreateComponent } from './get-quota/quota-create/quota-create.component';
import { ContactDataComponent } from './get-quota/quota-vs-affiliate/contact-data/contact-data.component';
import { ModalCreateEmailComponent } from './get-quota/quota-vs-affiliate/modal-create-email/modal-create-email.component';
import { ModalCreatePhoneComponent } from './get-quota/quota-vs-affiliate/modal-create-phone/modal-create-phone.component';
import { ModalRepeatedPhonesComponent } from './get-quota/quota-vs-affiliate/modal-repeated-phones/modal-repeated-phones.component';
import { QuotaVsAffiliateComponent } from './get-quota/quota-vs-affiliate/quota-vs-affiliate.component';
import { ModalConfirmDeleteRelatedDocumentComponent } from './get-quota/quota-vs-affiliate/related-documents/modal-confirm-delete-related-document/modal-confirm-delete-related-document.component';
import { ModalCreateRelatedDocumentComponent } from './get-quota/quota-vs-affiliate/related-documents/modal-create-related-document/modal-create-related-document.component';
import { RelatedDocumentsComponent } from './get-quota/quota-vs-affiliate/related-documents/related-documents.component';
import { QuotaWithoutContract } from './get-quota/quota-without-contract/quota-without-contract.component';
import { ReservesComponent } from './get-quota/reserves/reserves.component';
import { SearcherComponent } from './get-quota/searcher/searcher.component';
import { AutexKeyGenComponent } from './get-quota/transactions/blocks-unlocks/autex-key-gen/autex-key-gen.component';
import { BlockAccountComponent } from './get-quota/transactions/blocks-unlocks/block-account/block-account.component';
import { BlockTelphoneComponent } from './get-quota/transactions/blocks-unlocks/block-telphone/block-telphone.component';
import { BlockUnlockBuyerComponent } from './get-quota/transactions/blocks-unlocks/block-unlock-buyer/block-unlock-buyer.component';
import { BlocksUnlocksComponent } from './get-quota/transactions/blocks-unlocks/blocks-unlocks.component';
import { TransactionListByAuthorizationComponent } from './get-quota/transactions/blocks-unlocks/transaction-list-by-authorization/transaction-list-by-authorization.component';
import { BulkUploadQuearyComponent } from './get-quota/transactions/bulk-upload-queary/bulk-upload-queary.component';
import { EntryTransactionsComponent } from './get-quota/transactions/entry-transactions/entry-transactions.component';
import { IndividualTransactionsComponent } from './get-quota/transactions/entry-transactions/individual-transactions/individual-transactions.component';
import { SpecialTransactionsComponent } from './get-quota/transactions/entry-transactions/special-transactions/special-transactions.component';
import { LocalizationCustomerComponent } from './get-quota/transactions/localization-customer/localization-customer.component';
import { NonPaymentNoticeComponent } from './get-quota/transactions/non-payment-notice/non-payment-notice.component';
import { PromisoryNotes } from './get-quota/transactions/promisory-notes/promisory-notes.component';
import { QuotaTransactionsComponent } from './get-quota/transactions/quota-transactions/quota-transactions.component';
import { ThreeAndSixPaymentsComponent } from './get-quota/transactions/three-and-six-payments/three-and-six-payments.component';
import { AuthorizationsListComponent } from './get-quota/transactions/title-modify/authorizations-list/authorizations-list.component';
import { SearcherTitlesComponent } from './get-quota/transactions/title-modify/searcher-titles/searcher-titles.component';
import { TableModifyTitlesComponent } from './get-quota/transactions/title-modify/table-modify-titles/table-modify-titles.component';
import { TableReverseExonerationsComponent } from './get-quota/transactions/title-modify/table-reverse-exonerations/table-reverse-exonerations.component';
import { TitleModifyWithBackingComponent } from './get-quota/transactions/title-modify/title-modify-with-backing/title-modify-with-backing.component';
import { TitleModifyComponent } from './get-quota/transactions/title-modify/title-modify.component';
import { TransactionPromisoryNoteComponent } from './get-quota/transactions/transaction-promisory-note/transaction-promisory-note.component';
import { MarkNonPaymentComponent } from './mark-non-payment/mark-non-payment.component';
import { NonPaymentTableComponent } from './mark-non-payment/non-payment-table/non-payment-table.component';
import { AffiliateCardComponent } from './net-factoring/affiliate-card/affiliate-card.component';
import { FinancialInvoicesComponent } from './net-factoring/financial-invoices/financial-invoices.component';
import { InvoicesStatusComponent } from './net-factoring/invoices-status/invoices-status.component';
import { NamesAffiliatesComponent } from './net-factoring/names-affiliates/names-affiliates.component';
import { NegotiatedInvoicesComponent } from './net-factoring/negotiated-invoices/negotiated-invoices.component';
import { NetFactoringRouterComponent } from './net-factoring/net-factoring-router/net-factoring-router.component';
import { NetFactoringComponent } from './net-factoring/net-factoring.component';
import { ParamsAffiliateComponent } from './net-factoring/params-affiliate/params-affiliate.component';
import { PossibleInvoicesComponent } from './net-factoring/possible-invoices/possible-invoices.component';
import { DownloadUploadExcelComponent } from './operations/download-upload-excel/download-upload-excel.component';
import { MassiveExonerationStatusComponent } from './operations/massive-exoneration-status/massive-exoneration-status.component';
import { MassiveExonerationComponent } from './operations/massive-exoneration/massive-exoneration.component';
import { MassiveNopayStatusComponent } from './operations/massive-nopay-status/massive-nopay-status.component';
import { MassiveNopayComponent } from './operations/massive-nopay/massive-nopay.component';
import { OperationsTabsComponent } from './operations/operations-tabs/operations-tabs.component';
import { OperationsComponent } from './operations/operations.component';
import { TabIndividualOperationComponent } from './operations/tab-individual-operation/tab-individual-operation.component';
import { TabMassiveOperationComponent } from './operations/tab-massive-operation/tab-massive-operation.component';
import { TooltipIconComponent } from './operations/tooltip-icon/tooltip-icon.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { BagTableComponent } from './products/bag-table/bag-table.component';
import { ProductCardComponent } from './products/product-card/product-card.component';
import { ProductsComponent } from './products/products.component';
import { FilingsComponent } from './quota-radication/filings/filings.component';
import { MyRequestsComponent } from './quota-radication/my-requests/my-requests.component';
import { QuotaRadicationComponent } from './quota-radication/quota-radication.component';
import { RadicationComponent } from './quota-radication/radication/radication.component';
import { AssigProfileComponent } from './quota-radication/request-admin/assig-profile/assig-profile.component';
import { CreateEditProfileComponent } from './quota-radication/request-admin/create-edit-profile/create-edit-profile.component';
import { ProfileManagmentComponent } from './quota-radication/request-admin/profile-managment/profile-managment.component';
import { RequestAdminComponent } from './quota-radication/request-admin/request-admin.component';
import { RequestManagmentComponent } from './quota-radication/request-managment/request-managment.component';
import { SettleRequestComponent } from './quota-radication/settle-request/settle-request.component';
import { CurrentExhibitionComponent } from './quotas/current-exhibition/current-exhibition.component';
import { DescriptionRequestComponent } from './quotas/description-request/description-request.component';
import { HolderRelatedValidationsComponent } from './quotas/holder-related-validations/holder-related-validations.component';
import { LocationResultContactComponent } from './quotas/location-result-contact/location-result-contact.component';
import { MacrosComponent } from './quotas/macros/macros.component';
import { MainCentralsComponent } from './quotas/main-centrals/main-centrals.component';
import { MainSuppliersComponent } from './quotas/main-suppliers/main-suppliers.component';
import { ProjectsEjecutionComponent } from './quotas/projects-ejecution/projects-ejecution.component';
import { QuotaBuyerInfoComponent } from './quotas/quota-buyer-info/quota-buyer-info.component';
import { QuotaInfoComponent } from './quotas/quota-info/quota-info.component';
import { QuotasComponent } from './quotas/quotas.component';
import { ReferenceQuotaComponent } from './quotas/reference-quota/reference-quota.component';
import { SummaryCreditBureausComponent } from './quotas/summary-credit-bureaus/summary-credit-bureaus.component';
import { ReportBuyerComponent } from './report-buyer/report-buyer.component';
import { AffiliatePoliciesComponent } from './risks-processes-charges/affiliate-policies/affiliate-policies.component';
import { AffiliateRisksExportInfoComponent } from './risks-processes-charges/affiliate-risks/affiliate-risks-export-info/affiliate-risks-export-info.component';
import { AffiliateRisksIndividualComponent } from './risks-processes-charges/affiliate-risks/affiliate-risks-individual/affiliate-risks-individual.component';
import { AffiliateRisksMassiveComponent } from './risks-processes-charges/affiliate-risks/affiliate-risks-massive/affiliate-risks-massive.component';
import { AffiliateRisksComponent } from './risks-processes-charges/affiliate-risks/affiliate-risks.component';
import { BuyersMassiveEvaluationComponent } from './risks-processes-charges/buyers-massive-evaluation/buyers-massive-evaluation.component';
import { MassQuotaLoadComponent } from './risks-processes-charges/mass-quota-load/mass-quota-load.component';
import { QuotaAdjustmentComponent } from './risks-processes-charges/quota-adjustment/quota-adjustment.component';
import { QuotaCalificationsComponent } from './risks-processes-charges/quota-califications/quota-califications.component';
import { RestrictiveListsComponent } from './risks-processes-charges/restrictive-lists/restrictive-lists.component';
import { RisksBuyerQuotasComponent } from './risks-processes-charges/risks-buyer-quotas/risks-buyer-quotas.component';
import { RisksCoveringComponent } from './risks-processes-charges/risks-covering/risks-covering.component';
import { HabeasDataIndividualComponent } from './risks-processes-charges/risks-habeas-data/habeas-data-individual/habeas-data-individual.component';
import { HabeasDataMassiveComponent } from './risks-processes-charges/risks-habeas-data/habeas-data-massive/habeas-data-massive.component';
import { RisksHabeasDataComponent } from './risks-processes-charges/risks-habeas-data/risks-habeas-data.component';
import { RisksProcessesChargesComponent } from './risks-processes-charges/risks-processes-charges.component';
import { StateChangeComponent } from './risks-processes-charges/state-change/state-change.component';
import { TransactionsThreeAndSixPaymentsComponent } from './transactions/three-and-six-payments/three-and-six-payments.component';
import { TransactionsReportsComponent } from './transactions/transactions-reports/transactions-reports.component';
import { TransactionsComponent } from './transactions/transactions.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModalNetsuiteInvoiceInformationComponent } from '@app/components/modals/modal-netsuite-invoice-information/modal-netsuite-invoice-information.component';
import { PercentageMask } from '@app/directives/percentage-mask';
import { ExperianCodeSectorPipe } from '@app/pipes/experian-code-sector.pipe';
import { ExperianRecognizeGenrePipe } from '@app/pipes/experian-recognize-genre.pipe';
import { ExperianStateAccountPipe } from '@app/pipes/experian-state-account.pipe';
import { ExperianStateDocumentPipe } from '@app/pipes/experian-state-document.pipe';
import { DisabledByRolDirective } from '@app/roles/directives/disabled-by-rol.directive';
import { NgxMaskModule } from 'ngx-mask';
import { BagsActiveAffiliateComponent } from './affiliates/bags-active-affiliate/bags-active-affiliate.component';
import { BagsReportComponent } from './affiliates/bags-report/bags-report.component';
import { BillingGenerateReportComponent } from './affiliates/billing-generate-report/billing-generate-report.component';
import { CreditStudyComponent } from './affiliates/credit-study/credit-study.component';
import { GlobalBillingReportComponent } from './affiliates/global-billing-report/global-billing-report.component';
import { InstallmentsActiveSuspendedComponent } from './affiliates/installments-active-suspended/installments-active-suspended.component';
import { PendingBillingReportComponent } from './affiliates/pending-billing-report/pending-billing-report.component';
import { AlertsHistoryComponent } from './credit-info/components/alerts-history/alerts-history.component';
import { ArticleHistoryComponent } from './credit-info/components/article-history/article-history.component';
import { BasicInformationHistoryComponent } from './credit-info/components/basic-information-history/basic-information-history.component';
import { CurrentDebtHistoryComponent } from './credit-info/components/current-debt-history/current-debt-history.component';
import { CurrentDebtInformationHistoryComponent } from './credit-info/components/current-debt-information-history/current-debt-information-history.component';
import { DebtEvolutionHistoryComponent } from './credit-info/components/debt-evolution-history/debt-evolution-history.component';
import { EmptyStateExperianComponent } from './credit-info/components/empty-state-experian/empty-state-experian.component';
import { GlobalClassifiedDebtHistoryComponent } from './credit-info/components/global-classified-debt-history/global-classified-debt-history.component';
import { GlobalDebtSummaryHistoryComponent } from './credit-info/components/global-debt-summary-history/global-debt-summary-history.component';
import { HabitOfPaymentActiveHistoryComponent } from './credit-info/components/habit-of-payment-active-history/habit-of-payment-active-history.component';
import { HabitOfPaymentInactiveHistoryComponent } from './credit-info/components/habit-of-payment-inactive-history/habit-of-payment-inactive-history.component';
import { HistoryQueriesHistoryComponent } from './credit-info/components/history-queries-history/history-queries-history.component';
import { LawsuitsHistoryComponent } from './credit-info/components/lawsuits-history/lawsuits-history.component';
import { LegalActiveSavingAndCheckingAccountsComponent } from './credit-info/components/legal-active-saving-and-checking-accounts/legal-active-saving-and-checking-accounts.component';
import { LegalBalancesQuotaValuesOfActiveObligationsComponent } from './credit-info/components/legal-balances-quota-values-of-active-obligations/legal-balances-quota-values-of-active-obligations.component';
import { LegalBasicInformationTableComponent } from './credit-info/components/legal-basic-information-table/legal-basic-information-table.component';
import { LegalBasicInformationComponent } from './credit-info/components/legal-basic-information/legal-basic-information.component';
import { LegalCompanyBasicInformationComponent } from './credit-info/components/legal-company-basic-information/legal-company-basic-information.component';
import { LegalCreditHistoryTableComponent } from './credit-info/components/legal-credit-history-table/legal-credit-history-table.component';
import { LegalDebtLevelTableComponent } from './credit-info/components/legal-debt-level-table/legal-debt-level-table.component';
import { LegalFinancialInformationTableComponent } from './credit-info/components/legal-financial-information-table/legal-financial-information-table.component';
import { LegalGlobalDebtClassifiedTableComponent } from './credit-info/components/legal-global-debt-classified-table/legal-global-debt-classified-table.component';
import { LegalHistoricQueryTableComponent } from './credit-info/components/legal-historic-query-table/legal-historic-query-table.component';
import { LegalInactiveSavingAndCheckingAccountsComponent } from './credit-info/components/legal-inactive-saving-and-checking-accounts/legal-inactive-saving-and-checking-accounts.component';
import { LegalJudicialInformationTableComponent } from './credit-info/components/legal-judicial-information-table/legal-judicial-information-table.component';
import { LegalLegalRepresentationTableComponent } from './credit-info/components/legal-legal-representation-table/legal-legal-representation-table.component';
import { LegalOpenObligationsTableComponent } from './credit-info/components/legal-open-obligations-table/legal-open-obligations-table.component';
import { LegalPaymentHabitOfActiveObligationsTableComponent } from './credit-info/components/legal-payment-habit-of-active-obligations-table/legal-payment-habit-of-active-obligations-table.component';
import { LegalPaymentHabitOfInactiveObligationsTableComponent } from './credit-info/components/legal-payment-habit-of-inactive-obligations-table/legal-payment-habit-of-inactive-obligations-table.component';
import { LegalPaymentHabitResumeTableComponent } from './credit-info/components/legal-payment-habit-resume-table/legal-payment-habit-resume-table.component';
import { LegalPaymentHabitTableComponent } from './credit-info/components/legal-payment-habit-table/legal-payment-habit-table.component';
import { LegalPercentageOfArrearsByTypeOfObligationComponent } from './credit-info/components/legal-percentage-of-arrears-by-type-of-obligation/legal-percentage-of-arrears-by-type-of-obligation.component';
import { LegalPersonAlertsTableComponent } from './credit-info/components/legal-person-alerts-table/legal-person-alerts-table.component';
import { LegalPersonBasicInformationComponent } from './credit-info/components/legal-person-basic-information/legal-person-basic-information.component';
import { LegalScoresTableComponent } from './credit-info/components/legal-scores-table/legal-scores-table.component';
import { NotesHistoryComponent } from './credit-info/components/notes-history/notes-history.component';
import { QueryFormHistoryComponent } from './credit-info/components/query-form-history/query-form-history.component';
import { RecognizeAddressesTableComponent } from './credit-info/components/recognize-addresses-table/recognize-addresses-table.component';
import { RecognizeBasicInfoTableComponent } from './credit-info/components/recognize-basic-info-table/recognize-basic-info-table.component';
import { RecognizeDirectionsVectorTableComponent } from './credit-info/components/recognize-directions-vector-table/recognize-directions-vector-table.component';
import { RecognizeEmailsTableComponent } from './credit-info/components/recognize-emails-table/recognize-emails-table.component';
import { RecognizePhonesTableComponent } from './credit-info/components/recognize-phones-table/recognize-phones-table.component';
import { ScoresHistoryComponent } from './credit-info/components/scores-history/scores-history.component';
import { SummaryHistoryComponent } from './credit-info/components/summary-history/summary-history.component';
import { TableCurrentDebtHistoryComponent } from './credit-info/components/table-current-debt-history/table-current-debt-history.component';
import { TableDebtEvolutionHistoryComponent } from './credit-info/components/table-debt-evolution-history/table-debt-evolution-history.component';
import { TableDebtTendencyHistoryComponent } from './credit-info/components/table-debt-tendency-history/table-debt-tendency-history.component';
import { TableGeneralProfileHistoryComponent } from './credit-info/components/table-general-profile-history/table-general-profile-history.component';
import { TableGlobalClassifiedDebtHistoryComponent } from './credit-info/components/table-global-classified-debt-history/table-global-classified-debt-history.component';
import { TableHabitOfPaymentActiveHistoryComponent } from './credit-info/components/table-habit-of-payment-active-history/table-habit-of-payment-active-history.component';
import { TableHabitOfPaymentInactiveHistoryComponent } from './credit-info/components/table-habit-of-payment-inactive-history/table-habit-of-payment-inactive-history.component';
import { TableSummaryBalancesAndArrearsHistoryComponent } from './credit-info/components/table-summary-balances-and-arrears-history/table-summary-balances-and-arrears-history.component';
import { TableVectorAnalysisHistoryComponent } from './credit-info/components/table-vector-analysis-history/table-vector-analysis-history.component';
import { TecPersonMessageComponent } from './credit-info/components/tec-person-message/tec-person-message.component';
import { VectorAnalysisHistoryComponent } from './credit-info/components/vector-analysis-history/vector-analysis-history.component';
import { CreditInfoComponent } from './credit-info/credit-info.component';
import { CreditHistoryComponent } from './credit-info/experian/credit-history/credit-history.component';
import { ExperianComponent } from './credit-info/experian/experian.component';
import { LegalPersonComponent } from './credit-info/experian/legal-person/legal-person.component';
import { RecognizeComponent } from './credit-info/experian/recognize/recognize.component';
import { CustomCurrencyPipe } from './credit-info/pipes/custom-currency.pipe';
import { HtmlCleanerPipe } from './credit-info/pipes/html-cleaner.pipe';
import { ObligationBehaviorPipe } from './credit-info/pipes/obligation-behavior.pipe';
import { SafePercentPipe } from './credit-info/pipes/safe-percent.pipe';
import { TransunionComponent } from './credit-info/transunion/transunion.component';
import { MassiveChargeModule } from './massive-charge/massive-charge.module';
import { ReportsPdfComponent } from './reports-pdf/reports-pdf.component';
import { ConsumptionVariableComponent } from './affiliates/consumption-variable/consumption-variable.component';
import { DisagreementsWithInformationGenericComponent } from './credit-info/components/disagreements-with-information-generic/disagreements-with-information-generic.component';
import { ImpersonationClaimsGenericComponent } from './credit-info/components/impersonation-claims-generic/impersonation-claims-generic.component';
import { PaymentsAdministratorComponent } from './contracts/payments-administrator/payments-administrator.component';


@NgModule({
  providers: [ComunicationService, ExperianStateAccountPipe, HtmlCleanerPipe, SafePercentPipe],
  declarations: [
    HeaderComponent,
    AdministrationComponent,
    MyPaymentsComponent,
    TabBillToPayComponent,
    TabBillHistoryComponent,
    ModalPdfComponent,
    DocumentsComponent,
    ModalLogoutComponent,
    EmptySearchComponent,
    ProfileComponent,
    ChargeProfileImgComponent,
    BackupsComponent,
    BackupsTableComponent,
    SettlementTableComponent,
    RefundsTableComponent,
    TitleValueComponent,
    TitleValueIndividualComponent,
    TitleValueMasiveComponent,
    BackupsSearchComponent,
    BackupsTabsComponent,
    BackupsMassiveTransactionComponent,
    BackupsMassiveTransactionStatusComponent,
    DownloadUploadExcelComponent,
    MassiveExonerationComponent,
    MassiveExonerationStatusComponent,
    MassiveNopayComponent,
    MassiveNopayStatusComponent,
    OperationsTabsComponent,
    TabIndividualOperationComponent,
    TabMassiveOperationComponent,
    TooltipIconComponent,
    OperationsComponent,
    AcceptTermsComponent,
    AddressInputComponent,
    CommercialReferenceComponent,
    ContactChannelsComponent,
    DisplayLegalPersonComponent,
    DisplayNaturalPersonComponent,
    OwnerInformationFormComponent,
    ClientStudyComponent,
    QuotasComponent,
    BuyersComponent,
    AffiliatesComponent,
    AffiliatesSearchComponent,
    AffiliatesTabsComponent,
    AffiliatesTableComponent,
    BackupsReportTableComponent,
    SearchAutocompleteAffiliateComponent,
    SearchAffiliateComponent,
    PaymentsTableComponent,
    DownloadComponent,
    TestComponent,
    MarkNonPaymentComponent,
    NonPaymentTableComponent,
    ProductsComponent,
    AddProductComponent,
    ProductCardComponent,
    TransactionsTableComponent,
    BackupsTransactionsComponent,
    BagTableComponent,
    DownloadTransactionsComponent,
    QuotaInfoComponent,
    QuotaBuyerInfoComponent,
    HolderRelatedValidationsComponent,
    SummaryCreditBureausComponent,
    LocationResultContactComponent,
    ProjectsEjecutionComponent,
    MainSuppliersComponent,
    MainCentralsComponent,
    ReferenceQuotaComponent,
    DescriptionRequestComponent,
    CurrentExhibitionComponent,
    MacrosComponent,
    QuotaRadicationComponent,
    SettleRequestComponent,
    MyRequestsComponent,
    GetQuotaComponent,
    QueryQuotaComponent,
    DownloadBackingsToProcessComponent,
    BackingStudyComponent,
    BuyerInformationComponent,
    HolderValidationComponent,
    CentralRiskSummaryComponent,
    LocationContactResultComponent,
    MainCustomersComponent,
    RunningProyectsComponent,
    ReferencesComponent,
    RequestDescriptionComponent,
    InitialExhibitionComponent,
    FinalExhibitionComponent,
    FinancialInformationComponent,
    FinancialReasonsComponent,
    ObservationsMacroComponent,
    DescriptionChangeComponent,
    ProfitAndLossComponent,
    CurrentExhibitionsComponent,
    SetupAffiliateLinkComponent,
    RequestTypePipe,
    CityNamePipe,
    DepartmentNamePipe,
    BankNamePipe,
    PhoneTypeNamePipe,
    TermNamePipe,
    DocTypeNamePipe,
    FormatNumberPipe,
    FilterTablePipe,
    MunicipalityNamePipe,
    StateInvoicesPipe,
    AfiliateLinkComponent,
    AdminQuotaComponent,
    QuotaCreateComponent,
    QuotaVsAffiliateComponent,
    QuotaWithoutContract,
    ActivateQuotaComponent,
    ReservesComponent,
    DownloadReportComponent,
    NonPaymentNoticeComponent,
    QuotaTransactionsComponent,
    TitleModifyComponent,
    PromisoryNotes,
    SearcherComponent,
    SearcherTitlesComponent,
    TableModifyTitlesComponent,
    TableReverseExonerationsComponent,
    TitleModifyWithBackingComponent,
    TransactionsComponent,
    TransactionsThreeAndSixPaymentsComponent,
    TransactionsReportsComponent,
    EntryTransactionsComponent,
    IndividualTransactionsComponent,
    SpecialTransactionsComponent,
    RequestAdminComponent,
    RequestManagmentComponent,
    BackupsAuditComponent,
    ReturnedBackingsComponent,
    DownloadReturnedBackingsComponent,
    DownloadGetRefundsComponent,
    TransactionPromisoryNoteComponent,
    FormatBooleanPipe,
    ThreeAndSixPaymentsComponent,
    AffiliateNotifyEmailComponent,
    AddContractComponent,
    StepperContractComponent,
    AddQuearyCodeComponent,
    AddAffiliateDetailComponent,
    AddTaxpayerComponent,
    AnotherYesComponentComponent,
    SpecialConditionsComponent,
    ConsultIndexComponent,
    OperativeDelimiterComponent,
    ContractDetailComponent,
    AffiliateNotifyEmailComponent,
    BlocksUnlocksComponent,
    BlockAccountComponent,
    BlockUnlockBuyerComponent,
    BlockTelphoneComponent,
    TransactionListByAuthorizationComponent,
    AutexKeyGenComponent,
    MassiveChangeStatusComponent,
    ModalConfirmComponent,
    GetBankNamePipe,
    ProfileManagmentComponent,
    CreateEditProfileComponent,
    AssigProfileComponent,
    NetFactoringComponent,
    NamesAffiliatesComponent,
    NetFactoringRouterComponent,
    RisksProcessesChargesComponent,
    MassQuotaLoadComponent,
    QuotaAdjustmentComponent,
    StateChangeComponent,
    AffiliatePoliciesComponent,
    AffiliateRisksComponent,
    RisksHabeasDataComponent,
    BuyersMassiveEvaluationComponent,
    RestrictiveListsComponent,
    RisksBuyerQuotasComponent,
    RisksCoveringComponent,
    QuotaCalificationsComponent,
    AffiliateRisksExportInfoComponent,
    AffiliateRisksIndividualComponent,
    AffiliateRisksMassiveComponent,
    HabeasDataIndividualComponent,
    HabeasDataMassiveComponent,
    CoviResourceComponent,
    NegotiatedInvoicesComponent,
    PossibleInvoicesComponent,
    AuthorizationsListComponent,
    BuyerReportComponent,
    ModalImageUploadComponent,
    TableFileComponent,
    ModalVisualizeFilesComponent,
    ModalCreatePhoneComponent,
    ModalCreateAddressComponent,
    ModalRepeatedPhonesComponent,
    ModalConfirmDeleteAddressComponent,
    ModalCreateEmailComponent,
    ContactDataComponent,
    RelatedDocumentsComponent,
    ModalCreateRelatedDocumentComponent,
    ModalConfirmDeleteRelatedDocumentComponent,
    ClaimsComponent,
    FilterClaimsComponent,
    FileTableComponent,
    ModalUploadImagesComponent,
    FauDigitalComponent,
    CvTableComponent,
    ModalVisualizeComponent,
    ModalVisualizeComponent,
    BulkUploadQuearyComponent,
    ParamsAffiliateComponent,
    AffiliateCardComponent,
    ExpressReportComponent,
    ExcessRiskComponent,
    DeductibleComponent,
    InvoicesStatusComponent,
    ReportAffiliateComponent,
    ReportBuyerComponent,
    AffiliateBuyerComponent,
    IncomeRecoveryComponent,
    IncomeRecoveryIncomesComponent,
    IncomeRecoveryCancellationsComponent,
    IncomeRecoveryForDatesComponent,
    IncomeRecoveryFillingNumberComponent,
    FinancialInvoicesComponent,
    QuotasLinkComponent,
    GlobalBillingComponent,
    BillingRulesComponent,
    RulesCreatedComponent,
    SimulationTableComponent,
    BillingCutComponent,
    FilingsComponent,
    RadicationComponent,
    LocalizationCustomerComponent,
    NetsuiteInvoicesComponent,
    BackingInvoicesComponent,
    BackingsInvoicesTableComponent,
    AdminCovifacturaUsersComponent,
    CharguedNetsuiteInvoicesComponent,
    NonCharguedNetsuiteInvoicesComponent,
    GlobalBillingRecordedComponent,
    PendingBillingComponent,
    SpecialCaseBillingComponent,
    BagsManagerComponent,
    CurrentBillingComponent,
    BillingTrafficLightComponent,
    AdminCovifacturaUsersComponent,
    PercentageMask,
    BillingGenerateReportComponent,
    PendingBillingReportComponent,
    GlobalBillingReportComponent,
    BagsReportComponent,
    BagsActiveAffiliateComponent,
    DisabledByRolDirective,
    CreditInfoComponent,
    ExperianComponent,
    TransunionComponent,
    RecognizeComponent,
    CreditHistoryComponent,
    LegalPersonComponent,
    RecognizeBasicInfoTableComponent,
    RecognizeAddressesTableComponent,
    RecognizePhonesTableComponent,
    RecognizeEmailsTableComponent,
    RecognizeDirectionsVectorTableComponent,
    LegalPersonAlertsTableComponent,
    LegalLegalRepresentationTableComponent,
    LegalFinancialInformationTableComponent,
    LegalJudicialInformationTableComponent,
    LegalBasicInformationTableComponent,
    LegalScoresTableComponent,
    BasicInformationHistoryComponent,
    AlertsHistoryComponent,
    HistoryQueriesHistoryComponent,
    GlobalDebtSummaryHistoryComponent,
    TableGlobalClassifiedDebtHistoryComponent,
    GlobalClassifiedDebtHistoryComponent,
    DebtEvolutionHistoryComponent,
    TableDebtEvolutionHistoryComponent,
    VectorAnalysisHistoryComponent,
    TableVectorAnalysisHistoryComponent,
    TableHabitOfPaymentInactiveHistoryComponent,
    HabitOfPaymentInactiveHistoryComponent,
    HabitOfPaymentActiveHistoryComponent,
    TableHabitOfPaymentActiveHistoryComponent,
    SummaryHistoryComponent,
    TableGeneralProfileHistoryComponent,
    TableDebtTendencyHistoryComponent,
    CurrentDebtHistoryComponent,
    TableCurrentDebtHistoryComponent,
    ScoresHistoryComponent,
    NotesHistoryComponent,
    LawsuitsHistoryComponent,
    QueryFormHistoryComponent,
    CurrentDebtInformationHistoryComponent,
    ArticleHistoryComponent,
    LegalCreditHistoryTableComponent,
    LegalPaymentHabitTableComponent,
    LegalOpenObligationsTableComponent,
    LegalHistoricQueryTableComponent,
    LegalPaymentHabitResumeTableComponent,
    LegalDebtLevelTableComponent,
    LegalGlobalDebtClassifiedTableComponent,
    TableSummaryBalancesAndArrearsHistoryComponent,
    ObligationBehaviorPipe,
    CustomCurrencyPipe,
    SafePercentPipe,
    EmptyStateExperianComponent,
    ExperianRecognizeGenrePipe,
    ExperianStateDocumentPipe,
    ExperianCodeSectorPipe,
    TecPersonMessageComponent,
    CreditStudyComponent,
    ExperianStateAccountPipe,
    ModalNetsuiteInvoiceInformationComponent,
    LegalInactiveSavingAndCheckingAccountsComponent,
    LegalActiveSavingAndCheckingAccountsComponent,
    LegalPaymentHabitOfActiveObligationsTableComponent,
    LegalPaymentHabitOfInactiveObligationsTableComponent,
    LegalBalancesQuotaValuesOfActiveObligationsComponent,
    HtmlCleanerPipe,
    LegalBasicInformationComponent,
    LegalPersonBasicInformationComponent,
    LegalCompanyBasicInformationComponent,
    LegalPercentageOfArrearsByTypeOfObligationComponent,
    InstallmentsActiveSuspendedComponent,
    ReportsPdfComponent,
    ConsumptionVariableComponent,
    DisagreementsWithInformationGenericComponent,
    ImpersonationClaimsGenericComponent,
    PaymentsAdministratorComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TableModule,
    ModalModule,
    AppRoutingModule,
    SharedModule,
    ChartsModule,
    FormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    NgxMaskModule.forRoot(),
    MassiveChargeModule,
    ScrollingModule
    // RouterModule.forRoot([])
  ],
  exports: [
    HeaderComponent,
    AdministrationComponent,
    MyPaymentsComponent,
    TabBillToPayComponent,
    TabBillHistoryComponent,
    ModalPdfComponent,
    DocumentsComponent,
    ModalLogoutComponent,
    EmptySearchComponent,
    ProfileComponent,
    ChargeProfileImgComponent,
    BackupsSearchComponent,
    BackupsTabsComponent,
    BackupsMassiveTransactionComponent,
    BackupsMassiveTransactionStatusComponent,
    DownloadUploadExcelComponent,
    MassiveExonerationComponent,
    MassiveExonerationStatusComponent,
    MassiveNopayComponent,
    MassiveNopayStatusComponent,
    OperationsTabsComponent,
    TabIndividualOperationComponent,
    TabMassiveOperationComponent,
    TooltipIconComponent,
    OperationsComponent,
    AcceptTermsComponent,
    AddressInputComponent,
    CommercialReferenceComponent,
    ContactChannelsComponent,
    DisplayLegalPersonComponent,
    DisplayNaturalPersonComponent,
    OwnerInformationFormComponent,
    ClientStudyComponent,
    BackupsReportTableComponent,
    SearchAutocompleteAffiliateComponent,
    MarkNonPaymentComponent,
    NonPaymentTableComponent,
    BackupsTransactionsComponent,
    QuotaInfoComponent,
    QuotaBuyerInfoComponent,
    HolderRelatedValidationsComponent,
    SummaryCreditBureausComponent,
    LocationResultContactComponent,
    ProjectsEjecutionComponent,
    MainSuppliersComponent,
    MainCentralsComponent,
    ReferenceQuotaComponent,
    DescriptionRequestComponent,
    CurrentExhibitionComponent,
    MacrosComponent,
    QuotaRadicationComponent,
    SettleRequestComponent,
    MyRequestsComponent,
    DownloadBackingsToProcessComponent,
    SearchAffiliateComponent,
    CurrentExhibitionsComponent,
    QuotaCreateComponent,
    RequestAdminComponent,
    DeductibleComponent,
    CvTableComponent,
    ExpressReportComponent,
    ExcessRiskComponent,
    DeductibleComponent,
    InvoicesStatusComponent,
    ReportAffiliateComponent,
    ReportBuyerComponent,
    AffiliateBuyerComponent,
  ],
})
export class PagesModule {}
