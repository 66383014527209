import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  backupsAuditTabs: any[] = [
    'Fecha',
    'Estado previo',
    'Estado',
    'Usuario',
    'Valor de reintegro previo',
    'Valor de reintegro',
  ]

  quotaPromisoryNotes: any[] = [
    'Nombre comprador',
    'Documento comprador',
    'Existe comprador',
    'Nombre codeudor',
    'Documento codeudor',
    "Existe codeudor",
    'Fecha de firma',
    'Fecha proceso de cargue',
    'Tipo de pagaré',
    'Estado',
  ]

  quotaSearchPromisoryNotes: any[] = [
    'Rol',
    'Tipo de documento',
    'Documento',
    'Nombre',
    'Fecha firma',
  ]

  massiveChanteStatusTabs: any[] = [
    'Numero siniestro',
    'Nuevo estado',
    'Mensaje',
  ]

  returnedBackingsTabs: any[] = [
    'CV',
    'Título valor',
    'Documento Afiliado',
    'Nombre Afiliado',
    'Documento comprador',
    'Nombre comprador',
    'Contrato',
    'Valor de reembolso',
    'Valor reclamado',
    'Valor recuperado',
    'Fecha consulta',
    'Fecha de vencimiento',
    'Fecha radicación',
    'Estado',
    'Causal devolución',
    'Usuario radicación',
    'Usuario devolución',
    'Producto',
    'Oficina',
  ]

  public backupsTabsRestringed: any[] = [
    {
      href: '#title-value',
      // description: 'Títulos valor',
      description: 'Gestionar respaldos',
      icon: 'icon-tab-massive.svg',
      key: 'title-value'
    }
  ]

  public backupsTabsRestringedSac: any[] = [
    {
      href: '#claims',
      description: 'Documentos',
      icon: 'icon-tab-reserves.svg',
      key: 'claims'
    },
  ]
  public backupsTabs: any[] = [
    {
      href: '#settlement-table',
      description: 'Radicación',
      icon: 'icon-tab-individual.svg',
      key: 'settlement-table'
    },
    {
      href: '#title-value',
      // description: 'Títulos valor',
      description: 'Gestionar respaldos',
      icon: 'icon-tab-massive.svg',
      key: 'title-value'
    },
    {
      href: '#refunds-table',
      description: 'Reintegros',
      icon: 'icon-tab-reserves.svg',
      key: 'refunds-table'
    },
    {
      href: '#payments-table',
      description: 'Gestionar pagos',
      icon: 'icon-tab-reserves.svg',
      key: 'payments-table'
    },
    {
      href: '#returned-backings',
      description: 'Respaldos devueltos',
      icon: 'icon-tab-reserves.svg',
      key: 'returned-backings'
    },
    {
      href: '#claims',
      description: 'Documentos',
      icon: 'icon-tab-reserves.svg',
      key: 'claims'
    },
    {
      href: '#income-recovery',
      description: 'Ingreso Recuperación',
      icon: 'icon-tab-reserves.svg',
      key: 'income-recovery'
    }
    // {
    //   href: '#historical',
    //   description: 'Hístoricos',
    //   icon: 'icon-tab-reserves.svg',
    // },
  ];

  public quotasTabs: any[] = [
    {
      href: '#quota',
      description: 'Diligenciar',
      icon: 'icon-tab-individual.svg',
      key: 'quota'
    },
    {
      href: '#get-quota',
      description: 'Consultar Formulario 20M',
      icon: 'icon-tab-individual.svg',
      key: 'get-quota'
    },
    /* {
      href: '#gestion-solicitud',
      description: 'Gestión Solicitudes',
      icon: 'icon-tab-individual.svg',
      key: 'gestion-solicitud'
    } */
  ];

  public adminQuotaTabs: any[] = [
    {
      href: '#quotaVsAffiliate',
      description: 'Historial de cupos del comprador',
      icon: 'icon-tab-individual.svg',
      key: 'quota-vs-affiliate'
    },
    {
      href: '#activateQuota',
      description: 'Activación de cupos',
      icon: 'icon-tab-individual.svg',
      key: 'activate-quota'
    },
    {
      href: '#quotaWithoutContract',
      description: 'Cupos sin contrato',
      icon: 'icon-tab-individual.svg',
      key: 'quota-without-contract'
    },
    {
      href: '#reserves',
      description: 'Reservas',
      icon: 'icon-tab-individual.svg',
      key: 'reserves'
    },
  ];

  public blocksUnlocksTabs: any[] = [
    {
      href: '#block-unlock-buyer',
      description: 'Bloquear/Desbloquear comprador',
      icon: 'icon-tab-individual.svg',
      key: 'block-unlock-buyer'
    },
    {
      href: '#autex-key-gen',
      description: 'Generación de claves AUTEX',
      icon: 'icon-tab-individual.svg',
      key: 'autex-key-gen'
    },
    {
      href: '#block-account',
      description: 'Bloquear/Desbloquear cuentas',
      icon: 'icon-tab-individual.svg',
      key: 'block-account'
    },
    {
      href: '#block-telphone',
      description: 'Bloquear teléfonos',
      icon: 'icon-tab-individual.svg',
      key: 'block-telphone'
    },
    {
      href: '#transaction-list-by-authorization',
      description: 'Consulta de transacción',
      icon: 'icon-tab-individual.svg',
      key: 'transaction-list-by-authorization'
    },
  ];

  public transactionsReportsTabs: any[] = [
    {
      href: '#three-and-six-payments',
      description: 'Tres y Seis Pagos',
      icon: 'icon-tab-individual.svg',
      key: 'three-and-six-payments'
    },
  ];

  public modifyTitlesTabs: any[] = [
    {
      href: '#modify',
      description: 'Modificación de Titulos',
      icon: 'icon-tab-individual.svg',
      key: 'modify'
    },
    {
      href: '#reverse-exonerations',
      description: 'Reversar Exoneraciones',
      icon: 'icon-tab-individual.svg',
      key: 'reverse-exonerations'
    },
  ]

  constructor() { }


}
