import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductsStoreService } from '@app/pages/products/services/products-store.service';
import { BagResponse, BagResponseModel } from '@app/pages/products/models/response/bag.model';
import { ProductsService } from '@app/services/products.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { BagRequest, BagUpdateRequestModel } from '@app/pages/products/models/request/bag.model';

@Component({
  selector: 'app-modal-bag',
  templateUrl: './modal-bag.component.html',
  styleUrls: ['./modal-bag.component.scss']
})
export class ModalBagComponent implements OnInit, OnDestroy {

  @ViewChild('formBag') formBag: NgForm;
  public counterRequest: number = 0;
  public idProduct: number;
  public setStateBagRequest: BagUpdateRequestModel = new BagUpdateRequestModel();
  public stateBag$: Observable<BagResponseModel> = this.productsStore.statebag$;
  public stateIdProduct$: Observable<number> = this.productsStore.stateIdProduct$;
  public allowBags$: Observable<boolean> = this.productsStore.allowBags$;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();


  constructor(public modal: ModalService, public productsStore: ProductsStoreService, public helper: HelperService,
    private productsService: ProductsService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.stateBag$.pipe(takeUntil(this.unsubscribe$)).subscribe((bag: BagResponseModel) => this.handleInitForm(bag));
    this.stateIdProduct$.pipe(takeUntil(this.unsubscribe$)).subscribe((idProduct: number) => this.idProduct = idProduct);
    //this.helper.markForm(this.formBag, true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleSubmit(): void {
    this.setStateBagRequest.productBag = this.idProduct;
    if (this.modal.payload && this.modal.payload.action) {
      switch (this.modal.payload['action']) {
        case 'add':
          this.addBag();
          return;
        case 'edit':
          this.updateBag();
          return;
      }
    }
  }

  handleInitForm(bag: BagResponseModel): void {
    switch (this.modal.payload['action']) {
      case 'add':
       this.setStateBagRequest = new BagUpdateRequestModel();
        return;
      case 'edit':
        this.setStateBagRequest = bag;
        return;
    }
  }

  addBag() {
    if (!this.validateConfiguration()) return;
    this.spinner.show();
    this.counterRequest++;
    this.productsService.createBag(this.setStateBagRequest as any).subscribe(
      (createBagResponse: BagResponse) => {
        this.handleAddBagResponse(createBagResponse);
        console.log('setCreateBag ->', createBagResponse);
      }, (error: any) => {
        this.helper.handleErrorHttp(error, 'Productos');
      }
    );
  }

  handleAddBagResponse(createBagResponse: BagResponse) {
    if (createBagResponse && createBagResponse.ok === false) {
      this.helper.handleErrorHttp({}, 'Productos');
      return;
    }
    this.closeModal();
    Swal.fire({
      type: 'success',
      title: `Bolsa agregada con éxito`,
      text: `${createBagResponse['message']}`
    });

    this.productsStore.setIdProduct(this.idProduct);

    this.spinner.hide();
  }

  updateBag() {
    this.spinner.show();
    this.counterRequest++;
    this.productsService.updateBag(this.setStateBagRequest as any).subscribe(
      (updateBagResponse: BagResponse) => {
        this.handleUpdatebagResponse(updateBagResponse);
        console.log('setUpdateBag ->', updateBagResponse);
      }, (error: any) => {
        this.helper.handleErrorHttp(error, 'Productos');
      }
    );
  }

  handleUpdatebagResponse(updateBagResponse: BagResponse) {
    if (updateBagResponse && updateBagResponse.ok === false) {
      this.helper.handleErrorHttp({}, 'Productos');
      return;
    }
    this.closeModal();
    Swal.fire({
      type: 'success',
      title: `Bolsa actualizada con éxito`,
      text: `${updateBagResponse['message']}`
    });

    this.productsStore.setIdProduct(this.idProduct);
    this.helper.handlePristineForm(this.formBag);

    this.spinner.hide();
  }

  validateConfiguration(): boolean {
    console.log('validateConfiguration ->', this.setStateBagRequest);
    const { bondMonth, minimumMonthlyBilling, semiannualBilling } = this.setStateBagRequest;
    const validateArr = [bondMonth, minimumMonthlyBilling, semiannualBilling];
    // console.log('validateArr ->', validateArr);
    if (validateArr.some(item => Number(item) > 0)) {
      return true;
    } else {
      Swal.fire({
        type: 'error',
        title: `Configuración inválida`,
        text: `Alguno de los campos: Fianza mensual, facturación mínima mensual, facturación semestral debe ser diferente de 0`
      });
      return false;
    }
  }

  calculatePaymentValue(setStateBagRequest: BagResponseModel) {
    const { bondValue, serviceRate } = setStateBagRequest;
    this.setStateBagRequest.paymentValue = this.helper.calculatePercentage((Number(bondValue) || 0), (Number(serviceRate) || 0));
    console.log('calculatePaymentValue **** ->', this.setStateBagRequest.paymentValue);
  }

  closeModal() {
    this.productsStore.setSelectedRowBag(null);
    this.modal.close();
  }
}