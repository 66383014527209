import { Component, OnInit } from '@angular/core';
import { CreditNote, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-notes-history',
  templateUrl: './notes-history.component.html',
  styleUrls: ['./notes-history.component.scss']
})
export class NotesHistoryComponent implements OnInit {

  public headersTable: string[] = ['Fecha', 'Descripción'];
  public notes: CreditNote[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.notes = this.subjectCreditHistory.getNotesFromCreditHistory(response);
  }

}
