import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-recognize-basic-info-table',
  templateUrl: './recognize-basic-info-table.component.html',
  styleUrls: ['./recognize-basic-info-table.component.scss']
})
export class RecognizeBasicInfoTableComponent implements OnInit {

  @Input() dataTable: any;

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
  }

}
