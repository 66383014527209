import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './password/password.component';
import { AuthComponent } from './auth.component';
import { AuthGuard } from '../guards/auth.guard';


export const routes: Routes = [
 { path: '',
   component: AuthComponent,
   children: [
    { path: 'login', component: LoginComponent },
    { path: 'contrasena', component: PasswordComponent, canActivate: [AuthGuard], },
    { path: '**', pathMatch: 'full', redirectTo:  '/login' },
   ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }
