import { Component, Input, OnInit } from '@angular/core';
// import { idMacro } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-observations-macro',
  templateUrl: './observations-macro.component.html',
  styleUrls: ['./observations-macro.component.scss']
})
export class ObservationsMacroComponent implements OnInit {
  
  @Input() quotaData: any

  constructor() { }

  ngOnInit() { }

}
