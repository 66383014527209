import { Component, OnInit } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { RolesService } from '@app/services/roles.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-backups-audit',
  templateUrl: './backups-audit.component.html',
  styleUrls: ['./backups-audit.component.scss']
})
export class BackupsAuditComponent implements OnInit {

  public navItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  public totalAccessActionBackingsSac = true;
  public isLoadingPermissions=true;
  public message = '';
  public userName: any;

  data: string;

  constructor(private tabs: TabsService, public helper: HelperService, private backupsService: BackupsService,
     private spinner: NgxSpinnerService, private roles: RolesService) {
    this.navItemTabs = this.tabs.backupsAuditTabs;

  }

  ngOnInit() {
    this.getProfile();
  }

  search(currentPage?: any) {
    console.log(this.data);

    this.spinner.show();

    const body: any = {
      currentPage: currentPage,
      perPage: this.perPage,
      filingNumber: this.data,
    }

    this.backupsService.backupsAudit(body).subscribe(
      (res: any) => {
        console.log(res);
        this.totalPages = res.totalPages;
        this.spinner.hide();
        /* if (res.status != 200) this.helper.badResponse(res.message, res.status); */
        this.notices = res.data;
        console.log(res.data);
      },
      (error) => {
        this.spinner.hide();
        this.helper.handleErrorHttp(error, '')
      }
    );
  }

  goToPage($event: any){
    this.search($event.currentPage)
  }
  getProfile() {
    this.userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'backing'
          );
          if (permissions && permissions.actions) {
            this.handleBackingsTab(permissions);
          }
          this.isLoadingPermissions=false;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleBackingsTab(permissions: any) {
    if (permissions.actions['view-document'] === true) {
      this.totalAccessActionBackingsSac = false;
    }
  }

}
