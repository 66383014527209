export class TransactionsToBackupResponse {
  data: TransactionsToBackUpResponseModel[];
  ok: boolean;
  currentPage: number;
  totalPages: number;
}
export class TransactionsToBackUpResponseModel {
  // Fase 2 fm ok
  affiliateDocumentType: string;
  affiliateDocument: string;
  affiliateName: string;
  issuerDocumentType: string;
  issuerDocument: string;
  queryCode: number;
  branchOffice: string;
  issuerName: string;
  nonPaymentNoticeDate: string;
  nonPaymentNoticeDays: number;
  dueDate: string;
  backingValue: number;
  limitBackingDate: string;
  value: number;
  guaranteedValue: number;
  draftNumber: string;
  authorization: number;
  claimDays: number;
  statusBacking: string;
  sinisterProduct: string = "";
  contract: number = 0;
  draftDate: string = "";
  draftsNumber: number = 0;
  contractProduct: string = "";
  bankCode: number = 0;
  account: string = "";
}
