import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '../../../../services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { BackupsService } from '../../../../services/backups.service';
import { BackupsTransactionsRequest } from '@app/pages/backups/models/request/BackupsTransactions.model';
import { BackupsTransactionsResponse } from '../../models/response/BackupsTransactionsResponse.model';

@Component({
  selector: 'app-download-transactions',
  templateUrl: './download-transactions.component.html',
  styleUrls: ['./download-transactions.component.scss']
})
export class DownloadTransactionsComponent implements OnInit {

  @Input() type: string;
  @Input() data: any;
  @Input() contract: any;
  @Input() disabled: true;

  public counterRequest: number = 0;
  @Input() search: BackupsTransactionsRequest  = new BackupsTransactionsRequest();


  constructor(private spinner: NgxSpinnerService, private backups: BackupsService,
    private toastr: ToastrService, public helper: HelperService) { }

  ngOnInit() {
  }

  downloadExcel(): void {
    this.spinner.show();
    this.search.download = true;
    this.backups.downloadTransactions(this.search).subscribe(
      (transactionsFileDownload: BackupsTransactionsResponse) => {
        if (transactionsFileDownload && transactionsFileDownload.ok === false) {
          this.handleDownloadPaymentManagementError();
          return;
        }
        const excelFileBase64 = transactionsFileDownload['file'];
        console.log(excelFileBase64);
        
        if (excelFileBase64) {
          this.helper.downloadFromBase64(excelFileBase64, 'transacciones', '.xlsx');
        }

        this.spinner.hide();
        // console.log('transactionsDownload ->', transactionsFileDownload);
      }, (error: any) => {
        this.handleDownloadPaymentManagementError(error);
      }
    );
  }

  handleDownloadPaymentManagementError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Descargar excel', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.spinner.hide();
  }

}

