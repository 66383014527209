import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from "file-saver";
import Swal from 'sweetalert2';
import { ModalService } from '@app/services/modal.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-massive-change-status',
  templateUrl: './massive-change-status.component.html',
  styleUrls: ['./massive-change-status.component.scss']
})
export class MassiveChangeStatusComponent implements OnInit {

  @ViewChild('formPromisoryNotes') formPromisoryNotes: NgForm;

  public navItemTabs: any[] = [];
  public searchNavItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  historicPerpage: number = 10;
  historicCurrentPage: number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  docTypes: any;
  public file: File;
  public stringFile: string;
  public isSearch: boolean = false;
  public fileName: string = '';
  public isValidData: boolean = false;
  public totalAccessActionBackingsSac = true;
  public isLoadingPermissions=true;
  public message = '';
  public userName: any;

  public bodyFormPromisoryNotes = {
    documentType: '',
    document: '',
  }

  //public isSearch: boolean = false;

  buyer: string = '';

  data: any = [];

  constructor(private tabs: TabsService, public helper: HelperService, private backups: BackupsService,
     private spinner: NgxSpinnerService, private listCovinoc: ListsCovinocService, private modal: ModalService, private roles: RolesService) {
      this.searchNavItemTabs = this.tabs.massiveChanteStatusTabs
  }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
    });
    this.getProfile();
  }

  logDocument() {
    console.log(this.bodyFormPromisoryNotes.document);

  }

  downloadTemplate() {
    this.spinner.show();
    this.backups.getTemplate().subscribe((response: any) => {
      console.log(response);

      this.spinner.hide();
      this.helper.downloadFromBase64(response.data, 'cambio_masivo_plantilla', '.xlsx');
      //this.seleccionArchivo(response.data[0]);
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  seleccionArchivo(archivo: File) {
    console.log(archivo.name);
    this.fileName = archivo.name;
    this.file = archivo;
    this.toBase64(this.file).then((response: any) => {
      this.stringFile = response.split(',')[1];
    })
  }

  toBase64(file: File) {

    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = error => reject(error);

    });
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes")
  }

  previewFile() {
    this.spinner.show();
    this.isValidData = true;
    const body = {
      file: this.stringFile
    }
    this.backups.validateMassiveChangeStatus(body).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.data = response.data;
      this.verifyData();

      this.isSearch = true;
      this.totalPages = response.totalPages;
    },(error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  verifyData() {
    this.data.forEach(item => {
      if(item.ok === false) {
        this.isValidData = false;
      }
    });
    if(this.isValidData === false) {
      Swal.fire({
        title: `Tiene errores en su información`,
        text: `Por favor corrijalos y vuelva a subir el archivo.`,
        type: 'info',
      });
    }
  }



  /* onGetHistoricReport() {
    this.spinner.show();
    const params =  {
      currentPage: this.historicCurrentPage,
      perPage: this.historicPerpage
    }
    this.quotaGreater.historicReport(params).subscribe((response: any) => {
      console.log(response);
      this.historicCurrentPage = response.currentPage;
      this.totalPages = response.totalPages
      this.spinner.hide();
      this.data = response.data[0];
      this.isSearch = false;
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  } */

  saveAll() {
    this.spinner.show();
    this.backups.massiveChangeStatus(this.data).subscribe((response: any) => {
      this.spinner.hide();
      this.data = [];
      this.fileName = '';
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  /* goToPage($event: any){
    this.search($event.currentPage)
  } */

  getProfile() {
    this.userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'backing'
          );
          if (permissions && permissions.actions) {
            this.handleBackingsTab(permissions);
          }
          this.isLoadingPermissions=false;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleBackingsTab(permissions: any) {
    if (permissions.actions['view-document'] === true) {
      this.totalAccessActionBackingsSac = false;
    }
  }

}
