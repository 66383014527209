import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
} from "@angular/core";
import { PaginationComponent } from "@app/components/layout/pagination/pagination.component";
import { SelectsService } from "@app/services/selects.service";
import { Subject, Subscription } from "rxjs";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { BackupsService } from "@app/services/backups.service";
import { takeUntil } from "rxjs/operators";
import { SelectModel } from "@app/models/select.model";
import { HelperService } from "@app/services/helper.service";
import Swal from "sweetalert2";

import { ModalService } from "@app/services/modal.service";
import { ReportRequest } from "@app/pages/backups/models/request/Report.model";
import {
  ReportResponse,
  ReportResponseModel,
} from "@app/pages/backups/models/response/Report.model";
import { BackupsTableHeadersService } from "@app/pages/backups/services/backups-table-headers.service";
import { ExpressReportService } from "@app/services/express-report.service";
import {
  ExpressReport,
  FilterUploadExpressReportModel,
} from "@app/pages/backups/models/response/ExpressReport";
import { OptionsExpressReport } from "@app/models/express.models";
import { AuthService } from "@app/services/auth.service";

interface Report {
  status: string;
}
@Component({
  selector: "app-modal-express-report",
  templateUrl: "./modal-express-report.component.html",
  styleUrls: ["./modal-express-report.component.scss"],
})
export class ModalExpressReportComponent implements OnInit, OnDestroy {
  @ViewChild("paginationComponent") paginationComponent: PaginationComponent;
  @ViewChild("bodyTable") bodyTable: ElementRef;
  @Input() reportExpressResponse: Array<ExpressReport>;

  report: Report = {
    status: "",
  };

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public search: ReportRequest = new ReportRequest();
  public data: ReportResponseModel[] = [];
  public optionsFilters = OptionsExpressReport;
  public filter = "1";
  public Document: string = "";
  public issuerDocument: string = "";
  public affiliateName: string = "";
  public reportResponse: ReportResponseModel[] = [];
  public filterUploadResponse: FilterUploadExpressReportModel[] = [];
  public dataAprovedResponse: FilterUploadExpressReportModel[] = [];
  public dataResponseAproved: FilterUploadExpressReportModel[] = [];
  public dataResponseNegative: FilterUploadExpressReportModel[] = [];
  public selectOptionsBranchOffices: SelectModel[] = [];
  public selectedOptionFilterModal: string = "";
  public subs1: Subscription;
  public selectOptions: SelectModel[] = [];
  public branchOffices$ = this.selects.getSelectTypes("branchOffices");
  public resp: any;
  public showCard: boolean = false;
  public initDateInput: Date;
  public buttonProcess: boolean = false;
  public isLoading: boolean = true;
  public radicationNumber: number;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public selects: SelectsService,
    private spinner: NgxSpinnerService,
    public backupsService: BackupsService,
    public helper: HelperService,
    public tableHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public filterUploadExpressReport: ExpressReportService,
    public approveUploadExpress: ExpressReportService,
    public deniedUploadExpress: ExpressReportService,
    public authService: AuthService,
    public expressReport: ExpressReportService,
    public validatePending: ExpressReportService
    
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.reset();
  }

  goToPage({ currentPage }): void {
    this.currentPage = currentPage;
    this.filterUpload();
  }

  handleGetReportError(error?: any): void {
    console.error(error);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title: "Reporte express",
        text: `${message}`,
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.reportResponse = [];
    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination(): void {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }
  closeModal() {
    this.reset();
    this.modal.close();
  }

  searchNameAffiliate() {
    this.data = this.data.filter(
      (item) => item.affiliateName === this.affiliateName
    );
  }

  searchDocument() {
    this.data = this.data.filter(
      (item) => item.issuerDocument === this.issuerDocument
    );
  }

  filterUpload() {
    this.dataAprovedResponse = [];
    this.spinner.show();
    const parametersModalExpressReport = {
      uploadRadication: this.modal.payload.uploadRadication,
      contract: this.modal.payload.contract, // Contrato ejemplo: 523559
      uploadFilter: this.selectedOptionFilterModal, //estados: resumen, aprobado, pendiente */
      currentPage: this.currentPage,
      perPage: 10,
    };
    this.currentPage = 1;

    this.filterUploadExpressReport
      .filterUpload(parametersModalExpressReport)
      .subscribe(
        (filterUploadResponse: FilterUploadExpressReportModel) => {
          if (!filterUploadResponse) {
            this.handleGetReportError();
            return;
          }
          this.filterUploadResponse = filterUploadResponse["data"];
          this.cantPages = filterUploadResponse["totalPages"];
          if (this.filterUploadResponse.length <= 0) {
            Swal.fire({
              type: "success",
              title: "Reporte express",
              text: `No hay registros en el estado ${this.selectedOptionFilterModal}`,
            });

            this.spinner.hide();
          }
          this.spinner.hide();
        },
        (error: any) => {
          this.handleGetReportError(error);
        }
      );
    this.filterUploadResponse = [];
  }
  reset() {
    this.filterUploadResponse = [];
    this.cantPages = 0;
    this.selectedOptionFilterModal = "";
  }

  validatePendingDate() {
    this.validatePending
      .validatePending(this.radicationNumber)
      .subscribe((resp: any) => {
        console.log("Respuesta", resp)
      });
  }

  approveUpload() {
    this.spinner.show();
    if (this.dataResponseAproved.length > 0) {
      for (let i = 0; i < this.dataResponseAproved.length; i++) {
        const parameters = {
          documentType: this.dataResponseAproved[i].typeDocBuyer,
          document: this.dataResponseAproved[i].documentBuyer,
          uploadRadication: this.dataResponseAproved[i].uploadRadication,
          record: this.dataResponseAproved[i].record,
          nonPaymentNotice: 0,
          numberCvs: this.dataResponseAproved[i].numberCvs,
          numberChq: this.dataResponseAproved[i].numberChq,
          balance: this.dataResponseAproved[i].currentBalance,
          guaranteedValue: this.dataResponseAproved[i].guaranteedValue,
          spinnerType: this.dataResponseAproved[i].spinnerType,
          documentState: 0,
          clintonList: this.dataResponseAproved[i].clintonList,
          otherObligation:
            this.dataResponseAproved[i].obligationOtherPortfolios,
          contract: this.dataResponseAproved[i].contract,
          user: this.authService.getUserInfo().user,
          uploadDate: this.dataResponseAproved[i].uploadDate,
        };
        this.approveUploadExpress
          .approveUpload(
            parameters,
            this.dataResponseAproved[i].status || "aprobarCupo"
          )
          .subscribe(
            (resp: any) => {
              if (!resp) {
                this.handleGetReportError();
                return;
              }
              Swal.fire({
                type: "success",
                title: "Reporte express",
                text: "Registro procesado",
              });
              this.spinner.hide();
            },
            (error: any) => {
              this.handleGetReportError(error);
            }
          );
          this.radicationNumber=this.dataResponseAproved[i].uploadRadication;
      }
      this.validatePendingDate();
    } else {
      Swal.fire({
        type: "info",
        title: "Reporte express",
        text: "No hay registros seleccionados",
      });
      this.spinner.hide();
    }
  }

  deniedUpload() {
    this.spinner.show();
    if (this.dataResponseNegative.length > 0) {
      for (let i = 0; i < this.dataResponseNegative.length; i++) {
        const parameters = {
          documentType: this.dataResponseNegative[i].typeDocBuyer,
          document: this.dataResponseNegative[i].documentBuyer,
          uploadRadication: this.dataResponseNegative[i].uploadRadication,
          record: this.dataResponseNegative[i].record,
          numberChq: this.dataResponseNegative[i].numberChq,
          guaranteedValue: this.dataResponseNegative[i].guaranteedValue,
          contract: this.dataResponseNegative[i].contract,
          user: this.authService.getUserInfo().user,
          uploadDate: this.dataResponseNegative[i].uploadDate,
        };

        this.deniedUploadExpress.deniedUpload(parameters).subscribe(
          (resp: any) => {
            if (!resp) {
              this.handleGetReportError();
              return;
            }
            Swal.fire({
              type: "success",
              title: "Reporte express",
              text: "Registro procesado",
            });
            this.spinner.hide();
          },
          (error: any) => {
            this.handleGetReportError(error);
          }
        );
      }
      this.validatePendingDate();
    } else {
      Swal.fire({
        type: "info",
        title: "Reporte express",
        text: "No hay registros seleccionados",
      });
      this.spinner.hide();
    }
  }

  onBlur(): void {
    this.showCard = false;
  }

  changeCheck() {
    this.dataAprovedResponse = this.filterUploadResponse.filter(
      (ele) => ele.check === true
    );
  }

  changeAprovedLocked() {
    this.dataResponseAproved = this.dataAprovedResponse.filter(
      (register) =>
        register.status === "aprobar" ||
        register.status === "desbloquear" ||
        register.status === "autext"
    );
    this.dataResponseNegative = this.dataAprovedResponse.filter(
      (register) => register.status === "negar"
    );
  }

  changeAprovedInsufficientQuota() {
    this.dataResponseAproved = this.dataAprovedResponse.filter(
      (register) => register.status === "aprobar"
    );
    this.dataResponseNegative = this.dataAprovedResponse.filter(
      (register) => register.status === "negar"
    );
  }

  /*  actualizarEstado(){
    const indice = 0;
    this.dataAprovedResponse[indice].status=this.report.status;
  } */

  processData() {
    if (this.dataResponseAproved.length > 0) {
      this.approveUpload();
    }
    if (this.dataResponseNegative.length > 0) {
      this.deniedUpload();
    }
    this.buttonProcess = false;
    /*    this.filterUploadResponse = [];
    this.filterUpload(); */
  }
  /*   processDataQuota() {
    if (this.dataAprovedResponse.length > 0) {
      this.approveUploadQuota();
    }
    if (this.dataAprovedResponse.length > 0) {
      this.deniedUploadQuota();
    }
    this.buttonProcess=false;
    
  } */
}
