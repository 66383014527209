import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-backups-search',
  templateUrl: './backups-search.component.html',
  styleUrls: ['./backups-search.component.scss']
})
export class BackupsSearchComponent implements OnInit {

  constructor(public modal: ModalService, public helper: HelperService) { }

  ngOnInit() {
  }

}
