import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { NgForm } from "@angular/forms";
import Swal from "sweetalert2";

import { RadicationQuotaAdminService } from "@app/services/radication-quota-admin.service";
import { ResponseObject } from "../../../../models/responseUtil.model";
import { CreateProfileRequest } from "../../models/request/CreateProfileRequest.modal";
import {
  RequestStatus as requestStatusModal,
  RequestType as requestTypeModal,
} from "../../models/RequestTypeAndStatus.model";
import { RequestStatus, RequestType } from "../../models/requestTypeStatus";
import { ProfileCurrent } from "../../models/response/responseProfileRequest";
import { RequestTypeAndStatus } from "../../models/response/responseStatusTypeRequest";
import { RadicationService } from "../../services/radication-quota.service";

@Component({
  selector: "app-create-edit-profile",
  templateUrl: "./create-edit-profile.component.html",
  styleUrls: ["./create-edit-profile.component.scss"],
})
export class CreateEditProfileComponent implements OnInit {
  @Input() isTab: string = "";
  @Output() setTab = new EventEmitter<string>();

  @Input() isEdit: boolean = false;
  @Output() setEdit = new EventEmitter<boolean>();

  @Input() isView: boolean = false;
  @Output() setView = new EventEmitter<boolean>();

  public navItemTabs: any[] = [];

  public filterPost = "";
  public filterPostStatus = "";

  showButton = true;

  public requestType: Array<RequestType> = [];
  public requestStatus: Array<RequestStatus> = [];

  public createProfile: CreateProfileRequest;

  @ViewChild("formCreate") formCreate: NgForm;

  constructor(
    private radicationServiceAdmin: RadicationQuotaAdminService,
    public radicationService: RadicationService
  ) {
    this.navItemTabs = this.radicationService.radicationTabsAdmin;
  }

  ngOnInit() {
    this.createProfile = this.radicationServiceAdmin.createProfileRequest;
    this.createProfile.profile.profileName = ""; //here
    this.createProfile.profile.idProfile = null;
    this.radicationServiceAdmin.getStatusTypeRequest().subscribe(
      (response: ResponseObject<RequestTypeAndStatus>) => {
        this.requestStatus = response.data.requestStatus;
        this.requestType = response.data.requestType;
        this.validationData();
      },
      (error) => {
        this.requestType = [];
        this.requestStatus = [];
        console.error(error.message);
        this.showButton = true;
      }
    );
  }

  validationData() {
    let editCurrent: ProfileCurrent = JSON.parse(
      localStorage.getItem("editProfile")
    );
    if (editCurrent == null) {
      return this.createProfile;
    }
    this.createProfile.profile = {
      profileName: editCurrent ? editCurrent.name : "",
      idProfile: editCurrent ? editCurrent.id : null,
      isActive: "1",
    };

    if (editCurrent.requestStatus != null && editCurrent.requestType != null) {
      this.createProfile.requestStatus = editCurrent.requestStatus;
      this.createProfile.requestType = editCurrent.requestType;
    }

    this.requestStatus.map((element) => {
      let data = this.createProfile.requestStatus.find(
        (data) =>
          data.idReqStatus == element.idReqStatus && data.isActive == "1"
      );
      if (data == null || data == undefined) {
        element.isActive = "0";
      } else {
        element.isActive = "1";
      }
    });

    this.requestType.map((element) => {
      let data = this.createProfile.requestType.find(
        (data) => data.requestType == element.idReqType && data.isActive == "1"
      );
      if (data == null || data == undefined) {
        element.isActive = "0";
      } else {
        element.isActive = "1";
      }
    });
  }

  checkAllStatus(event) {
    let isCheck = event.target.checked;
    this.requestStatus.forEach((element) => {
      element.isActive = (isCheck === true) ? "1" : "0";
      if(isCheck || this.isEdit){
        this.checkProfileStatus(element, event);
      }else{
        this.createProfile.requestStatus = [];
      }
    });
  }

  checkAllType(event) {
    let isCheck = event.target.checked;
    this.requestType.forEach((element) => {
      element.isActive = (isCheck === true) ? "1" : "0";
      if(isCheck || this.isEdit){
        this.checkProfileType(element, event);
      }else{
        this.createProfile.requestType = [];
      }
    });
    console.log(this.requestType);
  }

  checkProfileStatus(dataStatus: RequestStatus, event, i?: number) {
    let isCheck = event.target.checked;
    let profile = this.createProfile.requestStatus.find(
      (element) => element.idReqStatus == dataStatus.idReqStatus
    );
    if (i != null) {
      this.requestStatus[i].isActive = (isCheck === true) ? "1" : "0";
    }
    if (profile == null || profile == undefined) {
      let statusRequest: requestStatusModal = {
        id: null,
        idProfile: this.createProfile.profile.idProfile,
        idReqStatus: dataStatus.idReqStatus,
        isActive: (isCheck === true) ? "1" : "0",
      };
      this.createProfile.requestStatus.push(statusRequest);
    } else {
      this.createProfile.requestStatus.map((element) => {
        let data = this.createProfile.requestStatus.find(
          (data) => data.idReqStatus == element.idReqStatus
        );
        if (data.idReqStatus == dataStatus.idReqStatus) {
          element.isActive = (isCheck === true) ? "1" : "0";
        }
      });
    }
  }

  checkProfileType(dataType: RequestType, event, i?: number) {
    let isCheck = event.target.checked;
    let profile = this.createProfile.requestType.find(
      (data) => dataType.idReqType == data.requestType
    );
    if (i != null) {
      this.requestType[i].isActive = (isCheck === true) ? "1" : "0";
    }
    if (profile == null || profile == undefined) {
      let typeRequest: requestTypeModal = {
        id: null,
        profile: this.createProfile.profile.idProfile,
        requestType: dataType.idReqType,
        isActive: (isCheck === true) ? "1" : "0",
      };
      this.createProfile.requestType.push(typeRequest);
    } else {
      this.createProfile.requestType.map((element) => {
        let data = this.createProfile.requestType.find(
          (data) => data.requestType == element.requestType
        );
        if (data.requestType == dataType.idReqType) {
          element.isActive = (isCheck === true) ? "1" : "0";
        }
      });
    }
  }

  back() {
    this.setTab.emit(this.navItemTabs[0].key);
  }

  saveForm() {
    if (this.isView) {
      this.back();
      return;
    }

    if (this.isEdit) {
      Swal.fire({
        type: "info",
        title: ``,
        text: `¿Desea continuar con esta solicitud?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: `Confirmar`,
        allowOutsideClick: false,
      }).then((value) => {
        if (value.value === true) {
          this.saveData();
        }
      });
    } else {
      this.saveData();
    }
  }
  saveData() {
    this.radicationServiceAdmin
      .saveCreateNewProfile(this.createProfile)
      .subscribe(
        (response: ResponseObject<any>) => {
          Swal.fire({
            type: "success",
            title: `${response.message}`,
          }).then(() => {
            this.setTab.emit(this.navItemTabs[0].key);
          });
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: `${error.error.message}`,
          });
          localStorage.setItem(
            "ERROR_ADMIN",
            JSON.stringify(this.createProfile)
          );
        }
      );
  }
}
