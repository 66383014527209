import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { SpecialFactorAsrTemp } from "@app/models/specialFactorAsrTemp.model";
import { SpecialFactorAsrTempPK } from "@app/models/compositeKey/SpecialFactorAsrTempPK.model";
import { NgxSpinnerService } from "ngx-spinner";
import { ContractService } from "@app/services/contract.service";
import Swal from "sweetalert2";
import { FilterCodeSpecialContract } from "@app/models/request/filterCodeSpecialContract.model";
import { SelectModel } from "@app/models/select.model";
import { FormContractService } from "../services/form-contract.service";

@Component({
  selector: "app-special-conditions",
  templateUrl: "./special-conditions.component.html",
  styleUrls: ["./special-conditions.component.scss"],
})
export class SpecialConditionsComponent implements OnInit {
  public specialConditionsForm: FormGroup;

  @Input() access: boolean = false;

  state: string;
  numberContract = 0;

  listCodeSpecial: number[] = [4];

  //List's code special saved
  public listSpecialFactorAsrTemp: SpecialFactorAsrTemp[] = [];

  //List's number special current
  public listCodeSpecialCurrents: number[] = [];

  listApply: SelectModel[] = [];

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private formService: FormContractService
  ) {
    this.specialConditionsForm = this.fb.group({
      contract: [{ value: "", disabled: true }, Validators.required],
      specialCondition: [{ value: "", disabled: true }, Validators.required],
      apply: ["0", Validators.required],
    });
    this.formService.myNumberContracts$.subscribe((contract) => {
      this.numberContract = contract;
      this.f.contract.setValue(this.numberContract.toString());
      this.f.specialCondition.setValue("Días entre cheques");
      this.getSpecialSaved();
    });
  }

  ngOnInit() {
    let apply = JSON.parse(localStorage.getItem("applyDays"));
    this.listApply = apply ? apply : [];
    console.log("SPECIAL");
    this.formDisable();
    this.specialConditionsForm.disable();
    this.formDisableStep();
  }

  //get data for send the DB
  mapperService(): SpecialFactorAsrTemp[] {
    let valueForm = this.specialConditionsForm.value;
    let specialFactor: SpecialFactorAsrTemp[] = [];

    let specialFactorTemp: SpecialFactorAsrTemp = new SpecialFactorAsrTemp();
    let specialFactorAsrTempPK: SpecialFactorAsrTempPK =
      new SpecialFactorAsrTempPK();
    specialFactorAsrTempPK.idContract = this.numberContract;
    specialFactorAsrTempPK.specialCode = 4;
    specialFactorTemp.specialFactorAsrTempPK = specialFactorAsrTempPK;
    specialFactorTemp.factor = Number(valueForm.apply);
    specialFactor.push(specialFactorTemp);

    return specialFactor;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.specialConditionsForm.controls;
  }

  getSpecialSaved() {
    this.contractService.getAnotherYes(this.getCurrentCodeSpecial()).subscribe(
      (resp) => {
        //if list have data
        this.listSpecialFactorAsrTemp = resp.data[0];
        //execute setData of the form
        this.setForm();

        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  setForm() {
    let data = this.listSpecialFactorAsrTemp[0];
    this.f.apply.setValue(data.factor);
  }

  formDisable() {
    if (this.access) {
      this.f.apply.enable();
    } else {
      this.f.apply.disable();
    }
  }

  getCurrentCodeSpecial(): FilterCodeSpecialContract {
    this.listCodeSpecial.map((element) => {
      this.listCodeSpecialCurrents.push(element);
    });
    let filterCodeSpecialContract: FilterCodeSpecialContract = {
      codeSpecial: this.listCodeSpecialCurrents,
      numberContract: this.numberContract,
    };
    return filterCodeSpecialContract;
  }

  formDisableStep() {
    if (this.formService.currentPages >= 7) {
      this.specialConditionsForm.enable();
    }
  }

  onSubmit() {
    if (this.numberContract) {
      this.contractService.saveAnotherYes(this.mapperService()).subscribe(
        () => {
          Swal.fire({
            type: "success",
            title: "Transacción exitosa",
          });
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }
  }
}
