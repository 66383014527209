import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackupsService } from '../../services/backups.service';
import { HelperService } from '../../services/helper.service';
import Swal from 'sweetalert2';
import { PaymentsPostBackingResponse, PaymentsPostBackingResponseModel } from '../../pages/backups/models/response/PaymentsPostBacking.model';

import { BackupsStoreService } from '../../pages/backups/services/backups-store.service';
import { PaginationComponent } from '../../components/layout/pagination/pagination.component';
import { PaymentsPostBackingRequestModel } from '../../pages/backups/models/request/PaymentsPostBackings.model';

@Component({
  selector: 'app-modal-show-payments',
  templateUrl: './modal-show-payments.component.html',
  styleUrls: ['./modal-show-payments.component.scss']
})
export class ModalShowPaymentsComponent implements OnInit {

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public paymentsPostBacking: PaymentsPostBackingResponseModel[] = [];
  public paymentsPostBackingRequest: PaymentsPostBackingRequestModel = new PaymentsPostBackingRequestModel();
  public cantPages = 0;
  public currentPage = 1;
  public showMessage: boolean = false;


  constructor(public modal: ModalService, private spinner: NgxSpinnerService,
    private backups: BackupsService, public helper: HelperService, private backupsStore: BackupsStoreService) {

  }

  ngOnInit() {
    this.backupsStore.statePaymentsPostBackingRequestModel$.subscribe((statePayments: PaymentsPostBackingRequestModel) => {
      // console.log('statePayments', statePayments);
      this.paymentsPostBackingRequest = statePayments;
      const { affiliateDocument, affiliateDocumentType  } = statePayments;
      if(affiliateDocument && affiliateDocumentType){
        this.getPaymentsPostBacking(1);
      }
    });
  }

  closeModal() {
    this.modal.close();
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getPaymentsPostBacking(currentPage);
  }

  getPaymentsPostBacking(currentPage: number) {
    this.spinner.show();
    this.paymentsPostBackingRequest.currentPage = currentPage;
    this.backups.getPaymentsPostBacking(this.paymentsPostBackingRequest).subscribe(
      (payments: PaymentsPostBackingResponse) => {
        if (payments && payments.ok === false) {
          this.handleGetPaymentsPostBackingError();
          return;
        }
        this.paymentsPostBacking = payments['data'];
        this.cantPages = payments['totalPages'];
        this.spinner.hide();
      }, (error: any) => {
        this.handleGetPaymentsPostBackingError(error);
      }
    );
  }

  handleGetPaymentsPostBackingError(error?: any) {
    console.error(error);
    if (this.showMessage) {
      if (error && error.error && error.error.message) {
        const { message } = error.error;
        Swal.fire({
          type: 'error', title: 'Abonos', text: `${message}`, allowOutsideClick: false
        });
      } else {
        this.helper.handleUndefinedError();
      }
    }
    this.cantPages = 0;
    this.paymentsPostBacking = [];
    this.spinner.hide();
  }
}
