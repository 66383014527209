import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AffiliateLinkService } from 'src/app/services/affiliate-link.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  public dateRange: Array<any> = [];

  public imageData: Array<any> = [];

  constructor(
    private affiliate: AffiliateLinkService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public helper: HelperService,
    public select: SelectsService,
  ) {
  }

  ngOnInit() { }

  public onGetDateRange($event) {
    this.dateRange = $event;
    let params = {
      paramIni: moment(this.dateRange[0]).format("YYYY-MM-DD"),
      paramFin: moment(this.dateRange[1]).format("YYYY-MM-DD"),
    }
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      paramIni: moment(this.dateRange[0]).format("YYYY-MM-DD"),
      paramFin: moment(this.dateRange[1]).format("YYYY-MM-DD"),
    }

    this.affiliate.getDownloadQuota(params).subscribe((response: any) => {
      this.spinner.hide();
      this.imageData = response.data;
      if (this.imageData) {
        this.helper.downloadFromBase64(this.imageData[0], 'Informe_Solicitud_Cupos', '.xlsx');
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }
}
