import { Injectable } from "@angular/core";
import { GlobalBilling } from "@app/models/global-billing.models";
import { SelectModel } from "@app/models/select.model";
import { QueryCode } from "@app/pages/quotas/models/queryCode.model";

@Injectable({
  providedIn: "root",
})
export class RadicationService {
  globalBilingRecordedMemory: GlobalBilling[] = [];

  public radicationTabs: any[] = [
    {
      href: "#radication-request",
      description: "Radicar Solicitud",
      icon: "icon-tab-individual.svg",
      key: "radication-request",
    },
    {
      href: "#requests",
      description: "Gestión de Solicitudes",
      icon: "icon-tab-massive.svg",
      key: "requests",
    },
  ];

  public radicationTabsAdmin: any[] = [
    {
      href: "#profile",
      description: "Perfiles",
      icon: "icon-tab-individual.svg",
      key: "profile",
    },
    {
      href: "#edit-create-profile",
      description: "Crear Perfiles",
      icon: "icon-tab-massive.svg",
      key: "edit-create-profile",
    },
    {
      href: "#assignProfile",
      description: "Asignar perfil",
      icon: "icon-tab-massive.svg",
      key: "assignProfile",
    },
  ];

  public managementRadication: any[] = [
    {
      href: "#management",
      description: "Gestionar Solicitud",
      icon: "icon-tab-individual.svg",
      key: "management",
    },
    {
      href: "#request-history",
      description: "Historico Solicitud",
      icon: "icon-tab-massive.svg",
      key: "request-history",
    },
  ];

  public requestType = [
    {
      value: 1,
      description: "Cupo hasta $ 20 millones",
      check: false,
    },
    {
      value: 2,
      description: "Cupo mayor de $ 20 millones",
      check: false,
    },
    {
      value: 3,
      description: "Informe Comercial",
      check: false,
    },
    {
      value: 4,
      description: "Estudio Posfechado",
      check: false,
    },
    {
      value: 5,
      description: "Viabilidad",
      check: false,
    },
  ];

  public requestTypes: SelectModel[] = [
    {
      value: "1",
      description: "Cupo hasta $ 20 millones",
    },
    {
      value: "2",
      description: "Cupo mayor de $ 20 millones",
    },
    {
      value: "3",
      description: "Informe Comercial",
    },
    {
      value: "4",
      description: "Estudio Posfechado",
    },
    {
      value: "5",
      description: "Viabilidad",
    },
  ];

  public messager =
    "Estimado usuario no tienes acceso a este modulo. Debes registrar tu ID : ";

  public requestStates: SelectModel[] = [
    {
      value: "2",
      description: "PENDIENTE",
    },
    {
      value: "3",
      description: "APROBADA",
    },
    {
      value: "4",
      description: "NEGADA",
    },
    {
      value: "5",
      description: "ANULADA",
    },
  ];

  public requestStatesAll: SelectModel[] = [
    {
      value: "1",
      description: "NUEVA",
    },
    {
      value: "2",
      description: "PENDIENTE",
    },
    {
      value: "3",
      description: "APROBADA",
    },
    {
      value: "4",
      description: "NEGADA",
    },
    {
      value: "5",
      description: "ANULADA",
    },
    {
      value: "6",
      description: "EN GESTIÓN",
    },
  ];

  public tableHeadersMyRequests: string[] = [
    // fase 2 ok fm
    "Acciones",
    "Priorizar",
    "Radicado",
    "Tipo Solicitud",
    "Tipo",
    "Documento",
    "Fecha/Hora",
    "Estado",
    "Usuario",
    "Analista",
    "Medio",
    "Producto",
  ];

  public tableHeadersRequestAdmin: string[] = [
    // fase 2 ok fm
    "Perfil",
    "Tipo solicitud asociado",
    "Estado solicitud asociado",
    "Usuarios",
    "Acciones",
  ];

  public tableHeadersHistory: string[] = [
    // fase 2 ok fm
    "Fecha/Hora Gestión",
    "Descripción",
    "Tiempo Gestión",
    "Estado",
    "Usuario",
  ];

  public negationStates = [
    "(EN BLANCO)",
    "AFILIADO CANCELA SOLICITUD",
    "ANTECEDENTES DISCIPLINARIOS",
    "AVISOS DE NO PAGO",
    "AVISOS DE NO PAGO EMPRESA",
    "AVISOS DE NO PAGO RL",
    "CAPACIDAD DE PAGO",
    "CHEQUES PRESTADOS",
    "CONDONACION CAPITAL",
    "CONTACTO NO EFECTIVO",
    "CUBRIMIENTO",
    "CUENTA EMBARGADA",
    "CUENTA INACTIVA",
    "CUENTA NUEVA",
    "CUENTA SALDADA",
    "CUPO NO ES PARA TITULAR",
    "CVS ACTIVOS",
    "CVS ACTIVOS SOCIO Y/O RL",
    "DATOS LOCALIZACION FUERA DE POLITICAS",
    "DEUDOR MOROSO DIAN",
    "EN LISTA SAGRILAFT",
    "ESTRUCTURA FINANCIERA",
    "FRAUDE/ESTAFA",
    "GIRADOR INFORMA NO GIRA CHEQUE",
    "INCONSISTENCIAS EN LA INFORMACION",
    "INFORMACIÓN FINANCIERA DESACTUALIZADA",
    "LEY 1116 - INSOLVENCIA",
    "LEY 1116 - INSOLVENCIA SOCIO Y/O RL",
    "MAL COMPORTAMIENTO DE PAGO",
    "MALA REFERENCIACIÓN",
    "MATRICULA NO RENOVADA",
    "MORAS EN CENTRALES DE RIESGO",
    "MORAS EN CHECK",
    "MORAS EN COBRA",
    "NO ACEPTAN CONDICIONES DIFERENTES DE APROBACION",
    "NO ACEPTAN PAGARE SOCIO Y/O RL",
    "NO ACEPTAN PIGNORACION",
    "NO CONFIRMA VINCULO COMERCIAL",
    "NO FIRMA PAGARE",
    "NO FIRMA PAGARE CON CODEUDOR",
    "NO RECONOCE VINCULO COMERCIAL",
    "NO REGISTRA MATRICULA MERCANTIL",
    "PARAMETROS HIPERMERCADOS Y/O OTROS",
    "PARÁMETRO EDAD",
    "PARÁMETRO INSTRUCCIÓN DE VICEP COMERCIAL",
    "PARÁMETRO PERMANENCIA",
    "PENALIZACION CV",
    "PRESTAMISTA",
    "PROCESOS VIGENTES RAMA JUDICIAL",
    "REFERENCIAS DUDOSAS",
    "RL CON OBLIGACIONES CHECK",
    "RUT SUSPENDIDO O CANCELADO",
    "SIN DATOS DE CONTACTO EN BASES DE DATOS",
    "TOPE MÁXIMO DEL BOLSA",
    "TOLERANCIA DE MORALIDAD"
];

public negationStatesRadication = [
  "(EN BLANCO)",
  "AFILIADO CANCELA CONSULTA",
  "AFILIADO NO ENVIA DOCUMENTOS",
  "AFILIADO NO ENVIA FORMATO DE AUTORIZACION",
  "ATADO A DOCUMENTO SOSPECHOSO",
  "AVISOS DE NO PAGO EMPRESA",
  "AVISOS DE NO PAGO RL",
  "BANCOLOMBIA",
  "CAPACIDAD DE PAGO",
  "CGA - EN LIQUIDACION-CTO4403",
  "CHEQUE GERENCIA",
  "CHEQUE YA AUTORIZADO",
  "CHEQUES EN CANJE",
  "CHEQUES PRESTADOS",
  "CUENTA BLOQUEADA",
  "CUENTA NO EXISTE",
  "CUENTA NUEVA",
  "CVS ACTIVOS",
  "DEUDOR MOROSO DIAN",
  "ERROR OPERADOR",
  "ESTAFADOR",
  "ESTRUCTURA FINANCIERA",
  "GIRADOR INFORMA NO GIRA CHEQUE",
  "IMPOSIBLE VERIFICAR DATOS",
  "INCOSISTENCIAS LA INFORMACION",
  "INFORMACIÓN FINANCIERA DESACTUALIZADA",
  "KONFIGURA TRANCHE 1 -CT07166",
  "KONFIGURA TRANCHE 2 -CTO7167",
  "KONFIGURA TRANCHE 3 -CT07168",
  "KONFIGURA TRANCHE 4 -CT07175",
  "LEY 1116 O DE INSOLVENCIA",
  "LISTA CLINTON",
  "LOCALIZACION CELULAR",
  "MAL COMPORTAMIENTO DE PAGO MORAS EN CENTRALES DE RIESGO",
  "MATRICULA NO RENOVADA",
  "MORAS EN COBRA",
  "NEW CREDIT 7 COLPATRIA -CT06993",
  "NEWCREDIT 1 TUYA -CT06577",
  "NEWCREDIT 11 BBVA -CT07443",
  "NEWCREDIT 12 BBVA -CT07449",
  "NEWCREDIT 2 DAVIVIENDA -CT06588",
  "NEWCREDIT 4 -CT06838",
  "NO ACEPTAN CONDICIONES DIFERENTES DE APROBACION",
  "NO ACEPTAN PIGNORACION",
  "NO CUBRE PLAZA",
  "NO FIRMA PAGARE",
  "NO FIRMA PAGARE CON CODEUDOR",
  "NO REGISTRA MATRICULA MERCANTIL",
  "PARAMETROS HIPERMERCADOS Y/O OTROS",
  "PARENTESCO",
  "PARÁMETRO EDAD",
  "PARÁMETRO INSTRUCCIÓN DE VICEP COMERCIAL",
  "PARÁMETRO PERMANENCIA",
  "PENALIZACION CV",
  "PENDIENTE PRIMER REGISTRO",
  "PLATAFORMA 2 - DAVIVIENDA -CT06345",
  "PLATAFORMA 5 - BBVA -CT07039",
  "PLATAFORMA 6 BBVA -CT07288",
  "PLATAFORMA 7 BBVA-CTO7289",
  "POR CHEQ REG COMO ROBADO",
  "POR CUENTA INACTIVA",
  "POR CUENTA SALDADA",
  "POST PENDIENTES",
  "PRESTAMISTA",
  "PROCESOS VIGENTES RAMA JUDICIAL",
  "REFERENCIAS DUDOSAS",
  "REINTEGRA - BANCO -CT06215",
  "REINTEGRA - SUFI -CT06220",
  "REINTEGRA 10 BANCOL -CT07421",
  "REINTEGRA 11 -CTO7473",
  "REINTEGRA 12-CT07608",
  "REINTEGRA 3 - BANCO -CT06527",
  "REINTEGRA 3 - SUFI-CT06553",
  "REINTEGRA 6 BANCO -CT07139",
  "REINTEGRA 7 BANCO -CT07195",
  "REINTEGRA 8 BANCO -CT07268",
  "REINTEGRA 8 SUFIN -CTO7270",
  "REINTEGRA 9 BANCO -CT07377",
  "REINTEGRA2 - BANCO -CT06376",
  "REINTEGRA4 - BANCO -CT06684",
  "REPRESENTANTE LEGAL CON MAL COMPORTAMIENTO DE PAGO",
  "RL CON OBLIGACIONES CHECK",
  "RUT SUSPENDIDO O CANCELADO",
  "SIN DATOS DE CONTACTO EN BASES DE DATOS",
  "SUPERA CUPO ASIGNADO",
  "SUPERA EXPOSICION MAXIMA",
  "TOPE MÁXIMO DEL BOLSA",
  "TOLERANCIA DE MORALIDAD",
  "TRANSACCION RIESGOSA",
  "VELOCIDAD DE GIRO",
  "VIVAYCO SAS -CTO6344"
];

  public filingsTable: string[] = [
    "Fecha de registro",
    "Hora",
    "Estudio",
    "Radicación",
    "Número de contrato",
    "Establecimiento",
    "Valor",
    "Opciones",
  ];

  public documents: SelectModel[] = [
    {
      value: "RUT",
      description: "Rut",
      disabled: false,
    },
    {
      value: "OTHER",
      description: "Otros",
      disabled: false,
    },
    {
      value: "FORM",
      description: "Formulario",
      disabled: true,
    },
    {
      value: "BANK_STATEMENT",
      description: "Extractos bancarios",
      disabled: false,
    },
    {
      value: "RETURN_INCOME",
      description: "Declaración de renta",
      disabled: false,
    },
    {
      value: "ID",
      description: "Documento de identidad",
      disabled: true,
    },
    {
      value: "FINANCIAL_STATEMENT",
      description: "Estado financiero",
      disabled: false,
    },
    {
      value: "CHAMBER_OF_COMMERCE",
      description: "Cámara de comercio",
      disabled: false,
    },
  ];

  public typeRadication: SelectModel[] = [
    //{
    //  value: "PAGARE",
    //  description: "Radicación pagare",
    //},
    {
      value: "ESTUDIO",
      description: "Radicación estudios",
    },
    //{
    //  value: "BASE",
    //  description: "Radicación base",
    //},
  ];

  public typeStudies: SelectModel[] = [
    {
      value: "INFORME COMERCIAL",
      description: "Informe comercial",
    },
    {
      value: "CUPO SEP",
      description: "Cupo SEP",
    },
    {
      value: "ESTUDIO SEP",
      description: "Estudio SEP",
    },
    {
      value: "AVANCE",
      description: "Avance",
    },
    {
      value: "CUPO GIRADOR",
      description: "Cupo girador",
    },
    {
      value: "ESTUDIO POSFECHADO",
      description: "Estudio posfechado",
    },
    {
      value: "CUPO PRE-APROBADO",
      description: "Cupo pre-aprobado",
    },
  ];

  public states: SelectModel[] = [
    {
      value: "PENDIENTE",
      description: "Pendiente",
    },
    {
      value: "DEVUELTO",
      description: "Devuelto",
    },
    {
      value: "APROBADO",
      description: "Aprobado",
    },
    {
      value: "NEGADO",
      description: "Negado",
    },
    {
      value: "ANULADO",
      description: "Anulada",
    },
  ];

  public queryCode: QueryCode[] = [
    {
      ctid: "1",
      documentType: "N",
      document: 8600284621,
      branchOffice: "BOGOTA",
      queryCode: 517144,
      establishment: "INFORME PROVEEDORES Y OTROS",
      state: "A",
      register: new Date("1996-10-10T15:42:57.000+00:00"),
      updated: new Date("2022-10-06T20:18:39.000+00:00"),
      retirement: new Date("2022-10-06T20:18:39.000+00:00"),
      sequence: 11,
      contract: 300000,
      userUpdate: "DEISYS",
    },
    {
      ctid: "2",
      documentType: "N",
      document: 8600284621,
      branchOffice: "BOGOTA",
      queryCode: 838680,
      establishment: "PUNTO DE VENTA PRUEBA",
      state: "A",
      register: new Date("2023-05-29T15:36:57.000+00:00"),
      updated: new Date("2023-05-29T15:36:57.000+00:00"),
      retirement: null,
      sequence: 89,
      contract: 300000,
      userUpdate: "DEISYS",
    },
  ];

  public responseMedium: SelectModel[] = [
    {
      value: "E-MAIL",
      description: "E-Mail",
    },
    {
      value: "TELEFONICO",
      description: "Telefono",
    },
  ];

  public yesNoQuestion: SelectModel[] = [
    {
      value: "SI",
      description: "Si",
    },
    {
      value: "NO",
      description: "No",
    },
  ];

  public timeRta: string[] = [
    "2 Horas",
    "24 Horas",
    "3 Horas",
    "48 Horas",
    "Otro",
  ];

  timeToResponse(requestType: number): string {
    switch (requestType) {
      case 1:
        return "8 horas hábiles";

      case 2:
        return "8 horas hábiles";

      case 3:
        return "24 horas";

      case 4:
        return "48 horas";

      case 5:
        return "2 horas hábiles";
    }
  }

  getDataUser(): string {
    let user = JSON.parse(localStorage.getItem("identity"))
      ? JSON.parse(localStorage.getItem("identity"))
      : {};
    return user.username;
  }

  constructor() {}
}
