import { Component, OnInit } from '@angular/core';
import { SelectModel } from '@app/models/select.model';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonDocument } from '../../searcher/models/person-document';
import { TransactionsHeadersService } from '../../services/transactions-headers.service';
import { NonPaymentNotice } from './models/response/non-payment-notice';
import { NonPaymentNoticeService } from './service/non-paymnet-notice.service';

@Component({
  selector: 'app-non-payment-notice',
  templateUrl: './non-payment-notice.component.html',
  styleUrls: ['./non-payment-notice.component.scss']
})
export class NonPaymentNoticeComponent implements OnInit {

  public navItemTabs: any[] = [];
  totalPages : number = 1;
  pageRequest : number = 1;
  totalQty = '';
  currentQty = '';
  documentType: string = '';
  document: string | null = null;
  notices: NonPaymentNotice[] = [];

  constructor(private tabs: TabsService, public helper: HelperService, private service: NonPaymentNoticeService,
    public tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService) {
    this.navItemTabs = this.tabs.adminQuotaTabs;

  }

  ngOnInit() {}

  search(person: PersonDocument) {
    this.spinner.show();

    const body: any = {
      documentType: person.documentType,
      document: person.document,
      currentPage: 1,
      perPage: 10
    }
    
    this.documentType = person.documentType;
    this.document = person.document;
    this.service.findAllNotices(body).subscribe(
      (res: any) => {
        this.totalQty = res.data[0].totalQty;
        this.currentQty = res.data[0].currentQty;
        this.spinner.hide();
        if (res.status != 200) this.helper.badResponse(res.message, res.status);
        this.notices = res.data[0]['nonPaymentBuyerInfoList'];
        console.log(res.data[0]['nonPaymentBuyerInfoList']);
      },
      (error) => {
        this.spinner.hide();
        this.helper.handleErrorHttp(error, 'Ocurrió un Error')
      } 
    );
  }

  goToPage(currentPage: any){
    //
  }

}
