import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { BackupsService } from '@app/services/backups.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { RefundsModel } from '../models/request/Refunds.model';
import { RefundsListModel, RefundsListResponse } from '../models/response/RefundsList.model';
import { AffiliatesListModel } from '../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../services/backups-store.service';
import * as moment from 'moment';
import { HelperService } from '@app/services/helper.service';
import Swal from 'sweetalert2';
import { SearchComponent } from '@app/components/layout/search/search.component';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';
import { SearchAutocompleteAffiliateComponent } from '../search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-refunds-table',
  templateUrl: './refunds-table.component.html',
  styleUrls: ['./refunds-table.component.scss']
})

export class RefundsTableComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('searchComponent') searchComponent: SearchComponent;
  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;

  public initDateInput: Date;
  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;

  public refundsList: RefundsListModel[] = [];

  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;

  public searchError: boolean = false;
  public activeTab: string = '';
  public parentTab: string = 'refunds-table';

  public search: RefundsModel = new RefundsModel();
  private readonly unsubscribe$: Subject<void> = new Subject();
  public stateShowSearchResult$: Observable<boolean> = this.backupsStore.stateShowSearchResult$;

  constructor(private backupsService: BackupsService, private backupsStore: BackupsStoreService,
    private spinner: NgxSpinnerService, public helper: HelperService, public tableHeaders: BackupsTableHeadersService,
    private toastr: NgxSpinnerService, private http: HttpClient) {

  }

  ngOnInit() {
    this.backupsStore.stateBackupsActiveTab$
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      )
      .subscribe(
        (stateBackupsActiveTab: string) => {
          this.activeTab = stateBackupsActiveTab;
          this.initilizeDateRangePicker();
          this.searchAutocompleteAffiliate.reset();
          if (this.activeTab === this.parentTab) {
            //this.getRefunds(1);
          }
        });
    this.backupsStore.stateAffiliate$.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
      // distinctUntilKeyChanged('document')
    ).subscribe(
      (stateAffiliate: AffiliatesListModel) => {
        // console.log('stateAffiliate on reintegros', stateAffiliate);
        this.initAffiliate(stateAffiliate);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const { affiliateDocumentType, affiliateDocument, issuerDocumentType, issuerDocument, contract,  draftNumber } = stateAffiliate;
    // console.log(stateAffiliate);
    this.search.affiliateDocumentType = affiliateDocumentType || '0';
    this.search.affiliateDocument = affiliateDocument || '0';
    this.search.issuerDocumentType = issuerDocumentType;
    this.search.issuerDocument = issuerDocument as any;
    this.search.contract = contract || 0;
    if (this.activeTab === this.parentTab&&this.search.issuerDocument.toString()!=="") {
      this.getRefunds(1);
    }
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getRefunds(currentPage);
  }

  onSearch(textSearch: string) {
    /* this.search.search = textSearch; */
    
    this.getRefunds(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).format('YYYY-MM-DD');
    this.search.finalDate = moment(endDate).format('YYYY-MM-DD');
    if (!this.validateAffiliate()) return;
    this.getRefunds(1);
  }

  getRefunds(currentPage: number) {
    this.counterRequest++;
    this.spinner.show();
    this.search.currentPage = currentPage;
    /* this.search.issuerDocumentType = "0";
    this.search.issuerDocument = 0; */
    this.search.currentPage = currentPage;
    this.search.perPage = 10;
    this.search.initialDate = moment(this.search.initialDate ).
    set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format('YYYY-MM-DD HH:mm:ss');
    this.search.finalDate = moment(this.search.finalDate)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    .format('YYYY-MM-DD HH:mm:ss');
    
    
    this.backupsService.getRefunds(this.search).subscribe(
      (refundsListResponse: RefundsListResponse) => {
        if (refundsListResponse && refundsListResponse.ok === false) {
          this.handleGetRefundsError();
          return;
        }
        this.refundsList = refundsListResponse['data'];
        this.cantPages = refundsListResponse['totalPages'];
        console.log('refundsListResponse', refundsListResponse);
        this.handleResetSearch();
        this.searchAutocompleteAffiliate.toggleResults(false);
        this.spinner.hide();
      }, (error: any) => {
        this.handleGetRefundsError(error);
      }
    );
  }

  handleGetRefundsError(error?: any) {
    console.error(error);
    /* if (this.search.search && this.search.search !== '0') {
      this.search.search = '0';
      this.getRefunds(1);
    } */
    this.handleResetSearch();
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({ 'type': 'error', 'title': 'Reintegros', 'text': `${message}` });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.refundsList = [];
    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
    this.searchAutocompleteAffiliate.removeFocus();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  handleResetSearch() {
    if (this.searchComponent) {
      this.searchComponent.reset();
    }
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).subtract(30, 'days').format('YYYY-MM-DD');
    this.search.finalDate = moment(this.finalDate).format('YYYY-MM-DD');
  }

  validateAffiliate() {
    const { affiliateDocument } = this.search;
    const isValid = (affiliateDocument !== '0') ? true : false;
    if (!isValid) {
      //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');

    }
    return isValid;
  }

}
