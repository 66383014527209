import { Component, OnInit } from '@angular/core';
import { RadicationService } from '@app/pages/quota-radication/services/radication-quota.service';
import { HelperService } from '@app/services/helper.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: "app-bags-manager",
  templateUrl: "./bags-manager.component.html",
  styleUrls: ["./bags-manager.component.scss"],
})
export class BagsManagerComponent implements OnInit {
  public dateRange: Array<any> = [];

  public imageData: Array<any> = [];
  public message = "";
  public userName: any;
  permissionToView = false;

  public tabs: Array<any> = [
    {
      title: "Reglas de facturación contrato",
      active: true,
    },
    {
      title: "Reglas creadas",
      active: false,
    },
    {
      title: "Actualizar facturación",
      active: false,
    },
    {
      title: "Reportes bolsas",
      active: false,
    },
  ];

  constructor(
    public helper: HelperService,
    private roles: RolesService,
    private radicationQuota: RadicationService
  ) {
    this.message = this.radicationQuota.messager;
  }

  ngOnInit() {
    const position = Boolean(localStorage.getItem("select-position") || false);

    if (position) {
      this.tabs[0].active = !position;
      this.tabs[2].active = position;
      localStorage.removeItem("select-position");
    }

    this.getProfile();
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;
  }

  getProfile() {
    this.userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "contract"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions.adminGlobalBill) {
      this.permissionToView = true;
    }
  }
}


