export class BagResponse {
    ok:                     boolean;
    message:                 string;
    data:        BagResponseModel[];
    status:                  number;
    currentPage:             number;
    totalPages:              number;
}

export class BagResponseModel {
    id:                      number;
    bondValue:               number;
    excessServicePercentage: number;
    bondMonth:               number;
    minimumMonthlyBilling:   number=0;
    serviceRate:             number;
    monthlyBilling:          number;
    semiannualBilling:       number;
    annualBilling:           number;
    numQuotas:           number = 0;
    paymentValue:            number;
    createdAt:               string;
    updatedAt:               string;
    productBag:              number;
    termOfUse:               number;
}
