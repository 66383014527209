import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackingsToProcessRequestModel } from '../models/request/BackingsToProcess.model';
import { AffiliatesListModel } from '../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../services/backups-store.service';

@Component({
  selector: 'app-title-value',
  templateUrl: './title-value.component.html',
  styleUrls: ['./title-value.component.scss'],
})
export class TitleValueComponent implements OnInit {
  // Radio
  public radioSelected = 'individual';
  public radio = [
    { name: 'Cargue individual', value: 'individual' },
    { name: 'Cargue masivo', value: 'massive' },
  ];

  public search: BackingsToProcessRequestModel = new BackingsToProcessRequestModel();
  public readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private backupsStore: BackupsStoreService) { }

  ngOnInit() {
    this.backupsStore.stateAffiliate$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (stateAffiliate: AffiliatesListModel) => {
        this.initAffiliate(stateAffiliate);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const { affiliateDocumentType, affiliateDocument, issuerDocumentType, issuerDocument, contract } = stateAffiliate;
    this.search.affiliateDocumentType = issuerDocumentType === '0' ? affiliateDocumentType : issuerDocumentType || '0';
    this.search.affiliateDocument = issuerDocument === '0' ? affiliateDocument : issuerDocument || '0';
    this.search.contract = contract || 0;
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(Item => Item.value === this.radioSelected);
    return radioSel.value;
  }
}
