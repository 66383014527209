import { Component, Input, OnInit } from '@angular/core';
import { PreBill } from '@app/models/global-billing.models';
import { SelectModel } from '@app/models/select.model';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportNoBillOrBill } from '../models/ReportNoBillorBill.mode';

@Component({
  selector: 'app-pending-billing-report',
  templateUrl: './pending-billing-report.component.html',
  styleUrls: ['./pending-billing-report.component.scss'],
})
export class PendingBillingReportComponent implements OnInit {
 report: any = 'NO_FAC';
  cantPages = 0;
  currentPage = 1;
  typeSearch = null;
  tableHeard  = [
    'Número de bolsa',
    'Contrato',
    'Nit',
    'Afiliado',
    'Mes cuota',
    'Cuota',
    'Valor cuota',
    'Corte',
    'Factura Net-suite',
    'Fecha facturación',
  ];

  public listOptions: SelectModel[] = [
    {
      value: 'NIT',
      description: 'Número de documento',
    },
    {
      value: 'AFILIADO',
      description: 'Nombre del afiliado',
    },
    {
      value: 'CONTRATO',
      description: 'Numero de contrato',
    }
  ];

  filter: boolean = false;
  value = null;

  typeSearchNumber = ['NIT', 'CONTRATO', 'id_bolsa', 'corte_facturacion'];

  globalBiling: string | ReportNoBillOrBill [];

  constructor(
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.backupsHeaders.loading$.subscribe(() => {
      this.getAll();
    });
    this.getAll();
  }

  getAll(isDownload: boolean = false) {
    this.spinner.show();
    const body = {
      filtro: this.typeSearch,
      valor: this.value,
      reporte: this.report,
      pagina: this.currentPage,
      por_pagina: 20,
      descargar: isDownload,
    };
    if (!isDownload) {
      this.globalBillingService.bagsReport(body).subscribe(
        (resp) => {
          if (resp.ok) {
            this.globalBiling = resp.data[0];
          }
          this.spinner.hide();
          this.cantPages = resp.totalPages;
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    } else {
      this.globalBillingService.bagsReport(body).subscribe(
        (resp) => {
          this.helper.downloadFromBase64(resp.data[0], 'reporte', '.xls');
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    }
  }

  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.getAll();
  }

  selectTypeSearch($event) {
    this.currentPage = 1;
    this.filter = this.typeSearchNumber.includes($event);
    this.typeSearch = $event;
    this.value = null;
  }

  refresh() {
    this.typeSearch = null;
    this.value = null;
    this.currentPage = 1;
    this.getAll();
  }

  openView(prebil: PreBill) {
    this.modal.show('individualBilling', prebil);
  }
}
