import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";
import { MunicipalyReference } from "@app/models/MunicipalReference.model";
import { Response } from "@app/models/responseUtil.model";
import {
  AffiliateInfoRequest,
  FindBuyerInfoRequest,
} from "@app/pages/get-quota/transactions/entry-transactions/models/request/entry-transactions.request";
import {
  AffiliateInfoResponse,
  FindBuyerInfoResponse,
} from "@app/pages/get-quota/transactions/entry-transactions/models/response/entry-transactions.response";
import { Draft } from "@app/pages/quotas/models/Draft.model";
import { AllocationLimit } from "@app/pages/quotas/models/allocationLimit.model";
import { Banks } from "@app/pages/quotas/models/banks.model";
import { BuyerAffiliate } from "@app/pages/quotas/models/buyerAffiliate.model";
import { BuyerAffiliateContract } from "@app/pages/quotas/models/buyerAffiliateContract.modal";
import { DocumentTypes } from "@app/pages/quotas/models/documentType.model";
import { FinancialQuery } from "@app/pages/quotas/models/financialQuery.model";
import { Formula } from "@app/pages/quotas/models/formula.model";
import { GeneralInfo } from "@app/pages/quotas/models/generalInfo.model";
import { HolderTValidations } from "@app/pages/quotas/models/holderTValidations.model";
import { QuotaForm } from "@app/pages/quotas/models/quotaForm.model";
import { QuotaGreaterThan20M } from "@app/pages/quotas/models/quotaGreaterThan20M.model";
import { ReferenceMunicipalities } from "@app/pages/quotas/models/referenceMunicipalities.model";
import { RelationEcBuyer } from "@app/pages/quotas/models/relationEcBuyer.model";
import { AllocationLimitRequest } from "@app/pages/quotas/models/request/allocationLimitRequest.model";
import { BuyerAffiliateRequest } from "@app/pages/quotas/models/request/buyerAffiliateRequest.model";
import { FinancialQueryRequest } from "@app/pages/quotas/models/request/financialQueryRequest.model";
import { QuotaGreaterThan20MRequest } from "@app/pages/quotas/models/request/quotaGreaterThan20MRequest.model";
import { RequestType } from "@app/pages/quotas/models/requestType.model";
import { TelephoneTypeResponse } from "@app/pages/quotas/models/response/telephoneTypeResponse.model";
import { SummaryCifin } from "@app/pages/quotas/models/summaryCifin.modal";
import { TermType } from "@app/pages/quotas/models/termType.model";
import { UserModel } from "@models/user.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class QuotaGreaterService {
  private urlApi: string;
  private apiUrl: string;
  private backingsToken: string;
  public userToken: any;
  public identity: UserModel;
  public entryTransactionsApi: string;
  public urlCoviFacturaTransactionsApi: string;
  public entryTransactionsApiToken: string;
  public dev7ApiUtl: string;

  constructor(private http: HttpClient,
    public helper: HelperService,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlQuotasApi;
    this.apiUrl = environment.apiUrl;
    this.backingsToken = environment.backingsToken;
    this.urlCoviFacturaTransactionsApi = environment.urlCoviFacturaTransactionsApi;
    this.entryTransactionsApi = environment.entryTransactionsApi;
    this.entryTransactionsApiToken = (environment as any).entryTransactionsApiToken || '';

    //this.readToken();
    //this.getIdentity();
    this.ciCdInit();
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {
        apiUrl,
        urlQuotasApi,
        entryTransactionsApi,
        entryTransactionsApiToken,
        backingsToken,
        urlCoviFacturaTransactionsApi,
        dev07ApiUtl
      } = apis;
      this.urlApi = urlQuotasApi;
      this.apiUrl = apiUrl;
      this.entryTransactionsApi = entryTransactionsApi;
      this.backingsToken = backingsToken;
      this.entryTransactionsApiToken = entryTransactionsApiToken;
      this.urlCoviFacturaTransactionsApi = urlCoviFacturaTransactionsApi;
      this.dev7ApiUtl = dev07ApiUtl;
    });
  }

  public responseExhibition: any = {
    ok: true,
    message: "Finalizó correctamente la consulta.",
    data: [
      {
        currentExhibitions: [
          {
            affiliate: "524204 TRS PARTES S.A.",

            product: "SEPGLOBAL",

            state: "A",

            quotaAffiliate: 20000001,

            date: "2020-12-18 21:08:17",

            fullGuarantee: 25464342.0,

            fullCurrentGuarantee: 20905859.0,

            initialGuaranteeDate: "2021-03-09",

            lastGuaranteeDate: "2022-06-06",

            currentExpiredWarranty: 549232.0,
          },

          {
            affiliate: "524255 EQUIPOS PARA ALTURAS SAS - EQUIALTURAS",

            product: "SEPGLOBAL 2",

            state: "A",

            quotaAffiliate: 3900000,

            date: "2021-06-15 23:42:47",

            fullGuarantee: 1008250.0,

            fullCurrentGuarantee: 1008250.0,

            initialGuaranteeDate: "2021-06-16",

            lastGuaranteeDate: "2021-09-28",

            currentExpiredWarranty: 0,
          },
          {
            affiliate: "524980 DIELCO S.A.S",

            product: "GLOBAL",

            state: "A",

            quotaAffiliate: 106000002,

            date: "2022-05-06 22:27:45",

            fullGuarantee: 0,

            fullCurrentGuarantee: 0,

            initialGuaranteeDate: null,

            lastGuaranteeDate: null,

            currentExpiredWarranty: 0,
          },
          {
            affiliate: "524998 SMART DEVELOPMENT SYSTEMS CORP - SUC COLOMBIA",

            product: "GLOBAL",

            state: "A",

            quotaAffiliate: 20000001,

            date: "2022-06-21 22:08:16",

            fullGuarantee: 0,

            fullCurrentGuarantee: 0,

            initialGuaranteeDate: null,

            lastGuaranteeDate: null,

            currentExpiredWarranty: 0,
          },
        ],
        initialQuota: 149900004,
        initialFullGuarantee: 26472592.0,
        initialFullCurrentGuarantee: 21914109.0,
        finalQuota: 149900004,
        finalFullCurrentGuarantee: 21914109.0,
        cvsNoTH: 0,
        daysCvsTH: 0,
        valueCvsTH: 0,
        daysUA: 0,
        noPaymentNoticeNo: 8,
        extendsNo: 73,
      },
    ],
    status: 200,
    currentPage: 1,
    totalPages: 1,
  };

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem("user"))
    ));
  }

  getQuotaGreaterThan20M(
    quotaGreaterThan20MRequest: QuotaGreaterThan20MRequest
  ): Observable<Response<QuotaGreaterThan20M>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}solicitudCupo/solicitudAutomaticaCupos/` +
          quotaGreaterThan20MRequest.settlement,
        options
      )
      .pipe(
        map((resp: Response<QuotaGreaterThan20M>) => {
          return resp;
        })
      );
  }

  getAfilliateBuyer(
    buyerAffiliate: BuyerAffiliate,
    currentPage = 0,
    perPage = 0
  ): Observable<Response<BuyerAffiliate>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = this.helper.createGetRequest(
      buyerAffiliate,
      currentPage,
      perPage
    );
    return this.http
      .get(`${this.urlApi}pagadorAfiliado/pagadorAfiliadoAll`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<BuyerAffiliate>) => {
          return resp;
        })
      );
  }

  getAfilliateBuyerContract(
    buyerAffiliateRequest: BuyerAffiliateRequest
  ): Observable<Response<BuyerAffiliateContract>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}pagadorAfiliado/pagadorAfiliadoContract?documentType=${buyerAffiliateRequest.identificationType}&document=${buyerAffiliateRequest.identification}`,
        options
      )
      .pipe(
        map((resp: Response<BuyerAffiliateContract>) => {
          return resp;
        })
      );
  }

  getDepartmentsCities(): Observable<Response<ReferenceMunicipalities>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(`${this.urlApi}referenciaMunicipio/departamentosMunicipios`, options)
      .pipe(
        map((resp: Response<ReferenceMunicipalities>) => {
          if (resp.data && resp.data.length > 0) {
            localStorage.setItem(
              "form20m_municipalitiesList",
              JSON.stringify(resp.data)
            );
          }
          return resp;
        })
      );
  }

  getHolderRelatedValidations(
    relationEcBuyer: RelationEcBuyer
  ): Observable<Response<RelationEcBuyer>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      docTypeGir: relationEcBuyer.docTypeGir,
      docGir: "" + relationEcBuyer.docGir,
    };
    return this.http
      .get(`${this.urlApi}relacionesEcComprador/relacionesByIdentification`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<RelationEcBuyer>) => {
          return resp;
        })
      );
  }

  getHolderRelatedValidationsAll(
    document: number,
    documentType: string
  ): Observable<Response<HolderTValidations>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const paramObject = {
      document: document,
      documentType: documentType,
    };
    const params = this.helper.createGetRequest(paramObject);
    return this.http
      .get(`${this.urlApi}validacionRelacionados/validacionesDocumento`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<HolderTValidations>) => {
          return resp;
        })
      );
  }

  getAllocationLimit(
    allocationLimit: AllocationLimit
  ): Observable<Response<AllocationLimit>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      documentType: allocationLimit.documentType,
      document: "" + allocationLimit.document,
    };
    return this.http
      .get(`${this.urlApi}limiteAsignacion/allocationLimitByIdentification`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<AllocationLimit>) => {
          return resp;
        })
      );
  }

  getBanks(): Observable<Response<Banks>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http.get(`${this.urlApi}bancos/bancos`, options).pipe(
      map((resp: Response<Banks>) => {
        if (resp.data && resp.data.length > 0) {
          localStorage.setItem("form20m_banksList", JSON.stringify(resp.data));
        }
        return resp;
      })
    );
  }

  getBanksByCountry() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http.get(
      `${this.apiUrl}covi-utils/lists/findBanksByCountry?countryId=49`,
      options
    );
  }

  getDocumentsType(): Observable<Response<DocumentTypes>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(`${this.urlApi}tipoDocumento/tiposDocumento/`, options)
      .pipe(
        map((resp: Response<DocumentTypes>) => {
          if (resp.data && resp.data.length > 0) {
            localStorage.setItem(
              "form20m_documentTypes",
              JSON.stringify(resp.data)
            );
          }
          return resp;
        })
      );
  }

  getDocTypes() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.get(
      `${this.urlApi}tipoDocumento/tiposDocumento/`,
      options
    );
  }

  getTelephoneType() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(`${this.urlApi}tipoTelefono/tiposTelefono/`, options)
      .pipe(
        map((resp: any) => {
          if (resp.data && resp.data.length > 0) {
            localStorage.setItem(
              "form20m_telephoneList",
              JSON.stringify(resp.data)
            );
          }
          return TelephoneTypeResponse.fromResponse(resp);
        })
      );
  }

  getRequestType(): Observable<Response<RequestType>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(`${this.urlApi}tipoSolicitud/tiposSolicitud/`, options)
      .pipe(
        map((resp: Response<RequestType>) => {
          if (resp.data && resp.data.length > 0) {
            localStorage.setItem(
              "form20m_requestTypeList",
              JSON.stringify(resp.data)
            );
          }
          return resp;
        })
      );
  }

  getTermType(): Observable<Response<TermType>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http.get(`${this.urlApi}tipoPlazo/tiposPlazo/`, options).pipe(
      map((resp: Response<TermType>) => {
        if (resp.data && resp.data.length > 0) {
          localStorage.setItem("form20m_termsList", JSON.stringify(resp.data));
        }
        return resp;
      })
    );
  }

  getFormulas(): Observable<Response<Formula>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http.get(`${this.urlApi}formulas/formulas/`, options).pipe(
      map((resp: Response<Formula>) => {
        return resp;
      })
    );
  }

  getReferences(
    quotaForm: QuotaForm,
    currentPage: number = 1,
    perPage: number = 10
  ): Observable<Response<QuotaForm>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = this.helper.createGetRequest(
      quotaForm,
      currentPage,
      perPage
    );
    return this.http
      .get(`${this.urlApi}formulario_cupo/formularioCupoByDocument`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<QuotaForm>) => {
          return resp;
        })
      );
  }

  getSummaryRiskCentral(
    allocationLimitRequest: AllocationLimitRequest
  ): Observable<Response<SummaryCifin>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get(
        `${this.urlApi}cifin_personas/cifinPersonasDocumento?document=${allocationLimitRequest.document}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<SummaryCifin>) => {
          return resp;
        })
      );
  }

  postForm20M(generalInfo: GeneralInfo): Observable<Response<GeneralInfo>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    const body = generalInfo;
    return this.http
      .post(`${this.urlApi}solicitudCupo/save`, body, options)
      .pipe(
        map((resp: Response<GeneralInfo>) => {
          return resp;
        })
      );
  }

  getQuota(quotaRequest: number): Observable<any> {
    const headers = new HttpHeaders(this.helper.getHeadersAuthorization());

    console.log(quotaRequest);

    const options = {
      headers,
    };
    return this.http
      .get<any>(
        `${this.urlApi}infoGeneral/findGeneralInfo?radicado=${quotaRequest}`,
        options
      )
      .pipe(map((resp: any) => resp));
  }

  getBuyerQuotas(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}/quota-manager/quotas`,
      body,
      options
    );
  }

  byBuyerAndContract(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}/quota-manager/quotas/byBuyerAndContract`,
      body,
      options
    );
  }

  updateAssignedQuota(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}/quota-manager/quotas/createNewQuota`,
      body,
      options
    );
  }

  getBuyerQuotasWithoutContract(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      documentType: body.documentType,
      document: body.document,
    };
    const options = {
      headers,
      params,
    };
    /* TODO: Quitar / */
    return this.http.get(
      `${this.apiUrl}/quota-manager/quotas/buyerQuotas`,
      options
    );
  }

  createBuyerQuotasWithoutContract(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}/quota-manager/quotas/createBuyerQuotas`,
      body,
      options
    );
  }

  updateBuyerQuotasWithoutContract(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}/quota-manager/quotas/updateBuyerQuotas`,
      body,
      options
    );
  }

  updateQuotas(body: any) {
    /* quota-manager/quotas */
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    /* TODO: Quitar / */
    return this.http.post(
      `${this.apiUrl}quota-manager/quotas/updateQuota`,
      body,
      options
    );
  }

  findFinancialInformation(
    body: FinancialQueryRequest
  ): Observable<Response<FinancialQuery>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .post(`${this.urlApi}financialInformation`, body, options)
      .pipe(
        map((resp: Response<FinancialQuery>) => {
          return resp;
        })
      );
  }

  public getReserves(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/bookings/findBookings`,
      body,
      options
    );
  }

  public getContactData(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.entryTransactionsApi}/phones`, options);
  }

  public getContactEmail(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.entryTransactionsApi}/email`, options);
  }

  public getContactAddress(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/address`,
      options
    );
  }

  public getPhoneTypes() {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/lists/phonetypes`,
      options
    );
  }

  public getPhoneStates() {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/lists/phonestates`,
      options
    );
  }

  public getPhoneRelations() {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/lists/phonesrelation`,
      options
    );
  }

  public createPhone(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.post(
      `${this.entryTransactionsApi}/phones`,
      body,
      options
    );
  }

  public createEmail(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.post(
      `${this.entryTransactionsApi}/email`,
      body,
      options
    );
  }

  public createAddress(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.post(
      `${this.entryTransactionsApi}/address`,
      body,
      options
    );
  }

  public normalizeAddress(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/address/normaladdress`,
      options
    );
  }

  public validateAutexKey(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.urlCoviFacturaTransactionsApi}/transactions/autexpassword`,
      options
    );
  }

  public deleteAddress(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.delete(
      `${this.entryTransactionsApi}/address`,
      options
    );
  }

  public deleteEmail(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.delete(
      `${this.entryTransactionsApi}/email`,
      options
    );
  }

  public getMunicipalReference(): Observable<Response<MunicipalyReference>> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http
      .get(`${this.entryTransactionsApi}/lists/municipalReference`, options)
      .pipe(
        map((resp: Response<MunicipalyReference>) => {
          return resp;
        })
      );
  }

  public getRepeatedPhones(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/phones/repeated`,
      options
    );
  }

  public updateReserve(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/bookings/updateBooking`,
      body,
      options
    );
  }

  public cancelReserve(params) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.apiUrl}quota-manager/bookings/cancelBooking`,
      options
    );
  }

  public saveReserve(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/bookings/saveBooking`,
      body,
      options
    );
  }

  public exonerationLists() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.get(
      `${this.apiUrl}quota-manager/exonerations/lists`,
      options
    );
  }

  findBuyerTitles(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/findBuyerTitles`,
      body,
      options
    );
  }

  findTitlesByAuthorizationNo(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/findTitlesByAuthorizationNo`,
      body,
      options
    );
  }

  lockUnlock(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/locksUnlocks/lockUnlockAccount`,
      body,
      options
    );
  }

  lockPhone(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/locksUnlocks/lockUnlockPhone`,
      body,
      options
    );
  }

  exonerate(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/exonerations/exonerate`,
      body,
      options
    );
  }

  findExonerationLists(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/exonerations/findExonerationLists`,
      body,
      options
    );
  }

  findDeclinedTitles(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/findDeclinedTitles`,
      body,
      options
    );
  }

  extendExoneration(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/exonerations/extend`,
      body,
      options
    );
  }

  reverseExoneration(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/exonerations/reverseExoneration`,
      body,
      options
    );
  }

  updateTitleValue(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/updateTitleValue`,
      body,
      options
    );
  }

  updateTitleNo(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/updateTitleNo`,
      body,
      options
    );
  }

  updateTitleDueDate(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/updateTitleDueDate`,
      body,
      options
    );
  }

  disableTitle(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/disableTitle`,
      body,
      options
    );
  }

  approveTitle(body: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/titles/approveTitle`,
      body,
      options
    );
  }

  sendQuotaLog(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/quotas/sendLog`,
      body,
      options
    );
  }

  threeAndSixPayments(body) {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}backingsPostgreSQL/transactions/threeAndSixPayments`,
      body,
      options
    );
  }

  affiliateInfo(
    bodyParams: AffiliateInfoRequest
  ): Observable<AffiliateInfoResponse> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    let params = new HttpParams().set("queryCodeNo", bodyParams.queryCodeNo);

    const options = {
      headers,
      params,
    };

    return this.http
      .get(
        `${this.entryTransactionsApi}/transactions/affiliateInfo`,
        options
      )
      .pipe(
        map((response: AffiliateInfoResponse) => {
          return response;
        })
      );
  }

  findBuyerInfo(body: FindBuyerInfoRequest): Observable<FindBuyerInfoResponse> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.post<FindBuyerInfoResponse>(
      `${this.entryTransactionsApi}/transactions/findBuyerInfo`,
      body,
      options
    );
  }

  authorize(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
    };

    return this.http.post(
      `${this.entryTransactionsApi}/transactions/authorize`,
      body,
      options
    );
  }
  /* https://desarrollo07.c0v.co/covifacturatransactions/
api/v1/transactions/pr_cupo?query_code=517144&buyer_document=8301342461&document_type_buyer=8301342461 */

  findCheckDay(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.entryTransactionsApi}/transactions/pr_cupo`,
      options
    );
  }




  invoiceMassiveChargue(body: File) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const formFiles = new FormData();
    formFiles.append("file", body);
    const options = {
      headers,
    };

    return this.http.put(
      `${this.entryTransactionsApi}/bulkupload/upload`,
      formFiles,
      options
    );
  }

  invoiceMassiveChargueInsert(body, params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };

    return this.http.put(
      `${this.entryTransactionsApi}/bulkupload/bulkupload`,
      body,
      options
    );
  }

  findBulkUpload(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa("CLIENT_APP-1992aaA7:g10hnFdE3@4")
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(
      `${this.dev7ApiUtl}covifacturatransactions/api/v1/bulkupload/findBulkUpload`,
      options
    );
  }

  downloadTransactionsFile(bodyParams) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      bodyParams,
    };

    return this.http.get<any>(
      `${this.entryTransactionsApi}/bulkupload/download`,
      options
    );
  }

  downloadTransactions(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.entryTransactionsApiToken)
    );

    const options = {
      headers,
      params,
    };
    console.log(params);

    return this.http.get<any>(
      `${this.entryTransactionsApi}/bulkupload/downloadbulkload`,
      options
    );
  }

  blockBuyer(body: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/locksUnlocks/buyer`,
      body,
      options
    );
  }

  findAllPromisoryNotes(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<FindBuyerInfoResponse>(
      `${this.apiUrl}quota-manager/promissoryNotes/findAll`,
      body,
      options
    );
  }

  downloadTemplate() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/x-www-form-urlencoded");

    const options = { headers };

    return this.http
      .get(
        `${this.apiUrl}quota-manager/promissoryNotes/downloadTemplate`,
        {
          headers: options.headers,
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  previewFile(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<FindBuyerInfoResponse>(
      `${this.apiUrl}quota-manager/promissoryNotes/preview`,
      body,
      options
    );
  }

  savePromisoryNotes(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<FindBuyerInfoResponse>(
      `${this.apiUrl}quota-manager/promissoryNotes/saveAll`,
      body,
      options
    );
  }

  markAsDeleted(params) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const httpParams: any = params;

    const options = {
      headers,
      params: httpParams,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/promissoryNotes/markAsDeleted`,
      options
    );
  }

  historicReport(params) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    /* const httpParams: any = params; */

    const options = {
      headers,
      params,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/promissoryNotes/historicReport`,
      options
    );
  }

  generateReport(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    /* const httpParams: any = params; */

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/reports/generateReport`,
      body,
      options
    );
  }

  findAccounts(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/locksUnlocks/findAccounts`,
      body,
      options
    );
  }

  findPhones(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/locksUnlocks/findPhones`,
      body,
      options
    );
  }

  titlesByAuthorization(body) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/titles/byAuthorization`,
      body,
      options
    );
  }

  generateAutexKeys(params) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    /* const httpParams: any = params; */

    const options = {
      headers,
      params,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/locksUnlocks/generateAutexKeys`,
      options
    );
  }

  getFormQuotaDraft(params) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    return this.http.get<any>(
      `${this.urlApi}solicitudCupo/draft/${params}`,
      options
    );
  }

  postDraft(draft: Draft): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    const body = draft;
    return this.http
      .post(`${this.urlApi}solicitudCupo/draft`, body, options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
