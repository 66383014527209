import { Component, OnInit } from "@angular/core";
import { PreBill } from "@app/models/global-billing.models";
import { SelectModel } from "@app/models/select.model";
import { BackupsTableHeadersService } from "@app/pages/backups/services/backups-table-headers.service";
import { GloballBillingService } from "@app/services/globall-billing.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-pending-billing",
  templateUrl: "./pending-billing.component.html",
  styleUrls: ["./pending-billing.component.scss"],
})
export class PendingBillingComponent implements OnInit {
  cantPages = 0;
  currentPage = 1;
  typeSearch = null;
  tableHeard = [
    "Número de bolsa",
    "Número de documento",
    "Afiliado",
    "Contrato",
    "Primera factura",
    "Tipo de facturación",
    "Valor cuota",
    "Sucursal",
    "Corte facturación",
    "Fecha facturación",
    "Opciones",
  ];

  public listOptions: SelectModel[] = [
    {
      value: null,
      description: "Seleccione un valor",
    },
    {
      value: "sucursal",
      description: "Sucursal",
    },
    {
      value: "nit",
      description: "Número de documento",
    },
    {
      value: "AFILIADO",
      description: "Nombre del afiliado",
    },
    {
      value: "contrato",
      description: "Numero de contrato",
    },
    {
      value: "tipo_facturacion",
      description: "Tipo de facturación",
    },
    {
      value: "id_bolsa",
      description: "Número de bolsa",
    },
    {
      value: "corte_facturacion",
      description: "Corte de facturación",
    },
  ];

  filter: boolean = false;
  value = null;

  typeSearchNumber = ["nit", "contrato", "id_bolsa", "corte_facturacion"];

  globalBiling = [];

  constructor(
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.backupsHeaders.loading$.subscribe(() => {
      this.getAll();
    });
    this.getAll();
  }

  getAll() {
    this.spinner.show();
    const body = {
      filtro: this.typeSearch,
      valor: this.value ? this.value.toUpperCase() : null,
      pagina: this.currentPage,
      por_pagina: 10,
    };
    this.globalBillingService.getPreBills(body).subscribe(
      (resp) => {
        if (resp.ok) {
          this.globalBiling = resp.data;
        }
        this.spinner.hide();
        this.cantPages = resp.totalPages;
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.getAll();
  }

  selectTypeSearch($event) {
    this.currentPage = 1;
    this.filter = this.typeSearchNumber.includes($event);
    this.typeSearch = $event;
    this.value = null;
  }

  refresh() {
    this.typeSearch = null;
    this.value = null;
    this.currentPage = 1;
    this.getAll();
  }

  openView(prebil: PreBill) {
    this.modal.show("individualBilling", prebil);
  }
}
