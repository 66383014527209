import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "indicativeNum",
})
export class IndicativeNum implements PipeTransform {
  transform(value: any): any {
    let indicativeName: string;

    let requestTypeList: any = JSON.parse(localStorage.getItem("covi-utils"));
    if (requestTypeList && requestTypeList.indicatives.length > 0 && value) {
      requestTypeList.indicatives.forEach((item) => {
        if (Number(item.value) === Number(value)) {
          indicativeName = item.description;
        }
      });
      return indicativeName;
    }
  }
}
