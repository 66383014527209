import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'add'
})
export class AddPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (typeof value === 'number' && typeof args === 'number') {
      return Number(value) + args | 1;
    }
  }

}
