import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { SearchAutocompleteComponent } from '@app/components/layout/search-autocomplete/search-autocomplete.component';
import { SelectModel } from '@app/models/select.model';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { SelectsService } from '@app/services/selects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SearchAffiliateModel } from '../models/request/SearchAffiliateModel';
import { AffiliatesListModel, AffiliatesResponse } from '../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../services/backups-store.service';
import { Globals } from '@app/globals/global';
import { CoviResourceService } from '@app/services/covi-resource.service';

@Component({
  selector: 'app-search-autocomplete-affiliate',
  templateUrl: './search-autocomplete-affiliate.component.html',
  styleUrls: ['./search-autocomplete-affiliate.component.scss'],
  providers: [Globals]
})
export class SearchAutocompleteAffiliateComponent implements OnInit, OnDestroy {
  @Input() authorization: boolean = false;
  @Input() sinisterNumber: boolean = false;
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();
  @Input() label: string = 'Filtrar por:';
  @Input() minlengthSearch: number = 3;
  @Input() required: boolean = true;
  public filterList: Array<any> = [];
  @Input() filterListExceptions: Array<any> = [];

  @ViewChild('searchAutocomplete') searchAutocomplete: SearchAutocompleteComponent;

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public cantPages = 0;
  public currentPage = 1;
 

  public selectOptionsBranchOffices: SelectModel[] = [];
  public selectedIndexResult: number = 0;
  public searchAffiliate: SearchAffiliateModel = new SearchAffiliateModel();
  public affiliateList: AffiliatesListModel[] = [];
  public documentTypes$: Observable<any> = this.selects.getSelectTypes('documentTypes');
  public showResults: boolean = false;
  public preventHide: boolean = false;
  public toggleOpenPagination: boolean = false;

  public selectedIndexResult$: Observable<number> = this.backupsStore.stateSelectResult$;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  @HostListener('click') clickInside() { }
  @HostListener('document:click') clickout() { }

  constructor(public helper: HelperService, public selects: SelectsService, public spinner: NgxSpinnerService,
    private backups: BackupsService, public backupsStore: BackupsStoreService, public toastr: ToastrService,
    public globals: Globals, private coviResource: CoviResourceService) {
    this.selectedIndexResult$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedIndexResult) => this.selectedIndexResult = selectedIndexResult);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchAffiliate.authorization = this.authorization;
    this.searchAffiliate.sinisterNumber = this.sinisterNumber;
    this.coviResource.getGeneralList().subscribe((response: any) => {
      this.filterList = response.data.filters;
      this.setFilterList();
    });
  }

  setFilterList() {
    this.filterList.forEach(element => {
      if (this.searchAffiliate.sinisterNumber && element.value == 'Número radicación') {
        element.state = "SHOW";
      } else if (this.searchAffiliate.authorization && element.value == 'Autorización') {
        element.state = "SHOW";
      }
  
      this.filterListExceptions.forEach(exception => {
        if (element.value == exception) {
          element.state = "HIDE";
        }
      });
    });
  }

  preventHideResults(inside: boolean): void {
    this.preventHide = inside;
    if (!inside && this.searchAutocomplete && !this.toggleOpenPagination) {
      console.log("SELECT");
      
      this.searchAutocomplete.focusElement();
    }
  }

  toggleResults(show: boolean, preventHide?: boolean): void {
    this.preventHide = preventHide ? preventHide : this.preventHide;
    this.showResults = (this.preventHide) ? true : show;
    this.backupsStore.setShowSearchResult(this.showResults);
  }

  selectAffiliateResult(selectedAffiliate: AffiliatesListModel, idx: number): void {
    this.selectedIndexResult = idx;
    this.backupsStore.changeStateAffiliate(selectedAffiliate);
    this.toggleResults(false);
  }

  handleSearch(searchText: string): void {
    this.searchAffiliate.search = searchText;
    if (!searchText) {
      this.handleClear();
      return;
    }

    if (searchText && searchText.length >= this.minlengthSearch) {
      this.getAffiliate(1);
    } else {
      this.handleErrorSearch('', false);
      this.backupsStore.changeStateAffiliate({ ...new AffiliatesListModel(), affiliateDocument: '0', issuerDocument: '0' });
    }
  }

  handleSelectFilter($event) {
    this.searchAffiliate.filter = $event;
    /* if(this.searchAffiliate.search) {
      this.handleSearch(this.searchAffiliate.search);
    } */
  }

  goToPage({ currentPage }): void {
    this.currentPage = currentPage;
    this.selectedIndexResult = 0;
    this.getAffiliate(currentPage);
    this.toggleResults(false);
  }

  getAffiliate(currentPage: number): void {
    this.spinner.show();
    this.searchAffiliate.currentPage = currentPage;

    this.backupsStore.changeStateSearchAffiliate(this.searchAffiliate);
    this.backups.getAffiliate(this.searchAffiliate).subscribe(
      (affiliateResponse: AffiliatesResponse) => {
        if (affiliateResponse && affiliateResponse.ok == false) {
          this.handleErrorSearch();
          return;
        }
        this.toggleResults(true);
        this.affiliateList = affiliateResponse['data'];

        this.cantPages = affiliateResponse['totalPages'];
        this.backupsStore.changeStateAffiliateList(affiliateResponse);
        console.log(affiliateResponse);
        this.spinner.hide();
      }, (error: any) => {
        this.handleErrorSearch(error);
      }
    );
  }

  handleErrorSearch(error?: any, showAlert?: boolean): void {
    console.error(error);
    this.backupsStore.changeStateAffiliateList(new AffiliatesResponse());
    this.backupsStore.changeStateSearchAffiliate(new SearchAffiliateModel());
    this.selectedIndexResult = 0;
    if (showAlert) {
      if (error && error.error && error.error.message) {
        const { message } = error.error;
        Swal.fire({ type: 'info', title: 'Buscar afiliado', text: `${message}` });
      } else {
        this.helper.handleUndefinedError();
      }
    }

    if (error && error.error && error.error.message) {
      const { message } = error.error;
      this.toastr.warning(message, this.globals.commonGlobal.textErrorSearchAffiliate);
    }
    this.affiliateList = [];
    this.toggleResults(false);
    this.cantPages = 0;
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
    this.spinner.hide();
  }

  removeFocus(): void {
    if (this.searchAutocomplete) {
      this.searchAutocomplete.clearFocus();
    }
  }

  cleanLetters(): void {
    this.searchAffiliate.document = (this.searchAffiliate.documentType === 'P')
      ? this.searchAffiliate.document
      : this.searchAffiliate.document.replace(/[^0-9]+/g, "");
  }

  reset() {
    if (this.searchAutocomplete) {
      this.searchAutocomplete.reset();
      let emptyModel = new AffiliatesResponse();
      this.affiliateList = [];
      this.backupsStore.changeStateAffiliateList(emptyModel);
    }
  }
  
  handleOpenPaginationSelect() {
    this.toggleOpenPagination = !this.toggleOpenPagination;
    console.log(this.toggleOpenPagination);
    
  }

  handleClear() {
    this.clear.emit();
    this.toggleResults(false);
    this.affiliateList = [];
    this.backupsStore.changeStateAffiliateList(new AffiliatesResponse());
    this.backupsStore.changeStateAffiliate(new AffiliatesListModel());
  }

}
