import { SubjectService } from '@services/subject.service';
import { NgForm } from '@angular/forms';
import { ChangePasswordRequest } from '@models/changePassword.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '@services/modal.service';
import { UserPhotoRequest, UserPhotoResponse } from '@models/userPhoto.model';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ProfileService } from "@services/profile.service";
import { SellersRequest } from "@models/request/sellersRequest.model";
import { SellersResponse } from "@models/response/sellersResponse.model";
import { BirthdayRequest } from '@models/birthday.model';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  public sellersRequest: SellersRequest = {
    idContry: "1",
    sellerUser: "",
    userDocumentType: "",
    userDocumentNumber: "",
    documentType: "",
    documentNumber: "",
    page: 1,
    registers: 3
  };

  @ViewChild('formProfile') formProfile: NgForm;

  public userBirthday: BirthdayRequest = new BirthdayRequest();
  public uploadPhotoRequest: UserPhotoRequest = new UserPhotoRequest();
  public sellersResponse: SellersResponse = new SellersResponse();

  public birthdayFromService: Date;
  public profileImgBase64: string;
  public min = new Date(1930, 1, 1);
  public max = new Date();

  constructor(private profileService: ProfileService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    private cdRef: ChangeDetectorRef,
    private subject: SubjectService,
    private modal: ModalService) { }

  ngOnInit() {
    this.sellers();
  }

  sellers() {
    this.profileService.getSellers(this.sellersRequest).subscribe(
      (profile: SellersResponse) => {
        const birthday = moment(profile.birthday, 'DD/MM/YYYY').format('MM-DD-YYYY');
        profile.birthday = new Date(birthday);
        this.birthdayFromService = new Date(birthday);
        this.sellersResponse = profile;
        this.subject.sendProfileImg(this.sellersResponse.userPhoto);
      },
      (err: any) => {
        console.error();
      }
    );
  }

  updateUser() {
    if (!moment(this.birthdayFromService).isSame(this.sellersResponse.birthday)) {
      this.updateUserBirthday();
    }
    if (this.uploadPhotoRequest.photo) {
      this.uploadUserPhoto();
    }
  }

  updateUserBirthday() {
    this.spinner.show();
    const { email, cellphone, birthday } = this.sellersResponse;
    this.userBirthday.userDate = birthday;
    this.profileService.userBirthday(this.userBirthday).subscribe(
      (resp: any) => {
        this.toastr.success('Perfil actualizado.', '', {
          timeOut: 3000,
        });
      }, (err: any) => {
        console.error(err);
        this.toastr.error('No se pudo actualizar.', '', {
          timeOut: 3000,
        });
      }
    )
  }

  selectedProfileImg(userPhoto: File) {
    this.uploadPhotoRequest.photo = userPhoto;
    this.cdRef.detectChanges();
  }

  base64Img(base64: any) {
    this.profileImgBase64 = base64;
  }

  uploadUserPhoto() {
    this.spinner.show();
    this.profileService.uploadUserPhoto(this.uploadPhotoRequest).subscribe(
      (url: UserPhotoResponse) => {
        if (url) {
          this.modal.show('profileUpdateSuccess');
          this.subject.sendProfileImg(this.profileImgBase64);
        }
      }, (err: any) => {
        console.error(err);
        this.sellers();
        this.modal.show('error', {
          title: 'No se pudo cargar la imagen.',
          message: 'Por favor intente más tarde.'
        });
        this.helper.refresh('/pages/administracion/perfil');
      }, () => { this.spinner.hide() }
    );
  }

  showModalChangePassword() {
    this.modal.show('profileChangePassword');
  }

  changePassword(changePassRequest: ChangePasswordRequest) {
    this.profileService.changePassword(changePassRequest).subscribe(
      (resp: any) => {
        this.modal.show('profileChangePasswordSuccess');
      }, (err: any) => {
        console.error(err);
        this.modal.show('error', {
          title: 'No se pudo cambiar la contraseña.',
          message: 'Por favor intente más tarde.'
        });
      }
    )
  }

  refresh() {
    this.uploadPhotoRequest.photo = '';
    this.formProfile.form.markAsPristine();
  }

}
