import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @ViewChild('formSearch') formSearch: NgForm;
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Input() minLength = 2;
  public searchString: string;

  constructor(public spinner: NgxSpinnerService) { }

  search(searchString: string) {
    if(searchString && searchString.length > this.minLength) {
      this.onSearch.emit(searchString);
    }
  }

  reset() {
    this.searchString = '';
    this.formSearch.reset();
  }

}
