import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-modal-confirm-delete-address',
  templateUrl: './modal-confirm-delete-address.component.html',
  styleUrls: ['./modal-confirm-delete-address.component.scss']
})
export class ModalConfirmDeleteAddressComponent implements OnInit, OnDestroy {

  @Output() confirm: EventEmitter<any>;

  constructor(public modal: ModalService,
              public helper: HelperService,
              private router: Router) { 
    this.confirm = new EventEmitter();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.modal.close();
  }

 logOut() {
    this.confirm.emit();
 }

 closeModal() {
  this.modal.close();
 }

}
