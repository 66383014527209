import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';
import { QuotaRadicationService } from '@app/services/radication-quota.service';
import { ListResponse } from '@app/models/listResponse.model';
@Component({
  selector: 'app-get-quota',
  templateUrl: './get-quota.component.html',
  styleUrls: ['./get-quota.component.scss']
})
export class GetQuotaComponent implements OnInit {
  public navItemTabs: any[] = [];
  public listResponse: ListResponse;
  constructor(private tabs: TabsService, public helper: HelperService, private radicationService: QuotaRadicationService) {
    this.navItemTabs = this.tabs.quotasTabs;
   }
  
  ngOnInit() {
    
  }
  
}
