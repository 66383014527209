import { CurrencyPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HelperService } from "@app/services/helper.service";
import { QuotaForm } from "../models/quotaForm.model";
import { TermType } from "../models/termType.model";

@Component({
  selector: "app-reference-quota",
  templateUrl: "./reference-quota.component.html",
  styleUrls: ["./reference-quota.component.scss"],
  providers: [CurrencyPipe],
})
export class ReferenceQuotaComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;
  @Input() terms: Array<TermType>;
  @Input() reference2: boolean;
  private quotaForm = new QuotaForm();

  constructor(
    public helper: HelperService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    let nitRef2 = this.infoStudyQuote.controls.nitRef2.value
    if(nitRef2){
      this.add();
    }
  }

  add() {
    this.reference2 = true;
  }

  remove() {
    this.reference2 = false;
    // this.infoStudyQuote.controls.nameRef2.setValue("");
    // this.infoStudyQuote.controls.nitRef2.setValue("");
    // this.infoStudyQuote.controls.cityRef2.setValue("");
    // this.infoStudyQuote.controls.phoneRef2.setValue("");
    // this.infoStudyQuote.controls.personRef2.setValue("");
    // this.infoStudyQuote.controls.positionRef2.setValue("");
    // this.infoStudyQuote.controls.clientsFrom2.setValue("");
    // this.infoStudyQuote.controls.supplies2.setValue("");
    // this.infoStudyQuote.controls.quotaRef2.setValue("");
    // this.infoStudyQuote.controls.termRef2.setValue("");
    // this.infoStudyQuote.controls.paymentMethod2.setValue("");
    // this.infoStudyQuote.controls.qualificationRef2.setValue("");
  }

  updatePipe(event: any) {
    event.srcElement.value = this.currencyPipe.transform(
      event.srcElement.value,
      "COP",
      "symbol"
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.infoStudyQuote.controls;
  }
}
