import { ActionReducerMap } from "@ngrx/store";

import * as reducers from "./reducers";

export interface AppState {
    selects: reducers.SelectsState;
    quotas: reducers.GetQuotasState;
};

export const appReducers: ActionReducerMap<AppState> = {
    selects: reducers.selectReducer,
    quotas: reducers.getQuotaReducer
};