export class BackupsTransactionsRequest {
    affiliateDocumentType: string = '';
    affiliateDocument:     string = '';
    issuerDocumentType:    string = '';
    issuerDocument:        string = '';
    contract:               number = 0;
    draftNumber:           string = '';
    initialDate:           string = '';
    finalDate:             string = '';
    currentPage:            number = 1;
    perPage:               number = 10;
    download:          boolean = false;
    authorization:          number = 0;
}
