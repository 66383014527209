import { Component, Input, OnInit } from '@angular/core';
import { ImpersonationClaim } from '../../services/natural-person-adapter.service';

@Component({
  selector: 'app-impersonation-claims-generic',
  templateUrl: './impersonation-claims-generic.component.html',
  styleUrls: ['./impersonation-claims-generic.component.scss']
})
export class ImpersonationClaimsGenericComponent implements OnInit {

  public headersTable: string[] = ['Leyenda', 'No.de Cuenta', 'Entidad'];
  @Input() impersonationClaims: ImpersonationClaim[] = [];

  constructor() { }

  ngOnInit() {
  }

}
