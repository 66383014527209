import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";
import { ModalService } from "@app/services/modal.service";
import { SubjectService } from "@app/services/subject.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-admin-covifactura-users",
  templateUrl: "./admin-covifactura-users.component.html",
  styleUrls: ["./admin-covifactura-users.component.scss"],
})
export class AdminCovifacturaUsersComponent implements OnInit {
  public link: string = "";
  public urlMicrofrontUsersCovifactura: string = "";
  public isProductionEnv: boolean = false;
  public url: SafeResourceUrl;
  public urlMicrofrontendProduction: "http://digitalcovinoc.com/fm-frontend/tecnologia/appAsrUsuarios/#/pages/administracion/usuarios";

  private confirmationSubject: Subject<boolean> = new Subject<boolean>();

  @ViewChild("iframe") iframe: ElementRef;

  constructor(
    private domSanitizer: DomSanitizer,
    public router: Router,
    public spinner: NgxSpinnerService,
    private ciCd: CiCdConfigService
  ) {
    this.ciCdInit();
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.link);
  }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    // Si no se encuentra registrado, que mande a login
    if (!user || !user.user) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnDestroy() {
    this.confirmationSubject.next();
    this.confirmationSubject.complete();
  }

  ngAfterViewInit(): void {
    this.handleRenderUsersFromAffiliate({
      user: "",
      documentType: "",
      document: "",
    });
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { production } = apis;
      this.isProductionEnv = production;
      this.urlMicrofrontUsersCovifactura = apis.urlMicrofrontUsersCovifactura;
      console.log("url microfrontend", this.urlMicrofrontUsersCovifactura);
    });
  }

  handleRenderUsersFromAffiliate(affiliate: any) {
    try {
      let { user, documentType, document } = affiliate;
      const token: string = localStorage.getItem("tokenASR") || "";
      const userCanCreate: boolean = false;
      this.link = `${this.urlMicrofrontUsersCovifactura}?numeroDocumento=${document}&tipoDocumento=${documentType}&usuario=${user}&userCanCreate=${userCanCreate}&token=${token}`;
      this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.link);
    } catch (error) {
      this.errorAlert("acceder a la página", error);
    }
  }

  errorAlert(message: string, error: any) {
    Swal.fire({
      type: "error",
      title: `No se pudo ${message}`,
      text: error ? error : "Por favor intente más tarde",
    });
  }

  closeUsersPage(value: boolean) {
    this.confirmationSubject.next(value);
  }
}
