import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditInfo, CreditStudy } from './models/credit-info.models';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CreditStudyService } from './credit-study.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { InformationContract } from '../models/ResponseContract';
import { ContractService } from '@app/services/contract.service';
import { SelectsService } from '@app/services/selects.service';

@Component({
  selector: 'app-credit-study',
  templateUrl: './credit-study.component.html',
  styleUrls: ['./credit-study.component.scss'],
})
export class CreditStudyComponent implements OnInit {
  public studyCreditForm: FormGroup;
  public creditInfo: CreditInfo = {
    courtesyStudies: 0,
    rangeStudy: 0,
    rows: [],
  };
  public from: number = 0;
  public editableFields: any[] = [];
  public numberRangeStudy = [1, 2, 3];
  public headTable = ['Desde', 'Hasta', 'Valor'];
  public identity: any;
  public user: string = '';
  public creditStudies: any[] = [];
  public affiliateName: string = '';
  public listContracts;
  public minLengthAffiliate: number = 5;

  public arrayContracts: number[] = [];

  public messageError: string = '';
  public validContact = false;

  constructor(
    public helper: HelperService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private creditStudyService: CreditStudyService,
    private contractBillingService: GloballBillingService,
    private contractService: ContractService,
    public select: SelectsService
  ) {
    this.studyCreditForm = this.fb.group({
      typeDocument: this.fb.control('', Validators.required),
      numDocument: this.fb.control('', Validators.required),
      affiliateName: this.affiliateName,
      filterOptionContract: this.fb.control('', Validators.required),
      courtesyStudies: [
        0,
        [Validators.required, Validators.min(0), Validators.max(10)],
      ],
      rangeStudy: [
        '',
        [Validators.required, Validators.min(1), Validators.max(3)],
      ],
      contract: [0, [Validators.required]],
    });
  }

  ngOnInit() {
    this.getContracts();
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
  }

  getContracts() {
    this.listContracts = [];
    const typeDoc = this.studyCreditForm.get('typeDocument').value;
    const numDoc = this.studyCreditForm.get('numDocument').value;
    if (
      typeDoc &&
      numDoc &&
      numDoc.toString().length > this.minLengthAffiliate
    ) {
      const paramsIndex = {
        typeDoc,
        numDoc,
      };
      this.contractService.setParamsIndex(paramsIndex);
      this.contractBillingService.getContract(typeDoc, numDoc).subscribe(
        (resp: any) => {
          this.studyCreditForm.controls.filterOptionContract.setValue('');
          if (resp.data && resp.data.length > 0 && resp.data[0]) {
            this.affiliateName = resp.data[0].razon_social;
            this.arrayContracts = resp.data[0].contratos;
            this.messageError = null;
          } else {
            this.messageError = 'No existen datos asociados a este documento';
            this.affiliateName = '';
            this.arrayContracts = [];
          }
        },
        (error: any) => {
          this.messageError = error.error.message;
          this.studyCreditForm.get('typeDocument').setValue('');
          this.studyCreditForm.get('filterOptionContract').setValue(null);
          this.studyCreditForm.get('numDocument').reset();
          this.affiliateName = '';
          this.arrayContracts = [];
          this.validContact = true;
          this.listContracts = [];
          this.spinner.hide();
        }
      );
    }
  }

  // Generador de filas basado en las condiciones de cortesías y rangos
  generateRows() {
    this.creditInfo.rows = [];
    this.editableFields = [];
    const courtesyStudies =
      +this.studyCreditForm.get('courtesyStudies').value || 0;
    const rangeStudy =
      +parseInt(this.studyCreditForm.get('rangeStudy').value) || 1;
    if (courtesyStudies > 0) {
      this.from = courtesyStudies;
    } else {
      this.from = 10;
    }

    let currentFrom = 1;
    let remainingCourtesyStudies = courtesyStudies;
    let currentTo = 20;
    //Caso 1
    if (courtesyStudies >= 0 && courtesyStudies < 10 && rangeStudy === 1) {
      for (let i = 0; i < rangeStudy; i++) {
        this.creditInfo.rows.push({
          from: currentFrom,
          to: 'Indeterminado',
          value: 0,
        });
        this.editableFields.push({
          fromEditable: false,
          toEditable: false,
          valueEditable: true,
        });
      }
      //Caso 2
    } else if (courtesyStudies === 10 && rangeStudy === 1) {
      for (let i = 0; i <= rangeStudy; i++) {
        if (i === rangeStudy) {
          this.creditInfo.rows.push({
            from: courtesyStudies + 1,
            to: 'Indeterminado',
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: false,
            valueEditable: true,
          });
        } else {
          this.creditInfo.rows.push({
            from: currentFrom,
            to: remainingCourtesyStudies || 10,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: false,
            toEditable: false,
            valueEditable: false,
          });
        }
      }
      //Caso 3
    } else if (
      courtesyStudies >= 0 &&
      courtesyStudies < 10 &&
      rangeStudy === 2
    ) {
      for (let i = 0; i <= rangeStudy - 1; i++) {
        if (i === rangeStudy - 1) {
          if (courtesyStudies === 0) {
            this.creditInfo.rows.push({
              from: 11,
              to: 'Indeterminado',
              value: 0,
            });
          } else {
            this.creditInfo.rows.push({
              from: courtesyStudies + 1,
              to: 'Indeterminado',
              value: 0,
            });
          }

          this.editableFields.push({
            fromEditable: true,
            toEditable: false,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 2) {
          if (courtesyStudies === 0) {
            this.creditInfo.rows.push({
              from: currentFrom,
              to: 10,
              value: 0,
            });
          } else {
            this.creditInfo.rows.push({
              from: currentFrom,
              to: courtesyStudies,
              value: 0,
            });
          }
          this.editableFields.push({
            fromEditable: false,
            toEditable: true,
            valueEditable: true,
          });
        }
      }
      //Caso 4
    } else if (courtesyStudies === 10 && rangeStudy === 2) {
      for (let i = 0; i <= rangeStudy; i++) {
        if (i === rangeStudy) {
          this.creditInfo.rows.push({
            from: courtesyStudies + 11,
            to: 'Indeterminado',
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: false,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 1) {
          this.creditInfo.rows.push({
            from: courtesyStudies+1,
            to: courtesyStudies +10 || 20,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: true,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 2) {
          this.creditInfo.rows.push({
            from: currentFrom,
            to: remainingCourtesyStudies || 10,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: false,
            toEditable: false,
            valueEditable: false,
          });
        }
      }
      //Caso 5
    } else if (
      courtesyStudies >= 0 &&
      courtesyStudies < 10 &&
      rangeStudy === 3
    ) {
      for (let i = 0; i <= rangeStudy; i++) {
        if (i === rangeStudy) {
          this.creditInfo.rows.push({
            from: currentFrom + 20,
            to: 'Indeterminado',
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: false,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 1) {
          if (courtesyStudies === 0) {
            this.creditInfo.rows.push({
              from: courtesyStudies+11,
              to: courtesyStudies+20,
              value: 0,
            });
          } else {
            this.creditInfo.rows.push({
              from: courtesyStudies+1,
              to: courtesyStudies+(20-courtesyStudies),
              value: 0,
            });
          }
          this.editableFields.push({
            fromEditable: true,
            toEditable: true,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 2) {
          if (courtesyStudies === 0) {
            this.creditInfo.rows.push({
              from: currentFrom,
              to: 10,
              value: 0,
            });
          } else {
            this.creditInfo.rows.push({
              from: currentFrom,
              to: courtesyStudies,
              value: 0,
            });
          }
          this.editableFields.push({
            fromEditable: false,
            toEditable: true,
            valueEditable: true,
          });
        }
      }
      //Caso 6
    } else if (courtesyStudies === 10 && rangeStudy === 3) {
      for (let i = 0; i <= rangeStudy; i++) {
        if (i === rangeStudy - 3) {
          this.creditInfo.rows.push({
            from: currentFrom,
            to: courtesyStudies,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: false,
            toEditable: false,
            valueEditable: false,
          });
        } else if (i === rangeStudy - 2) {
          this.creditInfo.rows.push({
            from: courtesyStudies+1,
            to: courtesyStudies+10,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: true,
            valueEditable: true,
          });
        } else if (i === rangeStudy - 1) {
          this.creditInfo.rows.push({
            from: courtesyStudies + 11,
            to: courtesyStudies + 20,
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: true,
            valueEditable: true,
          });
        } else if (i === rangeStudy) {
          this.creditInfo.rows.push({
            from: courtesyStudies + 21,
            to: 'Indeterminado',
            value: 0,
          });
          this.editableFields.push({
            fromEditable: true,
            toEditable: false,
            valueEditable: true,
          });
        }
      }
    }
    console.log('La tablita', this.creditInfo.rows);
  }

  isFormValid(): boolean {
    return (
      this.studyCreditForm.valid &&
      this.creditInfo.rows.every((row) => row.value !== null)
    );
  }

  reset() {
    this.creditInfo.rows = [];
    this.studyCreditForm.get('courtesyStudies').reset();
    this.studyCreditForm.get('rangeStudy').reset();
    this.studyCreditForm.get('filterOptionContract').reset();
    this.studyCreditForm.get('typeDocument').reset();
    this.studyCreditForm.get('numDocument').reset();
    this.affiliateName = '';
    this.messageError = '';
  }

  cleanTable() {
    this.creditInfo.rows = [];
  }

  cleanField(rowIndex: number, fieldName: string) {
    if (
      this.creditInfo.rows[rowIndex] &&
      this.creditInfo.rows[rowIndex][fieldName] !== undefined
    ) {
      this.creditInfo.rows[rowIndex][fieldName] = '';
    } else {
      console.warn('Índice o campo inválido');
    }
  }

  creditStudy() {
    this.spinner.show();
    const body = {
      contrato: parseInt(
        this.studyCreditForm.get('filterOptionContract').value
      ),
      usuario: this.user,
      estudios: this.creditInfo.rows.map((row) => ({
        desde: row.from,
        hasta: row.to,
        valor: row.value,
      })),
    };
    console.log('Este es el body', body);

    this.creditStudyService.postCreditStudy(body).subscribe(
      (creditStudies: any) => {
        this.creditStudies = creditStudies.data;
        Swal.fire({
          type: 'success',
          title: 'Estudio de crédito',
          text: 'Estudio de crédito grabado con éxito',
        });
        console.log('Este es el arreglo de facturación', this.creditStudies);
        this.spinner.hide();
      },
      (error: any) => {
        Swal.fire({
          type: 'error',
          title: 'Estudio de crédito',
          text: error.error.message,
        });
        console.error('Error al obtener datos de facturación:', error);
      }
    );
  }

  preventExtraDigits(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    if (input.value.length >= 10) {
      event.preventDefault();
    }
  }
}
