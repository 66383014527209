import { Component, OnInit } from '@angular/core';
import { DebtEvolution, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

interface TotalTable {
  headers: string[]
  row: any[]
}

@Component({
  selector: 'app-debt-evolution-history',
  templateUrl: './debt-evolution-history.component.html',
  styleUrls: ['./debt-evolution-history.component.scss']
})
export class DebtEvolutionHistoryComponent implements OnInit {

  public debtEvolutions: DebtEvolution[] = [];

  public totalTable: TotalTable = {} as TotalTable;

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.debtEvolutions = this.subjectCreditHistory.getDebtEvolution(response);
    this.generateTotalTable();
  }

  generateTotalTable() {
    let headers: string[] = [];
    const rowsTotalesPerSector: any[][] = [];

    this.debtEvolutions.forEach((table: DebtEvolution) => {
      const rowTotalPerSector: any[][] = table.accounts[table.accounts.length - 1]; // La última cuenta son los totales
      rowsTotalesPerSector.push(...rowTotalPerSector);
      if(headers.length === 0) headers = [...table.headers];
    });
    headers[0] = "Totales";
    headers[1] = "";

    let rowTotal: any[] = Array(headers.length).fill(0);
    rowTotal[0] = 'Saldos';
    rowTotal[1] = '';

    rowsTotalesPerSector.forEach((rowSector) => {
      rowSector.forEach((value, index) => {
        if(index >= 2) {
          rowTotal[index] += (typeof value === 'number') ? value : 0;
        }
      });
    });

    this.totalTable = {
      headers,
      row: rowTotal
    };
  }

}
