import { Component, OnInit } from '@angular/core';
import { AffiliateLinkService } from '@app/services/affiliate-link.service';
import { HelperService } from '@app/services/helper.service';
import { RolesService } from '@app/services/roles.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss'],
})
export class AffiliatesComponent implements OnInit {
  public dateRange: Array<any> = [];
  public imageData: Array<any> = [];
  public tabs: Array<any> = [
    {
      title: 'Habilitar Link',
      active: true,
    },
    {
      title: 'Reporte de transacciones realizadas',
      active: false,
    },
    {
      title: 'Correos notificaciones',
      active: false,
    },
/*     {
      title: 'Gestión de Links',
      active: false,
    }, */
    {
      title: 'Descarga informe link de cupos',
      active: false,
    },
  ];
  public tabsAffiliate: Array<any> = [
    {
      title: 'Habilitar Link',
      active: true,
    },
    {
      title: 'Correos notificaciones',
      active: false,
    },
    {
      title: 'Descarga informe link de cupos',
      active: false,
    },
  ];
  public totalAccessLinkAffiliate = true;
  public message = '';
  public userName: any;

  constructor(
    private affiliate: AffiliateLinkService,
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    private toastr: ToastrService,
    private roles: RolesService
  ) {}

  ngOnInit() {
    this.getProfile();
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;

  }
  selectTabAffiliate(tab: any) {
    // deactivate all tabs
    this.tabsAffiliate.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;

  }

  public onGetDateRange($event) {
    this.dateRange = $event;
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      paramIni: moment(this.dateRange[0]).format('YYYY-MM-DD'),
      paramFin: moment(this.dateRange[1]).format('YYYY-MM-DD'),
    };
    console.log(params);

    this.affiliate.getDownloadQuota(params).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.imageData = response.data;
        if (this.imageData) {
          this.helper.downloadFromBase64(
            this.imageData[0],
            'Informe_Solicitud_Cupos',
            '.xlsx'
          );
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }
      },
      (error) => {
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
        this.toastr.error(error.error.message);
      }
    );
  }

  getProfile() {
    this.userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'affiliate'
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions['link-affiliate']===true) {
      this.totalAccessLinkAffiliate = false;
    }
  }
}
