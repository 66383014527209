import { SubjectService } from "@services/subject.service";
import { Subscription, Observable } from "rxjs";
import { HostListener } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ProfileService } from "@services/profile.service";
import { SellersRequest } from "@models/request/sellersRequest.model";
import { SellersResponse } from "@models/response/sellersResponse.model";
import { UserModel } from "@models/user.model";
import { UserPhotoRequest } from "@models/userPhoto.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  public showMenu: boolean = false;
  public subscribeProfileImg: Subscription;
  public identity: any = {};
  public sellersRequest: SellersRequest = {
    idContry: "1",
    sellerUser: "",
    userDocumentType: "",
    userDocumentNumber: "",
    documentType: "",
    documentNumber: "",
    page: 1,
    registers: 3
  };

  public uploadPhotoRequest: UserPhotoRequest = new UserPhotoRequest();
  public sellersResponse: SellersResponse = new SellersResponse();
  public collapseMenu: Observable<boolean> = this.subject.stateMenu$;
  public isMobile: Observable<boolean> = this.subject.stateIsMobile$;

  public innerWidth: number;

  constructor(
    private subject: SubjectService,
    private router: Router
  ) {
    this.subscribeChangeProfileImg();
  }

  ngOnInit() {
    this.collapseMenu.subscribe((show: boolean) => (this.showMenu = show));
    this.identity = JSON.parse(localStorage.getItem("identity"));
  }


  subscribeChangeProfileImg() {
    this.subscribeProfileImg = this.subject.eventSelectedProfileImg$.subscribe(
      (img: string) => {
        this.sellersResponse.userPhoto = img;
      }
    );
  }

  collapseSidebar() {
    this.showMenu = !this.showMenu;
    this.subject.menuCollapse(this.showMenu);
  }

  goToProfile() {
    return;
    this.router.navigateByUrl("/pages/administracion/perfil");
  }
}
