import { Pipe, PipeTransform } from '@angular/core';

    @Pipe({
        name: 'percentagePipe'
    })

export class PercentagePipe implements PipeTransform {

    public transform(value: any) {
        if (value != undefined) {
            return value = value + '%';
        }
    }
}