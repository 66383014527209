export class AffiliatesResponse {
    currentPage:         number;
    message:             string;
    ok:                 boolean;
    status:              number;
    totalPages:          number;
    data: AffiliatesListModel[];
}

export class AffiliatesListModel {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    socialReason?:          string = '';
    contract:                number = 0;
    issuerDocumentType:    string = '0';
    issuerDocument:         string = '';
    issuerName:            string = '0';
    draftNumber:           string = '0';
    authorization:           number = 0;
    sinisterNumber:        string = '0';
}