import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'experianStateAccount'
})
export class ExperianStateAccountPipe implements PipeTransform {

  transform(codigo: string): string {
    const experianLists = JSON.parse(localStorage.getItem('EXPERIAN_LISTS'));

    if(experianLists) {
      const estadosCuenta = experianLists[0]['estado-cuenta'];
      const estado = estadosCuenta.find((estado: any) => estado.codigo === codigo);

      return estado ? estado.descripcion : 'Desconocido';
    }

    return null;
    
  }

}
