import { environment } from 'src/environments/environment';
import { EntryTransactionsService } from './../services/entry-transactions.service';


import { ModalService } from '@app/services/modal.service';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SelectsDataForm } from '@app/models/selectModel';
import { CoviResourceService } from '@app/services/covi-resource.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AffiliateInfoRequest, FindBuyerInfoRequest } from '../models/request/entry-transactions.request';
import { AffiliateInfoResponse, AffiliateInfoResponseData, FindBuyerInfoResponse, FindBuyerInfoResponseData } from '../models/response/entry-transactions.response';
import { EntryTransactionsTabsService } from '../services/entry-transactions-tabs.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-special-transactions',
  templateUrl: './special-transactions.component.html',
  styleUrls: ['./special-transactions.component.scss']
})
export class SpecialTransactionsComponent implements OnInit {

  @ViewChild('formGetCode') formGetCode: NgForm;
  @ViewChild('BuyerInfoForm') BuyerInfoForm: NgForm;
  @ViewChild('dayCheckForm') dayCheckForm: NgForm;
  @ViewChild('postdatedCheckForm') postdatedCheckForm: NgForm;
  @ViewChild('invoiceForm') invoiceForm: NgForm;

  @ViewChild('dayCheckContainer') dayCheckContainer: ElementRef;
  @ViewChild('postdatedCheckContainer') postdatedCheckContainer: ElementRef;
  @ViewChild('invoiceContainer') invoiceContainer: ElementRef;

  public exonerationLists: any = [];

  public daysCheck: number[] = [];

  public docTypes: any;

  public dueDate = new Date();

  public minDueDate: Date;

  public maxDueDate: any;

  public min = new Date();

  public isSecondStep: boolean = false;

  public isThirdStep: boolean = false;

  public phoneType: boolean = false;

  public bankList: Array<any>;

  public bankName: string;

  public checks: Array<any> = [];

  public invoices: Array<any> = [];

  public cities: Array<any> = [];

  public departments: any;

  public listCities: any;

  public generalLists: SelectsDataForm;

  public checksNum: string;

  public isMassiveInvoices: boolean;

  public settled: string;

  public invoicesTabs: any[] = [
    {
      href: '#one-to-one-invoices',
      description: 'Uno a uno',
      icon: 'icon-tab-individual.svg',
      key: 'one-to-one-invoices'
    },
    {
      href: '#massive-invoices',
      description: 'Masivas',
      icon: 'icon-tab-individual.svg',
      key: 'massive-invoices'
    },
  ];

  public quotaState = [
    {value: 'A', description: 'Activo'},
    {value: 'B', description: 'Bloqueado'},
    {value: 'S', description: 'Suspendido'},
    {value: 'R', description: 'Retirado'},
    {value: 'I', description: 'Inactivo'}
  ]

  public dataQueryCode: AffiliateInfoRequest = {
    queryCodeNo: '',
  };

  public dataAffiliate: AffiliateInfoResponseData = {
    name: '',
    product: '',
    branchOffice: '',
    options: '',
    contract: '',
  };

  public findBuyerInfoRequestData: FindBuyerInfoRequest = {
    documentType: '',
    document: '',
    contract: '',
    option: '',
    media: '',
    mediaValue: '',
    whoRequest: '',
  }

  public dataBuyferInfo: FindBuyerInfoResponseData = {
    name: '',
    quotaValue: '',
    totalValue: '',
    quotaState: '',
    quotaAvailable: '',
    quotaAvailableBuyer: '',
    checkValue: '',
    quotaValueBuyer: '',
    city: '',
    cityCode: '',
    municipally: '',
    address: '',
    phone: '',
    mobile: '',
    email: '',
    bank: '',
    account: '',
    demandsDayCheck: false,
    maxDays: '',
    docsMaxGuarantee: '',
  }

  public authorizeData: any = {
    phone: '',
    queryCode: '',
    titleNo: ''
  }

  public autexKey: string = '';
  public isValidatedKey: boolean = false;

  public file: File;

  public invoiceData;

  public entryTransactionsTabs: Array<string> = [];

  constructor(private quotaGreater: QuotaGreaterService,
              private listCovinoc: ListsCovinocService, private spinner: NgxSpinnerService, public helper: HelperService, private renderer: Renderer2, private coviResourceService: CoviResourceService, private modal: ModalService, private entryTransactionsTabsService: EntryTransactionsTabsService, private entryTransactionsService: EntryTransactionsService, private toastr: ToastrService) { }

  ngOnInit() {
    this.minDueDate = moment().subtract(1, 'days').toDate();
    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.exonerationLists = response.data[0];
    });
    this.quotaGreater.getBanksByCountry().subscribe((response: any) => {
      this.bankList = response.data[0];
    })
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      let allDocTypes = response.data[0].documentTypesCode;
      this.docTypes = allDocTypes.filter((item) => item.value !== 'P' && item.value !== 'T');
    });
    this.coviResourceService.getGeneralList().subscribe((response: any) => {
    });

    this.entryTransactionsTabs = this.entryTransactionsTabsService.invoicesMassiveChargues;

    this.generalList();
    this.getDepartments();
  }

  generalList() {
    this.coviResourceService.getGeneralList().subscribe((response: any) => {
      this.generalLists = response;
    },
      (err) => {
        this.listCities = [];
      });
  }

  getCityCode(city: string) {
    let cityCode: string = '';
    this.listCities.forEach(element => {
      if(city === element.description) {
        cityCode = element.value;
      }
    });
    return cityCode;
  }

  getDepartmentCode(department: string) {
    let departmentCode: string = '';
    this.departments.forEach(element => {
      if(department === element.description) {
        departmentCode = element.value;
      }
    });
    return departmentCode;
  }

  calculateChecks() {
    this.spinner.show();
    /* TODO */
    let daysCounter = 0;
    this.checks = [];
    this.daysCheck = [];
    this.quotaGreater.findCheckDay({
      query_code: this.dataQueryCode.queryCodeNo,
      buyer_document: this.findBuyerInfoRequestData.document,
      document_type_buyer: this.findBuyerInfoRequestData.documentType,
    }).subscribe((response: any) => {
      this.spinner.hide();
      if(response.data.length > 0) {
        console.log(response.data[0].cuotas_CHEQUE)
        console.log(response.data[0].cuotas_CHEQUE && response.data[0].cuotas_CHEQUE !== " ")
        const totalValue = parseFloat(this.dataBuyferInfo.totalValue);
        const checksNum = parseInt(this.checksNum, 10);
        const baseValue = Math.floor(totalValue / checksNum);
        let sumValues = 0;
        if(response.data[0].cuotas_CHEQUE && response.data[0].cuotas_CHEQUE !== " ") {
          console.log(response.data[0].cuotas_CHEQUE);
          for(let i = 0; i <= parseInt(response.data[0].cuotas_CHEQUE) - 1; i++) {
            this.daysCheck.push(i);
          }
        }
        daysCounter = parseInt(response.data[0].dias_CHEQUE);
        for (let i = 0; i < checksNum; i++) {
          let value = baseValue;
          if (i === checksNum - 1) {
            value = totalValue - sumValues;
          } else {
            sumValues += value;
          }

          this.checks.push({
            checkNumber: i + 1,
            value: value,
            postdatedDate: moment(new Date()).add(daysCounter, 'days').format("YYYY-MM-DD"),
            days: daysCounter
          });
          daysCounter += parseInt(response.data[0].dias_CHEQUE);
        }
        /* console.log(this.checks)
        console.log(this.checks)
        if(this.checks.length > 0) {
          this.dueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
          this.minDueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
          console.log(this.minDueDate);
        } */
      }
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'error',
      });
    });
  }

  calculateInvoices() {
    let daysCounter = 60 + Math.round(parseInt(this.dataBuyferInfo.maxDays));
    this.invoices = [];
    for(let i = 0; i <= 2; i++) {
      this.invoices.push({
        checkNumber: i + 1,
        value: Math.round(parseInt(this.dataBuyferInfo.totalValue) / 3),
        postdatedDate: moment(new Date()).add(daysCounter, 'days').format("YYYY-MM-DD"),
        days: daysCounter
      });
      daysCounter += 32;
    }
  }

  selectTransactionOption(option: string): void {
    if(option === 'D') {
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
      this.renderer.addClass(this.dayCheckContainer.nativeElement, 'show');
    } else if(option === 'P') {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
      this.renderer.addClass(this.postdatedCheckContainer.nativeElement, 'show');
    } else if(option === 'F') {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.addClass(this.invoiceContainer.nativeElement, 'show');
    } else {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
    }
  }

  validateQueryCode(): void {
    this.spinner.show();
    this.selectTransactionOption('C');
    const user = JSON.parse(localStorage.getItem('user'));
    this.quotaGreater.affiliateInfo(this.dataQueryCode)
      .subscribe((response: AffiliateInfoResponse) => {
        if(user.user) {
          this.spinner.hide();
          this.isSecondStep = true;
          this.findBuyerInfoRequestData.whoRequest = user.user;
          this.dataAffiliate = response.data[0];
          this.invoiceData = undefined;
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }
    }, (error) => {
      this.spinner.hide()
      this.isSecondStep = false;
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'error',
      });
    });
  }

  findBuyerInfo(): void {
    this.spinner.show()
    const user = JSON.parse(localStorage.getItem("user"));

    if(user) {
      const body: FindBuyerInfoRequest = {
        documentType: this.findBuyerInfoRequestData.documentType,
        document: this.findBuyerInfoRequestData.document,
        contract: this.dataAffiliate.contract,
        option: this.findBuyerInfoRequestData.option,
        media: this.findBuyerInfoRequestData.media,
        mediaValue: this.findBuyerInfoRequestData.mediaValue,
        whoRequest: this.findBuyerInfoRequestData.whoRequest,
      }

      this.quotaGreater.findBuyerInfo(body).subscribe((response: FindBuyerInfoResponse) => {
        this.spinner.hide();
        this.isThirdStep = true;
        this.dataBuyferInfo = response.data[0];
        this.checks = [];
        this.invoiceData = undefined;
        this.maxDueDate = new Date(moment(new Date()).add(parseInt(this.dataBuyferInfo.maxDays) + 60, 'days').format("YYYY-MM-DD"))
        this.authorizeData.titleNo = '';
        if(this.dataBuyferInfo.municipally) {
          this.selectDeparment(this.dataBuyferInfo.municipally);
        }
        if(this.dataBuyferInfo.phone) {
          this.dataBuyferInfo.phone = this.dataBuyferInfo.phone.split(' ')[1];
        }
        if(this.dataBuyferInfo.mobile) {
          this.dataBuyferInfo.mobile = this.dataBuyferInfo.mobile.split(' ')[1];
        }

        this.getBankFromId();
        this.selectTransactionOption(this.findBuyerInfoRequestData.option)
        this.dueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      })
    }
  }

  authorize() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));

    if(user) {
      let body = {
        documentType: this.findBuyerInfoRequestData.documentType,
        document: this.findBuyerInfoRequestData.document,
        user: this.findBuyerInfoRequestData.whoRequest,
        webUser: user.user,
        queryCode: this.dataQueryCode.queryCodeNo,
        name: this.dataBuyferInfo.name,
        bank: this.dataBuyferInfo.bank,
        account: this.dataBuyferInfo.account || 0,
        checkValue: this.dataBuyferInfo.totalValue || 0,
        phone: this.dataBuyferInfo.phone || 0,
        mobile: this.dataBuyferInfo.mobile || 0,
        address: this.dataBuyferInfo.address,
        city: this.getCityCode(this.dataBuyferInfo.city),
        municipally: this.getDepartmentCode(this.dataBuyferInfo.municipally),
        cityCode: this.dataBuyferInfo.cityCode,
        totalValue: this.dataBuyferInfo.totalValue || 0,
        titleNo: this.authorizeData.titleNo,
        dueDate: moment(this.dueDate).format('DDMMYYYY'),
        option: this.findBuyerInfoRequestData.option,
        password: this.autexKey,
      }

      this.quotaGreater.authorize(body).subscribe((response: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: `${response.data[0].message}`,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      })
    }
  }

  changeMediaType() {
    this.findBuyerInfoRequestData.mediaValue = '';
  }

  changePhoneType(event): void {
    const { value } = event.target;
    this.phoneType = (value === 'phone') ? true : false;
    //this.dataBuyferInfo.phone = '';
  }

  changePhone() {
    this.dataBuyferInfo.phone = '';
  }

  private getBankFromId() {
    if(this.dataBuyferInfo.bank) {
      this.bankList.forEach((item) => {
        if(this.dataBuyferInfo.bank.toString() === item.value) {
          this.bankName = item.description;
        }
      });
    }
  }

  getDepartments() {
    this.coviResourceService.getMunicipalities().subscribe((departments: any) => {
      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  selectDeparment(selectedDepartment) {
    const departmentToSearch = this.departments.filter((item ) => item.description === selectedDepartment)
    if(departmentToSearch.length > 0) {
      this.dataBuyferInfo.city = "";
    this.coviResourceService.getCities(departmentToSearch[0].value || '').subscribe(
      (municipalities: any) => {
        this.listCities = municipalities.data[0].cities || [];
      },
      (err) => {
        this.listCities = [];
      });
    }
  }

  validateAutexKey(event) {
    this.quotaGreater.validateAutexKey(
      {
        password: this.autexKey,
        code: this.dataQueryCode.queryCodeNo
      }
    ).subscribe((response: any) => {

      if(response.data[0] === 1) {
        this.isValidatedKey = true;
        this.toastr.success(response.message)
      } else {
        this.isValidatedKey = false;
        this.toastr.error('Clave Autex no validada correctamente')
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      });
  }

  selectCityCode() {
    let cityDescription = this.listCities.filter((item) => item.description === this.dataBuyferInfo.city);
    this.dataBuyferInfo.cityCode = cityDescription[0].value;
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes")
  }

  seleccionArchivo(archivo: File) {
    this.file = archivo;
  }

  downloadTemplate() {
    //window.open(http://desarrollo02.c0v.co:8080/ServiciosCovifacturaDSRL/ver/DocComerciales/formato_carga.xlsx, '_newtab');}
    window.open(`${environment.urlInvoicesTemplate}/ver/DocComerciales/formato_carga.xlsx`, "_newtab");
  }

  search() {
    this.spinner.show();
    this.quotaGreater.invoiceMassiveChargue(this.file).subscribe((response: any) => {
      this.spinner.hide();
      this.invoiceData = response.data[0];

        if(this.invoiceData.correctRecords) {
          if(this.invoiceData.errorRecords.length > 0) {
            Swal.fire({
              title: `Tienes errores en algunos de los registros`,
              text: `Por favor corregir y subir el archivo nuevamente`,
              type: 'error',
            });
          } else {
            Swal.fire({
              title: `${response.message}`,
              text: ``,
              type: 'success',
            });
          }
        }
    }, (error) => {
      this.spinner.hide();
      if(error.error) {
        Swal.fire({
          title: `${error.error.message || 'Error interno de servidor'}`,
          text: ``,
          type: 'error',
        });
      }
    });
  }

  selectInvoiceOption(item) {
    this.isMassiveInvoices = (item.key === 'massive-invoices') ? true : false;
  }

  insertMassiveInvoices() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));
    if(user.user) {
      const bodyParams = {
        usuario: user.user,
        documento: this.findBuyerInfoRequestData.document,
        tipoDocumento: this.findBuyerInfoRequestData.documentType
      }
      this.quotaGreater.invoiceMassiveChargueInsert(this.invoiceData.correctRecords, bodyParams).subscribe((response: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        if(error) {
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'error',
          });
        }
      });
    }
  }

  downloadInvoices() {
    this.spinner.hide();

    const bodyParams = {
      estado: 'Negado',
      documento: this.findBuyerInfoRequestData.document,
      tipoDocumento: this.findBuyerInfoRequestData.documentType,
      radicado: this.settled
    }

    this.quotaGreater.downloadTransactions(bodyParams).subscribe((response: any) => {
      this.spinner.hide();
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
      if(response.data[0]) {
        this.helper.downloadFromBase64(response.data[0], 'Informe_Solicitud_Cupos', '.xlsx');
      }
      this.helper.downloadFromBase64(response.data[0], 'Informe_Solicitud_Cupos', '.xlsx');
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'error',
      });
    });
  }

}
