export class DataSearchMultipleFilesBuyer {
    id = null;
    source = null;
    user = null;
    documentType: string;
    document: number;
    originalName = null;
    creationDate = null;
    category = null;
    infoFile = null;
    originalPath = null;
    originalFinalName = null;
    resourceId = null
    currentPage: number;
    totalPages = 10;

}

export class HeadersTableFiles {
    static headersFile = [
        {
            name: 'Documento'
        },
        {
            name: 'Fuente'
        },
        {
            name: 'Usuario'
        },
        {
            name: 'Nombre Archivo'
        },
        {
            name: 'Fecha'
        },
        {
            name: 'Categoria'
        },
        {
            name: 'visualizar'
        }
    ]
}

export class DataSendFiles {
    id?: number;
    source?: string;
    user?: string;
    documentType?: string;
    document?: number;
    originalName?: string;
    creationDate?: string;
    category?: string;
    infoFile?: string;
    originalPath?: any;
    originalFinalName?: string;
    resourceId?: any;

}

export class DataFileTable {
    id: number;
    source: string;
    user: string;
    documentType: string;
    document: number;
    originalName: string;
    creationDate: string;
    category: string;
    infoFile: string;
    originalPath: any;
    originalFinalName: string;
    resourceId: any;
    fileExtension: string;
    currentPage: any;
    totalPages: any;
}

export class DataFileTableReceived {
    ok: boolean;
    message: string;
    data: DataFileTable [];
    status: number;
    currentPage: number;
    totalPages: number;
}

export  class DataSendImages {
    images : DataImages[];
}

export class DataImages {
    document: number;
    documentType: string;
    source: string;
    userName: string;
    fileName: string;
    fileExtension: string;
    category: string;
    image: string;
}
