import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RelationEcBuyer } from "../models/relationEcBuyer.model";
import { RelationEcBuyerRequest } from "../models/request/relationEcBuyerRequest.model";
import { RelationEcBuyerResponse } from "../models/response/relationEcBuyerReponse.model";
import { QuotaService } from "@app/pages/quotas/services/quota-greateer.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HolderTValidations } from "../models/holderTValidations.model";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import { Response } from "@app/models/responseUtil.model";
import { HolderValidations } from "../models/holderValidations.model";

@Component({
  selector: "app-holder-related-validations",
  templateUrl: "./holder-related-validations.component.html",
  styleUrls: ["./holder-related-validations.component.scss"],
})
export class HolderRelatedValidationsComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;

  states = [];
  editField: string;
  propertySaved: string;
  personList: Array<any> = [];
  idFieldPerson: number = 0;
  financialQuery = false;

  private relationEcBuyer = new RelationEcBuyer();
  private relationEcBuyerResponse = new RelationEcBuyerResponse();

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private quotaService: QuotaService,
    private quotaGreaterService: QuotaGreaterService
  ) {
    this.states = this.quotaService.states;
  }

  ngOnInit() {
    this.getHolderRelated();
    if (
      this.infoStudyQuote.get("financialInfo") &&
      this.infoStudyQuote.get("financialInfo").value
    )
      this.onChangesFinancialSources();
  }

  /**
   * Is in charge of filling out The Holder Related when information is found
   * in other forms of 20m in consultation of financial information
   * @author Rafael Ramirez
   */
  onChangesFinancialSources() {
    this.infoStudyQuote
      .get("holderRelated")
      .valueChanges.subscribe((holderValidations) => {
        let updatedPersonsList = [];
        holderValidations.forEach(
          (holderValidation: HolderValidations, index: number) => {
            let person = {
              id: index + 1,
              docType: holderValidation.partnerTypeDoc,
              doc: holderValidation.partnerDoc,
              name: holderValidation.partnerName,
              relation: holderValidation.idRelationship,
              afilliate:
                holderValidation.affiliateState != undefined ? "SI" : "NO",
              sagralaft:
                holderValidation.sagralaft != undefined &&
                holderValidation.sagralaft
                  ? holderValidation.sagralaft
                  : "NO REGISTRA",
              classification:
                holderValidation.calification != undefined &&
                holderValidation.calification
                  ? holderValidation.calification
                  : "NO REGISTRA",
              centralState:
                holderValidation.centralState != undefined ? "NORMAL" : "NO",
              editable: false,
              promissoryNote: this.quotaService.stateToShow(
                holderValidation.promissoryNote
              ),
              cobra: this.quotaService.stateToShow(holderValidation.cobra),
              railingSS: this.quotaService.stateToShow(
                null,
                holderValidation.idRailingSs
              ),
              hoursBeen: this.quotaService.stateToShow(
                null,
                holderValidation.idBeenInArrears
              ),
              flag: true,
            };
            updatedPersonsList.push(person);
          }
        );
        this.personList = updatedPersonsList;
        this.financialQuery = true;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.infoStudyQuote.controls;
  }

  updateList(id: number, property: string, event: any) {
    let value;
    if (property == "railingSS" || property == "hoursBeen"
      || property == "cobra" || property == "promissoryNote" ) {
      value = event.target.value;
    } else {
      value = event.target.textContent;
      event.target.textContent = value;
    }

    if (this.financialQuery) {
      id++;
    }

    this.personList[id][property] = value;
    this.infoStudyQuote.controls.holderRelated.setValue(this.personList);
    return;
  }

  getHolderRelated() {
    this.relationEcBuyer.docGir = this.infoStudyQuote.controls.document.value;
    this.relationEcBuyer.docTypeGir =
      this.infoStudyQuote.controls.docType.value;

    let listHolderRelated = this.infoStudyQuote.controls.holderRelated.value;

    if (listHolderRelated.length > 0) {
      this.personList = listHolderRelated;
      return;
    }

    this.quotaGreaterService
      .getHolderRelatedValidations(this.relationEcBuyer)
      .subscribe((resp: Response<RelationEcBuyer>) => {
        if (resp.data && resp.data.length > 0) {
          this.updateValidations(resp.data);
        }
      });
  }

  convertDocumentType(value: String) {
    switch (value) {
      case "N":
        return "Nit";
      case "C":
        return "CC";
      case "E":
        return "CE";
      case "P":
        return "Pasaporte";
      default:
        return "";
    }
  }

  updateValidations(list: Array<RelationEcBuyer>) {
    list.forEach((element) => {
      this.quotaGreaterService
        .getHolderRelatedValidationsAll(element.docRela, element.docTypeRela)
        .subscribe((resp: Response<HolderTValidations>) => {
          if (resp.data && resp.data.length > 0) {
            this.updateTable(element, resp.data[0]);
          }
        });
    });
    this.infoStudyQuote.controls.holderRelated.setValue(this.personList);
  }

  updateTable(
    relationEcBuyer: RelationEcBuyer,
    validations: HolderTValidations
  ) {
    this.idFieldPerson = this.idFieldPerson + 1;
    let person = {
      id: this.idFieldPerson,
      docType: this.convertDocumentType(relationEcBuyer.docTypeRela),
      doc: relationEcBuyer.docRela,
      name: relationEcBuyer.name,
      relation: relationEcBuyer.relation,
      afilliate: validations.queryCodeDTO,
      sagralaft: validations.clintonListDTO,
      classification: validations.buyerCalificationDTO,
      centralState: validations.legalPersonDTO,
      editable: false,
      flag: false,
      promissoryNote: "N",
    };
    this.personList.push(person);
  }

  fieldPropertyAdd(value: string): string {
    return value;
  }

  remove(id: any) {
    this.personList.splice(id, 1);
  }

  add() {
    this.idFieldPerson = this.idFieldPerson + 1;
    const person = { id: this.idFieldPerson, editable: true, flag: false };
    this.personList.push(person);
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
    this.propertySaved = property;
  }

  changeValueSelect(id: number, property: string, event: any) {
    this.editField = event.target.value;
    this.propertySaved = property;
  }
}
