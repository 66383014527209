export class PaymentManagementRequestModel {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    issuerDocumentType:    string = '0';
    issuerDocument:        string = '0';
    branchOffice:   string = 'NACIONAL';
    contract:                number = 0;
    initialDate:            string = '';
    finalDate:              string = '';
    sinisterStatus:    string = 'TODOS';
    currentPage:             number = 1;
    perPage:                number = 10;
    download:           boolean = false;
    sinisterNumber:        string = '0';
    draftNumber:           string = '0';
}


