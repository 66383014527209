import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UploadFileComponent } from "@app/components/upload-file/upload-file.component";
import { DataSendFiles } from "@app/pages/buyer-report/models/buyer-report.model";
import { ServicesBuyerReportService } from "@app/pages/buyer-report/services/services-buyer-report.service";
import { ParamsAffiliateStoreService } from "@app/pages/net-factoring/services/params-affiliate-store.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { NetFactoringBackEndService } from "@app/services/net-factoring-back-end.service";
import { RolesService } from "@app/services/roles.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { Filelist } from "../../../pages/net-factoring/models/invoice-status.model";
/* D:\projects\Covinoc\admin-sector-real\src\app\pages\net-factoring */

@Component({
  selector: "app-modal-invoices-status",
  templateUrl: "./modal-invoices-status.component.html",
  styleUrls: ["./modal-invoices-status.component.scss"],
})
export class ModalInvoicesStatusComponent implements OnInit {
  public invoicesForm: FormGroup;

  isAddDocument = false;
  isChangeStatus = false;

  filesInvoices: any[] = [];

  /* (click)="downloadFile(document)" */

  public toggleFixUploadFile: Array<boolean> = [];

  @ViewChild("document") document: UploadFileComponent;
  public extensions: string =
    ".gif,.jpeg,.jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.txt,.msg";
  public bytes: number = 10000000000000;

  selectOptionsDocumentTypes: any[] = [];
  public files: any[] = [];
  public isAbleToCorrect: boolean = false;
  public fileList: Filelist = {};
  public fileInvoiceSelected: any;
  public fileToConvert: any;
  public flagList: Array<any> = [];

  mockFilesInvoices: any[] = [
    {
      documentype: "PDF",
      id: "284",
      document: 199,
      originalName: "ASR_Covinoc.pdf",
      user: "mario",
      creationDate: "2023-10-18T16:26:33.764Z",
      category: "CAJAYA",
      infoFile:
        "data:application/pdf;base64,JVBERi0xLjQKJcfsj6IKNSAwIG9iago<...>",
    },
    {
      documentype: "DOCX",
      id: "285",
      document: 200,
      originalName: "Budget_Report_2023.docx",
      user: "luisa",
      creationDate: "2023-10-19T11:00:00.000Z",
      category: "FINANZAS",
      infoFile:
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,UEsDBBQABgAIAAAAIQDfpMnK0gEA<...>",
    },
    {
      documentype: "XLSX",
      id: "286",
      document: 201,
      originalName: "Sales_Data_2023.xlsx",
      user: "carla",
      creationDate: "2023-10-20T09:30:45.123Z",
      category: "VENTAS",
      infoFile:
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,5Y+R5a2X5Lqs6YOo5oCB5L+h5oGv<...>",
    },
  ];

  filtersModal = [
    {
      id: "R",
      name: "Rechazado",
      label: "Rechazado",
    },
    {
      id: "A",
      name: "Aprobado",
      label: "Aprobado",
    },
    {
      id: "P",
      name: "Pendiente",
      label: "Pendiente",
    },
  ];

  public get isAllFilesUploaded(): boolean {
    return Object.keys(this.fileList).length === 5;
  }

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private roles: RolesService,
    private serviceFile: ServicesBuyerReportService,
    private netFactoringService: NetFactoringBackEndService,
    private paramsStoreService: ParamsAffiliateStoreService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getDocumentType();
    this.getRoleUser();
    this.getFilesInvoices();
  }

  getDocumentType() {
    let data = JSON.parse(localStorage.getItem("covi-utils"));
    if (data) {
      data.documentTypesCode.forEach((element) => {
        this.selectOptionsDocumentTypes.push(element);
      });
    }
  }

  getRoleUser() {
    let userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (userName) {
      this.roles.findByUsername(userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "net-factoring"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (this.modal.payload.estado == "P") {
      if (permissions.actions.ADD_DOCUMENT) {
        this.isAddDocument = permissions.actions.ADD_DOCUMENT;
      }
      if (permissions.actions.CHANGE_STATUS_INVOICES) {
        this.isChangeStatus = permissions.actions.CHANGE_STATUS_INVOICES;
        this.f.state.enable();
      }
    }
  }

  toggleFixDocument(indexFileInvoice, itemFileInvoice) {
    //this.fileInvoiceSelected = item;
    this.isAbleToCorrect = false;
    this.fileInvoiceSelected = itemFileInvoice;
    console.log(indexFileInvoice);
    this.toggleFixUploadFile.forEach((element, index) => {
      if (index == indexFileInvoice) {
        this.toggleFixUploadFile[index] = !this.toggleFixUploadFile[index];
      } else {
        this.toggleFixUploadFile[index] = false;
      }
    });
    console.log(this.toggleFixUploadFile);
  }

  getFilesInvoices() {
    this.spinner.show();
    let filter: DataSendFiles = {
      document: this.modal.payload.id,
      category: "CAJAYA",
    };
    /* TODO: Delete this line */
    /* this.filesInvoices = this.filesInvoices.concat(this.mockFilesInvoices); */
    this.serviceFile.postSearchFilesBuyer(filter).subscribe(
      (resp: any) => {
        this.spinner.hide();
        for(let i = 0; i < resp.data.length; i++) {
          this.toggleFixUploadFile.push(false);
        }
        console.log(this.toggleFixUploadFile);
        
        return this.filesInvoices = resp.data;



      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  public notify(itemFileInvoice, idFile): void {
    console.log(itemFileInvoice);
    this.spinner.show();
    const storage = localStorage.getItem("CAJAYA_FLAG");

    this.netFactoringService.postSaveFiles(itemFileInvoice, idFile).subscribe(
      (response: any): any => {
        if (response) {
          if (storage) {
            localStorage.setItem("CAJAYA_FLAG", JSON.stringify([...JSON.parse(localStorage.getItem("CAJAYA_FLAG")), { id: itemFileInvoice.id }]));
            this.flagList = JSON.parse(localStorage.getItem("CAJAYA_FLAG"));
          } else {
            localStorage.setItem("CAJAYA_FLAG", JSON.stringify([{ id: itemFileInvoice.id }]));
          }
          Swal.fire({
            type: "success",
            title: response.message,
          }).then(() => {
            this.paramsStoreService.alterListInvoices(response.data);
          });
          this.spinner.hide()
        }
      },
      (error) => {
        Swal.fire({
          type: "error",
          title: "Error",
          text: `${error.error.message}`,
        });
        this.spinner.hide();
      }
    );
  }

  verifyIsFlag(fileInvoiceId) {
    const storage = localStorage.getItem("CAJAYA_FLAG");
    if (storage) {
      this.flagList = JSON.parse(localStorage.getItem("CAJAYA_FLAG"));
      return this.flagList.some((element) => element.id === fileInvoiceId);
    }
    return false;
  }

  createForm() {
    this.invoicesForm = this.fb.group({
      typeDocument: [
        { value: this.modal.payload.tipoDocumentoAfiliado, disabled: true },
      ],
      document: [
        { value: this.modal.payload.numeroDocumentoAfiliado, disabled: true },
      ],
      numberInvoices: [
        { value: this.modal.payload.numeroFactura, disabled: true },
      ],
      emitedDate: [
        {
          value: moment(this.modal.payload.fechaEmision).format("YYYY-MM-DD"),
          disabled: true,
        },
      ],
      negotiationRate: [
        { value: this.modal.payload.tasaNegociacion, disabled: true },
      ],
      availableNegotation: [
        { value: this.modal.payload.disponibleNegociacion, disabled: true },
      ],
      finalNegotiatedValue: [
        { value: this.modal.payload.valorFinalNegociado, disabled: true },
      ],
      codigoConsulta: [
        { value: this.modal.payload.codigoConsulta, disabled: true },
      ],
      dayExpiration: [
        { value: this.modal.payload.diasAVencimiento, disabled: true },
      ],
      discountTotal: [
        { value: this.modal.payload.totalDescontado, disabled: true },
      ],
      administrativeFees: [
        { value: this.modal.payload.disponibleNegociacion, disabled: true },
      ],
      dateExpiration: [
        {
          value: moment(this.modal.payload.fechaVencimiento).format(
            "YYYY-MM-DD"
          ),
          disabled: true,
        },
      ],
      state: [{ value: this.modal.payload.estado, disabled: true }],
      deductibleCovifactura: [
        { value: this.modal.payload.deducibleCovifactura, disabled: true },
      ],
      deductibleValueCovifactura: [
        { value: this.modal.payload.deducibleCovifacturaValor, disabled: true },
      ],
      invoiceValue: [
        { value: this.modal.payload.valorFactura, disabled: true },
      ],
      negotiationValueRate: [
        { value: this.modal.payload.tasaNegociacionValor, disabled: true },
      ],

      observation: [{ value: this.modal.payload.observation, disabled: false }],
    });
  }

  get f() {
    return this.invoicesForm.controls;
  }

  closeModal() {
    this.modal.close();
  }

  getDataUser(): string {
    let user = JSON.parse(localStorage.getItem("identity"))
      ? JSON.parse(localStorage.getItem("identity"))
      : {};
    return user.username;
  }

  async addNotifCompradorDocument(files) {
    await this.addDocumentWithType(files, "notifComprador");
  }

  async addFacturaPDFDocument(files) {
    await this.addDocumentWithType(files, "facturaPDF");
  }

  async addEndosoPDFDocument(files) {
    await this.addDocumentWithType(files, "endosoPDF");
  }

  async addXmlDocument(files) {
    await this.addDocumentWithType(files, "xml");
  }

  async addTrazabilidadDocument(files) {
    await this.addDocumentWithType(files, "trazabilidad");
  }

  async addAdjustDocument(files) {
    await this.adjustDocument(files);
  }

  async addDocumentWithType(files, documentType: string) {
    files.forEach(async (file) => {
      let currentFiles = {};
      let idNegociacion = this.modal.payload.idNegociacion;
      let id = this.modal.payload.id;
      let contract = this.f.codigoConsulta.value;
      let user = this.getDataUser();
      await Promise.resolve(
        this.helper.convertFileToBase64(file).then(function (value) {
          currentFiles = {
            id: idNegociacion,
            documentype: documentType,
            document: id,
            numberContract: contract,
            originalName: file.name,
            creationDate: new Date(),
            category: "CAJAYA",
            infoFile: value,
            user: user,
          };
        })
      );
      this.fileList[documentType] = currentFiles;
      console.log(this.fileList);
      
      this.convertToFilesFormat();
      
    });
  }

  /* selectFileInvoice(item) {
    this.fileInvoiceSelected = item;
    this.file
  } */

  adjustDocument(files) {
    console.log(files);
    files.forEach(async (file) => {
      let currentFiles = {};
      let idNegociacion = this.modal.payload.idNegociacion;
      let id = this.modal.payload.id;
      let contract = this.f.codigoConsulta.value;
      let user = this.getDataUser();
      await Promise.resolve(
        this.helper.convertFileToBase64(file).then(function (value) {
          console.log(files[0].name.split("."));
          currentFiles = {
            id: idNegociacion,
            documentype: files[0].name.split(".")[1].toUpperCase(),
            document: id,
            numberContract: contract,
            originalName: file.name,
            creationDate: new Date(),
            category: "CAJAYA",
            infoFile: value,
            user: user,
          };
        })
      );

      
      this.fileToConvert = currentFiles;
      console.log(currentFiles);
      console.log(this.fileInvoiceSelected);
      this.isAbleToCorrect = true;
      /* this.fileList[fileToChange] = currentFiles; */
      /* this.convertToFilesFormat(); */
    });
  }

  async onSubmitCorrectFile() {
    this.spinner.show();

    const currentFileInvoiceSelected = {
      id: this.fileInvoiceSelected.id,
      documentype: this.fileInvoiceSelected.documentype,
      document: this.fileInvoiceSelected.document,
      numberContract: this.fileInvoiceSelected.numberContract,
      originalName: this.fileInvoiceSelected.originalName,
      creationDate: this.fileInvoiceSelected.creationDate,
      category: this.fileInvoiceSelected.category,
      infoFile: this.fileInvoiceSelected.infoFile,
      user: this.fileInvoiceSelected.user,
    };
    
    const body = {
      documents : [
        {
          idDocument: this.fileInvoiceSelected.id,
          document: {...this.fileToConvert}
        }
      ]
    };

    this.netFactoringService
      .correctDocuments(body)
      .subscribe(
        (resp: any) => {
          if (resp) {
            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
            }).then(() => {
              this.modal.close();
            });
          }
          this.spinner.hide();
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: `${error.error.message}`,
          }).then(() => { });
          this.spinner.hide();
        }
      );
  }



  convertToFilesFormat() {
    Object.keys(this.fileList).forEach((key, index) => {
      this.files[index] = this.fileList[key];
    });
  }

  getFilesWErrorFromComponent($event, documentType) {
    delete this.fileList[documentType];
    this.addNotifCompradorDocument($event);
  }

  getFacturaPDFErrorFilesFromComponent($event, documentType) {
    delete this.fileList[documentType];
    this.addFacturaPDFDocument($event);
  }

  getEndosoPDFErrorFilesFromComponent($event, documentType) {
    delete this.fileList[documentType];
    this.addEndosoPDFDocument($event);
  }

  getXmlErrorFilesFromComponent($event, documentType) {
    delete this.fileList[documentType];
    this.addXmlDocument($event);
  }

  getTrazabilidadErrorFilesFromComponent($event, documentType) {
    delete this.fileList[documentType];
    this.addTrazabilidadDocument($event);
  }

  downloadFile($event) {
    this.helper.downloadFromBase64(
      $event.infoFile.split(";")[1].split(",")[1],
      $event.originalName,
      "." + $event.fileExtension
    );
  }

  async onSubmit() {
    this.spinner.show();
    let body = {
      states: this.f.state.value,
      files: this.files,
      observation: this.f.observation.value,
      userUpdate: this.getDataUser()
    };

    this.netFactoringService
      .updateInvoices(this.modal.payload.id, body)
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
            }).then(() => {
              this.paramsStoreService.alterListInvoices(resp.data);
              this.modal.close();
            });
          }
          this.spinner.hide();
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: `${error.error.message}`,
          }).then(() => { });
          this.spinner.hide();
        }
      );
  }
}
