import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-legal-global-debt-classified-table',
  templateUrl: './legal-global-debt-classified-table.component.html',
  styleUrls: ['./legal-global-debt-classified-table.component.scss']
})
export class LegalGlobalDebtClassifiedTableComponent implements OnInit, OnChanges {

  @Input() globalDebtClassifiedDataTable: any;
  groupedData: any = {};

  constructor() { }

  ngOnInit() {
    console.log('Global Debt Classified Data:', this.globalDebtClassifiedDataTable);
    this.groupDataBySector();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Changes:', changes);
    if (changes.globalDebtClassifiedDataTable && changes.globalDebtClassifiedDataTable.currentValue) {
      this.groupDataBySector();
    }
  }

  groupDataBySector() {
    if (Array.isArray(this.globalDebtClassifiedDataTable)) {
      this.groupedData = this.globalDebtClassifiedDataTable.reduce((acc: any, curr: any) => {
        const sector = curr.Entidad.sector.toString().trim();
        if (!acc[sector]) {
          acc[sector] = { data: [], totalCreditos: 0, totalSaldoPendiente: 0 };
        }
        acc[sector].data.push(curr);
        acc[sector].totalCreditos += curr.numeroCreditos || 0;
        acc[sector].totalSaldoPendiente += curr.saldoPendiente || 0;
        return acc;
      }, {});
    } else if (typeof this.globalDebtClassifiedDataTable === 'object' && this.globalDebtClassifiedDataTable !== null) {
      // Si es un objeto, convierte a un array antes de agrupar
      const dataArray = Object.values(this.globalDebtClassifiedDataTable);
      this.groupedData = dataArray.reduce((acc: any, curr: any) => {
        const sector = curr.Entidad.sector.toString().trim();
        if (!acc[sector]) {
          acc[sector] = { data: [], totalCreditos: 0, totalSaldoPendiente: 0 };
        }
        acc[sector].data.push(curr);
        acc[sector].totalCreditos += curr.numeroCreditos || 0;
        acc[sector].totalSaldoPendiente += curr.saldoPendiente || 0;
        return acc;
      }, {});
    } else {
      console.error('globalDebtClassifiedDataTable no es un array ni un objeto válido:', this.globalDebtClassifiedDataTable);
      this.groupedData = {}; // Opcional: Limpia groupedData si no es un array ni un objeto válido
    }
    console.log('Grouped Data:', this.groupedData);
  }

}