import { Component, Input, OnInit } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-initial-exhibition',
  templateUrl: './initial-exhibition.component.html',
  styleUrls: ['./initial-exhibition.component.scss']
})
export class InitialExhibitionComponent implements OnInit {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }
}
