import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'experianCodeSector'
})
export class ExperianCodeSectorPipe implements PipeTransform {

  transform(codigo: string): string {
    // Leer los datos de localStorage
    const experianLists = localStorage.getItem('EXPERIAN_LISTS');

    if (!experianLists) {
      return 'Data no encontrada';
    }

    // Parsear la data de JSON
    const data = JSON.parse(experianLists);

    // Encontrar la lista de código-sector
    const codigoSectorList = data[0]['codigo-sector'];

    if (!codigoSectorList) {
      return 'Lista de código-sector no encontrada';
    }

    // Buscar la descripción por código
    const sector = codigoSectorList.find((item: { codigo: string; descripcion: string }) => item.codigo === codigo);

    // Devolver la descripción si se encuentra, de lo contrario, devolver un mensaje predeterminado
    return sector ? sector.descripcion : 'Código no reconocido';
  }
}
