import { Component, Input, OnInit } from "@angular/core";
import { QuotaHeadingTablesService } from "../../services/quota-heading-tables.service";
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: "app-financial-information",
  templateUrl: "./financial-information.component.html",
  styleUrls: ["./financial-information.component.scss"],
})
export class FinancialInformationComponent implements OnInit {
  @Input() quotaData: any;
  @Input() generalBalanceList: Array<any>;
  public firstYear: string = "";
  public secondYear: string = "";
  public generalBalancelistKeys: Array<string> = [];
  public financialInformation: Array<string> = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.generalBalanceList.length > 0) {
      console.log(this.generalBalanceList);
      
      this.getYears();
    }
  }

  getYears() {
    let yearsList: Array<any> = [];
    yearsList = Object.keys(this.generalBalanceList[0]);
    console.log(yearsList);
    this.generalBalancelistKeys = yearsList;
    this.firstYear = yearsList[0];
    this.secondYear = yearsList[1];
  }
}
