import { Component, Input, OnInit } from '@angular/core';
import { PersonInformationFromCompleteBasicInformation } from '../../services/legal-person-adapter.service';
import { DocumentValidity } from '../../models/expirian-lists.model';
import { ExpirianListsService } from '../../services/expirian-lists.service';

@Component({
  selector: 'app-legal-person-basic-information',
  templateUrl: './legal-person-basic-information.component.html',
  styleUrls: ['./legal-person-basic-information.component.scss']
})
export class LegalPersonBasicInformationComponent implements OnInit {

  @Input() personInformation: PersonInformationFromCompleteBasicInformation = {} as PersonInformationFromCompleteBasicInformation;

  constructor(private expirianList: ExpirianListsService) { }

  ngOnInit() {
  }

  getLabelDocumentValidity(code: string) {
    const result: DocumentValidity | undefined = this.expirianList.findDocumentValidityByCode(code);
    return result ? result.descripcion : code;
  }

}
