import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { OperationService } from '@services/operation.service';
import { ExonerationService } from '@services/exoneration.service';
import { NoticeService } from '@services/notice.service';
import { ModalService } from '@services/modal.service';
import { IndividualOperationRequest } from '@models/request/individualOperationRequest.model';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { OperationItem } from '@models/operationItem.model';
import { ExonerateGuaranteRequest } from '@models/request/exonerateGuaranteRequest.model';
import { CONSTANTS } from '@config/constants';
import * as moment from 'moment';
import { PubSubService } from '@services/pubsub.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-individual-operation',
  templateUrl: './tab-individual-operation.component.html',
  styleUrls: ['./tab-individual-operation.component.scss'],
})
export class TabIndividualOperationComponent implements OnInit, OnDestroy {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public individualOperationRequest: IndividualOperationRequest = {
    page: 1,
    search: '',
    endDate: moment().add(1, 'month').toDate(),
    startDate: moment().toDate(),
  };
  private reloadSub: Subscription;
  public totalPages: number = 1;
  public pageLimit: number = 5;
  public operationType: number = 1;
  public minInput: Date = moment().subtract(2, 'year').toDate();
  public maxInput: Date = moment().add(2, 'years').toDate();

  public someElementChecked = false;
  public operations: Array<OperationItem> = [];
  public selectedOperations: Array<OperationItem> = [];
  public totalNoticeAvailable: boolean = false;
  public allCheck: boolean = false;

  constructor(
    public helper: HelperService,
    public operationService: OperationService,
    public exonerationService: ExonerationService,
    public noticeService: NoticeService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private pubSubService: PubSubService
  ) {}

  ngOnInit() {
    this.reloadSub = this.pubSubService.messageSent.subscribe(() => {
      this.paginationComponent.reset();
      this.individualOperationRequest = {
        page: 1,
        search: '',
        endDate: moment().add(1, 'month').toDate(),
        startDate: moment().toDate(),
      };
      this.listOperations();
    });

    this.listOperations();
  }

  ngOnDestroy() {
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
      this.reloadSub = null;
    }
  }

  listOperations() {
    this.selectedOperations = [];
    this.spinner.show();
    this.operationService
      .getOperationList(this.individualOperationRequest)
      .subscribe(
        (operations: Array<OperationItem>) => {
          this.operations = operations;
          this.totalPages =
            operations.length > 0
              ? Math.ceil(
                  parseInt(operations[0].numberRecords) / this.pageLimit
                )
              : 1;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  search(dateRange: Array<Date>) {
    if (dateRange[0]) {
      this.individualOperationRequest.startDate = dateRange[0];
    }
    if (dateRange[1]) {
      this.individualOperationRequest.endDate = dateRange[1];
    }
    if (dateRange[0] && dateRange[1]) {
      this.listOperations();
    }
  }

  pageSelected(page: any) {
    this.individualOperationRequest.page = page.currentPage;
    this.listOperations();
  }

  toggleSelected() {
    this.operations = this.operations.map((operationItem: OperationItem) => {
      operationItem.check = !this.allCheck;
      return operationItem;
    });
    this.selectedOperations = this.allCheck ? [] : this.operations.slice(0);
    this.totalNoticeAvailable = true;
    this.selectedOperations.forEach((selectedOperation: OperationItem) => {
      if (
        selectedOperation.status === CONSTANTS.OPERATIONS.NOTICE_NOT_PAYMENT
      ) {
        this.totalNoticeAvailable = false;
      }
    });

    this.allCheck = !this.allCheck;
  }

  onSearch(documentNumber: string) {
    this.individualOperationRequest.search = documentNumber || '';
    this.individualOperationRequest.page = 1;
    this.paginationComponent.reset();
    this.listOperations();
  }

  checkOperation(operationItem: OperationItem) {
    const selectedIndex = this.selectedOperations.findIndex(
      (selectedOperation: OperationItem) =>
        `${operationItem.authorization}-${operationItem.titleValue}` ===
        `${selectedOperation.authorization}-${selectedOperation.titleValue}`
    );

    if (selectedIndex < 0) {
      this.selectedOperations.push(operationItem);
    } else {
      this.selectedOperations.splice(selectedIndex, 1);
    }

    this.totalNoticeAvailable = true;
    this.selectedOperations.forEach((selectedOperation: OperationItem) => {
      if (
        selectedOperation.status === CONSTANTS.OPERATIONS.NOTICE_NOT_PAYMENT
      ) {
        this.totalNoticeAvailable = false;
      }
    });
    // const checked = dataTableFake.find((item: any) => item.check === true);
    // checked
    //   ? (this.someElementChecked = true)
    //   : (this.someElementChecked = false);
  }

  totalExoneration() {
    if (this.isamount()) {
      const amountOperation: OperationItem = this.getAmountItem();
      if (amountOperation) {
        this.modal.show('operationsConfirm', {
          type: 'partialExoneration',
          titleValueType:
            amountOperation.titleValueType === CONSTANTS.OPERATIONS.CHECK
              ? 'cheque'
              : 'factura',
          title: 'Confirmar exoneración parcial',
          name: amountOperation.name
            .toLowerCase()
            .replace(/\b\S/g, function (t) {
              return t.toUpperCase();
            }),
          billNumber: amountOperation.titleValue,
          billPrice: amountOperation.amount,
        });
        this.operationType = 2;
      }
    } else {
      const billTotal = this.selectedOperations.reduce(
        (acc: number, it: OperationItem) =>
          (acc += parseInt(it.valueToExonerate)),
        0
      );

      if (this.selectedOperations.length > 1) {
        console.log(
          'this.selectedOperations[0].titleValueType',
          this.selectedOperations[0].titleValueType
        );
        this.modal.show('operationsConfirm', {
          type: 'exoneration',
          titleValueType:
            this.selectedOperations[0].titleValueType ===
            CONSTANTS.OPERATIONS.CHECK
              ? 'cheque'
              : 'factura',
          title: 'Confirmar Exoneración total',
          billNumber: this.selectedOperations.length,
          billTotal,
        });
      } else {
        console.log(
          'this.selectedOperations[0].titleValueType',
          this.selectedOperations[0].titleValueType
        );
        this.modal.show('operationsConfirm', {
          type: 'noPay',
          titleValueType:
            this.selectedOperations[0].titleValueType ===
            CONSTANTS.OPERATIONS.CHECK
              ? 'cheque'
              : 'factura',
          title: 'Confirmar Exoneración total',
          name: this.selectedOperations[0].name
            .toLowerCase()
            .replace(/\b\S/g, function (t) {
              return t.toUpperCase();
            }),
          billNumber: this.selectedOperations[0].titleValue,
          billPrice: this.selectedOperations[0].valueToExonerate,
          dueDate: new Date(),
        });
      }

      this.operationType = 1;
    }

    // exonerationService
  }

  totalNotice() {
    const billTotal = this.selectedOperations.reduce(
      (acc: number, it: OperationItem) =>
        (acc += parseInt(it.valueToExonerate)),
      0
    );

    if (this.selectedOperations.length > 1) {
      this.modal.show('operationsConfirm', {
        type: 'exoneration',
        title: 'Confirmar aviso de no pago',
        titleValueType:
          this.selectedOperations[0].titleValueType ===
          CONSTANTS.OPERATIONS.CHECK
            ? 'cheque'
            : 'factura',
        billNumber: this.selectedOperations.length,
        billTotal,
      });
    } else {
      this.modal.show('operationsConfirm', {
        type: 'noPay',
        titleValueType:
          this.selectedOperations[0].titleValueType ===
          CONSTANTS.OPERATIONS.CHECK
            ? 'cheque'
            : 'factura',
        title: 'Confirmar aviso de no pago',
        name: this.selectedOperations[0].name
          .toLowerCase()
          .replace(/\b\S/g, function (t) {
            return t.toUpperCase();
          }),
        billNumber: this.selectedOperations[0].titleValue,
        billPrice: this.selectedOperations[0].valueToExonerate,
        dueDate: new Date(),
      });
    }

    this.operationType = 3;
  }

  noPay() {
    this.modal.show('operationsConfirm', {
      type: 'noPay',
      titleValueType:
        this.selectedOperations[0].titleValueType === CONSTANTS.OPERATIONS.CHECK
          ? 'cheque'
          : 'factura',
      title: 'Confirmar aviso de no pago',
      name: 'Industrias Universal SAS',
      billNumber: '1234567',
      billPrice: '1234567',
      dueDate: new Date(),
    });
  }

  sendTotalExoneration() {
    const exonerateGuaranteRequest: ExonerateGuaranteRequest = {
      exonerateArray: this.selectedOperations,
    };
    this.spinner.show();
    this.exonerationService
      .exonerateGuarante(exonerateGuaranteRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.listOperations();
          this.modal.show('operationsSuccess', {
            title: 'Exoneración total exitosa',
          });
        },
        (err) => {
          this.spinner.hide();
          this.modal.show('operationsDenied', {
            title: 'Exoneración total negada',
          });
        }
      );
  }

  sendNotifyGuarante() {
    const exonerateGuaranteRequest: ExonerateGuaranteRequest = {
      exonerateArray: this.selectedOperations,
    };
    this.spinner.show();
    this.noticeService.notifyGuarante(exonerateGuaranteRequest).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.listOperations();
        this.modal.show('operationsSuccess', {
          title: 'Aviso de no pago exitoso',
        });
      },
      (err) => {
        this.spinner.hide();
        this.modal.show('operationsDenied', {
          title: 'Aviso de no pago negado',
        });
      }
    );
  }

  sendPartialExoneration() {
    const amountOperation: OperationItem = this.getAmountItem();
    const exonerateGuaranteRequest: ExonerateGuaranteRequest = {
      exonerateArray: [amountOperation],
    };
    this.spinner.show();
    this.exonerationService
      .exonerateGuarante(exonerateGuaranteRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.listOperations();
          this.modal.show('operationsSuccess', {
            title: 'Exoneración parcial exitosa',
          });
        },
        (err) => {
          this.spinner.hide();
          this.modal.show('operationsDenied', {
            title: 'Exoneración parcial negada',
          });
        }
      );
  }

  sendOperation() {
    switch (this.operationType) {
      case 1:
        this.sendTotalExoneration();
        break;
      case 2:
        this.sendPartialExoneration();
        break;
      case 3:
        this.sendNotifyGuarante();
        break;

      default:
        break;
    }
  }

  isamount() {
    const amountOperations: Array<OperationItem> = this.operations.filter(
      (operation: OperationItem) => operation.amount > 0
    );
    return amountOperations.length > 0;
  }

  getAmountItem() {
    const amountOperations: Array<OperationItem> = this.operations.filter(
      (operation: OperationItem) => operation.amount > 0
    );
    const amountOperation: OperationItem =
      amountOperations.length > 0 ? amountOperations[0] : null;
    return amountOperation;
  }

  isAmountError() {
    const amountOperation: OperationItem = this.getAmountItem();
    if (amountOperation) {
      return (
        parseInt(amountOperation.valueToExonerate) < amountOperation.amount
      );
    }
    return false;
  }

  //  this.modal.show('operationsSuccess', { title: 'Aviso de no pago exitoso'});
  // this.modal.show('operationsDenied', { title: 'Aviso de no pago negado'});
  //  this.modal.show('operationsSuccess', { title: 'Exoneración total exitosa'});
  //  this.modal.show('operationsDenied', { title: 'Exoneración total negada'});
  //  this.modal.show('operationsSuccess', { title: 'Exoneración parcial exitosa'});
  //  this.modal.show('operationsDenied', { title: 'Exoneración parcial negada'});
}
