export class NonPaymentNoticeResponse {
    ok:          boolean;
    data:        NonPaymentNoticeResponseModel[];
    currentPage: number;
    totalPages:  number;
}

export class NonPaymentNoticeResponseModel {
    account:                  number;
    affiliateDocument:        number;
    affiliateDocumentType:    string;
    authorization:            number;
    bank:                     number;
    contract:                 number;
    date:                     string;
    draftNumber:              string;
    draftsNumber:             number;
    guaranteedValue:          number;
    issuerDocument:           number;
    issuerDocumentType:       string;
    moduleAuthorization:      string;
    paymentDate:              string;
    postDated:                string;
    queryCode:                number;
    quotaAssignmentDate:      string;
    reportDate:               string;
    user:                     string;
    value:                    number;
    sinisterNumber:           number;
    nonPaymentNoticeStatus:   string;
    branchOffice:   string;
}