import { LegalRepAsrTempPK } from './compositeKey/LegalRepAsrTempPK.model';

export class RepLegalAsrTemp {
  id: number;
  legalRepAsrTempPK: LegalRepAsrTempPK;
  firstName: string;
  secondName: string;
  firstLastName: string;
  secondLastName: string;
  documentTypeOld?: string;
  documentOld?: number;
}
