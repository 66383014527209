import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-authorizations-list',
  templateUrl: './authorizations-list.component.html',
  styleUrls: ['./authorizations-list.component.scss']
})
export class AuthorizationsListComponent implements OnInit {

  @Output() authorizationSelected: EventEmitter<string>;

  public list: Array<any>  = [];
  public keyword = 'name';
  public data = [
    {id: 1, name: 'ok'}
  ]

  toString(value: any) {
    return (value) ? value.toString() : value;
  }

  selectEvent(item) {
    this.authorizationSelected.emit(item.name);
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }

  constructor(private subject: SubjectService) {
    this.authorizationSelected = new EventEmitter();
  }

  ngOnInit() {
    this.subject.authorizationList$.subscribe((response) => {
      this.list = response;
    });
  }

}
