import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ListsCovinoc } from '@app/models/lists-covinoc';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { AffiliateLinkService } from '@app/services/affiliate-link.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { ModalService } from '@app/services/modal.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-block-account',
  templateUrl: './block-account.component.html',
  styleUrls: ['./block-account.component.scss']
})
export class BlockAccountComponent implements OnInit {

  @ViewChild('blockAccountForm') blockAccountForm: NgForm;

  public headers: Array<string>;

  public accounts: Array<any>;

  public docTypes;

  public currentPage: number = 1;

  public totalPages: number = 1;

  public isBlock: boolean = false;

  public isOtherReason: boolean = false;

  public selectList: Array<boolean> = [];

  public blockOption: string = 'Bloquear';

  public accountSelected: any;

  public isQuerySuccess: boolean = false;

  public formAccount = {
    documentType: '',
    document: '',
    reason: '',
    reasonOther: '',
    type: '',
  }

  public reasonList: Array<any>;

  public blockAccountOptions = [
    {
      description: 'Bloquear',
      value: 'L'
    },
    {
      description: 'Desbloquear',
      value: 'U'
    },
  ]

  constructor(private quotaGreater: QuotaGreaterService, private listCovinoc: ListsCovinocService, public helper: HelperService, private tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService, private modal: ModalService, private affiliateLink: AffiliateLinkService, private toastr: ToastrService) { }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      this.docTypes = response.data[0].documentTypesCode;
    });
    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.reasonList = response.data[0].locksReason;
    });
    this.affiliateLink.getBanks().subscribe((response: any) => {
      localStorage.setItem("BANK_LIST", JSON.stringify(response));
    })
    this.headers = this.tableHeaders.tableHeadersBlockAccount
  }

  selectBlockOption($event) {
    this.formAccount.type = $event.target['value'];    
    this.isBlock = (this.formAccount.type === 'L') ? true : false;
    this.blockOption = (this.formAccount.type === 'L') ? 'Bloquear' : 'Desbloquear';
  }

  selectOtherReason() {
    this.isOtherReason = (this.formAccount.reason === 'Otro') ? true : false;
    console.log(this.isOtherReason);
  }

  showAlertConfirm() {
    this.modal.show('confirm');
  }

  selectAccount(id: number) {
    this.accountSelected = this.accounts[id];
    this.accounts.forEach((item, index) => {
      console.log('id ', id);
      console.log('index ', index);
      
      if(id === index) {
        this.selectList[id] = true;
      } else {
        this.selectList[index] = false;
      }
    });
  }

  executeOption() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));
    
    if(user) {
      const body = {
        documentType: this.formAccount.documentType,
        document: this.formAccount.document,
        user: user.user,
        entityCode: this.accountSelected.entityCode,
        account: this.accountSelected.account,
        newState: (this.formAccount.type === 'L') ? 'BLOQUEADA' : 'VIGENTE',
      }
  
      this.quotaGreater.lockUnlock(body).subscribe((response: any) => {
        this.spinner.hide();
        this.goToPage({currentPage: this.currentPage})
        this.modal.close();
        this.toastr.success(response.message);
      }, (error) => {
        this.modal.close();
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });
    }    
  }

  searchAccounts() {
    this.spinner.show();
    const body = {
      documentType: this.formAccount.documentType,
      document: this.formAccount.document,
      currentPage: this.currentPage,
      perPage: 10
    }

    this.quotaGreater.findAccounts(body).subscribe((response: any) => {
      this.spinner.hide();
      this.accounts = response.data[0];
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.isQuerySuccess = true;
      this.accounts.forEach((item) => {
        this.selectList.push(false);
      });
    }, (error) => {
      console.error(error)
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  goToPage($event) {
    this.spinner.show();

    const body = {
      documentType: this.formAccount.documentType,
      document: this.formAccount.document,
      currentPage: $event.currentPage,
      perPage: 10
    }

    this.quotaGreater.findAccounts(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.accounts = response.data[0];
      this.currentPage = response.currentPage
      this.totalPages = response.totalPages
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      console.log(error)
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

}
