export class QuotaGreaterThan20M {
    product: string;
    businessName: string;
    state: string;
    settlement: number;
    promissoryNote: string;
    receptionDate: string;
    receptionHour: string;
    classification: string;
    identificationType: string;
    identification: number;
    names: string;
    docExpDate: string;
    placeExpDoc: string;
    mark: string;
    ciiuCode: number;
    queryCode: number;
    affiliateDocType: string;
    affiliateDoc: number;
    completeName: string;

    ifDraft: boolean;
    draftForm: string;


    public static fromResponse(json: any): QuotaGreaterThan20M {
      const quotaGreaterThan20M: QuotaGreaterThan20M = {

        product: json['product'],
        businessName: json['businessName'],
        state: json['state'],
        settlement: json['settlement'],
        promissoryNote: json['promissoryNote'],
        receptionDate: json['receptionDate'],
        receptionHour: json['receptionHour'],
        classification: json['classification'],
        identificationType: json['identificationType'],
        identification: json['identification'],
        names: json['names'],
        docExpDate: json['docExpDate'],
        placeExpDoc: json['placeExpDoc'],
        mark: json['mark'],
        ciiuCode: json['ciiuCode'],
        queryCode: json['queryCode'],
        affiliateDocType: json['affiliateDocType'],
        affiliateDoc: json['affiliateDoc'],
        completeName: json['completeName'],
        ifDraft: json['ifDraft'],
        draftForm: json['draft-form']
      };
      return quotaGreaterThan20M;
    }
  }
