import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { DataFileTable, HeadersTableFiles } from '../models/buyer-report.model';

@Component({
  selector: 'app-table-file',
  templateUrl: './table-file.component.html',
  styleUrls: ['./table-file.component.scss']
})
export class TableFileComponent implements OnInit {
  @Input() dataFile: any;
  @Output() sendChangePage  =  new EventEmitter<number>();
  tableHeaders = HeadersTableFiles;
  @Input() totalPages: number;
  pdfSrc: string;

  constructor() { }

  ngOnInit() {
  }

  goToPage(event: any) {
    this.sendChangePage.emit(event.currentPage);
    //console.log(event.currentPage)
  }

  visualize(item: any) {
    
      let formatDocument = item.originalName.split('.');
      let format = formatDocument.at(-1).toLowerCase();
      switch (format) {
        case 'pdf':
          this.pdfSrc = item.infoFile;
      
          break;
        case 'xml':
          
            const src = `data:text/xml;base64,${item.infoFile}`;
            const link = document.createElement("a");
            link.href = src;
            link.download = item.originalName;
            link.click();
            link.remove();
            
          break;
        case 'jpg':
          var image = new Image();
          image.src = "data:image/jpg;base64," + encodeURI(item.infoFile);

          var w = window.open("");
          w.document.write(image.outerHTML);
          break;
        case 'jpeg':
          var image = new Image();
          image.src = "data:image/jpg;base64," + encodeURI(item.infoFile);

          var w = window.open("");
          w.document.write(image.outerHTML);
          break;
          case 'png':
            var image = new Image();
            image.src = "data:image/png;base64," + encodeURI(item.infoFile);
  
            var w = window.open("");
            w.document.write(image.outerHTML);
            break;
        default:
          Swal.fire({
            title: `Formato`,
            text: `No se puede leer el formato`,
            type: 'error',
          });
        
      }
  }

  closeModal(event: boolean) {
    this.pdfSrc = undefined;
  }

}
