import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClaimsFile } from '../models/request/claims';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss']
})
export class FileTableComponent implements OnInit {
  @Input() dataFile: any = [];
  @Output() sendChangePage  =  new EventEmitter<number>();
  tableHeaders = ClaimsFile;
  @Input() totalPages: number = 0;
  pdfSrc: string;
  

  constructor() { }

  ngOnInit() {
  }

  goToPage(event: any) {
    this.sendChangePage.emit(event.currentPage);
    //console.log(event.currentPage)
  }

  visualize(item: any) {
    
    /**let pdfWindow = window.open("")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(item.infoFile) + "'></iframe>"
    )
    */
    /**let myWindow=window.open('','','width=200,height=100')
    myWindow.document.write( encodeURI(item.infoFile));
    myWindow.focus()**/
      let formatDocument = item.originalName.split('.');
      let format = formatDocument.at(-1).toLowerCase();
      switch (format) {
        case 'pdf':
          this.pdfSrc = item.infoFile;
        /**  let pdfWindow = window.open("")
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(item.infoFile) + "'></iframe>")
        */
          break;
        case 'xml':
          
            const src = `data:text/xml;base64,${item.infoFile}`;
            const link = document.createElement("a");
            link.href = src;
            link.download = item.originalName;
            link.click();
            link.remove();
            
          break;
        case 'jpg':
          var image = new Image();
          image.src = "data:image/jpg;base64," + encodeURI(item.infoFile);

          var w = window.open("");
          w.document.write(image.outerHTML);
          break;
        case 'jpeg':
          var image = new Image();
          image.src = "data:image/jpg;base64," + encodeURI(item.infoFile);

          var w = window.open("");
          w.document.write(image.outerHTML);
          break;
        default:
          Swal.fire({
            title: `Formato`,
            text: `No se puede leer el formato`,
            type: 'error',
          });
        
      }
    
    
   /* var image = new Image();
    image.src = "data:image/jpg;base64," + encodeURI(item.infoFile);

    var w = window.open("");
    w.document.write(image.outerHTML);
    */
  }

  closeModal(event: boolean) {
    this.pdfSrc = undefined;
  }
  
}
