import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from '@app/services/helper.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})

export class AffiliatesBuyerService {
  public urlBuyerApi: string = '';
  public tokenAffiliate: string;
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private helper: HelperService,private ciCd: CiCdConfigService) {
    this.timeout = environment.httpTimeout;
    this.tokenAffiliate = environment.tokenAffiliate;
    this.urlBuyerApi = environment.urlBuyerApi;
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {urlBuyerApi, tokenAffiliate, httpTimeout, dev07ApiUtl} = apis;
      this.timeout = httpTimeout;
      this.tokenAffiliate = tokenAffiliate;
      this.urlBuyerApi = dev07ApiUtl;
    });
  }

  getinfoAffilateASR(tipoDocumento: string, documento: string){
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenAffiliate),

    });

    const params = {
      tipoDocumento: tipoDocumento,
      documento: documento
    };

    const options = { headers,params};

    const apiUrl = `${this.urlBuyerApi}buyeraffiliatehistory/api/v1/affiliate/getInfo`;

    return this.http.get(apiUrl, options);
  }



  GetBuyerInfo(documentType: string,document: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenAffiliate),

    });

    const params = {
      documentType: documentType,
      document: document
    };

    const options = { headers,params};

    const apiUrl = `${this.urlBuyerApi}buyeraffiliatehistory/api/v1/buyer/getInfo`;

    return this.http.get(apiUrl, options);
  }
}
