import { Injectable } from '@angular/core';
import { ACCOUNT_STATUS, AccountStatus, CHECKING_AND_SAVINGS_ACCOUNT_CLASS, CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS, CheckingAndSavingsAccountClass, CheckingAndSavingsAccountStatement, FRANCHISE_CARD, FranchiseCard, GENDER_AND_MARITAL_STATUS, GenderAndMaritalStatus, GUARANTEE_OF_HABIT_OF_PAYMENT_OF_CURRENT_OBLIGATIONS, IResponseCodeExperian, PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO, PaymentStatus, PLASTIC_CONDITION, PlastiCondition, PORTFOLIO_AND_CREDIT_CARD_ACCOUNT_RATING, PortfolioAndCreditCardAccountRating, QUALITY_OF_DEBTOR, QualityOfDebtor, RESPONSE_CODE_FROM_HDC, RESPONSE_CODE_FROM_HDC_AND_PJ, RESPONSE_CODE_FROM_RECONOCER, SAVINGS_AND_CHECKING_ACCOUNT_RATING, SavingsAndCheckingAccountRating, SITUATION_OF_THE_OWNER, SituationOfTheOwner, STATE_OF_ORIGIN, StateOfOrigin, TYPE_OF_CREDIT_CARD, TypeOfCreditCard, WarrantyOfHabitOfPaymentOfCurrentObligation } from '../constants/global-credit-info';

export interface ParamsToDetermineState {
  accountState?: string | number
  paymentState?: string | number
  originState?: string | number
  plasticState?: string | number
  paymentMethod?: string | number
  isBloqued?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class HelperCreditInfoService {

  constructor() { }


  isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string): boolean {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    const result: boolean = (status && status.state !== "Cerrada");
    return result;
  }

  isInactiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string): boolean {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    const result: boolean = (status && status.state === "Cerrada");
    return result;
  }

  findQualityOfDebtor(qualityOfDebtorCode: string) {
    const parsedCode: number = !isNaN(Number(qualityOfDebtorCode)) ? Number(qualityOfDebtorCode) : -1;
    if (parsedCode >= 1 && parsedCode <= 3) qualityOfDebtorCode = "01_03";
    if (parsedCode >= 9 && parsedCode <= 95) qualityOfDebtorCode = "09_95";
    if (parsedCode >= 98 && parsedCode <= 99) qualityOfDebtorCode = "98_99";

    const result: QualityOfDebtor | undefined = QUALITY_OF_DEBTOR[qualityOfDebtorCode];
    return result || { cardDescription: 'N/A', portfolioDescription: 'N/A' };
  }

  findWarrantyOfHabitOfPaymentOfCurrentObligation(warrantyCode: string): WarrantyOfHabitOfPaymentOfCurrentObligation {
    const result: WarrantyOfHabitOfPaymentOfCurrentObligation | undefined = GUARANTEE_OF_HABIT_OF_PAYMENT_OF_CURRENT_OBLIGATIONS[warrantyCode];
    return result || { code: '-', name: '-', description: '-' }
  }

  isActiveCheckingAndSavingAccount(code: string): boolean {
    const status: CheckingAndSavingsAccountStatement | undefined = CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS[code];
    const result: boolean = (status && status.state === 'Vigente');

    return result;
  }

  findCheckingAndSavingAccountClassByCode(code: string): CheckingAndSavingsAccountClass {
    const result: CheckingAndSavingsAccountClass | undefined = CHECKING_AND_SAVINGS_ACCOUNT_CLASS[code];
    return result || { code: '-', name: '-', description: '-' }
  }

  findGenderByCode(code: string): string {
    const result: GenderAndMaritalStatus | undefined = GENDER_AND_MARITAL_STATUS[code];
    return (result && result.gender) ? result.gender : code;
  }

  findPortfolioAndCreditCardAccountRatingByCode(code: string | number): string {
    const searched: PortfolioAndCreditCardAccountRating | undefined = PORTFOLIO_AND_CREDIT_CARD_ACCOUNT_RATING.find((item) => item.code == code);
    return (searched && searched.rating) ? searched.rating : `${code}`;
  }

  findSavingAndCheckingAccountRatingByCode(code: string | number): string {
    const searched: SavingsAndCheckingAccountRating | undefined = SAVINGS_AND_CHECKING_ACCOUNT_RATING.find((item) => item.code == code);
    return (searched && searched.rating) ? searched.rating : `${code}`;
  }

  findCheckingAndSavingAccountStatementByCode(code: string): string {
    const status: CheckingAndSavingsAccountStatement | undefined = CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS[code];

    return (status && status.name) ? status.name : code;
  }

  findCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string | number): string {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    return (status && status.name) ? status.name : '';
  }

  findStateOriginByCode(code: string | number): string {
    const result: StateOfOrigin | undefined = STATE_OF_ORIGIN.find((item) => item.code == code);
    return (result && result.description) ? result.description : '';
  }

  findPlasticConditionByCode(code: string | number): string {
    const result: PlastiCondition | undefined = PLASTIC_CONDITION.find((item) => item.code == code);
    return (result && result.description) ? result.description : 'No reportado';
  }

  findAccountStatusByCode(code: string | number): string {
    const result: AccountStatus | undefined = ACCOUNT_STATUS.find((item) => item.code == code);
    return (result && result.description) ? result.description : '';
  }

  /**
   * La lógica que se encuentra en esta función es extraída del manual de insumo de HDC-PN
   * @param param0 
   * @returns 
   */
  determinePortfolioAndCreditCardStatus({ accountState, paymentState, originState, plasticState, paymentMethod, isBloqued }: ParamsToDetermineState): string {
    if (accountState == "10") return this.findAccountStatusByCode(accountState);

    if (paymentState == "46" && paymentMethod == "3") return 'Pago Jur.';

    if (paymentState == "46" && paymentMethod && paymentMethod != "3") return 'Pago Vol.';

    const accountStateLabel: string = this.findAccountStatusByCode(accountState);
    const paymentStateLabel: string = this.findCardOrPortfolioByPaymentStatusCode(paymentState);
    const originStateLabel: string = this.findStateOriginByCode(originState);
    const plasticStateLabel: string = this.findPlasticConditionByCode(plasticState);

    const originStateToPrint: string = originStateLabel ? `Orig: ${originStateLabel}` : '';
    const plasticStateToPrint: string = plasticStateLabel ? `Plstico: ${plasticStateLabel}` : '';

    if (accountState == "10" || accountState == "14" || accountState == "13" || accountState == "16" || accountState == "15") {
      return `${accountStateLabel} + ${originStateToPrint} + ${plasticStateToPrint}`;
    }

    if (paymentState == "46" && paymentMethod == "3") {
      return `Pago Juridico + ${originStateToPrint}`;
    }

    if (paymentState == "46" && paymentMethod && paymentMethod != "3") {
      return `Pago Voluntario + ${originStateToPrint}`;
    }

    // if((paymentState == "3" || paymentState == "6") && true) // Validación con el vector de comportamiento al día (se desconoce esto)

    // if((paymentState == "3" || paymentState == "6") && false) // Validación con el vector de comportamiento si tiene mora (se desconoce esto)

    if (paymentState && paymentState != "3" && paymentState != "6" && paymentState != "46") {
      return `${paymentStateLabel} + ${originStateToPrint}`;
    }

    if (plasticState != undefined || plasticState !== null || plasticState != "0") {
      return `${plasticStateToPrint} + ${originStateToPrint} + ${accountStateLabel}`;
    }

    if (!originState || isBloqued === true) {
      return `${paymentState} + ${accountState}`
    }

    return '-';
  }

  findSituationOfTheOwnerByCode(code: string | number): string {
    const result: SituationOfTheOwner | undefined = SITUATION_OF_THE_OWNER.find((item) => item.code == code);
    return (result && result.description) ? result.description : `${code}`;
  }

  findTypeOfCreditCardByCode(code: string | number): string {
    const result: TypeOfCreditCard | undefined = TYPE_OF_CREDIT_CARD.find((item) => item.code == code);
    return (result && result.description) ? result.description : '-';
  }

  findFranchiseCardByCode(code: string | number) {
    const result: FranchiseCard | undefined = FRANCHISE_CARD.find((item) => item.code == code);
    return (result && result.description) ? result.description : '-';
  }

  findResponseCodeFromHdc(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_HDC.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

  findResponseCodeFromHdcAndPj(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_HDC_AND_PJ.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

  findResponseCodeFromReconcer(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_RECONOCER.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

}