import { Component, OnInit } from "@angular/core";
import { GlobalBilling } from "@app/models/global-billing.models";
import { StatusBillings } from "@app/models/select.model";
import { BackupsTableHeadersService } from "@app/pages/backups/services/backups-table-headers.service";
import { GloballBillingService } from "@app/services/globall-billing.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { SelectsService } from "@app/services/selects.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { RulesCreatedService } from "./rules-created.service";
import { send } from "process";

@Component({
  selector: "app-rules-created",
  templateUrl: "./rules-created.component.html",
  styleUrls: ["./rules-created.component.scss"],
})
export class RulesCreatedComponent implements OnInit {
  public reportGlobalBillingResponse: GlobalBilling[] = [];
  public data: GlobalBilling[] = [];
  public dataUpdate: any;
  public globalBillingResponse: GlobalBilling[] = [];
  public registerSelected: any;
  public modifyRegister: any = {};
  public cantPages = 0;
  public currentPage = 1;
  public id: number;
  public identity: any;
  public user: string = "";
  filter: string = "";
  value = 0;

  public filterParams = {
    nit: null,
    contract: null,
    nameAffiliate: null,
  };

  private typeFilter = {
    nit: "NIT",
    contract: "CONTRATO",
    nameAffiliate: "NOMBRE",
  };

  public tableHeadersGlobalBilling: string[] = [
    "Número de bolsa",
    "Nit",
    "Afiliado",
    "Contrato",
    "Sucursal",
    "Valor bolsa",
    "Porcentaje",
    "N° Cuotas amortizar",
    "N° Cuotas facturar",
    "Periodicidad",
    "Estado",
    "Editar",
    "Opciones",
  ];

  constructor(
    public backupsHeaders: BackupsTableHeadersService,
    private spinner: NgxSpinnerService,
    private globalBilling: GloballBillingService,
    public helper: HelperService,
    public select: SelectsService,
    public bagUpdate: GloballBillingService,
    public modal: ModalService,
    public communication: RulesCreatedService
  ) {}

  ngOnInit() {
    this.id = 0;
    this.generalGlobalBillingReport();
    this.identity = JSON.parse(localStorage.getItem("user"));
    this.user = this.identity.user;
    this.backupsHeaders.loading$.subscribe(() => {
      this.generalGlobalBillingReport();
    });
    //this.modifyRegister = this.communication.getModifyRegister();
  }

  onStatusChange(statusBillings: StatusBillings, newStatus: string): void {
    if (statusBillings.description !== "Inactivo") {
      statusBillings.description = newStatus;
    }
  }

  refresh() {
    this.currentPage = 1;
    this.filterParams.nit = null;
    this.filterParams.nameAffiliate = null;
    this.filterParams.contract = null;
    this.generalGlobalBillingReport();
  }

  generalGlobalBillingReport() {
    this.spinner.show();
    const body = {
      pagina: this.currentPage,
      por_pagina: this.currentPage,
    };
    this.globalBilling.getGlobalBilling(body).subscribe(
      (globalBillingResponse) => {
        this.reportGlobalBillingResponse = globalBillingResponse.data;
        this.currentPage = globalBillingResponse.currentPage;
        this.cantPages = globalBillingResponse.totalPages;

        this.data = globalBillingResponse.data;
        console.log("Este es el arreglo de facturación", this.data);
        this.spinner.hide();
      },
      (error: any) => {
        console.error("Error al obtener datos de facturación:", error);
        this.handleGetReportError(error);
      }
    );
  }

  handleGetReportError(error?: any): void {
    console.error(error);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title: "Reglas Creadas",
        text: `${message}`,
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.spinner.hide();
  }
  toggleSeleccion(register: any) {
    this.registerSelected = register;
    console.log("El arreglo de registros seleccionados", this.registerSelected);
  }
  viewTableReport(report: any) {
    this.registerSelected = report;
    this.backupsHeaders.viewTableData = false;
  }
  goToPage({ currentPage }): void {
    if (this.filter) {
      this.currentPage = currentPage;
      this.searchBill();
    } else {
      this.currentPage = currentPage;
      this.generalGlobalBillingReport();
    }
  }

  searchBill() {
    this.spinner.show();
    const body = {
      filtro: this.filter,
      valor: this.value,
      grabadas: "NO_GRABADAS",
      pagina: this.currentPage,
      por_pagina: 10,
    };
    this.globalBilling.filterGlobalBilling(body).subscribe(
      (resp) => {
        this.spinner.hide();
        this.reportGlobalBillingResponse = resp.data || [];
        this.currentPage = resp.currentPage;
        this.cantPages = resp.totalPages;
        this.data = resp.data;
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  setItem(typeFilter, value) {
    if (!value) {
      this.filter = null;
      this.value = null;
      this.generalGlobalBillingReport();
      Object.keys(this.filterParams).map((element) => {
        this.filterParams[element] = null;
      });
    } else {
      Object.keys(this.filterParams).map((element) => {
        if (element != typeFilter) {
          this.filterParams[element] = null;
        } else {
          this.value = this.filterParams[element];
        }
      });
      this.filter = this.typeFilter[typeFilter];
    }
  }
  openModal(report: any) {
    this.registerSelected = report;
    this.modal.show("rulesCreate", {});
    this.sendRegister();
  }

  sendRegister() {
    const register = this.registerSelected;
    this.communication.sendRegister(register);
  }
}
