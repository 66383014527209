import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonFloat } from "@app/models/buttonFloat.model";
import { ListResponse } from "@app/models/listResponse.model";
import { AffiliatesListModel } from "@app/pages/backups/models/response/SearchAffiliate.model";
import { BackupsStoreService } from "@app/pages/backups/services/backups-store.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { Response } from "../../../models/responseUtil.model";
import { RadicationService } from "../services/radication-quota.service";

@Component({
  selector: "app-request-managment",
  templateUrl: "./request-managment.component.html",
  styleUrls: ["./request-managment.component.scss"],
})
export class RequestManagmentComponent implements OnInit {
  public status = false;
  public navItemTabs: any[] = [];
  public activeTab$: Observable<string> =
    this.backupsStore.stateBackupsActiveTab$;
  public profileActived: boolean = false;
  public identification: string = "";
  public listProfile: Array<ButtonFloat> = [];

  public messager = "";

  constructor(
    public modal: ModalService,
    public helper: HelperService,
    public backupsStore: BackupsStoreService,
    private router: Router,
    private quotaRadicationService: RadicationService,
    private radicationService: QuotaRadicationService
  ) {
    this.radicationService.getLists().subscribe();
    this.navItemTabs = this.quotaRadicationService.radicationTabs;
    this.messager = this.quotaRadicationService.messager;
    this.setTab(this.navItemTabs[0]);
  }

  ngOnInit() {
    let information = JSON.parse(localStorage.getItem("user"));

    localStorage.removeItem("profile");

    this.identification = information.user;
    this.messager += this.identification;
    this.radicationService
      .getUserProfile(this.identification)
      .subscribe((response: Response<ButtonFloat>) => {
        if (response.data && response.data.length > 0) {
          this.listProfile = response.data;
          this.profileActived = true;
        } else {
          this.profileActived = false;
        }
      });
  }

  setTab(tab: any) {
    this.backupsStore.setActiveTab(tab.key);
    this.backupsStore.changeStateAffiliate(new AffiliatesListModel());
  }

  eventData(data) {
    this.messager = "";
    localStorage.setItem("profile", data);
    this.profileActived = false;
    setTimeout(() => {
      this.profileActived = true;
    }, 1000);
  }
}
