import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class IncomeRecoveryService {
  public backingsToken: string;
  public apiUrlBackings: string = "";
  public apiUrl: string;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
      this.apiUrl = environment.apiUrl;
      this.backingsToken = environment.backingsToken;
      this.ciCdInit()
    }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, backingsToken, apiUrlBackings} = apis;
      this.apiUrl = apiUrl;
      this.apiUrlBackings = apiUrlBackings;
      this.backingsToken = backingsToken;
       console.log('config ci cd', apiUrl);
    });
  }

  public getIncome(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.backingsToken));

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrlBackings}/backings/getIncome`,
      body,
      options
    );
  }

  public getForDates(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.backingsToken));

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrlBackings}/backings/getForDates`,
      body,
      options
    );
  }

  public getCancellations(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.backingsToken));

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrlBackings}/backings/getCancellations`,
      body,
      options
    );
  }

  public getFilingNumber(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.backingsToken));

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrlBackings}/backings/getFilingNumber`,
      body,
      options
    );
  }
}
