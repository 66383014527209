import { Component, OnInit } from '@angular/core';
import { CreditHistoryAlert, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-alerts-history',
  templateUrl: './alerts-history.component.html',
  styleUrls: ['./alerts-history.component.scss']
})
export class AlertsHistoryComponent implements OnInit {

  public headersTable: string[] = ['Fuente', 'Fecha', 'Novedad', 'Descripción'];
  public alerts: CreditHistoryAlert[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.alerts = this.subjectCreditHistory.getAlertsFromCreditHistory(response);
  }

}
