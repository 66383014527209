import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectModel } from '@app/models/select.model';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { SubjectService } from '@app/services/subject.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PersonTitle } from './models/person-title';

@Component({
  selector: 'app-searcher-titles',
  templateUrl: './searcher-titles.component.html',
  styleUrls: ['./searcher-titles.component.scss']
})
export class SearcherTitlesComponent implements OnInit {

  public navItemTabs: any[] = [];
  documentTypes: SelectModel[] = [];
  @Input() tabState: string;
  @Output() titlesData: EventEmitter<any>;
  @Output() personalInformation: EventEmitter<any>;

  data: any = {
    documentType: '',
    document: null,
    authorization: null,
    transactionType: '',
  }

  transactionType = [
    {
      value: 'APROBADAS',
      description: 'APROBADAS',
    },
    {
      value: 'NEGADAS',
      description: 'NEGADAS',
    },
  ]

  keyword = 'name';

  dataAuthorizationList = [];
 
  

  authorizationList: Array<any> = []

  constructor(private tabs: TabsService, public helper: HelperService, private listService: ListsCovinocService, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService, private subject: SubjectService) {
    this.navItemTabs = this.tabs.adminQuotaTabs;
    this.titlesData = new EventEmitter();
    this.personalInformation = new EventEmitter();
  }

  public get isValidAuthorization(): boolean {
    console.log(this.data.authorization);
    return (this.tabState === "#modify") ? true : (this.data.authorization !== null && this.data.authorization !== '');
  }

  ngOnInit() {
    this.findAllLists();
  }

  onGetAuthorization($event) {
    this.data.authorization = $event;
  }

  onSelectTransactionType() {
    this.data.authorization = (this.data.transactionType === 'NEGADAS') ? null : this.data.authorization;
  }

  onSubmitSearch() {
    this.spinner.show();
    if (this.tabState === "#modify") {
      const body = {
        documentType: this.data.documentType,
        document: this.data.document,
        authorization: this.data.authorization,
        option: this.data.transactionType,
        currentPage: '1',
        perPage: '10'
      }
      
      if(this.data.authorization === null) {  
        this.quotaGreater.findBuyerTitles(body).subscribe((response: any) => {
          this.spinner.hide();
          console.log(response);
          this.titlesData.emit(response.data[0].titles)
          
          this.getAuthorizationList(response.data[0].authorizationsList);
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          })
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        });
      } else {
        this.quotaGreater.findTitlesByAuthorizationNo(body).subscribe((response: any) => {
          this.spinner.hide();
          console.log(response);
          this.titlesData.emit(response.data[0]);
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          })
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        });
      }
    } else if (this.tabState === "#reverse-exonerations") {
      const body = {
        documentType: this.data.documentType,
        document: this.data.document,
        option: 'APROBADAS',
        authorization: this.data.authorization,
        currentPage: '1',
        perPage: '10'
      }
      this.quotaGreater.findExonerationLists(body).subscribe((response: any) => {
        this.spinner.hide();
        console.log(response);
        this.titlesData.emit(response);
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        })
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    } else {
      const body = {
        documentType: this.data.documentType,
        document: this.data.document,
        queryCode: this.data.authorization,
        currentPage: '1',
        perPage: '10'
      };
      this.quotaGreater.findDeclinedTitles(body).subscribe((response: any) => {
        this.spinner.hide();
        console.log(response);
        this.titlesData.emit(response);
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        })
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
    this.personalInformation.emit(this.data);
  }

  getAuthorizationList(list: Array<any>) {
    if(this.data.transactionType === 'APROBADAS') {
      list.forEach((item, key) => {
        this.dataAuthorizationList.push(
          {id: key, name: item.toString()}
        )
      });
      this.subject.changeAuthorizationList(this.dataAuthorizationList);
      console.log("Here");
    } else {
      this.subject.changeAuthorizationList([]);
    } 
  }

  findAllLists(): void {
    this.listService.findAllLists().subscribe(
      (res) => {
        if (res.status != 200) this.helper.badResponse(res.message, res.status);
        this.documentTypes = res.data[0]['documentTypesCode'];
      },
      error => this.helper.handleErrorHttp(error, 'Ocurrió un Error')
    );
  }

  validRequest(): boolean {
    const isTransactionTypeValid = (this.tabState === '#modify') ? this.data.transactionType && this.data.transactionType !== '' : true;
    return this.data.documentType && this.data.document && this.data.document > 0 && isTransactionTypeValid;
  }

}
