import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BuyerInfoMassiveCharge,
  QueryCodeMassiveCharge,
} from '@app/models/massive-charge.model';
import { HelperService } from '@app/services/helper.service';
import { MassiveChargeService } from '@app/services/massive-charge.service';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal, { SweetAlertType } from 'sweetalert2';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  documentTypes: any[] = [
    { id: 'N', value: 'Nit' },
    { id: 'C', value: 'Cédula' },
  ];
  affiliateInfo: any = {
    name: '',
    contracts: [],
    contractSelected: 0,
  };
  chargeTypes: any[] = [
    { id: 'ind', value: 'Individual' },
    { id: 'mass', value: 'Masivo' },
  ];
  bagName: string = '';

  isBuyerSelected:boolean = false;
  isPaymentOrderSelected: boolean = false;
  paymentOrdersData: Array<any> = [];
  selectedOrderPayment = null;
  buyerInfo: BuyerInfoMassiveCharge;
  cicle = Array.from({ length: 30 }, (_, i) => i + 1);
  posibleCuotes = Array.from({ length: 12 }, (_, i) => i + 1);
  showHistory: boolean = false;
  selectedChargeType: string = 'ind';
  fileUpload: any = null;
  buyerSearchForm: FormGroup = this.fb.group({
    /*  documentType: ['N', Validators.required],
    documentNumber: ['9012935489', Validators.compose([Validators.maxLength(11), Validators.minLength(4), Validators.required])], */
    documentType: ['', Validators.required],
    documentNumber: [
      '',
      Validators.compose([
        Validators.maxLength(11),
        Validators.minLength(4),
        Validators.required,
      ]),
    ],
  });
  paymentOrderForm: FormGroup = this.fb.group({
    documentType: ['', Validators.required],
    documentNumber: [
      '',
      Validators.compose([
        Validators.maxLength(11),
        Validators.minLength(4),
        Validators.required,
      ]),
    ],
  });
  buyerPorfolioForm: FormGroup = this.fb.group({
    amount: [
      '',
      Validators.compose([Validators.max(999999999), Validators.required]),
    ],
    cycle: ['', Validators.required],
    queryCode: ['', Validators.required],
    branch: ['', Validators.required],
    adminQuotas: ['', Validators.required],
    productCode: ['', Validators.required],
    pendingQuotas: [null, Validators.required],
    creditNumber: [
      ' ',
      Validators.compose([Validators.maxLength(12), Validators.required]),
    ],
    rate: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(5)]),
    ],
  });
  formAffiliateSearch: FormGroup = this.fb.group({
    documentType: ['', Validators.compose([Validators.required])],
    documentNumber: [
      '',
      Validators.compose([Validators.maxLength(11), Validators.required]),
    ],
    /*  documentType: 'N',
     documentNumber: '8600284621' */
  });
  queryCodes: QueryCodeMassiveCharge[] = [];
  files: any[] = [];
  chargeHistory: any[] = [
    {
      selected: false,
      chargeDate: new Date(),
      status: 'Rechazado',
      rejects: 3,
      uploads: 2,
      user: 'Lorena.lopez',
    },
    {
      selected: true,
      chargeDate: new Date(),
      status: 'Rechazado',
      rejects: 3,
      uploads: 2,
      user: 'Lorena.lopez',
    },
  ];
  public totalPages: number = 1;

  constructor(
    public helper: HelperService,
    private fb: FormBuilder,
    private massiveChargeService: MassiveChargeService,
    private loader: NgxSpinnerService
  ) {}

  ngOnInit() {}
  onSelect(event: any) {
    this.fileUpload = event.addedFiles[0];
  }
  pageSelected(page: any) {
  }
  onSelectContractNumber() {
    this.getQueryCodes();
  }
  onSelectQueryCode() {
    this.buyerPorfolioForm
      .get('branch')
      .setValue(
        this.queryCodes.filter(
          (el) => el.queryCode == this.buyerPorfolioForm.get('queryCode').value
        )[0].branch
      );
  }

  disableInputs() {
    this.buyerPorfolioForm.get('productCode').disable();
    this.buyerPorfolioForm.get('branch').disable();
    this.buyerPorfolioForm.get('adminQuotas').disable();
  }
  onSelectQuotas() {
    //this.posibleAdminCuotes = Array.from({ length: parseInt(this.buyerPorfolioForm.get('pendingQuotas').value) }, (_, i) => i + 1)
    this.buyerPorfolioForm.get('adminQuotas').enable();
    this.buyerPorfolioForm.get('adminQuotas').setValue(null);
    /*
    setTimeout(() => {
    }, 1000); */
  }
  searchAffiliate() {
    this.loader.show();
    this.massiveChargeService
      .getPortfolioManagementActiveContracts(
        this.formAffiliateSearch.get('documentType').value,
        this.formAffiliateSearch.get('documentNumber').value
      )
      .subscribe({
        next: (data) => {
          this.loader.hide();
          this.affiliateInfo.contracts = data.data;
          this.affiliateInfo.name =
            this.affiliateInfo.contracts[0].affiliateName;
        },
        error: (error) => {
          console.log(error.error);
          this.showAlert(
            'error',
            'Se ha producido un error',
            error.error.message
          );
        },
      });
  }

  getBuyerInfo() {
    this.loader.show();
    this.massiveChargeService
      .getClientDataByDocumentAndDocType(
        this.buyerSearchForm.get('documentType').value,
        this.buyerSearchForm.get('documentNumber').value,
        this.affiliateInfo.contractSelected
      )
      .subscribe({
        next: (data) => {
          this.buyerInfo = data.data;
          this.buyerPorfolioForm.reset();
          this.buyerPorfolioForm.get('productCode').setValue('CARTERA1_NVT');
          this.disableInputs();
          this.loader.hide();
        },
        error: (error) => {
          console.log(error.error);
          this.showAlert(
            'error',
            'Se ha producido un error',
            error.error.message
          );
        },
      });
  }

  getPaymentOrderInfo() {
    this.loader.show();
    this.massiveChargeService
      .postCheckPortfolio(
        this.paymentOrderForm.get('documentType').value,
        this.paymentOrderForm.get('documentNumber').value,
      )
      .subscribe((response: any) => {
        this.loader.hide();
        if (response.ok) {
          this.paymentOrdersData = response.data;
          /*  */
          if(this.paymentOrdersData.length > 0) {
            this.paymentOrdersData.forEach((element) => {
              this.bagName = element.PrimerNombre + ' ' + element.PrimerApellido;
            });
          }
          this.showAlert(
            'success',
            'Éxito',
            'Consulta realizada exitosamente'
          );
        } else {
          this.showAlert(
            'error',
            'Se ha producido un error',
            response.message
          );
        }
      }, (error) => {
        console.log(error);
        this.loader.hide();
        this.showAlert(
          'error',
          'No ha sido posible generar la orden de pago',
          error.error.message
        );
        this.paymentOrdersData = [];
      });
  }

  selectPaymentOrder(paymentOrder: any) {
    this.selectedOrderPayment = paymentOrder;
  }

  generatePaymentOrder() {
    this.loader.show();
    let { user } = JSON.parse(localStorage.getItem('user'));
    if(user) {
      const body = {
        docType: this.selectedOrderPayment.TipoDocumento,
        docNumber: this.selectedOrderPayment.NumeroDocumento,
        operation: this.selectedOrderPayment.NumeroOperacion,
        username: user
      }
      this.massiveChargeService.postCreateUploadPaymentOrder(body).subscribe((response: any) => {
        this.loader.hide();
        if (response.ok) {
          const { data } = response;
          const datascoringData = data[0].datascoringData;
  
          const total = datascoringData.totalArrears;
          const capital = datascoringData.capitalValue;
          const interesFinanciacion = datascoringData.financingInterest || 0;
          const interesMora = datascoringData.arrearsInterest || 0;
          const iva = datascoringData.vat || 0;
          const gastosCobranza = datascoringData.collectionCost || 0;
          const ivaAdministracion = datascoringData.vatAdministrationFee;
          const cuotaAdministracion = datascoringData.administrationFee;

          const totalFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(total);
          const interesFinanciacionFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(interesFinanciacion);
          const capitalFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(capital);
          const interesMoraFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(interesMora);
          const ivaFormatted = (iva * 100).toFixed(2) + '%';
          const gastosCobranzaFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(gastosCobranza);
          const ivaAdministracionFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(ivaAdministracion);
          const cuotaAdministracionFormatted = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(cuotaAdministracion);
          
          Swal.fire({
            title: `Orden de pago: ${data[0].liquidationSequence}`,
            html: `
              <div style="text-align:left">
                <p>Total: ${totalFormatted}</p>
                <p>Interés financiación: ${interesFinanciacionFormatted}</p>
                <p>Capital: ${capitalFormatted}</p>
                <p>Interés mora: ${interesMoraFormatted}</p>
                <p>Iva: ${ivaFormatted}</p>
                <p>Gastos cobranza: ${gastosCobranzaFormatted}</p>
                <p>Iva cuota de administración: ${ivaAdministracionFormatted}</p>
                <p>Cuota de administración: ${cuotaAdministracionFormatted}</p>
              </div>
            `,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#3085d6',
          }).then((result) => {
            this.getPaymentOrderInfo();
          });
          
          
        } else {
          this.showAlert(
            'error',
            'Se ha producido un error',
            response.message
          );
        }
      }, (error) => {
        this.loader.show();
        console.log(error);
        this.showAlert(
          'error',
          'No ha sido posible generar la orden de pago',
          error.error.message
        );
      });
    }
  }

  getQueryCodes() {
    this.loader.show();
    this.massiveChargeService
      .getQueryCodes(this.affiliateInfo.contractSelected)
      .subscribe({
        next: (data) => {
          this.loader.hide();
          this.queryCodes = data.data;
          /* TODO: Asking this */
          this.isBuyerSelected = true
          this.isPaymentOrderSelected = true;
        },
        error: (error) => {
          console.log(error.error);
          this.showAlert(
            'error',
            'Se ha producido un error',
            error.error.message
          );
        },
      });
  }

  savePortfolio() {
    this.loader.show();
    this.massiveChargeService
      .createPortfolio(
        this.formAffiliateSearch.get('documentType').value,
        this.formAffiliateSearch.get('documentNumber').value,
        this.affiliateInfo.contractSelected,
        this.buyerInfo.documentType,
        parseInt(this.buyerInfo.documentNumber),
        this.buyerPorfolioForm.get('queryCode').value,
        this.buyerPorfolioForm.get('branch').value,
        this.buyerPorfolioForm.get('productCode').value,
        this.buyerPorfolioForm.get('amount').value,
        this.buyerPorfolioForm.get('pendingQuotas').value,
        this.buyerPorfolioForm.get('cycle').value,
        this.buyerPorfolioForm.get('creditNumber').value,
        this.buyerPorfolioForm.get('adminQuotas').value,
        this.buyerPorfolioForm.get('rate').value
      )
      
      .subscribe({
        next: (data) => {
          this.loader.hide();
          if (data.ok) {
            if (data.data.filter((el) => el.ok === false).length) {
              this.showAlert(
                'warning',
                '¡ Atención !',
                'El portafolio se ha creado con problemas, por favor contacte con el administrador.'
              );
            } else {
              this.showAlert(
                'success',
                'Éxito',
                `El portafolio se ha creado correctamente, el número de operación es: ${data.data[0].data[0].operationNumber}`
              );
            }
          }
          this.clearForm();
        },
        error: (error) => {
          console.log(error.error);
          this.showAlert(
            'error',
            'Se ha producido un error',
            error.error.message
          );
        },
      });
  }
  uploadFilePortfolio() {
    this.loader.show();
    this.massiveChargeService
      .createPortfolioMassive(
        this.formAffiliateSearch.get('documentType').value,
        this.formAffiliateSearch.get('documentNumber').value,
        this.fileUpload,
        this.affiliateInfo.contractSelected
      )
      .subscribe({
        next: (data) => {
          this.loader.hide();
          if (data.ok) {
            this.showAlert(
              'success',
              'Éxito',
              'El archivo cargado se encuentra en proceso, el número de radicado es: ' +
                data.data[0].uploadNumber
            );
            this.clearForm();
          }
        },
        error: (error) => {
          console.log(error);
          this.loader.hide();
          this.showAlert(
            'error',
            'Se ha producido un error',
            error.error.message
          );
        },
      });
  }
  clearForm() {
    this.formAffiliateSearch.reset();
    this.buyerPorfolioForm.reset();
    this.buyerSearchForm.reset();
    this.affiliateInfo.contracts = [];
    this.affiliateInfo.contractSelected = null;
    this.buyerInfo.documentNumber = null;
    this.fileUpload = null;
  }
  showAlert(typeAlert: SweetAlertType, titleAlert: string, textAlert: string) {
    this.loader.hide();
    Swal.fire({
      type: typeAlert,
      title: titleAlert,
      text: textAlert,
    });
  }
  downloadTemplate() {
    this.loader.show();
    this.massiveChargeService.downloadExcelTemplate().subscribe({
      next: (data) => {
        this.loader.hide();
        if (data.ok) {
          this.downloadDocument(
            data.data[0].dataAsBase,
            data.data[0].fileNameExtension,
            data.data[0].mimeType
          );
        }
      },
    });
  }
  downloadDocument(fileBase64: string, fileName: string, mimeType: string) {
    const byteCharacters = atob(fileBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    FileSaver.saveAs(blob, fileName);
  }
}
