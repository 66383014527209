import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { DebtEvolution } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface TotalTable {
  headers: string[]
  row: any[]
}

@Component({
  selector: 'app-debt-evolution-history',
  templateUrl: './debt-evolution-history.component.html',
  styleUrls: ['./debt-evolution-history.component.scss']
})
export class DebtEvolutionHistoryComponent implements OnInit, OnDestroy {

  public debtEvolutions: DebtEvolution[] = [];

  public totalTable: TotalTable = {} as TotalTable;

  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.debtEvolutions = response.debtEvolutions;
    this.generateTotalTable();
  }

  generateTotalTable() {
    let headers: string[] = [];
    const rowsTotalesPerSector: any[][] = [];

    this.debtEvolutions.forEach((table: DebtEvolution) => {
      const rowTotalPerSector: any[][] = table.accounts[table.accounts.length - 1]; // La última cuenta son los totales
      rowsTotalesPerSector.push(...rowTotalPerSector);
      if(headers.length === 0) headers = [...table.headers];
    });
    headers[0] = "Totales";
    headers[1] = "";

    let rowTotal: any[] = Array(headers.length).fill(0);
    rowTotal[0] = 'Saldos';
    rowTotal[1] = '';

    rowsTotalesPerSector.forEach((rowSector) => {
      rowSector.forEach((value, index) => {
        if(index >= 2) {
          rowTotal[index] += (typeof value === 'number') ? value : 0;
        }
      });
    });

    this.totalTable = {
      headers,
      row: rowTotal
    };
  }

}
