import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "@services/helper.service";
import { ReferenceMunicipalities } from "../models/referenceMunicipalities.model";
import { DocumentTypeResponse } from "../models/response/documentTypeResponse.model";
import { DocumentTypes } from "../models/documentType.model";
import { CurrencyPipe } from "@angular/common";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import { Response } from "@app/models/responseUtil.model";
import { QuotaService } from "../services/quota-greateer.service";

@Component({
  selector: "app-quota-buyer-info",
  templateUrl: "./quota-buyer-info.component.html",
  styleUrls: ["./quota-buyer-info.component.scss"],
  providers: [CurrencyPipe],
})
export class QuotaBuyerInfoComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;
  @Input() departments: Array<{}>;
  @Input() referenceMunicipalities: Array<ReferenceMunicipalities>;

  public cities: Array<{}> = [];
  public documentTypes: Array<DocumentTypes> = [];
  public repreLegalNeeded: boolean = false;
  private documentTypesResponse: DocumentTypeResponse =
    new DocumentTypeResponse();

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private quotaService: QuotaGreaterService,
    private quotaServices: QuotaService,
    public helper: HelperService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    const NIT = "N";
    console.log(this.departments);
    if (this.infoStudyQuote.controls.docType.value == NIT) {
      this.repreLegalNeeded = true;
    } else {
      this.repreLegalNeeded = false;
    }

    let city = this.infoStudyQuote.controls.city.value;
    if(city){
      this.updateCities(this.infoStudyQuote.controls.department.value)
      this.infoStudyQuote.controls.city.setValue(city);
      this.updateCovering(city);
    }

    this.onChangesFinancialSources();

    this.infoStudyQuote
      .get("telephone")
      .valueChanges.subscribe((selectedValue) => {
        if (selectedValue != "") {
          this.infoStudyQuote.get("cellphone").clearValidators();
          this.infoStudyQuote.get("cellphone").setValidators(null);
          this.infoStudyQuote.get("cellphone").setErrors(null);
          this.infoStudyQuote.updateValueAndValidity();
        } else {
          this.infoStudyQuote
            .get("cellphone")
            .setValidators([Validators.maxLength(10)]);
          this.infoStudyQuote.updateValueAndValidity();
        }
      });

    this.infoStudyQuote
      .get("cellphone")
      .valueChanges.subscribe((selectedValue) => {
        if (selectedValue != "") {
          this.infoStudyQuote.get("telephone").clearValidators();
          this.infoStudyQuote.get("telephone").setValidators(null);
          this.infoStudyQuote.get("telephone").setErrors(null);
          this.infoStudyQuote.updateValueAndValidity();
        }
      });

    this.getDocumentsType();
    this.findDocument();

  }

  // convenience getter for easy access to form fields
  get f() {
    return; //this.infoStudyQuote.controls;
  }

  getDocumentsType() {
    this.quotaService
      .getDocumentsType()
      .subscribe((resp: Response<DocumentTypes>) => {
        if (resp.data && resp.data.length > 0) {
          this.documentTypes = resp.data;
        }
      });
  }

  findDocument(){
    let typeDocument = this.infoStudyQuote.controls.docType.value;
    // typeDocument = this.quotaServices.convertFind(typeDocument);
    // this.infoStudyQuote.controls.docType.setValue(typeDocument);
  }

  updateCities($event) {
    this.cities = [];
    let city = {};
    this.referenceMunicipalities.forEach((element) => {
      if (Number(element.department) == $event) {
        city = {
          id: Number(element.city),
          name: element.nameCity,
        };
        this.cities.push(city);
      }
    });
    this.infoStudyQuote.controls.covering.setValue("");
    this.infoStudyQuote.controls.city.setValue("");
  }

  updateCovering($event) {
    let covering = this.referenceMunicipalities.filter(
      (element) =>
        Number(element.department) ==
          this.infoStudyQuote.controls.department.value &&
        Number(element.city) == this.infoStudyQuote.controls.city.value
    );
    if (covering.length > 0) {
      this.infoStudyQuote.controls.covering.setValue(covering[0].covering);
    }
  }

  /**
   * Is in charge of filling out The quota buyer infor when information is found
   * in other forms of 20m in consultation of financial information
   * @author Rafael Ramirez
   */
  onChangesFinancialSources(): void {
    this.infoStudyQuote
      .get("documentTypeLR")
      .valueChanges.subscribe((selectedValue) => {
        if (typeof selectedValue === "string") {
          this.documentTypes.forEach((value) => {
            if (selectedValue === value.type) {
              //this.infoStudyQuote.controls.documentTypeLR.setValue(value);
            }
          });
        }
      });

    this.infoStudyQuote
      .get("department")
      .valueChanges.subscribe((selectedValue) => {
        if (typeof selectedValue === "string") {
          this.departments.forEach((value) => {
            if (selectedValue === value["id"].toString()) {
              this.infoStudyQuote.controls.department.setValue(value);
            }
          });
        }
      });

    this.infoStudyQuote.get("city").valueChanges.subscribe((selectedValue) => {
      if (typeof selectedValue === "string") {
        this.cities.forEach((value) => {
          if (selectedValue === value["id"].toString()) {
            this.infoStudyQuote.controls.city.setValue(value);
          }
        });
      }
    });
  }
}
