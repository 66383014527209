import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-image-upload',
  templateUrl: './modal-image-upload.component.html',
  styleUrls: ['./modal-image-upload.component.scss']
})
export class ModalImageUploadComponent  {
  uploads = [];
  @Output() close = new EventEmitter<any>();
  @Output() sendFiles  = new EventEmitter<any>();
  files: File[] = [];
  
  constructor(private spinner: NgxSpinnerService) {}

  onSelect(event) {
    let ex
    for(let element of event.addedFiles) {
      if(element.size > 8000000) {
        Swal.fire({
          title: `Sobrepasa el tamaño máximo 8Mb`,
          text: `Nombre:`+`${element.name}`,
          type: 'error',
        });
        break;
      }
    }
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  closeModal() {
    this.close.emit();
  }

  async saveDataFiles() {
    this.spinner.show();

    if(this.files.length > 0) {
      for(let element of this.files) {
        this.uploads.push({
          fileName: element.name,
          fileExtension: element.type,
          image: await this.toBase64(element)
        });
      }
    }
    this.sendFiles.emit(this.uploads);
    this.closeModal();
    this.spinner.hide();
  }

  async toBase64(element)  {
    let doc = await this.convertFile(element);
    let docSend = doc.split(',');
    //console.log(docSend[1])
    return  docSend[1];
  }

  convertFile(file: File): any {
    
    return new Promise((resolve, reject) => {
    
      const reader = new FileReader();
      
      reader.readAsDataURL(file);
      
      reader.onload = () => resolve(reader.result);
      
      reader.onerror = error => reject(error);
    
    });
  }


}
