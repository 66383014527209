import { RelationEcBuyer } from "../relationEcBuyer.model";

export class RelationEcBuyerResponse {
    response: Array<RelationEcBuyer> = [];
    error?: {};

    public static fromResponse(json: any): RelationEcBuyerResponse {
        const relationEcBuyerResponse: RelationEcBuyerResponse = {
        response: json.response ? json.response : [],
        error: json.error ? json.error : null
    };
        return relationEcBuyerResponse;
    }
}
