import { ModalService } from './../../../../services/modal.service';
import { QuotaVsAffiliateHeadingTablesService } from './../../services/quota-vs-affiliate-heading-tables.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';

@Component({
  selector: 'app-modal-repeated-phones',
  templateUrl: './modal-repeated-phones.component.html',
  styleUrls: ['./modal-repeated-phones.component.scss']
})
export class ModalRepeatedPhonesComponent implements OnInit, OnChanges {

  @Input() dataTable: any;

  @Input() phone: number;

  @Output() onGoToRepeatedPhones: EventEmitter<any> = new EventEmitter();

  public totalPages: any =  1;

  constructor(public headingTable: QuotaVsAffiliateHeadingTablesService, private modal: ModalService) { }

  ngOnInit() {
    console.log(this.dataTable);
    
  }

  ngOnChanges() {
    console.log(this.dataTable);
    this.totalPages = this.dataTable.totalPages;
  }

  closeModal() {
    this.modal.close();
  }

  goToRepeatedPhones($event) {
    console.log($event);
    
    this.onGoToRepeatedPhones.emit({
      phone: this.phone,
      currentPage: $event.currentPage
    })
  }
}
