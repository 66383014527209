import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuotaVsAffiliateHeadingTablesService {

  constructor() { }

  public quotaVsAffiliateHeaderTable: Array<string> = [
    'Seleccionar',
    'Contrato',
    'Estado',
    'Cupo Afiliado',
    'Cheque día',
    'Cheque Postfechado',
    'Cupo Disponible',
    'Pagaré',
    'Fecha',
    'Usuario Ingreso',
    'Fecha Actualiza',
    'Usuario Actualiza',
  ]

  public contactPhoneHeaderTable: Array<string> = [
    'Secuencia',
    'Girador',
    'Cod. País',
    'Municipio',
    'Indicativo',
    'Número',
    'Extensión',
    'Tipo teléfono',
    'Fecha/Hora registro',
    'Fecha/Hora verificación',
    'Fecha/Hora Actualización',
    'Estado',
  ]

  public repeatedPhonesHeaderTable: Array<string> = [
    'Tipo documento',
    'Documento',
    'Nombre',
    'Indicativo',
    'Teléfono',
    'Estado de cupo',
    'Tipo de girador',
  ]

  public quotaVsAffiliateCreateHeaderTable: Array<string> = [
    'Contrato',
    'Estado',
    'Cupo Afiliado',
  ]

  public contactAddressTable: Array<string> = [
    'Ciudad',
    'Departamento',
    'Dirección',
    'Relación',
    'Fecha/Hora registro',
    'Fecha/Hora Actualización',
    'Estado',
    'Nombre referencia',
    'Fuente',
  ]

  public contactEmailTable: Array<string> = [
    'Email',
    'Relación',
    'Fecha/Hora Registro',
    'Fecha/Hora Actualización',
    'Estado',
    'Fuente',
  ]
}
