export class BackingsToProcessRequestModel {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    issuerDocumentType:    string = '0';
    issuerDocument:        string = '0';
    branchOffice: string =   'NACIONAL';
    initialDate:            string = '';
    finalDate:              string = '';
    currentPage:             number = 1;
    perPage:                number = 10;
    sinisterStatus:    string = 'TODOS';
    contract?:               number = 0; 
    download?:          boolean = false;
    sinisterNumber?:       string = '0';
    draftNumber:            number  = 0;
}



