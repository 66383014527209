import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { createPortfolioResponse, getClientInfoMassiveChargeResponse, getContractsResponse, getQueryCodesMassiveChargeResponse, getTemplateExcelResponse, uploadPortfolioFileResponse } from '@app/models/massive-charge.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MassiveChargeService {

  apiUrlMassiveCharge: string;
  dev07ApiUrl: string;
  apiAuthMassiveCharge: string;
  apiAuthDataScoring: string;
  apiUrlTags: string;

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.ciCdInit();

  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { apiUrlMassiveCharge, apiAuthMassiveCharge, apiUrlTags, dev07ApiUtl, datascoringToken } = apis
      this.apiAuthDataScoring = datascoringToken;
      this.apiUrlMassiveCharge = apiUrlMassiveCharge;
      this.dev07ApiUrl = dev07ApiUtl;
      this.apiAuthMassiveCharge = apiAuthMassiveCharge;
      this.apiUrlTags = apiUrlTags;
    });
  }

  getPortfolioManagementActiveContracts(documentType: string, documentNumber: number): Observable<getContractsResponse> {
    return this.http.post<getContractsResponse>(this.apiUrlMassiveCharge + '/contracts/active', {
      "document": documentNumber,
      "documentType": documentType
    }, {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
      }
    })

    //return of(JSON.parse('{"ok":true,"message":"Success getting active portfolio management contracts","data":[{"contractNumber":300000,"affiliateName":"ZIGEPIZ F U"},{"contractNumber":400000,"affiliateName":"ZIGEPIZ F U"}]}')).pipe(delay(1000))
  }
  getClientDataByDocumentAndDocType(documentType: string, documentNumber: number, contractNumber: number): Observable<getClientInfoMassiveChargeResponse> {
    return this.http.post<getClientInfoMassiveChargeResponse>(this.apiUrlMassiveCharge + '/clients/info', {
      "docNumber": documentNumber,
      "docType": documentType,
      "covifacturaContract": contractNumber
    }, {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
      }
    })
    //return of(JSON.parse('{"ok":true,"message":"Success getting client","data":{"firstName":"OPQEBUP NO ZIDITMEU DSNU","secondName":"","firstLastName":"","secondLastName":"","documentType":"N","documentNumber":"8000601590","indicative":"91","phone":"7425390","mobile":"3108031952","email":"jsiguy@opqebup.zit.zi","state":"11","city":"11001","cityName":"BOGOTA","address":"ZY 12 93 17","nitCompany":"8000601590","companyName":"OPQEBUP NO ZIDITMEU DSNU","affiliate":"NO"}}')).pipe(delay(800))
  }

  postCheckPortfolio(documentType: string, documentNumber: number): Observable<any> {
    const bodyRequest = {
      "tipo_documento": documentType,
      "numero_documento": documentNumber
    }
    return this.http.post<any>(`${this.dev07ApiUrl}datascoring/api/v1/consulta_cartera`, bodyRequest,
      {
        headers: {
          authorization: 'Basic ' + btoa(this.apiAuthDataScoring

          )
        }
      }
    );

    /* return of(this.mockResponsePortfolio); */
  }

  postCreateUploadPaymentOrder(bodyRequest: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrlMassiveCharge}/pay-orders/pay-order`, bodyRequest,
      {
        headers: {
          authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
        }
      }
    );

    return of(this.mockCreateUploadPaymentOrder);
  }

  getQueryCodes(contractNumber: number): Observable<getQueryCodesMassiveChargeResponse> {
    return this.http.get<getQueryCodesMassiveChargeResponse>(this.apiUrlMassiveCharge + '/query-codes/active?contract=' + contractNumber, {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
      }
    })
    //return of(JSON.parse('{"ok":true,"message":"Success getting active query codes by contract","data":[{"queryCode":400002,"branch":"BOGOTA"},{"queryCode":517151,"branch":"CALI"},{"queryCode":517169,"branch":"BOGOTA"},{"queryCode":647842,"branch":"BUCARAMANGA"},{"queryCode":647867,"branch":"BUCARAMANGA"},{"queryCode":680066,"branch":"MEDELLIN"},{"queryCode":683102,"branch":"BOGOTA"},{"queryCode":693093,"branch":"BOGOTA"},{"queryCode":990903,"branch":"BOGOTA"},{"queryCode":990929,"branch":"BOGOTA"},{"queryCode":990978,"branch":"BOGOTA"},{"queryCode":113365,"branch":"BOGOTA"},{"queryCode":114173,"branch":"BOGOTA"},{"queryCode":117044,"branch":"BOGOTA"},{"queryCode":117051,"branch":"BOGOTA"},{"queryCode":117101,"branch":"BOGOTA"},{"queryCode":117119,"branch":"BOGOTA"},{"queryCode":117135,"branch":"BOGOTA"},{"queryCode":154641,"branch":"BOGOTA"},{"queryCode":157677,"branch":"VILLAVICENCIO"},{"queryCode":203299,"branch":"BOGOTA"},{"queryCode":354969,"branch":"BOGOTA"},{"queryCode":473231,"branch":"BOGOTA"},{"queryCode":473249,"branch":"BOGOTA"},{"queryCode":473322,"branch":"BOGOTA"},{"queryCode":473330,"branch":"BOGOTA"},{"queryCode":473348,"branch":"BOGOTA"},{"queryCode":473355,"branch":"BOGOTA"},{"queryCode":473363,"branch":"BOGOTA"},{"queryCode":473371,"branch":"BOGOTA"},{"queryCode":473389,"branch":"BOGOTA"},{"queryCode":473397,"branch":"BOGOTA"},{"queryCode":473405,"branch":"BOGOTA"},{"queryCode":473413,"branch":"BOGOTA"},{"queryCode":473421,"branch":"BOGOTA"},{"queryCode":474908,"branch":"BARRANQUILLA"},{"queryCode":474916,"branch":"BUCARAMANGA"},{"queryCode":474924,"branch":"CALI"},{"queryCode":474932,"branch":"MEDELLIN"},{"queryCode":972687,"branch":"BOGOTA"},{"queryCode":889220,"branch":"BOGOTA"},{"queryCode":838680,"branch":"BOGOTA"},{"queryCode":517144,"branch":"BOGOTA"}]}')).pipe(delay(700))
  }
  createPortfolio(affiliateDocumentType: string,
    affiliateDocumentNumber: string,
    contractNumber: number,
    buyerDocumentType: string,
    buyerDocumentNumber: number,
    queryCode: number,
    branch: string,
    productCode: string,
    balance: string,
    outstandingFees: number,
    cycle: number,
    creditNumber: string,
    adminQuotas: string,
    rate: number,
  ): Observable<createPortfolioResponse> {
    return this.http.post<createPortfolioResponse>(this.apiUrlMassiveCharge + '/portfolios/portfolio', {
      "username": JSON.parse(localStorage.getItem('user')).user,
      "affiliate": {
        "docNumber": parseInt(affiliateDocumentNumber),
        "docType": affiliateDocumentType,
        "covifacturaContract": contractNumber
      },
      "portfolio": {
        "buyerDocumentNumber": buyerDocumentNumber,
        "buyerDocumentType": buyerDocumentType,
        "branch": branch,
        "product": productCode,
        "balance": balance.toString(),
        "outstandingFees": outstandingFees,
        "cycle": cycle,
        "creditNumber": creditNumber,
        "adminFee": adminQuotas,
        "queryCode": queryCode,
        "initialFee": "0", //Navitrans no va
        "rate": rate //Navitrans no va
      }
    }, {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
      }
    })
    //return of(JSON.parse('{"ok":true,"message":"Partial Success","data":[{"ok":true,"message":"Success creating portfolio in Datascoring: -> Solicitud de Credito creada con exito","data":[{"message":"Solicitud de Credito creada con exito","operationNumber":"3662"}],"status":"200 OK"},{"ok":false,"message":"Internal server error -> Check error -> checkPortfolioUpload","data":null,"status":"500 INTERNAL_SERVER_ERROR"},{"ok":false,"message":"Internal Server Error -> Error covifactura transaction -> Error createUploadPortfolio: ","data":["No target to validate"],"status":"500 INTERNAL_SERVER_ERROR"}]}'))
  }

  createPortfolioMassive(documentType: string, documentNumber: string, file: any, contractNumber: string): Observable<uploadPortfolioFileResponse> {
    // Crea un nuevo FormData para enviar el archivo y los demás datos
    const formData = new FormData();
    formData.append('username', JSON.parse(localStorage.getItem('user')).user);
    formData.append('affiliateDocType', documentType);
    formData.append('affiliateDocNumber', documentNumber);
    formData.append('file', file, file.name);
    formData.append('contract', contractNumber);
    return this.http.post<uploadPortfolioFileResponse>(this.apiUrlMassiveCharge + '/portfolios/bulk-upload', formData,
      {
        headers: {
          authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
        }
      })
  }

  downloadExcelTemplate(): Observable<getTemplateExcelResponse> {
    return this.http.get<getTemplateExcelResponse>(this.apiUrlMassiveCharge + '/portfolios/bulk-upload/template/excel', {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthMassiveCharge)
      }
    }).pipe(
      map(el => {
        return el
      })
    )
  }

  public navitransTabs: any[] = [
    {
      href: '#buyerInformation',
      description: 'Información comprador',
      icon: 'icon-tab-individual.svg',
      key: 'individual-transactions'
    },
    {
      href: '#paymentOrderInformation',
      description: 'Información cartera',
      icon: 'icon-tab-individual.svg',
      key: 'special-transactions'
    },
  ];

  private mockResponsePortfolio = {
    ok: true,
    message: null,
    data: [
      {
        TipoDocumento: "N",
        NumeroDocumento: "8600284621",
        PrimerNombre: "",
        SegundoNombre: "",
        PrimerApellido: "",
        SegundoApellido: "",
        NitAfiliado: "8600284621",
        CodigoConsulta: "880757",
        NumeroCliente: "00000000002670",
        PRODUCTO: "CARTERA1_NVT",
        FECHAAPERTURA: "2024-09-23",
        PLAZOACTUAL: "8",
        Tasa: "30.000000",
        ESTADOOBLIGACION: "AL_DIA",
        SALDOINICIAL: "10.00",
        SaldoVigente: "107110.23",
        SALDOTOTAL: "107110.23",
        SaldoVencido: "0.00",
        ULTIMOPAGO: "2024-09-23",
        PROXIMOPAGO: "2024-10-30",
        DiasAtraso: "0",
        ValorMora: null,
        CuotasPagas: "0",
        CUOTASATRASO: "0",
        CuotasPendientes: "20",
        Comportamiento: "0",
        ValorCuotaOriginal: "17851.39",
        PagoMinimo: "2.00",
        CuotaInicial: "0.00",
        Celular: "",
        Correo: "",
        TotalPagosAplicados: "0",
        CantidadPagosAplicados: "0.00",
        NumeroOperacion: "3850",
        Descripcion: null,
        Amortizacion: null,
        AmortizacionConvertida: [
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Capital",
            Dias: "-11",
            Importe: "1.14",
            Debe: "1.14",
            Total: "1.14"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Interes Financiación",
            Dias: "-11",
            Importe: "0.00",
            Debe: "0.23",
            Total: "0.23"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Cuota Administracion",
            Dias: "-11",
            Importe: "2850.00",
            Debe: "2850.00",
            Total: "2850.00"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Cuota Administracion",
            Dias: "-11",
            Importe: "15000.00",
            Debe: "15000.00",
            Total: "15000.00"
          },
          // (continúa con las siguientes cuotas)
        ]
      },
      {
        TipoDocumento: "N",
        NumeroDocumento: "8600284621",
        PrimerNombre: "",
        SegundoNombre: "",
        PrimerApellido: "",
        SegundoApellido: "",
        NitAfiliado: "8600284621",
        CodigoConsulta: "880757",
        NumeroCliente: "00000000002670",
        PRODUCTO: "CARTERA1_NVT",
        FECHAAPERTURA: "2024-09-23",
        PLAZOACTUAL: "8",
        Tasa: "30.000000",
        ESTADOOBLIGACION: "AL_DIA",
        SALDOINICIAL: "10.00",
        SaldoVigente: "107110.23",
        SALDOTOTAL: "107110.23",
        SaldoVencido: "0.00",
        ULTIMOPAGO: "2024-09-23",
        PROXIMOPAGO: "2024-10-30",
        DiasAtraso: "0",
        ValorMora: null,
        CuotasPagas: "0",
        CUOTASATRASO: "0",
        CuotasPendientes: "20",
        Comportamiento: "0",
        ValorCuotaOriginal: "17851.39",
        PagoMinimo: "2.00",
        CuotaInicial: "0.00",
        Celular: "",
        Correo: "",
        TotalPagosAplicados: "0",
        CantidadPagosAplicados: "0.00",
        NumeroOperacion: "3850",
        Descripcion: null,
        Amortizacion: null,
        AmortizacionConvertida: [
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Capital",
            Dias: "-11",
            Importe: "1.14",
            Debe: "1.14",
            Total: "1.14"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Interes Financiación",
            Dias: "-11",
            Importe: "0.00",
            Debe: "0.23",
            Total: "0.23"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Cuota Administracion",
            Dias: "-11",
            Importe: "2850.00",
            Debe: "2850.00",
            Total: "2850.00"
          },
          {
            NumeroOperacion: "3850",
            Amortizacion: "1",
            FechaVencimiento: "2024-10-30",
            Concepto: "Cuota Administracion",
            Dias: "-11",
            Importe: "15000.00",
            Debe: "15000.00",
            Total: "15000.00"
          },
          // (continúa con las siguientes cuotas)
        ]
      }
    ]
  };

  mockCreateUploadPaymentOrder = {
    ok: true,
    message: "Success creating payment order",
    data: [
        {
            liquidationSequence: 5000000369,
            datascoringData: {
                message: null,
                totalArrears: 207100,
                capitalValue: 100000,
                financingInterest: 0,
                arrearsInterest: 0,
                vat: null,
                collectionCost: null,
                administrationFee: 90000,
                vatAdministrationFee: 17100,
                convertedBase: null
            }
        }
    ]
}

}
