import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-activate-quota',
  templateUrl: './activate-quota.component.html',
  styleUrls: ['./activate-quota.component.scss']
})
export class ActivateQuotaComponent implements OnInit {

  quotaVsAffiliateSearchForm: FormGroup

  searchBySettlement: Boolean = true;

  isQuerySuccess: boolean = false;

  docTypes: any;

  quotaVsAffiliateData: any[];

  constructor(private helper: HelperService, private formBuilder: FormBuilder, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.quotaVsAffiliateSearchForm = this.formBuilder.group({
      contract: ['', Validators.required],
      documentType: ['', Validators.required],
      document: ['', Validators.required],
      affiliateName: [''],
      product: [''],
      assignedQuota: [''],
    })

    this.quotaGreater.getDocTypes().subscribe((resp: any) => {
      this.docTypes = resp.data
      console.log(this.docTypes);
      
    })
  }

  get getIsQuerySuccess() {
    return this.isQuerySuccess;
  }

  onFindContract() {
    this.spinner.show();
    const body = {
      contract: this.quotaVsAffiliateSearchForm.get('contract').value,
      documentType: this.quotaVsAffiliateSearchForm.get('documentType').value,
      document: this.quotaVsAffiliateSearchForm.get('document').value,
    }

    this.quotaGreater.byBuyerAndContract(body).subscribe((resp: any) => {
      this.spinner.hide();
      this.isQuerySuccess = true;
      this.quotaVsAffiliateSearchForm.get('affiliateName').setValue(resp.data[0].affiliateName);
      this.quotaVsAffiliateSearchForm.get('product').setValue(resp.data[0].product);
      this.quotaVsAffiliateSearchForm.get('assignedQuota').setValue(resp.data[0].assignedQuota);
      
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  onSubmitContract() {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;

    if(userUpdate) {
      const body = {
        documentType: this.quotaVsAffiliateSearchForm.get('documentType').value,
        document: this.quotaVsAffiliateSearchForm.get('document').value,
        contract: this.quotaVsAffiliateSearchForm.get('contract').value,
        assignedQuota: this.quotaVsAffiliateSearchForm.get('assignedQuota').value,
        userUpdate: userUpdate,
      }
  
      this.quotaGreater.updateAssignedQuota(body).subscribe((resp:any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${resp.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
  }

  onSubmitSearch() {
    console.log(this.quotaVsAffiliateSearchForm.get('idPayer').value); 
    this.isQuerySuccess = true; 
    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: 1,
      perPage: 10
    } 
    this.quotaGreater.getBuyerQuotas(body).subscribe((resp: any) => {
      this.quotaVsAffiliateData = resp.data[0];
    })
  }

}
