export class NonPaymentNoticeToProcessRequestModel {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    issuerDocumentType:    string = '0';
    issuerDocument:        string = '0';
    contract:              number   = 0;
    draftNumber:          string  = '0';
    initialDate:                 string;
    finalDate:                   string;
    currentPage:                 number;
    perPage:                number = 10;
    authorization:            number = 0;
}


export class markNonPaymentNoticeToProcessRequest {
    user:                      string;
    nonPaymentNoticeToProcess: NonPaymentNoticeToProcess[];
}

export class NonPaymentNoticeToProcess {
    affiliateDocumentType:    string = '';
    affiliateDocument:         number = 0;
    issuerDocumentType:       string = '';
    issuerDocument:            number = 0;
    authorization:             number = 0;
    draftNumber:              string = '';
    guaranteedValue:           number = 0;
    queryCode:                 number = 0;
    contract:                  number = 0;
    sinisterNumber:            number = 0;
    reportDate:               string = '';
    nonPaymentNoticeStatus:   string = '';
}


