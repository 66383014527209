import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable,  } from 'rxjs';
import { SearchAffiliateModel } from '../models/request/SearchAffiliateModel';
import { AffiliatesListModel, AffiliatesResponse } from '../models/response/SearchAffiliate.model';
import { SearchAffiliateComponent } from '../search-affiliate/search-affiliate.component';
import { BackupsStoreService } from '../services/backups-store.service';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';

@Component({
  selector: 'app-backups-table',
  templateUrl: './backups-table.component.html',
  styleUrls: ['./backups-table.component.scss'],
})
export class BackupsTableComponent implements OnDestroy {

  @ViewChild('searchAffiliateComponent') searchAffiliateComponent: SearchAffiliateComponent;

  public affiliateList$: Observable<AffiliatesResponse> = this.backupsStore.stateAffiliateList$;
  public searchAffiliate: Observable<SearchAffiliateModel> = this.backupsStore.stateSearchAffiliate$;

  constructor(private router: Router, private backupsStore: BackupsStoreService, public tableHeaders: BackupsTableHeadersService) {
  }
  
  ngOnDestroy() {
    this.backupsStore.changeStateAffiliateList(new AffiliatesResponse());
  }

  goToPage(currentPage: any){
    this.searchAffiliateComponent.goToPage(currentPage);
  }

  handleSelectAffiliate(affiliate: AffiliatesListModel) {
    const {  affiliateDocumentType, affiliateDocument, issuerDocumentType, issuerDocument } = affiliate;
    this.backupsStore.changeStateAffiliate(affiliate);
    this.router.navigate(['pages/respaldos/afiliado', affiliateDocumentType, affiliateDocument]);
  }
}
