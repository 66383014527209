import { Component, Input, OnInit } from "@angular/core";
import { SelectModel } from "@app/models/select.model";
import { QuotaRadication } from "@app/pages/quota-radication/models/quotaRadication.model";
import { QuotaSettlementHist } from "@app/pages/quota-radication/models/quotaSettlementHist.model";
import { RadicationService } from "@app/pages/quota-radication/services/radication-quota.service";
import { HelperService } from "@app/services/helper.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";

@Component({
  selector: "app-history-request",
  templateUrl: "./history-request.component.html",
  styleUrls: ["./history-request.component.scss"],
})
export class HistoryRequestComponent implements OnInit {
  @Input() quotaSettlement: QuotaRadication = null;
  public requestStatesAll: SelectModel[] = [];
  public requestTypes: Array<SelectModel> = [];

  public quotaSettlementHist: QuotaSettlementHist[] = [];

  constructor(
    public radicationService: RadicationService,
    public helper: HelperService,
    private quotaRadicationService: QuotaRadicationService
  ) {
    this.requestStatesAll = radicationService.requestStatesAll;
  }

  ngOnInit() {
    this.requestTypes = JSON.parse(
      localStorage.getItem("requestTypeSettlements")
    );
    this.getHistoryRequest();
  }

  getHistoryRequest() {
    this.quotaRadicationService
      .getQuotaRadicationHist(this.quotaSettlement.idRadQuota)
      .subscribe(
        (resp) => {
          resp.data.forEach((element: QuotaSettlementHist) => {
            let quotaSettlement = element;
            if (
              this.quotaSettlement.requestType != 3 &&
              this.quotaSettlement.requestType != 4
            ) {
              quotaSettlement.timeTrans = this.helper.calculateWorkingHours(
                new Date(this.quotaSettlement.requestDate),
                new Date(quotaSettlement.managementDate)
              );
            } else {
              quotaSettlement.timeTrans = this.helper.calculateWorkingHours(
                new Date(this.quotaSettlement.requestDate),
                new Date(quotaSettlement.managementDate),
                true
              );
            }
            this.quotaSettlementHist.push(quotaSettlement);
          });
        },
        (error) => {}
      );
  }
}
