import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-central-risk-summary',
  templateUrl: './central-risk-summary.component.html',
  styleUrls: ['./central-risk-summary.component.scss']
})
export class CentralRiskSummaryComponent implements OnInit, OnChanges {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(this.quotaData);
      
  }

}
