import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class MacrosService {
  public year = moment(new Date()).year() - 2;
  public generalBalance: Array<any> = [
    {
      id: 1,
      title: "Disponible",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 2,
      title: "Inversiones Temporales",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 3,
      title: "Cuentas por Cobrar - Clientes",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 5,
      title: "Inventarios",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 4,
      title: "Cuentas por cobrar socios",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 6,
      title: "Otros Activos Corrientes",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 7,
      title: "Total Activo Corriente",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 8,
      title: "Activos Fijos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 9,
      title: "(Depreciacion Acumulada)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 10,
      title: "Activo Fijo Neto",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 11,
      title: "Otros Activos No Corrientes",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 12,
      title: "TOTAL ACTIVOS (SIN VALORIZACIONES)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 13,
      title: "Valorizaciones",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 14,
      title: "TOTAL ACTIVOS",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 15,
      title: "Obligaciones bancarias (C.P)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 16,
      title: "Cuentas proveedores",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 17,
      title: "Deudas con socios",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 18,
      title: "Otros pasivos (C.P)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 19,
      title: "Anticipos Recibidos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 20,
      title: "Total Pasivo Corriente",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 21,
      title: "Obligaciones Bancarias (L.P)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 22,
      title: "Deudas con socios",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 23,
      title: "Otros Pasivos (L.P)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 24,
      title: "Anticipos Recibidos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 25,
      title: "TOTAL PASIVO",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 26,
      title: "Capital Pagado",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 27,
      title: "Otras de Patrimonio",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 28,
      title: "Valorizaciones (Desvalorizaciones)",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 29,
      title: "Utilidades (Perdidas) acumuladas",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 30,
      title: "Utilidades (Perdidas) del período",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 31,
      title: "TOTAL PATRIMONIO",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 32,
      title: "TOTAL PASIVO + PATRIMONIO",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
    {
      id: 33,
      title: "",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
    },
  ];

  public pgState: any = [
    {
      id: 1,
      title: "VENTAS NETAS",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 2,
      title: "Costo de Ventas",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 3,
      title: "UTILIDAD (PERDIDA) BRUTA",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 4,
      title: "Gtos. Operacionales",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 5,
      title: "UTILIDAD (PERDIDA) OPERACIONAL",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 6,
      title: "Otros ingresos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 7,
      title: "Otros Egresos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 8,
      title: "Impuestos",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 9,
      title: "UTILIDAD (PERDIDA) NETA",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 10,
      title: "Depreciaciones y Amortizaciones",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 11,
      title: "EBITDA",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
    {
      id: 12,
      title: "Capacidad Inicial por ventas",
      valY1: "",
      perc1: "",
      valY2: "",
      perc2: "",
      valY: "",
      perc: "",
      percBetYears: "",
      valBetYears: "",
      proyect: "",
    },
  ];

  public financialReasons: any = [
    {
      id: 1,
      title: "Indicadores de Liquidez y Actividad",
      valY1: this.year,
      valY2: this.year + 1,
      valY: "",
    },
    {
      id: 2,
      title: "Razón Corriente",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 3,
      title: "Prueba Acida",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 4,
      title: "Capital de Trabajo",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 5,
      title: "Rotación de Cartera",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 6,
      title: "Rotación Inventarios",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 7,
      title: "Rotación Proveedores",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 8,
      title: "Ciclo Operacional",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 9,
      title: "Indicadores de Endeudamiento",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 10,
      title: "Endeudamiento Total",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 11,
      title: "Endeudamiento Sin valorizaciones",
      valY1: "",
      valY2: "",
      valY: "",
    },
    {
      id: 12,
      title: "Endeudamiento corto plazo",
      valY1: "",
      valY2: "",
      valY: "",
    },
  ];

  headers = {
    title: "BALANCE GENERAL",
    year1: this.year,
    year2: this.year + 1,
    perc: "% V",
    year: "#N/A",
    monthValue: "",
    perBetYears: "▲▼%",
    valBetYears: "▲▼$",
    yearCalculated: this.year + 1 + "/" + this.year,
  };

  headersPGState = {
    title: "ESTADO DE P Y G",
    year1: this.year,
    year2: this.year + 1,
    perc: "% V",
    year: "#N/A",
    proyect: "P&G proyect.",
    perBetYears: "▲▼%",
    valBetYears: "▲▼$",
    yearCalculated: this.year + 1 + "/" + this.year,
  };

  headersFR = {
    title: "RAZONES FINANCIERAS",
    year1: "",
    year2: "",
    year: "",
  };

  calculateUtilNeta(a = 0, b = 0, c = 0, d = 0) {
    let result = a + b - c - d;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result;
  }

  calculateNetaValY(a = 0, b = 0, c = 0) {
    let result = a + b - c;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result.toFixed(2);
  }

  roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  calculateUtilPerc(a = 0, b = 0, c = 0.01) {
    let result = a / b / c;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    result = this.roundUp(result, 3);
    return this.decimalAdjust("round", result, -1);
  }

  calculateUtilProyect(a = 0, b = 0) {
    let result = (a / b) * 12;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result;
  }

  calculateUtilBruta(a = 0, b = 0) {
    let result = a - b;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result;
  }

  calculateEBITDA(a = 0, b = 0) {
    let result = a + b;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result;
  }

  calculateSale(a) {
    let result = (a / 12) * 0.3;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result;
  }

  calculatePerBetYear(a = 0, b = 0) {
    let result = (b - a) / a;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    result = result / 0.01;
    return Math.round(result);
  }

  calculateReasons(a = 0, b = 0, c = 0, d = 1, fixed = 2) {
    let result = (a - b) / c / d;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    result = result;
    return result.toFixed(fixed);
  }

  calculateValBetYear(a = 0, b = 0, isNegative: boolean = true) {
    let result = a - b;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    if (result < 0 && isNegative) {
      result = result * -1;
    }
    return result;
  }

  calculaRes(a = 0, b = 0, c = 0) {
    let result = a - b - c;

    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }

    return result;
  }

  calculateFormulaPerc(
    formula: string,
    x: number = 0,
    y: number = 0,
    z: number = 0
  ) {
    try {
      let formulaUpdated = formula;
      if (x.toString() == "") x = 0;
      if (y.toString() == "") y = 0;
      if (z.toString() == "") z = 0;

      formulaUpdated = formulaUpdated.replace("x", x.toString());
      formulaUpdated = formulaUpdated.replace(/y/g, y.toString());
      formulaUpdated = formulaUpdated.replace("z", z.toString());
      formulaUpdated = formulaUpdated.replace("--", "-");
      formulaUpdated = formulaUpdated.replace(" ", "0");
      let result = eval(formulaUpdated);
      if (result === Infinity || isNaN(result) || result === -Infinity) {
        return (result = 0);
      }
      return this.decimalAdjust("round", result, -1);
    } catch (err) {
      console.log(formula, x, y, z);
      console.error(err.message);
      return 0;
    }
  }

  calcularInfo(a: number, b: number): any {
    let result = (a / b) * 360;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result.toFixed(2);
  }

  calcularData(a: number, b: number): any {
    let result = a / b;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result.toFixed(2);
  }

  calcularPerc(a: number, b: number): any {
    let result = a / b / 0.01;
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result.toFixed(1);
  }

  calcularValue(a: number, b: number, c: number): any {
    let result = (a / b) * (c * 30);
    if (result === Infinity || isNaN(result) || result === -Infinity) {
      return (result = 0);
    }
    return result.toFixed(2);
  }

  decimalAdjust(type, value, exp) {
    // Si el exp no está definido o es cero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Si el valor no es un número o el exp no es un entero...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return 0;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  constructor() {}
}
