import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  private currencyPipe = new CurrencyPipe('es-CO');

  transform(value: any, currencyCode: string = 'COP', display: 'symbol' | 'code' = 'symbol', digitInfo?: string): any {
    if (!isNaN(Number(value))) {
      return this.currencyPipe.transform(value, currencyCode, display, digitInfo);
    }

    if(isNaN(value)) return '-';

    return value;
  }

}
