import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditInfoHeadersService {

  public recognizePhonesHeaders: string[] = [
    '# Orden',
    'Número',
    'Tipo',
    'Ciudad',
    'Departamento',
    'Reportado desde',
    'Último reporte',
    '# de Reportes',
    '# de Entidades',
  ];

  public emailTableHeaders: string[] = [
    '# Orden',
    'Correo',
    'Reportado desde',
    'Último Reporte',
    '# Reportes',
  ];

  constructor() { }


}
