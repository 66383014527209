import { Pipe, PipeTransform } from '@angular/core';
import { AppState } from '@app/redux/app.reducer';
import { Store } from '@ngrx/store';
import { SelectModel } from '../models/select.model';

@Pipe({
  name: 'productTypeDescription'
})
export class ProductTypeDescriptionPipe implements PipeTransform {

  public selectOptions: SelectModel[] = [];

  constructor(private store: Store<AppState>) {

  }

  async transform(value: any, selectType: any): Promise<string> {
    return await this.getSelects(value, selectType);
  }

  getDescriptionByValue(value: string) {
    const [description] = this.selectOptions
      .filter((option: SelectModel) => option.value == value) || [''];

    return description ? description['description'] : '';
  }

  getSelects(value: string, selectType: string): Promise<string> {
    return new Promise((resolve, reject) => {

      if(!selectType) reject('No se ha especificado el tipo de select');

      this.store.select('selects')
        .subscribe(({ selects }: { selects: any }) => {
          // console.log('selects on pipe', selectType, selects);
          this.selectOptions = selects[selectType] || [new SelectModel()];
          const result = this.getDescriptionByValue(value);
          resolve(result);
        }, (error) => {
          reject(value);
          console.error(error);
        });
    });
  }

}

