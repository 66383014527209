import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { Resource } from "@app/models/Resource.model";

import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Response } from "@app/models/responseUtil.model";
import { HelperService } from "./helper.service";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class CoviResourceService {
  public urlApi: string;
  public apiUrl: string;
  public  dev07ApiUtl: string;
  public urlInvoicesTemplate: string;
  public coviUtilsUrl: string;
  coviResourceToken: string = '';
  private headers;;

  constructor(private http: HttpClient,
    private helper: HelperService,
    private ciCd: CiCdConfigService
    ) {
    this.urlApi = `${environment.coviResourceUrl}CoviResourceServices/servicios/covifactura`;
    this.dev07ApiUtl = environment.dev07ApiUtl;
    this.apiUrl = environment.apiUrl;
    this.urlInvoicesTemplate = environment.urlInvoicesTemplate;
    this.coviUtilsUrl = environment.coviUtilsUrl;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const { coviResourceUrl, apiUrl, urlInvoicesTemplate,coviUtilsUrl, dev07ApiUtl, coviResourceToken } = apis;
      this.urlApi = `${coviResourceUrl}CoviResourceServices/servicios/covifactura`;
      this.dev07ApiUtl = dev07ApiUtl;
      this.apiUrl = apiUrl;
      this.urlInvoicesTemplate = urlInvoicesTemplate;
      this.coviUtilsUrl = coviUtilsUrl;
      this.coviResourceToken = coviResourceToken;
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization": `Basic ${btoa(this.coviResourceToken)}`
      });
    });
  }

  searchFilter(resource: Resource): Observable<Response<Resource>> {
    const options = {
      headers: this.headers,
    };

    const body = resource;
    return this.http
      .post(`${this.urlApi}/findImageByCriteria`, body, options)
      .pipe(
        map((resp: Response<Resource>) => {
          return resp;
        })
      );
  }

  public getGeneralList(): Observable<any> {
    const headers = new HttpHeaders(
      this.helper.getHeadersAuthorization()
    );

    let options = { headers }

    return this.http.get<any>(`${this.dev07ApiUtl}/commons/lists`, options)
  }

  public initializeLists() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let options = { headers }

    return this.http.get<any>(`${this.urlInvoicesTemplate}/servicios/inicializar`, options)
  }

  getMunicipalities() {
    return this.http.get<any>(`${this.coviUtilsUrl}lists`).pipe(
      map(response => {
        return response.data[0].departaments
      })
    );
  }

  getCities(departmentId: string) {
    const params = { municipaly: departmentId }
    const options = {
      params
    };
    return this.http.get(`${this.coviUtilsUrl}lists/cities`, options);
  }
}
