import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { IncomeRecoveryHeadersService } from '../services/income-recovery-headers.service';

@Component({
  selector: 'app-income-recovery-incomes',
  templateUrl: './income-recovery-incomes.component.html',
  styleUrls: ['./income-recovery-incomes.component.scss']
})
export class IncomeRecoveryIncomesComponent implements OnInit {

  @Input() tableHeaders: Array<string> = [];
  @Input() dataTable: Array<any> = [];
  @Input() pageData: any = {};

  @Output() handleCurrentPage: EventEmitter<any> = new EventEmitter();

  public totalPagesAddress = 1;

  constructor(public incomeRecovery: IncomeRecoveryHeadersService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPagesAddress = this.pageData.totalPages;
  }

  goToPage($event) {
    this.handleCurrentPage.emit($event.currentPage)
  }
}
