import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';
import { AffiliatesListModel } from '../models/response/SearchAffiliate.model';
import { TransactionsToBackupResponse, TransactionsToBackUpResponseModel } from '../models/response/TransactionsToBackupResponse';
import { BackupsStoreService } from '../services/backups-store.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { TransactionsToBackUpRequestModel } from '../models/request/TransactionsToBackupModel';
import { CreateBackingsRequest, CreateBackingsRequestModel } from '../models/request/CreateBackings.model';
import { CreateBackingsResponse } from '../models/response/CreateBackings.model';
import { HelperService } from '@app/services/helper.service';
import { SelectsService } from '@app/services/selects.service';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';
import { ModalService } from '@app/services/modal.service';
import { SearchAutocompleteAffiliateComponent } from '../search-autocomplete-affiliate/search-autocomplete-affiliate.component';

@Component({
  selector: 'app-settlement-table',
  templateUrl: './settlement-table.component.html',
  styleUrls: ['./settlement-table.component.scss']
})
export class SettlementTableComponent implements OnInit, OnDestroy {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;
  public activeTab: string = '';
  public parentTab: string = 'settlement-table';
  public showModalOnError: boolean = false;

  public stateShowSearchResult$: Observable<boolean> = this.backupsStore.stateShowSearchResult$;
  public transactionsToBackup: TransactionsToBackUpResponseModel[] = [];
  public selectedItemsSettlement: CreateBackingsRequestModel[] = [];
  public createBackingRequest: CreateBackingsRequest = new CreateBackingsRequest();
  public search: TransactionsToBackUpRequestModel = new TransactionsToBackUpRequestModel();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private backupsService: BackupsService, private backupsStore: BackupsStoreService,
    private spinner: NgxSpinnerService, public helper: HelperService, public selects: SelectsService,
    public tableHeaders: BackupsTableHeadersService, public modal: ModalService) {
  }

  ngOnInit() {
    // this.modal.show('settlementResponse', { title: 'Respuesta de radicación' });

    this.backupsStore.stateBackupsActiveTab$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(
        (stateBackupsActiveTab: string) => {
          this.activeTab = stateBackupsActiveTab;
          this.initilizeDateRangePicker();
          if (this.activeTab === this.parentTab) {
            this.searchAutocompleteAffiliate.reset();
            this.showModalOnError = true;
            // this.getTransactionsToBackUp(1); 
          }
        });

    this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (stateAffiliate: AffiliatesListModel) => {
          //console.log('stateAffiliate on radicacion', stateAffiliate);
          this.initAffiliate(stateAffiliate);
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getTransactionsToBackUp(currentPage);
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const instanceModel = new TransactionsToBackUpRequestModel();
    this.search = {
      ...this.helper.assignEnumerableKeysAndAssignDefaultValues(stateAffiliate, instanceModel),
      initialDate: this.search.initialDate,
      finalDate: this.search.finalDate
    };
   // console.log("this.search setlement", this.search);
    // this.initilizeDateRangePicker();
    //  Si recibe el document vacio no ejecuta una nueva búsqueda
    if (this.activeTab === this.parentTab && (this.search.affiliateDocument !== '0' || this.search.issuerDocument !== '0')) {
      this.showModalOnError = false;
      this.getTransactionsToBackUp(1);
    }
  }

  onSearch(textSearch: string) {
    //console.log('textSearch', textSearch);
    // this.search.search = textSearch;
    this.getTransactionsToBackUp(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    if (!this.validateAffiliate()) return;

    this.getTransactionsToBackUp(1);
  }

  getTransactionsToBackUp(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = currentPage;
    //console.log("SEARCHHHHH:",this.search);
    
    this.backupsService.getTransactionsToBackUp(this.search).subscribe(
      (transactionsToBackUp: TransactionsToBackupResponse) => {
        if (transactionsToBackUp && transactionsToBackUp.ok === false) {
          this.handleGetTransactionsToBackUpError();
          return;
        }
        this.transactionsToBackup = transactionsToBackUp['data'];
        this.cantPages = transactionsToBackUp['totalPages'];
        this.selectedItemsSettlement = [];
        this.spinner.hide();
        this.searchAutocompleteAffiliate.toggleResults(false);
        //console.log('transactions to backup ->', transactionsToBackUp);
      }, (error: any) => {
        this.handleGetTransactionsToBackUpError(error);
      }
    );
  }

  checkedItemsSettlement(transactionSelected: TransactionsToBackUpResponseModel) {
    const { affiliateDocumentType: documentType, affiliateDocument: document, } = transactionSelected;

    // this.createBackingRequest.affiliateDocumentType = documentType;
    // this.createBackingRequest.affiliateDocument = document;

    this.selectedItemsSettlement = this.transactionsToBackup
      .filter((transaction: TransactionsToBackUpResponseModel, idx) => transaction['check'] === true);
    this.createBackingRequest.transactionsToBackUp = this.selectedItemsSettlement
      .map((transaction: TransactionsToBackUpResponseModel) => this.convertItemToBackingRequest(transaction));
  }

  convertItemToBackingRequest(transactionSelected: TransactionsToBackUpResponseModel): CreateBackingsRequestModel {
    let createBackingsRequest: CreateBackingsRequestModel = new CreateBackingsRequestModel();
    for (const key of Object.keys(transactionSelected)) {
      if (key in createBackingsRequest) {
        createBackingsRequest[key] = transactionSelected[key];
      }
    }
    return createBackingsRequest;
  }

  createBacking() {
    this.spinner.show();
    this.backupsService.createBacking(this.createBackingRequest).subscribe(
      (createBackingResponse: CreateBackingsResponse) => {
        if (createBackingResponse && createBackingResponse['ok'] === false) {
          this.handleCreateBackingError();
          return;
        }
        //console.log('create backing ->', createBackingResponse);
        this.spinner.hide();
        this.selectedItemsSettlement = [];

        // Nuevo modal de respuesta
        // this.modal.show('settlementResponse', { title: 'Respuesta de radicación' });

        Swal.fire({
          type: 'success', title: 'Radicación', text: `${createBackingResponse.message}`, allowOutsideClick: false
        }).then(() => {
          this.modal.close();
          this.getTransactionsToBackUp(1);
        });
      }, (error: any) => {
        this.handleCreateBackingError(error);
      }
    );
  }

  handleGetTransactionsToBackUpError(error?: any) {
    //console.error(error);
    if (this.showModalOnError === false) {
      if (error && error.error && error.error.message && this.counterRequest > 1) {
        const { message } = error.error;
        Swal.fire({
          type: 'error', title: 'Radicación', text: `${message}`, allowOutsideClick: false
        });
      } else if (this.counterRequest > 1) {
        this.helper.handleUndefinedError();
      }
    }
    this.createBackingRequest.transactionsToBackUp = [];
    this.transactionsToBackup = [];
    this.selectedItemsSettlement = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleCreateBackingError(error?: any) {
    //console.error(error);
    this.getTransactionsToBackUp(1);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Crear respaldos', text: `${message}`, allowOutsideClick: false
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.selectedItemsSettlement = [];
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");
  }

  clearSearch($event) {
    this.transactionsToBackup = [];
    this.backupsStore.changeStateAffiliate(new AffiliatesListModel());
    //console.log('clear search on cont')
  }

  validateAffiliate() {
    const { affiliateDocument, issuerDocument } = this.search;
    //console.log("validate =>", affiliateDocument, issuerDocument);
    if (affiliateDocument && affiliateDocument !== '0' || issuerDocument && issuerDocument !== '0') {
      return true;
    }
    //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');
    return false;
  }

}
