import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IncomeRecoveryHeadersService {

  public incomesHeaders: string[] = [
    "Deducible",
    "Actualizar Usuario",
    "Capital",
    "Valor Intereses",
    "Valor Cuotas",
    "IVA Cuotas",
    "Valor Sanción",
    "Gastos Cobranza",
    "IVA Gastos Cobranza",
    "Valor IVA Gastos Cobranza",
    "Cuota Fija",
    "Razón Cancelación",
    "Fecha Pago Real",
    "Tipo Recuperación",
    "Número Expediente",
    "Cancelado",
    "Recibo Efectivo",
    "Sin IVA",
    "Fecha Actualización",
    "Fecha Ingreso",
    "ID Pago",
    "Total Recibido"
  ]

  public cancellationsHeaders: string[] = [
    "Deducible",
    "Actualizar Usuario",
    "Capital",
    "Valor Intereses",
    "Valor Cuotas",
    "IVA Cuotas",
    "Valor Sanción",
    "Gastos Cobranza",
    "IVA Gastos Cobranza",
    "Valor IVA Gastos Cobranza",
    "Cuota Fija",
    "Razón Cancelación",
    "Fecha Pago Real",
    "Tipo Recuperación",
    "Número Expediente",
    "Cancelado",
    "Recibo Efectivo",
    "Sin IVA",
    "Fecha Actualización",
    "Fecha Ingreso",
    "ID Pago",
    "Total Recibido"
  ]

  public forDatesHeaders: string[] = [
    "Deducible",
    "Actualizar Usuario",
    "Capital",
    "Valor Intereses",
    "Valor Cuotas",
    "IVA Cuotas",
    "Valor Sanción",
    "Gastos Cobranza",
    "IVA Gastos Cobranza",
    "Valor IVA Gastos Cobranza",
    "Cuota Fija",
    "Razón Cancelación",
    "Fecha Pago Real",
    "Tipo Recuperación",
    "Número Expediente",
    "Cancelado",
    "Recibo Efectivo",
    "Sin IVA",
    "Fecha Actualización",
    "Fecha Ingreso",
    "ID Pago",
    "Total Recibido"
  ]

  public filingNumberHeaders: string[] = [
    "Deducible",
    "Actualizar Usuario",
    "Capital",
    "Valor de Intereses",
    "Valor de Cuotas",
    "IVA de Cuotas",
    "Valor de Sanción",
    "Gastos de Cobranza",
    "IVA de Gastos de Cobranza",
    "Valor del IVA de Gastos de Cobranza",
    "Valor de Cuota Fija",
    "Razón de Cancelación",
    "Fecha Real de Pago",
    "Tipo de Recuperación",
    "Número de Expediente",
    "Cancelado",
    "Recibo de Caja",
    "Sin IVA",
    "Fecha de Actualización",
    "Fecha de Ingreso",
    "ID de Pago",
    "Total Recibido"
  ]

  constructor() { }
}
