import { Component, OnInit } from '@angular/core';
import { HabitOfPaymentInactive, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-habit-of-payment-inactive-history',
  templateUrl: './habit-of-payment-inactive-history.component.html',
  styleUrls: ['./habit-of-payment-inactive-history.component.scss']
})
export class HabitOfPaymentInactiveHistoryComponent implements OnInit {

  public habitsOfPaymentInactive: HabitOfPaymentInactive[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.habitsOfPaymentInactive = this.subjectCreditHistory.getHabitOfPaymentInactive(response);
  }

}
