export class SellersResponse {
  approvedTransactions: number;
  amount: number;
  userName: string;
  email: string;
  userPhoto: any = 'assets/img/administration/icon-change-img-profile.svg';
  birthday: any;
  rol: string;
  cellphone: number;

  public static fromResponse(json: any): any {
    const sellersResponse: SellersResponse = {
      approvedTransactions: json["APROBADAS"],
      amount: json["MONTO"],
      userName: json["NOMBRE_USUARIO"],
      email: json["EMAIL"],
      userPhoto: json["FOTO_USUARIO"],
      birthday: json["FECHA_CUMPLEANOS"],
      rol: json["ROL"],
      cellphone: json["CELULAR"]
    };
    return sellersResponse;
  }
}
