import { AffiliateAsrTemp } from "./affiliateAsrTemp.model";

export class ContractAsrTemp {
  numberContract: number;
  branchOffices: string;
  product: string;
  billingType: string;
  billingPeriod: string;
  billingDay: number;
  affiliationDate: Date;
  idAdviser: number;
  advisorName: string;
  advisorUser: string;
  idAffiliate: number;
  status: boolean;
  affiliateAsrTemp: AffiliateAsrTemp;
  statusReal: string;
  nombreComercial: string;
}
