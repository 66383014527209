import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateLinkGlobalBilling } from '@app/models/afiliate-link';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { SelectsService } from '@app/services/selects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
import { CutBillingService } from '@app/models/cutBillingmodel';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { ComunicationService } from '../billing-cut/comunication.service';
import { HistoryConsumptionCutDate } from '../../../services/history-consumption-cut-date';
import { Data } from '../../../models/selectModel';

@Component({
  selector: 'app-consumption-variable',
  templateUrl: './consumption-variable.component.html',
  styleUrls: ['./consumption-variable.component.scss'],
  providers: [DatePipe],
})
export class ConsumptionVariableComponent implements OnInit {
  public identity: any;
  public cutBillingForm: FormGroup;
  public maximumDate: DateLinkGlobalBilling = new DateLinkGlobalBilling();
  public user: string;
  public data: CutBillingService;
  public dataHistory: Date[] = [];
  public dateMasiveBilling: Date = new Date();
  public nullRegister: boolean = null;
  public lastBillingCut: number = null;
  private consecutivesList = [];
  public selectedCuts: number[] = [];

  constructor(
    public helper: HelperService,
    public select: SelectsService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public modal: ModalService,
    private masiveBilling: GloballBillingService,
    private HistoryConsumptionCutDate: GloballBillingService,
    private datePipe: DatePipe,
    private comunication: ComunicationService,
    public backupsHeaders: BackupsTableHeadersService
  ) {}

  ngOnInit() {
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.createCutBillingForm();
    this.consecutives();
    //this.timeDraft();
  }

  createCutBillingForm(): void {
    this.dateMasiveBilling = new Date();
    this.cutBillingForm = this.formBuilder.group({
      billingCartagena: [{ value: 0, disabled: true }, Validators.required],
      billingDate: [this.dateMasiveBilling, Validators.required],
      consumptionCutDate: ['', Validators.required],
      billingBarranquilla: [{ value: 0, disabled: true }, Validators.required],
      billingMedellin: [{ value: 0, disabled: true }, Validators.required],
      billingBogota: [{ value: 0, disabled: true }, Validators.required],
      billingPereira: [{ value: 0, disabled: true }, Validators.required],
      limitPayDate: ['', Validators.required],
      billingBucaramanga: [{ value: 0, disabled: true }, Validators.required],
      billingCali: [{ value: 0, disabled: true }, Validators.required],
      user: this.user,
    });
  }

  openModal() {
    this.modal.show('consumptionVariable', {});
  }

  sendMasiveBilling() {
    this.spinner.show();
    const dataMasiveBilling = {
      fecha_corte_consumo: this.formatDate(this.cutBillingForm.get('consumptionCutDate').value),
      fecha_facturacion: this.formatDate(this.cutBillingForm.get('billingDate').value),
      fecha_limite_pago: this.formatDate(this.cutBillingForm.get('limitPayDate').value),
      usuario: this.identity.user,
    };
    this.masiveBilling
      .postMasiveBilling(dataMasiveBilling)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.data = response;
          if (response.ok) {
            Swal.fire({
              title: 'Facturación exitosa',
              text: response.message,
              type: 'success',
              confirmButtonText: 'OK',
            });
            this.sendData();
          }
          this.backupsHeaders.setLoad(true);
        },
        (error: any) => {
          Swal.fire({
            title: 'Error',
            text: error.error.message,
            type: 'error',
            confirmButtonText: 'OK',
          });
        }
      );
    this.cutBillingForm.get('limitPayDate').reset();
    this.cutBillingForm.get('billingDate').reset();
  }

  getHistoryConsumptionCutDate() {
    this.spinner.show();
    this.HistoryConsumptionCutDate.getHistoryConsumptionCutDate()
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.dataHistory = response.data;
          console.log('El arreglo de salida', this.dataHistory);
          this.sendDataHistory();
        },
        (error: any) => {
          Swal.fire({
            title: 'No hay registros',
            text: error.error.message,
            type: 'info',
            confirmButtonText: 'OK',
          });
        }
      );
    this.openModal();
  }

  /*  formatDate(date: string): string {
    const dateFormat = this.datePipe.transform(date, 'yyyy-MM-dd');
    return dateFormat;
  } */

  formatDate(date: string): string {
    const selectedDate = new Date(date);
    const now = new Date();
    selectedDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    return this.datePipe.transform(selectedDate, 'yyyy-MM-dd HH:mm:ss');
  }

  sendData() {
    const data = this.data;
    this.comunication.sendData(data);
  }
  sendDataHistory() {
    const data = this.dataHistory;
    this.comunication.sendDataHistory(data);
  }
  sendDataNullRegister() {
    const nullRegister = this.nullRegister;
    this.comunication.sendDataNullRegister(nullRegister);
  }

  isSendButtonDisabled(): boolean {
    return this.cutBillingForm.invalid;
  }

  consecutives() {
    this.spinner.show();
    this.masiveBilling.getConsecutives().subscribe(
      (resp) => {
        this.consecutivesList = resp.data[0];
        this.cutBillingForm
          .get('billingCartagena')
          .setValue(this.getNameConsecutive('CARTAGENA'));
        this.cutBillingForm
          .get('billingBarranquilla')
          .setValue(this.getNameConsecutive('BARRANQUILLA'));
        this.cutBillingForm
          .get('billingMedellin')
          .setValue(this.getNameConsecutive('MEDELLIN'));
        this.cutBillingForm
          .get('billingBogota')
          .setValue(this.getNameConsecutive('BOGOTA'));
        this.cutBillingForm
          .get('billingPereira')
          .setValue(this.getNameConsecutive('PEREIRA'));
        this.cutBillingForm
          .get('billingBucaramanga')
          .setValue(this.getNameConsecutive('BUCARAMANGA'));
        this.cutBillingForm
          .get('billingCali')
          .setValue(this.getNameConsecutive('CALI'));

        this.spinner.hide();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
    this.cutBillingForm.get('limitPayDate').reset();
    this.cutBillingForm.get('billingDate').reset();
  }

  cuts() {
    this.selectedCuts = [];
  }

  getNameConsecutive(name) {
    const nameConsecutive = this.consecutivesList.find(
      (element) => element.sucursal === name
    );
    return nameConsecutive ? nameConsecutive.consecutivo_actual : 0;
  }

  timeDraft() {
    return setInterval(() => {
      this.consecutives();
    }, 5000);
  }
}
