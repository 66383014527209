import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { PermissionModel } from '@models/permission.model';
import { UserModel } from '@models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;
  public permissions: Array<PermissionModel>;
  public quotaStudy: boolean;
  public quotaReport: boolean;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getPermisions();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi} = apis;
      this.urlApi = urlApi;
       console.log('config ci cd', apiUrl);
    });
  }

  readToken() {
    if (localStorage.getItem('tokenASR')) {
      this.userToken = localStorage.getItem('tokenASR');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getPermisions() {
    localStorage.setItem('permissions', JSON.stringify(
    {
    "REPORTE_CUPOS": "SI",
    "MENU": [
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "1",
            "DESCRIPCION": "Transacciones"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "2",
            "DESCRIPCION": "Consultar cupo"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "3",
            "DESCRIPCION": "Transaccion indv factura"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "4",
            "DESCRIPCION": "Transaccion indv cheque"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "5",
            "DESCRIPCION": "Reservar cupo"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "6",
            "DESCRIPCION": "Transaccion masiva"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "7",
            "DESCRIPCION": "Listado reserva"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "8",
            "DESCRIPCION": "Estudio cliente"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "9",
            "DESCRIPCION": "Operaciones"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "10",
            "DESCRIPCION": "Operaciones individuales"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "11",
            "DESCRIPCION": "Operaciones masivas"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "12",
            "DESCRIPCION": "Aviso de no pago masivo"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "13",
            "DESCRIPCION": "Exoneracion masiva"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "14",
            "DESCRIPCION": "Reintegros"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "15",
            "DESCRIPCION": "Descargar reporte"
        },
        {
            "IDROL": "1",
            "ESTADO": "O",
            "ID_OPCION": "16",
            "DESCRIPCION": "Acuerdos de pago"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "17",
            "DESCRIPCION": "Reportes"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "18",
            "DESCRIPCION": "Reportes generales"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "19",
            "DESCRIPCION": "Descargar reporte cupos"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "20",
            "DESCRIPCION": "Reporte transaccion"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "21",
            "DESCRIPCION": "Descargar informe"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "22",
            "DESCRIPCION": "Reporte vendedores"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "23",
            "DESCRIPCION": "Descargar informe general"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "24",
            "DESCRIPCION": "Descargar informe detallado"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "25",
            "DESCRIPCION": "Administracion"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "26",
            "DESCRIPCION": "Mis pagos"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "27",
            "DESCRIPCION": "Factura por pagar"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "28",
            "DESCRIPCION": "Historial de facturas"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "29",
            "DESCRIPCION": "Usuarios"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "30",
            "DESCRIPCION": "Agregar usuarios"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "31",
            "DESCRIPCION": "Editar perfil"
        },
        {
            "IDROL": "1",
            "ESTADO": "A",
            "ID_OPCION": "32",
            "DESCRIPCION": "Documentos"
        },
        {
            "IDROL": "1",
            "ESTADO": "O",
            "ID_OPCION": "33",
            "DESCRIPCION": "Liquidador"
        },
        {
            "IDROL": "1",
            "ESTADO": "O",
            "ID_OPCION": "34",
            "DESCRIPCION": "Reclamaciones"
        }
    ],
    "ESTUDIO_CUPO": "SI"
}
    ));
    const permissions = localStorage.getItem('permissions');
    if (permissions) {
      const parsedPermissions = JSON.parse(permissions);
      this.permissions = parsedPermissions.MENU.map((permission) =>
        PermissionModel.fromResponse(permission)
      );
      this.quotaStudy =
        parsedPermissions.ESTUDIO_CUPO &&
        parsedPermissions.ESTUDIO_CUPO === 'SI';
      this.quotaReport =
        parsedPermissions.REPORTE_CUPOS &&
        parsedPermissions.REPORTE_CUPOS === 'SI';
    }
    // console.log(permissions);
  }

  /* getUsers(usersRequest: UsersRequest) {
    const { userInfo, username } = this.identity;
    usersRequest.documentNumber = userInfo.document;
    usersRequest.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: UsersRequest.toRequest(usersRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_usuarios_v3`, options).pipe(
      map((resp: any) => {
        let users: Array<UsersResponse> = [];
        users =
          resp['USUARIOS'] && resp['USUARIOS'].length > 0
            ? resp['USUARIOS'].map((user: UsersResponse) =>
                UsersResponse.fromResponse(user)
              )
            : [];
        return users;
      })
    );
  }

  adminUser(addUserRequest: AddUserRequest) {
    const { userInfo, username } = this.identity;
    addUserRequest.documentNumber = userInfo.document;
    addUserRequest.documentType = userInfo.documentType;
    addUserRequest.cellphone = addUserRequest.cellphone || 0;
    addUserRequest.newPass = addUserRequest.newPass || '';
    // if(!addUserRequest.newPass) {
    //   delete addUserRequest.newPass;
    // }
    addUserRequest.user = username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: AddUserRequest.toRequest(addUserRequest),
      headers,
    };

    return this.http
      .post(`${this.urlApi}/pr_administrar_usuario_v4`, {}, options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  } */

}
