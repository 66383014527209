import { Component, OnInit, OnDestroy, DoCheck } from "@angular/core";
import { ModalService } from "@services/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ContractService } from "@app/services/contract.service";
import { PhoneAsrTemp } from "@app/models/phoneAsrTemp.model";
import { Response } from "@app/models/responseUtil.model";
import { SelectModel } from "@app/models/select.model";
import { HelperService } from "@app/services/helper.service";
import { FormContractService } from "@app/pages/contracts/services/form-contract.service";

@Component({
  selector: "app-modal-add-phones",
  templateUrl: "./modal-add-phones.component.html",
  styleUrls: ["./modal-add-phones.component.scss"],
})
export class ModalAddPhonesComponent implements OnInit, DoCheck, OnDestroy {
  public phones: FormGroup;

  listPhoneType = [
    { key: "TEL", description: "Tel" },
    { key: "CEL", description: "Cel" },
    { key: "FAX", description: "Fax" },
  ];

  public listDepartment: SelectModel[] = [];
  public listCityPhone: SelectModel[] = [];
  public dataTablePhones: PhoneAsrTemp[] = [];
  public listIndicative: SelectModel[] = [];
  public selectContract: any;
  validModalChange = false;
  sequence = 0;

  itemPhone: PhoneAsrTemp;

  cityName: string | null = "";
  departamentName: string | null = "";

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    public helper: HelperService,
    private formService: FormContractService
  ) {}

  ngOnInit() {
    this.validModalChange = false;
    this.itemPhone = null;
    this.sequence = 0;
    this.createForm();
  }

  ngOnDestroy(): void {
    this.validModalChange = false;
    this.sequence = 0;
    this.modal.close();
  }

  ngDoCheck(): void {
    if (this.modal.modal.addPhonesModal && !this.validModalChange) {
      this.formService.init();
      this.selectContract = JSON.parse(localStorage.getItem("covi-utils"));
      if (this.selectContract) {
        this.listIndicative = this.selectContract.indicatives;
        this.listDepartment = this.selectContract.departaments;
      }
      if (localStorage.getItem("listContractPhones")) {
        this.dataTablePhones = JSON.parse(
          localStorage.getItem("listContractPhones")
        );
        this.sequence = this.dataTablePhones.length + 1;
      }
      if (localStorage.getItem("itemPhone")) {
        this.itemPhone = JSON.parse(localStorage.getItem("itemPhone"));
        localStorage.removeItem("itemPhone");
        this.getItemPhone();
      }
      this.validModalChange = true;
    }
  }

  createForm() {
    this.phones = this.fb.group({
      indicative: ["", Validators.required],
      contactNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      extension: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
      ],
      phoneType: ["", Validators.required],
      department: ["", Validators.required],
      cityPhone: ["", Validators.required],
    });
    this.itemPhone = null;
    this.f.cityPhone.disable();
    this.f.department.valueChanges.subscribe((value) => {
      let nameDepartment = this.listDepartment.find(
        (element) => element.value == value
      );
      this.departamentName = nameDepartment ? nameDepartment.description : "";
    });

    this.f.cityPhone.valueChanges.subscribe((value) => {
      let cityName = this.listCityPhone.find(
        (element) => element.value == value
      );
      this.cityName = cityName ? cityName.description : "";
    });

    if (this.formService.status) {
      this.f.department.disable();
      this.f.cityPhone.disable();
    }
  }

  get f() {
    return this.phones.controls;
  }

  selectType(idx: any) {
    if (idx === "CEL") {
      this.f.indicative.disable();
      this.f.extension.disable();
      this.f.indicative.setValue("");
      this.f.extension.setValue("");
      this.f.contactNumber.setValue("");
      this.f.contactNumber.setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]);
    } else {
      this.f.indicative.enable();
      this.f.extension.enable();
      this.f.contactNumber.setValue("");
      this.f.contactNumber.setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ]);
    }
  }

  selectDepartmentPhone(idx: any) {
    this.f.cityPhone.disable();
    this.f.cityPhone.setValue("");
    this.getCityByDepartment(idx);
  }

  closeModal() {
    this.createForm();
    this.validModalChange = false;
    this.modal.close();
  }
  mapperService(): PhoneAsrTemp {
    let phoneAsrTemp: PhoneAsrTemp = new PhoneAsrTemp();
    if (this.itemPhone) {
      phoneAsrTemp.id = this.itemPhone.id;
      phoneAsrTemp.queryCodeId = this.itemPhone.queryCodeId;
    }

    phoneAsrTemp.documentType = this.formService.typeDocument;
    phoneAsrTemp.document = this.formService.document;

    phoneAsrTemp.indicative = this.f.indicative.value;
    phoneAsrTemp.contactNumber = this.f.contactNumber.value;
    phoneAsrTemp.phoneType = this.f.phoneType.value;
    phoneAsrTemp.extension = this.f.extension.value;
    phoneAsrTemp.department = this.f.department.value;
    phoneAsrTemp.city = this.f.cityPhone.value;
    phoneAsrTemp.sequence = this.sequence;
    phoneAsrTemp.idContract = Number(localStorage.getItem("numberContract"));
    phoneAsrTemp.cityName = this.cityName;
    phoneAsrTemp.departmentName = this.departamentName;
    return phoneAsrTemp;
  }

  createContractPhone(phoneAsrTemp: PhoneAsrTemp) {
    this.spinner.show();
    this.contractService.postContractPhoneAsrTemp(phoneAsrTemp).subscribe(
      (resp: Response<PhoneAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.createForm();
          this.validModalChange = false;
          this.modal.close();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getCityByDepartment(codDepartment: number) {
    this.spinner.show();
    this.contractService.getSelectCity(codDepartment.toString()).subscribe(
      (resp: Response<any>) => {
        if (resp.ok && resp.data) {
          this.listCityPhone = resp.data[0]!.cities;
          this.f.cityPhone.enable();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.listCityPhone = [];
        this.spinner.hide();
      }
    );
  }

  getItemPhone() {
    this.f.phoneType.setValue(this.itemPhone.phoneType);
    this.selectType(this.itemPhone.phoneType);
    this.f.indicative.setValue(this.itemPhone.indicative);
    this.f.contactNumber.setValue(this.itemPhone.contactNumber);
    this.f.extension.setValue(this.itemPhone.extension);
    this.f.department.setValue(this.itemPhone.department);
    this.getCityByDepartment(this.itemPhone.department);
    this.f.cityPhone.setValue(this.itemPhone.city);
  }

  onSubmit() {
    this.createContractPhone(this.mapperService());
  }
}
