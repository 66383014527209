import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegalNatural } from '@app/models/legalNatural.model';

@Component({
  selector: "app-affiliate-card",
  templateUrl: "./affiliate-card.component.html",
  styleUrls: ["./affiliate-card.component.scss"],
})
export class AffiliateCardComponent implements OnInit {
  @Input() dataCards: LegalNatural[];
  @Output() edit: EventEmitter<LegalNatural> = new EventEmitter<LegalNatural>();

  constructor() {}

  ngOnInit() {}

  editAffiliate(selectedProduct: LegalNatural): void {
    this.edit.emit(selectedProduct);
  }
}
