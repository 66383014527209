import { Component, OnInit } from '@angular/core';
import { IBasicInformation, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';
import { ExpirianListsService } from '../../services/expirian-lists.service';
import { DocumentValidity } from '../../models/expirian-lists.model';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-basic-information-history',
  templateUrl: './basic-information-history.component.html',
  styleUrls: ['./basic-information-history.component.scss']
})
export class BasicInformationHistoryComponent implements OnInit {

  public personInformation: IBasicInformation = {} as IBasicInformation;

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService,
    private expirianList: ExpirianListsService,
    private helperCreditInfo: HelperCreditInfoService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.personInformation = this.subjectCreditHistory.getBasicInformation(response);
  }

  getLabelDocumentValidity(code: string) {
    const result: DocumentValidity | undefined = this.expirianList.findDocumentValidityByCode(code);
    return result ? result.descripcion : code;
  }

  getLabelGender(genderCode: string): string {
    return this.helperCreditInfo.findGenderByCode(genderCode);
  }

}
