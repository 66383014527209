import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mark-non-payment',
  templateUrl: './mark-non-payment.component.html',
  styleUrls: ['./mark-non-payment.component.scss']
})
export class MarkNonPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
