import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { RolesService } from '../services/roles.service';
import { tap } from 'rxjs/operators';

@Directive({
  selector: 'input[dis], button[dis]'
})
export class DisabledByRolDirective {

  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private rolService: RolesService
  ) {}
  ngOnInit() {
   // this.setupObs();
  }
 /*   setupObs() {
   return;
    this.rolService.getRolDetails()
      .pipe(
        tap((hasPermission) => {
          this.render.setProperty(
            this.el.nativeElement,
            'disabled',
            hasPermission
          );
        })
      )
      .subscribe();
  } */

}
