import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { SubjectService } from '@app/services/subject.service';
import { CONSTANTS } from '@config/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements OnInit {
  @Input() clientStudyForm: any = {};
  @Input() onResetForm: boolean = false;

  @ViewChild('formAddress') formAddress: NgForm;
  @Output() formAddressValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddressText: EventEmitter<string> = new EventEmitter<string>();

  public address: any = {
    addressType: "",
    addressFirst: "",
    addressSecond: "",
    addressThird: "",
    addressOther: "",
  };
  public stateAddressSubscription: Subscription;
  public regex = "^(?=\w+\d)([A-Za-z]*)\w{​​​​​1,}​​​​​$"
  public titleAddress: string;
  public constants = CONSTANTS;  
  
  public addressFields = [
    'Calle',
    'Carrera',
    'Avenida',
    'Avenida Carrera',
    'Avenida Calle',
    'Circular',
    'Circunvalar',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Vía',
    'Otra',
  ];

  constructor(public helper: HelperService, public subject: SubjectService) {
  }

  ngOnInit() {
    this.subject.handleIsResetAddress$.subscribe((response) => {
      this.formAddress.resetForm();
    })
  }

  setAddress() {
    this.clientStudyForm.direccion = `${this.address.addressType || ''} ${this.address.addressFirst || ''} ${this.address.addressSecond || ''} ${this.address.addressThird || ''} ${this.address.addressOther || ''}`;
    console.log('DIRECCION', this.clientStudyForm.direccion);
    
    this.onAddressText.emit(this.clientStudyForm.direccion)
  }

  handleValidate() {
    const valid = this.formAddress.form.valid;
    this.formAddressValid.emit(valid);
  }
}
