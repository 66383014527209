import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectModel } from '@app/models/select.model';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { NonPaymentNotice } from './models/response/non-payment-notice';
import { NonPaymentNoticeService } from './service/non-paymnet-notice.service';

@Component({
  selector: 'app-affiliate-notify-email',
  templateUrl: './affiliate-notify-email.component.html',
  styleUrls: ['./affiliate-notify-email.component.scss']
})
export class AffiliateNotifyEmailComponent implements OnInit {

  @ViewChild('emailForm') emailForm: NgForm;

  public navItemTabs: any[] = [];
  totalPages : number = 1;
  pageRequest : number = 1;
  isInvalidSearchForm: boolean = false;
  person: any;
  documentType: string = '';
  document: number | null = null;
  isSearch: boolean = false;
  email: string = ''; 
  data: Array<any> = [];
  isQuerySuccess: boolean = false;

  constructor(private tabs: TabsService, public helper: HelperService, private service: NonPaymentNoticeService,
    public tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService) {
    this.navItemTabs = this.tabs.adminQuotaTabs;
  }

  ngOnInit() {}

  onGetIsValidSearchForm($event) {
    this.isQuerySuccess = false;
    this.isInvalidSearchForm = $event;
  }

  search(person: any) {
    this.person = person;
    this.spinner.show();
    const params: any = {
      affiliateDocumentType: person.documentType,
      affiliateDocument: person.document,
      currentPage: 1,
      perPage: 10
    }
    
    this.documentType = person.documentType;
    this.document = person.document;
    this.service.searchNotifyEmail(params).subscribe(
      (res: any) => {
        this.isQuerySuccess = true;
        this.isSearch = true;
        this.spinner.hide();
        this.data = res.data;
        console.log(res)
      },
      (error) => {
        this.spinner.hide();
        this.helper.handleErrorHttp(error, 'Ocurrió un Error')
      } 
    );
  }

  goToPage(currentPage: any){
    //
  }

  addEmail() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      const body = {
        issuerDocumentTypeId: this.person.documentType,
        issuerDocumentId: this.person.document,
        userAct: user.user,
        email: this.email,
      }
      this.service.addNotificationMail(body).subscribe((response: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
  }

}
