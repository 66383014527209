import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-quota-radication",
  templateUrl: "./quota-radication.component.html",
  styleUrls: ["./quota-radication.component.scss"],
})
export class QuotaRadicationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
