import { Component, OnInit } from '@angular/core';
import { PreBill } from '@app/models/global-billing.models';
import { SelectModel } from '@app/models/select.model';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bags-active-affiliate',
  templateUrl: './bags-active-affiliate.component.html',
  styleUrls: ['./bags-active-affiliate.component.scss'],
})
export class BagsActiveAffiliateComponent implements OnInit {
  cantPages = 0;
  currentPage = 1;
  typeSearch = null;
  tableHeard = [
    'Corte',
    'Número de bolsa',
    'Contrato',
    'Número de documento',
    'Afiliado',
    'Mes cuota',
    'Cuota',
    'Valor Cuota',
    'Factura Netsuit',
    'Fecha de facturación',
  ];

  public listOptions: SelectModel[] = [
    {
      value: 'NIT',
      description: 'Número de documento',
    },
    {
      value: 'AFILIADO',
      description: 'Nombre del afiliado',
    },
    {
      value: 'CONTRATO',
      description: 'Numero de contrato',
    },
  ];

  filter: boolean = false;
  value = null;

  typeSearchNumber = ['NIT', 'CONTRATO', 'id_bolsa', 'corte_facturacion'];

  globalBiling = [];

  private monthNames = [
    'ene-',
    'feb-',
    'mar-',
    'abr-',
    'may-',
    'jun-',
    'jul-',
    'ago-',
    'sep-',
    'oct-',
    'nov-',
    'dic-',
  ];

  getMonthAndYear(dateString: string): string {
    if (!dateString) return '--';
    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    if (isNaN(monthIndex)) return '--';
    const monthName = this.monthNames[monthIndex];
    const year = (date.getUTCFullYear()).toString().slice(-2);

    return `${monthName} ${year}`;
  }

  constructor(
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.backupsHeaders.loading$.subscribe(() => {
      this.getAll();
    });
    this.getAll();
  }

  getAll(isDownload: boolean = false) {
    this.spinner.show();
    const body = {
      filtro: this.typeSearch,
      valor: this.value ? this.value : null,
      pagina: this.currentPage,
      por_pagina: 12,
      reporte: 'ALL',
      descargar: isDownload,
    };
    this.globalBillingService.getBagsReport(body).subscribe(
      (resp) => {
        if (resp.ok) {
          this.globalBiling = resp.data;
          //this.globalBiling=this.globalBiling.sort((a, b) => a.mes_cuota.localeCompare(b.mes_cuota, 'es', { sensitivity: 'base' }));
          console.log('La respuesta es', resp.data);
          if (isDownload) {
            this.helper.downloadFromBase64(
              resp.data[0] as string,
              'pre-facturacion',
              '.xls'
            );
          }
        }
        this.spinner.hide();
        this.cantPages = resp.totalPages;
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }

  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.getAll();
  }

  selectTypeSearch($event) {
    this.currentPage = 1;
    this.filter = this.typeSearchNumber.includes($event);
    this.typeSearch = $event;
    this.value = null;
  }

  refresh() {
    this.typeSearch = null;
    this.value = null;
    this.currentPage = 1;
    this.getAll();
  }

  openView(prebil: PreBill) {
    this.modal.show('individualBilling', prebil);
  }
}
