import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { IVectorAnalysis } from '../../services/natural-person-adapter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vector-analysis-history',
  templateUrl: './vector-analysis-history.component.html',
  styleUrls: ['./vector-analysis-history.component.scss']
})
export class VectorAnalysisHistoryComponent implements OnInit, OnDestroy {

  public vectorAnalysis: IVectorAnalysis[] = [];
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.vectorAnalysis = response.vectorAnalysis;
  }
}
