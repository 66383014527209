import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractService } from '@app/services/contract.service';
import { QuearyCodeAsrTemp } from '@app/models/quearyCodeAsrTemp.model';
import { Response } from '@app/models/responseUtil.model';
import * as moment from 'moment';
import { SelectModel } from '@app/models/select.model';
import { Subject } from 'rxjs';
import { AppState } from '@app/redux/app.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AddressAsrTemp } from '@app/models/addressAsrTemp.model';
import { PhoneAsrTemp } from '@app/models/phoneAsrTemp.model';
import { FormContractService } from '@app/pages/contracts/services/form-contract.service';

@Component({
  selector: 'app-modal-add-queary-code',
  templateUrl: './modal-add-queary-code.component.html',
  styleUrls: ['./modal-add-queary-code.component.scss'],
})
export class ModalAddQuearyCodeComponent implements OnInit, OnDestroy, DoCheck {
  public quearyCodeForm: FormGroup;

  public selectOptionsBranchOffices: SelectModel[] = [];
  public dataTableAddress: AddressAsrTemp[] = [];
  public dataTablePhones: PhoneAsrTemp[] = [];
  numberContract = 0;
  sequenceQuearyCode: any = 0;

  validModalChange = false;

  isReal: boolean = false;

  listState = [
    { key: 'A', description: 'Activo' },
    { key: 'S', description: 'Suspendido' },
    { key: 'R', description: 'Retirado' },
  ];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  itemQuearyCode: QuearyCodeAsrTemp;

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private store: Store<AppState>,
    private formService: FormContractService
  ) {}

  ngOnDestroy(): void {
    this.validModalChange = false;
    this.modal.close();
  }

  ngOnInit() {
    this.createForm();
    this.itemQuearyCode = null;
    this.validModalChange = false;
  }

  ngDoCheck(): void {
    this.isReal = this.formService.status;
    if (this.modal.modal.addQueryCodeModal && !this.validModalChange) {
      this.formService.init();
      this.store
        .select('selects')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(({ selects }) => this.getSelects(selects));
      this.numberContract = Number(localStorage.getItem('numberContract'));
      this.f.affiliateName.setValue(localStorage.getItem('socialReason'));

      this.getContractAddress();
      this.getContractPhones();

      if (localStorage.getItem('idQuearyCode')) {
        let idQuearyCode: number = Number(localStorage.getItem('idQuearyCode'));
        if (!this.formService.status) {
          this.getQuearyCodeById(idQuearyCode);
        } else {
          this.getQuearyCodeRealById(idQuearyCode);
        }

        localStorage.removeItem('idQuearyCode');
      } else {
        this.getQuearyCodeSequence();
      }
      this.validModalChange = true;
    }
  }

  createForm() {
    this.quearyCodeForm = this.fb.group({
      affiliateName: [{ value: '', disabled: true }, Validators.required],
      establishment: ['', [Validators.required, Validators.maxLength(32)]],
      state: ['0', Validators.required],
      branchOffices: ['', Validators.required],
      register: [
        { value: moment(new Date()).format('DD/MM/YYYY'), disabled: true },
        Validators.required,
      ],
      address: [null, Validators.required],
      phone: [null, Validators.required],
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      secondName: ['', [Validators.maxLength(32)]],
      firstLastName: ['', [Validators.required, Validators.maxLength(32)]],
      secondLastName: ['', [Validators.maxLength(32)]],
      centro: [{ value: '', disabled: true }, Validators.required],
    });
    this.itemQuearyCode = null;

    this.quearyCodeForm.controls.branchOffices.valueChanges.subscribe(
      (value) => {
        this.quearyCodeForm.controls.centro.setValue(null);
        this.contractService.getSucursalPrincipal(value).subscribe((resp) => {
          this.quearyCodeForm.controls.centro.setValue(resp.sucursalPrincipal);
        });
      }
    );
  }

  get f() {
    return this.quearyCodeForm.controls;
  }

  closeModal() {
    this.createForm();
    this.validModalChange = false;
    this.modal.close();
  }

  getQuearyCodeById(idQuearyCode: number) {
    this.spinner.show();
    this.contractService.getQuearyCodeByIdAsrTemp(idQuearyCode).subscribe(
      (resp: Response<QuearyCodeAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.itemQuearyCode = resp.data[0];
          this.getItemQuearyCode();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getQuearyCodeRealById(idQuearyCode: number) {
    this.spinner.show();
    this.contractService.getQuearyCodeByIdReal(idQuearyCode).subscribe(
      (resp: Response<QuearyCodeAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.itemQuearyCode = resp.data[0];
          this.getItemQuearyCode();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  mapperService(): QuearyCodeAsrTemp {
    this.formService.init();
    let quearyCodeAsrTemp: QuearyCodeAsrTemp = new QuearyCodeAsrTemp();
    if (this.itemQuearyCode) {
      quearyCodeAsrTemp.id = this.itemQuearyCode.id;
    }
    quearyCodeAsrTemp.state = this.f.state.value;
    quearyCodeAsrTemp.branchOffices = this.f.branchOffices.value;
    quearyCodeAsrTemp.register = new Date();
    quearyCodeAsrTemp.establishment = this.f.establishment.value;
    quearyCodeAsrTemp.firstName = this.f.firstName.value;
    quearyCodeAsrTemp.secondName = this.f.secondName.value;
    quearyCodeAsrTemp.firstLastName = this.f.firstLastName.value;
    quearyCodeAsrTemp.secondLastName = this.f.secondLastName.value;
    quearyCodeAsrTemp.idContract = this.numberContract;
    quearyCodeAsrTemp.idAddress = this.f.address.value;
    quearyCodeAsrTemp.idPhone = this.f.phone.value;

    quearyCodeAsrTemp.document = this.formService.document;
    quearyCodeAsrTemp.typeDocument = this.formService.typeDocument;

    quearyCodeAsrTemp.user = this.formService.user;

    if (
      this.formService.status &&
      this.itemQuearyCode &&
      this.itemQuearyCode.sequence != null &&
      this.itemQuearyCode.sequence != undefined
    ) {
      quearyCodeAsrTemp.sequence = this.itemQuearyCode.sequence;
    }

    if (this.formService.status && !this.itemQuearyCode) {
      quearyCodeAsrTemp.sequence = Number(this.sequenceQuearyCode);
    }

    console.log(quearyCodeAsrTemp);

    return quearyCodeAsrTemp;
  }

  getQuearyCodeSequence() {
    this.contractService.getSequenceQuearyCode(this.numberContract).subscribe(
      (resp) => {
        this.sequenceQuearyCode = resp.data[0];
        this.f.address.setValue(this.sequenceQuearyCode);
        this.f.phone.setValue(this.sequenceQuearyCode);
      },
      (error) => {
        console.log(error);
        this.sequenceQuearyCode = 0;
      }
    );
  }

  createQuearyCodeAsrTemp(quearyCodeAsrTemp: QuearyCodeAsrTemp) {
    this.spinner.show();
    if (this.formService.status) {
      this.saveQueryCodeReal(quearyCodeAsrTemp);
    } else {
      this.saveQueryCodeAsrTemp(quearyCodeAsrTemp);
    }
  }

  saveQueryCodeAsrTemp(quearyCodeAsrTemp: QuearyCodeAsrTemp) {
    this.contractService.postQuearyCodeAsrTemp(quearyCodeAsrTemp).subscribe(
      (resp: Response<QuearyCodeAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.formService.setCurrentStep(3);
          this.createForm();
          this.validModalChange = false;
          this.modal.close();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  saveQueryCodeReal(quearyCodeAsrTemp: QuearyCodeAsrTemp) {
    this.contractService.postQuearyCodeReal(quearyCodeAsrTemp).subscribe(
      (resp: Response<QuearyCodeAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.createForm();
          this.validModalChange = false;
          this.modal.close();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getContractAddress() {
    this.spinner.show();
    this.contractService
      .getContractAddressByNumber(this.numberContract)
      .subscribe(
        (resp: Response<AddressAsrTemp[]>) => {
          if (resp.ok && resp.data) {
            this.dataTableAddress = resp.data[0];
            localStorage.setItem(
              'listContractAddress',
              JSON.stringify(this.dataTableAddress)
            );
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.dataTableAddress = [];
          this.spinner.hide();
        }
      );
  }

  getContractPhones() {
    this.spinner.show();
    this.contractService
      .getContractPhoneByNumber(this.numberContract)
      .subscribe(
        (resp: Response<PhoneAsrTemp[]>) => {
          if (resp.ok && resp.data) {
            this.dataTablePhones = resp.data[0];
            localStorage.setItem(
              'listContractPhones',
              JSON.stringify(this.dataTablePhones)
            );
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.dataTablePhones = [];
          this.spinner.hide();
        }
      );
  }

  getSelects(selects?: any) {
    this.selectOptionsBranchOffices = selects.branchOffices || [];
  }

  onSubmit() {
    this.createQuearyCodeAsrTemp(this.mapperService());
  }

  getItemQuearyCode() {
    this.contractService
      .getSucursalPrincipal(this.itemQuearyCode.branchOffices)
      .subscribe((resp) => {
        this.quearyCodeForm.controls.centro.setValue(resp.sucursalPrincipal);
      });
    this.f.establishment.setValue(this.itemQuearyCode.establishment);
    this.f.state.setValue(this.itemQuearyCode.state);
    this.f.branchOffices.setValue(this.itemQuearyCode.branchOffices);
    this.f.register.setValue(
      moment(this.itemQuearyCode.register).format('DD/MM/YYYY')
    );
    this.f.address.setValue(
      this.itemQuearyCode.idAddress
        ? this.itemQuearyCode.idAddress
        : this.itemQuearyCode.sequence
    );
    this.f.phone.setValue(
      this.itemQuearyCode.idPhone
        ? this.itemQuearyCode.idPhone
        : this.itemQuearyCode.sequence
    );
    this.f.firstName.setValue(this.itemQuearyCode.firstName);
    this.f.secondName.setValue(this.itemQuearyCode.secondName);
    this.f.firstLastName.setValue(this.itemQuearyCode.firstLastName);
    this.f.secondLastName.setValue(this.itemQuearyCode.secondLastName);
    if (this.isReal) {
      let data = this.dataTablePhones.filter(
        (element) => element.sequence == this.itemQuearyCode.sequence
      );
      if (data.length > 0) {
        let value = data[data.length - 1].sequence;
        this.f.phone.setValue(value);
      }
      this.f.phone.disable();
      this.f.address.disable();
    }
  }
}
