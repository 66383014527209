import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safePercent'
})
export class SafePercentPipe implements PipeTransform {

  private percentPipe = new PercentPipe('es-CO');
  
  transform(value: any, digitsInfo?: string, truncateDecimals: number = 1): any {
    if (typeof value === 'number' && !isNaN(value)) {

      const factor = Math.pow(10, truncateDecimals);
      value = Math.trunc(value * factor) / factor;

      return this.percentPipe.transform(value / 100, digitsInfo);
    }
    return value;
  }

}
