import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { RisksProcessesChargesService } from '@app/services/risks-processes-charges.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { RisksProcessesChargesTabsService } from '../risks-processes-charges-tabs.service';

@Component({
  selector: 'app-affiliate-risks',
  templateUrl: './affiliate-risks.component.html',
  styleUrls: ['./affiliate-risks.component.scss']
})
export class AffiliateRisksComponent implements OnInit {

  public navItemTabs: any[] = [];
  public searchNavItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  historicPerpage: number = 10;
  historicCurrentPage: number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  docTypes: any;
  public file: File;
  public stringFile: string;
  public isSearch: boolean = false;

  public isValidData: boolean = false;
  public isQuerySuccess: boolean = false;

  public bodyFormPromisoryNotes = {
    contract: '',
  }

  buyer: string = '';

  data: any = [];

  constructor(private tabs: TabsService, public helper: HelperService, private quotaGreater: QuotaGreaterService,
    private spinner: NgxSpinnerService, private modal: ModalService, private risksProcesses: RisksProcessesChargesService, private risksProcessesCharguesTabs: RisksProcessesChargesTabsService) { }

  ngOnInit() {
    this.navItemTabs = this.risksProcessesCharguesTabs.affiliatePoliciesCharguesTabs;
  }

  search(currentPage?: any) {
    this.spinner.show();
    const params: any = {
      contract: this.bodyFormPromisoryNotes.contract,
    }
    
    this.risksProcesses.affiliateInfo(params).subscribe(
      (res: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${res.message}`,
          text: ``,
          type: 'success',
        });
        this.isSearch = res.ok;
        this.totalPages = res.totalPages;
        this.notices = res.data;
        this.data = res.data[0];
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      } 
    );
  }

  downloadTemplate() {
    this.spinner.show();
    const params = {
      type: 'RIESGO_AFILIADOS',
    };
    this.risksProcesses.affiliateRiskSemester().subscribe((response: any) => {
      this.spinner.hide();
      this.helper.downloadFromBase64(response.data[0], 'reporte_6_meses', '.xlsx');
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  seleccionArchivo(archivo: File) {
    this.file = archivo;
    this.toBase64(this.file).then((response: any) => {
      this.stringFile = response.split(',')[1];
    })
  }

  toBase64(file: File) {

    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = error => reject(error);

    });
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes")
  }

  previewFile(currentPage) {
    this.spinner.show();
    const body = {
      option: "CARGUE_CUPOS",
      base64: this.stringFile
    }
    this.quotaGreater.previewFile(body).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.data = response.data[0];
      this.isQuerySuccess = true
      this.isSearch = false;
      this.totalPages = response.totalPages;
      this.currentPage = response.currentPage;
    },(error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  saveAll() {
    this.spinner.show();
    this.quotaGreater.savePromisoryNotes(this.data).subscribe((response) => {
      this.spinner.hide();
      this.data = [];
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  goToPage($event: any){
    this.previewFile($event.currentPage)
  }

}
