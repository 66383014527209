import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from "file-saver";
import Swal from 'sweetalert2';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-transaction-promisory-note',
  templateUrl: './transaction-promisory-note.component.html',
  styleUrls: ['./transaction-promisory-note.component.scss']
})
export class TransactionPromisoryNoteComponent implements OnInit {

  @ViewChild('formPromisoryNotes') formPromisoryNotes: NgForm;

  public navItemTabs: any[] = [];
  public searchNavItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  historicPerpage: number = 10;
  historicCurrentPage: number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  docTypes: any;
  public file: File;
  public stringFile: string;
  public isSearch: boolean = false;

  public isValidData: boolean = false;
  public isQuerySuccess: boolean = false;

  public bodyFormPromisoryNotes = {
    documentType: '',
    document: '',
  }

  buyer: string = '';

  data: any = [];

  constructor(private tabs: TabsService, public helper: HelperService, private quotaGreater: QuotaGreaterService,
     private spinner: NgxSpinnerService, private listCovinoc: ListsCovinocService, private modal: ModalService) {
    this.navItemTabs = this.tabs.quotaPromisoryNotes;
      this.searchNavItemTabs = this.tabs.quotaSearchPromisoryNotes
  }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
    });
  }

  logDocument() {
    console.log(this.bodyFormPromisoryNotes.document);
    
  }

  deletePromisoryNote(id: number) {
    this.spinner.show();
    console.log(this.data);

    const params = {
      id: id
    }

    this.quotaGreater.markAsDeleted(params).subscribe((response: any) => {
      this.spinner.hide();
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
      this.goToPage({currentPage: this.currentPage});
    },(error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  search(currentPage?: any) {
    
    this.spinner.show();

    const body: any = {
      documentType: this.bodyFormPromisoryNotes.documentType,
      document: this.bodyFormPromisoryNotes.document,
      currentPage: currentPage,
      perPage: this.perPage,
      type: this.buyer,
    }
    
    this.quotaGreater.findAllPromisoryNotes(body).subscribe(
      (res: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${res.message}`,
          text: ``,
          type: 'success',
        });
        this.isSearch = true;
        this.totalPages = res.totalPages;
        this.notices = res.data;
        this.data = res.data[0];
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      } 
    );
  }

  downloadTemplate() {
    this.spinner.show();
    this.quotaGreater.downloadTemplate().subscribe((response: any) => {
      this.spinner.hide();
      this.helper.downloadFromBase64(response.data[0], 'Transaccion_plantilla', '.xlsx');
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  seleccionArchivo(archivo: File) {
    this.file = archivo;
    this.toBase64(this.file).then((response: any) => {
      this.stringFile = response.split(',')[1];
    })
  }

  toBase64(file: File) {

    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = error => reject(error);

    });
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes")
  }

  previewFile() {
    this.spinner.show();
    ;
    const body = {
      option: "CARGUE_CUPOS",
      base64: this.stringFile
    }
    this.quotaGreater.previewFile(body).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.data = response.data[0];
      this.verifyData();
      this.isQuerySuccess = true
      this.isSearch = false;
      this.totalPages = response.totalPages;
    },(error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  verifyData() {
    this.data.forEach(item => {
      if(item.existBuyer === false || item.existCosigner === false) {
        this.isValidData = true;
      }
    });
    if(this.isValidData === true) {
      Swal.fire({
        title: `Tiene errores en su información`,
        text: ``,
        type: 'info',
      });
    }
  }

  onGetHistoricReport(currentPage) {
    this.spinner.show();
    const params =  {
      currentPage: currentPage,
      perPage: this.historicPerpage
    }
    this.quotaGreater.historicReport(params).subscribe((response: any) => {
      console.log(response);
      this.historicCurrentPage = response.currentPage;
      this.totalPages = response.totalPages
      this.spinner.hide();
      this.data = response.data[0];
      this.isSearch = false;
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  saveAll() {
    this.spinner.show();
    this.quotaGreater.savePromisoryNotes(this.data).subscribe((response) => {
      this.spinner.hide();
      this.data = [];
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  goToPage($event: any){
    this.onGetHistoricReport($event.currentPage)
  }

}
