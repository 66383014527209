import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-t-heading',
  templateUrl: './t-heading.component.html',
  styleUrls: ['./t-heading.component.scss']
})
export class THeadingComponent implements OnInit {
  @Input() tableHeadings: Array<string> = [];
  constructor() { }

  ngOnInit() {
  }

}
