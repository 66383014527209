import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  AffiliateLinkDataForm,
  DateLinkGlobalBilling,
} from 'src/app/models/afiliate-link';
import { LegalRepresentative } from 'src/app/models/membership.model';
import { PartnerModel } from 'src/app/models/partner.model';
import { SelectsDataForm } from 'src/app/models/selectModel';
import { AffiliateLinkService } from 'src/app/services/affiliate-link.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectModel } from '@app/models/select.model';
import { ListResponse } from '@app/models/listResponse.model';
import { Response } from '@app/models/responseUtil.model';
import { ContractResponse } from '@app/models/response/ContractResponse.model';
import { ContractService } from '@app/services/contract.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { InformationContract } from '../models/ResponseContract';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-billing-rules',
  templateUrl: './billing-rules.component.html',
  styleUrls: ['./billing-rules.component.scss'],
  providers: [DatePipe],
})
export class BillingRulesComponent implements OnInit, OnDestroy {
  @ViewChild('percentageInput') percentageInput: ElementRef;
  private ngUnsubscribe$ = new Subject<void>();
  public contract: any = {};
  public data: AffiliateLinkDataForm = new AffiliateLinkDataForm();
  public maximumDate: DateLinkGlobalBilling = new DateLinkGlobalBilling();
  public affiliateName: string = '';
  public minLengthAffiliate: number = 5;
  public arrayContracts: number[] = [];
  public globalBillingForm: FormGroup;
  public listContracts;
  public validContact = false;
  public responseData: any;
  public identity: any;
  public filterOptionContract: number;
  public amortization: string = '';
  public user: string = '';
  public totalIncome: number = 0;
  public branchBilling: string = '';
  public numberBag: string;
  public createBag: boolean = false;
  public messageError: string = '';
  public invoiceDate: Date;
  public periods: number[] = [];
  constructor(
    private contractService: ContractService,
    public helper: HelperService,
    public select: SelectsService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private globalBilling: GloballBillingService,
    private datePipe: DatePipe,
    private contractBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService
  ) {
    this.validContact = false;
    this.listContracts = [];
  }

  ngOnInit() {
    this.initializeSelects();
    this.createGlobalBillingForm();
    this.getContracts();
    this.getVerifyContract();
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
    this.globalBillingForm.get('bag').valueChanges.subscribe(() => {
      this.calcPercentage();
    });
    this.globalBillingForm.get('percentage').valueChanges.subscribe(() => {
      this.calcPercentage();
    });
    this.globalBillingForm.valueChanges.subscribe(() => {
      console.log(this.globalBillingForm.value);
      console.log(this.globalBillingForm.controls);
    });

    this.globalBillingForm.get('initialDate').valueChanges.subscribe(() => {
      this.updateFinalDate();
    });
    this.globalBillingForm.get('monthBagDuration').valueChanges.subscribe(() => {
      this.updateFinalDate();
    });
  }
  updateFinalDate() {
    const initialDate = this.globalBillingForm.get('initialDate').value;
    const monthBagDuration = this.globalBillingForm.get('monthBagDuration').value;
    if (initialDate && monthBagDuration) {
      const initial = new Date(initialDate);
      initial.setHours(0, 0, 0, 0);
      const finalDate = new Date(initial);
      const monthsToAdd = Number(monthBagDuration);
      finalDate.setMonth(initial.getMonth() + monthsToAdd);
      if (finalDate.getDate() !== initial.getDate()) {
        finalDate.setDate(0);
      }
      finalDate.setDate(finalDate.getDate()+1)
      const finalDateFormatted = finalDate.toISOString().split('T')[0];
      this.globalBillingForm
        .get('finalDate')
        .setValue(finalDateFormatted, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  calcPercentage(): void {
    const bag = Math.round(this.globalBillingForm.get('bag').value);
    const percentage = this.globalBillingForm.get('percentage').value;

    const totalIncome = Math.round((bag * 1000000 * percentage) / 100);
    this.globalBillingForm.get('totalIncome').setValue(totalIncome);
  }

  createGlobalBillingForm(): void {
    this.invoiceDate = new Date();
    this.globalBillingForm = this.formBuilder.group({
      typeDocument: this.formBuilder.control('', Validators.required),
      numDocument: this.formBuilder.control('', Validators.required),
      affiliateName: this.affiliateName,
      filterOptionContract: this.formBuilder.control('', Validators.required),
      branch: this.branchBilling,
      initialDate: this.formBuilder.control('', Validators.required),
      monthBagDuration: this.formBuilder.control('', Validators.required),
      finalDate: this.formBuilder.control('', Validators.required),
      invoiceDate: this.formBuilder.control(
        this.invoiceDate,
        Validators.required
      ),
      period: this.formBuilder.control('', Validators.required),
      periodicity: this.formBuilder.control('', Validators.required),
      totalIncome: this.formBuilder.control(0, Validators.required),
      aditionalValue: this.formBuilder.control(0, Validators.required),
      bag: this.formBuilder.control(0, Validators.required),
      minimumPurchaseUnit: this.formBuilder.control(0, Validators.required),
      mail: this.formBuilder.control('', [
        Validators.required,
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.maxLength(250),
        Validators.minLength(3),
      ]),
      percentage: this.formBuilder.control(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      billingCut: this.formBuilder.control('', Validators.required),
      adviser: this.formBuilder.control('', Validators.required),
      numberInstallmentAmortization: this.formBuilder.control(
        '',
        Validators.required
      ),
      numberInstallmentBilling: this.formBuilder.control(
        '',
        Validators.required
      ),
      purchaseOrder: this.formBuilder.control('', Validators.required),
      incomeDistribution: this.formBuilder.control('', Validators.required),
      novelty: this.formBuilder.control('', Validators.required),
      user: this.user,
    });
  }
  resetControlState(): void {
    const control = this.globalBillingForm.get('percentage');
    control.markAsUntouched();
    control.markAsPristine();
    control.updateValueAndValidity();
    this.percentageInput.nativeElement.setAttribute('class', '', 'ng-invalid');
  }

  resetGlobalBillingData(): void {
    this.globalBillingForm.get('typeDocument').reset();
    this.globalBillingForm.get('numDocument').reset();
    this.globalBillingForm.get('affiliateName').reset();
    //this.globalBillingForm.get("filterOptionContract").reset();
    this.globalBillingForm.get('branch').reset();
    this.globalBillingForm.get('initialDate').reset();
    this.globalBillingForm.get('monthBagDuration').reset();
    this.globalBillingForm.get('finalDate').reset();
    this.globalBillingForm.get('invoiceDate').reset();
    this.globalBillingForm.get('period').reset();
    this.globalBillingForm.get('periodicity').reset();
    this.globalBillingForm.get('totalIncome').reset();
    this.globalBillingForm.get('aditionalValue').reset();
    this.globalBillingForm.get('bag').reset();
    this.globalBillingForm.get('minimumPurchaseUnit');
    this.globalBillingForm.get('mail').reset();
    this.globalBillingForm.get('percentage').reset();
    this.globalBillingForm.get('billingCut').reset();
    this.globalBillingForm.get('numberInstallmentAmortization').reset();
    this.globalBillingForm.get('numberInstallmentBilling').reset();
    this.globalBillingForm.get('purchaseOrder').reset();
    this.globalBillingForm.get('incomeDistribution').reset();
    this.globalBillingForm.get('novelty').reset();
    this.globalBillingForm.get('adviser').reset();
    this.globalBillingForm.get('user').reset();
    this.arrayContracts = [];
    this.affiliateName = '';
    this.branchBilling = '';
    this.createBag = false;
  }

  sendGlobalBilling() {
    const document = this.globalBillingForm
      .get('numDocument')
      .value.toString()
      .replace(/ /g, '');
    const dataGLobalBilling = {
      nit: document,
      afiliado: this.affiliateName,
      contrato: this.globalBillingForm.get('filterOptionContract').value,
      sucursal: this.branchBilling,
      p_fecha_inicial: this.formatDate(
        this.globalBillingForm.get('initialDate').value
      ),
      p_fecha_final: this.formatDate(
        this.globalBillingForm.get('finalDate').value
      ),
      anual_o_mensual: this.globalBillingForm.get('period').value,
      periodicidad_cuotas: this.globalBillingForm.get('periodicity').value,
      valor_ingreso_total: this.globalBillingForm.get('totalIncome').value,
      valor_adicional_bolsa:
        this.globalBillingForm.get('aditionalValue').value * 1000000,
      asesor: this.globalBillingForm.get('adviser').value,
      valor_bolsa: this.globalBillingForm.get('bag').value * 1000000,
      valor_cuota: this.globalBillingForm.get('bag').value,
      email_envio_factura: this.globalBillingForm.get('mail').value,
      orden_de_compra: this.globalBillingForm.get('purchaseOrder').value,
      distribucion_del_ingreso:
        this.globalBillingForm.get('incomeDistribution').value,
      otra_novedad: this.globalBillingForm.get('novelty').value,
      fecha_factura: this.formatDate(
        this.globalBillingForm.get('invoiceDate').value
      ),
      usuario_act: this.identity.user,
      p_comision: this.globalBillingForm.get('percentage').value,
      corte_facturacion: parseInt(
        this.globalBillingForm.get('billingCut').value
      ),
      no_cuotas_amortizar: parseInt(
        this.globalBillingForm.get('numberInstallmentAmortization').value
      ),
      no_cuotas_facturar: parseInt(
        this.globalBillingForm.get('numberInstallmentBilling').value
      ),
      meses_duracion_bolsa: this.globalBillingForm.get('monthBagDuration').value,
      monto_min_bolsa:  this.globalBillingForm.get('minimumPurchaseUnit').value*1000000
    };
    console.log('Datos del formulario:', this.globalBillingForm.value);
    console.log('Contrato:', dataGLobalBilling.contrato);
    this.globalBilling
      .postGlobalBilling(dataGLobalBilling)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          /*   this.globalBillingForm.reset(); */
          Swal.fire({
            title: 'Facturación global',
            text: 'Facturación enviada con éxito',
            type: 'success',
            confirmButtonText: 'OK',
          }).then((result: any) => {
            this.globalBillingForm.get('numDocument').reset();
            this.globalBillingForm.get('typeDocument').setValue('');
            this.globalBillingForm.get('filterOptionContract').setValue(null);
            this.affiliateName = '';
            this.createBag = false;
          });
          this.backupsHeaders.setLoad(true);
        },
        (err: any) => {
          Swal.fire({
            title: 'Ocurrió un error intentelo mas tarde!',
            type: 'error',
            confirmButtonText: 'OK',
          });
        }
      );
    this.resetGlobalBillingData();
  }

  getVerifyContract(): void {}

  private handleOtherStatus(response: any): void {
    Swal.fire({
      title: 'Error en el contrato',
      text: response.message,
      type: 'error',
      confirmButtonText: 'OK',
    });
    this.globalBillingForm.get('numDocument').reset();
    this.globalBillingForm.get('typeDocument').setValue('');
    this.globalBillingForm.get('filterOptionContract').setValue(null);
    this.affiliateName = '';
    this.createBag = false;
  }

  initializeSelects(): void {
    this.data.applicative = 'ASR';
  }

  formatDate(date: string): string {
    const dateFormat = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    return dateFormat;
  }

  onSelectChange() {
    const contratoSeleccionado = this.globalBillingForm.get(
      'filterOptionContract'
    ).value;
    console.log('Contrato seleccionado:', contratoSeleccionado);
    this.contractBillingService
      .verifyContractBilling(contratoSeleccionado)
      .subscribe(
        (response: any) => {
          if (response.ok) {
            this.branchBilling = response.data[0].sucursal;
            this.numberBag = response.data[0].bolsas_actuales;
            if (this.numberBag !== null) {
              Swal.fire({
                title: 'Crear bolsa',
                showCancelButton: true,
                text: this.numberBag,
                type: 'success',
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
              }).then((result) => {
                if (result.value) {
                  this.createBag = true;
                } else {
                  this.createBag = false;
                }
              });
            } else {
              this.createBag = true;
            }

            /*  if (this.numberBag == 0) {
              this.createBag = true;
            } else if (this.numberBag == 1) {
              Swal.fire({
                title: "¿Desea crear otra bolsa?",
                showCancelButton: true,
                text: "El Contrato tiene una bolsa ",
                type: "question",
                confirmButtonText: "Si",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.value) {
                  this.createBag = true;
                } else {
                  this.createBag = false;
                }
              });
            } */
          } else {
            this.handleOtherStatus(response);
          }
        },
        (err: any) => {
          this.handleOtherStatus(err.error);
        }
      );
  }

  getContracts() {
    this.listContracts = [];
    const typeDoc = this.globalBillingForm.get('typeDocument').value;
    const numDoc = this.globalBillingForm.get('numDocument').value;
    if (
      typeDoc &&
      numDoc &&
      numDoc.toString().length > this.minLengthAffiliate
    ) {
      const paramsIndex = {
        typeDoc,
        numDoc,
      };
      this.contractService.setParamsIndex(paramsIndex);
      this.contractBillingService.getContract(typeDoc, numDoc).subscribe(
        (resp: Response<InformationContract>) => {
          this.globalBillingForm.controls.filterOptionContract.setValue('');
          if (resp.data && resp.data.length > 0 && resp.data[0]) {
            this.affiliateName = resp.data[0].razon_social;
            this.arrayContracts = resp.data[0].contratos;
            this.messageError = null;
          } else {
            this.messageError = 'No existen datos asociados a este documento';
            this.affiliateName = '';
            this.arrayContracts = [];
            this.createBag = false;
          }
        },
        (error: any) => {
          this.messageError = error.error.message;
          this.globalBillingForm.get('numDocument').reset();
          this.globalBillingForm.get('typeDocument').setValue('');
          this.globalBillingForm.get('filterOptionContract').setValue(null);
          this.affiliateName = '';
          this.arrayContracts = [];
          this.createBag = false;
          this.validContact = true;
          this.listContracts = [];
          this.spinner.hide();
        }
      );
    }
  }

  calculatePeriods() {
    const initialDate = new Date(
      this.globalBillingForm.get('initialDate').value
    );
    const finalDate = new Date(this.globalBillingForm.get('finalDate').value);
    const period = this.globalBillingForm.get('periodicity').value;
    const monthsDifference = this.monthDiff(initialDate, finalDate);
    let periods = [];

    switch (period) {
      case 'MENSUAL':
        periods = this.generateIntervals(monthsDifference, 1); // Mensual
        break;
      case 'BIMENSUAL':
        periods = this.generateIntervals(monthsDifference, 2); // Bimensual
        break;
      case 'TRIMESTRAL':
        periods = this.generateIntervals(monthsDifference, 3); // Trimestral
        break;
      case 'SEMESTRAL':
        periods = this.generateIntervals(monthsDifference, 6); // Trimestral
        break;
      case 'ANUAL':
        periods = this.generateIntervals(Math.floor(monthsDifference / 12), 1); // Anual
        break;
    }

    return periods;
  }

  monthDiff(d1: Date, d2: Date): number {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  generateIntervals(totalMonths: number, divisor: number): number[] {
    const numOfIntervals = Math.floor(totalMonths / divisor);
    let intervals = [];
    for (let i = 1; i <= numOfIntervals; i++) {
      intervals.push(i);
    }
    return intervals;
  }

  onCalculate() {
    this.periods = this.calculatePeriods();
  }
}
