import { Component, OnInit, OnDestroy, DoCheck } from "@angular/core";
import { ModalService } from "@services/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ContractService } from "@app/services/contract.service";
import { AddressAsrTemp } from "@app/models/addressAsrTemp.model";
import { Response } from "@app/models/responseUtil.model";
import { SelectModel } from "@app/models/select.model";
import { FormContractService } from "@app/pages/contracts/services/form-contract.service";

@Component({
  selector: "app-modal-add-address",
  templateUrl: "./modal-add-address.component.html",
  styleUrls: ["./modal-add-address.component.scss"],
})
export class ModalAddAddressComponent implements OnInit, DoCheck, OnDestroy {
  public addresses: FormGroup;

  listAddressType = [
    { key: "OFICINA", description: "Oficina" },
    { key: "RESIDENCIA", description: "Residencia" },
    { key: "REFERENCIA", description: "DIRECCION DE REFERENCIA FAM" },
  ];

  public listDepartment: SelectModel[] = [];
  public listCity: SelectModel[] = [];
  public dataTableAddress: AddressAsrTemp[] = [];
  public selectContract: any;
  validModalChange = false;
  sequence = 0;

  itemAddress: AddressAsrTemp;

  cityName: string | null = "";
  departamentName: string | null = "";

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private formService: FormContractService
  ) {}

  ngOnDestroy(): void {
    this.validModalChange = false;
    this.modal.close();
  }

  ngOnInit() {
    this.validModalChange = false;
    this.itemAddress = null;
    this.sequence = 0;
    this.createForm();
  }

  ngDoCheck(): void {
    if (this.modal.modal.addAddressModal && !this.validModalChange) {
      this.formService.init();
      this.selectContract = JSON.parse(localStorage.getItem("covi-utils"));
      if (this.selectContract) {
        this.listDepartment = this.selectContract.departaments;
      }
      if (localStorage.getItem("listContractAddress")) {
        this.dataTableAddress = JSON.parse(
          localStorage.getItem("listContractAddress")
        );
        this.sequence = this.dataTableAddress.length + 1;
      }
      if (localStorage.getItem("itemAddress")) {
        this.itemAddress = JSON.parse(localStorage.getItem("itemAddress"));
        localStorage.removeItem("itemAddress");
        this.getItemPhone();
      }
      this.validModalChange = true;
    }
  }

  createForm() {
    this.addresses = this.fb.group({
      address: ["", Validators.required],
      department: ["", Validators.required],
      city: ["", Validators.required],
      addressType: ["OFICINA", Validators.required],
    });
    this.itemAddress = null;
    this.f.city.disable();

    this.f.department.valueChanges.subscribe((value) => {
      let nameDepartment = this.listDepartment.find(
        (element) => element.value == value
      );
      this.departamentName = nameDepartment ? nameDepartment.description : "";
    });

    this.f.city.valueChanges.subscribe((value) => {
      let cityName = this.listCity.find((element) => element.value == value);
      this.cityName = cityName ? cityName.description : "";
    });
  }

  get f() {
    return this.addresses.controls;
  }

  closeModal() {
    this.createForm();
    this.validModalChange = false;
    this.modal.close();
  }

  selectDepartment(idx: any) {
    this.f.city.disable();
    this.f.city.setValue("");
    this.getCityByDepartment(idx);
  }

  mapperService(): AddressAsrTemp {
    let addressAsrTemp: AddressAsrTemp = new AddressAsrTemp();
    if (this.itemAddress) {
      addressAsrTemp.id = this.itemAddress.id;
      addressAsrTemp.queryCodeId = this.itemAddress.queryCodeId;
    }
    addressAsrTemp.address = this.f.address.value;
    addressAsrTemp.addressType = this.f.addressType.value;
    addressAsrTemp.department = this.f.department.value;
    addressAsrTemp.city = this.f.city.value;
    addressAsrTemp.idContract = Number(localStorage.getItem("numberContract"));
    addressAsrTemp.cityName = this.cityName;
    addressAsrTemp.departmentName = this.departamentName;

    if (
      !this.formService.status &&
      this.itemAddress &&
      this.itemAddress.sequence
    ) {
      addressAsrTemp.sequence = this.sequence;
    }

    if (this.formService.status && this.itemAddress) {
      addressAsrTemp.sequence = this.itemAddress.sequence;
    }

    addressAsrTemp.documentType = this.formService.typeDocument;
    addressAsrTemp.document = this.formService.document;

    console.log(addressAsrTemp);

    return addressAsrTemp;
  }

  createContractAddress(addressAsrTemp: AddressAsrTemp) {
    this.spinner.show();
    this.contractService.postContractAddressAsrTemp(addressAsrTemp).subscribe(
      (resp: Response<AddressAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.createForm();
          this.validModalChange = false;
          this.modal.close();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getCityByDepartment(codDepartment: string) {
    this.spinner.show();
    this.contractService.getSelectCity(codDepartment).subscribe(
      (resp: Response<any>) => {
        if (resp.ok && resp.data) {
          this.listCity = resp.data[0]!.cities;
          this.f.city.enable();
          if (this.formService.status && this.itemAddress) {
            let city = this.listCity.find(
              (element) => element.description == this.itemAddress.cityName
            );

            console.log(city);

            this.itemAddress.city = city ? city.value : "0";

            this.f.city.setValue(this.itemAddress.city);
          }
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.listCity = [];
        this.spinner.hide();
      }
    );
  }

  getItemPhone() {
    this.f.address.setValue(this.itemAddress.address);
    this.f.department.setValue(this.itemAddress.department);
    this.f.addressType.setValue(this.itemAddress.addressType);
    if (this.itemAddress.department) {
      this.getCityByDepartment(this.itemAddress.department);
      this.f.city.setValue(this.itemAddress.city);
    }

    if (this.formService.status) {
      let department = this.listDepartment.find(
        (element) => element.description == this.itemAddress.departmentName
      );
      this.itemAddress.department = department ? department.value : "0";

      this.f.department.setValue(this.itemAddress.department);

      this.getCityByDepartment(this.itemAddress.department);
    }
  }

  onSubmit() {
    this.createContractAddress(this.mapperService());
  }
}
