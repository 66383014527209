import { Component, Input, OnInit } from '@angular/core';
import { CompanyInformationFromCompleteBasicInformation } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-company-basic-information',
  templateUrl: './legal-company-basic-information.component.html',
  styleUrls: ['./legal-company-basic-information.component.scss']
})
export class LegalCompanyBasicInformationComponent implements OnInit {

  @Input() companyInformation: CompanyInformationFromCompleteBasicInformation = {} as CompanyInformationFromCompleteBasicInformation;

  constructor() { }

  ngOnInit() {
  }

}
