import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-title-modify-with-backing',
  templateUrl: './title-modify-with-backing.component.html',
  styleUrls: ['./title-modify-with-backing.component.scss']
})
export class TitleModifyWithBackingComponent implements OnInit, OnChanges {

  public navItemTabs: any[] = [];

  public titleSelected: any;

  public titleValueList: Array<any> = [];
  
  public selectedValueTitle: string;

  totalPages : number = 0;
  pageRequest : number = 1;

  @Input() titlesWithBacking: any;

  public indexTitleSelected: any;

  @Input() personalInformation: any;

  constructor(private tabs: TabsService, public helper: HelperService, public tableHeaders: TransactionsHeadersService, private quotaGrater: QuotaGreaterService, private spinner: NgxSpinnerService) { 
    this.navItemTabs = this.tabs.adminQuotaTabs;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.titlesWithBacking);
    this.initLists()
    if(this.titlesWithBacking) {
      this.totalPages = this.titlesWithBacking.totalPages;

    }
  }

  selectTitleValue(titleValue) {
    this.selectedValueTitle = titleValue;
  }

  initLists() {
    if(this.titlesWithBacking && this.titlesWithBacking.length > 0) {
      this.titlesWithBacking.forEach((item) => {
        this.titleValueList.push(item.value);
      });
    }
  }

  selectItem(title) {
    this.titleSelected = title;
    console.log(this.titleSelected);
    
  }

  getTitleSelected(title, index) {
    console.log(title);
    this.indexTitleSelected = index;
    this.titleSelected = title;
  }

  goToPage($event){
    this.spinner.show();
    const body = {
      documentType: this.personalInformation.documentType,
      document: this.personalInformation.document,
      checksNumber: this.titleSelected.checksNumber,
      date: this.titleSelected.date,
      queryCode: this.titleSelected.queryCode,
      value: this.titleSelected.value,
    }
    console.log();
    this.quotaGrater.updateTitleValue(body).subscribe((response: any) => {
      this.spinner.hide();
      this.totalPages = response.totalPages;
      this.titlesWithBacking = response;
      console.log(response);
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      })
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  reverse() {
    this.spinner.show();
    const body = {
      documentType: this.personalInformation.documentType,
      document: this.personalInformation.document,
      checksNumber: this.titleSelected.checksNumber,
      date: this.titleSelected.date,
      queryCode: this.titleSelected.queryCode,
      value: this.titleSelected.value,
    }
    console.log();
    this.quotaGrater.updateTitleValue(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      })
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
    
  }

}
