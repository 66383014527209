import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HelperService } from "@app/services/helper.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ExecutionProject } from "../models/executionProject.model";

@Component({
  selector: "app-projects-ejecution",
  templateUrl: "./projects-ejecution.component.html",
  styleUrls: ["./projects-ejecution.component.scss"],
})
export class ProjectsEjecutionComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;

  private editField: string;
  private propertySaved: string;
  public personList: Array<any> = [];
  private idFieldPerson: number = 0;
  private idList: number;
  financialQuery = false;

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    public helper: HelperService
  ) {}

  ngOnInit() {
    const projects = this.infoStudyQuote.get('projects').value;
    let projectsFromDraft = false;
    if (projects && projects.length > 0) {
      projects.forEach((item) => {
        if (
          item.name  ||
          item.executionPercentage ||
          item.workValue ||
          item.annualAdvancePercentage ||
          item.completionDate
        ) {
          projectsFromDraft = true;
        }
      });
    }
    if (!projectsFromDraft) {
      this.remove(0);
    }
  }

  onChangesFinancialSources() {
    this.infoStudyQuote
      .get("projects")
      .valueChanges.subscribe((projectsExecList) => {
        let updatedPersonsList = [];
        projectsExecList.forEach(
          (projectsExec: ExecutionProject, index: number) => {
            projectsExec.completionDate = new Date();
            let dateCompletion = moment(projectsExec.completionDate).format(
              "DD/MM/YYYY"
            );
            let person = {
              id: index + 1,
              name: projectsExec.name,
              executionPercentage: projectsExec.executionPercentage,
              workValue: projectsExec.workValue,
              annualAdvancePercentage: projectsExec.annualAdvancePercentage,
              completionDate: dateCompletion,
              flag: true,
            };
            updatedPersonsList.push(person);
          }
        );
        this.personList = updatedPersonsList;
        this.financialQuery = true;
      });
  }

  remove(id: any) {
    let projects = this.infoStudyQuote.controls.projects as FormArray;
    projects.removeAt(id);
  }

  add() {
    let projects = this.infoStudyQuote.controls.projects as FormArray;
    projects.push(this.infoForm.group({
      name: [""],
      executionPercentage: [""],
      workValue: [""],
      annualAdvancePercentage: [""],
      completionDate: [""],
    }));
  }

  changeValue(id: number, property: string, event: any) {
    let text = event.target.textContent;
    text.replace("%", "");
    this.editField = text;
    this.propertySaved = property;
    this.idList = id;
  }
}
