import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormGroup } from "@angular/forms";
import { CodeSpecialParams } from "@app/models/codeSpecialParams.model";
import { ContractAddicControlsAsrTemp } from "@app/models/ContractAddicControlsAsrTemp";
import { ControlContractAsrTemp } from "@app/models/ControlContractAsrTemp.model";
import { DaysRefundAsrTemp } from "@app/models/DaysRetundAsrTemp";
import { DelimiteAsrTemp } from "@app/models/DelimiterAsrTemp.model";
import { DetailContractAsrTemp } from "@app/models/DetailContractAsrTemp.model";
import { ProductSelect } from "@app/models/productSelect.model";
import { SelectModel, SelectModelSubGroup } from "@app/models/select.model";
import { DelimiterOperativeRequest } from "@app/models/request/DelimiteOperativeRequest.model";

@Injectable({
  providedIn: "root",
})
export class FormContractService {
  user: "";
  document: 0;
  typeDocument: "N";
  productName = "";

  dateContract = "";
  numberContract = 0;
  currentPages = 0;

  status = localStorage.getItem("status") === "true";

  dateCreateContract: Date = new Date();

  private myNumberContracts = new BehaviorSubject<number>(0);
  private currentStep = new BehaviorSubject<number>(0);

  listEconomicActivity: SelectModelSubGroup[] = [];

  myNumberContracts$ = this.myNumberContracts.asObservable();
  currentStep$ = this.currentStep.asObservable();

  private detailContract: DetailContractAsrTemp[] = [];
  private myDetailContract = new BehaviorSubject<DetailContractAsrTemp[]>([]);

  myDetailContract$ = this.myDetailContract.asObservable();

  societyTypes = {
    LIMITADA: 3, //Sociedad de Responsabilidad Limitada
    EU: 4, //Empresa Unipersona
    ANONIMA: 5, // Sociedad anónima
    COMANDITA: 6, //Sociedad en Comandita por Acciones
  };

  constructor() {}

  init() {
    this.numberContract = Number(localStorage.getItem("numberContract"));
    let userCurrent = JSON.parse(localStorage.getItem("user"));
    let identity = JSON.parse(localStorage.getItem("AffiliateData"));

    this.document = identity ? identity.document : 0;
    this.typeDocument = identity ? identity.documentType : "";

    this.user = userCurrent ? userCurrent.user : null;
    this.detailContract = [];
    this.myDetailContract.next(this.detailContract);
    this.myNumberContracts.next(this.numberContract);
    if (!this.numberContract) localStorage.setItem("status", "false");
  }

  setContract(numberContract) {
    this.numberContract = numberContract;
    this.myNumberContracts.next(numberContract);
  }

  setCurrentStep(position) {
    if (position >= this.currentPages || position == 0) {
      this.currentPages = position;
      this.currentStep.next(this.currentPages);
    }
  }


  getCurrentStep(): number {
    return this.currentPages.valueOf();
  }

  addDetail(detailContract: DetailContractAsrTemp[]) {
    this.detailContract = this.detailContract.concat(detailContract);
    this.myDetailContract.next(this.detailContract);
  }

  editDetail(detailContract: DetailContractAsrTemp) {
    let data = this.detailContract.filter(
      (element) => element.pk.form != detailContract.pk.form
    );
    console.log(data);
    data.push(detailContract);
    this.detailContract = data;
    this.addDetail([]);
  }

  getStructDelimiter(
    product: string,
    requestDelimiter: DelimiteAsrTemp
  ): DelimiteAsrTemp {
    //Find the product
    let delimiter: DelimiteAsrTemp = {
      pk: {
        contract: this.numberContract,
        product: product,
        dateRecord: requestDelimiter
          ? requestDelimiter.pk.dateRecord
          : new Date().toJSON().toString(),
        state: "A",
      },
      quotas: 0,
      documentValue: 0,
      noticeNoPayment: 0,
      daysClaim: 0,
      daysRefund: 0,
      deductible: 0,
      requiresQuotaDay: "N",
      updateDate: new Date().toJSON().toString(),
      registrationUser: this.user,
      updateuser: this.user,
      returnDeductible: "N",
      daysReservation: 0,
    };
    return delimiter;
  }

  getStructDayRetund(product: string): DaysRefundAsrTemp {
    let daysRefund: DaysRefundAsrTemp = {
      pk: {
        contract: this.numberContract,
        product: product,
      },
      lower: 0,
      superior: 0,
      updateDate: new Date().toJSON().toString(),
      user: this.user,
    };
    return daysRefund;
  }

  getStructContractAddicControlsAsrTemp(
    form: FormGroup
  ): ContractAddicControlsAsrTemp {
    let contractAddicControlsAsrTemp = {
      contract: this.numberContract,
      quotaTotal: form.controls.totalQuota.value,
      userAct: this.user,
      actDate: new Date().toJSON().toString(),
    };
    return contractAddicControlsAsrTemp;
  }

  getStructControlContract(
    form: FormGroup,
    requestDelimiter: DelimiterOperativeRequest
  ): ControlContractAsrTemp {
    let info = requestDelimiter.tempControl;
    let controlContract: ControlContractAsrTemp = {
      pk: {
        contract: this.numberContract,
        date: info ? info.pk.date : new Date().toJSON().toString(),
      },
      day: Number(form.controls.day.value),
      postdated: Number(form.controls.postdated.value),
      pay: Number(form.controls.postdated.value),
      invoice: Number(form.controls.invoice.value),
      letter: Number(form.controls.letter.value),
      own: Number(form.controls.own.value),
      user: this.user,
      state: "A",
    };
    return controlContract;
  }

  getStructDetailContract(form: FormGroup): DetailContractAsrTemp {
    console.log(form.controls.endDate.value);
    let expiration = new Date(form.controls.endDate.value);

    //expiration.setHours(new Date().getHours());
    //expiration.setMinutes(new Date().getMinutes());
    //expiration.setMilliseconds(new Date().getMilliseconds());

    let start = new Date(form.controls.startDate.value);
    //start.setHours(new Date().getHours());
    //start.setMinutes(new Date().getMinutes());
    //start.setMilliseconds(new Date().getMilliseconds());

    let formValue =
      form.controls.formContract.value.length > 0
        ? form.controls.formContract.value[0]
        : form.controls.formContract.value;

    let detailContract: DetailContractAsrTemp = {
      pk: {
        contract: Number(this.numberContract),
        terms: form.controls.conditions.value,
        form: formValue,
        level: Number(form.controls.queryLevel.value),
      },
      minimumQuota: Number(form.controls.fixedCost.value),
      percentage: form.controls.percentage.value.toString().replace(/,/g, '.'),
      value: Number(form.controls.valueNum.value),
      expiration: expiration.toJSON().toString(),
      valueTX: Number(form.controls.valueTx.value),
      periodInvoice: form.controls.period.value,
      start: start.toJSON().toString(),
      negatedValue: Number(form.controls.valueNegated.value),
      userUpdate: this.user,
      dateUpdate: new Date().toJSON().toString(),
    };
    return detailContract;
  }

  filterCodeSpecial(
    columnName: string,
    codeSpecial: CodeSpecialParams[]
  ): CodeSpecialParams[] {
    let dataFilter = codeSpecial.filter(
      (element) => element.descModule === columnName
    );
    return dataFilter;
  }

  getTax(typeTax: string): SelectModel[] {
    let selectModel: SelectModel[] = [];
    try {
      switch (typeTax) {
        case "taxResponsabilities":
          let taxResponsabilities = JSON.parse(
            localStorage.getItem("taxResponsabilities")
          );
          selectModel = taxResponsabilities ? taxResponsabilities : [];
          break;
        case "taxRegimes":
          let taxRegimes = JSON.parse(localStorage.getItem("taxRegimes"));
          selectModel = taxRegimes ? taxRegimes : [];
          break;
      }
    } catch (error) {
      localStorage.removeItem("taxResponsabilities");
      localStorage.removeItem("taxRegimes");
    }

    return selectModel;
  }

  getProductName(id: number): string {
    let product: ProductSelect[] = JSON.parse(localStorage.getItem("product"));
    let find = product.find((element) => element.id == id);
    return find ? find.name : "";
  }

  getSocietyTypes(value): number {
    let societyType = this.societyTypes[value];
    return societyType ? societyType : value;
  }
}
