import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { GetCreditHistoryRequest, GetCreditHistoryResponse } from '../models/credit-history.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecognizeAdapterService } from './recognize-adapter.service';
import { LegalPersonAdapterService } from './legal-person-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class CreditHistoryService {

  public dev07ApiUtl: string = '';
  public headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private ciCd: CiCdConfigService,
    private recognizeAdapter: RecognizeAdapterService,
    private legalPersonAdapter: LegalPersonAdapterService
  ) {
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { dev07ApiUtl, experianToken } = apis;
      this.dev07ApiUtl = dev07ApiUtl;
      this.headers = this.headers.set(
        "Authorization",
        "Basic " + btoa(experianToken)
      );
    });
  }

  getCreditHistory(request: GetCreditHistoryRequest): Observable<GetCreditHistoryResponse> {
    const options = { headers: this.headers }
    return this.http.post<GetCreditHistoryResponse>(`${this.dev07ApiUtl}covifacturatransactions/api/v1/transactions/findExperianInfo`, request, options);
  }

  getRecognizeData(request: GetCreditHistoryRequest): Observable<any> {
    const options = { headers: this.headers }
    return this.http.post<GetCreditHistoryResponse>(`${this.dev07ApiUtl}covifacturatransactions/api/v1/transactions/findExperianInfo`, request, options).pipe(
      map(response => this.recognizeAdapter.adapt(response))
    );
  }

  getLegalPersonData(request: GetCreditHistoryRequest): Observable<any> {
    const options = { headers: this.headers }
    return this.http.post<GetCreditHistoryResponse>(`${this.dev07ApiUtl}covifacturatransactions/api/v1/transactions/findExperianInfo`, request, options).pipe(
      map(response => this.legalPersonAdapter.adapt(response))
    );
  }
}
