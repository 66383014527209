import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { environment } from 'src/environments/environment';
import { DataSendFiles, DataSendImages } from '../models/buyer-report.model';

@Injectable({
  providedIn: 'root'
})
export class ServicesBuyerReportService {
  public urlApi: string = '';
  public coviResourceUrl: string;
  public coviResourceToken: string;
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.urlApi = environment.apiUrl;
    this.timeout = environment.httpTimeout;
    this.coviResourceUrl = environment.coviResourceUrl;
    this.coviResourceToken = environment.coviResourceToken;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, httpTimeout, coviResourceUrl, coviResourceToken} = apis;
      this.urlApi = apiUrl;
      this.timeout = httpTimeout;
      this.coviResourceUrl = coviResourceUrl;
      this.coviResourceToken = coviResourceToken;
       console.log('config ci cd', apiUrl);
    });
  }

  postSearchFilesBuyer(data: DataSendFiles) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.coviResourceToken),
    });
    const options = { headers};
    return this.http.post(`${this.coviResourceUrl}CoviResourceServices/servicios/covifactura/findImageByCriteria`,data, options);
  }

  postSaveFilesBuyer(data: DataSendImages) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.coviResourceToken),
    });

    const options = { headers};
    return this.http.post(`${this.coviResourceUrl}CoviResourceServices/servicios/covifactura/saveMassiveImage`,data, options);
  }


}
