import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { QuotaService } from '@app/pages/quotas/services/quota-greateer.service';
import { HelperService } from '@app/services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-description-request',
  templateUrl: './description-request.component.html',
  styleUrls: ['./description-request.component.scss'],
  providers: [CurrencyPipe]
})
export class DescriptionRequestComponent implements OnInit {

  @Input() infoStudyQuote: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    public quotaService: QuotaService,
    public helper: HelperService,
    private currencyPipe: CurrencyPipe) {
  }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.infoStudyQuote.controls; }
}
