import { Component, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-tec-person-message',
  templateUrl: './tec-person-message.component.html',
  styleUrls: ['./tec-person-message.component.scss']
})
export class TecPersonMessageComponent implements OnInit {

  public showTecMessage: boolean = false;

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService,
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.showTecMessage = this.subjectCreditHistory.getIsIndependent(response);
  }

}
