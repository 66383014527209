import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-recognize-directions-vector-table',
  templateUrl: './recognize-directions-vector-table.component.html',
  styleUrls: ['./recognize-directions-vector-table.component.scss']
})
export class RecognizeDirectionsVectorTableComponent implements OnInit {

  @Input() dataTable: any;
  headers: string[] = [];

  constructor(private creditInfoHeaders: CreditInfoHeadersService) { }

  ngOnInit() {
    if (this.dataTable && this.dataTable.length > 0) {
      this.generateHeaders(this.dataTable[0].fechaInicial, 24); // Usamos 24 para 24 meses
    }
  }

  generateHeaders(startDate: string, length: number) {
    const start = new Date(startDate);
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    for (let i = 0; i < length; i++) {
      const header = `${monthNames[start.getMonth()]} ${start.getFullYear().toString().slice(-2)}`;
      this.headers.push(header);
      start.setMonth(start.getMonth() + 1);
    }
  }
}
