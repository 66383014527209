import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "@services/modal.service";
import { HelperService } from "@services/helper.service";
import { ActivatedRoute } from "@angular/router";
import { QuotaRadication } from "../models/quotaRadication.model";
import { QuotaRadicationService } from "@services/radication-quota.service";
import Swal from "sweetalert2";
import {
  Response,
  ResponseObject,
} from "../../../models/responseUtil.model";
import { SelectModel } from "@app/models/select.model";
import { DocsFilingQuota } from "../models/docsFilingQuota.model";
import { NgForm } from "@angular/forms";
import { ListResponse } from "@app/models/listResponse.model";
import { UploadFileComponent } from "@app/components/upload-file/upload-file.component";
import { ContractRequest } from "../models/request/ContractRequest.modal";
import { RadicationService } from "../services/radication-quota.service";

@Component({
  selector: "app-settle-request",
  templateUrl: "./settle-request.component.html",
  styleUrls: ["./settle-request.component.scss"],
})
export class SettleRequestComponent implements OnInit {
  @Input() status: boolean = false;
  @Output() setStatus = new EventEmitter<Boolean>();
  @Input() label: string = "Búsqueda contrato";
  @ViewChild("document") document: UploadFileComponent;
  @ViewChild("formSearchAutocomplete") formSearchAutocomplete: NgForm;
  @ViewChild("formSearchAffiliateAutocomplete")
  formSearchSearchAffiliateAutocomplete: NgForm;

  public queryCode: Array<SelectModel>;

  public searchStringAutocomplete: string;
  public searchAffiliateStringAutocomplete: string;
  public textErrorContract: string;
  public textErrorAffiliate: string;

  @ViewChild("formCheck") formCheck: NgForm;

  required: boolean = true;

  public quotaRadicationFormTest: QuotaRadication = {
    idRadQuota: null,
    requestType: 1,
    observations: "Observations",
    numberContract: null,
    typeDocBuyer: "",
    documentBuyer: "",
    docTypeAffiliate: "N",
    docAffiliate: 1234567890,
    nameAffiliate: "",
    quotaRequested: 20000000,
    email: "quotaRadication@test.com",
    docs: [],
    user: "user",
    userEmail: "user@test.com",
    requestState: null,
    requestDate: null,
    managementDate: null,
    description: null,
    priority: "0",
    assignedQuota: null,
    reasonDenial: null,
    assignedBag: "0",
    settlementNumber: null,
    persHist: true,
    buye: "",
    queryCode: 0,
    placeApplication:"A",
    product: "ASR"
  };

  public quotaRadicationReset: QuotaRadication = {
    idRadQuota: null,
    requestType: null,
    observations: "",
    numberContract: null,
    typeDocBuyer: "",
    documentBuyer: "",
    docTypeAffiliate: "N",
    docAffiliate: null,
    nameAffiliate: "",
    quotaRequested: null,
    email: "",
    docs: [],
    user: "",
    userEmail: "",
    requestState: null,
    requestDate: null,
    managementDate: null,
    description: "",
    priority: "0",
    assignedQuota: null,
    reasonDenial: null,
    assignedBag: "0",
    settlementNumber: null,
    persHist: true,
    buye: "",
    queryCode: 0,
    placeApplication:"A",
    product: "ASR"
  };

  public listResponse: ListResponse;

  public requestType: Array<SelectModel>;

  public typeDocument: Array<SelectModel> = [
    {
      value: "C",
      description: "Cedula de Ciudadanía",
    },
    {
      value: "E",
      description: "Cedula de Extranjería",
    },
    {
      value: "N",
      description: "NIT",
    },
  ];

  private requestStatus: Array<SelectModel>;

  public radioSelected = 0;

  public selectedTypeDocument = "";

  public buyeName = "";

  public continue = false;

  public statesConsult = false;

  public statesBuyer = false;

  public contractNotFound = false;

  public buyerNotFound = false;

  public findContract = false;

  public selectCode = false;

  public contractSuspend = false;

  public contractRe = false;

  public quotaRadicationForm: QuotaRadication = Object.assign(
    {},
    this.quotaRadicationReset
  );

  public files: any[] = [];
  public filesWErrors: any[] = [];

  public extensions: string =
    ".gif,.jpeg,.jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.txt,.msg";
  public bytes: number = 7340032;
  private docsFilingQuota: Array<DocsFilingQuota> = [];

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public modal: ModalService,
    public activatedRoute: ActivatedRoute,
    private quotaRadicationService: QuotaRadicationService,
    public radicationService: RadicationService,
  ) {}

  ngOnInit() {
    this.request();
  }

  request() {
    this.quotaRadicationService.getLists().subscribe((resp) => {
      if (resp.status == 200 && resp.data) {
        this.listResponse = resp.data[0];
        if (this.listResponse.requestTypeSettlements.length > 0)
          this.requestType = this.listResponse.requestTypeSettlements;
        if (this.listResponse.requestStatus.length > 0)
          this.requestStatus = this.listResponse.requestStatus;

        this.spinner.hide();
      }
    });
    this.requestType = localStorage.getItem("requestTypeSettlements")
      ? JSON.parse(localStorage.getItem("requestTypeSettlements"))
      : [];
    this.requestStatus = localStorage.getItem("requestStatus")
      ? JSON.parse(localStorage.getItem("requestStatus"))
      : [];
  }

  closeModal() {
    this.quotaRadicationForm = Object.assign({}, this.quotaRadicationReset);
  }

  changeClientType(requestType) {
    this.quotaRadicationForm = Object.assign({}, this.quotaRadicationReset);
    this.quotaRadicationForm.requestType = requestType;
  }

  radioSelectedItem() {
    const radioSel = this.requestType.find(
      (Item) => Item.value === this.radioSelected.toString()
    );
    return radioSel.value;
  }

  getFilesFromComponent(event) {
    this.files = event;
    this.files.forEach((file) => {
      let docFilingQuota: DocsFilingQuota = new DocsFilingQuota();
      Promise.resolve(
        this.helper.convertFileToBase64(file).then(function (value) {
          docFilingQuota.document = value;
          docFilingQuota.documentName = file.name;
        })
      );
      this.docsFilingQuota.push(docFilingQuota);
    });
  }

  getFilesWErrorFromComponent(event) {
    this.filesWErrors = event;
  }

  saveForm() {
    if (
      this.radioSelected != null &&
      this.quotaRadicationForm.observations !== "" &&
      this.files.length > 0
    ) {
      this.quotaRadicationForm.requestType = this.radioSelected;
      this.quotaRadicationForm.docs = this.docsFilingQuota;
      this.quotaRadicationForm.docs.forEach((element) => {
        element.document = this.helper.base64ToString(element.document);
      });

      if (this.requestStatus.length > 0) {
        let description: SelectModel = this.requestStatus.find(
          (element) => element.description == "NUEVA"
        );
        this.quotaRadicationForm.requestState = description
          ? Number(description.value)
          : null;
      }

      //this.quotaRadicationForm.user = name;

      this.spinner.show();
      let user  = this.radicationService.getDataUser();
      if (user) {
        this.quotaRadicationForm.userRequest = user;
        this.quotaRadicationService
        .postQuotaRadicationForm(this.quotaRadicationForm)
        .subscribe(
          (resp: Response<QuotaRadication>) => {
            if (resp.ok) {
              this.radioSelected = 0;
              Swal.fire({
                type: "success",
                title: "Solicitud exitosa",
                text:
                  "Se almacenó correctamente la radicación con el Id " +
                  resp.data[0].idRadQuota,
              }).then(() => {
                this.status = true;
                this.setStatus.emit(this.status);
                this.document.onRemoveAll();
                this.findContract = false;
                this.continue = false;
                this.selectCode = false;
                this.searchStringAutocomplete = "";
              });
            }
            this.quotaRadicationForm = Object.assign(
              {},
              this.quotaRadicationReset
            );
            this.quotaRadicationForm.quotaRequested = 0;
            this.spinner.hide();
          },
          (err) => {
            Swal.fire({
              type: "error",
              title: "Error en Transacción",
              text: "Ocurrio un error en la transacción",
            }).then(() => {});
            this.spinner.hide();
          }
        );
      }
      
      this.spinner.hide();
    } else if (!this.quotaRadicationForm.observations) {
      const prevClientType: string = this.quotaRadicationForm.observations;
      this.quotaRadicationForm = Object.assign({}, this.quotaRadicationReset);
      this.quotaRadicationForm.observations = prevClientType;
    }
  }

  onSelectFilterState(queryCode: number) {
    this.quotaRadicationForm.queryCode = queryCode;
    this.selectCode = true;
  }

  onSelectDocument(event: any) {
    this.selectedTypeDocument = event;
  }

  handleSearchAffiliate(searchText: string): void {
    this.quotaRadicationForm.typeDocBuyer = this.selectedTypeDocument;
    this.quotaRadicationForm.documentBuyer = searchText;
    this.quotaRadicationService
      .getNameAffiateInfo(this.selectedTypeDocument, searchText)
      .subscribe(
        (resp: Response<any>) => {
          this.buyeName = resp.data[0];
          this.quotaRadicationForm.buye = resp.data[0];
          this.buyerNotFound = false;
          this.continue = true;
        },
        (error) => {
          this.textErrorAffiliate = error.error.message;
          this.buyerNotFound = true;
          this.continue = false;
          this.buyeName = "";
        }
      );
  } 

  handleSearch(searchText: string): void {
    this.quotaRadicationForm.numberContract = Number(searchText);
    this.quotaRadicationService
      .getContractInfo(this.quotaRadicationForm.numberContract)
      .subscribe(
        (resp: ResponseObject<ContractRequest>) => {
          let contract = resp.data;
          this.quotaRadicationForm.docAffiliate = contract.document;
          this.quotaRadicationForm.docTypeAffiliate = contract.typeDocument;

          this.quotaRadicationForm.nameAffiliate = contract.nameAfiliated;
          this.findContract = true;

          const modelQuery: Array<SelectModel> = resp.data.queryCode.map(
            (query) => {
              const selecModel: SelectModel = {
                value: query.queryCode.toString(),
                description: query.queryCode + "-" + query.state,
                state: query.state,
              };
              this.selectCode = false;
              this.contractNotFound = false;
              this.continue = false;
              return selecModel;
            }
          );
          this.queryCode = modelQuery;
        },
        (error) => {
          this.textErrorContract = error.error.message;
          console.log('El error:', this.textErrorContract)
          this.selectCode = false;
          this.findContract = false;
          this.contractNotFound = true;
        }
      );
  }

  toggleResults(show: boolean, preventHide?: boolean): void {}
}
