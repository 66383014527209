import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-recognize-addresses-table',
  templateUrl: './recognize-addresses-table.component.html',
  styleUrls: ['./recognize-addresses-table.component.scss']
})
export class RecognizeAddressesTableComponent implements OnInit {

  @Input() dataTable: any;
  dataTableToDisplay: any[] = [];

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
    this.prepareData();
  }


  prepareData() {
    this.dataTableToDisplay = this.convertToArray(this.dataTable);
  }

  convertToArray(data: any): any[] {
    if (Array.isArray(data)) {
      return data;
    } else if (data && typeof data === 'object') {
      return [data];
    }
    return [];
  }
}
