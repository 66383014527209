export class ProductResponse {
    ok:          boolean;
    message:     string;
    data:        ProductResponseModel[];
    status:      number;
    currentPage: number;
    totalPages:  number;
}

export class ProductResponseModel {
    id:                    number;
    name:                  string;
    affiliationValue:      number;
    studyValue:            number;
    numStudies:            number;
    numChecks:             number;
    termOfSale:            number;
    segment:               string;
    coverage:              number;
    noticeOfNonPayment:    number;
    claimTerm:             number;
    backup:                number;
    reimbursementTerm:     number;
    specialConditions:     boolean;
    deductiblePaymentTerm: number;
    user:                  string;
    createdAt:             string;
    updatedAt:             string;
    productType:      string = "";
    bondPeriod:       string = "";
    active:               boolean;
    products:              string;

    file?:                 string;
    allowBags?:   boolean = false;
}
