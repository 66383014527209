import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AffiliatesListModel } from '@app/pages/backups/models/response/SearchAffiliate.model';
import { BackupsStoreService } from '@app/pages/backups/services/backups-store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-affiliate-id-information',
  templateUrl: './affiliate-id-information.component.html',
  styleUrls: ['./affiliate-id-information.component.scss']
})
export class AffiliateIdInformationComponent implements OnInit, OnDestroy {

  public affiliate: AffiliatesListModel = new AffiliatesListModel();
  public subs1: Subscription = new Subscription();

  @Input() show: boolean = true;

  constructor(private backupsStore: BackupsStoreService) {
    this.subs1 = this.backupsStore.stateAffiliate$.subscribe((affiliate: AffiliatesListModel) => {
      this.affiliate = affiliate;
    });
  }

  ngOnInit() {
    // console.log('id ->', this.affiliate);
  }

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

}

