import { Component, Input, OnInit } from '@angular/core';
import { CompleteBasicInformation } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-basic-information',
  templateUrl: './legal-basic-information.component.html',
  styleUrls: ['./legal-basic-information.component.scss']
})
export class LegalBasicInformationComponent implements OnInit {

  @Input() completeBasicInformation: CompleteBasicInformation | undefined = undefined;


  get isValidNaturalPerson(): boolean {
    return (this.completeBasicInformation && this.completeBasicInformation.isNaturalPerson && this.completeBasicInformation.personInformation) ? true : false;
  }

  get isValidCompany(): boolean {
    return (this.completeBasicInformation && this.completeBasicInformation.companyInformation) ? true : false;
  }

  constructor() { }

  ngOnInit() {
  }

}
