import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-autex-key-gen',
  templateUrl: './autex-key-gen.component.html',
  styleUrls: ['./autex-key-gen.component.scss']
})
export class AutexKeyGenComponent implements OnInit {

  @ViewChild('blockBuyerForm') blockBuyerForm: NgForm;

  public headers: Array<string>;

  public accounts: Array<any>;

  public docTypes;

  public currentPage: number = 1;

  public totalPages: number = 1;

  public isBlock: boolean = false;

  public isOtherReason: boolean = false;

  public selectList: Array<boolean> = [];

  public blockOption: string = 'Bloquear';

  public accountSelected: any;

  public isQuerySuccess: boolean = false;

  public formAccount = {
    documentType: '',
    document: '',
    reason: '',
    reasonOther: '',
    type: '',
  }

  public reasonList: Array<any>;

  public blockAccountOptions = [
    {
      description: 'Bloquear',
      value: 'L'
    },
    {
      description: 'Desbloquear',
      value: 'U'
    },
  ]

  public get documentType() {
    return parseInt(this.formAccount.documentType)
  }

  public get document() {
    return parseInt(this.formAccount.document)
  }

  constructor(private quotaGreater: QuotaGreaterService, private listCovinoc: ListsCovinocService, public helper: HelperService, private tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      this.docTypes = response.data[0].documentTypesCode;
    });
    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.reasonList = response.data[0].locksReason;
    })
    this.headers = this.tableHeaders.tableHeadersAutexKeys
  }

  selectBlockOption($event) {
    this.formAccount.type = $event.target['value'];    
    this.isBlock = (this.formAccount.type === 'L') ? true : false;
    this.blockOption = (this.formAccount.type === 'L') ? 'Bloquear' : 'Desbloquear';
  }

  selectOtherReason() {
    this.isOtherReason = (this.formAccount.reason === 'Otro') ? true : false;
    console.log(this.isOtherReason);
  }

  selectAccount(id: number) {
    this.accountSelected = this.selectList[id];
    this.selectList[id] = true;
  }

  executeOption() {

    const user = JSON.parse(localStorage.getItem("user"));

    if(user) {
      const body = {
        documentType: this.formAccount.documentType,
        document: this.formAccount.document,
        user: user.user,
        typeAction: this.formAccount.type,
        reason: this.formAccount.reason,
        reasonOther: this.formAccount.reasonOther,
      }
  
      this.quotaGreater.lockUnlock(body).subscribe((response: any) => {
        console.log(response);
        
      })
    }    
  }

  searchAccounts() {
    this.spinner.show();
    const params = {
      quantity: this.formAccount.document,
    }

    this.quotaGreater.generateAutexKeys(params).subscribe((response: any) => {
      this.spinner.hide();
      this.accounts = response.data[0];
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.isQuerySuccess = true;
    }, (error) => {
      console.error(error)
    })
  }

  goToPage($event) {
    this.spinner.show();

    const params = {
      quantity: this.formAccount.document,
    }

    this.quotaGreater.generateAutexKeys(params).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.accounts = response.data[0];
      this.currentPage = response.currentPage
      this.totalPages = response.totalPages
    }, (error) => {
      console.log(error)
    })
  }

}
