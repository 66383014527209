import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { FilterAffiate } from "../models/Request/FilterAffiliate";
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class NetFactoringService {
  
  constructor(private http: HttpClient) {}

  public ServiceTabs: any[] = [
    {
      href: "#name-affiliate",
      description: "Afiliados",
      key: "name-affiliate",
    },
    {
      href: "#negotiated-invoices",
      description: "Facturas Negociadas",
      key: "negotiated-invoices",
    },
    {
      href: "#possible-invoices",
      description: "Facturas Posibles",
      key: "negotiated-possible",
    },
  ];

  public typeReport: Array<string> = ["POSIBLES", "NEGOCIADAS"];

  public typeQuery: Array<string> = ["NOMBRE", "FECHAS"];

  public tableHeadersNamesAffiliates: string[] = [
    "Tipo de documento",
    "Documento",
    "Nombre",
  ];

  public tableHeaderPossibleInvoives: string[] = [
    "N° Factura",
    "N° Contrato",
    "Tipo de documento Afiliado",
    "Documento Afiliado",
  ];

  public tableHeaderNegotiatedInvoives: string[] = [
    "Identificación",
    "Fecha de emisión",
    "número de factura",
    "fecha de vencimiento",
    "Valor de la factura",
    "Tasa de negociación",
    "Negociación disponible",
    "Valor negociado final",
    "Estado de pago",
    "Reencontrar Fianza",
    "Contrato No",
    "tipo de documento Afiliado",
    "Documento Afiliado",
    "Negociación Id",
    "Días de vencimiento",
    "Tasa de descuento",
    "Total de descuento",
  ];

  public tableHeaderInvoivesStatus: string[] = [
    "Tipo de documento",
    "Documento",
    "Fecha de emisión",
    "Número de factura",
    "Fecha de vencimiento",
    "Valor de la factura",
    "Tasa de negociación",
    "Estado",
    "Negociación disponible",
    "Acciones",
  ];

  public tableHearderFinancial: string[] = [
    "Fecha de creación",
    "Numero de factura",
    "Estado",
    "Valor factura",
    "Deducible Covifactura",
    "Tarifa administrativa",
    "Tarifa Negociación",
    "Interés de mora",
    "Devolución del capital",
  ];

  public filterAfilliate: FilterAffiate = {
    socialReason: null,
    firstName: null,
    secondName: null,
    firstSurname: null,
    secondSurname: null,
    currentPage: 1,
    perPage: 10,
    from: null,
    to: null,
    typeReport: null,
    typeQuery: null,
  };

  public filterAfilliatePossible: FilterAffiate = {
    socialReason: null,
    firstName: null,
    secondName: null,
    firstSurname: null,
    secondSurname: null,
    currentPage: 1,
    perPage: 10,
    from: null,
    to: null,
    typeReport: null,
    typeQuery: null,
  };

  

  /* https://desarrollo07.c0v.co/CajaYa/api/v1/facturas/notificardocumento */

  
}
