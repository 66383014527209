import { Component, OnInit } from '@angular/core';
import { QuotaGreaterService } from '@app/services/quota-greater.service';

@Component({
  selector: 'app-localization-customer',
  templateUrl: './localization-customer.component.html',
  styleUrls: ['./localization-customer.component.scss']
})
export class LocalizationCustomerComponent implements OnInit {

  constructor(
    private quotaGreaterService: QuotaGreaterService
  ) { }

  ngOnInit() {
    this.quotaGreaterService.getMunicipalReference().subscribe((resp) => {
      console.log(resp);
    }, (error) => console.error)
  }

}
