import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIADMINROLES, PROJECT } from '../consts/API';
import { Observable } from 'rxjs';
import { RolByUserResponse } from '../models/rol-by-user.model';
import { RolDetailsResponse } from '../models/rol-details.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  getRolByUser(user: string): Observable<RolByUserResponse> {
    return this.http.get<RolByUserResponse>(`${APIADMINROLES}/${PROJECT}-usuarios/${user}`);
  }

  getRolDetails(rol: string): Observable<RolDetailsResponse> {
    return this.http.get<RolDetailsResponse>(`${APIADMINROLES}/${PROJECT}-roles/${rol}`);
  }
}
