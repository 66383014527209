import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AffiliateLinkDataForm, DateLink } from 'src/app/models/afiliate-link';
import { LegalRepresentative } from 'src/app/models/membership.model';
import { PartnerModel } from 'src/app/models/partner.model';
import { SelectsDataForm } from 'src/app/models/selectModel';
import { AffiliateLinkService } from 'src/app/services/affiliate-link.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-afiliate-link',
  templateUrl: './afiliate-link.component.html',
  styleUrls: ['./afiliate-link.component.scss']
})
export class AfiliateLinkComponent implements OnInit {
  @ViewChild('formSetup') formSetup: NgForm;
  @ViewChild('formSetup1') formSetup1: NgForm;
  public partners: PartnerModel[] = [new PartnerModel()];       
  public partnersModel: LegalRepresentative[];
  public documentTypesList: SelectsDataForm[];
  public generalLists: SelectsDataForm;
  public documentTypeAffiliate: any[] = [];
  public documentTypeContact: any[] = [];
  public affiliateData: AffiliateLinkDataForm;
  public data: AffiliateLinkDataForm = new AffiliateLinkDataForm();
  public maximumDate: DateLink = new DateLink();
  public municipalities: SelectsDataForm[];
  public city: string;
  public departments: any;
  public listCities: any;
  public cities: any;
  public daneCode: SelectsDataForm[];
  public message: AffiliateLinkDataForm;

  constructor(
    private affiliateLink: AffiliateLinkService,
    private notifyData: AffiliateLinkService,
    private department: AffiliateLinkService,
    public helper: HelperService,
    public select: SelectsService) {
  }

  ngOnInit() {
    this.initializeSelects();
  }

  initializeSelects(): void {
    this.data.applicative = "ASR"
    /* this.data.userAct = "A"
    this.data.idRol = 2;
    this.data.daneCode = "2"; */
    this.generalList();
    this.getDepartments();
  }

  generalList() {
    this.affiliateLink.getGeneralList().subscribe((response: SelectsDataForm) => {
      this.generalLists = response;
    },
      (err) => {
        this.listCities = [];
      });
  }


  getDepartments() {
    this.affiliateLink.getMunicipalities().subscribe((departments: any) => {
      console.log(departments);
      
      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  selectDeparment(selectedDepartment) {
    this.data.city = "";
    this.affiliateLink.getCities(selectedDepartment).subscribe(
      (municipalities: any) => {
        console.log(municipalities[0]);
        
        this.listCities = municipalities[0].cities || [];
      },
      (err) => {
        this.listCities = [];
      });
  }

  postNotifyForm() {
    let bodyNotifyForm = { ...this.data };
    delete bodyNotifyForm.applicative;
    delete bodyNotifyForm.message;
    delete bodyNotifyForm.urlAffiliate;
    delete bodyNotifyForm.roleAssignment;
    delete bodyNotifyForm.city;
    delete bodyNotifyForm.municipalities;
    this.notifyData.userNotifyForm(bodyNotifyForm).subscribe(
      (response: any) => {
        if (response) {
          Swal.fire({
            type: "info",
            title: response.message
          }).then(resp => {
            window.location.reload();
          })
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          type: "error",
          title: error.message,
        })
      }
    )
  }

  postAffiliateLink() {
    let bodyAffiliateLink = {
      issuerDocumentTypeId: this.data.issuerDocumentTypeId,
      issuerDocumentId: this.data.issuerDocumentId,
      urlAffiliate: this.data.urlAffiliate,
      applicative: this.data.applicative,
    }

    this.affiliateLink.userAffiliateLink(bodyAffiliateLink).subscribe(
      (response: any) => {
        if (response) {
          Swal.fire({
            type: "info",
            title: response.message
          }).then(resp => {
            window.location.reload();
          })
        }
      },
      (error) => {
        console.log(error);
        Swal.fire({
          type: "error",
          title: error.message,
        })
      }
    )
  }

  dualService() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.data.idRol = this.data.roleAssignment as any;
      this.data.userAct = user.user;
      this.data.daneCode = this.data.city;
      this.data.dateBirthday = this.maximumDate.dateBirthday;
      this.postNotifyForm();
      this.postAffiliateLink();
    }
  }
}
