import { Component, OnInit, Input, OnChanges } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-profit-and-loss',
  templateUrl: './profit-and-loss.component.html',
  styleUrls: ['./profit-and-loss.component.scss']
})
export class ProfitAndLossComponent implements OnInit, OnChanges {

  @Input() quotaData: any;
  @Input() stateList: Array<any> = [];
  public firstYear: string;
  public secondYear: string;
  public month: string;
  
  constructor() { }

  ngOnInit() {
    
    
  }

  ngOnChanges() {
    if(this.stateList.length > 0) {
      this.getYears()
    }
  }

  getYears() {
    let yearsList: Array<any> = [];
    
    yearsList = Object.keys(this.stateList[0]);
    this.firstYear = yearsList[0];
    this.secondYear = yearsList[1];
    this.month = yearsList[2];
  }

}
