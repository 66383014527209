import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "stateInvoices",
})
export class StateInvoicesPipe implements PipeTransform {
  state = [
    {
      id: "R",
      name: "Rechazando",
      label: "Rechazando",
    },
    {
      id: "A",
      name: "Aprobado",
      label: "Aprobado",
    },
    {
      id: "P",
      name: "Pendiente",
      label: "Pendiente",
    },
  ];

  transform(value: any, args?: any): any {
    let name = this.state[2];
    name = this.state.find((element)=> element.id == value );

    return name.name;
  }
}
