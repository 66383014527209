import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transunion',
  templateUrl: './transunion.component.html',
  styleUrls: ['./transunion.component.scss']
})
export class TransunionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
