import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@app/services/modal.service';
import { HelperService } from '@app/services/helper.service';
import { SelectModel } from '@app/models/select.model';
import { Store } from '@ngrx/store';
import { AppState } from '@app/redux/app.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectsService } from '@app/services/selects.service';
import { RepLegalAsrTemp } from '@app/models/repLegalAsrTemp.model';
import { LegalRepAsrTempPK } from '@app/models/compositeKey/LegalRepAsrTempPK.model';

@Component({
  selector: 'app-modal-add-rep-legal',
  templateUrl: './modal-add-rep-legal.component.html',
  styleUrls: ['./modal-add-rep-legal.component.scss'],
})
export class ModalAddRepLegalComponent implements OnInit, DoCheck, OnDestroy {
  public repLegal: FormGroup;

  public selectOptionsDocumentTypes: SelectModel[] = [];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  validModalChange = false;

  listLegalRep: any[] = [];
  itemLegalRep: RepLegalAsrTemp;
  public selectContract: any;

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    public selects: SelectsService,
    public helper: HelperService
  ) {}

  ngOnDestroy(): void {
    this.validModalChange = false;
    this.modal.close();
  }

  ngOnInit() {
    this.createForm();
    this.listLegalRep = [];
    this.itemLegalRep = null;
    this.validModalChange = false;
  }

  ngDoCheck(): void {
    if (this.modal.modal.addRepLegalModal && !this.validModalChange) {
      if (localStorage.getItem('listRepLegal'))
        this.listLegalRep = JSON.parse(localStorage.getItem('listRepLegal'));

      if (localStorage.getItem('itemRepLegal')) {
        this.itemLegalRep = JSON.parse(localStorage.getItem('itemRepLegal'));
        localStorage.removeItem('itemRepLegal');
        this.getItemRepLegal();
      }
      this.selectOptionsDocumentTypes = [];
      this.selectContract = JSON.parse(localStorage.getItem('covi-utils'));
      this.selectContract.documentTypesCode.forEach((element) => {
        if (!(element.value === 'T'))
          this.selectOptionsDocumentTypes.push(element);
      });
      this.validModalChange = true;
    }
  }

  createForm() {
    this.repLegal = this.fb.group({
      firstName: ['', Validators.required],
      secondName: [''],
      firstLastName: ['', Validators.required],
      secondLastName: [''],
      document: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ],
      ],
      documentType: ['', Validators.required],
    });
    this.listLegalRep = [];
    this.itemLegalRep = null;
  }

  get f() {
    return this.repLegal.controls;
  }

  closeModal() {
    this.createForm();
    this.validModalChange = false;
    this.modal.close();
  }

  onSubmit() {
    if (this.itemLegalRep && this.itemLegalRep.legalRepAsrTempPK.document) {
      this.listLegalRep = this.listLegalRep.filter(
        (element) =>
          element.legalRepAsrTempPK.document !==
          this.itemLegalRep.legalRepAsrTempPK.document
      );
    }
    this.listLegalRep.push(this.mapperLegalRep());
    localStorage.setItem('listRepLegal', JSON.stringify(this.listLegalRep));
    this.createForm();
    this.validModalChange = false;
    this.modal.close();
  }

  mapperLegalRep(): RepLegalAsrTemp {
    let repLegalAsrTemp: RepLegalAsrTemp = new RepLegalAsrTemp();
    let legalRepAsrTempPK: LegalRepAsrTempPK = new LegalRepAsrTempPK();

    if (this.itemLegalRep && this.itemLegalRep.legalRepAsrTempPK.document) {
      repLegalAsrTemp.id = this.itemLegalRep.id;
      repLegalAsrTemp.documentOld = this.itemLegalRep.documentOld;
      repLegalAsrTemp.documentTypeOld = this.itemLegalRep.documentTypeOld;
    }

    repLegalAsrTemp.firstName = this.f.firstName.value;
    repLegalAsrTemp.secondName = this.f.secondName.value;
    repLegalAsrTemp.firstLastName = this.f.firstLastName.value;
    repLegalAsrTemp.secondLastName = this.f.secondLastName.value;
    legalRepAsrTempPK.documentType = this.f.documentType.value;
    legalRepAsrTempPK.document = this.f.document.value;
    repLegalAsrTemp.legalRepAsrTempPK = legalRepAsrTempPK;
    return repLegalAsrTemp;
  }

  getItemRepLegal() {
    this.f.firstName.setValue(this.itemLegalRep.firstName);
    this.f.secondName.setValue(this.itemLegalRep.secondName);
    this.f.firstLastName.setValue(this.itemLegalRep.firstLastName);
    this.f.secondLastName.setValue(this.itemLegalRep.secondLastName);
    this.f.documentType.setValue(
      this.itemLegalRep.legalRepAsrTempPK.documentType
    );
    this.f.document.setValue(this.itemLegalRep.legalRepAsrTempPK.document);

    if (this.modal.payload.edit) {
      this.f.document.valueChanges.subscribe(() => {
        this.itemLegalRep.documentOld =
          this.itemLegalRep.legalRepAsrTempPK.document;
        this.itemLegalRep.documentTypeOld =
          this.itemLegalRep.legalRepAsrTempPK.documentType;
      });

      this.f.documentType.valueChanges.subscribe(() => {
        this.itemLegalRep.documentOld =
          this.itemLegalRep.legalRepAsrTempPK.document;
        this.itemLegalRep.documentTypeOld =
          this.itemLegalRep.legalRepAsrTempPK.documentType;
      });

      //this.f.document.disable();
      //this.f.documentType.disable();
    }
  }
}
