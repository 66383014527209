import { Pipe, PipeTransform } from '@angular/core';
import { SelectModel } from '@app/models/select.model';

@Pipe({
  name: 'descriptionSM'
})
export class DescriptionSelectModelPipe implements PipeTransform {

  transform(value: any, list?: SelectModel[]) {
    let description = list.find(element =>
      element.value == value
      );
    if (description) {
      return description.description;
    } else {
      return '--';
    }
  }

}


