import { Component, OnInit } from '@angular/core';
import { ExpirianListsService } from '../services/expirian-lists.service';
import { ExpirianListsResponse } from '../models/expirian-lists.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-experian',
  templateUrl: './experian.component.html',
  styleUrls: ['./experian.component.scss']
})
export class ExperianComponent implements OnInit {

  constructor(
    private expirianLists: ExpirianListsService,
    private spinner: NgxSpinnerService
  ) {
    this.handleInitExpirianLists();
  }

  ngOnInit() {
  }

  handleInitExpirianLists() {
    this.spinner.show();
    this.expirianLists.getExpirianLists().subscribe({
      next: (response: ExpirianListsResponse) => {
        if (response.data.length > 0) {
          localStorage.setItem('EXPERIAN_LISTS', JSON.stringify(response.data));
        }
        this.expirianLists.setResponseExpirianList(response);
        this.spinner.hide();
      },
      error: (error: any) => {
        console.log('Ocurrió un error al obtener las listas de expirian: ', error);
        this.spinner.hide();
      }
    })
  }

}
