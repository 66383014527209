export class QuearyCodeAsrTemp {

  /*Object of information, we cannot update and save...etc */
  typeDocument: string;
  document:number;

  id: number;
  state: string;
  branchOffices: string;
  register: Date;
  idContract: number;
  establishment: string;
  firstName: string;
  secondName: string;
  firstLastName: string;
  secondLastName: string;
  idAddress: number;
  idPhone: number;

  sequence: number;
  user: string;
  updateDate: Date;
  retirementDate: Date;
}
