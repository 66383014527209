import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";

import Swal from "sweetalert2";

import { RadicationQuotaAdminService } from "@app/services/radication-quota-admin.service";
import { Response } from "../../../../models/responseUtil.model";
import { ProfileCurrent } from "../../models/response/responseProfileRequest";
import { RadicationService } from "../../services/radication-quota.service";

@Component({
  selector: "app-profile-managment",
  templateUrl: "./profile-managment.component.html",
  styleUrls: ["./profile-managment.component.scss"],
})
export class ProfileManagmentComponent implements OnInit {
  public profileManagment: Array<ProfileCurrent>;
  @Input() isTab: string = "";
  @Output() setTab = new EventEmitter<string>();

  @Input() isEdit: boolean = false;
  @Output() setEdit = new EventEmitter<boolean>();

  @Input() isView: boolean = false;
  @Output() setView = new EventEmitter<boolean>();

  public navItemTabs: any[] = [];

  public cantPages = 0;
  public currentPage = 1;
  public perPage = 10;
  public onShowPag = false;

  constructor(
    private radicationServiceAdmin: RadicationQuotaAdminService,
    public radicationService: RadicationService
  ) {
    this.navItemTabs = this.radicationService.radicationTabsAdmin;
  }

  ngOnInit() {
    this.setEdit.emit(false);
    this.getDataCurrentProfile();
    localStorage.removeItem("editProfile");
  }

  getDataCurrentProfile() {
    this.radicationServiceAdmin
      .getProfileCurrent(this.currentPage, this.perPage)
      .subscribe(
        (response: Response<ProfileCurrent>) => {
          this.profileManagment = response.data;
          this.cantPages = response.totalPages;
          this.onShowPag = true;
        },
        (error) => {
          console.error(error.message);
        }
      );
  }

  onCreateProfile() {
    this.setTab.emit(this.navItemTabs[1].key);
    localStorage.setItem("create", "true");
  }

  deleteProfileConfirm(id: number) {
    Swal.fire({
      type: "info",
      text: `¿Desea continuar con esta solicitud?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
      allowOutsideClick: false,
    }).then((value) => {
      if (value.value === true) {
        this.deleteProfile(id);
      }
    });
  }

  deleteProfile(id: number) {
    this.radicationServiceAdmin.deleteProfile(id).subscribe(
      (response: Response<any>) => {
        Swal.fire({
          type: "success",
          title: `${response.message}`
        }).then(() => {
          this.getDataCurrentProfile();
        });
      },
      (error) => {
        Swal.fire({
          type: "error",
          title: "Error en Transacción",
          text: `${error.error.message}`,
        });
      }
    );
  }

  editProfileEvent(editProfile: ProfileCurrent, edit: boolean) {
    edit ? this.setView.emit(false) : this.setView.emit(true);
    edit ? this.setEdit.emit(true) : this.setEdit.emit(false);
    this.setTab.emit(this.navItemTabs[1].key);
    localStorage.setItem("editProfile", JSON.stringify(editProfile));
  }

  goToPage({ currentPage }) {
    console.log(currentPage);
    this.currentPage = currentPage;
    this.getDataCurrentProfile();
  }
}
