import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";
import { RequestCredit } from "@app/models/RequestCredit.model";
import { ButtonFloat } from "@app/models/buttonFloat.model";
import { ListResponse } from "@app/models/listResponse.model";
import {
  Response,
  ResponseObject,
} from "@app/models/responseUtil.model";
import { EmployeePerson } from "@app/pages/quota-radication/models/EmployeePerson.model";
import { LegalNatural } from "@app/pages/quota-radication/models/legalNatural.model";
import { QuotaRadication } from "@app/pages/quota-radication/models/quotaRadication.model";
import { QuotaSettlementHist } from "@app/pages/quota-radication/models/quotaSettlementHist.model";
import { ContractRequest } from "@app/pages/quota-radication/models/request/ContractRequest.modal";
import { QuotaRadicationRequest } from "@app/pages/quota-radication/models/request/quotaRadicationRequest.model";
import { UserModel } from "@models/user.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class QuotaRadicationService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlRequest;
    // this.readToken();
    // this.getIdentity();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlRequest} = apis
      this.urlApi = urlRequest;
       console.log('config ci cd', apiUrl);
    });
  }

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem("user"))
    ));
  }

  postQuotaRadicationForm(
    quotaRadication: QuotaRadication
  ): Observable<Response<QuotaRadication>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers: headers,
    };
    const body = quotaRadication;
    return this.http
      .post(`${this.urlApi}radicacionCupo/radicacion/`, body, options)
      .pipe(
        map((resp: Response<QuotaRadication>) => {
          return resp;
        })
      );
  }

  getAffiliateByNit(
    legalNatural: LegalNatural
  ): Observable<Response<LegalNatural>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const param = {
      currentPage: "1",
      perPage: "10",
      documentType: legalNatural.documentType,
      document: "" + legalNatural.document,
    };
    const options = {
      params: param,
      headers,
    };
    return this.http
      .get(`${this.urlApi}naturalJuridica/obtenerAfiliadoByNit`, {
        headers: options.headers,
        params: options.params,
      })
      .pipe(
        map((resp: Response<LegalNatural>) => {
          return resp;
        })
      );
  }

  getRadicationBuyerInfo(params): Observable<Response<any>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      params,
      headers,
    };
    return this.http
      .get(`${this.urlApi}naturalJuridica/obtenerAfiliadoByNit`, {
        headers: options.headers,
        params: options.params,
      })
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getQuotaRadicationByFilter(
    quotaRadicationRequest: QuotaRadicationRequest
  ): Observable<Response<QuotaRadication>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params: any = quotaRadicationRequest;
    const options = {
      params: params,
      headers,
    };
    return this.http
      .get(`${this.urlApi}/radicacionCupo/obtenerRadicacion`, {
        headers: options.headers,
        params: options.params,
      })
      .pipe(
        map((resp: Response<QuotaRadication>) => {
          return resp;
        })
      );
  }

  getQuotaRadicationHist(
    idQuotaSettlement: number
  ): Observable<Response<QuotaSettlementHist>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      currentPage: "1",
      perPage: "10",
      idQuotaSettlement: idQuotaSettlement + "",
    };
    const options = {
      params: params,
      headers,
    };
    return this.http
      .get(`${this.urlApi}radicacionCupo/obtenerRadicacionHist`, {
        headers: options.headers,
        params: options.params,
      })
      .pipe(
        map((resp: Response<QuotaSettlementHist>) => {
          return resp;
        })
      );
  }

  getLists(): Observable<Response<ListResponse>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    const params = {
      profile: localStorage.getItem("profile"),
    };
    return this.http
      .get(`${this.urlApi}requestSettlements/typeAndStatus`, {
        headers,
        params,
      })
      .pipe(
        map((resp: Response<ListResponse>) => {
          if (resp && resp.data) {
            if (
              resp.data[0].requestTypeSettlements &&
              resp.data[0].requestTypeSettlements.length > 0
            ) {
              localStorage.setItem(
                "requestTypeSettlements",
                JSON.stringify(resp.data[0].requestTypeSettlements)
              );
            }
            if (
              resp.data[0].requestStatus &&
              resp.data[0].requestStatus.length > 0
            ) {
              localStorage.setItem(
                "requestStatus",
                JSON.stringify(resp.data[0].requestStatus)
              );
            }
          }
          return resp;
        })
      );
  }

  getContractInfo(
    contract: number
  ): Observable<ResponseObject<ContractRequest>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    const params = {
      numberContract: contract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/getContract`, { headers, params })
      .pipe(
        map((resp: ResponseObject<ContractRequest>) => {
          return resp;
        })
      );
  }

  getNameAffiateInfo(
    typeDocument: string,
    document: string
  ): Observable<Response<Array<string>>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const params = {
      typeDocument: typeDocument,
      document: document,
    };
    return this.http
      .get(`${this.urlApi}affiatePayer/getAffiatePayer`, { headers, params })
      .pipe(
        map((resp: Response<Array<string>>) => {
          return resp;
        })
      );
  }

  getUserProfile(idUser: string): Observable<Response<ButtonFloat>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      idUser: idUser,
    };
    const options = {
      headers,
      params,
    };
    return this.http.get(`${this.urlApi}userProfile/getProfile`, options).pipe(
      map((resp: Response<ButtonFloat>) => {
        if (resp && resp.data && resp.data.length > 0) {
          localStorage.setItem("profile", resp.data[0].value.toString());
          return resp;
        }
      })
    );
  }

  getFiligs(
    typeRadication: string,
    numberRadication: number | string,
    pay: string,
    currentPage
  ): Observable<Response<RequestCredit>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      currentPage: currentPage,
      perPage: "20",
    };

    if (pay) {
      params["pagare"] = pay;
    }

    if (typeRadication) {
      params["tipoRadicacion"] = typeRadication;
    }

    if (numberRadication) {
      params["radicacion"] = numberRadication.toString();
    }

    const options = {
      headers,
      params,
    };
    return this.http.get(`${this.urlApi}requestStudy/getAll`, options).pipe(
      map((resp: Response<RequestCredit>) => {
        return resp;
      })
    );
  }

  getQueryCodes(
    typeDocument: string,
    document: string
  ): Observable<Response<ContractRequest>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const params = {
      typeDocument: typeDocument,
      document: document,
    };
    return this.http
      .get(`${this.urlApi}contract/getQueryCode`, { headers, params })
      .pipe(
        map((resp: Response<ContractRequest>) => {
          return resp;
        })
      );
  }

  // getStateContract
  public getStateContract(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.urlApi}contract/getStateContract`, options);
  }


  getQueryCodesEdit(
    affiliate: string,
  ): Observable<Response<ContractRequest>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    const params = {
      queryCode: affiliate
    };
    return this.http
      .get(`${this.urlApi}contract/getQueryCodeEdit`, { headers, params })
      .pipe(
        map((resp: Response<ContractRequest>) => {
          return resp;
        })
      );
  }

  createRadication(
    radicacion: RequestCredit
  ): Observable<ResponseObject<RequestCredit>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers: headers,
    };
    const body = radicacion;
    console.log(body)
    return this.http
      .post(`${this.urlApi}requestStudy/save`, body, options)
      .pipe(
        map((resp: ResponseObject<RequestCredit>) => {
          return resp;
        })
      );
  }

  getEmployee(): Observable<EmployeePerson[]> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(`${this.urlApi}ListEmployee/GetAll`, { headers })
      .pipe(
        map((resp: EmployeePerson[]) => {
          return resp;
        })
      );
  }
}
