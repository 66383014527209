import { Component, DoCheck, Input, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractAsrTemp } from "@app/models/ContractAsrTemp.model";
import { Response } from "@app/models/responseUtil.model";
import { ContractService } from "@app/services/contract.service";
import { ModalService } from "@app/services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { SelectModel, SelectModelSubGroup } from "../../../models/select.model";
import { SelectsService } from "@app/services/selects.service";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "@app/redux/app.reducer";
import { HelperService } from "@app/services/helper.service";
import { AffiliateAsrTemp } from "@app/models/affiliateAsrTemp.model";
import { Adviser } from "@app/models/adviser.model";
import { AffiliateAsrTempPK } from "@app/models/compositeKey/AffiliateAsrTempPK.model";
import Swal from "sweetalert2";
import { FormContractService } from "../services/form-contract.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-contract",
  templateUrl: "./add-contract.component.html",
  styleUrls: ["./add-contract.component.scss"],
})
export class AddContractComponent implements OnInit, DoCheck, OnDestroy {
  public contractForm: FormGroup;

  @Input() stepperNext: any;

  listBillingType = [
    { key: "CONSOLIDADA", description: "Consolidada" },
    { key: "INDIVIDUAL", description: "Individual" },
  ];

  listBillingPeriod = [
    { key: "SEMESTRAL", description: "Semestral" },
    { key: "BIMENSUAL", description: "Bimestral" },
    { key: "TRIMESTRAL", description: "Trimestral" },
    { key: "MENSUAL", description: "Mensual" },
    { key: "ANUAL", description: "Anual" },
  ];

  listBillingDay = [
    { key: 1, description: "1" },
    { key: 2, description: "2" },
    { key: 10, description: "10" },
    { key: 12, description: "12" },
    { key: 14, description: "14" },
    { key: 15, description: "15" },
    { key: 18, description: "18" },
    { key: 20, description: "20" },
    { key: 26, description: "26" },
    { key: 29, description: "29" },
  ];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public selectOptionsProduct;
  public selectOptionsAdviser;
  public selectOptionsDocumentTypes: SelectModel[] = [];
  public selectOptionsBranchOffices: SelectModel[] = [];
  public selectOptionsSocietyTypes: SelectModel[] = [];
  public listEconomicActivity: SelectModelSubGroup[] = [];
  public listSubSectorActivity: SelectModel[] = [];

  public selectContract: any;

  listLegalRep: any[] = [];

  validLegalRep: boolean = false;
  validAffiliate: boolean = false;

  numberContract = 0;

  nameAdviser = "";
  userAdviser = "";
  paramsIndex;
  contractAsrTempGlobal: ContractAsrTemp = null;
  affiliateAsrTemp: AffiliateAsrTemp = null;

  validChange = false;
  validRepLegal = false;

  public min: any;
  public max;

  affiliateIdTempGlobal: number = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public modal: ModalService,
    private contractService: ContractService,
    public formService: FormContractService,
    public selects: SelectsService,
    private store: Store<AppState>,
    public helper: HelperService
  ) {
    this.selectOptionsProduct = [];
    this.contractForm = this.fb.group({
      documentType: [{ value: "", disabled: true }, Validators.required],
      document: [
        { value: "", disabled: true },
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ],
      ],
      nameCompany: [""],
      socialReason: ["", Validators.required],
      societyType: ["", Validators.required],
      coverage: [{ value: "Nacional", disabled: true }, Validators.required],
      firstName: ["", Validators.required],
      secondName: [""],
      firstLastName: ["", Validators.required],
      secondLastName: [""],
      economicActivity: ["", Validators.required],
      subSector: ["", Validators.required],
      branchOffices: ["", Validators.required],
      product: ["", Validators.required],
      affiliation: ["", Validators.required],
      adviser: ["", Validators.required],
      billingType: ["CONSOLIDADA", Validators.required],
      billingPeriod: ["MENSUAL", Validators.required],
      billingDay: [26, Validators.required],
    });

    this.paramsIndex = this.contractService.getParamsIndex();
    this.f.subSector.disable();
    this.f.socialReason.disable();
    this.f.societyType.disable();
  }

  ngOnInit() {
    this.formService.init();
    this.store
      .select("selects")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ selects }) => this.getSelects(selects));
    this.getProducts();
    this.listEconomicActivity = this.formService.listEconomicActivity;

    if (this.listEconomicActivity && this.listEconomicActivity.length === 0) {
      this.listEconomicActivity = JSON.parse(
        localStorage.getItem("listEconomicActivity")
      );
    }
    this.selectContract = JSON.parse(localStorage.getItem("covi-utils"));
    this.numberContract = Number(localStorage.getItem("numberContract"));

    if (this.formService.user == null) {
      this.router.navigate(["/"]);
    }

    this.validTypeContract();

    this.f.economicActivity.valueChanges.subscribe((value) => {
      this.selectPage(value);
    });

    if (this.selectContract) {
      this.selectOptionsSocietyTypes = this.selectContract.societyTypes;
      this.selectOptionsDocumentTypes = this.selectContract.documentTypesCode;
    }

    this.f.adviser.valueChanges.subscribe((resp) => {
      this.getName(resp);
    });

    this.min = new Date(2000, 1, 1);
    this.max = new Date();
  }

  ngDoCheck(): void {
    if (this.modal.modal.addRepLegalModal === false && !this.validRepLegal) {
      if (localStorage.getItem("listRepLegal"))
        this.listLegalRep = JSON.parse(localStorage.getItem("listRepLegal"));
      this.validRepLegal = true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contractForm.controls;
  }

  validTypeContract() {
    if (this.numberContract && this.formService.status == false) {
      this.getAdviserActive();
      this.getContract();
    } else if (this.numberContract && this.formService.status) {
      this.getAdviser();
      this.getContractReal();
    } else if (
      this.paramsIndex &&
      this.paramsIndex.numDoc &&
      this.paramsIndex.typeDoc
    ) {
      this.getAffiliateReal();
      this.getAdviserActive();
    }
  }

  showModalRepLegal() {
    this.validRepLegal = false;
    this.modal.show("addRepLegalModal", {
      label: `Crear`,
      edit: false,
    });
  }

  showEditModalRepLegal(item) {
    this.validRepLegal = false;
    localStorage.setItem("itemRepLegal", JSON.stringify(item));
    this.modal.show("addRepLegalModal", {
      label: `Editar`,
      edit: true,
    });
  }

  selectPage(idx: any) {
    let data = this.listEconomicActivity.find((element) => element.id == idx);
    if (data) {
      this.f.subSector.enable();
      this.f.subSector.setValue("");
      this.listSubSectorActivity = data.subgroups;
    }
  }

  cleanAffiliate() {
    this.f.firstName.setValue(null);
    this.f.secondName.setValue(null);
    this.f.firstLastName.setValue(null);
    this.f.secondLastName.setValue(null);
  }

  selectDocType(idx: any) {
    this.cleanAffiliate();

    if (idx === "C") {
      this.validLegalRep = false;
      this.validAffiliate = true;
      this.f.socialReason.disable();
      this.f.societyType.disable();
      this.f.firstName.enable();
      this.f.secondName.enable();
      this.f.firstLastName.enable();
      this.f.secondLastName.enable();
    } else if (idx === "N") {
      this.validAffiliate = false;
      this.validLegalRep = true;
      this.f.socialReason.enable();
      this.f.societyType.enable();
      this.f.firstName.disable();
      this.f.secondName.disable();
      this.f.firstLastName.disable();
      this.f.secondLastName.disable();
    } else {
      this.validAffiliate = false;
      this.validLegalRep = false;
    }
  }

  getName(id: number) {
    let selectAdviser = JSON.parse(localStorage.getItem("adviser"));
    let name;
    if (this.formService.status) {
      name = selectAdviser.find((element) => element.id == id);
    } else {
      name = selectAdviser.find((element) => element.id == id);
    }
    this.nameAdviser = name ? name.names.slice(3) : "";
    this.userAdviser = name ? name.user : "";
  }

  mapperService(): ContractAsrTemp {
    let contractAsrTemp: ContractAsrTemp = new ContractAsrTemp();
    contractAsrTemp.branchOffices = this.f.branchOffices.value;
    contractAsrTemp.product = this.f.product.value;
    contractAsrTemp.affiliationDate = this.f.affiliation.value;
    this.formService.dateCreateContract = this.f.affiliation.value;
    contractAsrTemp.idAdviser = this.f.adviser.value;
    contractAsrTemp.advisorName = this.nameAdviser;
    contractAsrTemp.advisorUser = this.userAdviser;
    contractAsrTemp.billingType = this.f.billingType.value;
    contractAsrTemp.billingPeriod = this.f.billingPeriod.value;
    contractAsrTemp.billingDay = this.f.billingDay.value;

    let affiliateAsrTemp: AffiliateAsrTemp = new AffiliateAsrTemp();
    let affiliateAsrTempPK: AffiliateAsrTempPK = new AffiliateAsrTempPK();
    if (
      this.contractAsrTempGlobal &&
      this.contractAsrTempGlobal.affiliateAsrTemp &&
      this.contractAsrTempGlobal.affiliateAsrTemp.id
    ) {
      affiliateAsrTemp.id = this.contractAsrTempGlobal.affiliateAsrTemp.id;
    } else if (this.affiliateIdTempGlobal) {
      affiliateAsrTemp.id = this.affiliateIdTempGlobal;
    }
    affiliateAsrTempPK.document = this.f.document.value;
    affiliateAsrTempPK.documentType = this.f.documentType.value;
    affiliateAsrTemp.affiliateAsrTempPK = affiliateAsrTempPK;
    if (this.f.documentType.value === "N") {
      affiliateAsrTemp.socialReason = this.helper
        .trimValue(this.f.socialReason.value)
        .toUpperCase();
    } else if (this.f.documentType.value === "C") {
      affiliateAsrTemp.firstName = this.helper
        .trimValue(this.f.firstName.value)
        .toUpperCase();
      affiliateAsrTemp.secondName = this.f.secondName.value
        ? this.helper.trimValue(this.f.secondName.value).toUpperCase()
        : "";
      affiliateAsrTemp.firstLastName = this.helper
        .trimValue(this.f.firstLastName.value)
        .toUpperCase();
      affiliateAsrTemp.secondLastName = this.f.secondLastName.value
        ? this.helper.trimValue(this.f.secondLastName.value).toUpperCase()
        : "";
    }
    affiliateAsrTemp.societyType = this.f.societyType.value;
    affiliateAsrTemp.coverage = this.f.coverage.value;
    affiliateAsrTemp.economicActivity = this.f.economicActivity.value;
    affiliateAsrTemp.subSector = this.f.subSector.value;
    affiliateAsrTemp.economicActivityName = this.nameEconomic(
      this.f.economicActivity.value
    );
    affiliateAsrTemp.subSectorName = this.nameSubsector(
      this.f.subSector.value,
      this.f.economicActivity.value
    );
    affiliateAsrTemp.affiliationDate = new Date();

    if (this.listLegalRep && this.listLegalRep.length > 0) {
      affiliateAsrTemp.rePreLegalAsrTemps = this.listLegalRep;
    }

    if (this.contractAsrTempGlobal)
      contractAsrTemp.numberContract =
        this.contractAsrTempGlobal.numberContract;

    contractAsrTemp.affiliateAsrTemp = affiliateAsrTemp;
    contractAsrTemp.nombreComercial = this.f.nameCompany.value;

    return contractAsrTemp;
  }

  nameEconomic(id): string {
    let name = this.listEconomicActivity.find((element) => element.id == id);
    return name ? name.name : "";
  }

  nameSubsector(id, activityId): string {
    let name = this.listEconomicActivity.find(
      (element) => element.id == activityId
    );

    let subName = name
      ? name.subgroups.find((element) => element.value == id)
      : null;

    return subName ? subName.description : "";
  }

  stepOneSubmit() {
    console.log(JSON.stringify(this.mapperService()));
    if (this.formService.status) {
      this.createContract(this.mapperService());
    } else {
      this.createContractTemp(this.mapperService());
    }
  }

  getSelects(selects?: any) {
    this.selectOptionsBranchOffices = selects.branchOffices || [];
  }

  getProducts() {
    this.spinner.show();
    this.contractService.getProduct().subscribe(
      (resp: Response<any>) => {
        if (resp.ok && resp.data) {
          this.formService.status
            ? (this.selectOptionsProduct = resp.data[0].product)
            : (this.selectOptionsProduct = resp.data[0].productActive);
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.selectOptionsProduct = [];
        this.spinner.hide();
      }
    );
  }

  getAdviserActive() {
    this.spinner.show();
    this.contractService.getAdviserActive().subscribe(
      (resp: Response<Adviser>) => {
        if (resp.ok && resp.data) {
          localStorage.setItem("adviser", JSON.stringify(resp.data[0]));
          this.selectOptionsAdviser = resp.data[0];
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.selectOptionsAdviser = [];
        this.spinner.hide();
      }
    );
  }

  getAdviser() {
    this.spinner.show();
    this.contractService.getAdviser().subscribe(
      (resp: Response<Adviser>) => {
        if (resp.ok && resp.data) {
          localStorage.setItem("adviser", JSON.stringify(resp.data[0]));
          this.selectOptionsAdviser = resp.data[0];
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.selectOptionsAdviser = [];
        this.spinner.hide();
      }
    );
  }

  /* GET AND POST INFORMATION OF THE CONTRACT */

  createContractTemp(contractAsrTemp: ContractAsrTemp) {
    this.spinner.show();
    this.contractService.postContractAsrTemp(contractAsrTemp).subscribe(
      (resp: Response<ContractAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.saveMemory(resp, contractAsrTemp);
          Swal.fire({
            type: "success",
            title: "Transacción exitosa",
            text: `El contrato ${resp.data[0].numberContract} ha sido creado correctamente.`,
          }).then(() => {
            this.formService.setCurrentStep(1);
            this.stepperNext.next();
          });
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  createContract(contractAsrTemp: ContractAsrTemp) {
    this.spinner.show();
    this.contractService.postContractReal(contractAsrTemp).subscribe(
      (resp: Response<ContractAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.saveMemory(resp, contractAsrTemp);
          Swal.fire({
            type: "success",
            title: "Transacción exitosa",
            text: `El contrato ${resp.data[0].numberContract} ha sido creado correctamente.`,
          }).then(() => {
            this.formService.setCurrentStep(1);
            this.stepperNext.next();
          });
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  /*Contract is temporal*/
  getContract() {
    this.spinner.show();
    this.contractService.getContractByNumber(this.numberContract).subscribe(
      (resp: Response<ContractAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.contractAsrTempGlobal = resp.data[0];
          this.setValueForm();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }
  /*Contract is real */
  getContractReal() {
    this.spinner.show();
    this.contractService.getContractRealByNumber(this.numberContract).subscribe(
      (resp: Response<ContractAsrTemp>) => {
        if (resp.ok && resp.data) {
          this.contractAsrTempGlobal = resp.data[0];
          this.setValueForm();
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }
  /*Contract is new*/
  getAffiliate() {
    this.f.documentType.setValue(this.paramsIndex.typeDoc);
    this.f.document.setValue(this.paramsIndex.numDoc);
    this.selectDocType(this.paramsIndex.typeDoc);
    this.contractService
      .getPersonAsrTempByDocument(
        this.paramsIndex.typeDoc,
        this.paramsIndex.numDoc
      )
      .subscribe(
        (resp: Response<AffiliateAsrTemp>) => {
          if (resp.ok && resp.data) {
            this.affiliateAsrTemp = resp.data[0];
            this.setValueFormAffiliate();
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }
  /*If affiliate have more contract real get information of table real */
  getAffiliateReal() {
    this.f.documentType.setValue(this.paramsIndex.typeDoc);
    this.f.document.setValue(this.paramsIndex.numDoc);
    this.selectDocType(this.paramsIndex.typeDoc);
    this.contractService
      .getPersonByDocument(this.paramsIndex.typeDoc, this.paramsIndex.numDoc)
      .subscribe(
        (resp: Response<AffiliateAsrTemp>) => {
          if (resp.ok && resp.data) {
            this.affiliateAsrTemp = resp.data[0];
            this.setValueFormAffiliate();
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.getAffiliate();
        }
      );
  }

  getActivityBySubsector(codActivity: number) {
    this.spinner.show();
    this.contractService.getSelectActivityGroup(codActivity).subscribe(
      (resp: Response<any>) => {
        if (resp.ok && resp.data) {
          let activity: SelectModel = resp.data[0];
          this.f.economicActivity.setValue(activity.value);
          this.selectPage(activity.value);
          this.f.subSector.setValue(
            this.contractAsrTempGlobal.affiliateAsrTemp.subSector
          );
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  /* SET VALUE OF FORM */
  setValueForm() {
    this.selectDocType(
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK
        .documentType
    );
    this.f.documentType.setValue(
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK
        .documentType
    );
    this.f.document.setValue(
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK.document
    );

    this.getActivityBySubsector(
      Number(this.contractAsrTempGlobal.affiliateAsrTemp.subSector)
    );
    this.f.branchOffices.setValue(this.contractAsrTempGlobal.branchOffices);
    this.f.product.setValue(this.contractAsrTempGlobal.product);

    this.formService.productName = this.contractAsrTempGlobal.product;

    this.f.affiliation.setValue(this.contractAsrTempGlobal.affiliationDate);
    this.f.adviser.setValue(this.contractAsrTempGlobal.idAdviser);
    this.getAdviser();
    this.f.billingType.setValue(this.contractAsrTempGlobal.billingType);
    this.f.billingPeriod.setValue(this.contractAsrTempGlobal.billingPeriod);
    this.f.billingDay.setValue(this.contractAsrTempGlobal.billingDay);
    this.f.nameCompany.setValue(this.contractAsrTempGlobal.nombreComercial);
    if (
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK
        .documentType === "C"
    ) {
      this.f.firstName.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.firstName
      );
      this.f.secondName.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.secondName
      );
      this.f.firstLastName.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.firstLastName
      );
      this.f.secondLastName.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.secondLastName
      );
    } else if (
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK
        .documentType === "N"
    ) {
      this.f.coverage.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.coverage || 'NACIONAL'
      );
      this.f.socialReason.setValue(
        this.contractAsrTempGlobal.affiliateAsrTemp.socialReason
      );
      let societyType = this.formService.getSocietyTypes(
        this.contractAsrTempGlobal.affiliateAsrTemp.societyType
      );
      this.f.societyType.setValue(societyType);

      if (
        this.contractAsrTempGlobal.affiliateAsrTemp.rePreLegalAsrTemps &&
        this.contractAsrTempGlobal.affiliateAsrTemp.rePreLegalAsrTemps.length >
          0
      ) {
        this.listLegalRep =
          this.contractAsrTempGlobal.affiliateAsrTemp.rePreLegalAsrTemps;
        localStorage.setItem("listRepLegal", JSON.stringify(this.listLegalRep));
      }
    }

    this.formService.dateCreateContract =
      this.contractAsrTempGlobal.affiliationDate;
    this.saveSocialReazonLocal(
      this.contractAsrTempGlobal.affiliateAsrTemp.affiliateAsrTempPK
        .documentType === "N"
        ? this.contractAsrTempGlobal.affiliateAsrTemp.socialReason
        : this.nameFormatter(this.contractAsrTempGlobal.affiliateAsrTemp)
    );
    this.saveDataAffiliate(this.contractAsrTempGlobal);
  }

  setValueFormAffiliate() {
    this.affiliateIdTempGlobal = this.affiliateAsrTemp.id;
    this.f.economicActivity.setValue(this.affiliateAsrTemp.economicActivity);
    this.f.subSector.setValue(this.affiliateAsrTemp.subSector);
    if (this.affiliateAsrTemp.affiliateAsrTempPK.documentType === "C") {
      this.f.firstName.setValue(this.affiliateAsrTemp.firstName);
      this.f.secondName.setValue(this.affiliateAsrTemp.secondName);
      this.f.firstLastName.setValue(this.affiliateAsrTemp.firstLastName);
      this.f.secondLastName.setValue(this.affiliateAsrTemp.secondLastName);
    } else if (this.affiliateAsrTemp.affiliateAsrTempPK.documentType === "N") {
      this.f.coverage.setValue(this.affiliateAsrTemp.coverage || 'NACIONAL');
      this.f.socialReason.setValue(this.affiliateAsrTemp.socialReason);
      this.f.societyType.setValue(this.affiliateAsrTemp.societyType);

      if (
        this.affiliateAsrTemp.rePreLegalAsrTemps &&
        this.affiliateAsrTemp.rePreLegalAsrTemps.length > 0
      ) {
        this.listLegalRep = this.affiliateAsrTemp.rePreLegalAsrTemps;
        localStorage.setItem("listRepLegal", JSON.stringify(this.listLegalRep));
      }
    }
  }

  saveSocialReazonLocal(data: string) {
    localStorage.setItem("socialReason", data);
  }

  saveDataAffiliate(contract: ContractAsrTemp) {
    localStorage.setItem(
      "AffiliateData",
      JSON.stringify(contract.affiliateAsrTemp.affiliateAsrTempPK)
    );
  }

  nameFormatter(affiliateAsrTemp: AffiliateAsrTemp) {
    return `${affiliateAsrTemp.firstName} ${affiliateAsrTemp.secondName} ${affiliateAsrTemp.firstLastName} ${affiliateAsrTemp.secondLastName}`;
  }

  saveMemory(resp, contractAsrTemp: ContractAsrTemp) {
    this.validChange = false;
    localStorage.setItem("numberContract", String(resp.data[0].numberContract));
    this.saveSocialReazonLocal(
      contractAsrTemp.affiliateAsrTemp.affiliateAsrTempPK.documentType === "N"
        ? contractAsrTemp.affiliateAsrTemp.socialReason
        : this.nameFormatter(contractAsrTemp.affiliateAsrTemp)
    );
    this.saveDataAffiliate(contractAsrTemp);
    this.formService.productName = resp.data[0].product;
    this.formService.setContract(resp.data[0].numberContract);
  }
}
