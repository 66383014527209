import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: "app-modal-params-affiliate",
  templateUrl: "./modal-params-affiliate.component.html",
  styleUrls: ["./modal-params-affiliate.component.scss"],
})
export class ModalParamsAffiliateComponent implements OnInit {
  public economicCondForm: FormGroup;

  constructor(public modal: ModalService, private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.economicCondForm = this.fb.group({
      formContract: ["0"],
      queryLevel: [{ value: "99999999", disabled: true }],
      fixedCost: [{ value: "0", disabled: true }],
      percentage: ["", Validators.required],
      valueTx: ["0", Validators.required],
      valueNum: ["0", Validators.required],
      valueNegated: ["0"],
      startDate: [],
      endDate: [],
      period: ["MENSUAL"],
      conditions: [{ value: "Servicio Factura", disabled: true }],
      quotas: [{ value: "0", disabled: true }],
      transactionTop: [{ value: "0", disabled: true }],
      noticeOfNonPaymentOperative: [{ value: "0", disabled: true }],
      claimDays: [{ value: "0", disabled: true }],
      refundDays: [{ value: "0", disabled: true }],
      deductible: [{ value: "0", disabled: true }],
      totalContractQuota: ["0"],
      Periodicity: [{ value: "0", disabled: true }],
    });
  }

  get f() {
    return this.economicCondForm.controls;
  }

  closeModal() {
    this.modal.close();
  }

  onSubmit() {}
}
