import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Response } from "@app/models/responseUtil.model";
import { ContractService } from "@app/services/contract.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { SelectModel } from "../../../models/select.model";
import { SelectsService } from "@app/services/selects.service";
import { ProductSelect } from "@app/models/productSelect.model";
import { Adviser } from "@app/models/adviser.model";
import { AffiliateAsrTemp } from "@app/models/affiliateAsrTemp.model";
import { HelperService } from "@app/services/helper.service";
import { FormContractService } from "../services/form-contract.service";
import { ContractResponse } from "@app/models/response/ContractResponse.model";
@Component({
  selector: 'app-consult-index',
  templateUrl: './consult-index.component.html',
  styleUrls: ['./consult-index.component.scss'],
})
export class ConsultIndexComponent implements OnInit, OnDestroy {
  public typeDoc: string = 'N';
  public numDoc: number = 8600284621;
  public listContracts;
  validContat = false;
  public cumple: boolean = false;
  responseData: any;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  public selectOptionsDocumentTypes: SelectModel[] = [];
  public selectOptionsAdviser;
  public selectOptionsAffiliate;
  public selectOptionsProduct: string[];
  public selectContract: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    public selects: SelectsService,
    public helper: HelperService, // private authNitService: AuthNitService
    private formService: FormContractService
  ) {
    this.validContat = false;
    this.listContracts = [];

    if (!localStorage.getItem('covi-utils')) {
      this.getDataListCoviUtils();
    } else {
      this.getDocumentType();
    }
  }

  ngOnInit() {
    this.getTaxItem();
    this.getProducts();
    this.getAdviser();
    if (!localStorage.getItem('listEconomicActivity'))
      this.getActivityAndGroups();
    if (!localStorage.getItem('form20m_municipalitiesList'))
      this.getDepartmentsMunicipalities();
    this.formService.status = false;
    this.formService.setCurrentStep(0);

    localStorage.removeItem('numberContract');
    localStorage.removeItem('listContractAddress');
    localStorage.removeItem('listContractPhones');
    localStorage.removeItem('listRepLegal');
    localStorage.removeItem('AffiliateData');
    localStorage.removeItem('socialReason');
    localStorage.removeItem('numberProduct');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getContracts() {
    this.listContracts = [];
    if (this.typeDoc && this.numDoc) {
      this.spinner.show();
      const paramsIndex = {
        typeDoc: this.typeDoc,
        numDoc: this.numDoc,
      };
      this.contractService.setParamsIndex(paramsIndex);
      this.contractService
        .getContractAsrTempByDocument(this.typeDoc, this.numDoc)
        .subscribe(
          (resp: Response<ContractResponse>) => {
            if (resp.ok && resp.data) {
              this.validContat = true;
              let contract = resp.data[0].contracts;
              let contractTemp = resp.data[0].contractAsrTemps;
              if (contract.length > 0)
                this.listContracts = this.listContracts.concat(contract);
              if (contractTemp.length > 0)
                this.listContracts = this.listContracts.concat(contractTemp);
            }
            this.spinner.hide();
          },
          (error: any) => {
            console.log(error);
            this.validContat = true;
            this.listContracts = [];
            this.spinner.hide();
          }
        );
    }
  }

  getDataListCoviUtils() {
    this.spinner.show();
    this.contractService.getSelectData().subscribe(
      (resp: Response<any>) => {
        this.getDocumentType();
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getDocumentType() {
    this.selectContract = JSON.parse(localStorage.getItem('covi-utils'));
    if (this.selectContract) {
      this.selectContract.documentTypesCode.forEach((element) => {
        if (
          !(
            element.value === 'P' ||
            element.value === 'T' ||
            element.value === 'E'
          )
        )
          this.selectOptionsDocumentTypes.push(element);
      });
    }
  }

  editContract(id: number, status: boolean) {
    this.formService.status = status;
    localStorage.setItem('status', String(status));
    localStorage.setItem('numberContract', String(id));
    this.router.navigate(['pages/contrato/stepper']);
  }

  redirectContract() {
    this.router.navigate(['pages/contrato/stepper']);
  }

  getProducts() {
    this.spinner.show();
    this.contractService.getProduct().subscribe(
      (resp: Response<string[]>) => {
        if (resp.ok && resp.data) {
          this.selectOptionsProduct = resp.data[0];
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.selectOptionsProduct = [];
        this.spinner.hide();
      }
    );
  }

  getActivityAndGroups() {
    this.spinner.show();
    this.contractService.getSelectActivityAndGroup().subscribe(
      (resp: Response<any>) => {
        if (resp.ok && resp.data) {
          this.formService.listEconomicActivity = resp.data[0];
          localStorage.setItem(
            'listEconomicActivity',
            JSON.stringify(resp.data[0])
          );
        }
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getAdviser() {
    this.spinner.show();
    this.contractService.getAdviser().subscribe(
      (resp: Response<Adviser>) => {
        if (resp.ok && resp.data) {
          this.selectOptionsAdviser = resp.data[0];
        }
        this.spinner.hide();
      },

      (error: any) => {
        console.log(error);
        this.selectOptionsAdviser = [];
        this.spinner.hide();
      }
    );
  }

  getAdviserTemp(idAdviser: string, status: boolean): string {
    let adviser;
    if (status) {
      adviser = this.selectOptionsAdviser.find(
        (element) => element.user === idAdviser
      );
    } else {
      adviser = this.selectOptionsAdviser.find(
        (element) => element.id === Number(idAdviser)
      );
    }

    return adviser ? adviser.names.slice(3) : '';
  }

  getAffiliateAsrTemp(idAffiliate: string): string {
    let affiliate = this.selectOptionsAffiliate.find((element) => {
      if (element.id === Number(idAffiliate)) return element;
    });
    return affiliate.id;
  }

  getProductTemp(idProduct: string): string {
    let product = this.selectOptionsProduct.find(
      (element) => element == idProduct
    );
    return product ? product : '';
  }

  getNameAffiliate(affiliate: AffiliateAsrTemp) {
    if (!affiliate) {
      return '';
    }

    return affiliate.affiliateAsrTempPK.documentType === 'N'
      ? affiliate.socialReason
      : `${affiliate.firstName} ${affiliate.firstLastName}`;
  }

  getTaxItem() {
    this.contractService.getAllCovifacturaTax().subscribe(
      (resp) => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getDepartmentsMunicipalities() {
    this.contractService.getDepartmentsCities().subscribe();
  }
}
