import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContractEmailAsrTemp } from "@app/models/contractEmailAsrTemp.model";
import { ContractService } from "@app/services/contract.service";
import { ModalService } from "@app/services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Response } from "@app/models/responseUtil.model";
import { AddressAsrTemp } from "@app/models/addressAsrTemp.model";
import { PhoneAsrTemp } from "@app/models/phoneAsrTemp.model";
import Swal from "sweetalert2";
import { FormContractService } from "../services/form-contract.service";
import { sequence } from "@angular/animations";

@Component({
  selector: "app-add-affiliate-detail",
  templateUrl: "./add-affiliate-detail.component.html",
  styleUrls: ["./add-affiliate-detail.component.scss"],
})
export class AddAffiliateDetailComponent implements OnInit {
  public affiliateDetailForm: FormGroup;

  @Input() stepperNext: any;

  public dataTableAddress: Array<AddressAsrTemp> = [];
  public dataTablePhones: PhoneAsrTemp[] = [];
  validChangeAddress = false;
  validChangePhones = false;

  validDisabledAddress = true;
  validDisabledPhones = true;

  numberContract = 0;

  validIndicative: boolean = false;

  public selectContract: any;

  contractEmailAsrTempGlobal: ContractEmailAsrTemp = null;

  public get isValidDataTableAddres(): boolean {
    return this.dataTableAddress.length <= 0;
  }

  public get isValidDataTablePhones(): boolean {
    return this.dataTablePhones.length <= 0;
  }

  listPhoneType = [
    { key: "TEL", description: "Tel" },
    { key: "CEL", description: "Cel" },
    { key: "FAX", description: "Fax" },
  ];

  listAddressType = [
    { key: "OFICINA", description: "Oficina" },
    { key: "RESIDENCIA", description: "Residencia" },
    { key: "REFERENCIA", description: "DIRECCION DE REFERENCIA FAM" },
  ];

  isReal: Boolean = false;

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private formService: FormContractService
  ) {
    this.isReal = formService.status;
    this.affiliateDetailForm = this.fb.group({
      mainEmail: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      ownerMainEmail: ["", Validators.required],
      secondaryEmail: [
        "",
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
      ownerSecondEmail: [""],
      emailFE: [
        "",
        [
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      ownerEmailFE: [""],
    });
  }

  ngOnInit() {
    this.numberContract = Number(localStorage.getItem("numberContract"));
    this.selectContract = JSON.parse(localStorage.getItem("covi-utils"));
    this.getData();
    if (this.modal.modal.addAddressModal === false)
      delete this.modal.modal.addAddressModal;

    if (this.modal.modal.addPhonesModal === false)
      delete this.modal.modal.addPhonesModal;

    this.formService.currentStep$.subscribe((current) => {
      if (current >= 1) {
        this.validDisabledAddress = false;
        this.validDisabledPhones = false;
      }
    });

    this.disableForm();
  }

  getData() {
    if (this.numberContract) {
      this.getContractAddress();
      this.getContractPhones();
      this.getContractEmail();
    }
  }

  ngDoCheck(): void {
    if (
      this.modal.modal.addAddressModal === false &&
      !this.validChangeAddress
    ) {
      this.getData();
      this.validChangeAddress = true;
    }

    if (this.modal.modal.addPhonesModal === false && !this.validChangePhones) {
      this.getData();
      this.validChangePhones = true;
    }
  }

  get f() {
    return this.affiliateDetailForm.controls;
  }

  showModalAddAddress() {
    this.validChangeAddress = false;
    this.modal.show("addAddressModal", {
      label: `Crear`,
    });
  }

  showEditModalAddAddress(itemAddress) {
    localStorage.setItem("itemAddress", JSON.stringify(itemAddress));
    this.validChangeAddress = false;
    this.modal.show("addAddressModal", {
      label: `Editar`,
    });
  }

  showModalAddPhone() {
    this.validChangePhones = false;
    this.modal.show("addPhonesModal", {
      label: `Crear`,
    });
  }

  showModalEditAddPhone(itemPhone) {
    localStorage.setItem("itemPhone", JSON.stringify(itemPhone));
    this.validChangePhones = false;
    this.modal.show("addPhonesModal", {
      label: `Editar`,
    });
  }

  mapperService(): ContractEmailAsrTemp {
    let contractEmailAsrTemp: ContractEmailAsrTemp = new ContractEmailAsrTemp();
    contractEmailAsrTemp.mainEmail = this.f.mainEmail.value;
    contractEmailAsrTemp.ownerMainEmail = this.f.ownerMainEmail.value;
    contractEmailAsrTemp.secondaryEmail = this.f.secondaryEmail.value;
    contractEmailAsrTemp.ownerEmailFE = this.f.ownerEmailFE.value;
    contractEmailAsrTemp.ownerSecondEmail = this.f.ownerSecondEmail.value;
    contractEmailAsrTemp.emailFE = this.f.emailFE.value;
    contractEmailAsrTemp.idContract = this.numberContract;

    if (this.contractEmailAsrTempGlobal && this.contractEmailAsrTempGlobal.id)
      contractEmailAsrTemp.id = this.contractEmailAsrTempGlobal.id;
    console.log(contractEmailAsrTemp);
    return contractEmailAsrTemp;
  }

  createContractEmail(contractEmailAsrTemp: ContractEmailAsrTemp) {
    this.spinner.show();
    this.contractService
      .postContractEmailAsrTemp(contractEmailAsrTemp)
      .subscribe(
        (resp: Response<ContractEmailAsrTemp>) => {
          if (resp.ok && resp.data) {
            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
            }).then(() => {
              this.formService.setCurrentStep(2);
              this.stepperNext.next();
            });
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  getContractAddress() {
    this.spinner.show();
    this.contractService
      .getContractAddressByNumber(this.numberContract)
      .subscribe(
        (resp: Response<AddressAsrTemp[]>) => {
          if (resp.ok && resp.data) {
            this.dataTableAddress = resp.data[0];
            localStorage.setItem(
              "listContractAddress",
              JSON.stringify(this.dataTableAddress)
            );
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.dataTableAddress = [];
          this.spinner.hide();
        }
      );
  }

  getContractPhones() {
    this.spinner.show();
    this.contractService
      .getContractPhoneByNumber(this.numberContract)
      .subscribe(
        (resp: Response<PhoneAsrTemp[]>) => {
          if (resp.ok && resp.data) {
            this.dataTablePhones = resp.data[0];
            localStorage.setItem(
              "listContractPhones",
              JSON.stringify(this.dataTablePhones)
            );
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.dataTablePhones = [];
          this.spinner.hide();
        }
      );
  }

  getContractEmail() {
    this.spinner.show();
    this.contractService
      .getContractEmailtByNumber(this.numberContract)
      .subscribe(
        (resp: Response<ContractEmailAsrTemp>) => {
          if (resp.ok && resp.data) {
            this.contractEmailAsrTempGlobal = resp.data[0];
            this.f.mainEmail.setValue(resp.data[0].mainEmail);
            this.f.ownerMainEmail.setValue(resp.data[0].ownerMainEmail);
            this.f.secondaryEmail.setValue(resp.data[0].secondaryEmail);
            this.f.ownerSecondEmail.setValue(resp.data[0].ownerSecondEmail);
            this.f.emailFE.setValue(resp.data[0].emailFE);
            this.f.ownerEmailFE.setValue(resp.data[0].ownerEmailFE);
          }
          this.spinner.hide();
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  stepTwoSubmit() {
    this.createContractEmail(this.mapperService());
  }

  getTypePhone(id: string): string {
    if (id) {
      let typePhone;
      typePhone = this.listPhoneType.find((element) => element.key === id);

      return typePhone ? typePhone.description : "";
    }
    return null;
  }

  getTypeAddress(id: string): string {
    let typeAddress = this.listAddressType.find(
      (element) => element.key === id
    );

    return typeAddress ? typeAddress.description : "";
  }

  disableForm() {
    if (this.validDisabledPhones && this.validDisabledAddress) {
      this.affiliateDetailForm.disable();
    }
  }
}
