import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComunicationService {
  currentPage = 1;
  sendDataEvent = new EventEmitter<any>();
  sendNullRegister = new EventEmitter<any>();
  sendDataHistoryEvent = new EventEmitter<any>();
  sendData(data: any) {
    this.sendDataEvent.emit(data);
  }
  sendDataNullRegister(data: boolean) {
    this.sendNullRegister.emit(data);
  }
  sendDataHistory(dataHistory: any) {
    this.sendDataHistoryEvent.emit(dataHistory);
  }
  constructor() {
    //console.log('La variable en comunicación es', this.sendNullRegister);
  }
}
