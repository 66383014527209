import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';

export interface ITypeDoc {
  description: string
  state: string
  value: string
}

export interface IQueryForm {
  documentType: string
  document: string
  lastname: string
  includeScore: boolean
}

export type originTypeForm = '' | 'Reconocer' | 'HDC' | 'HDC_PJ';

@Component({
  selector: 'app-query-form-history',
  templateUrl: './query-form-history.component.html',
  styleUrls: ['./query-form-history.component.scss']
})
export class QueryFormHistoryComponent implements OnInit {

  @Input() origin: originTypeForm = '';
  @Output() onSubmit: EventEmitter<IQueryForm> = new EventEmitter<IQueryForm>();

  public docTypes: ITypeDoc[] = [];

  private MOCK_NATURAL: IQueryForm = {
    documentType: 'C',
    document: '1140833157',
    lastname: 'CASTAÑEDA',
    includeScore: false
  }

  private MOCK_LEGAL: IQueryForm = {
    documentType: 'C',
    document: '18510414',
    lastname: 'carrizosa',
    includeScore: false
  }

  private INITIAL_STATE: IQueryForm = {
    documentType: 'C',
    document: '',
    lastname: '',
    includeScore: false
  };

  public form: IQueryForm = this.INITIAL_STATE;

  public get searchByLabel(): string {
    return this.form.documentType === 'N' ? 'Razón social' : 'Primer apellido';
  }

  get showOptionScore(): boolean {
    return this.origin === 'HDC' || this.origin === 'HDC_PJ';
  }

  get getDocTypes(): ITypeDoc[] {

    if (this.origin === "HDC") return this.docTypes.filter((option) => option.value !== "N");

    // if(this.origin === "Reconocer") return this.docTypes.filter((option) => option.value);    

    return this.docTypes;
  }

  constructor(
    private listCovinoc: ListsCovinocService,
    public helper: HelperService
  ) {
    this.handleInitDocumentTypeLegalOrNatural();
  }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe(this.handleSubsListCovinoc.bind(this))
  }

  handleInitDocumentTypeLegalOrNatural() {
    if (this.origin === "HDC_PJ") {
      this.form.documentType = 'N';
    } else {
      this.form.documentType = 'C';
    }
  }

  /* handleSelectTypeDoc(event: any) {
    this.form.documentType = this.helper.onlyText(event)
  } */

  handleSubsListCovinoc(response: any) {
    this.docTypes = response.data[0].documentTypesCode;
  }

  handleSubmitForm() {
    this.form.document = this.replaceSpecialCases(this.form.document);
    this.form.lastname = this.replaceSpecialCases(this.form.lastname);
    console.log('Enviando formulario...', this.form)
    this.onSubmit.emit(this.form);
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');

    // Sanitize the pasted text
    pastedText = this.replaceSpecialCases(pastedText);

    if (this.form) {
      this.form.lastname = pastedText;
    }
  }

  replaceSpecialCases(value: string): string {
    const newValue = value
      .trim() // Espacios
      .replace(/\s+/g, ' ') // Dobles espacios
      .replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s.]/g, ''); // Remove special characters except periods and accented letters

    return newValue;
  }

}
