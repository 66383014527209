import { Component, Input, OnInit } from '@angular/core';
import { RolesService } from '@app/services/roles.service';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-buyer-information',
  templateUrl: './buyer-information.component.html',
  styleUrls: ['./buyer-information.component.scss']
})
export class BuyerInformationComponent implements OnInit {
  @Input() quotaData: any;

  isChangeValue: boolean;

  constructor(private roles: RolesService) { }

  ngOnInit() {
  }

}
