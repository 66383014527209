import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RelatedDocumentsHeadersService {

  public contactDataHeaders: any[] = [
    {
      href: '#contactPhone',
      description: 'Teléfonos',
      icon: 'icon-tab-individual.svg',
      key: 'contactPhone'
    },
    {
      href: '#contactAddress',
      description: 'Direcciones',
      icon: 'icon-tab-individual.svg',
      key: 'contactAddress'
    },
    {
      href: '#contactEmails',
      description: 'Correos',
      icon: 'icon-tab-individual.svg',
      key: 'contactEmails'
    },
  ];

  relatedDocumentsTable: Array<any> = [
    'Documento relacionado',
    'Nombre',
    'Tipo de relación',
    'Estado relación',
    'Fuente',
    'Fecha de registro',
    'Fecha de actualización',
    'Estado cupos',
    'Clasificación SEP',
  ]

  relationTypesList: Array<any> = [
    {value: 'DUEÑO', description: 'DUEÑO'},
    {value: 'SOCIO', description: 'SOCIO'},
    {value: 'REPRESENTANTE LEGAL', description: 'REPRESENTANTE LEGAL'},
    {value: 'EMPLEADO', description: 'EMPLEADO'},
    {value: 'FAMILIAR', description: 'FAMILIAR'},
    {value: 'GRUPO EMPRESARIAL', description: 'GRUPO EMPRESARIAL'},
    {value: 'OTRO', description: 'OTRO'},
  ]

  stateList: Array<any> = [
    {
      value: 'VIGENTE',
      description: 'VIGENTE'
    },
    {
      value: 'NO VIGENTE',
      description: 'NO VIGENTE'
    },
  ]

  constructor() { }
}
