import { Component, OnInit } from "@angular/core";
import { ModalService } from "@app/services/modal.service";

@Component({
  selector: "app-modal-global-various",
  templateUrl: "./modal-global-various.component.html",
  styleUrls: ["./modal-global-various.component.scss"],
})
export class ModalGlobalVariousComponent implements OnInit {
  constructor(public modal: ModalService) {}

  ngOnInit() {
    console.log("INIT");
  }

  closeModal() {
    this.modal.close();
  }
}
