import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appTransactionStatus]'
})
export class TransactionStatusDirective {

  @Input() value: any;


  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.color(this.value);
  }

  color(value: any) {
    const success = 'rgba(28, 190, 81, 1)';
    const danger = 'rgba(253, 73, 64, 1)';

    if (value === '0' || value === 0) {
      this.el.nativeElement.style.color = danger;
    } else {
      this.el.nativeElement.style.color = success;
    }

  }

}