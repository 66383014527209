import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transactions-three-and-six-payments',
  templateUrl: './three-and-six-payments.component.html',
  styleUrls: ['./three-and-six-payments.component.scss']
})
export class TransactionsThreeAndSixPaymentsComponent implements OnInit {

  public heading = [
    'Fecha de aprobación',
    'Título valor',
    'Documento comprador',
    'Nombre comprador',
    'Valor',
    'Documento afiliado',
    'Nombre afiliado',
    'Tipo de producto'
  ];

  public dateRange: Array<any> = [];

  public list: Array<any> = [];

  public file: string = '';

  public totalPages = 1;

  public currentPage = 1;

  constructor(private spinner: NgxSpinnerService, private quotaGreater: QuotaGreaterService, private toastr: ToastrService, private helper: HelperService) { }

  ngOnInit() {
  }

  public goToPage($event) {
    this.spinner.show();
    let params = {
      initialDate: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
      finalDate: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      currentPage: $event.currentPage,
      perPage: 10,
      download: false
    }
    
    this.quotaGreater.threeAndSixPayments(params).subscribe((response: any) => {
      this.spinner.hide();
      this.list = response.data
      this.file = '';
      this.totalPages = response.totalPages;
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });

  }

  public onGetDateRange($event) {
    console.log($event);
    
    this.dateRange = $event;
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      initialDate: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
      finalDate: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      currentPage: 1,
      perPage: 10,
      download: false
    }
    console.log("No se encontraron");
    this.quotaGreater.threeAndSixPayments(params).subscribe((response: any) => {
      this.spinner.hide();
      this.list = response.data
      this.totalPages = response.totalPages;
      if(response.ok === false) {
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      this.spinner.hide();
      console.log("No se encontraron");
      
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  public downloadQuota() {
    this.spinner.show();
    let params = {
      initialDate: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
      finalDate: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      currentPage: 1,
      perPage: 10,
      download: true
    }
    
    this.quotaGreater.threeAndSixPayments(params).subscribe((response: any) => {
      this.spinner.hide();
      this.file = response.file
      if (this.file) {
        this.helper.downloadFromBase64(this.file, 'tres_y_seis_pagos', '.xlsx');
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

}
