import { Component, Input, OnInit } from '@angular/core';
import { PaymentHabitOfInactiveObligations } from '../../services/legal-person-adapter.service';
import { ExpirianListsService } from '../../services/expirian-lists.service';

@Component({
  selector: 'app-legal-payment-habit-of-inactive-obligations-table',
  templateUrl: './legal-payment-habit-of-inactive-obligations-table.component.html',
  styleUrls: ['./legal-payment-habit-of-inactive-obligations-table.component.scss']
})
export class LegalPaymentHabitOfInactiveObligationsTableComponent implements OnInit {

  public headers: string[] = [
    "Estado obligación",
    "Tipo Cuenta",
    "Entidad informante",
    "Fecha cierre",
    "Número obligación",
    "Oficina",
    "Calidad",
    "Fecha apertura",
    "Fecha venc.",
    "Mora max.",
    "Desacuerdo con la información",
    "Comportamiento 47 meses",
    "Persona"
  ];

  @Input() paymentHabitOfInactiveObligations: PaymentHabitOfInactiveObligations[] = [];
  @Input() isAPerson: boolean = false;

  constructor(
    private experianListService: ExpirianListsService
  ) { }

  ngOnInit() {
  }

  getSectorDescription(code: string): string {
    const sector = this.experianListService.findSectorByCode(code);
    return sector ? sector.descripcion : code;
  }

}
