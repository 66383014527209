import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import * as moment from "moment";
import { NetFactoringService } from '../services/net-factoring.service';
import { NetFactoringBackEndService } from '@app/services/net-factoring-back-end.service';
import { HelperService } from '@app/services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-financial-invoices",
  templateUrl: "./financial-invoices.component.html",
  styleUrls: ["./financial-invoices.component.scss"],
})
export class FinancialInvoicesComponent implements OnInit {
  negotiatedInvoicesList: FinalInvoicesModel[] = [];
  fromDate: Date;
  toDate: Date;

  public cantPages = 0;
  public currentPage = 1;

  constructor(
    public helper: HelperService,
    public spinner: NgxSpinnerService,
    public netFactoringService: NetFactoringService,
    public netFactoringBackEndService: NetFactoringBackEndService
  ) {}

  ngOnInit() {}

  onDateRange() {
    this.spinner.show();

    if (!this.toDate) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Formato invalido",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    let longMonth = this.toDate.getMonth() - this.fromDate.getMonth();

    if (longMonth > 2) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Supero el limite de consulta debe seleccionar maximo 2 meses",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    if (this.toDate < this.fromDate) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "La fecha inicial es mayor a la final",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    if (this.fromDate.getMonth() > this.toDate.getMonth()) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Formato invalido",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    let query = {
      startDate: moment(this.fromDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      endDate: moment(this.toDate)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      currentPage: this.currentPage,
      perPage: 10,
    };
    this.netFactoringBackEndService
      .getInvoicesNegotiatedFinancia(query)
      .subscribe(
        (resp) => {
          this.negotiatedInvoicesList = resp.data;
          this.cantPages = resp.totalPages;
          this.spinner.hide();
        },
        (error) => {
          this.negotiatedInvoicesList = [];
          console.error(error);
          this.spinner.hide();
        }
      );
  }

  goToPage($event) {
    this.currentPage = $event;
  }

  downloadFile() {
    let query = {
      startDate: moment(this.fromDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      endDate: moment(this.toDate)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      currentPage: this.currentPage,
      perPage: 10,
    };
    this.spinner.show();
    this.netFactoringBackEndService.getInvoicesNegotiatedFinanciaDocument(query).subscribe(
      (response) => {
        let dataCurrent = response.data[0];
        this.helper.downloadFromBase64(dataCurrent, "informe-caja-ya", ".xls");
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
