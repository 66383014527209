import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  GlobalClassifiedDebt,
  GlobalClassifiedDebtSector,
} from '../../services/natural-person-adapter.service';
import { ExpirianListsService } from '../../services/expirian-lists.service';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-table-global-classified-debt-history',
  templateUrl: './table-global-classified-debt-history.component.html',
  styleUrls: ['./table-global-classified-debt-history.component.scss'],
})
export class TableGlobalClassifiedDebtHistoryComponent
  implements OnInit, OnChanges
{
  public headersTable: string[] = [
    'Entidad Informante',
    'Calificación',
    'Número',
    'Saldo total',
    'Comercial - Número',
    'Comercial - Miles $',
    'Hipotecario - Número',
    'Hipotecario - Miles $',
    'Consumo y Tarjeta de Crédito - Número',
    'Consumo y Tarjeta de Crédito - Miles $',
    'Microcrédito - Número',
    'Microcrédito - Miles $',
    'Garantías - Tipo',
    'Garantías - Fecha Avalúo',
    'Garantías - Valor',
    'Moneda',
    'Fuente',
  ];

  @Input() globalClassifiedDebtSector: any;

  groupedData: any = {};

  constructor(
    private expirianListsService: ExpirianListsService,
    private helperCreditInfo: HelperCreditInfoService
  ) {}

  ngOnInit() {
    // console.log('Global Debt Classified Data:', this.globalClassifiedDebtSector);
    this.groupDataBySector();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Changes:', changes);
    if (
      changes.globalDebtClassifiedDataTable &&
      changes.globalDebtClassifiedDataTable.currentValue
    ) {
      this.groupDataBySector();
    }
  }

  findSectorByCoded(code: string): string {
    const sector = this.expirianListsService.findSectorByCode(code);
    return sector.descripcion;
  }

  groupDataBySector() {
    const sortedData = this.globalClassifiedDebtSector.sort(
      (a: any, b: any) => {
        const dateA = new Date(a.fechaReporte);
        const dateB = new Date(b.fechaReporte);
        return dateA.getTime() - dateB.getTime();
      }
    );
    this.groupedData = sortedData.reduce((acc: any, curr: any) => {
      const reportDate = curr.fechaReporte.trim();
      if (!acc[reportDate]) {
        acc[reportDate] = {};
      }
      const sector = curr.Entidad.sector.toString().trim();
      if (!acc[reportDate][sector]) {
        acc[reportDate][sector] = {
          data: [],
          totalCreditos: 0,
          totalSaldoPendiente: 0,
          totalComercial: { numero: 0, saldo: 0 },
          totalHipotecario: { numero: 0, saldo: 0 },
          totalConsumo: { numero: 0, saldo: 0 },
          totalMicrocredito: { numero: 0, saldo: 0 },
        };
      }
      acc[reportDate][sector].data.push(curr);
      acc[reportDate][sector].totalCreditos += curr.numeroCreditos || 0;
      acc[reportDate][sector].totalSaldoPendiente += curr.saldoPendiente || 0;
      if (curr.tipoCredito === 'CMR') {
        acc[reportDate][sector].totalComercial.numero += curr.numeroCreditos || 0;
        acc[reportDate][sector].totalComercial.saldo += curr.saldoPendiente || 0;
      } else if (curr.tipoCredito === 'HIP') {
        acc[reportDate][sector].totalHipotecario.numero += curr.numeroCreditos || 0;
        acc[reportDate][sector].totalHipotecario.saldo += curr.saldoPendiente || 0;
      } else if (curr.tipoCredito === 'CNS') {
        acc[reportDate][sector].totalConsumo.numero += curr.numeroCreditos || 0;
        acc[reportDate][sector].totalConsumo.saldo += curr.saldoPendiente || 0;
      } else if (curr.tipoCredito === 'MIC') {
        acc[reportDate][sector].totalMicrocredito.numero += curr.numeroCreditos || 0;
        acc[reportDate][sector].totalMicrocredito.saldo += curr.saldoPendiente || 0;
      }
      return acc;
    }, {});
  }

  traslateWarrantyType(code: string | number): string {
    const result = this.helperCreditInfo.findWarrantyOfGlobalIndebtednessClassified(code);
    return result.label;
  }
}
