import { Component, OnInit } from '@angular/core';
import { PersonDocument } from '@app/pages/get-quota/searcher/models/person-document';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { DataBuyer, ReceiveBuyer } from '../affiliate-buyer/models/affiliate-buyer.model';
import { AffiliatesBuyerService } from '../affiliate-buyer/services/affiliates-buyer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-buyer',
  templateUrl: './report-buyer.component.html',
  styleUrls: ['./report-buyer.component.scss']
})
export class ReportBuyerComponent implements OnInit {
    dataBuyer: DataBuyer[] = [];
    documentTypes: any;
    data: PersonDocument = {
    documentType : '',
    document     : null
    }
    yearHeader1: number;
    colspanYear1 = 0;
    yearHeader2: number;
    colspanYear2 = 0;

  constructor(
    public helper: HelperService, 
    private listService: ListsCovinocService,
    private affiliatesBuyerService: AffiliatesBuyerService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    this.findAllLists();
  }

  onSubmit() {

  }

  findAllLists(): void {
    this.listService.findAllLists().subscribe(
      (res) => {
        if(res.status != 200) this.helper.badResponse(res.message, res.status);
        this.documentTypes = res.data[0]['documentTypesCode'];
      },
      error => this.helper.handleErrorHttp(error, 'Ocurrió un Error')
    );
  }

  changeInput() {
    
  }

  getBuyerInfo() {
    this.spinner.show();
    
    this.affiliatesBuyerService.GetBuyerInfo(this.data.documentType, this.data.document)
    .pipe(
      finalize(() => {
        this.spinner.hide();
      }
    ))
    .subscribe(
      (res: ReceiveBuyer) => {
        let months1 = [];
        let months2 = [];
        this.dataBuyer = res.data as DataBuyer[];
        this.dataBuyer.forEach((element)=> {
          //this.yearHeader2 = element.year;
          if(this.dataBuyer[0].year === element.year) {
            months1.push(element.year)
          } else {
            months2.push(element.year)
          }

        })
        this.colspanYear1 = months1.length;
        this.yearHeader1 = months1[0];
          if(months2.length > 0) {
            this.colspanYear2 = months2.length;
            this.yearHeader2 = months2[0];
          } else {
            this.colspanYear2 = undefined;
            this.yearHeader2 = undefined;
          }
      },(error: any) => {
        //console.log(error)
        Swal.fire({
          type: "info",
          //title: "Error en la busqueda",
          text: `${error.error.message}`
        });
        this.refresh();
      }
    );
  }

  refresh() {
    this.data= {
      documentType : '',
      document     : null
      }
      this.dataBuyer = [];
  }
  

}
