import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bags-report',
  templateUrl: './bags-report.component.html',
  styleUrls: ['./bags-report.component.scss']
})
export class BagsReportComponent implements OnInit {

  selectedReport: string = '1';
  constructor() {}
  ngOnInit() {}
  onSelectChange(event: Event): void {
    this.selectedReport = (event.target as HTMLSelectElement).value;
  }

}
