import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RisksProcessesChargesHeadersService {

  constructor() { }

  public massQuotaLoadHeaders: string[] = [
    "Documento",
    "Nombre",
    "Direccion", //
    "Código de contrato", //
    "Razón social", //
    "Código Dane", //
    "Teléfono", //
    "Celular", //
    "Correo", //
    "Documento Relación", //
    "Nombre relación", //
    "Relacion",
    "Nueva capacidad",
    "Cuota Asignada",
    "Tope cheque día",
    "Tope posfechado",
    "Tope factura",
    "Clasificación comprador",
    "Categoría",
    "Subcategoría",
    "Registro",
    "Comentario"
  ];

  /* 
    "documentType": "C",
    "document": 1045023851,
    "contractCode": 300000,
    "socialReason": null,
    "name1": "Rafael",
    "name2": "Angel",
    "surname1": "Ramirez",
    "surname2": "Serna",
    "daneCode": 1,
    "phone": 4571245,
    "mobile": 3209881879,
    "email": "raframzs@gmail.com",
    "documentTypeRelation": "N",
    "documentRelation": 900451777,
    "relationSocialReason": "FERRETERIA 777",
    "relationName1": null,
    "relationName2": null,
    "relationSurname1": null,
    "relationSurname2": null,
    "relation": "SOCIO",
    "newCapacity": 250000,
    "asignedQuota": 100000,
    "topCheckDay": 100000,
    "topPosdated": 200000,
    "topInvoice": 20000,
    "buyerClasification": "NUEVO",
    "category": "A",
    "subCategory": "B",
    "register": 1001,
    "comment": "Registro Perfecto!"
   */

  public quotaAdjustmentHeaders: string[] = [
    "Contrato",
    "Tipo documento",
    "Documento",
    "Cupo actual",
    "Nuevo cupo",
    "Estado",
    "Observaciones",
  ];

  public stateChangeHeaders: string[] = [
    "Tipo documento",
    "Documento",
    "Contrato",
    "Estado",
    "Nuevo estado",
    "Observaciones",
  ];

  public affiliatePoliciesHeaders: string[] = [
    "Tipo documento",
    "Documento",
    "Clasificación",
    "Observaciones",
  ];

  public affiliateRisksExportsHeaders: string[] = [
    "Tipo documento",
    "Documento",
    "Clasificación",
    "Fecha actualización",
    "Usuario",
    "Observaciones",
  ];

  public affiliateRisksMassiveHeaders: string[] = [
    "Tipo documento",
    "Documento",
    "Clasificación",
    "Observaciones",
  ];

  public habeasDataHeaders: string[] = [
    "Tipo documento",
    "Documento",
    "Observaciones",
  ];

  public buyersMassiveEvaluationHeaders: string[] = [
    "Tipo de documento",
    "Documento",
    "Observaciones",
  ];

  public buyersPostChargueMassiveEvaluationHeaders: string[] = [
    "Documento",
    "Nombres",
    "Tipo girador",
    "Cvs",
    "Valor reintegro",
    "Valor recuperado",
    "Avisos no pago",
    "Prorrogas",
    "Dirección",
    "Teléfono celular",
    "Teléfono fijo",
    "Consultas",
    "Valor consultas",
    "Valor Aprobado",
    "Valor máximo de garantía",
    "Máxima fecha",
    "Cupo vigente con afiliado",
    "Total de cupos con afiliado",
    "Cupo vigente comprador",
    "Total de cupos comprador",
    "Transacciones vigentes",
    "Valor de garantía vigente vencida",
    "Valor de garantía vigente no vencida",
    "Información de ventas",
    "Observaciones",
  ]

  public restrictiveListsHeaders: string[] = [
    "Documento",
    "Nombre",
    "Lista reporte",
    "Fecha varias",
    "Fecha actualiza",
    "Observaciones",
  ];

  public risksBuyerQuotasChargueHeaders: string[] = [
    "Tipo de documento",
    "Documento",
    "Valor de cupo",
    "Observaciones",
  ];

  public risksBuyerQuotasWithdrawalHeaders: string[] = [
    "Tipo de documento",
    "Documento",
    "Observaciones",
  ];

  public risksCoveringlHeaders: string[] = [
    "Tipo de documento",
    "Documento",
    "Observaciones",
  ];

  public risksQuotaQualificationsHeaders: string[] = [
    "Tipo de documento",
    "Documento",
    "Nombre del comprador",
    "Categoría",
    "Observaciones",
  ];
}
