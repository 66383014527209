import { Component, Input, OnInit } from '@angular/core';
import { SummaryOfPaymentHabits } from '../../services/legal-person-adapter.service';
import { ACCOUNT_TYPE } from '@pages/credit-info/constants/global-credit-info';


@Component({
  selector: 'app-legal-payment-habit-resume-table',
  templateUrl: './legal-payment-habit-resume-table.component.html',
  styleUrls: ['./legal-payment-habit-resume-table.component.scss'],
})
export class LegalPaymentHabitResumeTableComponent implements OnInit {
  @Input() summariesOfPaymentHabits: SummaryOfPaymentHabits[] = [];

  ngOnInit() {
  }

}
