import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateLinkGlobalBilling } from '@app/models/afiliate-link';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { SelectsService } from '@app/services/selects.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
import { ComunicationService } from './comunication.service';
import { CutBillingService } from '@app/models/cutBillingmodel';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';

@Component({
  selector: 'app-billing-cut',
  templateUrl: './billing-cut.component.html',
  styleUrls: ['./billing-cut.component.scss'],
  providers: [DatePipe],
})
export class BillingCutComponent implements OnInit {
  public identity: any;
  public cutBillingForm: FormGroup;
  public maximumDate: DateLinkGlobalBilling = new DateLinkGlobalBilling();
  public user: string;
  public data: CutBillingService;
  public dateBilling: Date;
  public nullRegister: boolean = null;
  public lastBillingCut: number = null;
  private consecutivesList = [];
  public selectedCuts: number[] = [];

  constructor(
    public helper: HelperService,
    public select: SelectsService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public modal: ModalService,
    private cutBilling: GloballBillingService,
    private datePipe: DatePipe,
    private comunication: ComunicationService,
    public backupsHeaders: BackupsTableHeadersService
  ) {}

  ngOnInit() {
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.createCutBillingForm();
    this.consecutives();
    //this.timeDraft();
  }

  createCutBillingForm(): void {
    this.dateBilling = new Date();
    this.cutBillingForm = this.formBuilder.group({
      billingCartagena: [{ value: 0, disabled: true }, Validators.required],
      dateBilling: [this.dateBilling, Validators.required],
      billingCut: [null, Validators.required],
      billingBarranquilla: [{ value: 0, disabled: true }, Validators.required],
      billingMedellin: [{ value: 0, disabled: true }, Validators.required],
      billingBogota: [{ value: 0, disabled: true }, Validators.required],
      billingPereira: [{ value: 0, disabled: true }, Validators.required],
      limitPayDate: ['', Validators.required],
      billingBucaramanga: [{ value: 0, disabled: true }, Validators.required],
      billingCali: [{ value: 0, disabled: true }, Validators.required],
      user: this.user,
    });
  }

  openModal() {
    this.modal.show('cutBilling', {});
  }

  sendCutBilling() {
    this.dateBilling = new Date();
    this.spinner.show();
    const selectedCut = parseInt(this.cutBillingForm.get('billingCut').value);
    this.selectedCuts.push(selectedCut);
    const dataCutBilling = {
      fecha_facturacion: this.formatDate(
        this.cutBillingForm.get('dateBilling').value
      ),
      dia_corte: selectedCut,
      fecha_limite_pago: this.formatDate(
        this.cutBillingForm.get('limitPayDate').value
      ),
      usuario: this.identity.user,
      //usuario: "rafael.ramiresz",
    };
    this.cutBilling
      .postCutBilling(dataCutBilling)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.data = response;
          if (this.data.data[0]) {
            this.sendData();
          }
          this.backupsHeaders.setLoad(true);
        },
        (error: any) => {
          if (error.status === 400) {
            this.nullRegister == false;
            this.sendDataNullRegister();

            Swal.fire({
              title: 'No hay registros',
              text: error.error.message,
              type: 'info',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: error.error.message,
              type: 'error',
              confirmButtonText: 'OK',
            });
          }
        }
      );
    this.cutBillingForm.get('limitPayDate').reset();
    this.cutBillingForm.get('billingCut').reset();
    this.openModal();
  }

  formatDate(date: string): string {
    const dateFormat = this.datePipe.transform(date, 'yyyy-MM-dd');
    return dateFormat;
  }

  sendData() {
    const data = this.data;
    this.comunication.sendData(data);
  }
  sendDataNullRegister() {
    const nullRegister = this.nullRegister;
    this.comunication.sendDataNullRegister(nullRegister);
  }

  isSendButtonDisabled(): boolean {
    const selectValue = parseInt(this.cutBillingForm.get('billingCut').value);
    return this.cutBillingForm.invalid || selectValue === null;
  }

  consecutives() {
    this.spinner.show();
    this.cutBilling.getConsecutives().subscribe(
      (resp) => {
        this.consecutivesList = resp.data[0];
        this.cutBillingForm
          .get('billingCartagena')
          .setValue(this.getNameConsecutive('CARTAGENA'));
        this.cutBillingForm
          .get('billingBarranquilla')
          .setValue(this.getNameConsecutive('BARRANQUILLA'));
        this.cutBillingForm
          .get('billingMedellin')
          .setValue(this.getNameConsecutive('MEDELLIN'));
        this.cutBillingForm
          .get('billingBogota')
          .setValue(this.getNameConsecutive('BOGOTA'));
        this.cutBillingForm
          .get('billingPereira')
          .setValue(this.getNameConsecutive('PEREIRA'));
        this.cutBillingForm
          .get('billingBucaramanga')
          .setValue(this.getNameConsecutive('BUCARAMANGA'));
        this.cutBillingForm
          .get('billingCali')
          .setValue(this.getNameConsecutive('CALI'));

        this.spinner.hide();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  cuts() {
    this.selectedCuts=[];
  }

  getNameConsecutive(name) {
    const nameConsecutive = this.consecutivesList.find(
      (element) => element.sucursal === name
    );
    return nameConsecutive ? nameConsecutive.consecutivo_actual : 0;
  }

  timeDraft() {
    return setInterval(() => {
      this.consecutives();
    }, 5000);
  }
}
