import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RisksProcessesChargesService {
  public apiUrl: string;
  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.apiUrl = environment.apiUrl;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { apiUrl } = apis;
      this.apiUrl = apiUrl;
      console.log('config ci cd', apiUrl);
    });
  }

  affiliateInfo(params) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
      params,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/loads/affiliateInfo`,
      options
    );
  }

  generateAutexKeys(params) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
      params,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/locksUnlocks/generateAutexKeys`,
      options
    );
  }

  titlesByAuthorization(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/titles/byAuthorization`,
      body,
      options
    );
  }

  masiveLoadClassification(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}quota-manager/loads/masiveLoadClassification`,
      body,
      options
    );
  }

  previewFile(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/previewData`,
      body,
      options
    );
  }

  updateClassification(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/updateClassification`,
      body,
      options
    );
  }

  affiliateRiskByDocument(params) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
      params,
    };

    return this.http.get<any>(
      `${this.apiUrl}quota-manager/loads/affiliateRiskByDocument`,
      options
    );
  }

  downloadTemplate(params) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/x-www-form-urlencoded');

    const options = { headers, params };

    return this.http
      .get(`${this.apiUrl}quota-manager/loads/getLoadTemplate`, options)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  quotasMasiveLoad(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/quotasMasiveLoad`,
      body,
      options
    );
  }

  quotasMasiveModify(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/quotasMasiveModify`,
      body,
      options
    );
  }

  quotasMasiveState(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/quotasMasiveState`,
      body,
      options
    );
  }

  loadAffiliatePolitics(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/loadAffiliatePolitics`,
      body,
      options
    );
  }

  individualLoadHabeasData(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/individualLoadHabeasData`,
      body,
      options
    );
  }

  masiveLoadHabeasData(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/masiveLoadHabeasData`,
      body,
      options
    );
  }

  masiveEvaluationBuyers(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/masiveEvaluationBuyers`,
      body,
      options
    );
  }

  masiveRestrictiveListsLoad(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/masiveRestrictiveListsLoad`,
      body,
      options
    );
  }

  masiveBuyerQualificationLoad(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/masiveBuyerQualificationLoad`,
      body,
      options
    );
  }

  affiliateRiskSemester() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/x-www-form-urlencoded');

    const options = { headers };

    return this.http
      .get(`${this.apiUrl}quota-manager/loads/affiliateRiskSemester`, options)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  masiveBuyerQuotasLoad(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/masiveBuyerQuotasLoad`,
      body,
      options
    );
  }

  findCurrentCoverage(params) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/x-www-form-urlencoded');

    const options = { headers, params };

    return this.http
      .get(`${this.apiUrl}quota-manager/loads/findCurrentCoverage`, options)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateCoverage(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const options = {
      headers,
    };

    return this.http.post(
      `${this.apiUrl}quota-manager/loads/updateCoverage`,
      body,
      options
    );
  }
}
