import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { ModalService } from '@app/services/modal.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-block-unlock-buyer',
  templateUrl: './block-unlock-buyer.component.html',
  styleUrls: ['./block-unlock-buyer.component.scss']
})
export class BlockUnlockBuyerComponent implements OnInit {

  @ViewChild('blockBuyerForm') blockBuyerForm: NgForm;

  public headers: Array<string>;

  public accounts: Array<any>;

  public docTypes;

  public currentPage: number = 1;

  public totalPages: number = 1;

  public isBlock: boolean = false;

  public isOtherReason: boolean = false;

  public selectList: Array<boolean> = [];

  public blockOption: string = 'Bloquear';

  public accountSelected: any;

  public isQuerySuccess: boolean = false;

  public isSelectUnlock: boolean = false;

  public formAccount = {
    documentType: '',
    document: '',
    reason: null,
    reasonOther: null,
    type: '',
  }

  public reasonList: Array<any>;

  public blockAccountOptions = [
    {
      description: 'Bloquear',
      value: 'L'
    },
    {
      description: 'Desbloquear',
      value: 'U'
    },
  ]

  constructor(private quotaGreater: QuotaGreaterService, private listCovinoc: ListsCovinocService, public helper: HelperService, private tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService, private modal: ModalService, private toastr: ToastrService) { }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      this.docTypes = response.data[0].documentTypesCode;
    });
    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.reasonList = response.data[0].locksReason;
    })
    this.headers = this.tableHeaders.tableHeadersBlockAccount
  }

  selectBlockOption() { 
    this.isBlock = (this.formAccount.type === 'L') ? true : false;
    this.blockOption = (this.formAccount.type === 'L') ? 'Bloquear' : 'Desbloquear';
    this.formAccount.reason = (this.formAccount.type === 'L') ? this.formAccount.reason : null;
    this.isSelectUnlock = (this.formAccount.type === 'L') ? false : true;
  }

  selectOtherReason() {
    this.isOtherReason = (this.formAccount.reason === 'Otro') ? true : this.resetOtherReason();

  }

  resetOtherReason(): boolean {
    this.formAccount.reasonOther = null;
    return;
  }

  selectAccount(id: number) {
    this.accountSelected = this.selectList[id];
    this.selectList[id] = true;
  }

  executeOption() {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      const body = {
        documentType: this.formAccount.documentType,
        document: this.formAccount.document,
        user: user.user,
        typeAction: this.formAccount.type,
        reason: this.formAccount.reason,
        reasonOther: this.formAccount.reasonOther,
      }
  
      this.quotaGreater.blockBuyer(body).subscribe((response: any) => {
        this.toastr.success(response.message);
        this.modal.close();
      }, (error) => {
        this.modal.close();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }    
  }

  showAlertConfirm() {
    this.modal.show('confirm');
  }

  searchAccounts() {
    this.spinner.show();
    const body = {
      documentType: this.formAccount.documentType,
      document: this.formAccount.document,
      currentPage: this.currentPage,
      perPage: 10
    }

    this.quotaGreater.findAccounts(body).subscribe((response: any) => {
      this.spinner.hide();
      this.accounts = response.data[0];
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.isQuerySuccess = true;
      this.accounts.forEach((item) => {
        this.selectList.push(false);
      });
    }, (error) => {
      console.error(error)
    })
  }

  goToPage($event) {
    this.spinner.show();

    const body = {
      documentType: this.formAccount.documentType,
      document: this.formAccount.document,
      currentPage: $event.currentPage,
      totalPages: this.totalPages
    }

    this.quotaGreater.findAccounts(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.accounts = response.data[0];
      this.currentPage = response.currentPage
      this.totalPages = response.totalPages
    }, (error) => {
      console.log(error)
    })
  }

}
