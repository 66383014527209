import { Component, OnInit } from "@angular/core";
import { Resource } from "@app/models/Resource.model";
import { SelectModel } from "@app/models/select.model";
import { Response } from "@app/models/responseUtil.model";
import { CoviResourceService } from "@app/services/covi-resource.service";
import { HelperService } from "@app/services/helper.service";
import { ListsCovinocService } from "@app/services/lists-covinoc.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DocumentTypes } from "../../quotas/models/documentType.model";
import { QuotaHeadingTablesService } from "../services/quota-heading-tables.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-covi-resource",
  templateUrl: "./covi-resource.component.html",
  styleUrls: ["./covi-resource.component.scss"],
})
export class CoviResourceComponent implements OnInit {
  findDocument: Array<DocumentTypes> = [];
  typeDocument: Array<SelectModel> = [];

  filterResource: Resource;
  resourceList: Array<Resource> = [];

  resourceDownload: Array<Resource> = [];

  currentPage: number = 1;
  cantPages = 0;

  constructor(
    public helper: HelperService,
    public quotaHeadingTablesService: QuotaHeadingTablesService,
    private coviResourceService: CoviResourceService,
    private spinner: NgxSpinnerService,
    private listCovinoc: ListsCovinocService,
    private toastr: ToastrService
  ) {
    this.filterResource = this.quotaHeadingTablesService.resource;
  }

  ngOnInit() {
    this.getDocumentType();
  }

  getDocumentType() {
    this.findDocument = JSON.parse(
      localStorage.getItem("form20m_documentTypes")
    );
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.typeDocument = resp.data[0].documentTypesCode;
    });
  }

  mapDocument() {
    this.typeDocument = this.findDocument.map((item) => {
      let typeItem: SelectModel = {
        value: item.type,
        description: item.description,
      };
      return typeItem;
    });
  }

  onSelectDocument($event) {
    this.filterResource.documentType = $event;
    console.log(this.filterResource);
  }

  filterData() {
    this.spinner.show();
    this.coviResourceService.searchFilter(this.filterResource).subscribe(
      (resp: Response<Resource>) => {
        this.resourceList = resp.data;
        this.cantPages = resp.totalPages;
        this.currentPage = resp.currentPage;
        this.spinner.hide();
      },
      (error) => {
        this.resourceList = [];
        console.log(error.error.message);
        this.toastr.error(error.error.message, "Error");
        this.spinner.hide();
      }
    );
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.filterData();
  }

  checkDocument($event, resource: Resource) {
    if ($event.target.checked) {
      this.resourceDownload.push(resource);
      console.log(this.resourceDownload);
      return;
    }

    this.resourceDownload = this.resourceDownload.filter(
      (element) => element.id != resource.id
    );
    console.log(this.resourceDownload);
  }

  downloadFile() {
    this.resourceDownload.forEach((element) => {
      this.helper.downloadFromBase64(
        element.infoFile,
        element.originalName,
        element.originalFinalName
      );
    });
  }

  onValueIdResource() {
    this.filterResource.documentType = null;
    this.filterResource.document = null;
  }

  onValueDocument(){
    this.filterResource.resourceId = null;
  }

}
