import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'departmentName'
})
export class DepartmentNamePipe implements PipeTransform {

  transform(value: any): any {
    let nameDepartment: string;
    
    let requestTypeList: any = JSON.parse(localStorage.getItem('form20m_municipalitiesList'));
    if(requestTypeList) {
      requestTypeList.forEach(item => {
        if(Number(item.department) === Number(value)) {
          nameDepartment = item.nameDepartment
        }
      })
      return nameDepartment;
    }
  }

}
