import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class TransactionsHeadersService {
    public noPaymentNoticeHeaders: string[] = [
        "Contrato",
        "Producto",
        "Código",
        "Fecha Consulta",
        "Cuota",
        "No. Titulo",
        "Fecha de Aviso",
        "Fecha Exoneración",
        "Fecha de Descarga",
    ];

    public titlesHeader: string[] = [
        "Seleccionar",
        "Acción",
        "Titulo",
        "Código Consulta",
        "Número de autorización",
        "Valor del título consultado",
        "Valor del título aprobado",
        "Estado del título",
        "Estado de la transacción",
        "Fecha del título",
        "Número de cheque",
        "Fecha de Vencimiento",
    ];

    public tableHeadersBlockAccount: string[] = [
        'Seleccionar',
        'Acciones',
        'Documento Comprador',
        'Nombre del banco',
        'Número de Cuenta',
        'Apertura',
        'Estado Actual',
        'Novedad',
        'Actualización',
        'Nit de la Entidad',
        'Oficina',
        'Tipo de Cuenta',
        'Ciudad',
    ];

    public tableHeadersBlockTelphone: string[] = [
        'Seleccionar',
        'Acciones',
        'Documento Comprador',
        'Número de Teléfono',
        'Departamento',
        'Ciudad',
        'Estado Actual',
        'Tipo de Teléfono',
        'Tabla Origen',
    ]

    public tableHeadersTitlesByAuthorization: string[] = [
        'Fecha de generación de la transacción',
        'Código de Consulta',
        'Nombre del Afiliado',
        'Tipo de Documento del Comprador',
        'Documento del Comprador',
        'Valor',
        'Número de Título',
    ]

    public tableHeadersAutexKeys: string[] = [
        'Claves',
    ]

    public reverseHeaders: string[] = [
        "Seleccionar",
        "Título",
        "Código Consulta",
        "Valor",
        "Fecha de Vencimiento",
        "Tipo",
        "Valor Exonerado",
        "Fecha de Exoneración",
        "Numero de Cheques",
        "Estado",
        "Solicitó",
    ];
}

