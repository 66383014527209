import { Injectable } from '@angular/core';
import { ACCOUNT_STATUS, ACCOUNT_TYPE, AccountStatus, CHECKING_AND_SAVINGS_ACCOUNT_CLASS, CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS, CheckingAndSavingsAccountClass, CheckingAndSavingsAccountStatement, FRANCHISE_CARD, FranchiseCard, GENDER_AND_MARITAL_STATUS, GenderAndMaritalStatus, GUARANTEE_OF_GLOBAL_INDEBTEDNESS_CLASSIFIED, GUARANTEE_OF_HABIT_OF_PAYMENT_OF_CURRENT_OBLIGATIONS, IResponseCodeExperian, MONTH_MAP_FROM_PERCENTAGE_OF_ARREARS_BY_TYPE_OF_OGLIBATION, PAYMENT_MODE_FROM_PORTFOLIOS, PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO, PaymentModeFromPortfolio, PaymentStatus, PLASTIC_CONDITION, PlastiCondition, PORTFOLIO_AND_CREDIT_CARD_ACCOUNT_RATING, PortfolioAndCreditCardAccountRating, QUALITY_OF_DEBTOR, QualityOfDebtor, RESPONSE_CODE_FROM_HDC, RESPONSE_CODE_FROM_HDC_AND_PJ, RESPONSE_CODE_FROM_RECONOCER, SAVINGS_AND_CHECKING_ACCOUNT_RATING, SavingsAndCheckingAccountRating, SITUATION_OF_THE_OWNER, SituationOfTheOwner, STATE_OF_ORIGIN, StateOfOrigin, TYPE_OF_CONTRACT_BY_OBLIGATION, TYPE_OF_CREDIT_CARD, TypeOfContractByObligation, TypeOfCreditCard, WarrantyOfGlobalIndebtednessClassified, WarrantyOfHabitOfPaymentOfCurrentObligation } from '../constants/global-credit-info';

export interface ParamsToDetermineState {
  accountState?: string | number
  paymentState?: string | number
  originState?: string | number
  plasticState?: string | number
  paymentMethod?: string | number
  isBloqued?: boolean
  hasMora?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class HelperCreditInfoService {

  constructor() { }


  isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string): boolean {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    const result: boolean = (status && status.state !== "Cerrada");
    return result;
  }

  isInactiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string): boolean {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    const result: boolean = (status && status.state === "Cerrada");
    return result;
  }

  findQualityOfDebtor(qualityOfDebtorCode: string) {
    const parsedCode: number = !isNaN(Number(qualityOfDebtorCode)) ? Number(qualityOfDebtorCode) : -1;
    if (parsedCode >= 1 && parsedCode <= 3) qualityOfDebtorCode = "01_03";
    if (parsedCode >= 9 && parsedCode <= 95) qualityOfDebtorCode = "09_95";
    if (parsedCode >= 98 && parsedCode <= 99) qualityOfDebtorCode = "98_99";

    const result: QualityOfDebtor | undefined = QUALITY_OF_DEBTOR[qualityOfDebtorCode];
    return result || { cardDescription: 'N/A', portfolioDescription: 'N/A' };
  }

  findWarrantyOfHabitOfPaymentOfCurrentObligation(warrantyCode: string): WarrantyOfHabitOfPaymentOfCurrentObligation {
    const result: WarrantyOfHabitOfPaymentOfCurrentObligation | undefined = GUARANTEE_OF_HABIT_OF_PAYMENT_OF_CURRENT_OBLIGATIONS[warrantyCode];
    const defaultValue: string = warrantyCode ? `${warrantyCode}` : '-';
    return result || { code: defaultValue, name: defaultValue, description: defaultValue }
  }

  findWarrantyOfGlobalIndebtednessClassified(warrantyCode: string | number): WarrantyOfGlobalIndebtednessClassified {
    const result: WarrantyOfGlobalIndebtednessClassified | undefined = GUARANTEE_OF_GLOBAL_INDEBTEDNESS_CLASSIFIED.find((item) => item.code == warrantyCode);
    const defaultValue: string = warrantyCode ? `${warrantyCode}` : '-';
    return result || { code: defaultValue, label: defaultValue, description: defaultValue }
  }

  isActiveCheckingAndSavingAccount(code: string): boolean {
    const status: CheckingAndSavingsAccountStatement | undefined = CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS[code];
    const result: boolean = (status && status.state === 'Vigente');

    return result;
  }

  findCheckingAndSavingAccountClassByCode(code: string): CheckingAndSavingsAccountClass {
    const result: CheckingAndSavingsAccountClass | undefined = CHECKING_AND_SAVINGS_ACCOUNT_CLASS[code];
    return result || { code: code, name: code, description: code }
  }

  findGenderByCode(code: string): string {
    const result: GenderAndMaritalStatus | undefined = GENDER_AND_MARITAL_STATUS[code];
    return (result && result.gender) ? result.gender : code;
  }

  findPortfolioAndCreditCardAccountRatingByCode(code: string | number): string {
    const searched: PortfolioAndCreditCardAccountRating | undefined = PORTFOLIO_AND_CREDIT_CARD_ACCOUNT_RATING.find((item) => item.code == code);
    return (searched && searched.rating) ? searched.rating : `${code}`;
  }

  findSavingAndCheckingAccountRatingByCode(code: string | number): string {
    const searched: SavingsAndCheckingAccountRating | undefined = SAVINGS_AND_CHECKING_ACCOUNT_RATING.find((item) => item.code == code);
    return (searched && searched.rating) ? searched.rating : `${code}`;
  }

  findCheckingAndSavingAccountStatementByCode(code: string): string {
    const status: CheckingAndSavingsAccountStatement | undefined = CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS[code];

    return (status && status.name) ? status.name : code;
  }

  findCardOrPortfolioByPaymentStatusCode(paymentStatusCode: string | number): string {
    const status: PaymentStatus | undefined = PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO[paymentStatusCode];
    return (status && status.name) ? status.name : '';
  }

  findStateOriginByCode(code: string | number): string {
    const result: StateOfOrigin | undefined = STATE_OF_ORIGIN.find((item) => item.code == code);
    return (result && result.description) ? result.description : '';
  }

  findPlasticConditionByCode(code: string | number): string {
    const result: PlastiCondition | undefined = PLASTIC_CONDITION.find((item) => item.code == code);
    return (result && result.description) ? result.description : 'No reportado';
  }

  findAccountStatusByCode(code: string | number): string {
    const result: AccountStatus | undefined = ACCOUNT_STATUS.find((item) => item.code == code);
    return (result && result.description) ? result.description : '';
  }

  /**
   * La lógica que se encuentra en esta función es extraída del manual de insumo de HDC-PN
   * @param param0 
   * @returns 
   */
  determinePortfolioAndCreditCardStatus({ accountState, paymentState, originState, plasticState, paymentMethod, isBloqued, hasMora }: ParamsToDetermineState, accountNumber: any = -1): string {

    // TDC: 200001425 - Consulta PN CC 1140833157 y CASTAÑEDA
    // { accountState: "01", originState: 0, paymentState: "01", paymentMethod: 0, plasticState: 1, isBloqued: false }

    const existPaymentMethod: boolean = paymentMethod !== null && paymentMethod !== undefined;
    const existOriginState: boolean = originState !== null && originState !== undefined;
    const existPlasticState: boolean = plasticState !== null && plasticState !== undefined;
    const existPaymentStateState: boolean = paymentState !== null && paymentState !== undefined;

    if (accountState == "10") return this.findAccountStatusByCode(accountState);

    if (paymentState == "46" && paymentMethod == "3") return 'Pago Jur.';

    if (paymentState == "46" && existPaymentMethod && paymentMethod != "3") return 'Pago Vol.';

    const accountStateLabel: string = this.findAccountStatusByCode(accountState);
    const paymentStateLabel: string = this.findCardOrPortfolioByPaymentStatusCode(paymentState);
    const originStateLabel: string = this.findStateOriginByCode(originState);
    const plasticStateLabel: string = this.findPlasticConditionByCode(plasticState);

    const originStateToPrint: string = originStateLabel ? `Orig: ${originStateLabel}` : '';
    const plasticStateToPrint: string = plasticStateLabel ? `Plstico: ${plasticStateLabel}` : '';

    if (accountState == "10" || accountState == "14" || accountState == "13" || accountState == "16" || accountState == "15") {
      return `${accountStateLabel} + ${originStateToPrint} + ${plasticStateToPrint}`;
    }

    if (paymentState == "46" && paymentMethod == "03") {
      return `Pago Juridico + ${originStateToPrint}`;
    }

    if (paymentState == "46" && existPaymentMethod && paymentMethod != "03") {
      return `Pago Voluntario + ${originStateToPrint}`;
    }

    if ((paymentState == "03" || paymentState == "06") && !hasMora) {
      return `${paymentStateLabel} + Sin Mora + ${originStateToPrint}`;
    }

    if ((paymentState == "03" || paymentState == "06") && hasMora) {
      return `${paymentStateLabel} + Mora + ${originStateToPrint}`;
    }

    if (existPaymentStateState && paymentState != "03" && paymentState != "06" && paymentState != "46") {
      return `${paymentStateLabel} + ${originStateToPrint}`;
    }

    if (existPlasticState && plasticState != "0") {
      return `${plasticStateToPrint} + ${originStateToPrint} + ${accountStateLabel}`;
    }

    if (!existOriginState || isBloqued === true) {
      return `${paymentState} + ${accountState}`
    }

    return '-';
  }

  findSituationOfTheOwnerByCode(code: string | number): string {
    const result: SituationOfTheOwner | undefined = SITUATION_OF_THE_OWNER.find((item) => item.code == code);
    return (result && result.description) ? result.description : `${code}`;
  }

  findTypeOfCreditCardByCode(code: string | number): string {
    const result: TypeOfCreditCard | undefined = TYPE_OF_CREDIT_CARD.find((item) => item.code == code);
    return (result && result.description) ? result.description : '-';
  }

  findFranchiseCardByCode(code: string | number) {
    const result: FranchiseCard | undefined = FRANCHISE_CARD.find((item) => item.code == code);
    return (result && result.description) ? result.description : '-';
  }

  findResponseCodeFromHdc(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_HDC.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

  findResponseCodeFromHdcAndPj(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_HDC_AND_PJ.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

  findResponseCodeFromReconcer(responseCode: string): IResponseCodeExperian {
    const result: IResponseCodeExperian | undefined = RESPONSE_CODE_FROM_RECONOCER.find((item) => item.code == responseCode);
    if (!result) return { code: 'N/A', description: 'Error no identificado', isValid: false };
    return result;
  }

  getDescriptionAccountTypeByCode(code: string): string {
    const account = ACCOUNT_TYPE.find((acc) => acc.code === code);
    return account ? account.description : code; // Retorna la descripción o el código original si no se encuentra
  }

  findPaymentMontFromPortfolioByCode(code: string | number): PaymentModeFromPortfolio | undefined {
    const paymentMont = PAYMENT_MODE_FROM_PORTFOLIOS.find((item) => item.code == code);
    return paymentMont;
  }

  getValueOfMonthFromPercentageOfArrearsByTypeOfObligation(letters: string): number {
    const result = MONTH_MAP_FROM_PERCENTAGE_OF_ARREARS_BY_TYPE_OF_OGLIBATION.find((item) => item.letters === letters.toUpperCase().trim());
    return result ? result.value : 0;
  }

  getDescriptionOfTypeOfContractByObligationByCode(code: number): TypeOfContractByObligation {
    const result = TYPE_OF_CONTRACT_BY_OBLIGATION.find((item) => item.code == code);
    return result ? result : { code: code, description: '-', letters: '-' }
  }

}