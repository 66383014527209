export class ProductRequest {
    perPage:      number = 5;
    currentPage:  number = 1;
    productId:    number;
}

export class ProductModel {
    name:                        string;
    affiliationValue:            number;
    studyValue:                  number;
    numStudies:                  number;
    numChecks:                   number;
    termOfSale:                  number;
    segment:                     string;
    coverage:                    number;
    noticeOfNonPayment:          number;
    claimTerm:                   number;
    backup:                      number;
    reimbursementTerm:           number;
    specialConditions:  boolean = false;
    deductiblePaymentTerm:       number;
    user:                        string;
    productType:            string = '';
    bondPeriod:             string = '';

    allowBags?:         boolean = false;
    contractFile?:          string = '';
    contractFileName? :     string = '';
    contractFileExtension?: string = '';

    constructor() {
        this.specialConditions = false;
    }
}

export class ProductStateRequest {
    product: number;
    active: boolean;
    user:    string;
}

