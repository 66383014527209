import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { ExpirianListsService } from '../../services/expirian-lists.service';
import { DocumentValidity } from '../../models/expirian-lists.model';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { IBasicInformation } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-basic-information-history',
  templateUrl: './basic-information-history.component.html',
  styleUrls: ['./basic-information-history.component.scss']
})
export class BasicInformationHistoryComponent implements OnInit, OnDestroy {

  public personInformation: IBasicInformation = {} as IBasicInformation;
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService,
    private expirianList: ExpirianListsService,
    private helperCreditInfo: HelperCreditInfoService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.personInformation = response.personInformation;
  }

  getLabelDocumentValidity(code: string) {
    const result: DocumentValidity | undefined = this.expirianList.findDocumentValidityByCode(code);
    return result ? result.descripcion : code;
  }

  getLabelGender(genderCode: string): string {
    return this.helperCreditInfo.findGenderByCode(genderCode);
  }

}
