import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-legal-scores-table',
  templateUrl: './legal-scores-table.component.html',
  styleUrls: ['./legal-scores-table.component.scss']
})
export class LegalScoresTableComponent implements OnInit {

  @Input() dataTable: any;
  dataToDisplay: any[] = [];  // Array para almacenar los datos formateados para la vista

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
    this.prepareData();
  }

  prepareData() {
    if (Array.isArray(this.dataTable)) {
      // Si dataTable es un array, lo recorremos y verificamos si Razon es un objeto o un array
      this.dataToDisplay = this.dataTable.map(item => this.processRazon(item));
    } else if (this.dataTable && typeof this.dataTable === 'object') {
      // Si dataTable es un objeto, lo procesamos como un solo elemento
      this.dataToDisplay = [this.processRazon(this.dataTable)];
    }
  }

  processRazon(item: any) {
    if (item.Razon) {
      item.Razon = Array.isArray(item.Razon) ? item.Razon : [item.Razon];
    } else {
      item.Razon = [];
    }
    return item;
  }
}
