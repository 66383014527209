import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AffiliateInfoResponse } from "@app/pages/get-quota/transactions/entry-transactions/models/response/entry-transactions.response";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class BulkUploadQuearyService {
  private urlApi: string;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlCoviFacturaTransactionsApi;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlCoviFacturaTransactionsApi, dev07ApiUtl} = apis
      this.urlApi = dev07ApiUtl;
       console.log('config ci cd', apiUrl);
    });
  }

  headersPreview: any[] = [
    "Tipo documento",
    "Documento",
    "Titulo valor",
    "Valor",
    "Fecha",
    "Codigo consulta",
  ];

  headersBulkUpload: any[] = [
    "Tipo documento",
    "Documento",
    "Numero chq",
    "Valor",
    "Fecha",
    "Radicacion",
    "Fecha radicacion",
    "Usuario radicacion",
    "Codigo consulta",
    "Fecha carga",
    "Autorización",
    "Fecha autorización",
    "Observaciones",
    "Numero chq original",
    "Registro",
  ];

  getNameAffiliate(
    documentType: string,
    document: number
  ): Observable<AffiliateInfoResponse> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa("CLIENT_APP-1992aaA7:g10hnFdE3@4")
    );

    const params = {
      typeDocument: documentType,
      document: document.toString(),
    };
    return this.http
      .get(`${this.urlApi}covifacturatransactions/api/v1/affiliate/getNameAffiliate`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: AffiliateInfoResponse) => {
          return resp;
        })
      );
  }

  uploadFileBulkQueary(body: File) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa("CLIENT_APP-1992aaA7:g10hnFdE3@4")
    );

    const formFiles = new FormData();
    formFiles.append("file", body);
    const options = {
      headers,
    };

    return this.http.put(
      `${this.urlApi}covifacturatransactions/api/v1/bulkuploadqueary/uploadqueary`,
      formFiles,
      options
    );
  }

  insertBulkUploadQueary(body, params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set(
      "Authorization",
      "Basic " + btoa("CLIENT_APP-1992aaA7:g10hnFdE3@4")
    );

    const options = {
      headers,
      params,
    };

    return this.http.put(
      `${this.urlApi}covifacturatransactions/api/v1/bulkuploadqueary/insertBulkUploadQueary`,
      body,
      options
    );
  }
}
