import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { ClientStudyRequest } from '@models/request/clientStudyRequest.model';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { CONSTANTS } from '@config/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-display-legal-person',
  templateUrl: './display-legal-person.component.html',
  styleUrls: ['./display-legal-person.component.scss'],
})
export class DisplayLegalPersonComponent {
  @ViewChild('formCheck') formCheck;
  @Input() departments: Array<Department>;
  @Input() initialDateFields: Array<String>;
  @Input() addressFields: Array<String>;
  @Input() documentTypesOptions: Array<any>;
  @Input() companyTypeFields: Array<any>;
  @Input() expeditionCities: Array<City>;
  @Input() homeCities: Array<City>;
  @Input() lastSaveDate: String;
  @Input() updateRefCities: Subject<boolean>;
  @Input() threePayments: boolean;

  // Form
  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  requestClientStudy: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  validateChange: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  homeDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  expeditionDepartmentChanged: EventEmitter<String> = new EventEmitter<String>();

  public isRef1Valid = false;
  public isRef2Valid = false;
  public isAddressValid = false;
  public isOwnersValid = false;
  public isTermAccepted = false;
  public constants = CONSTANTS;
  public max = new Date();
  public maxBirthDate = moment().subtract(18, 'years').toDate();

  public option = {
    description: 'pointsOfSale',
  };

  constructor(public helper: HelperService, public modal: ModalService) {}

  onBlur($event) {
    this.valueChanges.emit();
  }

  onBlurValidate($event) {
    this.validateChange.emit();
  }

  emitChanges($event) {
    this.valueChanges.emit();
  }

  setRef1Valid(value: boolean) {
    this.isRef1Valid = value;
  }

  setRef2Valid(value: boolean) {
    this.isRef2Valid = value;
  }

  setAddressValid(value: boolean) {
    this.isAddressValid = value;
  }

  setOwnersValid(value: boolean) {
    this.isOwnersValid = value;
  }

  setAcceptTerms(value: boolean) {
    this.isTermAccepted = value;
  }

  copyInfo() {
    this.clientStudyForm.contactCellular = this.clientStudyForm.cellular;
    this.clientStudyForm.contactEmail = this.clientStudyForm.email;
    this.clientStudyForm.contactFirstName =
      this.clientStudyForm.legalRepresentativeFirstName;
    this.clientStudyForm.contactSecondName =
      this.clientStudyForm.legalRepresentativeSecondName;
    this.clientStudyForm.contactFirstLastName =
      this.clientStudyForm.legalRepresentativeFistLastName;
    this.clientStudyForm.contactSecondLastName =
      this.clientStudyForm.legalRepresentativeSecondLastName;
  }

  onHomeDepartmentChanged(expeditionDepartmentId) {
    this.homeDepartmentChanged.emit(expeditionDepartmentId);
  }
  onExpeditionDepartmentChanged(expeditionDepartmentId) {
    this.expeditionDepartmentChanged.emit(expeditionDepartmentId);
  }

  handleSubmit() {
    this.requestClientStudy.emit();
  }
}
