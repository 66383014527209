import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchAffiliateModel } from '../models/request/SearchAffiliateModel';
import { AffiliatesListModel, AffiliatesResponse } from '../models/response/SearchAffiliate.model';
import { TransactionsToBackUpResponseModel } from '../models/response/TransactionsToBackupResponse';
import { PaymentsPostBackingRequestModel } from '../models/request/PaymentsPostBackings.model';
import { Deductible } from '../models/response/Deductibles.model';

@Injectable({
  providedIn: "root",
})
export class BackupsStoreService {
  private stateAffiliateList = new BehaviorSubject<AffiliatesResponse>(
    new AffiliatesResponse()
  );
  public stateAffiliateList$ = this.stateAffiliateList.asObservable();

  private stateAffiliate = new Subject<AffiliatesListModel>();
  public stateAffiliate$ = this.stateAffiliate.asObservable();

  private stateSearchAffiliate = new Subject<SearchAffiliateModel>();
  public stateSearchAffiliate$ = this.stateSearchAffiliate.asObservable();

  private stateTransactionsToBackup = new BehaviorSubject<
    TransactionsToBackUpResponseModel[]
  >([new TransactionsToBackUpResponseModel()]);
  public stateTransactionsToBackup$ =
    this.stateTransactionsToBackup.asObservable();

  private stateBackupsActiveTab: BehaviorSubject<string> =
    new BehaviorSubject<string>("settlement-table");
  public stateBackupsActiveTab$ = this.stateBackupsActiveTab.asObservable();

  private stateBackupsActiveTabRestringed: BehaviorSubject<string> =
    new BehaviorSubject<string>("value-title");
  public stateBackupsActiveTabRestringed$ = this.stateBackupsActiveTabRestringed.asObservable();

  private stateSelectedResult: Subject<number> = new Subject<number>();
  public stateSelectResult$ = this.stateSelectedResult.asObservable();

  private stateShowSearchResult: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public stateShowSearchResult$ = this.stateShowSearchResult.asObservable();

  private statePaymentsPostBackingRequestModel =
    new BehaviorSubject<PaymentsPostBackingRequestModel>(
      new PaymentsPostBackingRequestModel()
    );
  public statePaymentsPostBackingRequestModel$ =
    this.statePaymentsPostBackingRequestModel.asObservable();

  private statesDeductibles: BehaviorSubject<Deductible []> =
    new BehaviorSubject<Deductible []>([]);
  public statesDeductibles$ = this.statesDeductibles.asObservable();

  constructor() {}

  changeStateAffiliateList(affiliateList: AffiliatesResponse) {
    //console.log("changeStateAffiliateList", affiliateList);
    this.stateAffiliateList.next(affiliateList);
  }

  changeStateAffiliate(affiliate: AffiliatesListModel) {
    this.stateAffiliate.next(affiliate);
    // console.log('state afi', affiliate);
  }

  changeStateSearchAffiliate(searchAffiliate: SearchAffiliateModel) {
    this.stateSearchAffiliate.next(searchAffiliate);
    //console.log("state search", searchAffiliate);
  }

  changeStateTransactionsToBackup(
    transactionsToBackup: TransactionsToBackUpResponseModel[]
  ) {
    this.stateTransactionsToBackup.next(transactionsToBackup);
  }

  setActiveTab(activeTab: string) {
    this.stateBackupsActiveTab.next(activeTab);
  }

  setSelectedIndexResult(selectedIndexResult: number) {
    this.stateSelectedResult.next(selectedIndexResult);
  }

  setShowSearchResult(showSearchResult: boolean) {
    this.stateShowSearchResult.next(showSearchResult);
  }

  setStatePaymentsPostBackingRequestModel(
    paymentsPostBackingRequestModel: PaymentsPostBackingRequestModel
  ) {
    this.statePaymentsPostBackingRequestModel.next(
      paymentsPostBackingRequestModel
    );
  }

  changeStateDeductibles(deductibles: Deductible  []) {
    this.statesDeductibles.next(deductibles);
    //console.log("state deductibles search", deductibles);
  }
}
