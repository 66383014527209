import { Component, Input, OnInit } from '@angular/core';
// import { QuotaDataForm, ReferenceResponse } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }

}
