import { ToastrService } from 'ngx-toastr';
import { SubjectService } from '@app/services/subject.service';
import { HelperService } from './../../../../services/helper.service';
import { QuotaHeadingTablesService } from './../../services/quota-heading-tables.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuotaGreaterService } from './../../../../services/quota-greater.service';
import { CoviResourceService } from './../../../../services/covi-resource.service';
import { ModalService } from './../../../../services/modal.service';
import { ListsCovinocService } from './../../../../services/lists-covinoc.service';
import { QuotaVsAffiliateHeadingTablesService } from './../../services/quota-vs-affiliate-heading-tables.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RelatedDocumentsService } from '../related-documents/services/related-documents.service';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss']
})
export class ContactDataComponent implements OnInit, OnChanges {

  @Input() documentInfo: any = {};

  @Input() citiesAndDepartments: any = [];

  public contactDataList: Array<any> = [];

  public contactAddressList: Array<any> = [];
  
  public contactEmailList: Array<any> = [];
  
  public isContactPhone: boolean = false;

  public isResetAddressForm: boolean = false;

  public totalPagesPhones = 1;

  public currentPagePhones = 1;

  public totalPagesAddress = 1;

  public currentPageAddress = 1;

  public totalPagesRepeatedPhones = 1;

  public currentPageRepeatedPhones = 1;
  
  public totalPagesEmail = 1;

  public currentPageEmail = 1;

  public phoneRepeated;

  public selectedRow;

  public selectedEmailRow;

  public rowList: Array<boolean> = [];

  public rowEmailList: Array<boolean> = [];

  repeatedPhonesData: Array<any> = [];

  constructor(
    public headingTable: QuotaVsAffiliateHeadingTablesService, 
    private listCovinoc: ListsCovinocService, 
    private modal: ModalService, 
    private coviResources: CoviResourceService, 
    private quotaGreater: QuotaGreaterService, 
    private spinner: NgxSpinnerService, 
    public headers: QuotaHeadingTablesService, 
    public helper: HelperService, 
    public subject: SubjectService, 
    private toastr: ToastrService,
    private relatedDocuments: RelatedDocumentsService
    ) { }

  ngOnInit() {
    /* this.subject.handleIsResetAddress$.subscribe((response)) */
  }

  public selectRow(id) {
    this.contactAddressList.forEach((item, key) => {
      if(id === key) {
        this.selectedRow = {
          id: id,
          sequence: item.sequence,
          address: item.address
        };
        this.rowList[key] = true;
      } else {
        this.rowList[key] = false;
      }
    });
    console.log(this.selectedRow);
  }

  public selectRowEmail(id) {
    this.contactEmailList.forEach((item, key) => {
      if(id === key) {
        this.selectedEmailRow = {
          secuencia: item.sequence,
        };
        this.rowEmailList[key] = true;
      } else {
        this.rowEmailList[key] = false;
      }
    });
    console.log(this.selectedEmailRow);
  }

  ngOnChanges() {
    console.log(this.documentInfo);
    this.getContactData();
  }

  handleContactMenu(option: string) {
    console.log(option);
    
    if(option === '#contactPhone') {
      console.log('contactPhone');
      
    } else if(option === '#contactAddress') {
      console.log('contactAddress');
      this.getContactAddress();
    } else if(option === '#contactRelatedDocuments') {
      console.log('contactRelatedDocuments');
      this.getRelatedDocuments();
    } else {
      console.log("ContactEmail");
      this.getContactEmail();
    }
  }

  getRelatedDocuments() {
    this.spinner.show();
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: 1,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.relatedDocuments.getRelatedDocuments(bodyParams).subscribe((response: any) => {

      this.spinner.hide();
      console.log(this.documentInfo);
      
      this.totalPagesAddress = response.totalPages;
      this.contactAddressList = response.data;
      this.contactDataList.forEach((ite, key) => {
        this.rowList[key] = false;

      });
      this.selectedRow = undefined;
    }, (error) => {
      this.contactAddressList = null;
      console.log(this.documentInfo);
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }

  getContactData() {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: 1,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactData(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.contactDataList = response.data;
      this.totalPagesPhones = response.totalPages;
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }

  getContactEmail() {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: 1,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactEmail(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.contactEmailList = response.data;
      this.totalPagesEmail = response.totalPages;
      this.contactEmailList.forEach((ite, key) => {
        this.rowEmailList[key] = false;
        
      });
      this.selectedEmailRow = undefined;
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }

  getContactAddress() {
    this.spinner.show();
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: 1,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactAddress(bodyParams).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.totalPagesAddress = response.totalPages;
      this.contactAddressList = response.data;
      this.contactDataList.forEach((ite, key) => {
        this.rowList[key] = false;
        
      });
      this.selectedRow = undefined;
      console.log(this.selectedRow);
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }

  deleteAddress() {
    this.modal.show("confirmDeleteAddress")
  }

  deleteEmail() {
    this.modal.show("confirmDeleteAddress")
  }

  showCreatePhoneModal() {
    this.modal.show('createPhone');
  }

  handleConfirmDeleteAddress() {
    this.spinner.show();
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      secuencia: this.selectedRow.sequence,
      direccion: this.selectedRow.address
    }

    this.quotaGreater.deleteAddress(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.modal.close();
      this.getContactAddress();
      this.toastr.success(response.message)
    },(error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  handleConfirmDeleteEmail() {
    this.spinner.show();
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      secuencia: this.selectedEmailRow.secuencia,
    }

    this.quotaGreater.deleteEmail(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.spinner.hide();
      this.modal.close();
      this.getContactEmail();
      this.toastr.success(response.message)
    },(error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  showCreateAddressModal() {
    console.log("showCreateAddressModal");
    this.subject.changeHandleIsResetAddress(true);
    console.log(this.isResetAddressForm);
    
    this.modal.show('createAddress');
  }

  showCreateEmailModal() {
    console.log("showCreateEmailModal");    
    this.modal.show('createEmail');
  }

  goToPagePhones($event) {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: $event.currentPage,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactData(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.totalPagesPhones = response.totalPages;
      this.currentPagePhones = response.currentPage;
      this.contactDataList = response.data;
    });
  }

  goToPageAddress($event) {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: $event.currentPage,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactAddress(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.currentPageAddress = response.currentPage;
      this.totalPagesAddress = response.totalPages;
      this.contactAddressList = response.data;
    });
  }

  goToPageEmail($event) {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: $event.currentPage,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getContactEmail(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.currentPageEmail = response.currentPage;
      this.totalPagesEmail = response.totalPages;
      this.contactEmailList = response.data;
    });
  }

  clickCell(phone) {
    this.phoneRepeated = phone;
    this.spinner.show();
    console.log("Click cell");
    const bodyParams = {
      telefono: phone,
      page: 1,
      pageSize: 10,
    }
    this.quotaGreater.getRepeatedPhones(bodyParams).subscribe((resp: any) => {
      console.log(resp);
      this.repeatedPhonesData = resp;
      this.spinner.hide();
      this.modal.show('repeated-phones');
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  goToRepeatedPhones($event) {
    this.spinner.show();
    console.log("Click cell");
    const bodyParams = {
      telefono: $event.phone,
      page: $event.currentPage,
      pageSize: 10,
    }
    this.quotaGreater.getRepeatedPhones(bodyParams).subscribe((resp: any) => {
      console.log(resp);
      this.repeatedPhonesData = resp;
      this.spinner.hide();
      this.modal.show('repeated-phones');
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

}
