import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HelperService } from "@app/services/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MainProviders } from "../models/mainProviders.model";
import { TermType } from "../models/termType.model";

@Component({
  selector: "app-main-suppliers",
  templateUrl: "./main-suppliers.component.html",
  styleUrls: ["./main-suppliers.component.scss"],
})
export class MainSuppliersComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;
  @Input() terms: Array<TermType>;

  private editField: string;
  private propertySaved: string;
  public personList: Array<any> = [];
  private idFieldPerson: number = 0;
  private idList: number;

  financialQuery = false;

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    public helper: HelperService
  ) {}

  ngOnInit() {
    if(this.infoStudyQuote.get('financialInfo') && this.infoStudyQuote.get('financialInfo').value) this.onChangesFinancialSources();
    let suppliers = this.infoStudyQuote.controls.suppliers.value;
    if(suppliers.length > 0) {
      this.personList = suppliers;
    }
  }


   /**
   * Is in charge of filling out The Main Suppliers when information is found 
   * in other forms of 20m in consultation of financial information
   * @author Rafael Ramirez
   */  
  onChangesFinancialSources() {
    this.infoStudyQuote.get("suppliers").valueChanges.subscribe(mainProviders => {
      let updatedPersonsList = [];
      mainProviders.forEach((mainProvider: MainProviders, index: number)=>{
        let person = {
          id: index + 1,
          name: mainProvider.name,
          suppliesYou: mainProvider.supplies,
          term: mainProvider.term,
          flag: true
        }
        updatedPersonsList.push(person);
      });
      this.personList = updatedPersonsList;
      this.financialQuery = true;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.infoStudyQuote.controls;
  }

  updateList(id: number, property: string, event: any) {
    if(this.financialQuery) id++;
    if (this.propertySaved == property && this.idList == id) {
      this.personList[id][property] = this.fieldPropertyAdd(this.editField);
      this.infoStudyQuote.controls.suppliers.setValue(this.personList);
      if (this.propertySaved == "term") {
        return event.target.value = this.editField
      }
      return (event.target.textContent =
        property == "name"
          ? this.editField
              .replace(/[^a-zA-Z\ ]*/g, "")
              .replace(/[^a-zA-Z\ ]*/g, "")
          : this.editField);
    }
  }

  fieldPropertyAdd(value: string): string {
    return value;
  }

  remove(id: any) {
    this.personList.splice(id, 1);
  }

  add() {
    this.idFieldPerson = this.idFieldPerson + 1;
    const person = { id: this.idFieldPerson, flag: false };
    this.personList.push(person);
  }

  changeValue(id: number, property: string, event: any) {
    this.editField =
      property == "name"
        ? event.target.textContent
            .replace(/[^a-zA-Z\ ]*/g, "")
            .replace(/[^a-zA-Z \ ]*/g, "")
        : property != "term"
        ? event.target.textContent
        : event.target.value;
    this.propertySaved = property;
    this.idList = id;
  }
}
