
export class QuotaGreaterThan20MRequest {
  state: string;
  indetification: Number;
  identificationType: string;
  settlement: Number;

  public static toRequest(
    quotaGreaterThan20MRequest: QuotaGreaterThan20MRequest
  ): any {
    return {
      settlement: quotaGreaterThan20MRequest.settlement ? quotaGreaterThan20MRequest.settlement : 0
    };
  }
}
