import { Component, Input, OnInit } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-backing-study',
  templateUrl: './backing-study.component.html',
  styleUrls: ['./backing-study.component.scss']
})
export class BackingStudyComponent implements OnInit {

  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }
}
