import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Pipe({
  name: 'nullDateReplacer'
})
export class NullDateReplacerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let date = new moment(value).format("DD-MM-YYYY");
    return (value === '1900-01-01 00:00:00' || value === null) ? '--' : date;
  }

}
