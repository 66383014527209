import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PaginationComponent } from "@app/components/layout/pagination/pagination.component";
import { SelectModel } from "@app/models/select.model";
import { BackupsService } from "@app/services/backups.service";
import { HelperService } from "@app/services/helper.service";
import { SelectsService } from "@app/services/selects.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import Swal from "sweetalert2";

import { OptionsExpressReport } from "@app/models/express.models";
import { ExpressReportService } from "@app/services/express-report.service";
import { ModalService } from "@app/services/modal.service";
import { ReportRequest } from "../backups/models/request/Report.model";
import { ExpressReportModel } from "../backups/models/response/ExpressReport";
import { ReportResponseModel } from "../backups/models/response/Report.model";
import { BackupsTableHeadersService } from "../backups/services/backups-table-headers.service";


@Component({
  selector: "app-express-report",
  templateUrl: "./express-report.component.html",
  styleUrls: ["./express-report.component.scss"],
})
export class ExpressReportComponent implements OnInit, OnDestroy {
  @ViewChild("paginationComponent") paginationComponent: PaginationComponent;
  @ViewChild("bodyTable") bodyTable: ElementRef;

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public search: ReportRequest = new ReportRequest();

  public reportResponse: ReportResponseModel[] = [];
  public reportExpressResponse: ExpressReportModel[] = [];
  public uploadRadication: number =0;
  public product: string='';
  public selectOptionsBranchOffices: SelectModel[] = [];
  public optionsFilters = OptionsExpressReport;
  public contractNumber: number;
  public documentAffiliate: string='';
  public selectedFilterOption: string ='';
  public data: ExpressReportModel[] = [];
  public filter:string ='';
  public selectedContract: any;
  public showButton: boolean=false;

  public subs1: Subscription;
  public selectOptions: SelectModel[] = [];
  public branchOffices$ = this.selects.getSelectTypes("branchOffices");

  public initDateInput: Date;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public selects: SelectsService,
    private spinner: NgxSpinnerService,
    public backupsService: BackupsService,
    public helper: HelperService,
    public tableHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public expressReport: ExpressReportService
  ) {
    this.getSelects();
  }

  ngOnInit(): void {
    this.getBranchOffices();
    this.generalExpressReport();
    this.searchContractNumber()


  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSelects() {
    this.selects
      .getSelectTypes("branchOffices")
      .subscribe(
        (branchOffices) =>
          (this.selectOptionsBranchOffices = branchOffices || [
            new SelectModel(),
          ])
      );
  }

  getBranchOffices(): void {
    this.selects
      .getBranchOffices()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((branchOffices) => (this.selectOptions = branchOffices));
  }

  goToPage({ currentPage }): void {
    this.currentPage = currentPage;
    this.generalExpressReport();
  }

  handleGetReportError(error?: any): void {
    console.error(error);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title: "Reporte express",
        text: `${message}`,
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.reportResponse = [];
    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination(): void {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  openModal() {
    this.modal.show("expressReport", {
       contract: this.selectedContract,
       uploadRadication: this.uploadRadication,
       product: this.product,
      });
  }
  generalExpressReport()  {
    this.spinner.show();
    const parametersExpressReport = {
      contract: 0, // Contrato ejemplo: 523559
      uploadFilter: this.selectedFilterOption, //estados: resumen, aprobado, pendiente
      currentPage:  this.currentPage,
      perPage: 10,
    };

    this.expressReport.searchUpload(parametersExpressReport).subscribe(
      (reportExpressResponse: ExpressReportModel) => {
        if (!reportExpressResponse) {
          this.handleGetReportError();
          return;
        }
        this.reportExpressResponse = reportExpressResponse["data"];
        this.cantPages = reportExpressResponse["totalPages"];
        this.data=this.reportExpressResponse;
        this.spinner.hide()
      },
      (error: any) => {
        this.handleGetReportError(error);
      }
    );
    this.currentPage=1;
    this.filter=null;
    this.contractNumber=null;
    }
  searchContractNumber() {
    this.reportExpressResponse= this.data.filter(
      (item: any) => item.contract == this.contractNumber
    );
  }

  searchDocument() {
    this.reportExpressResponse= this.data.filter(
      (item: any) => item.documentAffiliate == this.documentAffiliate
    );
  }

  selectContract(report:any) {
    const selectedContract = report.contract;
    const uploadRadication = report.uploadRadication;
    const product = report.product;
    this.selectedContract= selectedContract;
    this.uploadRadication= uploadRadication;
    this.product=product;
    this.openModal();
  }

  refresh() {
    this.showButton = false;
    this.generalExpressReport();
  }

  reset() {
    this.reportExpressResponse = [];
    this.cantPages = 0;
    this.currentPage=1;
  }



}


/*
enviarParametros() {
  const parametros = {
    contract: 0, // Contrato ejemplo: 523559
    uploadFilter: "pendiente", //estados: resumen, aprobado, pendiente
    currentPage: 1,
    perPage: 10,
  };
  this.spinner.show();

  );
} */
