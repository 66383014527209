import { Component, OnInit } from "@angular/core";
import { NetFactoringBackEndService } from "@app/services/net-factoring-back-end.service";
import { FilterAffiate } from "../models/Request/FilterAffiliate";
import { NetFactoringService } from "../services/net-factoring.service";
import { PossibleInvoices } from "@app/pages/net-factoring/models/PossibleInvoices";
import { Response } from "@app/models/responseUtil.model";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-possible-invoices",
  templateUrl: "./possible-invoices.component.html",
  styleUrls: ["./possible-invoices.component.scss"],
})
export class PossibleInvoicesComponent implements OnInit {
  filterAffiliate: FilterAffiate;

  possibleInvoicesList:Array<PossibleInvoices> =[];

  public typePerson: string = "natural";

  currentPage: number = 1;
  cantPages = 0;

  reload = false;

  constructor(
    public netFactoringService: NetFactoringService,
    public netFactoringBackendService: NetFactoringBackEndService,
    private spinner: NgxSpinnerService,
  ) {
    this.filterAffiliate = this.netFactoringService.filterAfilliatePossible;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.filterAffiliate.typeReport = this.netFactoringService.typeReport[0];
    this.clearData();
    this.netFactoringBackendService
      .getInvoices(this.currentPage,this.filterAffiliate.typeReport)
      .subscribe(
        (response: Response<PossibleInvoices>) => {
          this.possibleInvoicesList = response.data;
          this.cantPages = response.totalPages;
          this.currentPage = response.currentPage;
          this.spinner.hide();
        },
        (error) => {
          this.possibleInvoicesList = [];
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  reLoad() {
    this.filterAffiliate.socialReason = null;
    this.filterAffiliate.firstName = null;
    this.filterAffiliate.secondName = null;
    this.filterAffiliate.firstSurname = null;
    this.filterAffiliate.secondSurname = null;
    this.getData();
    this.reload = false;
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getData();
  }

  clearData() {
    this.filterAffiliate.socialReason
      ? true
      : (this.filterAffiliate.socialReason = null);
    this.filterAffiliate.firstName
      ? true
      : (this.filterAffiliate.firstName = null);
    this.filterAffiliate.secondName
      ? true
      : (this.filterAffiliate.secondName = null);
    this.filterAffiliate.firstSurname
      ? true
      : (this.filterAffiliate.firstSurname = null);
    this.filterAffiliate.secondSurname
      ? true
      : (this.filterAffiliate.secondSurname = null);
  }

  getDataFilterAffiliate() {
    this.spinner.show();
    this.clearData();
    this.reload = true;
    this.filterAffiliate.typeQuery = this.netFactoringService.typeQuery[0];
    this.filterAffiliate.currentPage = this.currentPage;
    this.filterAffiliate.perPage = 10;
    this.netFactoringBackendService
      .getFilerAffiliate(this.filterAffiliate)
      .subscribe(
        (response: Response<PossibleInvoices>) => {
          this.possibleInvoicesList = response.data;
          this.cantPages = response.totalPages;
          this.currentPage = response.currentPage;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.possibleInvoicesList = [];
          console.log(error);
        }
      );
  }
}
