import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  OnDestroy
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  NgModel,
  Validators,
} from "@angular/forms";
import { BuyerAffiliate } from "./models/buyerAffiliate.model";
import { QuotaGreaterThan20M } from "./models/quotaGreaterThan20M.model";
import { ReferenceMunicipalities } from "./models/referenceMunicipalities.model";
import { QuotaGreaterThan20MRequest } from "./models/request/quotaGreaterThan20MRequest.model";
import { QuotaGreaterThan20MResponse } from "./models/response/quotaGreaterThan20MResponse.model";
import { HelperService } from "@app/services/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralInfo } from "./models/generalInfo.model";
import { HolderValidations } from "./models/holderValidations.model";
import { ExecutionProject } from "./models/executionProject.model";
import { MainProviders } from "./models/mainProviders.model";
import { MainClients } from "./models/mainClients.model";
import { Reference } from "./models/reference.model";
import { Description } from "./models/description.model";
import { CurrentExhibition } from "./models/currentExhibition.model";
import * as moment from "moment";
import { TermType } from "./models/termType.model";
import { Macros } from "./models/macros.model";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import { QuotaService } from "./services/quota-greateer.service";
import { Response } from "../../models/responseUtil.model";
import { FinancialQueryRequest } from "./models/request/financialQueryRequest.model";
import {
  FinancialQuery,
  GeneralBalance,
  StatesPyG,
  SuperSociety,
} from "./models/financialQuery.model";
import { ToastrService } from "ngx-toastr";
import { SubjectService } from "@app/services/subject.service";
import { MacrosService } from "./services/macros.service";
import { Draft } from "./models/Draft.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-quota",
  templateUrl: "./quotas.component.html",
  styleUrls: ["./quotas.component.scss"],
})
export class QuotasComponent
  implements OnInit, AfterContentInit, AfterViewInit, OnDestroy
{
  time;
  public tabs: Array<any> = [
    {
      title: "Estudio De Respldo",
      active: true,
    },
    {
      title: "Información Financiera",
      active: false,
    },
  ];

  infoGeneralForm: FormGroup;
  searchBySettlement: Boolean = true;
  secondServiceFinished: Boolean = false;
  infoStudyQuote: FormGroup;
  public departments: Array<{}> = [];
  private departmentsN: Array<string> = [];
  public referencesMunicipalitiesList: Array<ReferenceMunicipalities> = [];
  public termsList: Array<TermType> = [];

  private quotaGreaterRequest = new QuotaGreaterThan20MRequest();
  public quotaGreater = new QuotaGreaterThan20M();

  // Financial Query Request
  public years: number[] = [];
  public counterRequest: number = 0;
  public financialQueryRequest: FinancialQueryRequest =
    new FinancialQueryRequest();
  public financialQuery: Array<FinancialQuery> = [];
  public reference2: boolean = false;
  public error: boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private router: Router,
    private quotaGreaterService: QuotaGreaterService,
    private quotaService: QuotaService,
    public helper: HelperService,
    private toastr: ToastrService,
    private subject: SubjectService,
    private macrosService: MacrosService
  ) {
    this.years = this.fillYearsList();
  }

  ngOnInit() {
    let idSettlement = localStorage.getItem("settlementNumber")
      ? localStorage.getItem("settlementNumber")
      : "";
    localStorage.removeItem("settlementNumber");
    this.infoGeneralForm = this.infoForm.group({
      settlement: [idSettlement, Validators.required],
    });
    this.getDepartmentsMunicipalities();
    this.getTerms();
    this.quotaGreaterService.getDocumentsType();
    this.quotaGreaterService.getRequestType();
    this.quotaGreaterService.getTelephoneType().subscribe((resp) => {});
    this.quotaGreaterService.getBanks();
    console.log(this.router.url);
  }

  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);
    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs[0]);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    console.log("STOP....");
    clearInterval(this.time);
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.infoGeneralForm.controls;
  }

  backToSearch() {
    this.secondServiceFinished = false;
    this.searchBySettlement = true;
    clearInterval(this.time);
  }

  onSubmit() {
    localStorage.removeItem("currentExhibition");
    this.spinner.show();
    this.quotaGreaterRequest.settlement = this.infoGeneralForm.value.settlement;
    this.quotaGreaterService
      .getQuotaGreaterThan20M(this.quotaGreaterRequest)
      .subscribe(
        (resp: Response<QuotaGreaterThan20M>) => {
          let buyerAffiliate = new BuyerAffiliate();
          this.searchBySettlement = false;
          buyerAffiliate.document = resp.data[0].identification;

          // Financial Query
          this.financialQueryRequest.nit = resp.data[0].identification;
          // supersocieades 900182199
          //this.financialQueryRequest.nit = 900182199;

          buyerAffiliate.documentType = resp.data[0].identificationType;

          this.quotaGreaterService.getAfilliateBuyer(buyerAffiliate).subscribe(
            (res: Response<BuyerAffiliate>) => {
              if (res.data && res.data.length > 0) {
                this.anotherForm(resp.data[0]);
              }
            },
            (error) => {
              if (error.status == 404) {
                this.anotherForm(resp.data[0]);
                return;
              }
              this.searchBySettlement = true;
              Swal.fire({
                type: "warning",
                title: "Upps ocurrio algo",
                text: error.error.message,
              }).then(() => {});
              this.spinner.hide();
            }
          );
        },
        (error) => {
          this.searchBySettlement = true;
          Swal.fire({
            type: "warning",
            title: "Upps ocurrio algo",
            text: error.error.message,
          }).then(() => {});
          this.spinner.hide();
          return;
        }
      );
  }

  timeDraft() {
    return setInterval(() => {
      if (this.secondServiceFinished) {
        let data = this.infoStudyQuote.value;

        let currentExhibitionData = JSON.parse(
          localStorage.getItem("currentExhibition")
        );

        data.currentExhibition = currentExhibitionData
          ? currentExhibitionData
          : [];

        let macros = {
          HEADERS: this.macrosService.headers,
          HEADERS_STATE: this.macrosService.headersPGState,
          HEADERS_FR: this.macrosService.headersFR,
          GENERAL_BALANCE: this.macrosService.generalBalance,
          STATES_PG: this.macrosService.pgState,
          FINANCIAL_REASONS: this.macrosService.financialReasons,
        };

        data.macros = macros;
        let draft: Draft = {
          settlement: this.infoGeneralForm.value.settlement,
          form: JSON.stringify(data),
        };
        this.quotaGreaterService.postDraft(draft).subscribe(
          () => {
            this.toastr.success(
              this.infoGeneralForm.value.settlement,
              "Se guardo correctamente el borrador"
            );
          },
          (error) => {
            console.error(error.error.message);
          }
        );
      }
    }, 10 * 1000);
  }

  anotherForm(resp?: QuotaGreaterThan20M) {
    this.spinner.hide();
    this.quotaGreater = resp;

    let dateNow = new Date();
    let startHour =
      dateNow.getHours() +
      ":" +
      dateNow.getMinutes() +
      ":" +
      dateNow.getSeconds();

    this.infoStudyQuote = this.infoForm.group({
      quoteStudy: [
        { value: resp.promissoryNote, disabled: true },
        Validators.required,
      ],
      product: [{ value: resp.product, disabled: true }, Validators.required],
      afilliate: [
        { value: resp.businessName, disabled: true },
        Validators.required,
      ],
      state: [
        {
          value: this.quotaService.converQuotaState(resp.state),
          disabled: true,
        },
        Validators.required,
      ],
      requestType: ["", Validators.required],
      settlement: [
        { value: resp.settlement, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      receiveDate: [
        {
          value: resp.receptionDate,
          disabled: true
            ? resp.receptionDate != "" && resp.receptionDate != null
            : false,
        },
        [Validators.required],
      ],
      receiveHour: [
        {
          value: resp.receptionHour,
          disabled: true
            ? resp.receptionHour != "" && resp.receptionDate != null
            : false,
        },
        [Validators.required, Validators.maxLength(8)],
      ],
      startDate: [{ value: dateNow, disabled: true }, Validators.required],
      startHour: [
        { value: startHour, disabled: true },
        [Validators.required, Validators.maxLength(8)],
      ],
      responseDate: ["", [Validators.required]],
      responseHour: ["", Validators.required],
      afilliateRisk: [
        { value: resp.classification, disabled: true },
        Validators.required,
      ],
      minQuote: ["", Validators.required],
      medQuote: ["", Validators.required],
      maxQuote: ["", Validators.required],
      maxDocQuote: ["", Validators.required],
      //Inicia la sección información de comprador
      docType: [
        {
          value: resp.identificationType,
          disabled: true
            ? resp.identificationType != "" && resp.identificationType != null
            : false,
        },
        Validators.required,
      ],
      document: [
        {
          value: resp.identification,
          disabled: true
            ? resp.identification != 0 && resp.identification != null
            : false,
        },
        Validators.required,
      ],
      name: [
        {
          value: resp.names,
          disabled: true ? resp.names != "" && resp.names != null : false,
        },
        Validators.required,
      ],
      dateExpDoc: [
        {
          value: resp.docExpDate,
          disabled: true
            ? resp.docExpDate != "" && resp.docExpDate != null
            : false,
        },
        Validators.required,
      ],
      placeExpDoc: [
        {
          value: resp.placeExpDoc,
          disabled: true
            ? resp.placeExpDoc != "" && resp.placeExpDoc != null
            : false,
        },
        Validators.required,
      ],
      legalRepresentative: [""],
      documentTypeLR: [""],
      documentLR: ["", [Validators.maxLength(12)]],
      dateExpDocLR: [""],
      placeExpDocLR: [""],
      bornDate: [""],
      address: ["", Validators.required],
      department: ["", Validators.required],
      city: ["", Validators.required],
      covering: [{ value: "", disabled: true }, Validators.required],
      telephone: ["", [Validators.maxLength(10)]],
      cellphone: ["", [Validators.required, Validators.maxLength(10)]],
      email: [
        "",
        [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      ],
      constitution: [""],
      validity: [""],
      assets: [""],
      socialObject: ["", Validators.required],
      authorizedCapital: [""],
      paidInCapital: [""],
      //Validaciones de Titular y Relacionados
      holderRelated: [[]],
      observationsHRV: [""],
      //Resumen Centrales de Riesgo
      ciiu: [
        {
          value: resp.names,
          disabled: true ? resp.names != "" && resp.names != null : false,
        },
      ],
      bank: [""],
      draftAccount: [""],
      stateCB: [""],
      opening: [""],
      totalNoSF: [{ value: 0, disabled: true }, Validators.required],
      totalNoSR: [{ value: 0, disabled: true }, Validators.required],
      totalNoTC: [{ value: 0, disabled: true }, Validators.required],
      totalSF: [{ value: 0, disabled: true }, Validators.required],
      totalSR: [{ value: 0, disabled: true }, Validators.required],
      totalTC: [{ value: 0, disabled: true }, Validators.required],
      arrearsNSF: [{ value: 0, disabled: true }, Validators.required],
      arrearsNSR: [{ value: 0, disabled: true }, Validators.required],
      arrearsNTC: [{ value: 0, disabled: true }, Validators.required],
      arrearsSF: [{ value: 0, disabled: true }, Validators.required],
      arrearsSR: [{ value: 0, disabled: true }, Validators.required],
      arrearsTC: [{ value: 0, disabled: true }, Validators.required],
      heightMaxSF: [{ value: 0, disabled: true }, Validators.required],
      heightMaxSR: [{ value: 0, disabled: true }, Validators.required],
      heightMaxTC: [{ value: 0, disabled: true }, Validators.required],

      currentAccounts: [""],
      garnishedAccounts: [""],
      totalIndebtedness: [""],
      currentBalance: [""],
      balanceAsPrincipal: [""],
      porAsPrincipal: [""],
      balanceInArrears: [""],
      quota: [""],
      totalPaymentValueFee: [""],
      maxCalification: [""],
      use: [""],
      observationsCB: [""],
      //Resumen de Localizacion y Contacto
      contactPerson: [""],
      position: [""],
      telType: [""],
      telephoneLRC: [""],
      emailLRC: [
        "",
        [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      ],
      employees: [""],
      pointsSale: [""],
      halfContact: [""],
      longStandingAffiliateLink: [""],
      providesYou: [""],
      telWasLocated: [""],
      telTypeLocated: [""],
      departmentLRC: [""],
      cityLRC: [""],
      observationLRC: [""],
      comments: ["", Validators.required],
      // Proyectos en ejecución
      projects: this.infoForm.array([
        this.infoForm.group({
          name: [""],
          executionPercentage: [""],
          workValue: [""],
          annualAdvancePercentage: [""],
          completionDate: [""],
        }),
      ]),
      projectFinishDate: [""],
      // Principales proveedores
      suppliers: [[]],
      // Principales Centrales
      clients: [[]],
      // Referencia
      nameRef: [""],
      nitRef: [""],
      cityRef: [""],
      phoneRef: [""],
      personRef: [""],
      positionRef: [""],
      clientsFrom: [""],
      supplies: [""],
      quotaRef: [""],
      termRef: [""],
      paymentMethod: [""],
      qualificationRef: [""],
      nameRef2: [""],
      nitRef2: [""],
      cityRef2: [""],
      phoneRef2: [""],
      personRef2: [""],
      positionRef2: [""],
      clientsFrom2: [""],
      supplies2: [""],
      quotaRef2: [""],
      termRef2: [""],
      paymentMethod2: [""],
      qualificationRef2: [""],
      // Descripción de la solicitud
      postdated: [""],
      quotaDR: [""],
      codeDR: [""],
      maxCodeCap: [""],
      maxContractLimit: [""],
      purchaseObject: [""],
      afilFeesN: [""],
      feesRequestedN: [""],
      commercialValue: [""],
      paymentMethodDR: [""],
      // Exposicion Vigente
      currentExhibition: [[]],
      quotaCE: [{ value: 0, disabled: true }, Validators.required],
      fullGuarantee: [{ value: 0, disabled: true }, Validators.required],
      totalCurrentGuarantee: [
        { value: 0, disabled: true },
        Validators.required,
      ],
      quotaCEFE: [{ value: 0, disabled: true }, Validators.required],
      totalCurrentGuaranteeFE: [
        { value: 0, disabled: true },
        Validators.required,
      ],
      cvsNo2: [{ value: 0, disabled: true }, Validators.required],
      recMaxDays2: [{ value: 0, disabled: true }, Validators.required],
      cvsNo: [{ value: 0, disabled: true }, Validators.required],
      recMaxDays: [{ value: 0, disabled: true }, Validators.required],
      notPayNotices: [{ value: 0, disabled: true }, Validators.required],
      extensions: [{ value: 0, disabled: true }, Validators.required],
      observationsCE: [""],
      // Macros
      macros: [{}],
      observationsMacro: [""],
      variationInSales: [""],
      variationInDebt: [""],
      variationInInvTurn: [""],
      variationInPortRotation: [""],
      variationInNetInc: [""],
      observationVar: [""],

      macrosInput: [[]],
    });

    this.draftForm();

    this.time = this.timeDraft();

    this.helper.markFormGroup(this.infoStudyQuote, true);
    this.spinner.hide();
  }

  draftForm() {
    this.spinner.show();
    this.quotaGreaterService
      .getFormQuotaDraft(this.quotaGreaterRequest.settlement)
      .subscribe(
        (response) => {
          if (response.ifDraft) {
            Swal.fire({
              type: "info",
              title: ``,
              text: `¿Desea continuar con un borrador del radicado?`,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancelar",
              confirmButtonText: `Confirmar`,
              allowOutsideClick: false,
            }).then((value) => {
              if (value.value === true) {
                let data = JSON.parse(response.draftForm);
                localStorage.setItem(
                  "currentExhibition",
                  JSON.stringify(data.currentExhibition)
                );

                let projectsFromDraft = data.projects;

                delete data.projects;
                this.infoStudyQuote.patchValue(data);
                this.infoStudyQuote.controls.projects.value;

                let projects = this.infoStudyQuote.controls
                  .projects as FormArray;
                projects.removeAt(0);
                projectsFromDraft.forEach((project) => {
                  projects.push(
                    this.infoForm.group({
                      name: [project.name],
                      execPercentage: [project.execPercentage],
                      workValue: [project.workValue],
                      perAnnualAdvance: [project.perAnnualAdvance],
                      finishDate: [project.finishDate],
                    })
                  );
                });
                this.secondServiceFinished = true;

                this.macrosService.headers = data.macros.HEADERS;
                this.macrosService.headersPGState = data.macros.HEADERS_STATE;
                this.macrosService.headersFR = data.macros.HEADERS_FR;
                this.macrosService.generalBalance = data.macros.GENERAL_BALANCE;
                this.macrosService.pgState = data.macros.STATES_PG;
                this.macrosService.financialReasons =
                  data.macros.FINANCIAL_REASONS;
              } else {
                this.secondServiceFinished = true;
              }
              this.spinner.hide();
            });
          } else {
            this.spinner.hide();
            this.secondServiceFinished = true;
          }
        },
        (error) => {
          console.error(error.error.message);
          this.secondServiceFinished = true;
        }
      );
  }

  getTerms() {
    this.quotaGreaterService
      .getTermType()
      .subscribe((resp: Response<TermType>) => {
        if (resp.data && resp.data.length > 0) {
          this.termsList = resp.data;
        }
      });
  }

  getDepartmentsMunicipalities() {
    this.quotaGreaterService
      .getDepartmentsCities()
      .subscribe((resp: Response<ReferenceMunicipalities>) => {
        if (resp.data && resp.data.length > 0) {
          this.fillDepartments(resp.data);
        }
      });
  }

  fillDepartments(response: Array<ReferenceMunicipalities>) {
    this.referencesMunicipalitiesList = response;
    response.forEach((element) => {
      let department = {};
      if (this.departmentsN.length == 0) {
        department = {
          id: Number(element.department),
          name: element.nameDepartment,
        };
        this.departmentsN.push(element.nameDepartment);
        this.departments.push(department);
      }
      if (!this.departmentsN.includes(element.nameDepartment)) {
        department = {
          id: Number(element.department),
          name: element.nameDepartment,
        };
        this.departmentsN.push(element.nameDepartment);
        this.departments.push(department);
      }
    });
  }

  onSubmitForm() {
    this.spinner.show();
    let genInfo: GeneralInfo = new GeneralInfo();
    delete genInfo.id;
    let holderValidationsList: Array<HolderValidations> = [];
    let projectsExecList: Array<ExecutionProject> = [];
    let mainProvidersList: Array<MainProviders> = [];
    let mainClientsList: Array<MainClients> = [];
    let referenceList: Array<Reference> = [];
    let description: Description = new Description();
    delete description.id;
    let currentExhibitionList: Array<CurrentExhibition> = [];

    genInfo.studyType = this.infoStudyQuote.controls.quoteStudy.value;
    genInfo.product = this.infoStudyQuote.controls.product.value;
    genInfo.affiliate = this.infoStudyQuote.controls.afilliate.value;
    genInfo.state = this.infoStudyQuote.controls.state.value;
    genInfo.requestType = this.infoStudyQuote.controls.requestType.value;
    genInfo.settlement = Number(this.infoStudyQuote.controls.settlement.value);
    genInfo.receivedDate = this.infoStudyQuote.controls.receiveDate.value;
    genInfo.receivedHour = this.infoStudyQuote.controls.receiveHour.value;
    genInfo.startDate = this.infoStudyQuote.controls.startDate.value;
    genInfo.startHour = this.infoStudyQuote.controls.startHour.value;

    genInfo.responseDate = this.infoStudyQuote.controls.responseDate.value;
    genInfo.responseHour = this.infoStudyQuote.controls.responseHour.value;

    genInfo.affiliateRisk = this.infoStudyQuote.controls.afilliateRisk.value;
    genInfo.minimumQuota = this.infoStudyQuote.controls.minQuote.value;
    genInfo.mediumQuota = this.infoStudyQuote.controls.medQuote.value;
    genInfo.maxQuota = this.infoStudyQuote.controls.maxQuote.value;
    genInfo.maxDocsQuota = this.infoStudyQuote.controls.maxDocQuote.value;
    genInfo.documentType = this.infoStudyQuote.controls.docType.value;
    genInfo.buyerDoc = this.infoStudyQuote.controls.document.value;
    genInfo.names = this.infoStudyQuote.controls.name.value;
    genInfo.expeditionDate = this.infoStudyQuote.controls.dateExpDoc.value;
    genInfo.expeditionPlace = this.infoStudyQuote.controls.placeExpDoc.value;
    genInfo.nameLegalRepresentative =
      this.infoStudyQuote.controls.legalRepresentative.value;
    genInfo.typeDocLegalRepresentative =
      this.infoStudyQuote.controls.documentTypeLR.value;
    genInfo.documentLegalRepresentative =
      this.infoStudyQuote.controls.documentLR.value;
    genInfo.expeditionDateLegalRepresentative =
      this.infoStudyQuote.controls.dateExpDocLR.value;
    genInfo.expeditionPlaceLegalRepresentative =
      this.infoStudyQuote.controls.placeExpDocLR.value;
    genInfo.birthDateLegalRepresentative =
      this.infoStudyQuote.controls.bornDate.value;
    genInfo.address = this.infoStudyQuote.controls.address.value;
    genInfo.idDepartment = this.infoStudyQuote.controls.department.value;
    genInfo.idCity = this.infoStudyQuote.controls.city.value;
    genInfo.covering = this.infoStudyQuote.controls.covering.value;
    genInfo.phone = this.infoStudyQuote.controls.telephone.value;
    genInfo.cellphone = this.infoStudyQuote.controls.cellphone.value;
    genInfo.email = this.infoStudyQuote.controls.email.value;
    genInfo.constitution = this.infoStudyQuote.controls.constitution.value;
    genInfo.validity = this.infoStudyQuote.controls.validity.value;
    genInfo.assets = this.infoStudyQuote.controls.assets.value;
    genInfo.socialObject = this.infoStudyQuote.controls.socialObject.value;
    genInfo.authorizedCapital =
      this.infoStudyQuote.controls.authorizedCapital.value;
    genInfo.paidInCapital =
      this.infoStudyQuote.controls.authorizedCapital.value;

    //Holder Validations
    this.infoStudyQuote.controls.holderRelated.value.forEach((element) => {
      let holderValidations: HolderValidations = new HolderValidations();
      delete holderValidations.id;

      holderValidations.affiliateState =
        element.afilliate != undefined ? element.afilliate : null;
      holderValidations.calification =
        element.classification != undefined ? element.classification : null;
      holderValidations.centralState =
        element.centralState != undefined ? element.centralState : null;
      holderValidations.cobra =
        element.cobra != undefined ? element.cobra : null;
      holderValidations.idBeenInArrears =
        element.hoursBeen != undefined ? element.hoursBeen : null;
      holderValidations.idRailingSs =
        element.railingSS != undefined ? element.railingSS : null;
      holderValidations.idRelationship =
        element.relation != undefined ? element.relation : null;
      holderValidations.partnerDoc =
        element.doc != undefined ? element.doc : null;
      holderValidations.partnerName =
        element.name != undefined ? element.name : null;
      holderValidations.partnerTypeDoc =
        element.docType != undefined ? element.docType : null;
      holderValidations.promissoryNote =
        element.promissoryNote != undefined ? element.promissoryNote : null;
      holderValidations.sagralaft =
        element.sagralaft != undefined ? element.sagralaft : null;
      holderValidations.observations =
        this.infoStudyQuote.controls.observationsHRV.value;

      holderValidationsList.push(holderValidations);
    });

    genInfo.holderValidations = holderValidationsList;

    //Summary credit bureus
    genInfo.ciuCode = this.infoStudyQuote.controls.ciiu.value;
    genInfo.bankId = this.infoStudyQuote.controls.bank.value;
    genInfo.transactionAccount =
      this.infoStudyQuote.controls.draftAccount.value;
    genInfo.stateCentralRisk = this.infoStudyQuote.controls.stateCB.value;
    genInfo.opening = this.infoStudyQuote.controls.opening.value;
    genInfo.totalNoFS = this.infoStudyQuote.controls.totalNoSF.value;
    genInfo.totalFS = this.infoStudyQuote.controls.totalSF.value;
    genInfo.inArrearsNoFS = this.infoStudyQuote.controls.arrearsNSF.value;
    genInfo.inArrearsFS = this.infoStudyQuote.controls.arrearsSF.value;
    genInfo.maxHeightFS =
      this.infoStudyQuote.controls.heightMaxSF.value == "NO REGISTRA *"
        ? null
        : this.infoStudyQuote.controls.heightMaxSF.value;
    genInfo.totalNoRS = this.infoStudyQuote.controls.totalNoSR.value;
    genInfo.totalRS = this.infoStudyQuote.controls.totalSR.value;
    genInfo.inArrearsNoRS = this.infoStudyQuote.controls.arrearsNSR.value;
    genInfo.inArrearsRS = this.infoStudyQuote.controls.arrearsSR.value;
    genInfo.maxHeightRS =
      this.infoStudyQuote.controls.heightMaxSR.value == "NO REGISTRA *"
        ? null
        : this.infoStudyQuote.controls.heightMaxSR.value;
    genInfo.totalNoCC = this.infoStudyQuote.controls.totalNoTC.value;
    genInfo.totalCC = this.infoStudyQuote.controls.totalTC.value;
    genInfo.inArrearsNoCC = this.infoStudyQuote.controls.arrearsNTC.value;
    genInfo.inArrearsCC =
      this.infoStudyQuote.controls.arrearsTC.value == "NO REGISTRA *"
        ? null
        : this.infoStudyQuote.controls.arrearsTC.value;
    genInfo.maxHeightCC = this.infoStudyQuote.controls.heightMaxTC.value;
    genInfo.currentAccounts =
      this.infoStudyQuote.controls.currentAccounts.value;
    genInfo.garnishedAccounts =
      this.infoStudyQuote.controls.garnishedAccounts.value;
    genInfo.garnishedAccounts =
      this.infoStudyQuote.controls.garnishedAccounts.value;
    genInfo.totalIndebtedness =
      this.infoStudyQuote.controls.totalIndebtedness.value;
    genInfo.currentBalance = this.infoStudyQuote.controls.currentBalance.value;
    genInfo.balanceInArrears =
      this.infoStudyQuote.controls.balanceInArrears.value;
      genInfo.mainBalance =
      this.infoStudyQuote.controls.balanceAsPrincipal.value;
    genInfo.mainPercentage = this.infoStudyQuote.controls.porAsPrincipal.value;
    genInfo.share = this.infoStudyQuote.controls.quota.value;
    genInfo.totalPaymentValue =
      this.infoStudyQuote.controls.totalPaymentValueFee.value;
    genInfo.maxRating = this.infoStudyQuote.controls.maxCalification.value;
    genInfo.percentageUse = this.infoStudyQuote.controls.use.value;
    genInfo.observationsCentralRisk =
      this.infoStudyQuote.controls.observationsCB.value;

    //location result contact
    genInfo.personContact = this.infoStudyQuote.controls.contactPerson.value;
    genInfo.position = this.infoStudyQuote.controls.position.value;
    genInfo.typePhone = this.infoStudyQuote.controls.telType.value;
    genInfo.phoneContact = this.infoStudyQuote.controls.telephoneLRC.value;
    genInfo.emailContact = this.infoStudyQuote.controls.emailLRC.value;
    genInfo.employees = this.infoStudyQuote.controls.employees.value;
    genInfo.pointsOfSale = this.infoStudyQuote.controls.pointsSale.value;
    genInfo.halfContact = this.infoStudyQuote.controls.halfContact.value;
    genInfo.seniorityAffiliateLink =
      this.infoStudyQuote.controls.longStandingAffiliateLink.value;
    genInfo.whatProvides = this.infoStudyQuote.controls.providesYou.value;
    genInfo.phoneWhereLocated =
      this.infoStudyQuote.controls.telWasLocated.value;
    genInfo.typePhoneContact =
      this.infoStudyQuote.controls.telTypeLocated.value;
    genInfo.departmentContact =
      this.infoStudyQuote.controls.departmentLRC.value;
    genInfo.cityContact = this.infoStudyQuote.controls.cityLRC.value;
    genInfo.observationsContact =
      this.infoStudyQuote.controls.observationLRC.value;
    genInfo.comments = this.infoStudyQuote.controls.comments.value;

    //Execution Projects
    genInfo.executionProjects = projectsExecList =
      this.infoStudyQuote.controls.projects.value;

    console.log(genInfo);
    this.infoStudyQuote.controls.suppliers.value.forEach((element) => {
      let mainProviders: MainProviders = new MainProviders();
      delete mainProviders.id;
      mainProviders.name = element.name != undefined ? element.name : null;
      mainProviders.term =
        element.term != undefined
          ? this.quotaService.getIdFromList(element.term, this.termsList)
          : null;
      mainProviders.supplies =
        element.suppliesYou != undefined ? element.suppliesYou : null;

      mainProvidersList.push(mainProviders);
    });

    genInfo.mainSuppliersResponses = mainProvidersList;

    this.infoStudyQuote.controls.clients.value.forEach((element) => {
      let mainClients: MainClients = new MainClients();
      delete mainClients.id;
      mainClients.name = element.name != undefined ? element.name : null;
      mainClients.term =
        element.term != undefined
          ? this.quotaService.getIdFromList(element.term, this.termsList)
          : null;
      mainClients.perSales =
        element.salesPerPrevYear != undefined ? element.salesPerPrevYear : null;

      mainClientsList.push(mainClients);
    });

    genInfo.mainClientsResponses = mainClientsList;

    //References
    let reference1: Reference = new Reference();
    let reference2: Reference = new Reference();

    reference1.name = this.infoStudyQuote.controls.nameRef.value;
    reference1.nit = this.infoStudyQuote.controls.nitRef.value;
    reference1.city = this.infoStudyQuote.controls.cityRef.value;
    reference1.phone = this.infoStudyQuote.controls.phoneRef.value;
    reference1.person = this.infoStudyQuote.controls.personRef.value;
    reference1.position = this.infoStudyQuote.controls.positionRef.value;
    reference1.clientsFrom = this.infoStudyQuote.controls.clientsFrom.value;
    reference1.supplies = this.infoStudyQuote.controls.supplies.value;
    reference1.quota = this.infoStudyQuote.controls.quotaRef.value;
    reference1.term = this.infoStudyQuote.controls.termRef.value.idTerm;
    reference1.paymentMethod = this.infoStudyQuote.controls.paymentMethod.value;
    reference1.qualification =
      this.infoStudyQuote.controls.qualificationRef.value;
    referenceList.push(reference1);

    reference2.name = this.infoStudyQuote.controls.nameRef2.value;
    reference2.nit = this.infoStudyQuote.controls.nitRef2.value;
    reference2.city = this.infoStudyQuote.controls.cityRef2.value;
    reference2.phone = this.infoStudyQuote.controls.phoneRef2.value;
    reference2.person = this.infoStudyQuote.controls.personRef2.value;
    reference2.position = this.infoStudyQuote.controls.positionRef2.value;
    reference2.clientsFrom = this.infoStudyQuote.controls.clientsFrom2.value;
    reference2.supplies = this.infoStudyQuote.controls.supplies2.value;
    reference2.quota = this.infoStudyQuote.controls.quotaRef2.value;
    reference2.term = this.infoStudyQuote.controls.termRef2.value.idTerm;
    reference2.paymentMethod =
      this.infoStudyQuote.controls.paymentMethod2.value;
    reference2.qualification =
      this.infoStudyQuote.controls.qualificationRef2.value;
    referenceList.push(reference2);

    genInfo.references = referenceList;

    description.postdated = this.infoStudyQuote.controls.postdated.value;
    description.quota = this.infoStudyQuote.controls.quotaDR.value;
    description.queryCode = this.infoStudyQuote.controls.codeDR.value;
    description.topMaxCode = this.infoStudyQuote.controls.maxCodeCap.value;
    description.topeMaxContract =
      this.infoStudyQuote.controls.maxContractLimit.value;
    description.purchaseObject =
      this.infoStudyQuote.controls.purchaseObject.value;
    description.noQuotasAffiliate =
      this.infoStudyQuote.controls.afilFeesN.value;
    description.noQuotasReq = this.infoStudyQuote.controls.feesRequestedN.value;
    description.comercialValue =
      this.infoStudyQuote.controls.commercialValue.value;
    description.methodPayments =
      this.infoStudyQuote.controls.paymentMethodDR.value;

    genInfo.idDescripcion = description;

    //CurrentExhibition
    let currentExhibition = JSON.parse(
      localStorage.getItem("currentExhibition")
    );

    if (currentExhibition) {
      currentExhibition.forEach((element) => {
        let currentExhibition: CurrentExhibition = new CurrentExhibition();
        delete currentExhibition.id;
        currentExhibition.affiliate =
          element.afilliates != undefined ? element.afilliates : null;
        currentExhibition.product =
          element.product != undefined ? element.product : null;
        currentExhibition.state =
          element.state != undefined ? element.state : null;
        currentExhibition.assignment =
          element.assignment != undefined ? element.assignment : null;
        currentExhibition.quota =
          element.quota != undefined ? element.quota : null;
        currentExhibition.totalGuarantee =
          element.fullGuarantee != undefined ? element.fullGuarantee : null;
        currentExhibition.totalCurrentGuarantee =
          element.totalCurrentGuarantee != undefined
            ? element.totalCurrentGuarantee
            : null;
        currentExhibition.dateInitialGuarantee =
          element.initialWarrantyDate != undefined
            ? moment(element.initialWarrantyDate, "YYYY-MM-DD").toDate()
            : null;
        currentExhibition.dateLastGuarantee =
          element.lastGuaranteeDate != undefined
            ? moment(element.lastGuaranteeDate, "YYYY-MM-DD").toDate()
            : null;
        currentExhibition.currentWarrantyExpired =
          element.warrantyValidityExpired != undefined
            ? element.warrantyValidityExpired
            : null;
        currentExhibition.suggestedMove =
          element.suggestedMove != undefined ? element.suggestedMove : null;
        currentExhibition.movementValue =
          element.movementValue != undefined ? element.movementValue : null;
        currentExhibition.expoQuota =
          element.quotaSum != undefined ? element.quotaSum : null;
        currentExhibition.currentWarrantyTotal =
          element.totalCurrentWarranty != undefined
            ? element.totalCurrentWarranty
            : null;

        currentExhibitionList.push(currentExhibition);
      });
    }

    genInfo.currentExhibition = currentExhibitionList;

    genInfo.cvsNo = this.infoStudyQuote.controls.cvsNo2.value;
    genInfo.maxRecoveryDays = this.infoStudyQuote.controls.recMaxDays2.value;
    genInfo.cvsLastYear = this.infoStudyQuote.controls.cvsNo.value;
    genInfo.maxRecoveryLastYear = this.infoStudyQuote.controls.recMaxDays.value;
    genInfo.noticePaymentLastYearNo =
      this.infoStudyQuote.controls.notPayNotices.value;
    genInfo.extensionsLastYearNo =
      this.infoStudyQuote.controls.extensions.value;
    genInfo.initialQuota = this.infoStudyQuote.controls.quotaCE.value;
    genInfo.initialTotalGuarantee =
      this.infoStudyQuote.controls.fullGuarantee.value;
    genInfo.initialTotalCurrentGuarantee =
      this.infoStudyQuote.controls.totalCurrentGuarantee.value;
    genInfo.finalQuota = this.infoStudyQuote.controls.quotaCEFE.value;
    genInfo.finalTotalCurrentGuarantee =
      this.infoStudyQuote.controls.totalCurrentGuaranteeFE.value;
    genInfo.generalObservationsCurrentExhibition =
      this.infoStudyQuote.controls.observationsCE.value;

    let macro: Macros = new Macros();

    macro.generalBalance = new Map();
    macro.generalBalance =
      this.infoStudyQuote.controls.macros.value.GENERAL_BALANCE;
    macro.statesPG = this.infoStudyQuote.controls.macros.value.STATES_PG;
    macro.financialReasons =
      this.infoStudyQuote.controls.macros.value.FINANCIAL_REASONS;
    macro.observationsMacro =
      this.infoStudyQuote.controls.observationsMacro.value;
    macro.variationInSales =
      this.infoStudyQuote.controls.variationInSales.value;
    macro.variationInDebt = this.infoStudyQuote.controls.variationInDebt.value;
    macro.variationInInvTurn =
      this.infoStudyQuote.controls.variationInInvTurn.value;
    macro.variationInPortRotation =
      this.infoStudyQuote.controls.variationInPortRotation.value;
    macro.variationInNetInc =
      this.infoStudyQuote.controls.variationInNetInc.value;
    macro.observationVar = this.infoStudyQuote.controls.observationVar.value;

    genInfo.idMacro = macro;

    this.quotaGreaterService.postForm20M(genInfo).subscribe(
      (res: Response<GeneralInfo>) => {
        this.spinner.hide();
        clearInterval(this.time);
        Swal.fire({
          type: "success",
          title: "Transacción exitosa",
          text: res.message,
        }).then(() => {});
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          type: "error",
          title: "Error en transacción",
          text: error.error.message,
        }).then(() => {});
        this.spinner.hide();
        //const file = this.helper.createFileBlob(JSON.stringify(genInfo));
        //this.helper.downloadFromBlob(file,"error_cupo",".txt");

        localStorage.setItem(
          "ERROR_FORM_CUPOS",
          new Date() + JSON.stringify(genInfo)
        );
      }
    );
  }

  /**
   * Method that is triggered when the financial information query is clicked, it can bring up 3 possible objects
   * depending on where it finds information. Finally, it will process the response and fill in the form based on
   * what it finds.
   * @author Rafael Ramirez
   */
  findFinancialInformation(): void {
    this.spinner.show();
    this.quotaGreaterService
      .findFinancialInformation(this.financialQueryRequest)
      .subscribe(
        (response: Response<FinancialQuery>) => {
          this.financialQuery = response.data;
          this.processResponse(this.financialQuery);
          this.spinner.hide();
          let msg = this.getMessageSource(this.financialQuery[0].source);
          Swal.fire({
            type: "success",
            title: "¡Se encontró Información!",
            text: msg,
          }).then(() => {});
          return;
        },
        (error: any) => {
          // if error
          if (error.status == 404) {
            Swal.fire({
              type: "info",
              title: "¡No se encontraron registros!",
              text: "En ninguna de las fuentes de información.",
            }).then(() => {});
            this.spinner.hide();
            return;
          }
          Swal.fire({
            type: "error",
            title: "Error en la Consulta",
            text: error.message,
          }).then(() => {});
          this.spinner.hide();
          return;
        }
      );
  }

  /**
   * Process la respeste de Financial information query
   * @param financialQuery Response data of findFinancialInformation()
   * @author Rafael Ramirez
   */
  processResponse(financialQuery: Array<FinancialQuery>): void {
    const macroType = financialQuery[0].source;
    this.subject.changeStateMacroType(macroType);

    if (macroType === "SUPERSOCIEDADES") {
      this.fillSuperSocieties(financialQuery[0].superSocieties);
    }
    if (macroType === "OTROS_FC20M") {
      this.fillAnotherForms20M(financialQuery[0].another20MForms);
    }
    if (macroType === "INFORMA") {
      this.fillInform(financialQuery[0].informa);
    }
  }

  fillInform(inform: any) {
    const holderValidationsList: Array<any> = inform.holderRelated;
    const generalBalanceList: Array<any> = inform.generalBalances;
    const statesPGSList: Array<any> = inform.statesPGS;

    this.infoStudyQuote.controls.address.setValue(inform.address);

    this.infoStudyQuote.controls.socialObject.setValue(inform.activity);

    this.infoStudyQuote.controls.telephone.setValue(inform.contactPhone);

    this.infoStudyQuote.controls.email.setValue(inform.contactEmail);

    this.infoStudyQuote.controls.constitution.setValue(inform.constitutionDate);

    this.infoStudyQuote.controls.assets.setValue(inform.totalAssets);

    this.infoStudyQuote.controls.paidInCapital.setValue(inform.paymentCapital);

    this.infoStudyQuote.controls.document.setValue(inform.legalRepDocument);

    this.infoStudyQuote.controls.legalRepresentative.setValue(
      inform.legalRepName
    );

    this.infoStudyQuote.controls.holderRelated.setValue(holderValidationsList);

    const macros: Array<any> = [
      {
        year: generalBalanceList[0].year,
        generalBalance: generalBalanceList[0],
        statesPyG: statesPGSList[0],
      },
      {
        year: generalBalanceList[1].year,
        generalBalance: generalBalanceList[1],
        statesPyG: statesPGSList[1],
      },
    ];

    delete macros[0].generalBalance.year;
    delete macros[1].generalBalance.year;
    delete macros[0].generalBalance.assetsPlusEquity;
    delete macros[1].generalBalance.assetsPlusEquity;

    delete macros[0].statesPyG.year;
    delete macros[1].statesPyG.year;
    delete macros[0].statesPyG.grossLostProfit;
    delete macros[1].statesPyG.grossLostProfit;
    delete macros[0].statesPyG.totalIncome;
    delete macros[1].statesPyG.totalIncome;
    delete macros[0].statesPyG.totalLoss;
    delete macros[1].statesPyG.totalLoss;
    delete macros[0].statesPyG.totalBills;
    delete macros[1].statesPyG.totalBills;
    delete macros[0].statesPyG.utilityLossOperational;
    delete macros[1].statesPyG.utilityLossOperational;
    delete macros[0].statesPyG.utilityLossNet;
    delete macros[1].statesPyG.utilityLossNet;

    if (macros[0].year > macros[1].year) {
      macros.reverse();
    }

    this.infoStudyQuote.controls.macrosInput.setValue(macros);
  }

  /**
   * Method that fills out the entire form when it is found as a source of information Another 20M quota request form
   * @param another20MForms specific objet for fill another forms values
   * @author Rafael Ramirez
   */
  fillAnotherForms20M(another20MForms: GeneralInfo): void {
    const holderValidationsList: Array<HolderValidations> =
      another20MForms.holderValidations;
    const projectsExecList: Array<ExecutionProject> =
      another20MForms.executionProjects;
    const mainProvidersList: Array<MainProviders> =
      another20MForms.mainSuppliersResponses;
    const mainClientsList: Array<MainClients> =
      another20MForms.mainClientsResponses;
    const referenceList: Array<Reference> = another20MForms.references;
    const description: Description = another20MForms.idDescripcion;
    const currentExhibitionList: Array<CurrentExhibition> =
      another20MForms.currentExhibition;
    const macros: Macros = another20MForms.idMacro;

    this.infoStudyQuote.controls.dateExpDoc.setValue(
      another20MForms.expeditionDate
    );
    this.infoStudyQuote.controls.placeExpDoc.setValue(
      another20MForms.expeditionPlace
    );
    this.infoStudyQuote.controls.legalRepresentative.setValue(
      another20MForms.nameLegalRepresentative
    );
    this.infoStudyQuote.controls.documentTypeLR.setValue(
      another20MForms.typeDocLegalRepresentative
    );
    this.infoStudyQuote.controls.documentLR.setValue(
      another20MForms.documentLegalRepresentative
    );
    this.infoStudyQuote.controls.dateExpDocLR.setValue(
      another20MForms.expeditionDateLegalRepresentative
    );
    this.infoStudyQuote.controls.placeExpDocLR.setValue(
      another20MForms.expeditionPlaceLegalRepresentative
    );
    this.infoStudyQuote.controls.bornDate.setValue(
      another20MForms.birthDateLegalRepresentative
    );
    this.infoStudyQuote.controls.address.setValue(another20MForms.address);
    this.infoStudyQuote.controls.department.setValue(
      another20MForms.idDepartment
    );
    this.infoStudyQuote.controls.city.setValue(another20MForms.idCity);
    this.infoStudyQuote.controls.covering.setValue(another20MForms.covering);
    this.infoStudyQuote.controls.telephone.setValue(another20MForms.phone);
    this.infoStudyQuote.controls.cellphone.setValue(another20MForms.cellphone);
    this.infoStudyQuote.controls.email.setValue(another20MForms.email);
    this.infoStudyQuote.controls.constitution.setValue(
      another20MForms.constitution
    );
    this.infoStudyQuote.controls.validity.setValue(another20MForms.validity);
    this.infoStudyQuote.controls.assets.setValue(another20MForms.assets);
    this.infoStudyQuote.controls.socialObject.setValue(
      another20MForms.socialObject
    );
    this.infoStudyQuote.controls.authorizedCapital.setValue(
      another20MForms.authorizedCapital
    );
    this.infoStudyQuote.controls.paidInCapital.setValue(
      another20MForms.paidInCapital
    );
    this.infoStudyQuote.controls.contactPerson.setValue(
      another20MForms.personContact
    );
    this.infoStudyQuote.controls.position.setValue(another20MForms.position);
    this.infoStudyQuote.controls.telType.setValue(another20MForms.typePhone);
    this.infoStudyQuote.controls.telephoneLRC.setValue(
      another20MForms.phoneContact
    );
    this.infoStudyQuote.controls.emailLRC.setValue(
      another20MForms.emailContact
    );
    this.infoStudyQuote.controls.employees.setValue(another20MForms.employees);
    this.infoStudyQuote.controls.pointsSale.setValue(
      another20MForms.pointsOfSale
    );
    this.infoStudyQuote.controls.halfContact.setValue(
      another20MForms.halfContact
    );
    this.infoStudyQuote.controls.longStandingAffiliateLink.setValue(
      another20MForms.seniorityAffiliateLink
    );
    this.infoStudyQuote.controls.providesYou.setValue(
      another20MForms.whatProvides
    );
    this.infoStudyQuote.controls.telWasLocated.setValue(
      another20MForms.phoneWhereLocated
    );
    this.infoStudyQuote.controls.telTypeLocated.setValue(
      another20MForms.typePhoneContact
    );
    this.infoStudyQuote.controls.departmentLRC.setValue(
      another20MForms.departmentContact
    );
    this.infoStudyQuote.controls.cityLRC.setValue(another20MForms.cityContact);
    this.infoStudyQuote.controls.observationLRC.setValue(
      another20MForms.observationsContact
    );
    this.infoStudyQuote.controls.comments.setValue(another20MForms.comments);
    this.infoStudyQuote.controls.cvsNo.setValue(another20MForms.cvsNo);
    this.infoStudyQuote.controls.recMaxDays.setValue(
      another20MForms.maxRecoveryDays
    );
    this.infoStudyQuote.controls.cvsNo2.setValue(another20MForms.cvsLastYear);
    this.infoStudyQuote.controls.recMaxDays2.setValue(
      another20MForms.maxRecoveryLastYear
    );
    this.infoStudyQuote.controls.notPayNotices.setValue(
      another20MForms.noticePaymentLastYearNo
    );
    this.infoStudyQuote.controls.extensions.setValue(
      another20MForms.extensionsLastYearNo
    );

    this.infoStudyQuote.controls.quotaCE.setValue(another20MForms.initialQuota);
    this.infoStudyQuote.controls.fullGuarantee.setValue(
      another20MForms.initialTotalGuarantee
    );
    this.infoStudyQuote.controls.totalCurrentGuarantee.setValue(
      another20MForms.initialTotalCurrentGuarantee
    );
    this.infoStudyQuote.controls.quotaCEFE.setValue(another20MForms.finalQuota);
    this.infoStudyQuote.controls.totalCurrentGuaranteeFE.setValue(
      another20MForms.finalTotalCurrentGuarantee
    );
    this.infoStudyQuote.controls.observationsCE.setValue(
      another20MForms.generalObservationsCurrentExhibition
    );

    this.infoStudyQuote.controls.holderRelated.setValue(holderValidationsList);
    this.infoStudyQuote.controls.projects.setValue(projectsExecList);
    this.infoStudyQuote.controls.suppliers.setValue(mainProvidersList);
    this.infoStudyQuote.controls.clients.setValue(mainClientsList);
    this.fillReferences(referenceList);
    this.infoStudyQuote.controls.currentExhibition.setValue(
      currentExhibitionList
    );
    this.infoStudyQuote.controls.postdated.setValue(description.postdated);
    this.infoStudyQuote.controls.quotaDR.setValue(description.quota);
    this.infoStudyQuote.controls.codeDR.setValue(description.queryCode);
    this.infoStudyQuote.controls.maxCodeCap.setValue(description.topMaxCode);
    this.infoStudyQuote.controls.maxContractLimit.setValue(
      description.topeMaxContract
    );
    this.infoStudyQuote.controls.purchaseObject.setValue(
      description.purchaseObject
    );
    this.infoStudyQuote.controls.afilFeesN.setValue(
      description.noQuotasAffiliate
    );
    this.infoStudyQuote.controls.feesRequestedN.setValue(
      description.noQuotasReq
    );
    this.infoStudyQuote.controls.commercialValue.setValue(
      description.comercialValue
    );
    this.infoStudyQuote.controls.paymentMethodDR.setValue(
      description.methodPayments
    );
    this.infoStudyQuote.controls.macros.setValue(macros);
    this.infoStudyQuote.controls.observationsMacro.setValue(
      macros.observationsMacro
    );
    this.infoStudyQuote.controls.variationInSales.setValue(
      macros.variationInSales
    );
    this.infoStudyQuote.controls.variationInDebt.setValue(
      macros.variationInDebt
    );
    this.infoStudyQuote.controls.variationInInvTurn.setValue(
      macros.variationInInvTurn
    );
    this.infoStudyQuote.controls.variationInPortRotation.setValue(
      macros.variationInPortRotation
    );
    this.infoStudyQuote.controls.variationInNetInc.setValue(
      macros.variationInNetInc
    );
    this.infoStudyQuote.controls.observationVar.setValue(macros.observationVar);
  }

  /**
   * Fill reference section, used in AnotherForms20M
   * @param referenceList references objetcs
   * @author Rafael Ramirez
   */
  fillReferences(referenceList: Array<Reference>): void {
    if (referenceList.length > 0) {
      referenceList.forEach((reference: Reference, index) => {
        if (index == 0) {
          this.infoStudyQuote.controls.nameRef.setValue(reference.name);
          this.infoStudyQuote.controls.nitRef.setValue(reference.nit);
          this.infoStudyQuote.controls.cityRef.setValue(reference.city);
          this.infoStudyQuote.controls.phoneRef.setValue(reference.phone);
          this.infoStudyQuote.controls.personRef.setValue(reference.person);
          this.infoStudyQuote.controls.positionRef.setValue(reference.position);
          this.infoStudyQuote.controls.clientsFrom.setValue(
            reference.clientsFrom
          );
          this.infoStudyQuote.controls.supplies.setValue(reference.supplies);
          this.infoStudyQuote.controls.quotaRef.setValue(reference.quota);
          this.infoStudyQuote.controls.termRef.setValue(reference.term);
          this.infoStudyQuote.controls.paymentMethod.setValue(
            reference.paymentMethod
          );
          this.infoStudyQuote.controls.qualificationRef.setValue(
            reference.qualification
          );
        }
        if (index == 1) {
          this.reference2 = true;
          this.infoStudyQuote.controls.nameRef2.setValue(reference.name);
          this.infoStudyQuote.controls.nitRef2.setValue(reference.nit);
          this.infoStudyQuote.controls.cityRef2.setValue(reference.city);
          this.infoStudyQuote.controls.phoneRef2.setValue(reference.phone);
          this.infoStudyQuote.controls.personRef2.setValue(reference.person);
          this.infoStudyQuote.controls.positionRef2.setValue(
            reference.position
          );
          this.infoStudyQuote.controls.clientsFrom2.setValue(
            reference.clientsFrom
          );
          this.infoStudyQuote.controls.supplies2.setValue(reference.supplies);
          this.infoStudyQuote.controls.quotaRef2.setValue(reference.quota);
          this.infoStudyQuote.controls.termRef2.setValue(reference.term);
          this.infoStudyQuote.controls.paymentMethod2.setValue(
            reference.paymentMethod
          );
          this.infoStudyQuote.controls.qualificationRef2.setValue(
            reference.qualification
          );
        }
      });
    }
  }

  /**
   * when the information found comes from supersocieties as a source,
   * this method will fill in the correspnding fields.
   * @param superSocieties specific objet for fill supersocieties values
   * @author Rafael Ramirez
   */
  fillSuperSocieties(superSocieties: SuperSociety[]): void {
    superSocieties.forEach((info) => {
      if (info.legalRepresentative) {
        this.infoStudyQuote.controls.documentTypeLR.setValue(
          info.legalRepresentative.documentType
        );
        this.infoStudyQuote.controls.legalRepresentative.setValue(
          info.legalRepresentative.legalRepresentative
        );
        this.infoStudyQuote.controls.documentLR.setValue(
          info.legalRepresentative.documentNo
        );
        this.infoStudyQuote.controls.documentTypeLR.disable();
        this.infoStudyQuote.controls.legalRepresentative.disable();
        this.infoStudyQuote.controls.documentLR.disable();

        this.infoStudyQuote.controls.macrosInput.setValue(superSocieties);

        return;
      }
    });
  }

  // Fills year list on query information section
  fillYearsList(): number[] {
    let listYears = [];
    const currentYear = new Date().getFullYear();
    let minYear = 2010;
    while (minYear <= currentYear) {
      listYears.push(minYear);
      minYear++;
    }
    return listYears;
  }

  // Used for specify to the user from wich source the financial information was founded
  getMessageSource(source: string): string {
    if (source === "SUPERSOCIEDADES") return "Fuente: SuperSociedades.";
    if (source === "OTROS_FC20M")
      return "Fuente: Otros Formularios de Cupos Mayor a 20M.";
    if (source === "INFORMA") return "Fuente: Informa.";
    else return "";
  }

  // Used to set avaliability of financial information request
  updateFlag(): void {
    const year1 = this.financialQueryRequest.year1;
    const year2 = this.financialQueryRequest.year2;
    if (
      year1 == year2 ||
      year1 == undefined ||
      year1 == null ||
      year2 == undefined ||
      year2 == null
    ) {
      this.error = true;
      this.toastr.warning(
        "¡Seleccione dos fechas validas que sean diferentes!",
        null,
        {
          positionClass: "toast-bottom-right",
        }
      );
    } else this.error = false;
  }

  handleFinancialQuery() {
    this.spinner.show();
    // this.quotaGreaterService.getFinancialQuery(this.infoStudyQuote.controls.settlement.value).subscribe(
    //   (resp: Response<FinancialQuery>) => {
    //     this.spinner.hide();
    //     if (resp.ok) {
    //       if (resp.data) {
    //         this.financialQuery = resp.data;
    //  this.toaster.info('Información obtenida de la consulta financiera');
    //       } else {
    //         Swal.fire({
    //           type: 'error',
    //           title: 'Error en transacción',
    //           text: 'No se encontró información financiera para este número de radicado'
    //         }).then(() => {
    //         });
    //       }
    //     } else {
    //       Swal.fire({
    //         type: 'error',
    //         title: 'Error en transacción',
    //         text: 'Ocurrió un error al consultar'
    //       }).then(() => {
    //       });
    //     }
    //   });
  }
}
