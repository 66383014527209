import { GeneralInfo } from "./generalInfo.model";

export class MainClients {

    id: number;
	name: string;
	term: number;
	perSales: number;
	quotaId: GeneralInfo;

    public static fromResponse(json: any): MainClients {

        const mainClients: MainClients = {

        id: json['id'],
        name: json['name'],
        term: json['term'],
        perSales: json['perSales'],
        quotaId: json['quotaId'],

    };
    return mainClients;
    }
}