import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-recognize-phones-table',
  templateUrl: './recognize-phones-table.component.html',
  styleUrls: ['./recognize-phones-table.component.scss']
})
export class RecognizePhonesTableComponent implements OnInit {

  @Input() dataTable: any;
  @Input() cellphonesInfo: any;

  dataTableToDisplay: any[] = [];
  cellphonesInfoToDisplay: any[] = [];

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
    this.prepareData();
  }

  prepareData() {
    this.dataTableToDisplay = this.convertToArray(this.dataTable);
    this.cellphonesInfoToDisplay = this.convertToArray(this.cellphonesInfo);
    console.log( this.dataTableToDisplay)
    console.log(this.cellphonesInfoToDisplay)
  }

  convertToArray(data: any): any[] {
    if (Array.isArray(data)) {
      return data;
    } else if (data && typeof data === 'object') {
      console.log(data)
      return [data];
    }
    return [];
  }
}
