import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActiveSavingAndCheckingAccounts, InactiveSavingAndCheckingAccounts } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-credit-history-table',
  templateUrl: './legal-credit-history-table.component.html',
  styleUrls: ['./legal-credit-history-table.component.scss']
})
export class LegalCreditHistoryTableComponent implements OnInit {

  @Input() creditHistoryDataTable: any;
  @Input() portfolioAccountDataTable: any;
  @Input() activeSavingAndCheckingAccounts: ActiveSavingAndCheckingAccounts[] = [];
  @Input() inactiveSavingAndCheckingAccounts: InactiveSavingAndCheckingAccounts[] = [];
  @Input() isAPerson: boolean = false;

  cuentasAbiertas: any[] = [];
  cuentasCerradas: any[] = [];

  constructor() { }

  ngOnInit() {
    this.separarCuentas();
  }

  separarCuentas() {
    if (this.portfolioAccountDataTable) {
      const cuentas = Array.isArray(this.portfolioAccountDataTable) ? this.portfolioAccountDataTable : [this.portfolioAccountDataTable];
  
      this.cuentasAbiertas = cuentas.filter((cuenta: any) => {
        return cuenta.Estados && cuenta.Estados.EstadoCuenta && ['00', '01', '02'].includes(cuenta.Estados.EstadoCuenta.codigo);
      });
  
      this.cuentasCerradas = cuentas.filter((cuenta: any) => {
        return cuenta.Estados && cuenta.Estados.EstadoCuenta && ['03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16'].includes(cuenta.Estados.EstadoCuenta.codigo);
      });
    }
  }
}
