import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { SummaryBalancesAndArrears } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-table-summary-balances-and-arrears-history',
  templateUrl: './table-summary-balances-and-arrears-history.component.html',
  styleUrls: ['./table-summary-balances-and-arrears-history.component.scss']
})
export class TableSummaryBalancesAndArrearsHistoryComponent implements OnInit, OnDestroy {

  public debtTendency: SummaryBalancesAndArrears = { headers: [], records: [] };
  private readonly $unsubscribe: Subject<void> = new Subject<void>();


  get getHeaders(): string[] {
    return this.debtTendency.headers;
  }

  get getRecords() {
    return this.debtTendency.records;
  }

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.debtTendency = response.getSummaryBalancesAndArrears;
  }

}
