import { Injectable } from '@angular/core';

export interface RecognizeResponse {
  globalDebt: any;
  summaryBalance: SummaryBalance | undefined
  summariesOfPaymentHabits: SummaryOfPaymentHabits[]
  creditHistory: any;
  portfolioAccount: any;
  scores: any;
  basicInfo: any;
  consultedBy: ConsultedBy | undefined
  levelOfIndebtednessAndOpenDebentureValues: LevelOfIndebtednessAndOpenDebentureValues[]
  codigoRespuesta: string
}

export interface SummaryBalance {
  headers: string[]
  records: string[][]
}

export interface SummaryOfPaymentHabits {
  headers: string[]
  records: string[][]
}

export interface ConsultedBy {
  headers: string[]
  records: string[][]
}

interface GroupedByEntityConsult {
  [entidad: string]: {
    fecha: string;
    cantidad: string;
  }[];
  creditHistory: any;
  portfolioAccount: any;
}

export interface LevelOfIndebtednessAndOpenDebentureValues {
  sector: string
  headers: string[]
  entities: LevelOfIndebtednessAndOpenDebentureValuesEntity[]
}

export interface LevelOfIndebtednessAndOpenDebentureValuesEntity {
  entity: string
  records: any[][]
}

export interface ValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues {
  arrayQuota: number[]
  arrayQuotaInitialValue: number[]
  arrayBalance: number[]
  arrayBalanceInArrears: number[]
  arrayAvailableValue: number[]

}

@Injectable({
  providedIn: 'root'
})
export class LegalPersonAdapterService {

  constructor() { }

  /*  */
  public adapt(response: any): RecognizeResponse {
    const informe = response && response.data && response.data[0] && response.data[0].Informes && response.data[0].Informes.Informe || {};
    console.log(informe.CuentaCartera)
    return {
        globalDebt: informe.EndeudamientoGlobal || {},
        summaryBalance: this.getSummaryBalance(response),
        summariesOfPaymentHabits: this.getSummariesOfPaymentHabits(response),
        consultedBy: this.getConsultedBy(response),
        creditHistory: informe.InfoAgregadaPJ || {},
        portfolioAccount: informe.CuentaCartera || {},
        levelOfIndebtednessAndOpenDebentureValues: this.getLevelOfIndebtednessAndOpenDebentureValues(response),
        scores: informe.Score || {},
        basicInfo: {
            identidad: response && response.data && response.data[0] && response.data[0].identidad || '',
            tipoIdentificacion: response && response.data && response.data[0] && response.data[0].tipoIdentificacion || '',
            razonSocial: informe.JuridicaNacional && informe.JuridicaNacional.razonSocial || '',
        },
        codigoRespuesta: response && response.data && response.data[0] && response.data[0].codigoRespuesta || '',
    };
}

  getSummaryBalance(response: any): SummaryBalance | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos.Trimestre
    ) return undefined;

    let headers: string[] = ['Saldos'];
    let totalBalance: string[] = ['Saldo total'];
    let totalBalanceInArrears: string[] = ['Saldo en mora total']
    let data: any[] = [];
    if(response.data[0].Informes.Informe.InfoAgregadaPJ && response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos) {
      data = response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos.Trimestre || [];
    }

    data.forEach((item: any) => {
      const { trimestre, saldoTotal, saldoMoraTotal } = item;
      headers.push(trimestre);
      totalBalance.push(saldoTotal);
      totalBalanceInArrears.push(saldoMoraTotal)
    });

    return {
      headers,
      records: [totalBalance, totalBalanceInArrears]
    }
  }

  getSummariesOfPaymentHabits(response: any): SummaryOfPaymentHabits[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago.TipoCuenta
    ) return [];
  
    let data: any[] = [];
  
    if (response.data[0].Informes.Informe.InfoAgregadaPJ && 
        response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago) {
      const tipoCuentaData = response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago.TipoCuenta;
  
      data = Array.isArray(tipoCuentaData) ? tipoCuentaData : [tipoCuentaData];
    }
  
    const headers: string[] = ['Tipo de cuenta y estado', 'Número'];
  
    const summaries: SummaryOfPaymentHabits[] = data.map((item: any) => {
      let { tipoCuenta, cantidad, Estado: accounts } = item;
      const rowTotal: string[] = [tipoCuenta, cantidad];
  
      if (!Array.isArray(accounts)) accounts = [accounts];
      const rowStates: string[][] = this.getStatesFromSummaryOfPaymentsHabits(accounts);
  
      return {
        headers,
        records: [rowTotal, ...rowStates]
      }
    });
  
    return summaries;
  }

  private getStatesFromSummaryOfPaymentsHabits(accounts: any[]): string[][] {
    return accounts.map((item: any) => {
      const { codigo, cantidad } = item;
      return [codigo, cantidad];
    })
  }

  getConsultedBy(response: any): ConsultedBy | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.Consulta
    ) return undefined;

    const queries: any[] = response.data[0].Informes.Informe.Consulta || [];

    const dates: string[] = this.getDatesFromConsultedBy(queries);
    const headers: string[] = ['Consultante', ...dates];
    const groupByEntity: GroupedByEntityConsult = this.groupByEntityToConsultBy(queries);
    // console.log('======><><><><>Datos agrupados por entidad:: ', groupByEntity);

    let recordsOfTable: string[][] = [];

    Object.entries(groupByEntity).forEach(([entity, records]) => {
      // console.log('Entidad actual y valores asociados ===><><>', entity, records);
      let recordOfTable: string[] = [entity];

      dates.forEach((date: string) => {
        const queriesByDate = records.filter((item) => item.fecha.includes(date));
        if (queriesByDate.length === 0) recordOfTable.push('0');

        if (queriesByDate.length > 0) {
          let total: number = 0;
          queriesByDate.forEach((item) => {
            total += !isNaN(Number(item.cantidad)) ? Number(item.cantidad) : 0;
          })
          recordOfTable.push(`${total}`);
        }
      });

      recordsOfTable.push(recordOfTable);
    });

    const rowTotal: any[] = this.calculateTotalFromConsultedBy(recordsOfTable);

    const result: ConsultedBy = {
      headers,
      records: [...recordsOfTable, rowTotal]
    };

    return result;
  }

  private groupByEntityToConsultBy(queries: any[]): GroupedByEntityConsult {
    return queries.reduce((acc, item) => {
      if (!acc[item.entidad]) {
        acc[item.entidad] = [];
      }
      const [year, month, day] = item.fecha.split('-'); // "2024-07-03"
      acc[item.entidad].push({
        fecha: `${month}-${year}`,
        cantidad: item.cantidad
      })
      return acc;
    }, {} as GroupedByEntityConsult);
  }

  private getDatesFromConsultedBy(queries: any[]): string[] {
    let dates: string[] = [];
    queries.forEach((item: any) => {
      const { entidad, fecha, cantidad } = item;
      const [year, month, day] = fecha.split('-'); // "2024-07-03"
      dates.push(`${month}-${year}`);
    })

    return Array.from(new Set(dates));
  }

  private calculateTotalFromConsultedBy(records: string[][]): string[] {
    if (records.length === 0) return [];
    let result = new Array(records[0].length).fill(0);
    result[0] = "Total";

    records.forEach((record: string[]) => {
      record.forEach((value: string, index: number) => {
        if (index > 0) {
          result[index] += !isNaN(Number(value)) ? Number(value) : 0;
        }
      });
    });

    return result;
  }

  getLevelOfIndebtednessAndOpenDebentureValues(response: any): LevelOfIndebtednessAndOpenDebentureValues[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.SectorNivelEndValoresAbiertas
    ) return [];

    let sectores: any[] = [];

    if(response.data[0].Informes.Informe.InfoAgregadaPJ && response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas) {
      sectores = response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.SectorNivelEndValoresAbiertas || [];
    }

    const result: LevelOfIndebtednessAndOpenDebentureValues[] = sectores.map((sector) => {
      const sectorName: string = sector.nombreSector || '--';

      let entities: any[] = sector.Entidad ? sector.Entidad : [];

      // Puede ser un objeto y no un array
      if(!Array.isArray(entities)) entities = [entities];

      const headers: string[] = this.getHeadersFromSectorFromLevelOfIndebtednessAndOpenDebentureValues(entities);
      const records: LevelOfIndebtednessAndOpenDebentureValuesEntity[] = this.getRecordFromEntityFromLevelOfIndebtednessAndOpenDebentureValues(entities);

      return {
        sector: sectorName,
        headers,
        entities: records
      }
    });

    return result;
  }

  private getHeadersFromSectorFromLevelOfIndebtednessAndOpenDebentureValues(entities: any[]): string[] {
    const prefixHeaders: string[] = ['Entidad', 'Tipo de obligación', 'Número obligación', 'Valor', 'Prom'];
    if (!entities) return prefixHeaders;

    let tipoCuenta: any = (entities[0] && entities[0].TipoCuenta) ? entities[0].TipoCuenta : [];

    // Puede ser un array o un objeto
    if (!Array.isArray(tipoCuenta)) tipoCuenta = [tipoCuenta];

    const months: any[] = (tipoCuenta[0] && tipoCuenta[0].Mes) ? tipoCuenta[0].Mes : [];

    const dates: string[] = months.map((mes) => {
      return `${mes.mes}`;
    });

    return [...prefixHeaders, ...dates];
  }

  private getRecordFromEntityFromLevelOfIndebtednessAndOpenDebentureValues(entities: any[]): LevelOfIndebtednessAndOpenDebentureValuesEntity[] {
    if (!entities || entities.length === 0) return [];
    const records: LevelOfIndebtednessAndOpenDebentureValuesEntity[] = [];

    entities.forEach((entity) => {
      const entityName: string = entity.nombreEntidad || '--';
      const recordByEntity: any[][] = [];

      let accounts = entity.TipoCuenta || [];
      if (!Array.isArray(accounts)) accounts = [accounts]; // Puede ser un array

      accounts.forEach((account) => {
        const recordsFromAccount: any[][] = this.getRecordFromAccountSectorFromLevelOfIndebtednessAndOpenDebentureValues(account, entityName);
        recordByEntity.push(...recordsFromAccount);
      });
      records.push({ entity: entityName, records: recordByEntity })
    })

    return records;
  }

  private getRecordFromAccountSectorFromLevelOfIndebtednessAndOpenDebentureValues(tipoCuenta: any, entityName: string): any[][] {
    if (!tipoCuenta) return [];

    const { tipo, cantidad, promedioCuota, promedioCupoInicial,
      promedioSaldo, promedioSaldoMora, promedioValorDisponible } = tipoCuenta;

    const obligationType: string = tipo || '--';
    const quantity: number = cantidad || 0;
    const prefixLabels: any[] = [entityName, obligationType, quantity];

    const averageQuota: number = promedioCuota || 0;
    const averageQuotaInitialValue: number = promedioCupoInicial || 0;
    const averageBalance: number = promedioSaldo || 0;
    const averageBalanceInArrears: number = promedioSaldoMora || 0;
    const averageAvailableValue: number = promedioValorDisponible || 0;

    const valuesPerMonth = this.getValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues(tipoCuenta.Mes);

    let arrayQuota: any[] = [...prefixLabels, 'Cuota', averageQuota, ...valuesPerMonth.arrayQuota];
    let arrayQuotaInitialValue: any[] = [...prefixLabels, 'Cupo valor inicial', averageQuotaInitialValue, ...valuesPerMonth.arrayQuotaInitialValue];
    let arrayBalance: any[] = [...prefixLabels, 'Saldo', averageBalance, ...valuesPerMonth.arrayBalance];
    let arrayBalanceInArrears: any[] = [...prefixLabels, 'Saldo Mora', averageBalanceInArrears, ...valuesPerMonth.arrayBalanceInArrears];
    let arrayAvailableValue: any[] = [...prefixLabels, 'Valor disponible', averageAvailableValue, ...valuesPerMonth.arrayAvailableValue];

    return [
      arrayQuota,
      arrayQuotaInitialValue,
      arrayBalance,
      arrayBalanceInArrears,
      arrayAvailableValue
    ]
  }

  private getValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues(months: any[]): ValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues {
    let arrayQuota: number[] = [];
    let arrayQuotaInitialValue: number[] = [];
    let arrayBalance: number[] = [];
    let arrayBalanceInArrears: number[] = [];
    let arrayAvailableValue: number[] = [];

    months.forEach((month) => {
      const { cuota, cupoInicial, valorDisponible, saldo, saldoMora } = month;
      arrayQuota.push(cuota || 0);
      arrayQuotaInitialValue.push(cupoInicial || 0);
      arrayBalance.push(saldo || 0);
      arrayBalanceInArrears.push(saldoMora || 0);
      arrayAvailableValue.push(valorDisponible || 0);
    });

    return {
      arrayQuota,
      arrayQuotaInitialValue,
      arrayBalance,
      arrayBalanceInArrears,
      arrayAvailableValue
    }
  }

}
