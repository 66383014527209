import { Component, OnInit } from "@angular/core";
import { LegalNatural } from "@app/models/legalNatural.model";
import { ModalService } from "@app/services/modal.service";

@Component({
  selector: "app-params-affiliate",
  templateUrl: "./params-affiliate.component.html",
  styleUrls: ["./params-affiliate.component.scss"],
})
export class ParamsAffiliateComponent implements OnInit {
  typeDocument = [];
  affiliates: LegalNatural[] = [
    {
      pk: {
        document: 12,
        typeDocument: "N",
        secondTypeDocumento: "",
        secondDocument: 0,
      },
      businessName: "",
      firstName: "David",
      secondName: "Camilo",
      firstSurName: "Aguja",
      secondSurName: "Chico",
    },
  ];

  constructor(public modal: ModalService) {}

  ngOnInit() {}

  editAffiliate(legalNatural: LegalNatural) {
    let isCreate = {
      contractDetail: legalNatural,
    };
    this.modal.show("paramsAffiliate", isCreate);
  }

  goToPage($event) {}

  onSelectDocument($event){
    
  }
}
