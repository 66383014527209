import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface IApis {
  urlApi: string;
  urlApiAnalytics: string;
  production: string;
  version: string;
  deployment_date: string;
}

export interface Configuration {
  apis: IApis;
  assets: string[];
}

@Injectable({
  providedIn: 'root'
})
export class CiCdConfigService {

  private readonly CONFIG_URL = 'assets/ci-cd-config/configuration.json';
  private configuration$!: Observable<Configuration>;

  constructor(private http: HttpClient) {
  }


  public loadConfigurations(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<Configuration>(`${this.CONFIG_URL}`).pipe(
        // tap((configuration: Configuration) => { console.log('configuration', configuration); }),
        shareReplay(1)
      );
    }
    return this.configuration$;
  }
}
