import { Component, OnInit } from '@angular/core';
import { RadicationService } from '@app/pages/quota-radication/services/radication-quota.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  public message = "";
  constructor(
    private radicationQuota: RadicationService
    ) {
      this.message = this.radicationQuota.messager;
    }

  ngOnInit() {
  }

}
