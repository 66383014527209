import { Component, Input, OnInit } from '@angular/core';
import { CurrentDebtAccountPerQuality, CurrentDebtAccountType, CurrentDebtSector } from '../../services/subject-credit-history.service';

@Component({
  selector: 'app-table-current-debt-history',
  templateUrl: './table-current-debt-history.component.html',
  styleUrls: ['./table-current-debt-history.component.scss']
})
export class TableCurrentDebtHistoryComponent implements OnInit {

  @Input() sector: CurrentDebtSector = {sector: '', headers: [], accountTypes: []};

  get getTitle() {
    return this.sector.sector;
  }

  get getHeaders() {
    return this.sector.headers
  }

  get getAccountTypes() {
    return this.sector.accountTypes;
  }

  constructor() { }

  ngOnInit() {
  }

  getQuantityRowPerType(accountType: CurrentDebtAccountType): number {
    let totalRows: number = 0;
    accountType.accountPerQuality.forEach((quality: CurrentDebtAccountPerQuality) => {
      totalRows+= quality.accounts.length;
    });
    return totalRows;
  }

}
