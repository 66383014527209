import { Component, OnInit } from '@angular/core';
import { AffiliateLinkService } from '@app/services/affiliate-link.service';
import { HelperService } from '@app/services/helper.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {

  public dateRange: Array<any> = [];

  public imageData: Array<any> = [];

  public tabs: Array<any> = [
    {
      title: "Habilitar Link",
      active: true,
    },
    {
      title: "Reporte de transacciones realizadas",
      active: false,
    },
    {
      title: "Correos notificaciones",
      active: false,
    },
    {
      title: "Gestión de Links",
      active: false,
    },
    {
      title: "Descarga informe link de cupos",
      active: false,
    },
  ];

  constructor(private affiliate: AffiliateLinkService, private spinner: NgxSpinnerService, public helper: HelperService, private toastr: ToastrService) { }

  ngOnInit() {
    
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;
  }

  public onGetDateRange($event) {
    this.dateRange = $event;
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      paramIni: moment(this.dateRange[0]).format("YYYY-MM-DD"),
      paramFin: moment(this.dateRange[1]).format("YYYY-MM-DD"),
    }
    console.log(params);
    
    this.affiliate.getDownloadQuota(params).subscribe((response: any) => {
      this.spinner.hide();
      this.imageData = response.data;
      if (this.imageData) {
        this.helper.downloadFromBase64(this.imageData[0], 'Informe_Solicitud_Cupos', '.xlsx');
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
      this.toastr.error(error.error.message)
    });
  }
}
