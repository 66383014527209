import { Component, Input, OnInit } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-final-exhibition',
  templateUrl: './final-exhibition.component.html',
  styleUrls: ['./final-exhibition.component.scss']
})
export class FinalExhibitionComponent implements OnInit {
 
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }
}
