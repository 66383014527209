import { BuyerAffiliateResponse } from "./response/buyerAffiliateResponse.model";

export class BuyerAffiliate {
	documentType: String;
	document: number;
	contract: number;
	state: string;
	quotaAffiliate: number;
	topCheckDay: number;
	topCheckPostdated: number;
	topInvoice: number;
    topPromissoryNote: number;
    topLetter: number;
    topOwn: number;
    topPensions: number;
    dateAf: String;
    username: String;
    updateDate: String;
	userUpdate: String;

    public static fromResponse(json: any): BuyerAffiliate {

        const buyerAffiliate: BuyerAffiliate = {
        documentType: json['documentType'],
        document: json['document'],
        contract: json['contract'],
        state: json['state'],
        quotaAffiliate: json['quotaAffiliate'],
        topCheckDay: json['topCheckDay'],
        topCheckPostdated: json['topCheckPostdated'],
        topInvoice: json['topInvoice'],
        topPromissoryNote: json['topPromissoryNote'],
        topLetter: json['topLetter'],
        topOwn: json['topOwn'],
        topPensions: json['topPensions'],
        dateAf: json['dateAf'],
        username: json['username'],
        updateDate: json['updateDate'],
        userUpdate: json['userUpdate'],
    };
    return buyerAffiliate;
    }

    public static fromResponseToArray(resp: BuyerAffiliateResponse): Array<BuyerAffiliate> {
        let buyerAffiliateList: Array<BuyerAffiliate> = [];

        resp.response.forEach(ele => {
            let buyerAffiliate: BuyerAffiliate = {
                documentType: ele.documentType,
                document: ele.document,
                contract: ele.contract,
                state: ele.state,
                quotaAffiliate: ele.quotaAffiliate,
                topCheckDay: ele.topCheckDay,
                topCheckPostdated: ele.topCheckPostdated,
                topInvoice: ele.topInvoice,
                topPromissoryNote: ele.topPromissoryNote,
                topLetter: ele.topLetter,
                topOwn: ele.topOwn,
                topPensions: ele.topPensions,
                dateAf: ele.dateAf,
                username: ele.username,
                updateDate: ele.updateDate,
                userUpdate: ele.userUpdate,
            };
            buyerAffiliateList.push(buyerAffiliate);
        });
        return buyerAffiliateList;
    }
}
