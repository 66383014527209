import { Component, Input, OnInit } from '@angular/core';
import { HabitOfPaymentInactive } from '../../services/subject-credit-history.service';

@Component({
  selector: 'app-table-habit-of-payment-inactive-history',
  templateUrl: './table-habit-of-payment-inactive-history.component.html',
  styleUrls: ['./table-habit-of-payment-inactive-history.component.scss']
})
export class TableHabitOfPaymentInactiveHistoryComponent implements OnInit {


  public headersTable: string[] = ['Entidad informante', 'Tipo cuenta', 'Estado de la obligación', 'Calificación', 'Adjetivo-Fecha',
    'Número Cuenta 9 dígitos', 'Apertura', 'Cierre', 'Valor o cupo inicial', 'Ciudad/fecha', 'Oficina/deudor',
    'Desacuerdo con la información', '47 meses'];

  @Input() habitOfPaymentInactive: HabitOfPaymentInactive = {} as HabitOfPaymentInactive;

    get getSector() {
      return this.habitOfPaymentInactive.sector;
    }

    get getAccounts() {
      return this.habitOfPaymentInactive.accounts;
    }

  constructor() { }

  ngOnInit() {
  }

}
