import {
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalService } from '@services/modal.service';
import { UserAvailableSpaceResponse } from '@models/response/userAvailableSpaceResponse.model';

@Component({
  selector: 'app-modal-request-suspended-status',
  templateUrl: './modal-request-suspended-status.component.html',
  styleUrls: ['./modal-request-suspended-status.component.scss'],
})
export class ModalRequestSuspendedStatusComponent implements OnDestroy {
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }

}
