import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Response } from '@app/models/responseUtil.model';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Banks } from '../models/banks.model';
import { AllocationLimitRequest } from '../models/request/allocationLimitRequest.model';
import { SummaryCifin } from '../models/summaryCifin.modal';
import { QuotaService } from '../services/quota-greateer.service';

@Component({
  selector: 'app-summary-credit-bureaus',
  templateUrl: './summary-credit-bureaus.component.html',
  styleUrls: ['./summary-credit-bureaus.component.scss'],
  providers: [CurrencyPipe]
})
export class SummaryCreditBureausComponent implements OnInit {

  @Input() infoStudyQuote: FormGroup;

  public banks: Array<Banks> = [];
  private allocationLimitRequest = new AllocationLimitRequest();

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private quotaGreaterService: QuotaGreaterService,
    private quotaService: QuotaService,
    public helper: HelperService,
    private currencyPipe: CurrencyPipe) {
  }

  ngOnInit() {
    this.getBanks();
    this.getSummaryBureaus();
  }

  // convenience getter for easy access to form fields
  get f() { return this.infoStudyQuote.controls; }

  getBanks(){
    this.quotaGreaterService.getBanks().subscribe(
      (resp: Response<Banks>) => {
        if (resp.data.length > 0) {
          this.banks = resp.data;
        }
      })
  }

  getSummaryBureaus(){
    this.allocationLimitRequest = {
      document: this.infoStudyQuote.controls.document.value
    }

    this.quotaGreaterService.getSummaryRiskCentral(this.allocationLimitRequest).subscribe(
      (resp: Response<SummaryCifin>) => {
        if (resp.data && resp.data.length > 0) {
          console.log(resp.data[0]);
          this.updateForm((resp.data[0]));
      }
      }
    )
  }

  updateForm(summaryCB: SummaryCifin){
    try {
      this.infoStudyQuote.controls.totalNoSF.setValue(summaryCB.financialSector && summaryCB.financialSector.totalCant ? summaryCB.financialSector.totalCant : 0);
      this.infoStudyQuote.controls.totalNoSR.setValue(summaryCB.realSector && summaryCB.realSector.totalCant ? summaryCB.realSector.totalCant : 0);
      this.infoStudyQuote.controls.totalNoTC.setValue(summaryCB.creditCards && summaryCB.creditCards.totalCant ? summaryCB.creditCards.totalCant : 0);
      this.infoStudyQuote.controls.totalSF.setValue(summaryCB.financialSector && summaryCB.financialSector.totalBalance ? summaryCB.financialSector.totalBalance : 0);
      this.infoStudyQuote.controls.totalSR.setValue(summaryCB.realSector && summaryCB.realSector.totalBalance ? summaryCB.realSector.totalBalance : 0);
      this.infoStudyQuote.controls.totalTC.setValue(summaryCB.creditCards && summaryCB.creditCards.totalBalance ? summaryCB.creditCards.totalBalance : 0);
      this.infoStudyQuote.controls.arrearsNSF.setValue(summaryCB.financialSector && summaryCB.financialSector.moraCant ? summaryCB.financialSector.moraCant : 0);
      this.infoStudyQuote.controls.arrearsNSR.setValue(summaryCB.realSector && summaryCB.realSector.moraCant ? summaryCB.realSector.moraCant : 0);
      this.infoStudyQuote.controls.arrearsNTC.setValue(summaryCB.creditCards && summaryCB.creditCards.moraCant ? summaryCB.creditCards.moraCant : 0);
      this.infoStudyQuote.controls.arrearsSF.setValue(summaryCB.financialSector && summaryCB.financialSector.moraValue ? summaryCB.financialSector.moraValue : 0);
      this.infoStudyQuote.controls.arrearsSR.setValue(summaryCB.realSector && summaryCB.realSector.moraValue ? summaryCB.realSector.moraValue : 0);
      this.infoStudyQuote.controls.arrearsTC.setValue(summaryCB.financialSector ? summaryCB.financialSector.maxHeight : 0);
      this.infoStudyQuote.controls.heightMaxSF.setValue(summaryCB.financialSector ? summaryCB.financialSector.maxHeight : 0);
      this.infoStudyQuote.controls.heightMaxSR.setValue(summaryCB.realSector ? summaryCB.realSector.maxHeight : 0 );
      this.infoStudyQuote.controls.heightMaxTC.setValue(summaryCB.creditCards ? summaryCB.creditCards.maxHeight : '');
      this.infoStudyQuote.controls.currentAccounts.setValue(summaryCB.totalValid);
      this.infoStudyQuote.controls.garnishedAccounts.setValue(summaryCB.totalNoValid);
      this.infoStudyQuote.controls.totalIndebtedness.setValue(summaryCB.totalIndebtedness);
      this.infoStudyQuote.controls.currentBalance.setValue(summaryCB.currentBalance);
      this.infoStudyQuote.controls.balanceAsPrincipal.setValue(summaryCB.balanceAsPrincipal);
      this.infoStudyQuote.controls.porAsPrincipal.setValue(summaryCB.asPrincipal);
      this.infoStudyQuote.controls.balanceInArrears.setValue(summaryCB.balanceInArrears);
      this.infoStudyQuote.controls.quota.setValue(summaryCB.quota);
      this.infoStudyQuote.controls.totalPaymentValueFee.setValue(summaryCB.valuePaymentTotalFee);
      this.infoStudyQuote.controls.maxCalification.setValue(summaryCB.maxQualification);
      this.infoStudyQuote.controls.use.setValue(summaryCB.use);
    }catch(e){
      console.error(e);
    }
  }

}
