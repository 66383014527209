import { Component, OnInit } from "@angular/core";
import { HelperService } from "@app/services/helper.service";
import * as moment from "moment";
import Swal from "sweetalert2";
import { BackupsStoreService } from "../services/backups-store.service";
import { Deductible } from "../models/response/Deductibles.model";
import { BackupsService } from "@app/services/backups.service";
import { DeductibleRequest } from "../models/request/DeductibleRequest.model";
import { BackupsTableHeadersService } from "../services/backups-table-headers.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ResponseObject } from "@app/models/responseUtil.model";
import { SelectModel } from "@app/models/select.model";

@Component({
  selector: "app-deductible",
  templateUrl: "./deductible.component.html",
  styleUrls: ["./deductible.component.scss"],
})
export class DeductibleComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  typeFilter: string = "NO_SELECT";
  public onShowPag: boolean = false;

  listDeductibles: Deductible[] = [];
  public cantPages = 0;
  public currentPage = 1;

  public minInput: Date = moment().subtract(1, "month").toDate();
  public maxInput: Date = moment().add(1, "month").toDate();

  public typeDeducible: Array<SelectModel> = [
    { value: "NO_SELECT", description: "TODOS" },
    {
      value: "GET_AFFILIATE_NO_PAID",
      description: "Reclamaciones menores 180 Dias",
    },
    { value: "AFFILIATE_PAID", description: "Reclamaciones mayores 180 Dias" },
  ];

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public backupsHeaders: BackupsTableHeadersService,
    private backupsStore: BackupsStoreService,
    private backupsService: BackupsService
  ) {}

  ngOnInit() {
    this.backupsStore.statesDeductibles$.subscribe(
      (event) => (this.listDeductibles = event)
    );
    this.backupsStore.changeStateDeductibles([]);
  }

  onSelectTypeFilter($event) {
    this.typeFilter = $event;
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.onDateRange();
  }

  onDateRange() {
    this.spinner.show();

    if (!this.toDate) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Formato invalido",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    let longMonth = this.toDate.getMonth() - this.fromDate.getMonth();

    if (longMonth > 2) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Supero el limite de consulta debe seleccionar maximo 2 meses",
      }).then(() => {});
      this.spinner.hide();
      return;
    }


    if(this.toDate < this.fromDate){
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "La fecha inicial es mayor a la final",
      }).then(() => {});
      this.spinner.hide();
      return;
    }


    if (this.fromDate.getMonth() > this.toDate.getMonth()) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: "Formato invalido",
      }).then(() => {});
      this.spinner.hide();
      return;
    }

    let query: DeductibleRequest = {
      startDate: moment(this.fromDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      endDate: moment(this.toDate)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      filter: this.typeFilter,
      currentPage: this.currentPage,
      perPage: 10,
    };
    this.backupsService.getDeductible(query).subscribe(
      (response) => {
        if (response.status == 200) {
          this.onShowPag = true;
          this.backupsStore.changeStateDeductibles(response.data[0]);
          this.cantPages = response.totalPages;
          this.currentPage = response.currentPage;
        }
        this.spinner.hide();
      },
      (error) => {
        this.backupsStore.changeStateDeductibles([]);
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  downloadFile() {
    let query: DeductibleRequest = {
      startDate: moment(this.fromDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      endDate: moment(this.toDate)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .format("YYYY-MM-DD HH:mm:ss.SSS"),
      filter: this.typeFilter,
    };
    this.spinner.show();
    this.backupsService.downloadDeductibles(query).subscribe(
      (response: ResponseObject<any>) => {
        let dataCurrent = response.data[0];
        this.helper.downloadFromBase64(dataCurrent, "informe", ".xls");
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
