import { createReducer, on } from "@ngrx/store";
import { GetQuotaResponseModel } from "@app/pages/get-quota/models/request/QuotaRequest.model";
import { getQuotas, getQuotasSuccess, getQuotasFailure, getQuotasReset } from '../actions/quota.actions';

export interface GetQuotasState {
    quota: GetQuotaResponseModel;
    loaded: boolean;
    loading: boolean;
    error: any;
}

const initialState: GetQuotasState = {
    quota: null,
    loaded: false,
    loading: false,
    error: null
};

export const _getQuotaReducer = createReducer(initialState,
    on(getQuotas, state => ({ ...state, loading: true, error: null })),
    on(getQuotasSuccess, (state, { quota }) => {
        // console.log("data reducer", quota);
        return { ...state, quota: quota, loading: false, loaded: true, error: null };
    }),
    on(getQuotasFailure, (state, { error }) => ({ ...state, error, loading: false })),
    on(getQuotasReset, state => ({ ...initialState, loaded: false, loading: false, error: null })),
);

export function getQuotaReducer(state: GetQuotasState, action: any) {
    return _getQuotaReducer(state, action);
}