import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiSwitchModule } from "ngx-ui-switch";
import { AppRoutingModule } from "../app-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ModalTransactionComponent } from "./modals/modal-transaction/modal-transaction.component";
import { PaginationComponent } from "./layout/pagination/pagination.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ModalSuccessComponent } from "./modals/modal-success/modal-success.component";
import { ModalReservationSuccessComponent } from "./modals/modal-reservation-success/modal-reservation-success.component";
import { BackButtonComponent } from "./back-button/back-button.component";
import { ModalFileUploadComponent } from "./modals/modal-file-upload/modal-file-upload.component";
import { ModalInvalidCodeComponent } from "./modals/modal-invalid-code/modal-invalid-code.component";
import { ModalNotRegisteredComponent } from "./modals/modal-not-registered/modal-not-registered.component";
import { ModalRequestStatusComponent } from "./modals/modal-request-status/modal-request-status.component";
import { ModalAvailableQuotaComponent } from "./modals/modal-available-quota/modal-available-quota.component";
import { ModalRequestSuspendedStatusComponent } from "./modals/modal-request-suspended-status/modal-request-suspended-status.component";
import { ModalDeniedComponent } from "./modals/modal-denied/modal-denied.component";
import { ModalLockedComponent } from "./modals/modal-locked/modal-locked.component";
import { ModalReserveConfirmComponent } from "./modals/modal-reserve-confirm/modal-reserve-confirm.component";
import { ModalTransactionConfirmComponent } from "./modals/modal-transaction-confirm/modal-transaction-confirm.component";
import { DateRangeComponent } from "./date-range/date-range.component";
import { ModalNopayConfirmComponent } from "./modals/modal-nopay-confirm/modal-nopay-confirm.component";
import { ModalClaimSuccessComponent } from "./modals/modal-claim-success/modal-claim-success.component";
import { ModalClaimConfirmComponent } from "./modals/modal-claim-confirm/modal-claim-confirm.component";
import { ModalOperationsSuccessComponent } from "./modals/modal-operations-success/modal-operations-success.component";
import { ModalOperationsDeniedComponent } from "./modals/modal-operations-denied/modal-operations-denied.component";
import { ModalOperationsConfirmComponent } from "./modals/modal-operations-confirm/modal-operations-confirm.component";
import { ModalTransactionCheckConfirmComponent } from "./modals/modal-transaction-check-confirm/modal-transaction-check-confirm.component";
import { ModalConfigureProfileImgComponent } from "./modals/modal-configure-profile-img/modal-configure-profile-img.component";
import { ModalUserDisableComponent } from "./modals/modal-user-disable/modal-user-disable.component";
import { ModalUserEnableComponent } from "./modals/modal-user-enable/modal-user-enable.component";
import { ModalUserDeleteComponent } from "./modals/modal-user-delete/modal-user-delete.component";
import { ModalProfileUpdateSuccessComponent } from "./modals/modal-profile-update-success/modal-profile-update-success.component";
import { ModalUserAddSuccessComponent } from "./modals/modal-user-add-success/modal-user-add-success.component";
import { ModalUserDisableSuccessComponent } from "./modals/modal-user-disable-success/modal-user-disable-success.component";
import { ModalPaymentAgreementConfirmComponent } from "./modals/modal-payment-agreement-confirm/modal-payment-agreement-confirm.component";
import { ModalPaymentAgreementSuccessComponent } from "./modals/modal-payment-agreement-success/modal-payment-agreement-success.component";
import { ModalPaymentSuccessComponent } from "./modals/modal-payment-success/modal-payment-success.component";
import { ModalPaymentConfirmComponent } from "./modals/modal-payment-confirm/modal-payment-confirm.component";
import { ModalRequestApprovedComponent } from "./modals/modal-request-approved/modal-request-approved.component";
import { ModalRequestDeniedComponent } from "./modals/modal-request-denied/modal-request-denied.component";
import { ModalRequestSettledComponent } from "./modals/modal-request-settled/modal-request-settled.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { ModalErrorComponent } from "./modals/modal-error/modal-error.component";
import { ModalUserEnableSuccessComponent } from "./modals/modal-user-enable-success/modal-user-enable-success.component";
import { ModalUserDeleteSuccessComponent } from "./modals/modal-user-delete-success/modal-user-delete-success.component";
import { ModalUserUpdateSuccessComponent } from "./modals/modal-user-update-success/modal-user-update-success.component";
import { ModalProfileChangePasswordComponent } from "./modals/modal-profile-change-password/modal-profile-change-password.component";
import { ModalProfileSuccessChangePasswordComponent } from "./modals/modal-profile-success-change-password/modal-profile-success-change-password.component";
import { NoResultsComponent } from "./no-results/no-results.component";
import { EmbedTableauComponent } from "./embed-tableau/embed-tableau.component";
import { AffiliateIdInformationComponent } from "./affiliate-id-information/affiliate-id-information.component";
import { SearchComponent } from "./layout/search/search.component";
import { FilterSelectComponent } from "./layout/filter-select/filter-select.component";
import { SearchAutocompleteComponent } from "./layout/search-autocomplete/search-autocomplete.component";
import { ModalEditSettlementComponent } from "./modals/modal-edit-settlement/modal-edit-settlement.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { GoToTopComponent } from "./go-to-top/go-to-top.component";
import { ModalAddQuearyCodeComponent } from "./modals/modal-add-queary-code/modal-add-queary-code.component";
import { ModalAddAddressComponent } from "./modals/modal-add-address/modal-add-address.component";
import { ModalAddPhonesComponent } from "./modals/modal-add-phones/modal-add-phones.component";
import { ModalContractDetailComponent } from "./modals/modal-contract-detail/modal-contract-detail.component";
import { ModalTemplateResponseContractComponent } from "./modals/modal-template-response-contract/modal-template-response-contract.component";
import { ModalAddRepLegalComponent } from "./modals/modal-add-rep-legal/modal-add-rep-legal.component";
import { FloatingButtonComponent } from "./floating-button/floating-button.component";
import { CheckRequestComponent } from "./modals/modal-edit-settlement/check-request/check-request.component";
import { HistoryRequestComponent } from "./modals/modal-edit-settlement/history-request/history-request.component";
import { ManagementRequestComponent } from "./modals/modal-edit-settlement/management-request/management-request.component";
import { ModalGlobalVariousComponent } from "./modals/modal-global-various/modal-global-various.component";
import { ModalAmortizationComponent } from "./modals/modal-amortization/modal-amortization.component";
import { ModalParamsAffiliateComponent } from "./modals/modal-params-affiliate/modal-params-affiliate.component";
import { ModalInvoicesStatusComponent } from './modals/modal-invoices-status/modal-invoices-status.component';

@NgModule({
  declarations: [
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    // PaginationComponent,
    BackButtonComponent,
    ModalTransactionComponent,
    NotFoundComponent,
    ModalSuccessComponent,
    ModalFileUploadComponent,
    ModalRequestStatusComponent,
    ModalAvailableQuotaComponent,
    ModalRequestSuspendedStatusComponent,
    ModalDeniedComponent,
    ModalLockedComponent,
    ModalReserveConfirmComponent,
    ModalTransactionConfirmComponent,
    DateRangeComponent,
    ModalNopayConfirmComponent,
    ModalClaimSuccessComponent,
    ModalClaimConfirmComponent,
    ModalOperationsSuccessComponent,
    ModalOperationsDeniedComponent,
    ModalOperationsConfirmComponent,
    ModalTransactionCheckConfirmComponent,
    ModalConfigureProfileImgComponent,
    ModalUserDisableComponent,
    ModalUserEnableComponent,
    ModalUserDeleteComponent,
    ModalProfileUpdateSuccessComponent,
    ModalUserAddSuccessComponent,
    ModalPaymentAgreementConfirmComponent,
    ModalPaymentAgreementSuccessComponent,
    ModalPaymentSuccessComponent,
    ModalPaymentConfirmComponent,
    ModalUserDisableSuccessComponent,
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    ModalRequestApprovedComponent,
    ModalRequestDeniedComponent,
    ModalRequestSettledComponent,
    ErrorMessageComponent,
    ModalErrorComponent,
    ModalUserEnableSuccessComponent,
    ModalUserDeleteSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalProfileChangePasswordComponent,
    ModalProfileSuccessChangePasswordComponent,
    NoResultsComponent,
    EmbedTableauComponent,
    AffiliateIdInformationComponent,
    SearchComponent,
    FilterSelectComponent,
    SearchAutocompleteComponent,
    ModalEditSettlementComponent,
    UploadFileComponent,
    GoToTopComponent,
    ModalAddQuearyCodeComponent,
    ModalAddAddressComponent,
    ModalAddPhonesComponent,
    ModalContractDetailComponent,
    ModalTemplateResponseContractComponent,
    ModalAddRepLegalComponent,
    FloatingButtonComponent,
    CheckRequestComponent,
    HistoryRequestComponent,
    ManagementRequestComponent,
    ModalGlobalVariousComponent,
    ModalAmortizationComponent,
    ModalParamsAffiliateComponent,
    ModalInvoicesStatusComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
  ],
  exports: [
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    // PaginationComponent,
    BackButtonComponent,
    ModalTransactionComponent,
    NotFoundComponent,
    ModalSuccessComponent,
    ModalFileUploadComponent,
    ModalRequestStatusComponent,
    ModalAvailableQuotaComponent,
    ModalRequestSuspendedStatusComponent,
    ModalDeniedComponent,
    ModalLockedComponent,
    ModalReserveConfirmComponent,
    ModalTransactionConfirmComponent,
    DateRangeComponent,
    ModalNopayConfirmComponent,
    ModalClaimSuccessComponent,
    ModalClaimConfirmComponent,
    ModalOperationsSuccessComponent,
    ModalOperationsDeniedComponent,
    ModalOperationsConfirmComponent,
    ModalTransactionCheckConfirmComponent,
    ModalConfigureProfileImgComponent,
    ModalUserDisableComponent,
    ModalUserEnableComponent,
    ModalUserDeleteComponent,
    ModalUserDisableSuccessComponent,
    ModalProfileUpdateSuccessComponent,
    ModalUserAddSuccessComponent,
    ModalPaymentAgreementConfirmComponent,
    ModalPaymentAgreementSuccessComponent,
    ModalPaymentSuccessComponent,
    ModalPaymentConfirmComponent,
    ModalRequestApprovedComponent,
    ModalRequestDeniedComponent,
    ModalRequestSettledComponent,
    ErrorMessageComponent,
    ModalErrorComponent,
    ModalUserDeleteSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalUserEnableSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalProfileChangePasswordComponent,
    ModalProfileSuccessChangePasswordComponent,
    NoResultsComponent,
    AffiliateIdInformationComponent,
    EmbedTableauComponent,
    SearchComponent,
    FilterSelectComponent,
    SearchAutocompleteComponent,
    ModalEditSettlementComponent,
    UploadFileComponent,
    GoToTopComponent,
    ModalAddQuearyCodeComponent,
    ModalAddAddressComponent,
    ModalAddPhonesComponent,
    ModalContractDetailComponent,
    ModalTemplateResponseContractComponent,
    ModalAddRepLegalComponent,
    FloatingButtonComponent,
    ModalGlobalVariousComponent,
    ModalAmortizationComponent,
    ModalParamsAffiliateComponent,
    ModalInvoicesStatusComponent
  ],
})
export class ComponentsModule {}
