export class Macros {
    id: number;
	generalBalance: Map<String, Object>;
	statesPG: Map<String, Object>;
	financialReasons: Map<String, Object>;
	observationsMacro: string;
	variationInSales: string;
	variationInDebt: string;
	variationInInvTurn: string;
	variationInPortRotation: string;
	variationInNetInc: string;
	observationVar: string;
	public static fromResponse(json: any): Macros {

        const macros: Macros = {

		id: json['id'],
		generalBalance: json['generalBalance'],
		statesPG: json['statesPG'],
		financialReasons: json['financialReasons'],
		observationsMacro: json['observationsMacro'],
		variationInSales: json['variationInSales'],
		variationInDebt: json['variationInDebt'],
		variationInInvTurn: json['variationInInvTurn'],
		variationInPortRotation: json['variationInPortRotation'],
		variationInNetInc: json['variationInNetInc'],
		observationVar: json['observationVar'],
    };
    return macros;
    }
}