import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

import { filter } from 'rxjs/operators';
import { AnalyticsModel } from './models/analytics.model';
import { SelectsService } from './services/selects.service';
import { HelperService } from './services/helper.service';
import { Store } from '@ngrx/store';
import { AppState } from './redux/app.reducer';
import { getSelects } from './redux/actions';
import { CiCdConfigService } from './cicd/ci-cd-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
  public identity: any;
  public currentRoute: string;
  public enviroment = environment.production;
  public env: string = this.enviroment === true ? 'Prod ->' : 'Dev ->';
  public today = new Date();
  public featureMessage: string = '';
  public commitMessage: string = '';
  public author: string = '';
  public apiVersion: string = '';
  public deploymentDate: string = '';

  public test = new Date();

  public navigation: object[] = [
    {
      alt: 'Transacciones',
      img: 'icon-transactions.svg',
      path: '/transacciones',
    },
    {
      alt: 'Estudio de cliente',
      img: 'icon-client-study.svg',
      path: '/estudio-de-cliente',
    },
    { alt: 'Operaciones', img: 'icon-operations.svg', path: '/operaciones' },
    { alt: 'Reintegros', img: 'icon-claims.svg', path: '/reintegros' },
    {
      alt: 'Acuerdos de pago',
      img: 'icon-agreements.svg',
      path: '/acuerdos-de-pago',
    },
    { alt: 'Reportes', img: 'icon-reports.svg', path: '/reportes' },
    {
      alt: 'Administración',
      img: 'icon-administration.svg',
      path: '/administracion',
    },
  ];

  public analytics: AnalyticsModel;
  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }

  constructor(
    public router: Router,
    private selects: SelectsService,
    private helper: HelperService,
    private store: Store<AppState>,
    private ciCd: CiCdConfigService
  ) {
    this.handleAnalytics();
    this.ciCdInit();
    localStorage.removeItem('selectsASR');
  }

  ngOnInit() {
    this.analytics = JSON.parse(localStorage.getItem('identity')) || {};
    this.enviromentAndVersion();
    this.store.dispatch(getSelects());
  }

  ngDoCheck() {
    if (!JSON.parse(localStorage.getItem('identity'))) {
      this.identity = JSON.parse(localStorage.getItem('identity')) || {};
      this.analytics = this.identity;
    }
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const {
        apiUrl,
        urlMicrofrontUsersCovifactura,
        version,
        deployment_date,
      } = apis;
      this.apiVersion = version;
      this.deploymentDate = deployment_date;
      console.log('config ci cd login', apiUrl, urlMicrofrontUsersCovifactura);
    });
  }

  initializeSelects() {
    const localStorageSelectsObject = JSON.parse(
      localStorage.getItem('selectsASR')
    );
    if (!localStorageSelectsObject) {
      this.selects
        .getSelects()
        .subscribe((selectsResponse: any) =>
          localStorage.setItem('selectsASR', JSON.stringify(selectsResponse))
        );
    }
  }

  handleAnalytics() {
    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
  }

  enviromentAndVersion() {
    this.today = new Date(2025, 2, 20);
    this.today.setHours(5, 23, 0);
    this.commitMessage =
      "Fix: Pagare";
    //this.author = "Rubén Martínez";
    this.author = "David Aguja";
    //this.author = 'Erick González'
    this.author = "Rubén Martínez";
    this.featureMessage = `${this.env}, ${new Date(this.today)} - ${
      this.commitMessage
    } - ${this.author}`;
    this.helper.consoleLog(this.featureMessage);
  }

  noAuth() {
    const authRoutes = ['/login', '/forgot'];
    return authRoutes.includes(this.currentRoute);
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
