import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let formatNumber: string = '';
    if(value !== undefined) {
      formatNumber = (value === 0 || value === '0') ? '0' : value;
    } else {
      formatNumber = '--'
    }
    return formatNumber;
  }

}
