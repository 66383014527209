import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalBilling } from '@app/models/global-billing.models';
import { SelectModel } from '@app/models/selectModel';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { RadicationService } from '@app/pages/quota-radication/services/radication-quota.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RulesCreatedService } from '../rules-created/rules-created.service';

@Component({
  selector: 'app-global-billing-recorded',
  templateUrl: './global-billing-recorded.component.html',
  styleUrls: ['./global-billing-recorded.component.scss'],
})
export class GlobalBillingRecordedComponent implements OnInit {
  public tableHeard = [
    'Número de bolsa',
    'Nit',
    'Afiliado',
    'Contrato',
    'Valor bolsa',
    'Porcentaje',
    'N° Cuotas amortizar',
    'N° Cuotas bolsa',
    'Estado',
    'Opciones',
  ];

  private typeFilter = {
    nit: 'NIT',
    contract: 'CONTRATO',
    nameAffiliate: 'NOMBRE',
  };

  globalBilingRecorded: GlobalBilling[] = [];

  dataMemoria = [];

  public filterParams = {
    nit: null,
    contract: null,
    nameAffiliate: null,
  };

  public cantPages = 1;
  public currentPage = 1;

  public stateBill: SelectModel[] = [
    {
      label: 'Activo',
      value: 'ACTIVO',
    },
    {
      label: 'Retirado',
      value: 'INACTIVO',
    },
    {
      label: 'Suspendido',
      value: 'SUSPENDIDO',
    },
    //{
    //  label: "Reliquidar",
    // value: "RELIQUIDAR",
    //},
  ];

  constructor(
    public helper: HelperService,
    private router: Router,
    public backupsHeaders: BackupsTableHeadersService,
    private globalBillingService: GloballBillingService,
    private radicationQuotaService: RadicationService,
    private toastr: ToastrService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    public communication: RulesCreatedService
  ) {
    backupsHeaders.loading$.subscribe(() => {
      this.searchBill();
    });
  }

  ngOnInit() {
    this.currentPage = this.communication.currentPage;
    this.searchBill();
    console.log(this.currentPage);
  }

  filter: string = 'NIT';
  value = null;

  searchBill() {
    this.spinner.show();
    const body = {
      filtro: this.filter,
      valor: this.value,
      pagina: this.currentPage,
      grabadas: 'GRABADAS',
      por_pagina: 10,
    };
    this.globalBillingService.filterGlobalBilling(body).subscribe(
      (resp) => {
        if (!resp.ok) {
          this.spinner.hide();
          this.globalBilingRecorded = [];
          return;
        }
        this.globalBilingRecorded = resp.data.map((element) => {
          element.estado_anterior = element.estado;
          return element;
        });
        this.cantPages = resp.totalPages;
        this.spinner.hide();
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  filterInputs() {
    this.currentPage = 1;
    this.searchBill();
  }

  setItem(typeFilter, value) {
    if (!value) {
      this.filter = 'NIT';
      this.value = null;
      this.searchBill();
      Object.keys(this.filterParams).map((element) => {
        this.filterParams[element] = null;
      });
    } else {
      Object.keys(this.filterParams).map((element) => {
        if (element != typeFilter) {
          this.filterParams[element] = null;
        } else {
          this.value = this.filterParams[element];
        }
      });
      this.filter = this.typeFilter[typeFilter];
    }
  }

  goToPage({ currentPage }): void {
    if (this.filter) {
      this.communication.currentPage = currentPage;
      this.currentPage = currentPage;
      this.searchBill();
      // this.searchBill();
    } else {
      this.communication.currentPage = currentPage;
      this.currentPage = currentPage;
      // this.generalGlobalBillingReport();
    }
  }

  handleSelect(id, $event, index) {
    const value = $event.target.value;
    const bill = this.globalBilingRecorded[index];
    if (
      value == this.stateBill[0].value &&
      bill.estado_anterior === this.stateBill[2].value
    ) {
      this.bagUpdateSuspended(
        this.mapperObject(id, value, bill.estado_anterior),
        true,
        index
      );
      return;
    }

    if (value == this.stateBill[1].value) {
      Swal.fire({
        title: '¿Desea cambiar el estado de la bolsa?',
        showCancelButton: true,
        text:
          this.stateBill[1].value == value
            ? ''
            : 'Recuerda que no se visualizara la bolsa',
        type: 'question',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.sendData(
            this.mapperObject(id, value, bill.estado_anterior),
            index
          );
        } else {
          this.globalBilingRecorded[index].estado = bill.estado_anterior;
        }
      });
    } else {
      this.sendData(this.mapperObject(id, value, bill.estado_anterior), index);
    }
  }

  mapperObject(id, stateCurrent, stateAfter) {
    return {
      id: id,
      usuario: this.radicationQuotaService.getDataUser(),
      estado_anterior: stateAfter,
      estado_nuevo: stateCurrent,
    };
  }

  bagUpdateSuspended(body, init: boolean, index: number) {
    body.init = init;
    this.globalBillingService.bagUpdateSuspended(body).subscribe(
      (resp) => {
        if (resp.ok) {
          if (init) {
            Swal.fire({
              type: 'info',
              title: ``,
              text: resp.message,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
              confirmButtonText: `Confirmar`,
              allowOutsideClick: false,
            }).then((value) => {
              if (value.value === true) {
                this.bagUpdateSuspended(body, false, index);
              } else {
                this.globalBilingRecorded[index].estado = body.estado_anterior;
              }
            });
          } else {
            this.toastr.success(resp.message);
          }
        } else {
          this.globalBilingRecorded[index].estado = body.estado_anterior;
          this.toastr.error(
            `La bolsa numero ${body.id}`,
            `No se actualizo correctamente ${body.estado_nuevo.toLowerCase()}`
          );
        }
      },
      () => {
        this.globalBilingRecorded[index].estado = body.estado_anterior;
        this.toastr.error(
          `La bolsa numero ${body.id}`,
          `No se actualizo correctamente ${body.estado_nuevo.toLowerCase()}`
        );
      }
    );
  }

  sendData(body, index) {
    this.globalBillingService.patchBillUpdateState(body).subscribe(
      (resp) => {
        if (resp.ok) {
          this.toastr.success(`La bolsa numero ${body.id}`, `${resp.message}`);
          this.searchBill();
        } else {
          this.globalBilingRecorded[index].estado = body.estado_anterior;
          this.toastr.error(
            `La bolsa numero ${body.id}`,
            `No se actualizo correctamente ${body.estado_nuevo.toLowerCase()}`
          );
        }
      },
      () => {
        this.globalBilingRecorded[index].estado = body.estado_anterior;
        this.toastr.error(
          `La bolsa numero ${body.id}`,
          `No se actualizo correctamente ${body.estado_nuevo.toLowerCase()}`
        );
      }
    );
  }

  viewTableReport(report: GlobalBilling) {
    localStorage.setItem('select-position', 'true');
    this.router.navigate(['/pages/administrador-bolsa/semaforo-bolsa'], {
      queryParams: { id: report.id },
    });
  }
}
