import { Component, Input, OnInit } from '@angular/core';
import { PreBill } from '@app/models/global-billing.models';
import { SelectModel } from '@app/models/select.model';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from "sweetalert2";
import { ReportNoBillOrBill } from '../models/ReportNoBillorBill.mode';

@Component({
  selector: 'app-installments-active-suspended',
  templateUrl: './installments-active-suspended.component.html',
  styleUrls: ['./installments-active-suspended.component.scss'],
})
export class InstallmentsActiveSuspendedComponent implements OnInit {
  cantPages = 0;
  currentPage = 1;
  typeSearch = null;
  download = false;
  valueCut: number =1;
  tableInstallments = [
    'Id Cuota',
    'Contrato',
    'Nit',
    'Afiliado',
    'Sucursal',
    'Estado',
    'Valor cuota',
    'Corte de factura',
    'N° Cuota factura',
    'Fecha factura',

  ];

  public listOptions: any[] = [
    {
      value: 'ACTIVO',
      description: 'Activo',
    },
    {
      value: 'SUSPENDIDO',
      description: 'Suspendido',
    },
  ];

  filter: boolean = false;
  value = null;

  typeSearchNumber = ['NIT', 'CONTRATO', 'id_bolsa', 'corte_facturacion'];

  installmentActiveSuspended: any[];
  public imageData: Array<any> = [];

  constructor(
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {}

  postInstallmentActiveSuspended(isDownload: boolean = false) {
    this.spinner.show();
    const body = {
      tipo: this.typeSearch,
      pagina: this.currentPage,
      por_pagina: 10,
      descargar: isDownload,
      corte_facturacion: this.valueCut
    };

    if (!isDownload) {
      this.globalBillingService.postInstallmentActiveSuspended(body).subscribe(
        (resp: any) => {
          if (resp.ok) {
            this.installmentActiveSuspended = resp.data;
          }else{
            Swal.fire({
              title: `${resp.message}`,
              text: ``,
              type: "info",
            });

          }
          this.spinner.hide();
          this.cantPages = resp.totalPages;
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    } else {
      this.globalBillingService.postInstallmentActiveSuspended(body).subscribe(
        (resp) => {
          this.imageData = resp.data;
          if (this.imageData) {
            this.helper.downloadFromBase64(
              this.imageData[0],
              'Reporte',
              '.xlsx'
            );
          }
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    }
  }


  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.postInstallmentActiveSuspended();
  }

  selectTypeSearch($event) {
    this.currentPage = 1;
    this.typeSearch = $event;
    //this.postInstallmentActiveSuspended();
  }

  refresh() {
    this.typeSearch = null;
    this.value = null;
    this.currentPage = 1;
    this.valueCut = null;
    this.installmentActiveSuspended = null;
  }

  reset() {
    this.valueCut = null;
    this.installmentActiveSuspended = null;
  }
}
