import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MassiveChargeService } from '@app/services/massive-charge.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormComponent } from './form/form.component';
import { MassiveChargeRoutingModule } from './massive-charge-routing.module';

@NgModule({
  declarations: [FormComponent,PaginationComponent],
  imports: [
    CommonModule,
    FormsModule,
    UiSwitchModule,
    NgxDropzoneModule,
    MassiveChargeRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    CurrencyMaskModule,
    ReactiveFormsModule
  ],
  providers:[MassiveChargeService]
})
export class MassiveChargeModule { }
