export class OptionsExpressReport {
    static filters = [
        { 
            id:  '1',
            name: 'Resumen',
            label: 'resumen'
        },
        { 
            id: '2',
            name: 'Aprobado',
            label:'aprobado'
        },
        { 
            id: '3',
            name: 'Pendiente',
            label:'pendiente'
        },
    ]
    static filtersModal = [
        { 
            id:  '1',
            name: 'Resumen',
            label: 'resumen'
        },
        { 
            id: '2',
            name: 'Negado',
            label:'negado'
        },
        { 
            id: '3',
            name: 'Aprobado',
            label:'aprobado'
        },
        { 
            id: '4',
            name: 'Pendiente',
            label:'pendiente'
        },

    ]
    static filtersAprove = [
        { 
            id: '1',
            name: 'Aprobar',
            label: 'aprobar'
        },
        { 
            id: '2',
            name: 'Negar',
            label:'negar'
        },
        { 
            id: '3',
            name: 'Desbloquear',
            label:'desbloquear'
        },
        { 
            id: '4',
            name: 'Autext',
            label:'autext'
        }
    ] 
    
    static filtersSearch = [
        { 
            id: '1',
            name: 'Contrato',
            label: 'contrato'
        }
    ] 
    
    static filtersQuota = [
        { 
            id: '1',
            name: 'Aprobar',
            label: 'aprobar'
        },
        { 
            id: '2',
            name: 'Negar',
            label:'negar'
        },
    ]
}





