import { Component, OnInit, Input, DoCheck } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { AffiliateInfFiscalAsrTemp } from "@app/models/affiliateInfFiscalAsrTemp.model";
import { SpecialFactorAsrTempPK } from "@app/models/compositeKey/SpecialFactorAsrTempPK.model";
import { Taxpayer } from "@app/models/request/taxpayer.model";
import { SpecialFactorAsrTemp } from "@app/models/specialFactorAsrTemp.model";
import { ContractService } from "@app/services/contract.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Response } from "@app/models/responseUtil.model";
import { AffiliateInfFiscalAsrTempPK } from "@app/models/compositeKey/AffiliateInfFiscalAsrTempPK.model";
import Swal from "sweetalert2";
import { HelperService } from "@app/services/helper.service";
import { CodeSpecialParams } from "@app/models/codeSpecialParams.model";
import { FormContractService } from "../services/form-contract.service";

@Component({
  selector: "app-add-taxpayer",
  templateUrl: "./add-taxpayer.component.html",
  styleUrls: ["./add-taxpayer.component.scss"],
})
export class AddTaxpayerComponent implements OnInit, DoCheck {
  @Input() stepperNext: any;

  public addTaxPlayerForm: FormGroup;

  numberContract = 0;
  affiliateInfFiscalAsrTempPK: AffiliateInfFiscalAsrTempPK;
  taxpayerGlobal: Taxpayer;
  fiscalResponsibility = 0;
  taxRegime = 0;

  //Object's code special
  public listCodeSpecialType: CodeSpecialParams[] = [];

  //Object's code special PEP
  public listCodeSpecialPep: CodeSpecialParams[] = [];

  success: boolean = false;

  isFormCreate: boolean = false;

  isSave: boolean = true;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    public formContractService: FormContractService,
    public helper: HelperService
  ) {
    this.addTaxPlayerForm = this.fb.group({});
  }
  ngDoCheck(): void {
    //Clear code with Mauricio
    if (this.isFormCreate) {
      let data = this.addTaxPlayerForm.value;
      let values = [];
      for (const iterator in data) {
        let value = this.f[iterator].value;
        if (Number(value) >= 1) {
          this.isSave = false;
          values.push(iterator);
        }else if(values.length == 0){
          this.isSave = true;
        }
      }
    }
  }

  ngOnInit(): void {
    this.numberContract = Number(localStorage.getItem("numberContract"));
    this.affiliateInfFiscalAsrTempPK = JSON.parse(
      localStorage.getItem("AffiliateData")
    );
    this.getCodeSpecial("TIPO_DE_CONTRIBUYENTE");
    this.getCodeSpecial("PEP");
  }

  getCodeSpecial(description: string) {
    this.spinner.show();
    this.contractService.getCodeSpecialDescription(description).subscribe(
      (resp: Response<CodeSpecialParams[]>) => {
        if (resp.ok && resp.data) {
          if (description == "TIPO_DE_CONTRIBUYENTE") {
            this.listCodeSpecialType = resp.data[0];
          } else {
            this.listCodeSpecialPep = resp.data[0];
          }
          this.getTaxpayer();
          this.spinner.hide();
        }
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }
  //config form
  setFormType(listCodeSpecial: CodeSpecialParams[]) {
    listCodeSpecial.map((resp) => {
      let isCheck: number = this.findCodeSpecial(resp.codeSpecial);
      this.addTaxPlayerForm.addControl(
        `${resp.codeSpecial}`,
        new FormControl(isCheck)
      );
      if ((resp.codeSpecial === 34 || resp.codeSpecial == 33) && !isCheck) {
        this.f[resp.codeSpecial].disable();
      }
    });
  }

  enableOrDisable() {
    if (this.formContractService.currentPages <= 2) {
      this.addTaxPlayerForm.disable();
    }
  }

  findCodeSpecial(code: number): number {
    let valid = this.taxpayerGlobal
      ? this.taxpayerGlobal.specialFactorAsrTemp.find(
          (resp) => resp.specialFactorAsrTempPK.specialCode === code
        )
      : null;
    if (valid) {
      return valid.factor;
    }

    return 0;
  }

  getTaxpayer() {
    if (this.numberContract) {
      this.spinner.show();
      this.contractService
        .getTaxpayerAsrTemp(
          this.numberContract,
          this.affiliateInfFiscalAsrTempPK.documentType,
          this.affiliateInfFiscalAsrTempPK.document
        )
        .subscribe(
          (resp: Response<Taxpayer>) => {
            if (resp.ok && resp.data) {
              this.taxpayerGlobal = resp.data[0];
              this.setFormType(this.listCodeSpecialType);
              this.setFormType(this.listCodeSpecialPep);
              if (
                this.taxpayerGlobal.affiliateInfFiscalAsrTemp != null &&
                this.taxpayerGlobal.affiliateInfFiscalAsrTemp
                  .affiliateInfFiscalAsrTempPK != null
              ) {
                this.f["41"].setValue(
                  this.taxpayerGlobal.affiliateInfFiscalAsrTemp.idTaxLiability
                );
                this.f["42"].setValue(
                  this.taxpayerGlobal.affiliateInfFiscalAsrTemp.idTaxRegime
                );
              }
              this.enableOrDisable();
            }
            this.success = true;
            this.isFormCreate = true;
            this.spinner.hide();
          },
          (error: any) => {
            console.log(error);
            this.spinner.hide();
          }
        );
    } else {
      this.setFormType(this.listCodeSpecialType);
      this.setFormType(this.listCodeSpecialPep);
      this.enableOrDisable();
      this.isFormCreate = true;
      this.success = true;
    }
  }

  mapperService(): Taxpayer {
    let taxpayer: Taxpayer = new Taxpayer();
    let specialFactor: SpecialFactorAsrTemp[] = [];
    let listCodeSpecialConcat = this.listCodeSpecialType.concat(
      this.listCodeSpecialPep
    );
    listCodeSpecialConcat.map((element) => {
      if (element.codeSpecial < 41) {
        let specialFactorTemp: SpecialFactorAsrTemp =
          new SpecialFactorAsrTemp();
        let specialFactorAsrTempPK: SpecialFactorAsrTempPK =
          new SpecialFactorAsrTempPK();
        specialFactorAsrTempPK.idContract = this.numberContract;
        specialFactorAsrTempPK.specialCode = Number(element.codeSpecial);
        specialFactorTemp.specialFactorAsrTempPK = specialFactorAsrTempPK;
        specialFactorTemp.factor = Number(this.f[element.codeSpecial].value);
        specialFactor.push(specialFactorTemp);
      }
      let affiliateInfFiscal: AffiliateInfFiscalAsrTemp =
        new AffiliateInfFiscalAsrTemp();
      affiliateInfFiscal.affiliateInfFiscalAsrTempPK =
        this.affiliateInfFiscalAsrTempPK;
      affiliateInfFiscal.idTaxLiability = this.f["41"].value;
      affiliateInfFiscal.idTaxRegime = this.f["42"].value;

      taxpayer.specialFactorAsrTemp = specialFactor;
      taxpayer.affiliateInfFiscalAsrTemp = affiliateInfFiscal;

      if(!this.taxpayerGlobal.affiliateInfFiscalAsrTemp){
        taxpayer.affiliateInfFiscalAsrTemp.user = this.formContractService.user;
        taxpayer.affiliateInfFiscalAsrTemp.userUpdate = this.formContractService.user;
      }

    });
    return taxpayer;
  }

  switchIva(switchValue, codeSpecial: number) {
    let code: number = 0;
    //valid the code special 29
    if (codeSpecial === 29) {
      code = 34;
    }
    //valid the code special 30
    if (codeSpecial === 30) {
      code = 33;
    }

    if (
      (switchValue === false || switchValue === 0) &&
      (codeSpecial === 29 || codeSpecial === 30)
    ) {
      this.f[code].setValue(0);
      this.f[code].disable();
    }

    if ((codeSpecial === 29 || codeSpecial === 30) && switchValue) {
      this.f[code].enable();
    }
  }

  get f() {
    return this.addTaxPlayerForm.controls;
  }

  stepTwoSubmit() {
    if (this.numberContract) this.createTaxpayer(this.mapperService());
  }

  createTaxpayer(taxPayer: Taxpayer) {
    this.spinner.show();
    this.contractService.postTaxpayerAsrTemp(taxPayer).subscribe(
      (resp: Response<Taxpayer>) => {
        if (resp.ok && resp.data) {
          Swal.fire({
            type: "success",
            title: "Transacción exitosa",
          }).then(() => {
            this.formContractService.setCurrentStep(4);
            this.stepperNext.next();
          });
        }
        this.spinner.hide();
      },
      (error: any) => {
        Swal.fire({
          type: "error",
          title: "Error en Transacción",
          text: `${error.error.message}`,
        }).then(() => {});
        this.spinner.hide();
      }
    );
  }
}
