
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '@models/user.model';
import { MassiveTransactionRequest } from '@models/request/massiveTransactionRequest.model';
import { MassiveExportTransactionRequest } from '@models/request/massiveExportTransactionRequest.model';
import { MassiveRequest } from '@models/request/massiveRequest.model';
import { MassiveRecordResponse } from '@models/response/massiveRecordResponse.model';
import { ExonerateGuaranteRequest } from '@models/request/exonerateGuaranteRequest.model';
import { OperationItem } from '@models/operationItem.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root',
})
export class ExonerationService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi} = apis;
      this.urlApi = urlApi;
       console.log('config ci cd', apiUrl);
    });
  }

  readToken() {
    if (localStorage.getItem('tokenASR')) {
      this.userToken = localStorage.getItem('tokenASR');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    localStorage.setItem('user', JSON.stringify(
      {
        "userEmail": "CC860028462",
        "token": "29e16b4b3926ded2f2eb65e76b0fc29a",
        "userInfo": {
            "DOCUMENTO": "8600284621",
            "CELULAR": "0",
            "TIPO_DOCUMENTO_USUARIO": "C",
            "IP": "172.17.51.1",
            "LOGO_EMPRESA": "http://200.41.76.20:8080/ServiciosCovifactura/ver/logo/N_8600284621_1.png",
            "EMPRESA": "COVINOC S A",
            "SUCURSAL_USUARIO": "BOGOTA",
            "EMAIL": "claudia.grau@covinoc.com",
            "MENSAJE": "",
            "DOCUMENTO_USUARIO": "860028462",
            "ROL_USUARIO": "1",
            "CAMBIO_CONTRASENA": false,
            "FECHA_INGRESO": "20210901145301",
            "TIPO_DOCUMENTO": "N",
            "NACIMIENTO": "2019-06-15 00:00:00.0",
            "ERROR": 0,
            "TOKEN": "29e16b4b3926ded2f2eb65e76b0fc29a",
            "NOMBRE": "CAPACITACION  COVIFACTURA ",
            "FOTO_USUARIO": "http://200.41.76.20:8080/ServiciosCovifactura/ver/perfil/C_860028462.png"
        }
    }
    ));
    /* {
    "userEmail": "CC860028462",
    "token": "29e16b4b3926ded2f2eb65e76b0fc29a",
    "userInfo": {
        "DOCUMENTO": "8600284621",
        "CELULAR": "0",
        "TIPO_DOCUMENTO_USUARIO": "C",
        "IP": "172.17.51.1",
        "LOGO_EMPRESA": "http://200.41.76.20:8080/ServiciosCovifactura/ver/logo/N_8600284621_1.png",
        "EMPRESA": "COVINOC S A",
        "SUCURSAL_USUARIO": "BOGOTA",
        "EMAIL": "claudia.grau@covinoc.com",
        "MENSAJE": "",
        "DOCUMENTO_USUARIO": "860028462",
        "ROL_USUARIO": "1",
        "CAMBIO_CONTRASENA": false,
        "FECHA_INGRESO": "20210901145301",
        "TIPO_DOCUMENTO": "N",
        "NACIMIENTO": "2019-06-15 00:00:00.0",
        "ERROR": 0,
        "TOKEN": "29e16b4b3926ded2f2eb65e76b0fc29a",
        "NOMBRE": "CAPACITACION  COVIFACTURA ",
        "FOTO_USUARIO": "http://200.41.76.20:8080/ServiciosCovifactura/ver/perfil/C_860028462.png"
    }
} */
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  massiveUpload(file: File) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .put(`${this.urlApi}/pr_exoneraciones_masiva`, formData, options)
      .pipe(
        map((resp: any) => {
          const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
            resp.CARGA
          );
          return massiveRequets;
        })
      );
  }

  massiveVerify(massiveRegistersRequest: MassiveRequest, check: Boolean) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: check ? '1' : '0',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    headers.set('Content-Type', 'application/json');
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };
    const body = check
      ? MassiveRequest.toVerify(massiveRegistersRequest)
      : MassiveRequest.toSend(massiveRegistersRequest);

    return this.http
      .put(`${this.urlApi}/pr_exoneraciones_masiva`, body, options)
      .pipe(
        map((resp: any) => {
          if (check) {
            const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
              resp.CARGA
            );
            return massiveRequets;
          } else {
            const massiveRequets: Array<MassiveRecordResponse> = resp['CARGA']
              ? resp['CARGA'].map((correctRecord) =>
                  MassiveRecordResponse.fromResponse(correctRecord)
                )
              : [];

            return massiveRequets;
          }
        })
      );
  }

  exportMassiveResult(settled: string, status: string) {
    const massiveExportTransactionRequest: MassiveExportTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      settled,
      status,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveExportTransactionRequest.toRequest(
        massiveExportTransactionRequest
      ),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_exportar_exoneracion_masiva`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }

  exonerateGuarante(exonerateGuaranteRequest: ExonerateGuaranteRequest) {
    exonerateGuaranteRequest.documentNumber = this.identity.userInfo.document;
    exonerateGuaranteRequest.documentType = this.identity.userInfo.documentType;
    exonerateGuaranteRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ExonerateGuaranteRequest.toRequest(exonerateGuaranteRequest),
      headers,
    };
    const exonerateArray = JSON.stringify(
      exonerateGuaranteRequest.exonerateArray.map(
        (exonerateItem: OperationItem) =>
          OperationItem.toExonerate(exonerateItem)
      )
    );
    return this.http
      .post(
        `${this.urlApi}/pr_exonerar?StrJArray=${exonerateArray}`,
        {},
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
