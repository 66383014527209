import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from '@app/services/products.service';
import { PaginationComponent } from '../../components/layout/pagination/pagination.component';
import { ModalService } from '../../services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductRequest, ProductStateRequest } from './models/request/product.model';
import { ProductResponse, ProductResponseModel } from './models/response/product.model';
import { HelperService } from '../../services/helper.service';
import Swal from 'sweetalert2';
import { ProductsStoreService } from './services/products-store.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public activeContent: string = "";
  public type: string;
  public cantPages: number = 0;
  public currentPage: number = 1;

  public counterRequest: number = 0;
  public setStateProductRequest: ProductStateRequest = new ProductStateRequest();
  public getProductRequest: ProductRequest = new ProductRequest();
  public products: ProductResponseModel[] = [];
  public productsBD: ProductResponseModel[] = [];
  public selectedProductName: string = '';


  constructor(public modal: ModalService, private productsService: ProductsService, private spinner: NgxSpinnerService,
    public helper: HelperService, public productsStore: ProductsStoreService) { }

  ngOnInit() {
    this.getProduct(1);
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getProduct(currentPage);
  }

  getProduct(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.getProductRequest.currentPage = currentPage;
    this.productsService.getProducts(this.getProductRequest).subscribe(
      (productsResponse: ProductResponse) => {
        if (productsResponse && productsResponse.ok === false) {
          this.handleErrorHttp({}, 'Productos');
          return;
        }
        this.products = productsResponse['data'];
        this.productsBD = this.products
          .map(nonPayment => Object.assign({}, nonPayment));

        this.cantPages = productsResponse['totalPages'];
        this.spinner.hide();
        console.log('products ->', productsResponse);
      }, (error: any) => {
        this.handleErrorHttp(error, 'Productos');
        this.cantPages = 0;
      }
    );
  }


  setProductState(): void {
    this.spinner.show();
    this.counterRequest++;
    this.productsService.updateProductState(this.setStateProductRequest).subscribe(
      (productsResponse: ProductResponse) => {
        if (productsResponse && productsResponse.ok === false) {
          this.handleErrorHttp({}, 'Productos');
          return;
        }

        Swal.fire({
          type: 'success',
          title: `Producto: ${this.selectedProductName || ''} ${this.setStateProductRequest.active ? 'Activado' : 'Desactivado'}.`,
          text: `${productsResponse['message']}`
        }).then(() => {
          this.getProduct(1);
          this.handleResetPagination();
        });

        this.spinner.hide();
        console.log('setStateProduct ->', productsResponse);
      }, (error: any) => {
        this.handleErrorHttp(error, 'Productos');
      }
    );
  }

  toggleStateProduct(product: ProductResponseModel) {
    const { id, active } = product;
    this.setStateProductRequest.active = !active;
    this.setStateProductRequest.product = id;
    const action = this.setStateProductRequest.active ? 'Activar' : 'Desactivar';
    this.selectedProductName = product.name;

    Swal.fire({
      type: 'info',
      title: `¿${action} producto?`,
      text: `${product.name}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Si, ${action}`,
      allowOutsideClick: false
    }).then((value) => {
      if (value.value === true) {
        this.setProductState();
      }
    });
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  handleErrorHttp(error: any, title: string) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title, text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.handleResetPagination();
    this.spinner.hide();
  }

  addProduct() {
    this.activeContent = 'form-user';
    this.type = 'add';
    this.productsStore.setProduct(new ProductResponseModel());
  }

  editProduct(selectedProduct: ProductResponseModel) {
    this.activeContent = 'form-user';
    this.type = 'edit';
    console.log('selectedProduct ->', selectedProduct);
    this.productsStore.setProduct(selectedProduct);
    this.productsStore.setIdProduct(selectedProduct.id);
  }

  disableProduct() {
    this.modal.show('userDisable');
  }

  enableProduct() {
    this.modal.show('userEnable');
  }

  deleteProduct() {
    this.modal.show('userDelete');
  }

  showFormEditUser() {
  }

  back() {
    this.activeContent = '';
    this.getProduct(1);
  }

}