import { Component, OnInit } from '@angular/core';
import { FixedBranch } from '@app/models/branch.model';
import { ComunicationService } from '@app/pages/affiliates/billing-cut/comunication.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fixed-branch-modal',
  templateUrl: './fixed-branch-modal.component.html',
  styleUrls: ['./fixed-branch-modal.component.scss'],
})
export class FixedBranchModalComponent implements OnInit {
  public fixedBranches: string[] = ['Sucursal', 'Porcentaje %'];
  public arrayBranches: any[] = [];

  sendBranches: Array<{ name: string; percentage: number }> = [];
  percentages: number[] = new Array(this.arrayBranches.length).fill(0);
  totalPercentage = 0;
  constructor(
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public getData: ComunicationService,
    public subjectService: SubjectService
  ) {}

  ngOnInit() {
    this.subjectService.branchList$.subscribe((data) => {
      this.arrayBranches = data;
      console.log('El arreglo de sucursales es: ', this.arrayBranches);
    });
  }

  closeModal() {
    this.modal.close();
  }

  saveFixedBranch(){
    this.sendBranches = this.arrayBranches.map((branch, index) => ({
      name: branch.sucursal,
      percentage: this.percentages[index] || 0,
    }));
    this.modal.close();
    this.subjectService.updateBranchList(
      this.sendBranches
    );

    console.log('Datos para enviar:', this.sendBranches);

  }
  /* closeModal() {
    //this.spinnerShow();


    this.modal.close();
  } */

  formattedPercentage(index: number): string {
    const value = this.percentages[index];
    return value ? value.toFixed(2) + ' %' : '';
  }

  updatePercentage(event: Event, index: number): void {
    const value = parseFloat((event.target as HTMLInputElement).value) || 0;
    this.percentages[index] = value < 0 ? 0 : value > 100 ? 100 : value;
    this.updateTotal();
  }

  updateTotal(): void {
    this.totalPercentage = this.percentages.reduce(
      (sum, value) => sum + value,
      0
    );
    if (this.totalPercentage > 100) {
      this.showAlert();
      this.resetPercentage();
    }
  }

  showAlert(): void {
    Swal.fire({
      title: 'Porcentaje inválido',
      text: 'El porcentaje no puede ser mayor a 100%',
      type: 'error',
      confirmButtonText: 'OK',
    });
  }

  resetPercentage(): void {
    //this.percentages.fill(0);
    this.totalPercentage = 0;
  }
}
