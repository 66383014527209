import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-running-proyects',
  templateUrl: './running-proyects.component.html',
  styleUrls: ['./running-proyects.component.scss']
})
export class RunningProyectsComponent implements OnInit {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }

}
