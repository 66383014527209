import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RisksProcessesChargesTabsService {

  constructor() { }

  public affiliatePoliciesCharguesTabs = [
    {
      href: '#individual',
      description: 'Individual',
      icon: 'icon-tab-individual.svg',
      key: 'individual'
    },
    {
      href: '#massive',
      description: 'Masivo',
      icon: 'icon-tab-individual.svg',
      key: 'massive'
    },
  ];

  public chargueHabeasDataTabs = [
    {
      href: '#habeas-data-individual',
      description: 'Cargue individual',
      icon: 'icon-tab-individual.svg',
      key: 'individual'
    },
    {
      href: '#habeas-data-massive',
      description: 'Cargue masivo',
      icon: 'icon-tab-individual.svg',
      key: 'massive'
    },
  ];
}
