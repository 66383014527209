import { Injectable } from '@angular/core';

export interface RecognizeResponse {
  basicInfo: any;
  addressesInfo: any;
  phonesInfo: any;
  emailInfo: any;
  directionsVector: any;
  cellphonesInfo: any;
  codigoRespuesta: string
}

@Injectable({
  providedIn: 'root'
})
export class RecognizeAdapterService {

  constructor() { }

  public adapt(response: any): RecognizeResponse {
    const localizacion = response && response.data && response.data[0] && response.data[0].Localizacion || {};
    return {
        basicInfo: localizacion.NaturalNacional || '',
        addressesInfo: localizacion.Direccion || '',
        phonesInfo: localizacion.Telefono || '',
        cellphonesInfo: localizacion.Celular || '',
        emailInfo: localizacion.Email || '',
        directionsVector: localizacion.IndicadorMovilidad || '',
        codigoRespuesta: response && response.data && response.data[0] && response.data[0].codigoRespuesta || '',
    };
  }
}
