import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@app/models/responseUtil.model';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
    providedIn: 'root'
})
export class ListsCovinocService {
    URL : string;

    constructor(private http: HttpClient,
        private ciCd: CiCdConfigService){
        this.URL = environment.coviUtilsUrl;
        this.ciCdInit()
    }

    ciCdInit() {
        this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
            const {coviUtilsUrl} = apis;
            this.URL = coviUtilsUrl
            console.log('config ci cd', this.URL);
        });
    }

    findAllLists(): Observable<Response<ListsCovinocService>> {
        return this.http.get<Response<ListsCovinocService>>(this.URL+'lists');
    }

}
