import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  DoCheck,
} from "@angular/core";
import { ModalService } from "@services/modal.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DetailContractAsrTemp } from "@app/models/DetailContractAsrTemp.model";
import { FormContractService } from "@app/pages/contracts/services/form-contract.service";
import { ContractService } from "@app/services/contract.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "@app/services/helper.service";
import { FormConsult } from "@app/models/FormConsult.model";
import { FacturationPeriod } from "@app/models/facturationPeriod.model";

@Component({
  selector: "app-modal-contract-detail",
  templateUrl: "./modal-contract-detail.component.html",
  styleUrls: ["./modal-contract-detail.component.scss"],
})
export class ModalContractDetailComponent implements OnInit, OnDestroy {
  public economicCondForm: FormGroup;
  @Output() confirm: EventEmitter<object> = new EventEmitter();

  detailContract: DetailContractAsrTemp | null = null;

  startDate:any = "";
  endDate:any = "";
  numberContract = 0;

  isEditOrNewDetail = false;

  formProduct: FormConsult[] = [];

  facturationPeriod: FacturationPeriod [] = [];

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private formContract: FormContractService,
    private contractService: ContractService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    let today = new Date(this.formContract.dateCreateContract);
    this.startDate = today.toDateString();
    let endDate = today;
    endDate.setFullYear(today.getFullYear()+1);
    this.endDate =endDate;
    this.getFacturationPeriod();
    this.createForm();
    this.numberContract = this.formContract.numberContract;
    this.getPercentage();

    this.f.formContract.valueChanges.subscribe((resp) => {
      this.findDescriptionForm(resp);
    });
  }

  ngOnDestroy(): void {
    this.modal.close();
  }

  createForm() {
    this.economicCondForm = this.fb.group({
      formContract: ["0"],
      queryLevel: [{ value: "99999999", disabled: true }],
      fixedCost: [{ value: "0", disabled: true }],
      percentage: ["", Validators.required],
      valueTx: ["0", Validators.required],
      valueNum: ["0", Validators.required],
      valueNegated: ["0"],
      startDate: [{ value: this.startDate, disabled: true }],
      endDate: [{ value: this.endDate, disabled: true }],
      period: ["MENSUAL"],
      conditions: [{ value: "Servicio Factura", disabled: true }],
      quotas: [{ value: "0", disabled: true }],
      transactionTop: [{ value: "0", disabled: true }],
      noticeOfNonPaymentOperative: [{ value: "0", disabled: true }],
      claimDays: [{ value: "0", disabled: true }],
      refundDays: [{ value: "0", disabled: true }],
      deductible: [{ value: "0", disabled: true }],
      totalContractQuota: ["0"],
      Periodicity: [{ value: "0", disabled: true }],
    });
  }

  findDescriptionForm(form: string) {
    let formCurrent = this.formProduct.find((element) => element.form == form);
    if (formCurrent) {
      this.f.conditions.setValue(formCurrent.description);
    }

    if (formCurrent.fixCost == "S") {
      this.f.fixedCost.setValidators([Validators.required]);
      this.f.fixedCost.enable();
    }
  }

  setFormValue() {
    if (this.modal.payload.contractDetail) {
      this.detailContract = this.modal.payload.contractDetail;

      this.f.formContract.setValue(this.detailContract.pk.form);
      this.f.queryLevel.setValue(this.detailContract.pk.level);
      this.f.fixedCost.setValue(this.detailContract.minimumQuota);
      this.f.percentage.setValue(this.detailContract.percentage);
      this.f.valueTx.setValue(this.detailContract.valueTX);
      this.f.valueNum.setValue(this.detailContract.value);
      this.f.valueNegated.setValue(this.detailContract.negatedValue);
      this.f.period.setValue(this.detailContract.periodInvoice);
      this.f.conditions.setValue(this.detailContract.pk.terms);

      localStorage.removeItem("contractDetailModal");
    }
  }

  getPercentage() {
    this.contractService.getPercentage(this.numberContract).subscribe(
      (resp) => {
        this.f.percentage.setValue(resp.data[0].percentage);
        this.formProduct = resp.data[0].formConsults;
        if (resp.data[0].form.length > 0) {
          this.f.formContract.setValue(resp.data[0].form);
        }

        if (resp.data[0].fixCost) {
          this.f.fixedCost.setValidators([Validators.required]);
          this.f.fixedCost.enable();
        }

        this.setFormValue();
      },
      (error) => {
        this.closeModal();
        Swal.fire({
          type: "error",
          title: "Error en Transacción",
          text: `${error.error.message}`,
        }).then(() => {});
      }
    );
  }

  getFacturationPeriod(){
    this.contractService.geFacturationPeriod().subscribe(resp => {
      this.facturationPeriod = resp.data[0];
    },() => {
      this.facturationPeriod = [];
    })
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.economicCondForm.controls;
  }

  createData() {
    this.detailContract = this.formContract.getStructDetailContract(
      this.economicCondForm
    );
  }

  closeModal() {
    this.createForm();
    localStorage.removeItem("detailContract");
    this.modal.close();
    this.confirm.emit();
  }

  onSubmit() {
    this.createData();
    this.isEditOrNewDetail = this.modal.payload.isEdit;
    this.contractService
      .sendDetailContract(this.detailContract, this.isEditOrNewDetail)
      .subscribe(
        (resp) => {
          if (resp.ok) {
            if (!this.isEditOrNewDetail) {
              this.formContract.addDetail(resp.data);
            } else {
              this.formContract.editDetail(resp.data[0]);
            }
            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
            }).then(() => {
              this.formContract.setCurrentStep(7);
              this.closeModal();
            });
          }
          this.createForm();
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: `${error.error.message}`,
          }).then(() => {});
        }
      );
  }
}
