import { Component, OnInit } from '@angular/core';
import { GlobalClassifiedDebt, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-global-classified-debt-history',
  templateUrl: './global-classified-debt-history.component.html',
  styleUrls: ['./global-classified-debt-history.component.scss']
})
export class GlobalClassifiedDebtHistoryComponent implements OnInit {

  public globalClassifiedDebts : GlobalClassifiedDebt[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.globalClassifiedDebts = this.subjectCreditHistory.getGlobalClassifiedDebt(response);
  }

}
