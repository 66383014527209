import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { City } from '@app/models/city.model';
import { Bank } from '@models/bank.model';
import { Department } from '@models/department.model';
import { DocumentType } from '@models/documentType.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import * as moment from 'moment';


// fileSaver
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import * as FileSaver from "file-saver";
import { PaginationComponent } from '../components/layout/pagination/pagination.component';
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xls";
// ./fileSaver

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public clgStyle =
    "color: #1cb0d3; background-color: #ffffff; font-size: .8rem; border-radius: .5rem; padding: .3rem .5rem;";
  public documentTypes: Array<DocumentType>;
  public departments: Array<Department>;
  public banks: Array<Bank>;
  public nullDateReplacer = "1900-01-01 00:00:00";
  public percentageMask = {
    suffix: " %",
    prefix: "",
    thousands: "",
    decimal: "",
  };
  public copMask = {
    suffix: " cop",
    prefix: "$",
    thousands: ".",
    decimal: ",",
  };
  public noMask = { suffix: "", prefix: "", thousands: "", decimal: "" };
  private urlApi: string;
  private commonToken: string;
  public textExcludeSignsRegex = /[^0-9a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/g;

  public base64PathDocx =
    "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
  public base64PathDoc = "data:application/msword;base64,";

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private ciCd: CiCdConfigService
  ) {
    this.urlApi = environment.urlApi;
    this.commonToken = environment.commonToken;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi, commonToken} = apis;
      this.urlApi = urlApi;
      this.commonToken = commonToken;
       console.log('config ci cd list', apiUrl, commonToken);
    });
  }

  createArray(cantElements: number): Array<any> {
    return Array.from(new Array(cantElements), (x, i) => i + 1);
  }

  createPages(cantElements: number): Array<any> {
    return Array.from(new Array(cantElements), (x, i) => i + 1 + 1);
  }

  currencyInputChanged(value: any): number {
    if (!value || value) {
      return;
    }
    console.log("xxxxxx->", typeof value);
    const num = value.replace(/[\$,\. ]/g, "");
    return Number(num);
  }

  logOut(): void {
    this.router.navigateByUrl("/login");
    localStorage.clear();
  }

  getHeadersAuthorization() {
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(this.commonToken)}`,
    };
  }

  getHeadersAuthorizationDownload() {
    return {
      "Content-Type": "multipart/form-data",
      Accept: "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(this.commonToken)}`,
    };
  }

  markForm(
    form?: NgForm,
    toast?: boolean,
    time?: number,
    textToast?: string
  ): void {
    const timeOut = time || 12000;
    const markForm = form.form.controls;
    const message =
      textToast || "Los campos marcados con rojo son obligatorios.";

    setTimeout(() => {
      Object.keys(markForm).forEach((control, index) => {
        form.form.controls[control].markAsTouched();
      });
      if (toast) {
        this.toastr.warning(message, null, {
          timeOut,
          positionClass: "toast-bottom-right",
        });
      }
    }, 500);
  }

  markFormGroup(
    form?: FormGroup,
    toast?: boolean,
    time?: number,
    textToast?: string
  ): void {
    const timeOut = time || 12000;
    const markForm = form.controls;
    const message =
      textToast || "Los campos marcados con rojo son obligatorios.";

    setTimeout(() => {
      Object.keys(markForm).forEach((control, index) => {
        form.controls[control].markAsTouched();
      });
      if (toast) {
        this.toastr.warning(message, null, {
          timeOut,
          positionClass: "toast-bottom-right",
        });
      }
      console.log(form);
    }, 500);
  }

  handlePristineForm(form: NgForm): void {
    if (!form) return;
    form.form.markAsPristine();
    form.form.markAsUntouched();
    form.form.updateValueAndValidity();
  }

  showToastMessage(
    type: string,
    message: string,
    title?: string,
    timeOut?: number
  ): void {
    let timeOutToast = timeOut || 8000;
    let typeToast = type || "info";
    let titleToast = title || "";
    this.toastr[typeToast](message, titleToast, {
      timeOut: timeOutToast,
      positionClass: "toast-bottom-right",
    });
  }

  onlyNumber(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;
    const isCmdOrCtrl = event.ctrlKey || event.metaKey;
    const isShiftPressed = event.shiftKey;

    if (!((charCode >= 48 && charCode <= 57 && !isShiftPressed) || (charCode >= 96 && charCode <= 105)) &&
        ![8, 9, 13, 27, 37, 38, 39, 40, 46, ].includes(charCode) &&
        !(isCmdOrCtrl && [67, 86, 88].includes(charCode))) {
      event.preventDefault();
    }
  }


  sanitizeInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/[^0-9]/g, '');
    if (inputElement.value !== sanitizedValue) {
      inputElement.value = sanitizedValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }

  maxLength(event, maxLength) {
    const value = event.target["value"];
    if (value.length + 1 > maxLength) {
      {
        if (
          event.which !== 46 &&
          event.which !== 8 &&
          event.which !== 37 &&
          event.which !== 39 &&
          event.which !== 9
        ) {
          event.preventDefault();
        }
      }
    }
  }

  onlyAlphaNumericAddress($event) {
    let letters = /^[A-Za-z]+$/;
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13 ||
      $event.key.match(letters)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d+\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  validateSocialReasonInput($event): string {
    let pattern = /^[A-Za-zñÑ.,\-_()&\s]+$/;
    const arrayCodes = [8, 9, 37, 39];
    if (
      $event.keyCode === 91 ||
      $event.keyCode === 88 ||
      $event.keyCode === 67 ||
      $event.keyCode === 86 ||
      $event.keyCode === 13 ||
      $event.key.match(pattern)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d+\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  onlyNumberLess($event: any): void {
    const arrayCodes = [8, 9, 37, 91, 86, 189, 173];
    console.log($event.which);
    if (
      arrayCodes.includes($event.keyCode) ||
      arrayCodes.includes($event.which)
    ) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  notStartWithZero($event: any, value: any): void {
    console.log("$event", value);
    if ($event.keyCode.toString() === "48" && value && value.length <= 0) {
      $event.preventDefault();
    }
    // return $event.keyCode === 48 && $event.preventDefault();
  }

  getAmountOfPages(registers: any, pageLimit: number): number {
    return registers.length > 0
      ? Math.ceil(parseInt(registers[0].numberRecords || 1) / pageLimit)
      : 1;
  }

  getCities(departmentId: string): Observable<Array<City>> {
    return this.http
      .get(`${this.urlApi}/municipios?id_departamento=${departmentId}`)
      .pipe(
        map((resp: any) => {
          let cities: Array<City> = [];
          cities = resp["MUNICIPIOS"]
            ? resp["MUNICIPIOS"].map((city: City) => City.fromResponse(city))
            : [];
          return cities;
        })
      );
  }

  maskDate($event: any): void {
    let dateText = $event.target.value;
    let value = dateText.replace(/\D/g, "");
    if (value.length >= 5) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
    } else if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    $event.target.value = value.slice(0, 10);
  }

  maskDateFromTC($event) {
    let dateText = $event.target.textContent;
    let v = dateText.replace(/\D/g, "");
    if (v.length >= 5) {
      v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    $event.target.textContent = v.slice(0, 10);
    $event.target.value = v.slice(0, 10);
    // return v.slice(0, 10);
  }

  isBase64(base64String: string): boolean {
    /* if (base64String === '' || base64String.trim() === '') { return false; }
    try {
      return btoa(atob(base64String)) == base64String;
    } catch (err) {
      return false;
    } */
    //return btoa(atob(base64String)) == base64String;
    return (
      base64String === "" ||
      base64String.trim() === "" ||
      base64String.length > 60
    );
  }

  base64ReplacePath(file: File, base64: string) {
    const pathToDelete = `data:${file.type};base64,`;
    return base64.replace(pathToDelete, "");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  base64ToBinary(base64): Uint8Array {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }

  getFileExtension(file: File) {
    return `.${file.name.split(".").pop()}`;
  }

  removeFileExtension(fileName: string) {
    console.log(fileName.split(".").shift());
    return fileName.split(".").shift();
  }
  base64ToBlob(b64Data: any, sliceSize: number = 512) {
    b64Data = b64Data.replaceAll('"', "");
    // console.log(b64Data);
    let byteCharacters = atob(b64Data); //data.file there
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: "data:application/octet-stream;base64",
    });
  }

  downloadFromBase64(
    excelFileBase64: any,
    fileName: string,
    fileExtension: string
  ) {
    if (!excelFileBase64) return;
    const blob = this.base64ToBlob(excelFileBase64);
    this.downloadFromBlob(blob, fileName, fileExtension);
  }

  downloadFromBlob(blob: Blob, fileName: string, fileExtension: string) {
    const dateNow = moment().format("YYYY-MM-DD");
    const splitDate = dateNow.split("-");
    const dateFormat = `${splitDate[0]}-${this.getDayByIndex(splitDate[1])}-${
      splitDate[2]
    }`;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}-${dateFormat}${fileExtension}`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  createFileBlob(message: string): Blob {
    let blob = new Blob([message], { type: "text/plain" });
    return blob;
  }

  getDateId(): string {
    const dateNow = moment().format("YYYY-MM-DD");
    const splitDate = dateNow.split("-");
    const dateFormat = `-${splitDate[0]}-${this.getDayByIndex(splitDate[1])}-${
      splitDate[2]
    }`;
    return dateFormat || "-";
  }

  convertFileToBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getIdentity(): string {
    const identity = localStorage.getItem("identity");
    if (identity) {
      let user = JSON.parse(identity);
      return user.username;
    }

    return "";
  }

  base64ToString(base64: string): string {
    return base64.split(",")[1];
  }

  refresh(actualComponent: string): void {
    this.router
      .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([actualComponent]);
      });
  }

  flattenObject(objectToFlatten: any): object {
    let result = {};

    for (const key in objectToFlatten) {
      if (
        typeof objectToFlatten[key] === "object" &&
        !Array.isArray(objectToFlatten[key])
      ) {
        const tempFlattenObject = this.flattenObject(objectToFlatten[key]);
        for (const flatObjectKey in tempFlattenObject) {
          result[flatObjectKey] = tempFlattenObject[flatObjectKey];
        }
      } else {
        result[key] = objectToFlatten[key];
      }
    }
    return result;
  }

  handleUndefinedError(): void {
    this.spinner.hide();
    Swal.fire({
      type: "error",
      title: "Error",
      text: "Ocurrió un error inesperado",
    });
  }

  pasteOnlyNumber($event) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[^0-9]*/g, "");
  }

  pasteNumberAndLettersOnly($event): string {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[^a-zA-Z0-9]*/g, "");
  }

  onlyAlphaNumeric($event): void {
    if (
      $event.keyCode === 32 ||
      $event.keyCode === 190 ||
      $event.keyCode === 110
    ) {
      $event.preventDefault();
    }
  }

  onlyAlphaNumericInputChanged(value: string): string {
    if (!value) return;
    let formatValue = value.replace(" ", "");
    console.log("formt", formatValue);
    return formatValue;
  }

  pasteOnlyText($event: any) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(this.textExcludeSignsRegex, "");
  }

  onlyText($event) {
    if ($event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/) === -1) {
      $event.preventDefault();
    }
  }

  onlyTextAndNumbers($event) {
    if ($event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]*$/) === -1) {
      $event.preventDefault();
    }
  }

  onlyTextAndNumbersAndPoints($event) {
    if ($event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s.]*$/)) {
      $event.preventDefault();
    }
  }

  calculatePercentage = (percentage: number, of: number): number =>
    Math.floor(of * percentage) / 100;

  trackByFn(index: any, item: any): any {
    return index;
  }

  getDayByIndex(index: any): string {
    // console.log(index);
    index = Number(index);
    if (!index || index > 12) {
      return;
    }
    const years = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ];
    const year = years[index - 1];
    // console.log(year);
    return year;
  }

  getUser() {
    const user = JSON.parse(localStorage.getItem("identity"));
    return user
      ? user.username
      : JSON.parse(localStorage.getItem("identity")).username;
  }

  trimValue(value: string): string {
    return value ? value.trim() : value;
  }

  trimPasted($event): string {
    console.log("paste", $event);
    return "";
  }

  consoleLog(featureMessage: string): void {
    console.info(`%c${featureMessage}`, this.clgStyle); // Version
  }

  equalObjects(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  assignEnumerableKeys(objectModel: any, newInstanceModel: any): any {
    const enumKeys: string[] = Object.keys(newInstanceModel);
    let result: any = {};

    enumKeys.forEach((key, index) => {
      if (objectModel[key] === this.nullDateReplacer) {
        result[key] = "";
        return;
      }
      result[key] = objectModel[key] ? objectModel[key] : "";
    });
    // console.log('result =>', result);
    return result;
  }

  parseDateRange(startDate: any, endDate: any) {
    const initialDate = moment(startDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss");

    const finalDate = moment(endDate)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss");

    return { initialDate, finalDate };
  }

  assignEnumerableKeysAndAssignDefaultValues(
    objectModel: any,
    newInstanceModel: any
  ): any {
    const enumKeys: string[] = Object.keys(newInstanceModel);
    let result: any = {};

    enumKeys.forEach((key, index) => {
      if (objectModel[key] === this.nullDateReplacer) {
        result[key] = "";
        return;
      } else if (key == "perPage") {
        result[key] = 10;
      } else if (
        objectModel[key] === undefined ||
        objectModel[key] === null ||
        objectModel[key] === ""
      ) {
        result[key] = newInstanceModel[key];
      } else {
        result[key] = objectModel[key];
      }

      // result[key] = key == 'perPage' ? 10 : objectModel[key] || newInstanceModel[key];
    });
    return result;
  }

  handleErrorHttp(
    error: any,
    title: string,
    paginationComponent?: PaginationComponent
  ): number {
    let counterRequest: number = 0;
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title,
        text: `${message}`,
        allowOutsideClick: false,
      });
    } else if (counterRequest > 1) {
      this.handleUndefinedError();
    }

    this.spinner.hide();
    return counterRequest;
  }

  converterToHour($event: any) {
    if ($event.target.value.length == 2 || $event.target.value.length == 5) {
      return ($event.target.value = $event.target.value + ":");
    }
  }

  converterToHourTwo(event: any, control: FormControl) {
    let input = event.target.value.replace(/[^0-9]/g, '');

    if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
      control.setValue(input, { emitEvent: false });
      return;
    }

    if (input.length > 4) {
      input = input.slice(0, 4);
    }

    if (input.length >= 3) {
      input = input.slice(0, 2) + ':' + input.slice(2);
    }

    console.log(input);
    event.target.value = input;
    control.setValue(input, { emitEvent: false });
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  createGetRequest(
    object: Object,
    currentPage: number = 1,
    perPage: number = 10
  ) {
    let params = {
      currentPage: "" + currentPage,
      perPage: "" + perPage,
    };

    Object.keys(object).forEach((attr) => {
      if (object[attr]) params[attr] = "" + object[attr];
    });

    return params;
  }

  convertToArray(object: Object): Array<any> {
    let arrayOfObjects: Array<any> = [];
    for (const property in object) {
      arrayOfObjects.push(object[property]);
    }
    return arrayOfObjects;
  }

  scrollToElement(element: HTMLElement): void {
    console.log("scrollToElement", element);
    const { offsetTop} = element;
    window.scrollTo({
      behavior: "smooth",
      top: offsetTop - 90,
      left: 0,
    });
  }

  calculateWorkingHours(
    fromDate: Date,
    toDate: Date,
    allDay: boolean = false
  ): string {
    let from = moment(fromDate);
    let to = moment(toDate);
    let countHours = 0;
    let hoursDiff = to.diff(from, "hours");
    if (hoursDiff > 0) {
      for (let i = 1; i <= hoursDiff; i++) {
        from = from.add(1, "hours");
        if (from.isoWeekday() >= 1 && from.isoWeekday() <= 5) {
          if (allDay) {
            countHours = countHours + 1;
          } else {
            if (from.hour() >= 7 && from.hour() < 18) {
              if (from.hour() == 7) {
                if (from.minutes() >= 30) {
                  countHours = countHours + 1;
                }
              } else if (from.hour() == 17) {
                if (from.minutes() <= 30) {
                  countHours = countHours + 1;
                }
              } else {
                countHours = countHours + 1;
              }
            }
          }
        } else if (from.isoWeekday() == 6) {
          if (allDay) {
            countHours = countHours + 1;
          } else {
            if (from.hour() >= 8 && from.hour() <= 12) {
              countHours = countHours + 1;
            }
          }
        }
      }
      return countHours + " horas hábiles";
    } else {
      return to.diff(from, "minutes") + " minutos transcurridos";
    }
  }

  downloadFromBlobWithoutDate(
    blob: Blob,
    fileName: string,
    fileExtension: string
  ) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}${fileExtension}`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  badResponse(msg: string, code: number): void {
    Swal.fire("Algo Falló " + code, msg, "question");
  }


}
