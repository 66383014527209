
export class AllocationLimitRequest {
    document: number;
    documentType?: string;

    public static toRequest(
        allocationLimitRequest: AllocationLimitRequest
    ): any {
    return {
        document: allocationLimitRequest.document ? allocationLimitRequest.document : 0,
        documentType: allocationLimitRequest.documentType ? allocationLimitRequest.documentType : ''
    };
    }
}
