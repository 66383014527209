import { Component, OnInit } from "@angular/core";
import { NetFactoringBackEndService } from "@app/services/net-factoring-back-end.service";
import { NetFactoringService } from "../services/net-factoring.service";

import { Affiliate } from "@app/pages/net-factoring/models/Affiliate.model";
import { Response } from "@app/models/responseUtil.model";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-names-affiliates",
  templateUrl: "./names-affiliates.component.html",
  styleUrls: ["./names-affiliates.component.scss"],
})
export class NamesAffiliatesComponent implements OnInit {
  public namesAffiliateList: Array<Affiliate>;

  public cantPages = 1;
  public currentPage = 1;

  constructor(
    private spinner: NgxSpinnerService,
    public netFactoringService: NetFactoringService,
    public netFactoringBackendService: NetFactoringBackEndService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.netFactoringBackendService.getAffiliates(this.currentPage).subscribe(
      (response: Response<Affiliate>) => {
        this.namesAffiliateList = response.data;
        this.cantPages = response.totalPages;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
  goToPage({ currentPage }) {
    console.log(currentPage);
    this.currentPage = currentPage;
    this.getData();
  }
}
