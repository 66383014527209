import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth.service';
import { LoginModel } from '@models/login.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolesService } from '@app/services/roles.service';
import { environment } from '../../../environments/environment';
declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // Properties
  public userLogin: FormGroup;
  public hidePassword = false;
  public isProductionEnv: boolean;
  public rolesList: any;

  public dataLogin: LoginModel = {
    username: '',
    password: '',
  };

  public year = new Date();

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public roles: RolesService
  ) {
    //this.isProductionEnv = true;
    this.isProductionEnv = environment.production;
    this.userLogin = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });

    this.userLogin.setValue(this.dataLogin);
  }

  ngOnInit() {
    this.roles.getRoles().subscribe((resp: any) => {
      this.rolesList = resp.data;
    });
  }

  pass() {
    this.router.navigateByUrl('/contrasena');
  }

  login(form?: NgForm, dataLogin?: object) {
    //TODO: Por probar (testing)
    /* this.rolesList[2].users = "enrique.garcia";
    console.log(this.rolesList); */

    if (dataLogin) {
      this.userLogin.setValue(dataLogin);
    }
    this.spinner.show();
    this.auth.login({
      ...this.userLogin.value,
      username: this.userLogin.value.username.toLowerCase().trim()
    })
      .subscribe(
      (resp: any) => {

        const userName = resp.data.user['username'];

        if(this.isProductionEnv === false) {
          this.loginOk(resp, 'respaldos');
          return;
        }

        if (this.isProductionEnv === true) {
          if (this.roles.isAuthorized(userName, 'quota', this.rolesList)) {
            this.loginOk(resp, 'cupos');
          } else if(this.roles.isAuthorized(userName, 'backing', this.rolesList)) {
            this.loginOk(resp, 'respaldos');
          } else if(this.roles.isAuthorized(userName, 'affiliate', this.rolesList)) {
            this.loginOk(resp, 'afiliados/link-afiliado');
          } else if(this.roles.isAuthorized(userName, 'non-payment-notices', this.rolesList)) {
            this.loginOk(resp, 'respaldos/marcar-avis-de-no-pago');
          } else if(this.roles.isAuthorized(userName, 'affiliate', this.rolesList)) {
            this.loginOk(resp, 'afiliados/link-afiliado');
          } else if(this.roles.isAuthorized(userName, 'product', this.rolesList)) {
            this.loginOk(resp, 'productos');
          } else if(this.roles.isAuthorized(userName, 'contract', this.rolesList)) {
            this.loginOk(resp, 'facturacion-global');
          } else if(this.roles.isAuthorized(userName, 'transactions', this.rolesList)) {
            this.loginOk(resp, 'transacciones-cupos');
          } else if(this.roles.isAuthorized(userName, 'request-management', this.rolesList)) {
            this.loginOk(resp, 'gestion-solicitud');
          } else if(this.roles.isAuthorized(userName, 'risks-processes-charges', this.rolesList)) {
            this.loginOk(resp, 'cargues-proceso-de-riesgo');
          } else if(this.roles.isAuthorized(userName, 'net-factoring', this.rolesList)) {
            this.loginOk(resp, 'netFactoring');
          } else if(this.roles.isAuthorized(userName, 'related-documents', this.rolesList)) {
            this.loginOk(resp, 'related-documents');
          } else if(this.roles.isAuthorized(userName, 'fau-digital', this.rolesList)) {
            this.loginOk(resp, 'respaldos/fau-digital');
          } else if(this.roles.isAuthorized(userName, 'buyer-report', this.rolesList)) {
            this.loginOk(resp, 'informacion-comprador');
          } else if(this.roles.isAuthorized(userName, 'express-report', this.rolesList)) {
            this.loginOk(resp, 'reporte-express');
          } else if(this.roles.isAuthorized(userName, 'affiliate-report', this.rolesList)) {
            this.loginOk(resp, 'reporte-afiliado');
          } else if(this.roles.isAuthorized(userName, 'history-buyer-report', this.rolesList)) {
            this.loginOk(resp, 'reporte-comprador');
          } else if(this.roles.isAuthorized(userName, 'admin-covifactura-users', this.rolesList)) {
            this.loginOk(resp, 'admin-usuarios-covifactura');
          } else if(this.roles.isAuthorized(userName, 'experian', this.rolesList)) {
            this.loginOk(resp, 'experian');
          } else {
            this.loginUnauthorized(userName);
          }
        }
      },
      (err) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          text: 'Usuario o contraseña no válidos',
        });
      }
    );
  }

  loginOk(resp: any, route: string) {
    this.router.navigateByUrl(`/pages/${route}`);
    this.spinner.hide();
    this.toastr.success(resp.data.user['name'], 'Bienvenid@');
  }

  loginUnauthorized(userName: string) {
    this.spinner.hide();
    localStorage.clear();
    Swal.fire({
      title: `Usuario ${userName} no autorizado`,
      text: `No tiene permisos para ingresar a esta aplicación.`,
      type: 'info',
    });
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  toastNotification(title: string, description: string) {
    this.toastr.success(title, description);
  }
}
