import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HelperService } from "@app/services/helper.service";
import * as moment from "moment";
import { MacrosService } from "../services/macros.service";
import { Formula } from "../models/formula.model";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import Swal from "sweetalert2";
import { Response } from "@app/models/responseUtil.model";
import { Macros } from "../models/macros.model";
import { SubjectService } from "@app/services/subject.service";

@Component({
  selector: "app-macros",
  templateUrl: "./macros.component.html",
  styleUrls: ["./macros.component.scss"],
})
export class MacrosComponent implements OnInit {
  @Input() infoStudyQuote: FormGroup;

  private formula;
  private formulaTotals;
  private formulaAcFN;
  private formulaPercBetYears;
  private formulaCapacitySales;
  private formulaPGProyect;
  private formulaCurrentReason;
  private formulaAcidTest;
  private formulaOperaC;
  private formulaCurrentReasonPer;
  private formulaAcidTestReason;
  private newHeader: string = "";
  private editField: string;
  private propertySaved: string;
  private idList: number;
  private stateMacroType: string;

  public headers: any = {};
  public headersPG: any = {};
  public headersFR: any = {};

  public generalBalance: Array<any> = [];
  public statesPG: Array<any> = [];
  public financialReasons: Array<any> = [];
  private superSocieties = false;

  private percentage = 0.01;

  constructor(
    private macrosService: MacrosService,
    public helper: HelperService,
    private quotaGreaterService: QuotaGreaterService,
    private subjectService: SubjectService
  ) {}

  ngOnInit() {
    this.chargeFormulas();
    this.headers = this.macrosService.headers;
    this.headersPG = this.macrosService.headersPGState;
    this.headersFR = this.macrosService.headersFR;
    this.financialReasons = this.macrosService.financialReasons;
    this.generalBalance = this.macrosService.generalBalance;
    this.statesPG = this.macrosService.pgState;
    this.getStateMacroType();
    this.setMacro();
    this.fillFields();
    this.subjectService.stateMacroType$.subscribe((macroType: any) => {
      this.stateMacroType = macroType;
    });
  }

  getStateMacroType() {
    this.subjectService.stateMacroType$.subscribe((macroType: any) => {
      this.stateMacroType = macroType;
    });
  }

  setMacro() {
    this.setSuperSocietiesValues();
    this.setOtherForms20MMacros();
    this.setInformMacros();
  }

  chargeFormulas() {
    let formulas: Array<Formula>;
    this.quotaGreaterService
      .getFormulas()
      .subscribe((resp: Response<Formula>) => {
        if (resp.data.length > 0) {
          formulas = resp.data;
          formulas.forEach((element) => {
            if (element.idFormula == 1) {
              this.formula = element.formula;
            }
            if (element.idFormula == 2) {
              this.formulaTotals = element.formula;
            }
            if (element.idFormula == 3) {
              this.formulaAcFN = element.formula;
            }
            if (element.idFormula == 4) {
              this.formulaPercBetYears = element.formula;
            }
            if (element.idFormula == 5) {
              this.formulaCapacitySales = element.formula;
            }
            if (element.idFormula == 6) {
              this.formulaPGProyect = element.formula;
            }
            if (element.idFormula == 7) {
              this.formulaCurrentReason = element.formula;
            }
            if (element.idFormula == 8) {
              this.formulaAcidTest = element.formula;
            }
            if (element.idFormula == 9) {
              this.formulaOperaC = element.formula;
            }
            if (element.idFormula == 10) {
              this.formulaCurrentReasonPer = element.formula;
            }
            if (element.idFormula == 11) {
              this.formulaAcidTestReason = element.formula;
            }
          });
        }
      });
  }

  changeValueHeader(event: any) {
    this.newHeader = event.target.textContent;
  }

  updateHeader(event: any, property: string) {
    if (property == "numberCalculated") {
      this.headers.numberCalculated = event.target.textContent;
      return;
    }

    if (event.target.textContent == this.newHeader) {
      if (property === "year") {
        moment.locale("es");
        if (Number(this.newHeader) > 0 && Number(this.newHeader) <= 12) {
          let dateY = moment().month(Number(this.newHeader) - 1);
          this.headers.monthValue = this.newHeader;
          event.target.textContent = moment(dateY).format("MMMM");
          this.headers.year = moment(dateY).format("MMMM");
          this.headersPG.year = moment(dateY).format("MMMM");
          this.financialReasons[0]["valY"] = moment(dateY).format("MMMM");
          this.calculateEveryField("valY");
          console.log(this.headers);
          console.log(this.headersPG);
        } else {
          Swal.fire({
            type: "error",
            title: "Error",
            text: "Se debe ingresar un número de mes (1 - 12)",
          }).then(() => {});
          event.target.textContent = this.headersPG[property];
          return;
        }
      } else {
        if (this.newHeader.length > 4 || this.newHeader.length < 4) {
          Swal.fire({
            type: "error",
            title: "Error",
            text: "El valor ingresado no es un año permitido",
          }).then(() => {});
          event.target.textContent = this.headersPG[property];
          return;
        }
        if (
          Number(this.newHeader) < 2010 ||
          Number(this.newHeader) > moment().year()
        ) {
          Swal.fire({
            type: "error",
            title: "Error",
            text:
              "El valor ingresado no esta el rango de años permitidos (2010 - " +
              moment().year() +
              ")",
          }).then(() => {});
          event.target.textContent = this.headersPG[property];
          return;
        }
        this.headers[property] = this.newHeader.trim();
        this.headersPG[property] = this.newHeader.trim();
        if (property == "year1") {
          this.financialReasons[0]["valY1"] = this.newHeader.trim();
        }
        if (property == "year2") {
          this.financialReasons[0]["valY2"] = this.newHeader.trim();
        }
        this.headers.yearCalculated =
          this.headers.year2 + "/" + this.headers.year1;
        this.headersPG.yearCalculated =
          this.headers.year2 + "/" + this.headers.year1;
      }
    }
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent.replace(/\.|></g, "");
    this.editField = this.editField.replace(/_/g, "");
    this.propertySaved = property;
    this.idList = id;
  }

  updateList(id: number, property: string, event: any) {
    if (this.propertySaved == property && this.idList == id) {
      this.generalBalance[id][property] = this.fieldPropertyAdd(this.editField);
      this.calculateEveryField(property);
      return (event.target.textContent = this.editField
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."));
    }
  }

  updateListSPG(id: number, property: string, event: any) {
    if (this.propertySaved == property && this.idList == id) {
      this.statesPG[id][property] = this.fieldPropertyAdd(this.editField);
      this.calculateEveryField(property);
      return (event.target.textContent = this.editField
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."));
    }
  }

  updateListFR(id: number, property: string, event: any) {
    if (this.propertySaved == property && this.idList == id) {
      this.financialReasons[id][property] = this.fieldPropertyAdd(
        this.editField
      );
      this.calculateEveryField(property);
      return (event.target.textContent = this.editField
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."));
    }
  }

  fieldPropertyAdd(value: string): string {
    return value;
  }

  calculateActivateTotalsC(property: string) {
    let sumGenBalValTotalsC = 0;
    let sumGenBalValPassiveTotalsC = 0;
    let sumGenPassTot =
      this.generalBalance[19][property] != ""
        ? Number(this.generalBalance[19][property])
        : 0;
    let sumGenHeritageTot =
      this.generalBalance[29][property] != ""
        ? Number(this.generalBalance[29][property])
        : 0;
    this.generalBalance.forEach((element) => {
      if (element.id < 7) {
        sumGenBalValTotalsC = this.macrosService.calculateFormulaPerc(
          this.formulaTotals,
          sumGenBalValTotalsC,
          element[property] != "" ? element[property] : 0
        );
      }
      if (element.id >= 15 && element.id <= 19) {
        sumGenBalValPassiveTotalsC = this.macrosService.calculateFormulaPerc(
          this.formulaTotals,
          sumGenBalValPassiveTotalsC,
          element[property] != "" ? element[property] : 0
        );
      }
      if (element.id > 20 && element.id < 25) {
        sumGenPassTot = this.macrosService.calculateFormulaPerc(
          this.formulaTotals,
          sumGenPassTot,
          element[property] != "" ? element[property] : 0
        );
      }
      if (element.id > 25 && element.id < 30) {
        sumGenHeritageTot = this.macrosService.calculateFormulaPerc(
          this.formulaTotals,
          sumGenHeritageTot,
          element[property] != "" ? element[property] : 0
        );
      }
    });

    this.generalBalance[6][property] = sumGenBalValTotalsC;
    this.generalBalance[19][property] = sumGenBalValPassiveTotalsC;
    this.generalBalance[24][property] = sumGenPassTot;
    this.generalBalance[30][property] = sumGenHeritageTot;

    this.generalBalance[9][property] = this.macrosService.calculateFormulaPerc(
      this.formulaAcFN,
      this.generalBalance[7][property] != ""
        ? Number(this.generalBalance[7][property])
        : 0,
      this.generalBalance[8][property] != ""
        ? Number(this.generalBalance[8][property])
        : 0
    );

    let activateTotalsWV = this.macrosService.calculateFormulaPerc(
      this.formulaTotals,
      this.generalBalance[6][property] != ""
        ? Number(this.generalBalance[6][property])
        : 0,
      this.generalBalance[9][property] != ""
        ? Number(this.generalBalance[9][property])
        : 0
    );

    this.generalBalance[11][property] = this.macrosService.calculateFormulaPerc(
      this.formulaTotals,
      activateTotalsWV,
      this.generalBalance[10][property] != ""
        ? Number(this.generalBalance[10][property])
        : 0
    );

    this.generalBalance[13][property] = this.macrosService.calculateFormulaPerc(
      this.formulaTotals,
      this.generalBalance[11][property] != ""
        ? Number(this.generalBalance[11][property])
        : 0,
      this.generalBalance[12][property] != ""
        ? Number(this.generalBalance[12][property])
        : 0
    );

    this.generalBalance[31][property] = this.macrosService.calculateFormulaPerc(
      this.formulaTotals,
      this.generalBalance[24][property] != ""
        ? Number(this.generalBalance[24][property])
        : 0,
      this.generalBalance[30][property] != ""
        ? Number(this.generalBalance[30][property])
        : 0
    );

    this.generalBalance[32][property] = this.macrosService.calculateFormulaPerc(
      this.formulaAcFN,
      this.generalBalance[13][property] != ""
        ? Number(this.generalBalance[13][property])
        : 0,
      this.generalBalance[31][property] != ""
        ? Number(this.generalBalance[31][property])
        : 0
    );

    if (property === "valY1") {
      this.generalBalance[6]["perc1"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[6][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[9]["perc1"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[9][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[11]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[11][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[13]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[13][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[19]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[19][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[24]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[24][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[30]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[30][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[31]["perc1"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[31][property]),
          Number(this.generalBalance[13][property])
        );
    } else if (property === "valY2") {
      this.generalBalance[6]["perc2"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[6][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[9]["perc2"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[9][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[11]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[11][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[13]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[13][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[19]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[19][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[24]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[24][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[30]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[30][property]),
          Number(this.generalBalance[13][property])
        );
      this.generalBalance[31]["perc2"] =
        this.macrosService.calculateFormulaPerc(
          this.formula,
          Number(this.generalBalance[31][property]),
          Number(this.generalBalance[13][property])
        );
    } else if (property === "valY") {
      this.generalBalance[6]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[6][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[9]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[9][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[11]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[11][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[13]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[13][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[19]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[19][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[24]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[24][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[30]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[30][property]),
        Number(this.generalBalance[13][property])
      );
      this.generalBalance[31]["perc"] = this.macrosService.calculateFormulaPerc(
        this.formula,
        Number(this.generalBalance[31][property]),
        Number(this.generalBalance[13][property])
      );
    }
    this.generalBalance[6]["percBetYears"] =
      this.generalBalance[6]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[6]["valY2"]),
            Number(this.generalBalance[6]["valY1"])
          )
        : "";
    this.generalBalance[6]["valBetYears"] =
      this.generalBalance[6]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[6]["valY2"]),
            Number(this.generalBalance[6]["valY1"])
          )
        : "";
    this.generalBalance[9]["percBetYears"] =
      this.generalBalance[9]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[9]["valY2"]),
            Number(this.generalBalance[9]["valY1"])
          )
        : "";
    this.generalBalance[9]["valBetYears"] =
      this.generalBalance[9]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[9]["valY2"]),
            Number(this.generalBalance[9]["valY1"])
          )
        : "";
    this.generalBalance[11]["percBetYears"] =
      this.generalBalance[11]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[11]["valY2"]),
            Number(this.generalBalance[11]["valY1"])
          )
        : "";
    this.generalBalance[11]["valBetYears"] =
      this.generalBalance[11]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[11]["valY2"]),
            Number(this.generalBalance[11]["valY1"])
          )
        : "";
    this.generalBalance[13]["percBetYears"] =
      this.generalBalance[13]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[13]["valY2"]),
            Number(this.generalBalance[13]["valY1"])
          )
        : "";
    this.generalBalance[13]["valBetYears"] =
      this.generalBalance[13]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[13]["valY2"]),
            Number(this.generalBalance[13]["valY1"])
          )
        : "";
    this.generalBalance[19]["percBetYears"] =
      this.generalBalance[19]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[19]["valY2"]),
            Number(this.generalBalance[19]["valY1"])
          )
        : "";
    this.generalBalance[19]["valBetYears"] =
      this.generalBalance[19]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[19]["valY2"]),
            Number(this.generalBalance[19]["valY1"])
          )
        : "";
    this.generalBalance[24]["percBetYears"] =
      this.generalBalance[24]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[24]["valY2"]),
            Number(this.generalBalance[24]["valY1"])
          )
        : "";
    this.generalBalance[24]["valBetYears"] =
      this.generalBalance[24]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[24]["valY2"]),
            Number(this.generalBalance[24]["valY1"])
          )
        : "";
    this.generalBalance[30]["percBetYears"] =
      this.generalBalance[30]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[30]["valY2"]),
            Number(this.generalBalance[30]["valY1"])
          )
        : "";
    this.generalBalance[30]["valBetYears"] =
      this.generalBalance[30]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[30]["valY2"]),
            Number(this.generalBalance[30]["valY1"])
          )
        : "";
    this.generalBalance[31]["percBetYears"] =
      this.generalBalance[31]["valY1"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaPercBetYears,
            Number(this.generalBalance[31]["valY2"]),
            Number(this.generalBalance[31]["valY1"])
          )
        : "";
    this.generalBalance[31]["valBetYears"] =
      this.generalBalance[31]["valY2"] != ""
        ? this.macrosService.calculateFormulaPerc(
            this.formulaAcFN,
            Number(this.generalBalance[31]["valY2"]),
            Number(this.generalBalance[31]["valY1"])
          )
        : "";
  }

  updategenBalPerc(propertyVar: string, propertyToCal: string) {
    let totalValY1 = this.generalBalance[13][propertyVar];
    this.generalBalance.forEach((element) => {
      element[propertyToCal] = element[propertyVar]
        ? this.macrosService.calculateFormulaPerc(
            this.formula,
            Number(element[propertyVar]),
            totalValY1
          )
        : "";
      element["percBetYears"] =
        element["valY1"] != ""
          ? this.macrosService.calculateFormulaPerc(
              this.formulaPercBetYears,
              element["valY2"] != "" ? Number(element["valY2"]) : 0,
              Number(element["valY1"])
            )
          : "";
      element["valBetYears"] = this.macrosService.calculateValBetYear(
        Number(element["valY2"]),
        Number(element["valY1"])
      );
    });
    this.generalBalance[29][propertyVar] = this.statesPG[8][propertyVar];
  }

  updateSPGPerc(propertyVar: string, propertyToCal: string) {
    let month =
      this.headers.monthValue != "" ? Number(this.headers.monthValue) : 0;

    this.statesPG[2][propertyVar] = this.macrosService.calculateUtilBruta(
      Number(this.statesPG[0][propertyVar]),
      Number(this.statesPG[1][propertyVar])
    );

    this.statesPG[4][propertyVar] = this.macrosService.calculateUtilBruta(
      Number(this.statesPG[2][propertyVar]),
      Number(this.statesPG[3][propertyVar])
    );

    this.statesPG[8][propertyVar] = this.macrosService.calculateUtilNeta(
      Number(this.statesPG[4][propertyVar]),
      Number(this.statesPG[5][propertyVar]),
      Number(this.statesPG[6][propertyVar]),
      Number(this.statesPG[7][propertyVar])
    );

    this.statesPG.forEach((element) => {
      element.perc1 = this.macrosService.calculateUtilPerc(
        Number(element.valY1),
        Number(this.statesPG[0].valY1)
      );

      element.perc2 = this.macrosService.calculateUtilPerc(
        Number(element.valY2),
        Number(this.statesPG[0].valY2)
      );

      element.perc = this.macrosService.calculateUtilPerc(
        Number(element.valY),
        Number(this.statesPG[0].valY)
      );

      element.proyect = this.macrosService.calculateUtilProyect(
        Number(element.valY),
        Number(month)
      );

      element.percBetYears = this.macrosService.calculatePerBetYear(
        Number(element.valY1),
        Number(element.valY2)
      );

      element.valBetYears = this.macrosService.calculateValBetYear(
        Number(element.valY1),
        Number(element.valY2)
      );
      if (element.id === 12) {
        element.proyect = this.macrosService.calculateSale(
          Number(this.statesPG[0].proyect)
        );
      }
    });

    this.statesPG[10].valY1 = this.macrosService.calculateEBITDA(
      Number(this.statesPG[4].valY1),
      Number(this.statesPG[9].valY1)
    );

    this.statesPG[10].valY2 = this.macrosService.calculateEBITDA(
      Number(this.statesPG[4].valY2),
      Number(this.statesPG[9].valY2)
    );

    this.statesPG[10].valY = this.macrosService.calculateEBITDA(
      Number(this.statesPG[4].valY),
      Number(this.statesPG[9].valY)
    );

    this.statesPG[8].proyect = this.macrosService.calculateUtilNeta(
      Number(this.statesPG[4].proyect),
      Number(this.statesPG[5].proyect),
      Number(this.statesPG[6].proyect),
      Number(this.statesPG[7].proyect)
    );

    this.statesPG[11][propertyVar] = this.macrosService.calculateSale(
      Number(this.statesPG[0][propertyVar])
    );
  }

  updateFinancialReasons(propertyVar: string) {
    //Razón Corriente
    this.financialReasons[1][propertyVar] = this.macrosService.calcularData(
      Number(this.generalBalance[6][propertyVar]),
      Number(this.generalBalance[19][propertyVar])
    );

    //Prueba Acida
    this.financialReasons[2][propertyVar] = this.macrosService.calculateReasons(
      Number(this.generalBalance[6][propertyVar]),
      Number(this.generalBalance[3][propertyVar]),
      Number(this.generalBalance[19][propertyVar])
    );

    //Capital de Trabajo
    this.financialReasons[3][propertyVar] =
      this.macrosService.calculateValBetYear(
        Number(this.generalBalance[6][propertyVar]),
        Number(this.generalBalance[19][propertyVar]),
        false
      );

    //Rotación de Cartera
    this.financialReasons[4][propertyVar] = this.macrosService.calcularInfo(
      this.generalBalance[2][propertyVar],
      this.statesPG[1][propertyVar]
    );

    this.financialReasons[4]["valY"] = this.macrosService.calcularValue(
      Number(this.generalBalance[2].valY),
      Number(this.statesPG[0].valY),
      Number(this.headers.monthValue)
    );

    //Rotación Inventarios
    this.financialReasons[5][propertyVar] = this.macrosService.calcularInfo(
      Number(this.generalBalance[3][propertyVar]),
      Number(this.statesPG[1][propertyVar])
    );

    this.financialReasons[5]["valY"] = this.macrosService.calcularValue(
      Number(this.generalBalance[3].valY),
      Number(this.statesPG[1].valY),
      Number(this.headers.monthValue)
    );

    //Rotación Proveedores
    this.financialReasons[6][propertyVar] = this.macrosService.calcularInfo(
      Number(this.generalBalance[15][propertyVar]),
      Number(this.statesPG[1][propertyVar])
    );

    this.financialReasons[6]["valY"] = this.macrosService.calcularValue(
      Number(this.generalBalance[15].valY),
      Number(this.statesPG[1].valY),
      Number(this.headers.monthValue)
    );

    //Ciclo Operacional
    this.financialReasons[7][propertyVar] =
      this.macrosService.calculateNetaValY(
        Number(this.financialReasons[4][propertyVar]),
        Number(this.financialReasons[5][propertyVar]),
        Number(this.financialReasons[6][propertyVar])
      );
    //Endeudamiento Total
    this.financialReasons[9][propertyVar] = this.macrosService.calcularPerc(
      this.generalBalance[24][propertyVar],
      this.generalBalance[13][propertyVar]
    );
    //Endeudamiento sin valorizacion
    this.financialReasons[10][propertyVar] = this.macrosService.calcularPerc(
      this.generalBalance[24][propertyVar],
      this.generalBalance[11][propertyVar]
    );
    //Endeudamiento corto plazo
    this.financialReasons[11].valY1 = this.macrosService.calculateReasons(
      this.generalBalance[19].valY1,
      this.generalBalance[18].valY1,
      this.generalBalance[13].valY1,
      this.percentage,
      1
    );

    this.financialReasons[11].valY = this.macrosService.calcularPerc(
      this.generalBalance[19].valY,
      this.generalBalance[24].valY
    );
  }

  calculateEveryField(property: string) {
    this.calculateActivateTotalsC(property);
    this.updateFinancialReasons(property);

    if (property === "valY1") {
      this.updateSPGPerc(property, "perc1");
      this.updategenBalPerc(property, "perc1");
    } else if (property === "valY2") {
      this.updateSPGPerc(property, "perc2");
      this.updategenBalPerc(property, "perc2");
    } else if (property === "valY") {
      this.updateSPGPerc(property, "perc");
      this.updategenBalPerc(property, "perc");
    }
    this.generalBalance[32]["percBetYears"] = "";
    this.generalBalance[32]["valBetYears"] = "";
    this.generalBalance[32]["perc1"] = "";
    this.generalBalance[32]["perc2"] = "";
    this.generalBalance[32]["perc"] = "";
    this.calculateActivateTotalsC(property);
    this.fillFields();
  }

  fillFields() {
    let genBal = {};
    let statePG = {};
    let finReason = {};

    this.generalBalance.forEach((element) => {
      let gbToPers = {};
      if (element.id != 33) {
        gbToPers[this.headers.year1] = {
          PERCENTAGE: element["perc1"],
          VALUE: Number(element["valY1"]),
        };
        gbToPers[this.headers.year2] = {
          PERCENTAGE: element["perc2"],
          VALUE: Number(element["valY2"]),
        };
        gbToPers[
          this.headers.year == "#N/A" ? "NO_APLICA" : this.headers.year
        ] = {
          PERCENTAGE: element["perc"],
          VALUE: Number(element["valY"]),
        };
        gbToPers["PERCENTAGEBETYEARS"] = element["percBetYears"];
        gbToPers["VALUESBETYEARS"] = element["valBetYears"];

        genBal[element["id"]] = gbToPers;
      } else {
        gbToPers[this.headers["year1"]] = {
          VALUE: Number(element["valY1"]),
        };
        gbToPers[this.headers.year2] = {
          VALUE: Number(element["valY2"]),
        };
        gbToPers[
          this.headers.year == "#N/A" ? "NO_APLICA" : this.headers.year
        ] = {
          VALUE: Number(element["valY"]),
        };

        genBal["TOTALES"] = gbToPers;
      }
    });

    this.statesPG.forEach((element) => {
      let gbToPers = {};

      if (element.id == 12) {
        element.perc1 = "";
        element.perc2 = "";
        element.perc = "";
        element.percBetYears = "";
        element.valBetYears = "";
      }

      gbToPers[this.headers.year1] = {
        PERCENTAGE: element["perc1"],
        VALUE: Number(element["valY1"]),
      };
      gbToPers[this.headers.year2] = {
        PERCENTAGE: element["perc2"],
        VALUE: Number(element["valY2"]),
      };
      gbToPers[this.headers.year == "#N/A" ? "NO_APLICA" : this.headers.year] =
        {
          PERCENTAGE: element["perc"],
          VALUE: Number(element["valY"]),
        };
      gbToPers[
        this.headersPG.proyect
          .replace(/ /g, "_")
          .replace("&", "")
          .replace(".", "")
          .toUpperCase()
      ] = element["proyect"];
      gbToPers["PERCENTAGEBETYEARS"] = element["percBetYears"];
      gbToPers["VALUESBETYEARS"] = element["valBetYears"];

      statePG[element["id"]] = gbToPers;
    });

    this.financialReasons.forEach((element) => {
      let gbToPers = {};
      gbToPers[this.headers.year1] = element["valY1"];
      gbToPers[this.headers.year2] = element["valY2"];
      gbToPers[this.headers.year == "#N/A" ? "NO_APLICA" : this.headers.year] =
        element["valY"];
      finReason[element["id"]] = gbToPers;
    });

    this.macrosService.headers = this.headers;
    this.macrosService.headersPGState = this.headersPG;
    this.macrosService.headersFR   = this.headersFR;
    this.macrosService.financialReasons = this.financialReasons;
    this.macrosService.generalBalance = this.generalBalance;
    this.macrosService.pgState = this.statesPG;

    let macros = {
      GENERAL_BALANCE: genBal,
      STATES_PG: statePG,
      FINANCIAL_REASONS: finReason,
    };



    this.infoStudyQuote.controls.macros.setValue(macros);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.infoStudyQuote.controls;
  }

  /**
   * Method that is responsible for filling the columns of the financial statements.
   * This adjusts for changes that occur when the financial information query is run
   * @author Rafael Ramirez
   */
  setSuperSocietiesValues() {
    this.infoStudyQuote
      .get("macrosInput")
      .valueChanges.subscribe((selectedValue) => {
        if (this.stateMacroType === "SUPERSOCIEDADES") {
          if (selectedValue.length > 0) {
            selectedValue.forEach((value: any, i: number) => {
              this.headers["year" + (i + 1)] = value["year"];
              this.headersPG["year" + (i + 1)] = value["year"];
              this.headersFR["year" + (i + 1)] = value["year"];
            });

            selectedValue.forEach((value) => {
              let property = "";
              if (this.headers["year1"] === value["year"]) {
                property = "valY1";
              }
              if (this.headers["year2"] === value["year"]) {
                property = "valY2";
              }
              if (property == "") return;

              const generalBalanceVars = [
                "available",
                "temporaryInvestments",
                "accountsReceivableClients",
                "otherCurrentAssets",
                "fixedAssets",
                "otherNonCurrentAssets",
                "bankObligationsLP",
                "otherLiabilitiesLP",
                "paidInCapital",
                "otherHeritage",
              ];
              const generalControls = [0, 1, 2, 5, 7, 10, 20, 22, 25, 26];

              generalBalanceVars.forEach((obj, i) => {
                this.generalBalance[generalControls[i]][property] =
                  value["generalBalance"][obj].toString();
                this.generalBalance[generalControls[i]]["superSocieties"] =
                  true;
              });

              const stagesPGVars = [
                "netsales",
                "salesCost",
                "operationalExpenses",
                "otherIncome",
                "otherExpenses",
                "taxes",
              ];
              const stagesPGControls = [0, 1, 3, 5, 6, 7];

              stagesPGVars.forEach((obj, i) => {
                this.statesPG[stagesPGControls[i]][property] =
                  value["statesPyG"][obj].toString();
                this.statesPG[stagesPGControls[i]]["superSocieties"] = true;
              });
              this.calculateEveryField(property);
            });
          }
          this.superSocieties = true;
        }
      });
  }

  /**
   * Method that fills the macro information when the financial information
   * query finds data from other previously filled forms
   * @author Rafael Ramirez
   */
  setOtherForms20MMacros() {
    this.infoStudyQuote
      .get("macros")
      .valueChanges.subscribe((macros: Macros) => {
        if (this.stateMacroType === "OTROS_FC20M") {
          let year1: number = 0;
          let year2: number = 0;
          let month: number = 0;
          let monthValue = "";
          if (macros.generalBalance && macros.generalBalance != undefined) {
            Object.keys(macros.generalBalance[1]).forEach((control) => {
              let numberControl: number = Number(control);
              if (!isNaN(numberControl)) {
                if (year1 == 0) year1 = numberControl;
                if (year2 == 0 && year1 != numberControl) year2 = numberControl;
                if (year1 != 0 && year2 != 0 && year2 != numberControl)
                  month = numberControl;
              }
              if (
                control != "PERCENTAGEBETYEARS" &&
                control != "VALUESBETYEARS"
              ) {
                monthValue = control;
              }
            });

            if (year2 > year1) {
              this.headers["year1"] = year1;
              this.headers["year2"] = year2;
              this.headers["monthValue"] = month;
              this.headers["yearCalculated"] = year2 + "/" + year1;

              this.headersPG["year1"] = year1;
              this.headersPG["year2"] = year2;
              this.headersPG["yearCalculated"] = year2 + "/" + year1;

              this.headersFR["year1"] = year1;
              this.headersFR["year2"] = year2;
              this.headersFR["year"] = month;
            } else {
              this.headers["year1"] = year2;
              this.headers["year2"] = year1;
              this.headers["monthValue"] = month;
              this.headers["yearCalculated"] = year1 + "/" + year2;

              this.headersPG["year1"] = year2;
              this.headersPG["year2"] = year1;
              this.headersPG["yearCalculated"] = year1 + "/" + year2;

              this.headersFR["year2"] = year1;
              this.headersFR["year1"] = year2;
              this.headersFR["year"] = month;
            }

            this.generalBalance.forEach((item) => {
              if (item.id == 33) {
                item["valY1"] =
                  macros.generalBalance["TOTALES"][year1]["VALUE"];
              } else {
                item["valY1"] = macros.generalBalance[item.id][year1]["VALUE"];
                item["perc1"] =
                  macros.generalBalance[item.id][year1]["PERCENTAGE"];
                item["valY2"] = macros.generalBalance[item.id][year2]["VALUE"];
                item["perc2"] =
                  macros.generalBalance[item.id][year2]["PERCENTAGE"];
                item["valY"] =
                  macros.generalBalance[item.id][monthValue]["VALUE"];
                item["perc"] =
                  macros.generalBalance[item.id][monthValue]["PERCENTAGE"];
                item["percBetYears"] =
                  macros.generalBalance[item.id]["PERCENTAGEBETYEARS"];
                item["valBetYears"] =
                  macros.generalBalance[item.id]["VALUESBETYEARS"];
              }
            });

            this.statesPG.forEach((item) => {
              item["valY1"] = macros.statesPG[item.id][year1]["VALUE"];
              item["perc1"] = macros.statesPG[item.id][year1]["PERCENTAGE"];

              item["valY2"] = macros.statesPG[item.id][year2]["VALUE"];
              item["perc2"] = macros.statesPG[item.id][year2]["PERCENTAGE"];
              item["valY"] = macros.statesPG[item.id][monthValue]["VALUE"];
              item["perc"] = macros.statesPG[item.id][monthValue]["PERCENTAGE"];
              item["percBetYears"] =
                macros.statesPG[item.id]["PERCENTAGEBETYEARS"];
              item["valBetYears"] = macros.statesPG[item.id]["VALUESBETYEARS"];
              item["proyect"] = macros.statesPG[item.id]["PG_PROYECT"];
            });

            this.financialReasons.forEach((item) => {
              item["valY1"] = macros.financialReasons[item.id][year1];
              item["valY2"] = macros.financialReasons[item.id][year2];
              item["valY"] = macros.financialReasons[item.id][monthValue];
            });
          }
        }
      });
  }

  setInformMacros() {
    this.infoStudyQuote
      .get("macrosInput")
      .valueChanges.subscribe((selectedValue) => {
        if (this.stateMacroType === "INFORMA") {
          if (selectedValue.length > 0) {
            selectedValue.forEach((value: any, i: number) => {
              this.headers["year" + (i + 1)] = value["year"];
              this.headersPG["year" + (i + 1)] = value["year"];
              this.headersFR["year" + (i + 1)] = value["year"];
            });

            selectedValue.forEach((value) => {
              let property = "";
              if (this.headers["year1"] === value["year"]) {
                property = "valY1";
              }
              if (this.headers["year2"] === value["year"]) {
                property = "valY2";
              }
              if (property == "") return;

              const generalBalanceVars = [
                "available",
                "investments",
                "accountsReceivableCustomers",
                "otherCurrentAssets",
                "accountsReceivablePartners",
                "inventories",
                "fixedAssets",
                "otherNonCurrentAssets",
                "otherLiabilities",
                "financialObligations",
                "paidInCapital",
                "othersOfHeritage",
                "agetAcumulatedUtilities",
              ];
              const generalControls = [
                0, 1, 2, 5, 4, 3, 7, 10, 17, 14, 25, 26, 28,
              ];

              generalBalanceVars.forEach((obj, i) => {
                this.generalBalance[generalControls[i]][property] =
                  value["generalBalance"][obj].toString();
                this.generalBalance[generalControls[i]]["superSocieties"] =
                  true;
              });

              const stagesPGVars = [
                "otherIncome",
                "operationalExpenses",
                "costsOfSale",
                "taxes",
                "otherLoss",
              ];
              const stagesPGControls = [5, 3, 1, 7, 6];

              stagesPGVars.forEach((obj, i) => {
                this.statesPG[stagesPGControls[i]][property] =
                  value["statesPyG"][obj].toString();
                console.log(value["statesPyG"]);

                console.log(this.statesPG[stagesPGControls[i]]);

                /* console.log(this.statesPG[stagesPGControls[i]]);
                  
                  console.log(value["statesPyG"][obj]); */

                this.statesPG[stagesPGControls[i]]["superSocieties"] = true;
              });
              console.log(this.statesPG);

              this.calculateEveryField(property);
            });
          }
        }
      });
  }
}
