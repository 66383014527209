import { Component, OnInit } from '@angular/core';
import { BackupsStoreService } from '@app/pages/backups/services/backups-store.service';
import { HelperService } from '@app/services/helper.service';
import { NetFactoringService } from '../services/net-factoring.service';

@Component({
  selector: 'app-net-factoring-router',
  templateUrl: './net-factoring-router.component.html',
  styleUrls: ['./net-factoring-router.component.scss']
})
export class NetFactoringRouterComponent implements OnInit {

  public navItemTabs: any[] = [];

  constructor(
    public helper: HelperService,
    public backupsStore: BackupsStoreService,
    public netFactoringService: NetFactoringService
  ) { 
    this.navItemTabs = this.netFactoringService.ServiceTabs;
  }

  ngOnInit() {
  }

  setTab(tab: any) {
    this.backupsStore.setActiveTab(tab.key);
  }

}
