import { ChangePasswordRequest } from '@models/changePassword.model';
import { HelperService } from '@services/helper.service';
import { UserPhotoRequest, UserPhotoResponse } from '@models/userPhoto.model';
import { Injectable } from "@angular/core";
import { UserModel } from "@models/user.model";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { SellersRequest } from "@models/request/sellersRequest.model";
import { SellersResponse } from "@models/response/sellersResponse.model";
import { BirthdayRequest } from '@models/birthday.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;
  public transactionsNumber: string;

  constructor(private http: HttpClient,
              private helper: HelperService,
              private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi} = apis;
      this.urlApi = urlApi;
       console.log('config ci cd', apiUrl);
    });
  }

  readToken() {
    if (localStorage.getItem("tokenASR")) {
      this.userToken = localStorage.getItem("tokenASR");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getIdentity() {
    this.transactionsNumber = localStorage.getItem('transactionsNumber');
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getSellers(sellerRequest: SellersRequest) {
    const { userInfo, username } = this.identity;
    sellerRequest.documentNumber = userInfo.document;
    sellerRequest.documentType = userInfo.documentType;
    sellerRequest.userDocumentNumber = "0";
    sellerRequest.userDocumentType = userInfo.userDocumentType;
    sellerRequest.sellerUser = username;
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      params: SellersRequest.toRequest(sellerRequest),
      headers
    };

    return this.http.get(`${this.urlApi}/pr_vendedores`, options).pipe(
      map((resp: any) => {
        let seller = resp.VENDEDORES[0];
        seller.ROL = userInfo.useRoler;
        seller.EMAIL = userInfo.email;
        return SellersResponse.fromResponse(seller);
      })
    );
  }

  userBirthday(birthdayRequest: BirthdayRequest) {
    const { userInfo, username } = this.identity;
    birthdayRequest.documentNumber = userInfo.document;
    birthdayRequest.documentType = userInfo.documentType;
    birthdayRequest.user = username;
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      params: BirthdayRequest.toRequest(birthdayRequest),
      headers
    };

    return this.http.post(`${this.urlApi}/pr_usuario_cumpleanos`, null, options).pipe(
      map((resp: any) => {
         return resp['CUMPLEANOS']
      })
    );
  }

  uploadUserPhoto(userPhotoRequest: UserPhotoRequest) {
    const { userInfo, username } = this.identity;
    userPhotoRequest.documentNumber = userInfo.document;
    userPhotoRequest.documentType = userInfo.documentType;
    userPhotoRequest.userDocumentType = userInfo.userDocumentType;
    userPhotoRequest.userDocumentNumber = userInfo.userDocument;
    const photo = userPhotoRequest.photo;
    const headers = new HttpHeaders().set("token", this.userToken);
    headers.set('Content-Type', 'multipart/form-data');
    console.log('img sevice: ', photo);


    const body = new FormData();
    body.append('file', photo, photo.name);

    const options = {
      params: UserPhotoRequest.toRequest(userPhotoRequest),
      headers
    };

    return this.http.post(`${this.urlApi}/pr_subir_archivos`, body
    , options).pipe(
      map((resp: Array<UserPhotoResponse>) => {
         const files = resp['archivos'][0];
         return files.url;
      })
    );
  }

 changePassword(changePassRequest: ChangePasswordRequest) {
    const { userInfo, username } = this.identity;
    changePassRequest.documentNumber = userInfo.document;
    changePassRequest.documentType = userInfo.documentType;
    changePassRequest.user = username;
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      params: ChangePasswordRequest.toRequest(changePassRequest),
      headers
    };

    return this.http.put(`${this.urlApi}/pr_cambio_contrasena`, null, options).pipe(
      map((resp: any) => {
         return resp;
      })
    );
  }
}
