import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'municipalityName'
})
export class MunicipalityNamePipe implements PipeTransform {

  transform(value: string, municipalities?: any): string {
    let cityFilter = municipalities.filter((item) => item.ID === value.toString());
    return (cityFilter[0]) ? cityFilter[0].LABEL : '--';    
  }
}
