import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'termName'
})
export class TermNamePipe implements PipeTransform {

  transform(value: any): any {
    let termName: string;
    
    let termList: any = JSON.parse(localStorage.getItem('form20m_termsList'));
    if(termList) {
      termList.forEach(item => {      
        if(value === item.idTerm) {
          termName = item.description;
        }
      })
      return termName;
    }
  }
}
