export class ContractEmailAsrTemp {
  id: number;
  mainEmail: string;
  ownerMainEmail: string;
  secondaryEmail: string;
  ownerEmailFE: string;
  ownerSecondEmail: string;
  emailFE: string;
  idContract: number;
}
