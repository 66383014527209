import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { EntryTransactionsService } from './services/entry-transactions.service';

@Component({
  selector: 'app-entry-transactions',
  templateUrl: './entry-transactions.component.html',
  styleUrls: ['./entry-transactions.component.scss']
})
export class EntryTransactionsComponent implements OnInit {

  public entryTransactionsTabs: Array<any>;

  constructor(private entryTransactions: EntryTransactionsService, public helper: HelperService) { }

  ngOnInit() {
    this.entryTransactionsTabs = this.entryTransactions.entryTransactionsTabs;
  }

}
