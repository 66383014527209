import { Injectable } from "@angular/core";
import { Resource } from "@app/models/Resource.model";

@Injectable({
  providedIn: "root",
})
export class QuotaHeadingTablesService {

  public contactDataHeaders: any[] = [
    {
      href: '#contactPhone',
      description: 'Teléfonos',
      icon: 'icon-tab-individual.svg',
      key: 'contactPhone'
    },
    {
      href: '#contactAddress',
      description: 'Direcciones',
      icon: 'icon-tab-individual.svg',
      key: 'contactAddress'
    },
    {
      href: '#contactEmails',
      description: 'Correos',
      icon: 'icon-tab-individual.svg',
      key: 'contactEmails'
    },
    {
      href: '#contactRelatedDocuments',
      description: 'Documentos Relacionados',
      icon: 'icon-tab-individual.svg',
      key: 'contactRelatedDocuments'
    },
  ];

  public currentExhibition: Array<string> = [
    "Afiliados",
    "Producto",
    "Estado",
    "Asignación",
    "Cupo",
    "Garantía total",
    "Garantía total vigente",
    "Fecha garantía inicial",
    "Fecha garantía última",
    "Garantía vigencia",
    "Sugerido y movimiento",
    "Valor movimiento",
    "Cupo",
  ];

  public financialInformation: Array<string> = [
    "Balance general",
    "2020",
    "%V",
    "2021",
    "%V",
    "#N/A",
    "%V",
    "% 2021/2020",
    "$ 2021/2020",
  ];

  public holderValidation: Array<string> = [
    "Tipo Documento",
    "Documento",
    "Nombre",
    "Relación",
    "Afiliado",
    "Pagaré",
    "Sagralaft/Ley 1116",
    "Cobra",
    "Clasificación",
    "Estado Central",
    "Baranda SS",
    "Moras Estado",
  ];

  public generalBalanceTitles = [
    "Disponible",
    "Inversiones Temporales",
    "Cuentas por Cobrar-Clientes",
    "Cuentas por Cobrar Socios ",
    "Cuentas por Cobrar Socios",
    "Otros Activos Corrientes",
    "Total Activo Corriente",
    "Activos Fijos",
    "(Depreciación Acumulada)",
    "Activo Neto ",
    "Otros Activos No Corrientes",
    "TOTAL ACTIVOS (SIN VALORIZACIONES)",
    "Valorizaciones",
    "TOTAL ACTIVOS",
    "Obligaciones Bancarias (C.P.)",
    "Cuentas Proveedores",
    "Deudas Con Socios",
    "Otros Pasivos (C.P)",
    "Anticipos Recibidos",
    "Total Pasivo Corriente",
    "Obligaciones Bancarias (L.P.)",
    "Deudas con Socios",
    "Otros Pasivo (L.P.)",
    "Anticipos Recibidos",
    "TOTAL PASIVO",
    "Capital Pagado",
    "Otras de Patrimonio",
    "Valorizaciones (Desvalorizaciones)",
    "Utilidades (Perdidas) Acumuladas",
    "Utilidades (Perdidas) del Periodo",
    "TOTAL PATRIMONIO",
    "TOTAL PASIVO+PATRIMONIO",
  ];

  public stateTitles = [
    "VENTAS NETAS",
    "Costo de ventas",
    "UTILIDAD (PERDIDA) BRUTA",
    "Gastos operacionales",
    "UTILIDAD (PERDIDA) OPERACIONAL",
    "Otros ingresos",
    "Otros egresos",
    "Impuestos",
    "UTILIDAD (PERDIDA) NETA",
    "Depreciaciones y amortizaciones",
    "EBITDA",
    "Capacidad inicial por ventas",
  ];

  public financialReasonsTitles = [
    "Indicadores de liquidez y actividad",
    "Razón corriente",
    "Prueba ácida",
    "Capital de trabajo",
    "Rotación de cartera",
    "Rotación de inventarios",
    "Rotación Proveedores",
    "Ciclo operacional",
    "Indicadores de Endeudamiento",
    "Endeudamiento total",
    "Endeudamiento sin valorizaciones",
    "Endeudamiento corto plazo",
  ];

  public resourceTitles = [
    "No Radicación",
    "Tipo de Documento",
    "Documento",
    "Nombre Archivo",
    "Fecha de Carga",
    "Descargar"
  ];

  public resource:Resource = {
    "id": null,
    "source": null,
    "user": null,
    "documentType": null,
    "document": null,
    "originalName": null,
    "creationDate": null,
    "category": "Solicitud Cupo",
    "infoFile": null,
    "originalPath": null,
    "originalFinalName": null,
    "resourceId": null
  }

  constructor() {}
}
