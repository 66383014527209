export class TransactionsToBackUpRequestModel {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    contract:                number = 0;
    issuerDocumentType:    string = '0';
    issuerDocument:          string = '0';
    draftNumber:           string = '0';
    currentPage?:             number = 1;
    perPage?:                number = 10;
    initialDate?:            string = '';
    finalDate?:              string = '';
    authorization:            number = 0;
    sinisterNumber?:        string = '0';
}
