import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-risks-processes-charges',
  templateUrl: './risks-processes-charges.component.html',
  styleUrls: ['./risks-processes-charges.component.scss']
})
export class RisksProcessesChargesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
