import { IncomeRecoveryService } from './../../../services/income-recovery.service';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { HelperService } from '@app/services/helper.service';
import { NgForm } from '@angular/forms';
import { SelectModel } from '@app/models/select.model';
import { AppState } from '@app/redux/app.reducer';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-income-recovery',
  templateUrl: './income-recovery.component.html',
  styleUrls: ['./income-recovery.component.scss']
})
export class IncomeRecoveryComponent implements OnInit {
  public dateRange: Array<any> = [];

  public selectOptionsBranchOffices: SelectModel[] = [];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public incomeRecoveryFormData = {
    branch: '',
    filingNumber: '',
    incomeType: ''
  };

  public dataIncomes: Array<any> = [];
  public dataCancellations: Array<any> = [];
  public dataForDates: Array<any> = [];
  public dataFilingNumber: Array<any> = [];

  public incomesPageData: any = {};
  public cancellationsPageData: any = {};
  public forDatesPageData: any = {};
  public filingNumberPageData: any = {};

  public isSearched: boolean = false;

  public incomeTypeList: Array<any> = [
    {
      value: 'incomes',
      description: 'Ingresos'
    },
    {
      value: 'cancelations',
      description: 'Cancelaciones'
    },
    {
      value: 'forDates',
      description: 'Para fechas'
    },
    {
      value: 'filingNumber',
      description: 'Número de registro'
    },
  ];

  @ViewChild('incomeRecoveryForm') incomeRecoveryForm!: NgForm;

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private incomeRecovery: IncomeRecoveryService,
    private store: Store<AppState>
  ) {
    this.store.select('selects')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ selects }) => this.getSelects(selects)
      );
  }

  ngOnInit() { }

  onGetDateRange($event) {
    this.dateRange = $event;
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      paramIni: moment(this.dateRange[0]).format("YYYY-MM-DD 00:00:00"),
      paramFin: moment(this.dateRange[1]).format("YYYY-MM-DD 23:59:59"),
    }
    return params;
  }

  getSelects(selects: any) {
    this.selectOptionsBranchOffices = selects.branchOffices || [new SelectModel()];
  }

  submitSearch(currentPage?, isDownload = false) {
    const dateParams = this.getDownloadQuota();
    const body = {
      initialDate: dateParams.paramIni,
      finalDate: dateParams.paramFin,
      branch: this.incomeRecoveryFormData.branch,
      perPage: 10,
      currentPage: currentPage || 1,
      download: isDownload
    }
    if (this.incomeRecoveryFormData.incomeType === 'incomes') {
      this.incomeRecovery.getIncome(body).subscribe((response: any) => {
        this.isSearched = true;
        if (isDownload === true) {
          this.downloadBase64(response.file)
        } else {
          this.dataIncomes = response.data
          this.incomesPageData = {
            currentPage: response.currentPage,
            totalPages: response.totalPages
          }
        }

        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.resetTables()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });

    } else if (this.incomeRecoveryFormData.incomeType === 'cancelations') {
      this.incomeRecovery.getCancellations(body).subscribe((response: any) => {
        this.isSearched = true;
        if (isDownload === true) {
          this.downloadBase64(response.file)
        } else {
          this.dataCancellations = response.data
          this.cancellationsPageData = {
            currentPage: response.currentPage,
            totalPages: response.totalPages
          }
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.resetTables()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });

    } else if (this.incomeRecoveryFormData.incomeType === 'forDates') {
      this.incomeRecovery.getForDates(body).subscribe((response: any) => {
        this.isSearched = true;
        if (isDownload === true) {
          this.downloadBase64(response.file)
        } else {
          this.dataForDates = response.data
          this.forDatesPageData = {
            currentPage: response.currentPage,
            totalPages: response.totalPages
          }
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.resetTables()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });

    } else if (this.incomeRecoveryFormData.incomeType === 'filingNumber') {
      const bodyFilingNumber = {
        filingNumber: this.incomeRecoveryFormData.filingNumber,
        perPage: 10,
        currentPage: 1,
        download: isDownload
      }
      this.incomeRecovery.getFilingNumber(bodyFilingNumber).subscribe((response: any) => {
        this.isSearched = true;
        if (isDownload === true) {
          this.downloadBase64(response.file)
        } else {
          this.dataFilingNumber = response.data;
          this.filingNumberPageData = {
            currentPage: response.currentPage,
            totalPages: response.totalPages
          }
        }
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.resetTables()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });
    }
  }

  changeIncomeType() {
    this.isSearched = false;
  }

  resetTables() {
    this.dataIncomes = [];
    this.dataCancellations = [];
    this.dataForDates = [];
    this.dataFilingNumber = [];
  }

  handleDownloadData() {
    if (this.incomeRecoveryFormData.incomeType === 'incomes') {
      this.submitSearch(this.incomesPageData.currentPage, true);
    } else if (this.incomeRecoveryFormData.incomeType === 'cancelations') {
      this.submitSearch(this.cancellationsPageData.currentPage, true);
    } else if (this.incomeRecoveryFormData.incomeType === 'forDates') {
      this.submitSearch(this.forDatesPageData.currentPage, true);
    } else if (this.incomeRecoveryFormData.incomeType === 'filingNumber') {
      this.submitSearch(this.filingNumberPageData.currentPage, true);
    }
  }

  downloadBase64(data) {
    this.helper.downloadFromBase64(data, 'ingreso-recuperacion', '.xlsx');
  }

  goToPageIncomes($event) {
    this.incomesPageData.currentPage = $event;
    this.submitSearch(this.incomesPageData.currentPage);
  }

  goToPageCancellations($event) {
    this.cancellationsPageData.currentPage = $event;
    this.submitSearch(this.cancellationsPageData.currentPage);
  }

  goToPageForDates($event) {
    this.forDatesPageData.currentPage = $event;
    this.submitSearch(this.forDatesPageData.currentPage);
  }

  goToPageFilingNumber($event) {
    this.filingNumberPageData.currentPage = $event;
    this.submitSearch(this.filingNumberPageData.currentPage);
  }
}
