import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RequestCredit } from "@app/models/RequestCredit.model";
import { HelperService } from "@app/services/helper.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";
import { NgxSpinnerService } from "ngx-spinner";
import { RadicationService } from "../services/radication-quota.service";

@Component({
  selector: "app-filings",
  templateUrl: "./filings.component.html",
  styleUrls: ["./filings.component.scss"],
})
export class FilingsComponent implements OnInit {
  radicationList: RequestCredit[] = [];

  filterParams = {
    typeRadication: "ESTUDIO",
    typeStudy: "INFORME COMERCIAL",
    numberRadication: null,
  };

  cantPages = 0;
  currentPage = 1;

  constructor(
    public radicationService: RadicationService,
    private spinner: NgxSpinnerService,
    public radicationRequestService: QuotaRadicationService,
    private router: Router,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.getAll();
    localStorage.removeItem("radication");
  }

  selectTypeRadication($event, key: string) {
    this.currentPage = 1;
    if (key == "typeRadication") {
      this.filterParams.typeRadication = $event;
    }

    if(key == 'pay') {
      this.filterParams.typeStudy = $event;
    }

    this.getAll();
  }

  searchRadication() {
    //this.filterParams.typeRadication = "";
    this.cantPages = 0;
    this.currentPage = 1;
    this.filterParams.typeStudy = "";
    this.getAll();
  }

  getAll() {
    this.spinner.show();
    this.radicationRequestService
      .getFiligs(
        this.filterParams.typeRadication,
        this.filterParams.numberRadication,
        this.filterParams.typeStudy,
        this.currentPage,
      )
      .subscribe(
        (resp) => {
          this.radicationList = resp.data;
          this.cantPages = resp.totalPages;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
          this.radicationList = [];
        }
      );
  }

  goToPage($event){
    this.currentPage = $event.currentPage;
    this.getAll();
  }

  gotoRadication(radication?: string) {
    if (radication) {
      localStorage.setItem("radication", radication);
    }
    this.router.navigate(["pages/gestion-solicitud/radicado"]);
  }


  clear($event){
    if(!$event){
      this.cantPages = 0;
      this.currentPage = 1;
      this.filterParams.typeStudy = "INFORME COMERCIAL";
      this.getAll();
    }
  }
}
