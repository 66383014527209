import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ReferenceMunicipalities } from '../models/referenceMunicipalities.model'
import { NgxSpinnerService } from 'ngx-spinner';
import { TelephoneType } from '../models/telephoneType.model';
import { TelephoneTypeResponse } from '../models/response/telephoneTypeResponse.model';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { Response } from '@app/models/responseUtil.model';

@Component({
  selector: 'app-location-result-contact',
  templateUrl: './location-result-contact.component.html',
  styleUrls: ['./location-result-contact.component.scss']
})
export class LocationResultContactComponent implements OnInit {

  @Input() infoStudyQuote: FormGroup;
  @Input() departments: Array<{}>;
  @Input() referenceMunicipalities: Array<ReferenceMunicipalities>;

  public cities: Array<{}> = [];
  public telephoneTypes = [];

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private quotaGreaterService: QuotaGreaterService,
    public helper: HelperService) {
  }

  ngOnInit() {
    this.quotaGreaterService.getTelephoneType().subscribe(
      (resp: any) => {
        if (resp.ok) {
          alert("Ocurrio un error")
        } 
        this.telephoneTypes = JSON.parse(localStorage.getItem("form20m_telephoneList"));
        console.log("PRUEBAS",this.telephoneTypes);
      })

    let data = this.infoStudyQuote.controls.departmentLRC.value;
    let city = this.infoStudyQuote.controls.cityLRC.value;
    if(data && city){
      this.updateCities(data);
      this.infoStudyQuote.controls.cityLRC.setValue(city);
    }
  }

  getTelephoneTypes() {
  }

  updateCities($event) {
    this.cities = [];
    let city = {};
    this.referenceMunicipalities.forEach(element => {
      if (Number(element.department) == $event) {
        city = {
          id: Number(element.city),
          name: element.nameCity
        }
        this.cities.push(city);
      }
    });
    this.infoStudyQuote.controls.cityLRC.setValue('');
  }
  // convenience getter for easy access to form fields
  get f() { return this.infoStudyQuote.controls; }

}
