import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-backups',
  templateUrl: './backups.component.html',
  styleUrls: ['./backups.component.scss']
})
export class BackupsComponent implements OnInit {

  public tableHeading = [
    "Tipo Doc",
    "Documento",
    'Razón social',
    'Acción',
  ];

  public navItemTabs = [
    {
      href: '#individual',
      description: 'Radicación',
      icon: 'icon-tab-individual.svg',
    },
    {
      href: '#massive',
      description: 'Títulos valor',
      icon: 'icon-tab-massive.svg',
    },
    {
      href: '#reserves',
      description: 'Reintegros',
      icon: 'icon-tab-reserves.svg',
    },
    {
      href: '#historical',
      description: 'Hístoricos',
      icon: 'icon-tab-reserves.svg',
    },
  ];

  constructor(public modal: ModalService, public helper: HelperService) {
    // this.modal.show('succesContent', { });
   }

  ngOnInit() {
  }

}
