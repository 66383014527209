import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChargedAndNonChargedInvoice } from '../../models/response/netsuite-invoices-response.model';
import { NetsuiteInvoicesService } from '../../services/netsuite-invoices.service';

@Component({
  selector: 'app-non-chargued-netsuite-invoices',
  templateUrl: './non-chargued-netsuite-invoices.component.html',
  styleUrls: ['./non-chargued-netsuite-invoices.component.scss']
})
export class NonCharguedNetsuiteInvoicesComponent implements OnInit {

  @Input() invoiceSelected:  any = null;
  @Input() nonChargedInvoices: Array<ChargedAndNonChargedInvoice> = [];
  @Input() nonChargedInvoicesTableHeaders: Array<ChargedAndNonChargedInvoice> = [];  
  @Output() outputNonCharguedInvoice: EventEmitter<any> = new EventEmitter<any>();

  constructor(private netsuiteInvoiceService: NetsuiteInvoicesService) { }

  ngOnInit() {
    this.netsuiteInvoiceService.invoiceSelected$.subscribe((invoice: any) => {
      this.nonChargedInvoices = [];
      if(invoice === null) {
        this.invoiceSelected = null;
      }
    });
  }

  selectInvoice(invoice: any) {
    this.outputNonCharguedInvoice.emit(invoice.factura);
  }
}
