import { Component, Input, OnChanges, OnInit } from '@angular/core';
// import { QuotaDataForm } from 'src/app/models/quote-form-model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-financial-reasons',
  templateUrl: './financial-reasons.component.html',
  styleUrls: ['./financial-reasons.component.scss']
})
export class FinancialReasonsComponent implements OnInit, OnChanges {
  
  @Input() quotaData: any
  @Input() financialReasonsList: Array<any>;
  public firstYear: string;
  public secondYear: string;
  public month: string;

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {
    console.log(this.financialReasonsList);
    
    if(this.financialReasonsList.length > 0) {
      this.getYears()
    }
  }

  getYears() {
    let yearsList: Array<any> = [];
    
    yearsList = Object.keys(this.financialReasonsList[0]);
    this.firstYear = yearsList[0];
    this.secondYear = yearsList[1];
    this.month = yearsList[2];
  }
}
