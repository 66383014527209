export class ExpressReport{
    ok?:          boolean;
    message?:     string;
    data?:        ExpressReportModel[];
    currentPage?: number;
    totalPages?:  number;
}

export class ExpressReportModel {
    uploadRadication?:  number;
    product?:           Product;
    affiliateTypeDoc?:  string='';
    affiliateDocument?: number;
    affiliateName?:     string;
    approvedRecord?:    number;
    deniedRecord?:      number;
    pendingRecord?:     number;
    totalRecord?:       number;
    contract?:          number;
    uploadDate?:        null | string;
    finalizationDate?:  null | string;
}

export enum Product {
    Global = "GLOBAL",
    Sep = "SEP",
    Sepglobal = "SEPGLOBAL",
}

export class filterUploadExpressReport {
    ok?:          boolean;
    message?:     string;
    data?:        FilterUploadExpressReportModel[];
    currentPage?: number;
    totalPages?:  number;
}

export class FilterUploadExpressReportModel {
    status?: string ='';
    check?: boolean=false;
    value?:                     number;
    totalValue?:                number | null;
    typeDocBuyer?:              string =''; 
    documentBuyer?:             number;
    uploadState?:               string ='';
    availableQuota?:            number;
    buyerState?:                string ='';
    currentBalance?:            null;
    daysPastDue?:               number | null;
    obligationOtherPortfolios?: number;
    typeDocAffiliate?:          string ='';
    documentAffiliate?:         number;
    affiliateName?:             string ='';
    uploadRadication?:          number;
    record?:                    string ='';
    numberChq?:                 string ='';
    contract?:                  number;
    product?:                   string ='';
    affiliateQuota?:            number;
    uploadDate?:                string ='';
    authorization?:             null;
    issueDate?:                 string ='';
    radicationDate?:            null;
    debtorsReportDate?:         string ='';
    lockDate?:                  null;
    spinnerType?:               string ='';
    documentState?:             null;
    clintonList?:               number;
    numberCvs?:                 number | null;
    suspensionDate?:            null;
    currentNotice?:             null;
    limitToFile?:               null;
    uploadObservation?:         string ='';
    buyerName?:                 string ='';
    guaranteedValue?:           number;
    finalizationDate?:          null | string;
}


export class ApproveUploadModel {
    ok?:          boolean;
    message?:     string;
    data?:        ApproveUpload[];
    currentPage?: null;
    totalPages?:  null;
}
export class ApproveUpload {
    documentType?:     string;
    document?:         number;
    uploadRadication?: number;
    record?:           string;
    nonPaymentNotice?: number;
    numberCvs?:        number;
    numberChq?:        string;
    balance?:          number;
    totalValue?:       number;
    spinnerType?:      string;
    documentState?:    number;
    clintonList?:      number;
    otherObligation?:  number;
    contract?:         number;
    user?:             string;
    uploadDate?:       string;
}

export class ResAffilate {
    ok:          boolean;
    message:     string;
    data:        DataResAffilate[];
    currentPage: null;
    totalPages:  null;
}

export class DataResAffilate {
    excessRisk: ExcessRisk ;
    documentState: string;
    contractState: string;
    contractProduct: string;
}

export class ExcessRisk {
    excessRiskPK: ExcessRiskPK;
    contract: 300000;
    state: string;
    percentage: number;
    uploadUser: string
    updateDate: string;
    updateUser:string;
}

export class  ExcessRiskPK {
    documentType: string;
    document: number;
    uploadDate: string;
}

export class DataSearchRisk {
    documentType: string;
    document: number;
    currentPage: number;
    totalPerPage = 10;
}

export class OptionsPercentage {
    static numbersOptions = [
        { 
           id: 10,
            
        },
        {
            id:20
        },
        {
            id:30
        },
        {
            id:40
        },
        {
            id:50
        },
        {
            id:60
        },
        {
            id:70
        },
        {
            id:80
        },
        {
            id:90
        },
        {
            id:100
        }, 
        {
            id:110
        },
        {
            id:120
        },
        {
            id:130
        },
        {
            id:140
        },
        {
            id:150
        },
        {
            id:160
        },
        {
            id:170
        },
        {
            id:180
        },
        {
            id:190
        },
        {
            id:200
        },
    ]
}







