import { Component, Input, OnInit } from '@angular/core';
import { PaymentHabitOfActiveObligations } from '../../services/legal-person-adapter.service';
import { ExpirianListsService } from '../../services/expirian-lists.service';

@Component({
  selector: 'app-legal-payment-habit-of-active-obligations-table',
  templateUrl: './legal-payment-habit-of-active-obligations-table.component.html',
  styleUrls: ['./legal-payment-habit-of-active-obligations-table.component.scss']
})
export class LegalPaymentHabitOfActiveObligationsTableComponent implements OnInit {

  public headers: string[] = [
    "Estado obligación",
    "Tipo Cuenta",
    "Entidad informante",
    "Fecha act",
    "Número obligación",
    "Oficina",
    "Calidad",
    "Apertura",
    "Vencimiento",
    "Perm",
    "Cal",
    "Mora máx",
    "Desacuerdo con la información",
    "Comportamiento 47 meses"
  ];

  @Input() paymentHabitOfActiveObligations: PaymentHabitOfActiveObligations[] = [];

  constructor(
    private experianListService: ExpirianListsService
  ) { }

  ngOnInit() {
  }

  getSectorDescription(code: string): string {
    const sector = this.experianListService.findSectorByCode(code);
    return sector ? sector.descripcion : code;
  }

}
