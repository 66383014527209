import { Component, OnInit } from '@angular/core';
import { HabitOfPaymentActive, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-habit-of-payment-active-history',
  templateUrl: './habit-of-payment-active-history.component.html',
  styleUrls: ['./habit-of-payment-active-history.component.scss']
})
export class HabitOfPaymentActiveHistoryComponent implements OnInit {

  public habitsOfPaymentActive: HabitOfPaymentActive[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.habitsOfPaymentActive = this.subjectCreditHistory.getHabitOfPaymentActive(response);
  }

}
