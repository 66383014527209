
export class BuyerAffiliateRequest {
    identification: Number;
    identificationType: string;

    public static toRequest(
        buyerAffiliateRequest: BuyerAffiliateRequest
    ): any {
    return {
        identification: buyerAffiliateRequest.identification ? buyerAffiliateRequest.identification : 0,
        identificationType: buyerAffiliateRequest.identificationType ? buyerAffiliateRequest.identificationType : ''
    };
    }
}
