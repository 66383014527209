import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-promisory-notes.component',
  templateUrl: './promisory-notes.component.html',
  styleUrls: ['./promisory-notes.component.scss']
})
export class PromisoryNotes implements OnInit {

  @ViewChild('transactionReportsForm') transactionReportsForm: NgForm;

  public heading = [
    'Fecha de aprobación',
    'Título valor',
    'Documento comprador',
    'Nombre comprador',
    'Valor',
    'Documento afiliado',
    'Nombre afiliado',
    'Tipo de producto'
  ];

  public docTypes: any;

  public report = [
    {
      description: 'CUPOS',
      value: 'CUPOS'
    },
    {
      description: 'CUPOS DEL COMPRADOR',
      value: 'CUPOSC'
    },
    {
      description: 'RESERVAS',
      value: 'RESERVAS'
    },
    {
      description: 'TRANSACCIONES',
      value: 'TRANSACCIONES'
    },
    {
      description: 'BLOQUEOS',
      value: 'BLOQUEOS'
    },
    {
      description: 'HABEAS',
      value: 'HABEAS'
    },
    {
      description: 'CUBRIMIENTO',
      value: 'CUBRIMIENTO'
    },
  ]

  public type = [
    {
      description: 'HISTORICO',
      value: 'HISTORICO'
    },
    {
      description: 'RANGO',
      value: 'RANGO'
    },
    {
      description: 'DOCUMENTO',
      value: 'DOCUMENTO'
    },
  ]

  public bodyForm: any = {
    documentType: '',
    document: '',
    report: '',
    type: '',
  };

  public dateRange: Array<any> = [];

  public list: Array<any> = [];

  public file: string = '';

  public totalPages = 1;

  public currentPage = 1;

  public isRange: boolean = false;

  constructor(private spinner: NgxSpinnerService, private quotaGreater: QuotaGreaterService, private toastr: ToastrService, public helper: HelperService,
    private listCovinoc: ListsCovinocService) { }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
    });
  }

  public onGetDateRange($event) {
    this.dateRange = $event;
  }

  onSelectReportType() {
    console.log(this.bodyForm.report !== "CUPOS") ;
    console.log(this.bodyForm.report);
    
    console.log(this.isRange);
    
    this.isRange = ((this.bodyForm.report !== "CUPOS" && this.bodyForm.report !== "CUPOSC") || this.bodyForm.report === 'BLOQUEOS') ? true : false;
  }

  onSelectQueryType() {
    console.log(this.bodyForm.type);
    
    this.isRange = (this.bodyForm.type === "RANGO" || this.bodyForm.type === "HISTORICO") ? true : false;  
  }

  public getDownloadQuota() {
    this.spinner.show();
    let params = {
      from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      documentType: (this.isRange === false) ? this.bodyForm.documentType : null,
      document: (this.isRange === false) ? this.bodyForm.document : null,
      report: this.bodyForm.report,
      type: this.bodyForm.type,
    }
    this.quotaGreater.threeAndSixPayments(params).subscribe((response: any) => {
      this.spinner.hide();
      this.list = response.data
      this.totalPages = response.totalPages;
      if(response.ok === false) {
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }

  public downloadQuota() {
    this.spinner.show();
    let params = {
      from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      documentType: this.bodyForm.documentType,
      document: this.bodyForm.document,
      report: this.bodyForm.report,
      type: this.bodyForm.type,
    }
    
    this.quotaGreater.generateReport(params).subscribe((response: any) => {
      this.spinner.hide();
      this.file = response.data[0]
      if (this.file) {
        this.helper.downloadFromBase64(this.file, 'reporte', '.xlsx');
        Swal.fire({
          title: `Descarga exitosa`,
          text: ``,
          type: 'success',
        });
      }
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }
}
