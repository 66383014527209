import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { RulesCreatedService } from '@app/pages/affiliates/rules-created/rules-created.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-rules-create',
  templateUrl: './modal-rules-create.component.html',
  styleUrls: ['./modal-rules-create.component.scss'],
})
export class ModalRulesCreateComponent implements OnInit {
  public rulesCreateForm: FormGroup;
  public title = 'Editar bolsa';
  public registerReceived = null;
  public identity: any;
  public user: string = '';
  public maxQuota: number;
  public periodicity: number;
  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    public helper: HelperService,
    public backupsHeaders: BackupsTableHeadersService,
    public getRegister: RulesCreatedService,
    public sendModifyRegister: RulesCreatedService,
    public modifyBag: GloballBillingService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getRegister.sendDataEvent.subscribe((event) => {
      this.registerReceived = event;
      console.log('Registro recibido', this.registerReceived);
      this.setData();
      this.maxQuotas();
    });
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
  }

 createForm() {
    this.rulesCreateForm = this.fb.group({
      value: ['0', Validators.required],
      formattedComision: ['0', Validators.required],
      amortizar: ['0', [Validators.required, this.validateQuota()]],
      noCuota: ['0', [Validators.required, this.validateQuota()]],
    });
  }

  validateQuota(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = Number(control.value);
      if (isNaN(value) || value < 0 || value > this.maxQuota) {
        return { quotaExceeded: { value, maxQuota: this.maxQuota } }; // Devuelve un error si no cumple
      }
      return null;
    };
  }

  isSaveDisabled(): boolean {
    const noCuotaControl = this.rulesCreateForm.get('noCuota');
    const amortizarControl = this.rulesCreateForm.get('amortizar');
    if (!noCuotaControl || !amortizarControl) {
      return true;
    }
    const isNoCuotaInvalid = noCuotaControl.hasError('quotaExceeded') || noCuotaControl.invalid;
    const isAmortizarInvalid = amortizarControl.hasError('quotaExceeded') || amortizarControl.invalid;
    return isNoCuotaInvalid || isAmortizarInvalid || this.rulesCreateForm.invalid;
  }

  setData() {
    console.log('Set data');
    this.f.value.setValue(this.registerReceived.valor_bolsa);
    this.f.formattedComision.setValue(this.registerReceived.p_comision);
    this.f.amortizar.setValue(this.registerReceived.no_cuotas_amortizar);
    this.f.noCuota.setValue(this.registerReceived.no_cuotas_facturar);
    if (this.registerReceived.editar) {
      this.title = 'Crear bolsa';
    }
  }

  get f() {
    return this.rulesCreateForm.controls;
  }

  closeModal() {
    this.backupsHeaders.setLoad(true);
    this.modal.close();
  }

  onSubmit() {
    if (this.registerReceived.editar) {
      this.createOtherBag();
    } else {
      this.updateBag();
    }
  }

  createOtherBag() {
    const body = {
      id: this.registerReceived.id,
      estado_anterior: this.registerReceived.estado_anterior,
      estado_nuevo: 'RELIQUIDAR',
      usuario: this.user,
      condiciones: this.mapperOtherBill(),
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-update').subscribe(
      (resp) => {
        //this.sendModifyRegister.sendModifyRegister(this.registerReceived);
        Swal.fire({
          title: 'Se creo la bolsa',
          text: resp.message,
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Error en la creación de la Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.closeModal();
  }

  updateBag() {
    const body = {
      id: this.registerReceived.id,
      valor_bolsa: this.f.value.value,
      p_comision: this.f.formattedComision.value,
      no_cuotas_amortizar: this.f.amortizar.value,
      no_cuotas_facturar: this.f.noCuota.value,
      usuario_act: this.user,
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-general-update').subscribe(
      () => {
        //this.sendModifyRegister.sendModifyRegister(this.registerReceived);
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: 'La bolsa fue actualizada con éxito',
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.modal.close();
  }

  get formattedComision() {
    return this.registerReceived.p_comision !== null
      ? `${this.registerReceived.p_comision}%`
      : '';
  }

  updateComision(value: string) {
    if (isNaN(Number(value.replace('%', ''))) || value == '%')
      return (this.registerReceived.p_comision = '');
    this.registerReceived.p_comision =
      value !== '' ? parseFloat(value.replace('%', '')) : null;
  }

  mapperOtherBill() {
    return `${this.f.value.value};${this.f.formattedComision.value};${this.f.noCuota.value};${this.f.amortizar.value}`;
  }

  maxQuotas() {

    const periodicidadMap = {
      'MENSUAL': 1,
      'BIMENSUAL':2,
      'TRIMESTRAL': 3,
      'SEMESTRAL': 6,
      'ANUAL': 12,
    }
    this.periodicity= periodicidadMap[this.registerReceived.periodicidad_cuotas] || 1;
/*     if (this.registerReceived.periodicidad_cuotas === 'MENSUAL') {
      this.periodicity = 1;
    } else if (this.registerReceived.periodicidad_cuotas === 'BIMENSUAL') {
      this.periodicity = 2;
    } else if (this.registerReceived.periodicidad_cuotas === 'TRIMESTRAL') {
      this.periodicity = 3;
    } else if (this.registerReceived.periodicidad_cuotas === 'SEMESTRAL') {
      this.periodicity = 6;
    } else if (this.registerReceived.periodicidad_cuotas === 'ANUAL') {
      this.periodicity = 12;
    } */
    this.maxQuota =
      Math.round(this.registerReceived.meses_duracion_bolsa / this.periodicity);
    console.log('La cuota máxima es', this.maxQuota);
  }

  preventExtraDigits(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    if (input.value.length >= 2) {
      event.preventDefault();
    }
  }
}

