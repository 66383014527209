import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { Router } from "@angular/router";
import { BackupsStoreService } from "@app/pages/backups/services/backups-store.service";
import { RadicationService } from "@app/pages/quota-radication/services/radication-quota.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modal-edit-settlement",
  templateUrl: "./modal-edit-settlement.component.html",
  styleUrls: ["./modal-edit-settlement.component.scss"],
})
export class ModalEditSettlementComponent implements OnInit, OnDestroy {
  
  @Output() chargeList: EventEmitter<any> = new EventEmitter();
  @Input() quotaRadications: Array<any> = [];

  public management: boolean = true;

  constructor(
    public modal: ModalService,
    private quotaRadicationService: QuotaRadicationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public radicationService: RadicationService,
    public backupsStore: BackupsStoreService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.selectTab(0);
  }

  ngOnDestroy() {
    this.modal.close();
  }

  goToStart() {
    this.modal.close();
  }

  reloadRequest(){
    this.chargeList.emit();
    this.modal.close();
  }

  selectTab(selectedTab: number) {
    this.management = selectedTab == 0 ? true : false;
  }
}
