import { Component, OnInit } from '@angular/core';
import { IScoreInformation, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-scores-history',
  templateUrl: './scores-history.component.html',
  styleUrls: ['./scores-history.component.scss']
})
export class ScoresHistoryComponent implements OnInit {

  public headersTable: string[] = ['Tipo de score', 'Evaluación', 'Puntaje', 'Códigos/razones'];
  public scoresInformation: IScoreInformation[] = [];

  get hasScore(): boolean {
    return this.scoresInformation.length > 0;
  }

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.scoresInformation = this.subjectCreditHistory.getScoreInformation(response);
  }

}
