import { Component, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { ModalService } from "@services/modal.service";

import * as FileSaver from "file-saver";
import * as XLS from "xlsx";
import * as XLSX from "xlsx";
import { Subscription, Observable } from "rxjs";
import { SubjectService } from "@app/services/subject.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xls";

@Component({
  selector: "app-modal-file-upload",
  templateUrl: "./modal-file-upload.component.html",
  styleUrls: ["./modal-file-upload.component.scss"]
})
export class ModalFileUploadComponent implements OnInit, OnDestroy {
  public files: File[] = [];
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File> = new EventEmitter();
  public subs1: Subscription;
  public subsRemoveFiles: Observable<any> = this.subject.stateFileModal$;

  constructor(public modal: ModalService, public subject: SubjectService) {
    this.subs1 = this.subsRemoveFiles.subscribe(() => {
      this.onRemove(0);
    });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

  // DropZone
  onSelect(event) {
    this.fileSelected = event.addedFiles[0];
    this.files.push(this.fileSelected);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.modal.close();
    this.chargeResult.emit(this.fileSelected);
  }
}
