import { Component, OnInit } from "@angular/core";
import { ContractStepper } from "@app/models/contractStepper.model";
import { OrchestratorResponse } from "@app/models/orchestratorResponse.model";
import { Response } from "@app/models/responseUtil.model";
import { ContractService } from "@app/services/contract.service";
import { ModalService } from "@app/services/modal.service";
import { RolesService } from "@app/services/roles.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { FormContractService } from "../services/form-contract.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-stepper-contract",
  templateUrl: "./stepper-contract.component.html",
  styleUrls: ["./stepper-contract.component.scss"],
})
export class StepperContractComponent implements OnInit {
  validType: boolean = false;

  currentStep = 0;

  anotherIf: boolean = false;

  specialConditions: boolean = false;

  numberContract = 0;
  isReal = false;

  constructor(
    private router: Router,
    public modal: ModalService,
    private roles: RolesService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    public formService: FormContractService
  ) {
    this.numberContract = Number(localStorage.getItem("numberContract"));
    this.isReal = this.formService.status;
  }

  ngOnInit() {
    this.getRoleUser();
    this.getContractStepper();
    
    this.formService.currentStep$.subscribe((current) => {
      this.currentStep = current;
    });

    this.formService.myNumberContracts$.subscribe((current) => {
      this.numberContract = current;
    })

    if(this.isReal){
      this.formService.setCurrentStep(7)
    }
  }

  getRoleUser() {
    let userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (userName) {
      this.roles.findByUsername(userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "contract"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getContractStepper() {
    if (this.numberContract)
      this.contractService
        .getContractStepperByNumber(this.numberContract)
        .subscribe(
          (resp: Response<ContractStepper>) => {
            if (resp.ok && resp.data) {
              this.formService.setCurrentStep(resp.data[0].number);
            }
          },
          (error: any) => {
            console.log(error);
            this.spinner.hide();
          }
        );
  }

  getPermissions(permissions) {
    if (permissions.actions.ANOTHER_IF) {
      this.anotherIf = permissions.actions.ANOTHER_IF;
    }
    if (permissions.actions.SPECIAL_CONDITIONS) {
      this.specialConditions = permissions.actions.SPECIAL_CONDITIONS;
    }
  }

  getData(element) {
    console.log(element);

    // console.log(element);
    // if (element) element.style.color = "red";
  }

  orchestratorContract() {
    this.spinner.show();
    this.contractService
      .postContractOrchestrator(this.numberContract)
      .subscribe(
        (resp: Response<OrchestratorResponse>) => {
          console.log(resp);
          if (resp.ok && resp.data) {
            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
              text: `El contrato ${this.numberContract} ha sido finalizado correctamente.`,
            }).then(() => {
              this.router.navigate(["pages/contrato"]);
            });
            this.spinner.hide();
          }
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }
}
