import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractService } from '@app/services/contract.service';
import { RolesService } from '@app/services/roles.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payments-administrator',
  templateUrl: './payments-administrator.component.html',
  styleUrls: ['./payments-administrator.component.scss'],
})
export class PaymentsAdministratorComponent implements OnInit {
  form: FormGroup;

  user: string;

  profileActived = false;

  jsonData: {
    actividad: number;
    descripcion: string;
    valorNatural: number;
    valorJuridica: number;
  }[] = [];

  constructor(
    private contractService: ContractService,
    private roles: RolesService,
    private fb: FormBuilder
  ) {}

  public messager =
    'Estimado usuario no tienes acceso a este modulo. Debes registrar tu ID : ';

  ngOnInit() {
    let { user } = JSON.parse(localStorage.getItem('user'));

    this.user = user ? user : 'SYSTEM';

    this.messager += user;

    this.form = this.fb.group({
      actividades: this.fb.array(
        this.jsonData.map((data) => this.createActividadForm(data))
      ),
    });
    this.getRoleUser();
  }


  findAll() {
    this.contractService.getPaymentsAdministrator().subscribe((resp) => {
      this.jsonData = resp.data[0];

      const forms = this.jsonData.map((element) =>
        this.createActividadForm(element)
      );

      forms.forEach((element) => this.actividades.push(element));
    });
  }

  get actividades(): FormArray {
    return this.form.get('actividades') as FormArray;
  }

  createActividadForm(data: any): FormGroup {
    return this.fb.group({
      actividad: [
        { value: data.actividad, disabled: true },
        Validators.required,
      ],
      descripcion: [
        { value: data.descripcion, disabled: true },
        Validators.required,
      ],
      valorNatural: [data.valorNatural, Validators.required],
      valorJuridica: [data.valorJuridica, Validators.required],
    });
  }

  addActividad() {
    this.actividades.push(
      this.createActividadForm({
        actividad: null,
        descripcion: '',
        valorNatural: 0,
        valorJuridica: 0,
      })
    );
  }

  removeActividad(index: number) {
    this.actividades.removeAt(index);
  }

  submit() {
    const { actividades } = this.form.getRawValue();
    this.contractService
      .postPaymentsAdministrator(actividades, this.user)
      .subscribe(() => {
        Swal.fire({
          type: 'success',
          title: 'Transacción exitosa',
        });
        this.clearFormArray(this.actividades);
        this.findAll();
      });
  }

  clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  getRoleUser() {
    let userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (userName) {
      this.roles.findByUsername(userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'contract'
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions.paymentsAdministrador) {
      this.profileActived = permissions.actions.paymentsAdministrador;
      this.findAll();
    }
  }
}
