import { Component, OnInit } from '@angular/core';
import { PersonDocument } from '@app/pages/get-quota/searcher/models/person-document';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { DataAffiliate, ReceiveAffiliate } from '../../models/affiliate-buyer.model';
import { AffiliatesBuyerService } from '../../services/affiliates-buyer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-report-affiliate',
  templateUrl: './report-affiliate.component.html',
  styleUrls: ['./report-affiliate.component.scss']
})
export class ReportAffiliateComponent implements OnInit {

  documentTypes: any;
  data: PersonDocument = {
    documentType : '',
    document     :  ''
  }
  dataAffiliate: DataAffiliate[] = [];
  yearHeader1: number;
  colspanYear1 = 0;
  yearHeader2: number;
  colspanYear2 = 0;

  constructor(
    public helper: HelperService, 
    private listService: ListsCovinocService, 
    private affiliatesBuyerService: AffiliatesBuyerService,
    private spinner: NgxSpinnerService ) 
    { }
 
  ngOnInit() {
    this.findAllLists();
  }

  
  changeInput() {
    
  }

  getinfoAffilateASR() {
   
    this.spinner.show();
    this.affiliatesBuyerService.getinfoAffilateASR(this.data.documentType, this.data.document)
    .pipe(
      finalize(() => {
        this.spinner.hide();
      }
    ))
    .subscribe(
      (res: ReceiveAffiliate) => {
        let months1 = [];
        let months2 = [];
        this.dataAffiliate = res.data;
        /**let prueba: any = [
          {year:2023},
          {year:2023},
          {year:2023},
          {year:2023},
          {year:2022},
          {year:2022}
        ]
        */
        this.dataAffiliate.forEach((element)=> {
          //this.yearHeader2 = element.year;
          if(this.dataAffiliate[0].year === element.year) {
            months1.push(element.year)
          } else {
            months2.push(element.year)
          }

        })
        this.colspanYear1 = months1.length;
        this.yearHeader1 = months1[0];
          if(months2.length > 0) {
            this.colspanYear2 = months2.length;
            this.yearHeader2 = months2[0];
          } else {
            this.colspanYear2 = undefined;
            this.yearHeader2 = undefined;
          }
        
      },(error: any) => {
        //console.log(error)
          Swal.fire({
            type: "info",
            text:  `${error.error.message}`
          });
          this.refresh();
        });
  }


  findAllLists(): void {
    this.listService.findAllLists().subscribe(
      (res) => {
        if(res.status != 200) this.helper.badResponse(res.message, res.status);
        this.documentTypes = res.data[0]['documentTypesCode'];
      },
      error => this.helper.handleErrorHttp(error, 'Ocurrió un Error')
    );
  }

  refresh() {
    this.data = {
      documentType : '',
      document     :  ''
    }
    this.dataAffiliate = [];
  }


}
