import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@app/models/responseUtil.model';
import { HelperService } from '@app/services/helper.service';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NonPaymentNotice } from '../models/response/non-payment-notice';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class NonPaymentNoticeService {

  private API_URL: string;

  constructor(private http: HttpClient, public helper: HelperService, private ciCd: CiCdConfigService) {
    this.API_URL = environment.apiUrl + 'quota-manager/nonpaymentNotices/findNonPayments';
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl} = apis
      this.API_URL = apiUrl + 'quota-manager/nonpaymentNotices/findNonPayments';
       console.log('config ci cd', apiUrl);
    });
  }

  findAllNotices(body: string): Observable<Response<NonPaymentNotice>> {
    let requestUri = this.API_URL;
    return this.http.post<Response<NonPaymentNotice>>(`${requestUri}`, body);
  }
}
/* D:\Projects\admin-sector-real\src\app\services */
