import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { ResumenEndeudamiento } from '../../models/credit-history.model';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-global-debt-summary-history',
  templateUrl: './global-debt-summary-history.component.html',
  styleUrls: ['./global-debt-summary-history.component.scss']
})
export class GlobalDebtSummaryHistoryComponent implements OnInit, OnDestroy {
  public headersTable: string[] = ['Sector', 'Comercial - Nro', 'Comercial - Miles $', 'Hipotecario - Nro', 'Hipotecario - Miles $',
    'Consumo y Tarjeta de Crédito - Nro', 'Consumo y Tarjeta de Crédito - Miles $', 'Microcrédito - Nro', 'Microcrédito - Miles $', '% Participación'];

  public globalDebtSummaryArray: any[] = [];
  public totals: any = {};
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    const globalDebtSummaryObject: ResumenEndeudamiento | [] = response.globalDebtSummary;

    this.globalDebtSummaryArray = Object.values(globalDebtSummaryObject instanceof Array ? {} : globalDebtSummaryObject.Trimestre);

    this.calculateTotals();
  }

  calculateTotals() {
    this.totals = {
      comercialNro: 0,
      comercialMiles: 0,
      hipotecarioNro: 0,
      hipotecarioMiles: 0,
      consumoTarjetaNro: 0,
      consumoTarjetaMiles: 0,
      microcreditoNro: 0,
      microcreditoMiles: 0
    };

    this.globalDebtSummaryArray.forEach((trimestre) => {
      trimestre.Sector.forEach((sector: any) => {
        const comercial = sector.Cartera.find(c => c.tipo === 'Comercial');
        this.totals.comercialNro += comercial.numeroCuentas || 0;
        this.totals.comercialMiles += comercial.valor || 0;

        const hipotecario = sector.Cartera.find(c => c.tipo === 'Hipotecario');
        this.totals.hipotecarioNro += hipotecario.numeroCuentas || 0;
        this.totals.hipotecarioMiles += hipotecario.valor || 0;

        const consumo = sector.Cartera.find(c => c.tipo === 'Consumo');
        const tarjetaCredito = sector.Cartera.find(c => c.tipo === 'Tarjeta de Crédito');
        this.totals.consumoTarjetaNro += (consumo.numeroCuentas || 0) + (tarjetaCredito.numeroCuentas || 0);
        this.totals.consumoTarjetaMiles += (consumo.valor || 0) + (tarjetaCredito.valor || 0);

        const microcredito = sector.Cartera.find(c => c.tipo === 'Microcrédito');
        this.totals.microcreditoNro += microcredito.numeroCuentas || 0;
        this.totals.microcreditoMiles += microcredito.valor || 0;
      });
    });
  }


  getNumeroCuentas(cartera: any[], tipo: string): number | string {
    const item = cartera.find(c => c.tipo === tipo);
    return item ? item.numeroCuentas : '--';
  }

  getValor(cartera: any[], tipo: string): number | string {
    const item = cartera.find(c => c.tipo === tipo);
    return item ? item.valor : '--';
  }

  getConsumoYTarjetaNumero(cartera: any[]): number {
    const consumo = cartera.find(c => c.tipo === 'Consumo').numeroCuentas || 0;
    const tarjeta = cartera.find(c => c.tipo === 'Tarjeta de Crédito').numeroCuentas || 0;
    return consumo + tarjeta;
  }

  getConsumoYTarjetaValor(cartera: any[]): number {
    const consumo = cartera.find(c => c.tipo === 'Consumo').valor || 0;
    const tarjeta = cartera.find(c => c.tipo === 'Tarjeta de Crédito').valor || 0;
    return consumo + tarjeta;
  }
}
