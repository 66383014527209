import { Component, OnInit } from "@angular/core";
import { GloballBillingService } from "@app/services/globall-billing.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { ICuotas, IFilas } from "../models/Semaforo.model";
import { UpdateDateBill } from "../models/UpdateDateBill";
import { HelperService } from "@app/services/helper.service";
import { GracePeriod } from "@app/models/global-billing.models";
import { RolesService } from "@app/services/roles.service";

@Component({
  selector: "app-billing-traffic-light",
  templateUrl: "./billing-traffic-light.component.html",
  styleUrls: ["./billing-traffic-light.component.scss"],
})
export class BillingTrafficLightComponent implements OnInit {
  hearder: Array<string> = [];
  data: Array<IFilas> = [];
  footerTable: Array<any> = [];
  valueStart = null;
  valueEnd = null;
  comment = null;
  selectStart: Array<any> = [];
  selectEnd: Array<string> = [];
  selectBill: Array<any> = [];
  infoGracePeriod: Array<any> = [];
  id = 0;
  identity: any;
  user: string='';
  gracePeriod: number;

  valueBill = null;
  selectMonth=[1,2,3]

  action: Array<{ label: string; value: string }> = [
    {
      label: "Mover cuotas",
      value: "MOVE",
    },
    {
      label: "Cambiar valor cuotas",
      value: "CHANGE",
    },
    {
      label: "No facturar",
      value: "NO_BILL",
    },
    {
      label: "Adicionar período de gracia",
      value: "GRACE_PERIOD",
    },
  ];

  actionNotGracePeriod: Array<{ label: string; value: string }> = [
    {
      label: "Mover cuotas",
      value: "MOVE",
    },
    {
      label: "Cambiar valor cuotas",
      value: "CHANGE",
    },
    {
      label: "No facturar",
      value: "NO_BILL",
    },
  ];


  actionValue = "MOVE";
  public message = "";
  public userName: any;
  public permissionToView = false;
  public permissionToViewTableAmortization = false;


  constructor(
    public helper: HelperService,
    private route: ActivatedRoute,
    private simulationService: GloballBillingService,
    private gracePeriodService: GloballBillingService,
    private gracePeriodCondition: GloballBillingService,
    private spinner: NgxSpinnerService,
    private roles: RolesService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const { id } = params;
      if (id) {
        this.id = id;
      }
    });
    console.log(this.id);
    this.getAllService();
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
    this.getProfile();
  }

  getAllService() {
    this.getBilling();
    this.selectHandler();
    this.getToBilling();
    this.getGracePeriodCondition();
  }

  getBilling() {
    this.spinner.show();
    this.simulationService.getBillingTrafficLigth(this.id).subscribe(
      (resp) => {
        if (resp.ok) {
          this.data = [];
          this.hearder = [];
          this.footerTable = [];
          this.data = resp.data.filas;
          this.hearder = this.hearder.concat(resp.data.encabezados);
          this.footerTable = resp.data.pie_pagina.map((element) => {
            if (Number(element)) {
              return { isNumber: Number(element), value: Number(element) };
            } else {
              return { isNumber: Number(element), value: element };
            }
          });

          const filas: Array<IFilas> = [];

          this.data.forEach((element) => {
            const fila = element;
            const cuotas: Array<ICuotas> = [];
            this.hearder.forEach((item) => {
              const cuota = element.cuotas.filter(
                (element) => element.cuota == item
              );

              if (!fila.color) {
                fila.color = "WHITE";
              }
              if (item === "Total Factura") return;
              if (item === "Número de Factura") return;
              if (cuota.length > 0) {
                const numberBill = cuota.find(
                  (element) => element.numero_factura != null
                );

                fila.numero_factura = numberBill
                  ? numberBill.numero_factura
                  : null;
                let sum = 0;
                cuota.forEach((element) => (sum += element.valor));
                const currentCuota: ICuotas = {
                  cuota: item,
                  valor: sum,
                  numero_factura: cuota[0].numero_factura,
                };
                cuotas.push(currentCuota);
              } else {
                cuotas.push({ valor: 0, cuota: item, numero_factura: 0 });
              }
            });
            fila.cuotas = cuotas;
            filas.push(fila);
          });

          this.data = filas;
          console.log(this.data);

          this.spinner.hide();
        }
      },
      (error) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  getOrigin() {
    this.spinner.show();
    this.simulationService.getOrigin(this.id).subscribe(
      (resp) => {
        this.selectStart = resp.data[0]["listado"].map((element) => {
          const date = new Date(element);
          const month = date.toLocaleString("default", { month: "long" });
          return {
            label: `${date.getFullYear()} - ${month} - ${date.getDate()}`,
            value: element,
          };
        });
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  getToBilling() {
    this.simulationService.getToBilling(this.id).subscribe((resp) => {
      if (resp.data.length == 0) return;
      this.selectBill = resp.data[0].map((element: { cuota: string, fecha: string }) => {
        return {
          label: element.cuota,
          value: element,
        };
      });
    });
  }

  selectHandler() {
    this.getDestination();
  }

  getDestination() {
    this.spinner.show();
    this.simulationService.getDestination(this.id).subscribe(
      (resp) => {
        if (!resp.ok) {
          this.selectEnd = [];
          this.spinner.hide();
          return;
        }
        this.selectEnd = resp.data[0]["listado"].map((element) => {
          const date = new Date(element);
          const month = date.toLocaleString("default", { month: "long" });
          return {
            label: `${date.getFullYear()} - ${month} - ${date.getDate()}`,
            value: element,
          };
        });
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.spinner.show();
    const body: UpdateDateBill = {
      id_bolsa: this.id,
      cant_cuotas: this.valueStart,
      destino: this.valueEnd,
      observacion: this.comment,
    };

    this.simulationService.updateDates(body).subscribe(
      () => {
        Swal.fire({
          type: "success",
          title: "Correcto",
          text: `Se guardo correctamente`,
        });
        this.spinner.hide();
        this.valueEnd = null;
        this.valueStart = null;
        this.comment = null;
        this.selectStart = [];
        this.selectEnd = [];
        this.data = [];
        this.hearder = [];
        this.getAllService();
      },
      (error) => {
        const { message } = error.error;
        Swal.fire({
          type: "error",
          title: "Tabla de simulación",
          text: `${message}`,
        });
        this.spinner.hide();
      }
    );
  }

  onSubmitChange() {
    this.spinner.show();
    this.simulationService
      .changeValueBill({
        id_bolsa: this.id,
        valor: this.valueBill,
        observacion: this.comment,
      })
      .subscribe(
        () => {
          Swal.fire({
            type: "success",
            title: "Correcto",
            text: `Se guardo correctamente`,
          });
          this.spinner.hide();
          this.valueBill = null;
          this.comment = null;
          this.getAllService();
        },
        (error) => {
          const { message } = error.error;
          Swal.fire({
            type: "error",
            title: "Tabla de simulación",
            text: `${message}`,
          });
          this.spinner.hide();
        }
      );
  }

  onSubmitBill() {
    this.spinner.show();
    this.simulationService
      .toBill({
        id_bolsa: this.id,
        body: this.valueBill,
        observacion: this.comment,
      })
      .subscribe(
        () => {
          Swal.fire({
            type: "success",
            title: "Correcto",
            text: `Se guardo correctamente`,
          });
          this.spinner.hide();
          this.valueBill = null;
          this.comment = null;
          this.getAllService();
        },
        (error) => {
          const { message } = error.error;
          Swal.fire({
            type: "error",
            title: "Tabla de simulación",
            text: `${message}`,
          });
          this.spinner.hide();
        }
      );
  }

  onSubmitGracePeriod() {
    this.spinner.show();
    const body: any = {
      id_bolsa: this.id,
      meses: this.gracePeriod,
      usuario: this.user,
      observaciones: this.comment,
    };
    this.gracePeriodService.gracePeriod(body).subscribe(
      (resp) => {
        Swal.fire({
          type: "success",
          title: "Período de gracia",
          text: resp.message,
        });
        this.spinner.hide();
        this.gracePeriod=0;
        this.comment = null;
        this.getAllService();
      },
      (error) => {
        const { message } = error.error;
        Swal.fire({
          type: "error",
          title: "Período de gracia",
          text: `${message}`,
        });
        this.spinner.hide();
      }
    );
  }

  getGracePeriodCondition() {

    console.log("El id es:", this.id)

    this.gracePeriodCondition.getGracePeriodCondition(this.id).subscribe(
      (resp: any) => {

        if (resp.data && resp.data.length > 0) {
          this.infoGracePeriod=resp.data[0];
        } else {
          this.infoGracePeriod=null;
        }


        console.log("La información de periodo de gracias es", this.infoGracePeriod)
       /*  Swal.fire({
          type: "success",
          title: "Período de gracia",
          text: resp.message,
        }); */


      },
      /* (error) => {
        const { message } = error.error;
        Swal.fire({
          type: "error",
          title: "Período de gracia",
          text: `${message}`,
        });
        this.spinner.hide();
      } */
    );
  }


  getProfile() {
    this.userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "contract"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions.updateBilling === true) {
      this.permissionToViewTableAmortization = true;
    }
  }
}
