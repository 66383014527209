import { GeneralInfo } from "./generalInfo.model";

export class CurrentExhibition {
    id: number;
    affiliate: string;
    product: string;
    state: string;
    assignment: string;
    quota: number;
    totalGuarantee: number;
    totalCurrentGuarantee: number;
    dateInitialGuarantee: Date;
    dateLastGuarantee: Date;
    currentWarrantyExpired: string;
    suggestedMove: string;
    movementValue: number;
    expoQuota: number;
    currentWarrantyTotal: string;
    history: Array<History>;
    quotaId: GeneralInfo;

    public static fromResponse(json: any): CurrentExhibition {

        const currentExhibition: CurrentExhibition = {

        id: json['id'],
        affiliate: json['affiliate'],
        product: json['product'],
        state: json['state'],
        assignment: json['assignment'],
        quota: json['quota'],
        totalGuarantee: json['totalGuarantee'],
        totalCurrentGuarantee: json['totalCurrentGuarantee'],
        dateInitialGuarantee: json['dateInitialGuarantee'],
        dateLastGuarantee: json['dateLastGuarantee'],
        currentWarrantyExpired: json['currentWarrantyExpired'],
        suggestedMove: json['suggestedMove'],
        movementValue: json['movementValue'],
        expoQuota: json['expoQuota'],
        currentWarrantyTotal: json['currentWarrantyTotal'],
        history: json['history'],
        quotaId: json['quotaId']
    };
    return currentExhibition;
    }
}

export class History {
    id: string | null;
    date: string;
    user: string;
    updateField: string;
    currentExhibitionId: string | null;
}