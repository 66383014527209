import { Component, OnInit, ViewChild, ViewEncapsulation  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AffiliateLinkDataForm, DateLink } from 'src/app/models/afiliate-link';
import { LegalRepresentative } from 'src/app/models/membership.model';
import { PartnerModel } from 'src/app/models/partner.model';
import { SelectsDataForm } from 'src/app/models/selectModel';
import { AffiliateLinkService } from 'src/app/services/affiliate-link.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-setup-affiliate-link',
  templateUrl: './setup-affiliate-link.component.html',
  styleUrls: ['./setup-affiliate-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SetupAffiliateLinkComponent implements OnInit {
  @ViewChild('formSetup') formSetup: NgForm;
  @ViewChild('formSetup1') formSetup1: NgForm;
  public partners: PartnerModel[] = [new PartnerModel()];
  public partnersModel: LegalRepresentative[];
  public documentTypesList: SelectsDataForm[];
  public generalLists: SelectsDataForm;
  public documentTypeAffiliate: any[] = [];
  public documentTypeContact: any[] = [];
  public affiliateData: AffiliateLinkDataForm;
  public data: AffiliateLinkDataForm = new AffiliateLinkDataForm();
  public maximumDate: DateLink = new DateLink();
  public municipalities: SelectsDataForm[];
  public city: string;
  public departments: any;
  public listCities: any;
  public cities: any;
  public daneCode: SelectsDataForm[];
  public listDaneCode: any;
  public message: AffiliateLinkDataForm;

  constructor(
    private affiliateLink: AffiliateLinkService,
    private notifyData: AffiliateLinkService,
    private department: AffiliateLinkService,
    public helper: HelperService,
    private toastr: ToastrService,
    public select: SelectsService) {
  }

  public get isNitDocumentType(): boolean {
    return this.data.issuerDocumentTypeId === 'N';
  }

  ngOnInit() {
    this.initializeSelects();
  }

  initializeSelects(): void {
    this.data.applicative = "ASR"
    this.generalList();
    this.getDepartments();
  }

  generalList() {
    this.affiliateLink.getGeneralList().subscribe((response: SelectsDataForm) => {
      this.generalLists = response;
    },
      (err) => {
        this.listCities = [];
      });
  }

  /* parseDocumentType(isAffiliate: boolean) {
    let documentTypes;
    this.generalLists.data.documentTypes[0].state = "UNSHOW";
    this.generalLists.data.documentTypes[4].state = "UNSHOW";

    if(!isAffiliate) {
      this.generalLists.data.documentTypes[2].state = "UNSHOW";
    }

    return [...documentTypes];
  } */

  getDepartments() {
    this.affiliateLink.getMunicipalities().subscribe((departments: any) => {
      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  selectDeparment(selectedDepartment) {
    this.data.city = ""
    this.affiliateLink.getCities(selectedDepartment).subscribe(
      (municipalities: any) => {
        this.listCities = municipalities.data[0].cities || [];
      },
      (err) => {
        this.listCities = [];
      });
      /* this.prospectus.city = "";
    this.backend.getCities(selectedDepartment).subscribe(
      (municipalities: any) => {
        if(municipalities.data) {
          this.listCities = municipalities.data.cities || [];
          console.log("Data de ciudades", this.listCities);

        }
      },
      (err) => {
        this.listCities = [];
      }); */
  }

  postNotifyForm() {
    let bodyNotifyForm = {...this.data};
    delete bodyNotifyForm.applicative;
    delete bodyNotifyForm.message;
    delete bodyNotifyForm.urlAffiliate;
    delete bodyNotifyForm.roleAssignment;
    delete bodyNotifyForm.city;
    delete bodyNotifyForm.municipalities;
    this.notifyData.userNotifyForm(bodyNotifyForm).subscribe(
      (response: any) => {
        if (response) {
          Swal.fire({
            type: "info",
            title: response.message,
            customClass: {
              title: 'title',
            }
          }).then(resp => {
            window.location.reload();
          })
        }
      },
      (error) => {
        console.error(error);
        Swal.fire({
          type: "error",
          title: error.message,
          customClass: {
            title: 'title',
          }
        })
      }
    )
  }

  postAffiliateLink() {
    let bodyAffiliateLink = {
      issuerDocumentTypeId: this.data.issuerDocumentTypeId,
      issuerDocumentId: this.data.issuerDocumentId,
      urlAffiliate: this.data.urlAffiliate,
      applicative: this.data.applicative,
    }

    let user = JSON.parse(localStorage.getItem('user'));

    if(user) {
      this.data.idRol = this.data.roleAssignment as any;
      this.data.userAct = user.user;
      this.data.daneCode = this.data.city;
      this.data.dateBirthday = this.maximumDate.dateBirthday;

      this.affiliateLink.userAffiliateLink(bodyAffiliateLink).subscribe(
        (response: any) => {
          if (response) {
            this.toastr.success(response.message);
            this.postNotifyForm();
          }
        },
        (error) => {
          console.error(error);
          Swal.fire({
            type: "error",
            title: error.message,
            customClass: {
              title: 'title',
            }
          })
        }
      )
    }
  }

  resetUrlAffiliate() {
    this.data.urlAffiliate = ((this.data.issuerDocumentTypeId === 'N') ? this.data.urlAffiliate : "");
  }

  dualService() {
    this.postAffiliateLink();
  }
}
