import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-netsuite-invoice-information',
  templateUrl: './modal-netsuite-invoice-information.component.html',
  styleUrls: ['./modal-netsuite-invoice-information.component.scss'],
})
export class ModalNetsuiteInvoiceInformationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() netsuiteInvoiceInformation: any = {};

  organizedData: { label: string; value: any }[] = [];

  constructor(public modal: ModalService, private router: Router) {}

  ngOnInit() {
    this.organizeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.netsuiteInvoiceInformation && changes.netsuiteInvoiceInformation.currentValue) {
      this.organizeData();
    }
  }

  organizeData() {
    if (this.netsuiteInvoiceInformation && typeof this.netsuiteInvoiceInformation === 'object') {
      // Parse JSON strings if applicable
      let parsedContent: any = {};
      let parsedResponse: any = {};
      let parsedItems = [];

      try {
        parsedContent = JSON.parse(this.netsuiteInvoiceInformation.contenido || '{}');
      } catch (error) {
        console.error('Error parsing contenido:', error);
      }

      try {
        // Clean the "respuesta" string before parsing
        let cleanedResponse = this.netsuiteInvoiceInformation.respuesta.replace(/^Respuesta:\s*/, '');
        parsedResponse = JSON.parse(cleanedResponse || '{}');
      } catch (error) {
        console.error('Error parsing respuesta:', error);
      }

      if (parsedContent && parsedContent.items) {
        parsedItems = parsedContent.items;
      }

      // Organizing the data into label-value pairs for display
      this.organizedData = [
        { label: 'Fecha', value: this.netsuiteInvoiceInformation.fecha },
        { label: 'Integración', value: this.netsuiteInvoiceInformation.integracion },
        // Display parsed "contenido" fields
        ...Object.entries(parsedContent).filter(([key]) => key !== 'items').map(([key, value]) => ({ label: this.formatLabel(key), value })),
        // Display parsed "items" if available
        ...parsedItems.map((item, index) => ({
          label: `Item ${index + 1}`,
          value: Object.entries(item).map(([key, value]) => `${this.formatLabel(key)}: ${value}`).join(', ')
        })),
        // Display parsed "respuesta" fields
        ...Object.entries(parsedResponse).map(([key, value]) => ({ label: this.formatLabel(key), value })),
        { label: 'Fecha Fin', value: this.netsuiteInvoiceInformation.fecha_fin },
        { label: 'Lote', value: this.netsuiteInvoiceInformation.lote },
        { label: 'External ID', value: this.netsuiteInvoiceInformation.externalid }
      ];
    }
}


  formatLabel(key: string): string {
    // Convert camelCase or snake_case to a more readable format
    return key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase());
  }

  ngOnDestroy() {}

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }
}
