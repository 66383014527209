import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { of } from "rxjs";
import * as QuotaActions from '@app/redux/actions/quota.actions';
import { QuotaGreaterService } from '../../services/quota-greater.service';
import { GetQuotaResponseModel } from "@app/pages/get-quota/models/request/QuotaRequest.model";

@Injectable()

export class QuotasEffect {

    constructor(private actions$: Actions, private quotaService: QuotaGreaterService) { }

    public loadSelects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuotaActions.getQuotas),
            // tap(data => console.log("select effect tap =>", SelectActions.getSelects, data)),
            mergeMap((action) => {
                return this.quotaService.getQuota(action.id).pipe(
                    // tap(data => console.log("select effect tap =>", data)),
                    map((quota: GetQuotaResponseModel) => {
                        return QuotaActions.getQuotasSuccess({ quota });
                    }),
                    catchError(error => of(QuotaActions.getQuotasFailure({ error })))
                );
            }),
        )
    );

}