import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-operations-denied',
  templateUrl: './modal-operations-denied.component.html',
  styleUrls: ['./modal-operations-denied.component.scss']
})
export class ModalOperationsDeniedComponent implements OnDestroy {

  constructor(public modal: ModalService) { }

  ngOnDestroy() {
    this.modal.close();
  }

}
