import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lawsuits-history',
  templateUrl: './lawsuits-history.component.html',
  styleUrls: ['./lawsuits-history.component.scss']
})
export class LawsuitsHistoryComponent implements OnInit {

  public headersTable: string[] = ['Fecha', 'Descripción'];

  constructor() { }

  ngOnInit() {
  }

}
