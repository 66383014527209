import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '@services/helper.service';

import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  // Dates
  public finalDate;
  public initDate;
  public min: any;
  public max;

  // Properties
  public selectedMoments = [];

  @Output() search: EventEmitter<object> = new EventEmitter();
  @Input() minInput: Date;
  @Input() maxInput: Date;
  @Input() initDateInput: Date;
  @Input() finalDateInput: Date;
  @Input() isInit: boolean = true;

  constructor(public helper: HelperService) { }

  ngOnInit() {
    this.min = this.minInput || new Date(2000, 1, 1);
    this.max = this.maxInput || new Date();
    if(this.isInit){
      this.initializeDateRange();
    }
  }

  initializeDateRange() {
    this.finalDate = this.finalDateInput || new Date();
    this.initDate =
      this.initDateInput ||
      moment(this.finalDate).subtract(30, 'days').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
    this.search.emit(this.selectedMoments)
  }

  dateValidate(initDate: any) {
    const minDate = moment(initDate, "DD/MM/YYYY") as any;
    this.min = new Date(minDate);
  }

  dateTextInitChange($event) {
    this.initDate =
      $event.target.value.length != 10
        ? moment().toDate()
        : moment($event.target.value, 'DD/MM/YYYY').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
  }

  dateTextFinalChange($event) {
    this.finalDate =
      $event.target.value.length != 10
        ? moment().toDate()
        : moment($event.target.value, 'DD/MM/YYYY').toDate();
    this.selectedMoments = [this.initDate, this.finalDate];
    if (this.finalDate < this.max && this.initDate > this.min) {
      this.search.emit(this.selectedMoments);
    }
  }

  selectRange($event) {
    const [start, end] = $event;
    if (!start || !end) {
      this.initializeDateRange();
      return;
    }
  
    this.dateValidate(start);

    if (moment(end).diff(moment(start), 'days') < 0) {
      console.log('no range', moment(end).diff(moment(start), 'days') < 1);
      return;
    }

    let validEvent = true;
    if ($event && $event[0] && typeof $event[0]._i === 'string' && $event[0]._i.length != 10) {
      validEvent = false;
    }
    if ($event && $event[0] && typeof $event[1]._i === 'string' && $event[1]._i.length != 10) {
      validEvent = false;
    }
    if (validEvent) {
      this.search.emit($event);
    }
  }
}

