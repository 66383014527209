import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BuyerAffiliateRequest } from "../models/request/buyerAffiliateRequest.model";
import { NgxSpinnerService } from "ngx-spinner";
import { BuyerAffiliateContractResponse } from "../models/response/buyerAffiliateContractResponse.model";
import { BuyerAffiliateContract } from "../models/buyerAffiliateContract.modal";
import { HelperService } from "@app/services/helper.service";
import { CurrencyPipe } from "@angular/common";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import { QuotaService } from "@app/pages/quotas/services/quota-greateer.service";
import Swal from "sweetalert2";
import { CurrentExhibition } from "../models/currentExhibition.model";
import { Response } from "@app/models/responseUtil.model";
import { QuotaGreaterThan20M } from "../models/quotaGreaterThan20M.model";

@Component({
  selector: "app-current-exhibition",
  templateUrl: "./current-exhibition.component.html",
  styleUrls: ["./current-exhibition.component.scss"],
  providers: [CurrencyPipe],
})
export class CurrentExhibitionComponent implements OnInit, OnChanges {
  @Input() infoStudyQuote: FormGroup;
  @Input() quotaResponse: QuotaGreaterThan20M;
  states = [];

  editField: string;
  propertySaved: string;
  propertySelectSave: string;
  personList: Array<any> = [];
  idFieldPerson: number = 0;
  bodyBuyerQuota: Array<any> = [];
  idValue: number = 0;
  valueMovementValue = "";

  private buyerAffiliateRequest = new BuyerAffiliateRequest();

  constructor(
    private quotaGreaterService: QuotaGreaterService,
    private quotaService: QuotaService,
    public helper: HelperService
  ) {
    this.states = this.quotaService.statesExhibition;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.infoStudyQuote);
  }
  ngOnInit() {
    this.infoStudyQuote
      .get("quotaDR")
      .valueChanges.subscribe((selectedValue) => {
        if (selectedValue != "") {
          this.personList.forEach((element) => {
            if (element["afilliates"] == "NUEVO CUPO") {
              element["quota"] = selectedValue;
              element["quotaSum"] = selectedValue;
              this.updateQuota();
            }
          });
        }
      });
    this.chargeInfo();
    this.onChangesFinancialSources();
  }

  /**
   * Is in charge of filling out The Current Exhibition when information is found
   * in other forms of 20m in consultation of financial information
   * @author Rafael Ramirez
   */
  onChangesFinancialSources() {
    this.infoStudyQuote
      .get("currentExhibition")
      .valueChanges.subscribe((currentExhibitions) => {
        let updatedPersonsList = [];

        currentExhibitions
          .slice()
          .reverse()
          .forEach((currentExhibition: CurrentExhibition, index: number) => {
            let person = {
              id: index + 1,
              afilliates: currentExhibition.affiliate,
              product: currentExhibition.product,
              state: currentExhibition.state,
              assignment: currentExhibition.assignment,
              quota: currentExhibition.quota,
              fullGuarantee: currentExhibition.totalGuarantee,
              totalCurrentGuarantee: currentExhibition.totalCurrentGuarantee,
              initialWarrantyDate: currentExhibition.dateInitialGuarantee,
              lastGuaranteeDate: currentExhibition.dateLastGuarantee,
              warrantyValidityExpired: currentExhibition.currentWarrantyExpired,
              suggestedMove: currentExhibition.suggestedMove,
              movementValue: currentExhibition.movementValue,
              quotaSum: currentExhibition.expoQuota,
              totalCurrentWarranty: currentExhibition.totalCurrentGuarantee,
            };
            updatedPersonsList.push(person);
          });
        this.personList = updatedPersonsList;
      });
  }

  chargeInfo() {
    this.buyerAffiliateRequest = {
      identification: this.infoStudyQuote.controls.document.value,
      identificationType: this.infoStudyQuote.controls.docType.value,
    };

    let currentExhibition = this.infoStudyQuote.controls.currentExhibition.value;

    if(currentExhibition.length > 0) {
      this.personList = currentExhibition;
      return;
    }

    this.quotaGreaterService
      .getAfilliateBuyerContract(this.buyerAffiliateRequest)
      .subscribe((res: Response<BuyerAffiliateContract>) => {
        if (res.data && res.data.length > 0) {
          this.updatingTable(res.data);
        }
      });
  }

  getHistory() {
    const body = {
      documentType: "C",
      document: "37391823",
      currentPage: "1",
      perPage: "10",
    };
    this.quotaGreaterService.getBuyerQuotas(body).subscribe((resp: any) => {
      this.bodyBuyerQuota = resp.data[0].quotas;
    });
  }

  updatingTable(list: Array<BuyerAffiliateContract>) {
    this.infoStudyQuote.controls.quotaCE.setValue(list[0].initialQuota);
    this.infoStudyQuote.controls.quotaCEFE.setValue(list[0].finalQuota);
    this.infoStudyQuote.controls.fullGuarantee.setValue(
      list[0].initialFullGuarantee
    );
    this.infoStudyQuote.controls.totalCurrentGuarantee.setValue(
      list[0].initialFullCurrentGuarantee
    );
    this.infoStudyQuote.controls.totalCurrentGuaranteeFE.setValue(
      list[0].finalFullCurrentGuarantee
    );

    this.infoStudyQuote.controls.cvsNo2.setValue(list[0].cvsNoTH);
    this.infoStudyQuote.controls.recMaxDays2.setValue(
      list[0].daysCvsTH != null ? list[0].daysCvsTH : 0
    );
    this.infoStudyQuote.controls.cvsNo.setValue(
      list[0].valueCvsTH != null ? list[0].valueCvsTH : 0
    );
    this.infoStudyQuote.controls.recMaxDays.setValue(
      list[0].daysUA != null ? list[0].daysUA : 0
    );
    this.infoStudyQuote.controls.notPayNotices.setValue(
      list[0].noPaymentNoticeNo
    );
    this.infoStudyQuote.controls.extensions.setValue(list[0].extendsNo);

    if (list.length > 0) {
      list[0].currentExhibitions.forEach((element) => {
        console.log(element);
        let exhibition = {
          afilliates: element.affiliate,
          product: element.product,
          state: element.state,
          assignment: element.date,
          quota: element.quotaAffiliate,
          fullGuarantee: element.fullGuarantee,
          totalCurrentGuarantee: element.fullCurrentGuarantee,
          initialWarrantyDate: element.initialGuaranteeDate,
          lastGuaranteeDate: element.lastGuaranteeDate,
          warrantyValidityExpired:
            element.currentExpiredWarranty != null
              ? element.currentExpiredWarranty
              : 0,
          quotaSum: element.quotaAffiliate,
          totalCurrentWarranty: element.fullCurrentGuarantee,
          suggestedMove:
            element.sugestAndMove != null ? element.sugestAndMove : 0,
          movementValue: "",
        };
        this.personList.push(exhibition);
      });
    }

    let exhibition = {
      afilliates: "NUEVO CUPO",
      product: "",
      state: "",
      assignment: "",
      quota: this.infoStudyQuote.controls.quotaDR.value
        ? this.infoStudyQuote.controls.quotaDR.value
        : 0,
      fullGuarantee: "",
      totalCurrentGuarantee: "",
      initialWarrantyDate: "",
      lastGuaranteeDate: "",
      warrantyValidityExpired: "",
      quotaSum: this.infoStudyQuote.controls.quotaDR.value
        ? this.infoStudyQuote.controls.quotaDR.value
        : 0,
      totalCurrentWarranty: "",
      suggestedMove: "CUPO",
      movementValue: "",
    };
    this.personList.push(exhibition);
    localStorage.setItem("currentExhibition", JSON.stringify(this.personList));
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.infoStudyQuote.controls;
  }

  updateList(id: number, property: string, event: any) {
    let valueBigger = false;
    let val = 0;
    if (
      property == "movementValue" &&
      event.target.textContent == this.editField &&
      this.idValue == id
    ) {
      if(this.personList[id]["suggestedMove"] === this.propertySelectSave || this.personList[id]["suggestedMove"] == 0){
        this.personList[id]["suggestedMove"] = this.propertySelectSave;
      }
      
      this.editField = this.editField.replace(".", "");
      this.editField = this.editField.replace(".", "");
      if (this.propertySelectSave == "AUMENTO") {
        val = Number(this.personList[id]["quota"]) + Number(this.editField);
      } else {
        if (Number(this.personList[id]["quota"]) >= Number(this.editField)) {
          val = Number(this.personList[id]["quota"]) - Number(this.editField);
        } else {
          Swal.fire({
            type: "error",
            title: "Error",
            text:
              "No se puede ingresar un valor mayor al cupo " +
              this.personList[id]["quota"]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
          }).then(() => {});
          val = Number(
            this.personList[id]["movementValue"] != ""
              ? Number(this.personList[id]["quota"]) -
                  Number(this.personList[id]["movementValue"])
              : Number(this.personList[id]["quota"])
          );
          valueBigger = true;
        }
      }

      this.personList[id]["quotaSum"] = this.fieldPropertyAdd(val.toString());
      this.updateQuota();
    }

    if (this.propertySaved == property && this.idValue == id) {
      if (!valueBigger) {
        this.personList[id][property] = this.fieldPropertyAdd(this.editField);
        if (this.propertySaved == "quota") {
          this.personList[id]["quotaSum"] = this.fieldPropertyAdd(
            this.editField
          );
        }
        this.updateQuota();
        //this.infoStudyQuote.controls.currentExhibition.setValue(this.personList);
        localStorage.setItem(
          "currentExhibition",
          JSON.stringify(this.personList)
        );
        return (event.target.textContent = this.editField
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, "."));
      } else {
        return (event.target.textContent = this.personList[id][property]
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, "."));
      }
    }
  }

  changeValueSelect(id: number, event: any) {
    this.personList[id]["quotaSum"] = this.personList[id]["quota"];

    this.personList[id]["movementValue"] = " ";
    this.personList[id]["suggestedMove"] = event.target.value;
    console.log(this.personList[id]);
    this.propertySelectSave = event.target.value;
  }

  fieldPropertyAdd(value: string): string {
    return value;
  }

  changeValue(id: number, property: string, event: any) {
    this.idValue = id;
    this.editField = event.target.textContent;
    this.propertySaved = property;
  }

  updateQuota() {
    let finalQuota = 0;
    let iniQuota = 0;
    let quotaSuggested = 0;
    this.personList.forEach((element) => {
      iniQuota = iniQuota + Number(element["quota"]);
      if (element.afilliates != "NUEVO CUPO") {
        quotaSuggested =
          quotaSuggested +
          (element["movementValue"] != ""
            ? Number(element["movementValue"])
            : 0);
      }
    });
    this.personList[this.personList.length - 1]["quotaSum"] =
      (this.personList[this.personList.length - 1]["quota"] != ""
        ? Number(this.personList[this.personList.length - 1]["quota"])
        : 0) + quotaSuggested;
    this.personList.forEach((element) => {
      finalQuota = finalQuota + Number(element["quotaSum"]);
    });
    localStorage.setItem("currentExhibition", JSON.stringify(this.personList));
    this.infoStudyQuote.controls.quotaCEFE.setValue(finalQuota);
    this.infoStudyQuote.controls.quotaCE.setValue(iniQuota);
  }
}
