export class PhoneAsrTemp {
  id: number;
  sequence: number;
  idContract: number;
  queryCodeId: number;
  indicative: number;
  contactNumber: number;
  phoneType: string;
  extension: number;
  department: number;
  city: number;
  departmentName: string;
  cityName: string;

  /*DATA IN MEMORY */
  documentType: string;
  document: number;
  countryCode: number;

  check: boolean;
}
