import { Component, Input, OnInit } from '@angular/core';
import { DisagreementWithInformation } from '../../services/natural-person-adapter.service';

@Component({
  selector: 'app-disagreements-with-information-generic',
  templateUrl: './disagreements-with-information-generic.component.html',
  styleUrls: ['./disagreements-with-information-generic.component.scss']
})
export class DisagreementsWithInformationGenericComponent implements OnInit {

  public headersTable: string[] = ['Desacuerdo', 'No. Desacuerdo', 'Estado', 'Fecha', 'Entidad'];
  @Input() disagreementsWithInformation: DisagreementWithInformation[] = [];

  constructor() { }

  ngOnInit() {
  }

}
