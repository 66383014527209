import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-history',
  templateUrl: './summary-history.component.html',
  styleUrls: ['./summary-history.component.scss']
})
export class SummaryHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
