// globals.ts
import { Injectable } from '@angular/core';
import { BackupsGlobal } from './backups/backups.global';
import { MarkNonPaymentGlobal } from './mark-non-payment/markNonpayment.global';
import { CommonGlobals } from './common/common.global';


@Injectable()
export class Globals {
  
  public backupsGlobal = BackupsGlobal;
  public markNonPaymentGlobal = MarkNonPaymentGlobal;
  public commonGlobal = CommonGlobals;

  constructor() {}
  
}
