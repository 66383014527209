import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IncomeRecoveryHeadersService } from '../services/income-recovery-headers.service';

@Component({
  selector: 'app-income-recovery-cancellations',
  templateUrl: './income-recovery-cancellations.component.html',
  styleUrls: ['./income-recovery-cancellations.component.scss']
})
export class IncomeRecoveryCancellationsComponent implements OnInit, OnChanges {
  @Input() tableHeaders: Array<string> = [];
  @Input() dataTable: Array<any> = [];
  @Input() pageData: any = {};

  @Output() handleCurrentPage: EventEmitter<any> = new EventEmitter();

  public totalPagesAddress = 1;

  constructor(public incomeRecovery: IncomeRecoveryHeadersService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPagesAddress = this.pageData.totalPages
  }

  goToPage($event) {
    this.handleCurrentPage.emit($event.currentPage)
  }
}
