import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditHistoryService } from '../../services/credit-history.service';
import { IQueryForm } from '../../components/query-form-history/query-form-history.component';
import { GetCreditHistoryRequest, GetCreditHistoryResponse } from '../../models/credit-history.model';
import { HelperService } from '@app/services/helper.service';
import Swal from 'sweetalert2';
import { IResponseCodeExperian } from '../../constants/global-credit-info';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-legal-person',
  templateUrl: './legal-person.component.html',
  styleUrls: ['./legal-person.component.scss']
})
export class LegalPersonComponent implements OnInit, AfterViewInit {
  public showResults: boolean = false;

  public legalData: any;

  // Scroll
  @ViewChild("legalRepresentationTable") legalRepresentationTable: ElementRef<HTMLElement>;
  @ViewChild("legalCreditHistoryTable") legalCreditHistoryTable: ElementRef<HTMLElement>;
  @ViewChild("legalPaymentHabitTable") legalPaymentHabitTable: ElementRef<HTMLElement>;
  @ViewChild("legalOpenObligationsTable") legalOpenObligationsTable: ElementRef<HTMLElement>;
  @ViewChild("legalHistoricQueryTable") legalHistoricQueryTable: ElementRef<HTMLElement>;
  @ViewChild("legalPaymentHabitResumeTable") legalPaymentHabitResumeTable: ElementRef<HTMLElement>;
  @ViewChild("legalDebtLevelTable") legalDebtLevelTable: ElementRef<HTMLElement>;
  @ViewChild("legalGlobalDebtClassifiedTable") legalGlobalDebtClassifiedTable: ElementRef<HTMLElement>;
  @ViewChild("legalBasicInformationTable") legalBasicInformationTable: ElementRef<HTMLElement>;
  @ViewChild("legalScoresTable") legalScoresTable: ElementRef<HTMLElement>;

  public scrollButtons: Array<{
    description: string;
    target: HTMLElement | string;
  }> = [];

  // offset page
  public isShowAnFixedBar: boolean;
  topPosToStartShowing = 100;
  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // console.log('scrollPosition', scrollPosition);
    if (window && window.pageYOffset >= 110) {
      this.isShowAnFixedBar = true;
    } else {
      this.isShowAnFixedBar = false;
    }
  }
  // ./Scroll

  constructor(
    private spinner: NgxSpinnerService,
    private creditHistoryService: CreditHistoryService,
    public helper: HelperService,
    private helperCreditInfo: HelperCreditInfoService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // this.handleInitializeScrollButtons();
  }

  handleInitializeScrollButtons() {
    this.scrollButtons = [
      {
        description: "Existencia y representación legal",
        target: this.legalRepresentationTable.nativeElement,
      },
      {
        description: "Historia de crédito",
        target: this.legalCreditHistoryTable.nativeElement,
      },
      {
        description: "Estado hábito de pago obligaciones abiertas/vigentes",
        target: this.legalPaymentHabitTable.nativeElement,
      },
      {
        description: "Saldos, cupos y valores de obligaciones abiertas/vigentes",
        target: this.legalOpenObligationsTable.nativeElement,
      },
      {
        description: "Consultado por",
        target: this.legalHistoricQueryTable.nativeElement,
      },
      {
        description: "Resumen de hábito de pago",
        target: this.legalPaymentHabitResumeTable.nativeElement,
      },
      {
        description: "Nivel de endeudamiento y valores de obligaciones abiertas/vigentes",
        target: this.legalDebtLevelTable.nativeElement,
      },
      {
        description: "Endeudamiento global clasificado",
        target: this.legalGlobalDebtClassifiedTable.nativeElement
      },
      {
        description: "Información básica",
        target: this.legalBasicInformationTable.nativeElement,
      },
      {
        description: "Scores",
        target: this.legalScoresTable.nativeElement,
      },
    ];
  }

  handleSubmitQueryLegalPersonForm(queryForm: IQueryForm) {
    const { documentType, document, lastname, includeScore } = queryForm;
    const request: GetCreditHistoryRequest = {
      identificationType: documentType,
      identification: document,
      firstSurname: lastname,
      force: false,
      service: includeScore ? 'CONSULTAR_HC2_SCORE_PJ' : 'CONSULTAR_HC2_PJ'
    };
    this.getLegalPersonData(request);
  }

  getLegalPersonData(request: any) {
    this.spinner.show();
    this.creditHistoryService.getLegalPersonData(request).subscribe((response: any) => {
      // console.log(response)
      this.spinner.hide();
      // this.showResults = true;
      // this.legalData = response;
      this.handleValidateError(response);
      console.log('Legal Data:', this.legalData, response);
    }, (error: any) => {
      this.handleServiceError(error);
    });
  }

  handleValidateError(response: any) {
    //     const { creditHistory, globalDebt, levelOfIndebtednessAndOpenDebentureValues, portfolioAccount, summariesOfPaymentHabits } = response;
    // if (
    //   !this.handleIsEmptyObjet(creditHistory) &&
    //   !this.handleIsEmptyObjet(globalDebt) &&
    //   !this.handleEmptyArray(levelOfIndebtednessAndOpenDebentureValues) &&
    //   !this.handleIsEmptyObjet(portfolioAccount) &&
    //   !this.handleEmptyArray(summariesOfPaymentHabits)) {

    const { codigoRespuesta = '' } = response;
    const responseCode: IResponseCodeExperian = this.helperCreditInfo.findResponseCodeFromHdcAndPj(codigoRespuesta);

    if (responseCode.isValid) {
      console.log('ok');
      this.legalData = response;
      this.showResults = true;
      this.spinner.hide();
      // console.log('Legal data*****', this.legalData);
    } else {
      console.log('error al consultar');
      this.showResults = false;
      Swal.fire({
        type: 'error',
        title: 'Alerta:',
        html: `No hay resultados para la consulta realizada <br/><br/> Error HDC+PJ_${responseCode.code}: ${responseCode.description}`,
      });
      this.spinner.hide();
    }
  }

  handleServiceError(error: any) {
    console.log('Error al procesar servicio de HDC+PJ', error);
    this.spinner.hide();
    this.showResults = false;
    this.legalData = {};

    Swal.fire({
      type: 'error',
      title: 'Alerta:',
      html: `Este servicio no se encuentra disponible en este momento.`,
    });
  }

  handleIsEmptyObjet(obj: any) {
    return Object.keys(obj).length === 0;
  }

  handleEmptyArray(array: any) {
    return array.length === 0;
  }

}













