import { Component, Input, OnInit } from '@angular/core';
import { ExpirianListsService } from '../../services/expirian-lists.service';
import { PaymentHabitOfActiveObligations, PaymentHabitOfInactiveObligations } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-payment-habit-table',
  templateUrl: './legal-payment-habit-table.component.html',
  styleUrls: ['./legal-payment-habit-table.component.scss']
})
export class LegalPaymentHabitTableComponent implements OnInit {

  @Input() portfolioAccountDataTable: any;
  @Input() paymentHabitOfActiveObligations: PaymentHabitOfActiveObligations[] = [];
  @Input() paymentHabitOfInactiveObligations: PaymentHabitOfInactiveObligations[] = [];
  @Input() isAPerson: boolean = false;

  experianLists: any;
  openObligations: any = {};
  closedObligations: any = {};

  constructor(private experianListService: ExpirianListsService) { }

  ngOnInit() {
    console.log(this.portfolioAccountDataTable)
    this.experianListService.getExpirianLists().subscribe((response) => {
      this.experianLists = response;
      // console.log('Listas de Experian:', this.experianLists);

      this.organizeDataBySector();
    });
  }

  ngOnChanges() {
    // console.log(this.portfolioAccountDataTable)
  }

  organizeDataBySector() {
    if (this.portfolioAccountDataTable && Array.isArray(this.portfolioAccountDataTable)) {
      this.openObligations = {};
      this.closedObligations = {};
      const estadoCuentaList = this.experianLists.data[0]['estado-cuenta'];
      const openStatusCodes = ['01', '02', '03', '04', '05', '06', '07'];
      const closedStatusCodes = ['00', '08', '09', '10', '11', '12', '13', '14', '15', '16'];
      this.portfolioAccountDataTable.forEach(item => {
        // console.log(item)
        const sector = item.sector;
        const estadoCuenta = item.Estados.EstadoCuenta.codigo;

        if (!this.openObligations[sector]) this.openObligations[sector] = [];
        if (!this.closedObligations[sector]) this.closedObligations[sector] = [];

        if (openStatusCodes.includes(estadoCuenta)) {
          this.openObligations[sector].push(item);
        } else if (closedStatusCodes.includes(estadoCuenta)) {
          this.closedObligations[sector].push(item);
        }
      });
    }
  }
}
