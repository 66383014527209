import { Component, OnInit } from '@angular/core';
import { SummaryBalancesAndArrears, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-table-summary-balances-and-arrears-history',
  templateUrl: './table-summary-balances-and-arrears-history.component.html',
  styleUrls: ['./table-summary-balances-and-arrears-history.component.scss']
})
export class TableSummaryBalancesAndArrearsHistoryComponent implements OnInit {

  public debtTendency: SummaryBalancesAndArrears = { headers: [], records: [] };


  get getHeaders(): string[] {
    return this.debtTendency.headers;
  }

  get getRecords() {
    return this.debtTendency.records;
  }

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.debtTendency = this.subjectCreditHistory.getSummaryBalancesAndArrears(response);
  }

}
