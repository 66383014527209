import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubjectService } from '@app/services/subject.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { RelatedDocumentsHeadersService } from './services/related-documents-headers.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { ModalService } from '@app/services/modal.service';
import { CoviResourceService } from '@app/services/covi-resource.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { HelperService } from '@app/services/helper.service';
import { RelatedDocumentsService } from './services/related-documents.service';
import { QuotaHeadingTablesService } from '../../services/quota-heading-tables.service';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-related-documents',
  templateUrl: './related-documents.component.html',
  styleUrls: ['./related-documents.component.scss']
})
export class RelatedDocumentsComponent implements OnInit, OnChanges {

  @ViewChild('formRelatedDocuments') formRelatedDocuments: NgForm;

  @Input() documentInfo: any = {};

  @Input() citiesAndDepartments: any = [];

  public contactDataList: Array<any> = [];

  @Input() contactAddressList: Array<any> = [];

  public contactEmailList: Array<any> = [];

  public isContactPhone: boolean = false;

  public isResetAddressForm: boolean = false;

  public totalPagesPhones = 1;

  public currentPagePhones = 1;

  public totalPagesAddress = 1;

  public currentPageAddress = 1;

  public totalPagesRepeatedPhones = 1;

  public currentPageRepeatedPhones = 1;

  public totalPagesEmail = 1;

  public currentPageEmail = 1;

  public phoneRepeated;

  public selectedRow;

  public selectedEmailRow;

  public rowList: Array<boolean> = [];

  public rowEmailList: Array<boolean> = [];

  repeatedPhonesData: Array<any> = [];

  public docTypes: Array<any> = [];

  public bodyFormRelatedDocuments = {
    documentType: '',
    document: '',
  }

  constructor(
    public headingTable: RelatedDocumentsHeadersService,
    private listCovinoc: ListsCovinocService, 
    private modal: ModalService, 
    private quotaGreater: RelatedDocumentsService, 
    private spinner: NgxSpinnerService, 
    public headers: QuotaHeadingTablesService, 
    public helper: HelperService, 
    public subject: SubjectService, 
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getDocTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    
    this.bodyFormRelatedDocuments = {
      documentType: this.documentInfo.documentType,
      document: this.documentInfo.document
    }
  }

  public getDocTypes() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
    });
  }

  public selectRow(id) {
    this.contactAddressList.forEach((item, key) => {
      if (id === key) {
        this.selectedRow = {
          tipoDocumentoComprador: item.documentType,
          documentoComprador: item.document,
          tipoDocumentoRelacionado: item.documentTypeRelated,
          documentoRelacionado: item.documentRelated,
          relacion: item.relation,
          estado: 'VIGENTE'
        };
        this.rowList[key] = true;
      } else {
        this.rowList[key] = false;
      }
    });
  }

  updateRelatedDocument(id: number, $event) {
    const relatedDocumentSelected = {
      documentType: this.contactAddressList[id].documentType,
      document: this.contactAddressList[id].document,
      relatedDocumentType: this.contactAddressList[id].documentTypeRelated,
      relatedDocument: this.contactAddressList[id].documentRelated,
      name: this.contactAddressList[id].name,
      relation: this.contactAddressList[id].relation,
      state: $event.target.value
    }
    

    this.quotaGreater.createRelatedDocument(relatedDocumentSelected).subscribe((response: any) => {
      this.spinner.hide();
      this.getRelatedDocuments();
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
      this.modal.close();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  getRelatedDocuments() {
    this.spinner.show();
    const bodyParams = {
      tipoDocumento: this.bodyFormRelatedDocuments.documentType,
      documento: this.bodyFormRelatedDocuments.document,
      page: 1,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getRelatedDocuments(bodyParams).subscribe((response: any) => {

      this.spinner.hide();
      this.totalPagesAddress = response.totalPages;
      this.contactAddressList = response.data;
      this.contactDataList.forEach((ite, key) => {
        this.rowList[key] = false;

      });
      this.selectedRow = undefined;
    }, (error) => {
      this.contactAddressList = null;
      this.spinner.hide();
      this.toastr.error(error.error.message)
    });
  }

  handleDeleteRelatedDocument() {
    this.modal.show("confirmDeleteRelatedDocument")
  }

  showCreateRelatedDocumentModal() {
    this.modal.show('createRelatedDocument');
  }

  handleConfirmDeleteRelatedDocument() {
    this.spinner.show();
    const bodyParams = {
      ...this.selectedRow
    }

    this.quotaGreater.deleteRelatedDocument(bodyParams).subscribe((response: any) => {
      this.spinner.hide();
      this.modal.close();
      this.getRelatedDocuments();
      this.toastr.success(response.message)
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  showCreateAddressModal() {
    this.subject.changeHandleIsResetAddress(true);
    this.modal.show('createAddress');
  }

  showCreateEmailModal() {
    this.modal.show('createEmail');
  }

  goToPageAddress($event) {
    const bodyParams = {
      tipoDocumento: this.documentInfo.documentType,
      documento: this.documentInfo.document,
      page: $event.currentPage,
      pageSize: 10,
    }

    this.isContactPhone = true;
    this.quotaGreater.getRelatedDocuments(bodyParams).subscribe((response: any) => {
      this.currentPageAddress = response.currentPage;
      this.totalPagesAddress = response.totalPages;
      this.contactAddressList = response.data;
    });
  }
}
