import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { ProfileCurrent } from "@app/pages/quota-radication/models/response/responseProfileRequest";
import { Observable } from "rxjs";
import {
  Response,
  ResponseObject,
} from "@app/models/responseUtil.model";
import { RequestTypeAndStatus } from "@app/pages/quota-radication/models/response/responseStatusTypeRequest";
import { CreateProfileRequest } from "@app/pages/quota-radication/models/request/CreateProfileRequest.modal";
import { UserProfile } from "@app/pages/quota-radication/models/userProfile.model";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class RadicationQuotaAdminService {
  private urlApi: string;
  private headers = new HttpHeaders().set("Content-Type", "application/json");

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlRequest;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlRequest} = apis
      this.urlApi = urlRequest;
       console.log('config ci cd', apiUrl);
    });
  }

  public createProfileRequest: CreateProfileRequest = {
    profile: {
      idProfile: null,
      profileName: "",
      isActive: "1",
    },
    requestType: [],
    requestStatus: [],
  };

  public userProfile: UserProfile = {
    userName: "",
    profiles: [],
  };

  getProfileCurrent(
    currentPage: number,
    perPage: number
  ): Observable<Response<ProfileCurrent>> {
    const params = {
      currentPage: currentPage.toString(),
      perPage: perPage.toString(),
    };
    const options = {
      headers: this.headers,
      params: params,
    };
    return this.http
      .get(`${this.urlApi}userProfile/getProfileCurrent`, options)
      .pipe(
        map((resp: Response<ProfileCurrent>) => {
          return resp;
        })
      );
  }

  getStatusTypeRequest(): Observable<ResponseObject<RequestTypeAndStatus>> {
    const options = {
      headers: this.headers,
    };
    return this.http
      .get(`${this.urlApi}requestSettlements/typeAndStatusAll`, options)
      .pipe(
        map((resp: ResponseObject<RequestTypeAndStatus>) => {
          return resp;
        })
      );
  }

  deleteProfile(id: number): Observable<ResponseObject<any>> {
    const options = {
      headers: this.headers,
    };
    return this.http
      .delete(`${this.urlApi}userProfile/deleteProfile/${id}`, options)
      .pipe(
        map((resp: ResponseObject<any>) => {
          return resp;
        })
      );
  }

  saveCreateNewProfile(
    profile: CreateProfileRequest
  ): Observable<ResponseObject<any>> {
    const options = {
      headers: this.headers,
    };
    const body = profile;
    return this.http
      .post(`${this.urlApi}adminRequest/saveProfile`, body, options)
      .pipe(
        map((resp: ResponseObject<any>) => {
          return resp;
        })
      );
  }

  saveAssigProfileUser(userProfile: UserProfile): Observable<Response<any>> {
    const options = {
      headers: this.headers,
    };
    const body = userProfile;
    return this.http.post(`${this.urlApi}userProfile/save`, body, options).pipe(
      map((resp: Response<any>) => {
        return resp;
      })
    );
  }
}
