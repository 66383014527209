import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-visualize',
  templateUrl: './modal-visualize.component.html',
  styleUrls: ['./modal-visualize.component.scss']
})
export class ModalVisualizeComponent implements OnInit {
  @Input() pdfSrc: string;
  @Output() closeModal  =  new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeModal.emit(false);
  }

}
