import { Component, Input, OnInit } from '@angular/core';
import { HabitOfPaymentActive } from '../../services/subject-credit-history.service';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-table-habit-of-payment-active-history',
  templateUrl: './table-habit-of-payment-active-history.component.html',
  styleUrls: ['./table-habit-of-payment-active-history.component.scss']
})
export class TableHabitOfPaymentActiveHistoryComponent implements OnInit {

  @Input() habitOfPaymentActive: HabitOfPaymentActive = {} as HabitOfPaymentActive;

  public headersTable: string[] = ['Entidad Informante', 'Tipo cuenta', 'Número Cuenta 9 dígitos', 'Calificación', 'Estado de la obligación', 'Fecha autorización',
    'Adjetivo-fecha', 'Apertura', 'Vencimiento', 'Mora máxima', '47 meses',
    'Desacuerdo con la información', 'Estado del titular', 'Marca/clase', 'Tipo garantía', 'Valor o cupo inicial', 'Saldo Actual (Miles $)',
    'Saldo en Mora (Miles $)', 'Valor Cuota (Miles $)', 'Fecha límite pago', 'Fecha del pago', 'Perm.', '# Cheq Devueltos',
    'Cuotas/M/Vigencia', '% deuda', 'Oficina/deudor'
  ];


  get getSector(): string {
    return this.habitOfPaymentActive.sector;
  }

  get getAccounts() {
    return this.habitOfPaymentActive.accounts;
  }

  constructor(
    private helperCreditInfo: HelperCreditInfoService
  ) { }

  ngOnInit() {
  }

  getWarrantyName(warrantyCode: string): string {
    return this.helperCreditInfo.findWarrantyOfHabitOfPaymentOfCurrentObligation(warrantyCode).name;
  }

}
