import { Component, Input, OnInit } from '@angular/core';
import { DebtEvolution } from '../../services/subject-credit-history.service';

@Component({
  selector: 'app-table-debt-evolution-history',
  templateUrl: './table-debt-evolution-history.component.html',
  styleUrls: ['./table-debt-evolution-history.component.scss']
})
export class TableDebtEvolutionHistoryComponent implements OnInit {

  @Input() debtEvolution: DebtEvolution = { headers: [], accounts: [], sector: '' };

  get getHeaders(): string[] {
    return this.debtEvolution.headers;
  }

  get getSector() {
    return this.debtEvolution.sector;
  }

  get getAccount() {
    return this.debtEvolution.accounts;
  }

  constructor() {
  }

  ngOnInit() {
  }

  isNumber(value: string | number): boolean {
    return !isNaN(Number(value));
  }

}
