import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '@models/user.model';
import { of } from 'rxjs';
import { SelectModel, BillingCuts,
  NumberInstallmentAmortizations,
  NumberInstallments,
  SelectModelPeriod,
  StatusBillings,
  YesOrNot, } from '@app/models/select.model';
import { map, pluck } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: "root",
})
export class SelectsService {
  public test = { label: "Cédula de ciudadanía", value: "C" };

  public dataDocumentTypes = [
    { label: "Cédula de ciudadanía", value: "C" },
    { label: "Cédula de extranjería", value: "E" },
    { label: "Nit", value: "N" },
    { label: "Pasaporte", value: "P" },
    { label: "Tarjeta identidad", value: "T" },
  ];

  public dataSocietyTypes = [
    { label: "S.C. Accionest", value: "N" },
    { label: "S.C. Simple", value: "N" },
    { label: "Consorcio", value: "N" },
    { label: "Limitada", value: "N" },
    { label: "PN Comerciante", value: "N" },
    { label: "S. Anónima", value: "N" },
    { label: "S. Colectiva", value: "N" },
    { label: "S.A.S", value: "N" },
    { label: "Unión Temporala", value: "N" },
    { label: "I.E.S", value: "N" },
    { label: "E. Unipersonal", value: "N" },
  ];

  public dataBanks = [
    { label: "banco de occidente", value: "N" },
    { label: "banco agrario", value: "N" },
    { label: "banco av villas", value: "N" },
    { label: "banco bbva", value: "N" },
    { label: "banco caja social", value: "N" },
    { label: "banco coomeva1banco de bogotá", value: "N" },
    { label: "banco falabella7banco gnb sudameris", value: "N" },
    { label: "banco pichincha2banco popular", value: "N" },
    { label: "banco w5bancolombia citibank", value: "N" },
    { label: "colpatria", value: "N" },
    { label: "corpbanca", value: "N" },
    { label: "davivienda", value: "N" },
    { label: "itaú", value: "N" },
    { label: "scotiabank", value: "N" },
  ];

  public dataAccountTypes = [
    { label: "Cta. Ahorros", value: "Cta. Ahorros" },
    { label: "Cta. Corriente", value: "Cta. Corriente" },
  ];

  public dataDeclaringClass = [
    { label: "Contribuyente", value: "Contribuyente" },
    { label: "No Contribuyente", value: "No Contribuyente" },
    {
      label: "Régimen tributario especial",
      value: "Régimen tributario especial",
    },
    { label: "Gran contribuyente", value: "Gran contribuyente" },
  ];

  public dataIva = [
    { label: "Común", value: "Común" },
    { label: "Simplificado", value: "Simplificado" },
    { label: "No responsable", value: "No responsable" },
  ];

  public dataPep: any = [
    {
      id: "¿Maneja recursos públicos?",
      description: "¿Maneja recursos públicos?",
      check: false,
    },
    {
      id: "¿Por su actividad u oficio tiene reconocimiento político general?",
      description:
        "¿Por su actividad u oficio tiene reconocimiento político general?",
      check: false,
    },
    {
      id: "¿Por su cargo o actividad ejerce algún grado de poder público?",
      description:
        "¿Por su cargo o actividad ejerce algún grado de poder público?",
      check: false,
    },
    {
      id: "¿Existe algún vínculo entre usted y una persona considerada públicamente expuesta?",
      description:
        "¿Existe algún vínculo entre usted y una persona considerada públicamente expuesta?",
      check: false,
    },
    {
      id: "¿Obligado a cumplir prevención LAFT?",
      description: "¿Obligado a cumplir prevención LAFT?",
      check: false,
    },
  ];

  public listaCodigoDane = [
    { codigoDane: "código DANE 1", value: "5001" },
    { codigoDane: "código DANE 2", value: "5002" },
    { codigoDane: "código DANE 3", value: "5004" },
    { codigoDane: "código DANE 4", value: "5001" },
  ];

  public roleAssignementList = [
    { roleAssignement: "super Usuario", value: "1" },
    { roleAssignement: "Administrador", value: "2" },
    { roleAssignement: "Operador", value: "3" },
  ];

  public urlApi: string;
  public dev07ApiUtl: string;
  public userToken: string;
  public identity: UserModel;

  public documentTypes: SelectModel[] = [
    { value: "N", description: "Nit", state: "show" },
    { value: "C", description: "Cédula de ciudadanía", state: "show" },
    { value: "E", description: "Cédula de extranjería", state: "show" },
    // { value: 'P', description: 'Pasaporte' },
  ];

  public typeDocument: Array<SelectModel> = [
    {
      value: "C",
      description: "Cedula de Ciudadanía",
    },
    {
      value: "E",
      description: "Cedula de Extranjería",
    },
    {
      value: "N",
      description: "NIT",
    },
  ];

  public searchAffiliateBy: SelectModel[] = [
    { value: "document", description: "Documento", state: "show" },
    { value: "socialReason", description: "Razón social", state: "show" },
    { value: "contract", description: "Contrato", state: "show" },
    // { value: 'queryPoint', description: 'Punto de Consulta' },
    // { value: 'branchOffice', description: 'Sucursal' },
  ];

  public optionsStateTitleValue: SelectModel[] = [
    { value: "ACTIVO", description: "Activo", state: "show" },
    { value: "DEVUELTO", description: "Devuelto", state: "show" },
    { value: "REVISADO", description: "Revisado", state: "show" },
    // { value: "AUTORIZADO DE PAGO", description: "Autorizado de pago" }
  ];

  public optionsBackingsState: SelectModel[] = [
    { value: "ACTIVO", description: "Activo", state: "show" },
    { value: "DEVUELTO", description: "Devuelto", state: "show" },
    { value: "REVISADO", description: "Revisado", state: "show" },
    // { value: "AUTORIZADO DE PAGO", description: "Autorizado de pago" }
  ];

  public optionsBackingsToProcessFilterState: SelectModel[] = [
    { value: "ACTIVO", description: "Activo", state: "show" },
    { value: "REVISION", description: "Revisión", state: "show" },
    { value: "OTROS-ESTADOS", description: "Otros Estados", state: "show" },
  ];

  public optionsBranchOffices: SelectModel[] = [
    { value: "NACIONAL", description: "NACIONAL", state: "show" },
    { value: "ARMENIA", description: "ARMENIA", state: "show" },
    { value: "BARRANQUILLA", description: "BARRANQUILLA", state: "show" },
    { value: "BOGOTA", description: "BOGOTA", state: "show" },
    { value: "BUCARAMANGA", description: "BUCARAMANGA", state: "show" },
    { value: "CALI", description: "CALI", state: "show" },
    { value: "CARTAGENA", description: "CARTAGENA", state: "show" },
    { value: "CUCUTA", description: "CUCUTA", state: "show" },
    { value: "MANIZALES", description: "MANIZALES", state: "show" },
    { value: "MEDELLIN", description: "MEDELLIN", state: "show" },
    { value: "PASTO", description: "PASTO", state: "show" },
    { value: "PEREIRA", description: "PEREIRA", state: "show" },
    { value: "VILLAVICENCIO", description: "VILLAVICENCIO", state: "show" },
  ];

  public reservationStateList: any = [
    { value: "VIGENTE", description: "VIGENTE" },
    { value: "UTILIZADA", description: "UTILIZADA" },
    { value: "VENCIDA", description: "VENCIDA" },
    { value: "ANULADA", description: "ANULADA" },
  ];

  public installmentPeriod: Array<SelectModelPeriod> = [
    { value: "mensual", description: "MENSUAL" },
    { value: "anual", description: "ANUAL" },
  ];

  public installmentPeriodBag: Array<SelectModelPeriod> = [
    { value: "mensual", description: "MENSUAL" },
    { value: "bimensual", description: "BIMENSUAL" },
    { value: "trimestral", description: "TRIMESTRAL" },
    { value: "cuatrimestral", description: "CUATRIMESTRAL" },
    { value: "semestral", description: "SEMESTRAL" },
    { value: "anual", description: "ANUAL" },
  ];
  public numberInstallments: Array<NumberInstallments> = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 11 },
    { id: 11, value: 12 },
  ];
  public billingCuts: Array<BillingCuts> = [
    { id: 1, value: 0 },
    { id: 2, value: 1 },
    { id: 3, value: 2 },
    { id: 4, value: 3 },
  ];

  public billingRulesCut: Array<BillingCuts> = [
    { id: 2, value: 1 },
    { id: 3, value: 2 },
    { id: 4, value: 3 },
  ];

  public statusBillings: Array<StatusBillings> = [
    {id: 1,description: "ACTIVA"},
    {id: 2,description: "INACTIVA"},
    {id: 3,description: "SUSPENDIDA"},
  ];

  public selectYesNot: Array<YesOrNot> = [
    {id: 1,description: "SI"},
    {id: 2,description: "NO"},
  ];

  public branchIncome: Array<YesOrNot> = [
    {id: 1,description: "NO"},
    {id: 2,description: "FIJO POR SUCURSAL"},
    //{id: 3,description: "VARIABLE POR CONSUMO (GARANTÍA)"},
  ];

  public numberInstallmentAmortizations: Array<NumberInstallmentAmortizations> =
    [
      { id: 1, value: 1 },
      { id: 2, value: 2 },
      { id: 3, value: 3 },
      { id: 4, value: 4 },
      { id: 5, value: 5 },
      { id: 6, value: 6 },
      { id: 7, value: 7 },
      { id: 8, value: 8 },
      { id: 9, value: 9 },
      { id: 10, value: 10 },
      { id: 11, value: 11 },
      { id: 12, value: 12 },
      { id: 13, value: 13 },
      { id: 14, value: 14 },
      { id: 15, value: 15 },
      { id: 16, value: 16 },
      { id: 17, value: 17 },
      { id: 18, value: 18 },
      { id: 19, value: 19 },
      { id: 20, value: 20 },
      { id: 21, value: 21 },
      { id: 22, value: 22 },
      { id: 23, value: 23 },
      { id: 24, value: 24 },
    ];
  public numberInstallmentBillings: Array<NumberInstallmentAmortizations> = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 11, value: 11 },
    { id: 12, value: 12 },
    { id: 13, value: 13 },
    { id: 14, value: 14 },
    { id: 15, value: 15 },
    { id: 16, value: 16 },
    { id: 17, value: 17 },
    { id: 18, value: 18 },
  ];

  public periodsMonth: number[] = [
    6,7,8,9,10,11,12,13,14,15,16,17,18
  ];

  constructor(private http: HttpClient, public helper: HelperService,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.apiUrl;
    this.dev07ApiUtl = environment.dev07ApiUtl
    this.getSelects();
    this.readToken();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, dev07ApiUtl} = apis;
      this.dev07ApiUtl = dev07ApiUtl
       console.log('config ci cd', apiUrl);
       this.urlApi = apiUrl;
    });
  }

  readToken() {
    this.userToken = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    return this.userToken;
  }

  getBackingsState() {
    const state = of(this.optionsBackingsState);
    return state;
  }

  getBranchOffices() {
    const branchOffices = of(this.optionsBranchOffices);
    return branchOffices;
  }

  getBackingsToProcessFilterState() {
    const backingsToProcessFilter = of(
      this.optionsBackingsToProcessFilterState
    );
    return backingsToProcessFilter;
  }

  getSelectTypes(selectType: string) {
    const localStorageSelectsObject = JSON.parse(
      localStorage.getItem("selectsASR")
    );
    return selectType && localStorageSelectsObject
      ? of(localStorageSelectsObject[selectType])
      : this.getSelects(selectType);
  }

  getSelects(selectType?: string) {
    const headers = new HttpHeaders(this.helper.getHeadersAuthorization());
    const options = {
      headers,
    };
    return this.http.get<any[]>(`${ this.dev07ApiUtl}/commons/lists`, options).pipe(
      map((listsResponse: any[]) => {
        return selectType
          ? listsResponse["data"][selectType]
          : listsResponse["data"];
      })
    );
  }

  getSelectsWithParams(list: string, value: string) {
    const headers = new HttpHeaders(this.helper.getHeadersAuthorization());

    const options = {
      headers,
      params: { list, value },
    };

    return this.http.get<any[]>(`${ this.dev07ApiUtl}/commons/lists`, options).pipe(
      map((listsResponse: any[]) => {
        return list ? listsResponse["data"][list] : listsResponse["data"];
      })
    );
  }

  getSelectData() {
    const headers = new HttpHeaders(this.helper.getHeadersAuthorization());
    const options = {
      headers,
    };
    return this.http.get<any[]>(`${ this.dev07ApiUtl}/commons/lists`, options).pipe(
      map((listsResponse: any[]) => {
        return listsResponse["data"];
      })
    );
  }
}
