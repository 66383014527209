import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-quota-without-contract',
  templateUrl: './quota-without-contract.component.html',
  styleUrls: ['./quota-without-contract.component.scss']
})
export class QuotaWithoutContract implements OnInit {

  quotaVsAffiliateSearchForm: FormGroup

  searchBySettlement: Boolean = true;

  isQuerySuccess: boolean = false;

  docTypes: any;

  stateList = [
    {value: "A", description: "Activo"},
    {value: "R", description: "Retirado"},
  ]

  public tabs: Array<any> = [
    {
      title: "Consultar cupo sin contrato",
      active: true,
    },
    {
      title: "Crear cupo sin contrato",
      active: false,
    },
  ];

  quotaData: any[];

  constructor(public helper: HelperService, private formBuilder: FormBuilder, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService) { }

  public get isSearchFormInvalid() {
    return this.quotaVsAffiliateSearchForm.get('documentTypeSearch').invalid ||
    this.quotaVsAffiliateSearchForm.get('documentSearch').invalid;
  }

  public get isCreateFormInvalid() {
    return this.quotaVsAffiliateSearchForm.get('documentType').invalid ||
      this.quotaVsAffiliateSearchForm.get('document').invalid ||
      this.quotaVsAffiliateSearchForm.get('state').invalid ||
      this.quotaVsAffiliateSearchForm.get('quota').invalid ||
      this.quotaVsAffiliateSearchForm.get('available').invalid;
  }

  ngOnInit() {
    this.quotaVsAffiliateSearchForm = this.formBuilder.group({
      documentTypeSearch: ['', Validators.required],
      documentSearch: ['', Validators.required],
      documentType: ['', Validators.required],
      document: ['', Validators.required],
      state: ['', Validators.required],
      quota: ['', Validators.required],
      available: ['', Validators.required],
    })

    this.quotaGreater.getDocTypes().subscribe((resp: any) => {
      this.docTypes = resp.data
    })
  }

  get getIsQuerySuccess() {
    return this.isQuerySuccess;
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;
  }

  onFindContract() {
    this.spinner.show();
    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('documentTypeSearch').value,
      document: this.quotaVsAffiliateSearchForm.get('documentSearch').value,
    }
    console.log("HERE");
    
    this.quotaGreater.getBuyerQuotasWithoutContract(body).subscribe((resp: any) => {
      this.spinner.hide();
      this.quotaData = resp.data[0];
      /* this.quotaData = this.quotaDummyData.data; */
      this.isQuerySuccess = true;
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  changeState(id, $event) {
    console.log($event.target.value);
    this.quotaData[id].state = $event.target.value;
    console.log(this.quotaData);
    
  }

  changeQuota(id, $event) {
    console.log($event.target.textContent);
    this.quotaData[id].quota = $event.target.textContent;
    console.log(this.quotaData);
  }

  changeAvailable(id, $event) {
    console.log($event.target.textContent);
    this.quotaData[id].available = $event.target.textContent;
    console.log(this.quotaData);
  }

  onSubmitContract() {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;

    if(userUpdate) {
      const body = {
        id: null,
        documentType: this.quotaVsAffiliateSearchForm.get('documentType').value,
        document: this.quotaVsAffiliateSearchForm.get('document').value,
        state: this.quotaVsAffiliateSearchForm.get('state').value,
        date: null,
        quota: this.quotaVsAffiliateSearchForm.get('quota').value,
        available: this.quotaVsAffiliateSearchForm.get('available').value,
        user: userUpdate,
      }
  
      this.quotaGreater.createBuyerQuotasWithoutContract(body).subscribe((resp:any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${resp.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
  }

  onUpdateContract(quota) {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;

    if(userUpdate) {
      const body = {
        id: quota.id,
        state: quota.state,
        quota: quota.quota,
        available: quota.available,
        user: userUpdate
      }
  
      this.quotaGreater.updateBuyerQuotasWithoutContract(body).subscribe((resp:any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${resp.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
  }
}
