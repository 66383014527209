import { Component, OnInit } from "@angular/core";
import { HelperService } from "@app/services/helper.service";
import { RolesService } from "@app/services/roles.service";
import { RadicationService } from "../services/radication-quota.service";

@Component({
  selector: "app-request-admin",
  templateUrl: "./request-admin.component.html",
  styleUrls: ["./request-admin.component.scss"],
})
export class RequestAdminComponent implements OnInit {
  public navItemTabs: any[] = [];

  public isTab = "profile";
  public isEdit = false;
  public isView = false;
  public permissionToView: boolean = false;
  constructor(
    public helper: HelperService,
    private quotaRadicationService: RadicationService,
    private roles: RolesService
  ) {
    this.navItemTabs = this.quotaRadicationService.radicationTabsAdmin;
  }

  ngOnInit() {
    this.getRoleUser();
  }

  getRoleUser() {
    let userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (userName) {
      this.roles.findByUsername(userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "request-management"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions)
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions.adminRequest) {
      this.permissionToView = true;
    }
  }

  setTab(tab: any) {
    this.isTab = tab.key;
  }
}
