import { GeneralInfo } from "./generalInfo.model";

export class MainProviders {

    id: number;
	name: string;
	term: number;
	supplies: string;
    quotaId: GeneralInfo;

    public static fromResponse(json: any): MainProviders {

        const mainProviders: MainProviders = {

        id: json['id'],
        name: json['name'],
        term: json['term'],
        supplies: json['supplies'],
        quotaId: json['quotaId'],

    };
    return mainProviders;
    }
}