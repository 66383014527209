import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuotaGreaterThan20M } from '../models/quotaGreaterThan20M.model';
import { HelperService } from '@app/services/helper.service';
import { AllocationLimit } from '../models/allocationLimit.model';
import { RequestTypeResponse } from '../models/response/requestTypeResponse.model';
import { RequestType } from '../models/requestType.model';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { Response } from '@app/models/responseUtil.model';

@Component({
  selector: 'app-quota-info',
  templateUrl: './quota-info.component.html',
  styleUrls: ['./quota-info.component.scss']
})
export class QuotaInfoComponent implements OnInit {

  @Input() infoStudyQuote: FormGroup;
  @Input() quotaGreaterThan20M: QuotaGreaterThan20M;

  private allocation = new AllocationLimit();

  public states: Array<RequestType> = [];

  constructor(
    private spinner: NgxSpinnerService,
    private infoForm: FormBuilder,
    private quotaService: QuotaGreaterService,
    public helper: HelperService) {
  }

  ngOnInit() {
    console.log(this.quotaGreaterThan20M, '------');
    console.log(this.infoStudyQuote, '-----');
    this.allocation.document = this.quotaGreaterThan20M.affiliateDoc;
    this.allocation.documentType = this.quotaGreaterThan20M.affiliateDocType;
    this.quotaService.getAllocationLimit(this.allocation).subscribe(
      (resp: Response<AllocationLimit>) => {
        if (resp.data && resp.data.length > 0) {
          this.updateForm(resp.data[0]);
        }
      });
      this.quotaService.getRequestType().subscribe(
        (resp: Response<RequestType>) => {
          if (resp.data && resp.data.length > 0) {
            this.states = resp.data;
          }
        });
  }

  updateForm(resp: AllocationLimit){
    this.infoStudyQuote.controls.maxDocQuote.setValue(resp.docsMaxium);
    this.infoStudyQuote.controls['maxDocQuote'].disable();
    this.infoStudyQuote.controls.minQuote.setValue(resp.minium);
    this.infoStudyQuote.controls['minQuote'].disable();
    this.infoStudyQuote.controls.medQuote.setValue(resp.middle);
    this.infoStudyQuote.controls['medQuote'].disable();
    this.infoStudyQuote.controls.maxQuote.setValue(resp.basicMaxium);
    this.infoStudyQuote.controls['maxQuote'].disable();
  }
  // convenience getter for easy access to form fields
  get f() { return this.infoStudyQuote.controls; }

}
