import { SubjectService } from '@app/services/subject.service';
import Swal from 'sweetalert2';
import { SelectsDataForm } from '../../../../models/selectModel';
import { AffiliateLinkService } from '../../../../services/affiliate-link.service';
import { ListsCovinocService } from '../../../../services/lists-covinoc.service';
import { ModalService } from '@app/services/modal.service';
import { HelperService } from '@services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-create-address',
  templateUrl: './modal-create-address.component.html',
  styleUrls: ['./modal-create-address.component.scss']
})
export class ModalCreateAddressComponent implements OnInit, OnChanges {

  @ViewChild('createPhoneForm') createPhoneForm: NgForm;

  @Input() documentInfo: any = {};

  @Output() handleResetPhoneList: EventEmitter<any> = new EventEmitter();

  @Input() isResetAddressForm: boolean = false;

  public phoneStates: Array<any> = [];

  public phoneTypes: Array<any> = [];

  public phoneRelations: Array<any> = [];

  public docTypes: Array<any> = [];

  public generalLists: SelectsDataForm;

  public department = "";

  public city: string;

  public departments: any;

  public listCities: any;

  public cities: any;

  public isValidAddress: boolean = false;

  public form = {
    address: "",
    sequence: null,
    stratum: null,
    city: "",
    departament: "",
    postalCode: null,
    country: "COLOMBIA",
    addressType: "",
    enterpriseName: "",
    state: "",
    document: this.documentInfo.document,
    documentType: this.documentInfo.documentType
  }

  constructor(private quotaGreater: QuotaGreaterService, private toastr: ToastrService, public helper: HelperService, private modal: ModalService, private listCovinoc: ListsCovinocService, private affiliateLink: AffiliateLinkService, private spinner: NgxSpinnerService, private subject: SubjectService) { }

  closeModal() {
    this.createPhoneForm.resetForm();
    this.subject.changeHandleIsResetAddress(false);

    this.department = "";
    this.modal.close();
  }

  ngOnInit() {
    this.quotaGreater.getPhoneStates().subscribe((response: any) => {
      this.phoneStates = response.data;
    }, (error) => {
      this.toastr.error(error.error.message);
    });
    this.quotaGreater.getPhoneTypes().subscribe((response: any) => {
      this.phoneTypes = response.data;
    }, (error) => {
      this.toastr.error(error.error.message);
    });
    this.quotaGreater.getPhoneRelations().subscribe((response: any) => {
      this.phoneRelations = response.data;
    }, (error) => {
      this.toastr.error(error.error.message);
    });

    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
      console.log("DOCTYPES", this.docTypes);

    });
    this.generalList();
    this.getDepartments();
  }

  ngOnChanges() {
    this.isResetAddressForm = true;
  }

  handleValidAddress($event) {
    this.isValidAddress = $event;
  }


  generalList() {
    this.affiliateLink.getGeneralList().subscribe((response: SelectsDataForm) => {
      this.generalLists = response;
      console.log("Departments: ", response);

    },
      (err) => {
        this.listCities = [];
      });
  }

  handleSetAddressText($event) {
    console.log($event);
    this.form.address = $event;
  }


  getDepartments() {
    this.affiliateLink.getMunicipalities().subscribe((departments: any) => {
      console.log(departments);

      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  selectDeparment(selectedDepartment) {
    console.log("selectedDepartment: ", selectedDepartment.target.value);
    console.log("DEPARTMENTS: ", this.departments);
    const department = this.departments.filter((item) => item.value.toString() === selectedDepartment.target.value.toString());
    console.log("DEPARTMENT: ", department);
    this.form.city = "";
    this.affiliateLink.getCities(selectedDepartment.target.value).subscribe(
      (municipalities: any) => {
        console.log(municipalities);

        this.listCities = municipalities.data[0].cities || [];
      },
      (err) => {
        this.listCities = [];
      });
  }

  getCityById() {
    console.log("getCityById");
    console.log(this.departments);
    console.log(this.form.departament);
    
    this.departments.forEach((item) => {
      if(this.form.departament === item.value) {
        console.log("SSSSSSSSSSSSSSSSSSS");
        
        this.form.departament = item.description
      }
    });
  }

  getDepartmentById() {
    console.log("getDepartmentById");

    this.listCities.forEach((item) => {
      if(this.form.city === item.value) {
        this.form.city = item.description
      }
    });
  }

  handleSubmit() {
    this.spinner.show();

    this.getCityById();
    this.getDepartmentById();
    this.form.documentType = this.documentInfo.documentType;
    this.form.document = this.documentInfo.document;
    console.log(this.form);

    this.quotaGreater.normalizeAddress({ direccion: this.form.address }).subscribe((response: any) => {
      this.form.address = response.data[0].normalAddress

      this.quotaGreater.createAddress(this.form).subscribe((response: any) => {
        this.createPhoneForm.resetForm();
        this.isResetAddressForm = true;
        this.handleResetPhoneList.emit();
        console.log(response);
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
        this.modal.close();
      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });

    }, (error) => {
      this.toastr.error(error.error.message);
    });
  }
}
