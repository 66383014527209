import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackupsTableHeadersService {
  private load = new BehaviorSubject<boolean>(true);

  loading$ = this.load.asObservable();

  setLoad(isLoad) {
    this.load.next(isLoad);
  }

  public tableHeadersRefunds: string[] = [
    // fase 2 ok fm
    // "Tipo documento afiliado",
    // "Nombre afiliado",
    'Documento comprador',
    'Nombre comprador',
    'Título valor',
    'Fecha vencimiento',
    'Valor garantizado',
    'Valor reclamado',
    'Fecha reclamado',
    'Sucursal',
    'Producto',
    'Contrato',
    'Valor reintegro',
    'Fecha reintegro',
    'Valor deducible',
    'Fecha deducible',
  ];

  public tableHeadersSettlement: string[] = [
    // fase 2 ok fm
    'Seleccionar',
    'Contrato',
    'Título valor',
    'Código de consulta',
    'Documento afiliado',
    'Nombre afiliado',
    'Documento comprador',
    'Nombre comprador',
    'Sucursal',
    'Fecha aviso',
    'Fecha consulta',
    'Días aviso',
    'Valor factura',
    'Fecha vencimiento',
    'Días reclamación',
    'Fecha límite reclamación',
  ];

  public tableHeadersIndividualValueTitle: any[] = [
   'Seleccionar',
   'Estado radicación',
   'Causal devolución',
   'Contrato',
   'Producto',
   'Recuperación',
   'Fecha radicación',
   'Número radicación',
   'Título valor',
   'Documento afiliado',
   'Nombre afiliado',
   'Documento comprador',
   'Nombre comprador',
   'Deducible',
   'Valor factura',
   'Abonos',
   'Ver Abonos',
   'Exoneraciones',
   'Abono',
   'Valor reclamado',
   'Valor reintegro',
   'Sucursal',
   'Días aviso',
   'Reintegro recuperado',
   'Deducible recuperado',
   'Tipo Producto',
   'Fecha vencimiento',
   'Fecha máxima reclamación',
   'Fecha pago',
   'Días para reclamar',
   'Días de reintegro',
   'Email',
  ];


public tableHeadersIndividualValueTitlePermissions: any[] = [
  'Estado radicación',
  'Causal devolución',
  'Recuperación',
  'Fecha radicación',
  'Número radicación',
  'Título valor',
  'Documento afiliado',
  'Nombre afiliado',
  'Documento comprador',
  'Nombre comprador',
  'Deducible',
  'Valor factura',
  'Valor reclamado',
  'Valor reintegro',
  'Sucursal',
  'Reintegro recuperado',
  'Deducible recuperado',
 ];

  public tableHeadersBackupsReport: string[] = [
    // fase 2 ok fm
    'Nombre afiliado',
    'Documento afiliado',
    'Título valor',
    'Nombre comprador',
    'Documento comprador',
    'Valor factura',
    'Abonos',
    'Valor reclamado',
    'Producto',
    'Fecha vencimiento',
    'Número radicación',
    'Estado radicación',
    'Fecha máxima reclamación',
    'Fecha pago',
  ];

  public expressReport: string[] = [
    // Encabezado reporte-express

    'Contrato',
    'Producto',
    'Radicación carga',
    'Fecha de radicación',
    'Tipo documento afiliado',
    'Documento afiliado',
    'Nombre afiliado',
    'Registros aprobados',
    'Registros pendientes',
    'Registros negados',
    'Registros totales',
    'Fecha de finalización',
    'Ver detalles',
  ];

  public filterExpressReportSuspended: string[] = [
    // Encabezado filtro reporte-express suspendido
    'Contrato',
    'Producto',
    'Radicación carga',
    'Fecha carga',
    'Tipo documento comprador',
    'Documento comprador',
    'Nombre comprador',
    'Registro',
    'Número cheque',
    'Valor',
    'Fecha radicación',
    'Observación',
    'Estado',
    'Fecha emisión',
    'Cupo afiliado',
    'Cupo disponible',
    'Fecha suspensión',
    'Avisos vigentes',
    'Límite para radicar',
    'Número CVS',
    'Saldo actual',
    'Valor total',
    'Días mora',
    'Lista clinton',
    'Tipo girador',
    'Estado documento',
    'Oblig. otras carteras',
    'Fecha reporte deudores',
    'Fecha bloqueo',
    'Fecha de finalización',
  ];

  public filterExpressReportSummary: string[] = [
    // Encabezado filtro reporte-express resumen, pendiente, aprobado
    'Contrato',
    'Producto',
    'Radicación carga',
    'Fecha carga',
    'Tipo documento comprador',
    'Documento comprador',
    'Registro',
    'Número cheque',
    'Valor',
    'Autorización',
    'Fecha radicación',
    'Observación',
    'Estado',
    'Fecha emisión',
    'Fecha de finalización',
  ];

  public filterExpressReportDenied: string[] = [
    // Encabezado filtro reporte-express negado
    'Contrato',
    'Producto',
    'Radicación carga',
    'Fecha carga',
    'Tipo documento comprador',
    'Documento comprador',
    'Nombre comprador',
    'Registro',
    'Número cheque',
    'Valor',
    'Fecha radicación',
    'Observación',
    'Estado',
    'Fecha emisión',
    'Fecha de finalización',
  ];

  public excessRisk: string[] = [
    // Encabezado exceso de riesgo
    'Tipo documento',
    'Documento',
    'Nombre',
    'Estado documento',
    'Contrato',
    'Producto',
    'Estado contrato',
    'Estado en reporte express',
    'Exceso',
  ];

  public tableHeadersPaymentsTable: string[] = [
    // "Seleccionar",
    'Estado radicación',
    'Causal devolución',
    'Contrato',
    'Deducible', //
    'Fecha radicación',
    'Número radicación',
    'Título valor',
    'Documento afiliado',
    'Nombre afiliado',
    'Documento comprador',
    'Nombre comprador',
    'Valor factura',
    'Abonos',
    // "Ver Abonos",
    'Exoneraciones',
    // "Abono",
    'Valor reclamado',
    'Producto',
    'Fecha vencimiento',
    'Fecha máxima reclamación',
    'Fecha pago',
    'Sucursal',
    'Días para reclamar',
    'Días de reintegro',
  ];

  public tableHeadersBackupsTable: string[] = [
    'Tipo Documento',
    'Documento',
    'Razón social',
    'Ver afiliado',
  ];

  public tableHeadersNonPaymentsTable: string[] = [
    'Seleccionar',
    'Estado',
    'Acciones',
    'Fecha', //
    'Contrato', //
    'Código consulta', //
    'Fecha vencimiento', //
    'Documento comprador', //
    'Documento afiliado', //
    'Sucursal', //
    'Autorización', //
    'Valor', //
    'Valor garantizado', //
    'Usuario', //
    'Pago', //
    'Número cheques', //
    'Módulo', //
    'Número siniestro', //
    'Número compra', //
    'Banco', //
    'Cuenta', //
    'Título valor', //
    'Fecha reporte', //
  ];

  public tableHeadersTransactions: string[] = [
    // "Seleccionar",
    'Título valor',
    'Contrato',
    'Valor',
    'Autorización',
    'Fecha emisión',
    'Fecha vencimiento',
    'Documento comprador',
    'Documento afiliado',
    'Sucursal',
    'CV',
    'Fecha exoneración parcial',
    'Usuario exoneración parcial',
    'Fecha exoneración total',
    'Usuario exoneración total',
    'Usuario carga factura',
    'Fecha de aviso no pago',
    'Fecha de descargo aviso',
    'Usuario descarga aviso',
    'Valor recuperado',
    'Estado recuperación',
  ];

  public tableHeadersInvoices: string[] = [
    'Oficina',
    'N° Factura',
    'Documento',
    'Contrato',
    'Afiliado',
    'Fecha generación',
    'Fecha vencimiento',
    'Valor factura',
    'Estado',
  ];

  public tableHeadersDeductibles: string[] = [
    'Número de presentación',
    'Radicación',
    'Fecha de pago',
    'fecha límite para el deducible',
    'Día límite deducible',
    'Días',
    'Revolución deducible',
    'Recuperación del deducible',
    'Descontar',
    'Pagar al afiliado',
    'Inicio de sesión del sistema',
    'caja de recibos',
    'Tipo de recuperación',
    'Usuario actualizado',
    'Producto',
    'Subproducto',
    'Rama',
    'Establecimiento',
    'Contrato',
    'Documento de afiliado',
    'Tipo de documento del pagador',
    'Documento del pagador',
    'Nombre del pagador',
    'Número de facturación',
    'Sistema de fechas',
  ];

  public tableHeadersGlobalBilling: string[] = [
    'Check',
    'Nit',
    'Afiliado',
    'Contrato',
    'Sucursal',
    'Valor bolsa',
    'N° Cuotas amortizar',
    'N° Cuotas facturar',
    'Estado',
  ];

  public tableHeadersCutBilling: string[] = [
    'Proceso',
    'Sucursal',
    'Cantidad de facturas',
    'Valor',
  ];

  public tableHeadersRulesCreated: string[] = [
    'Valor bolsa',
    'Porcentaje',
    'N° Cuotas amortizar',
    'N° Cuotas facturar',
  ];

  public tableHeadersSpecialCases: string[] = [
    'Id bolsa',
    'Id cuota',
    'N° de factura',
  ];
  constructor() {}

  public viewTableData = true;
}
