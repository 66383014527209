import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { of } from "rxjs";
import { SelectModel } from '@app/models/select.model';
import * as SelectActions from '@app/redux/actions/select.actions';
import { SelectsService } from "@app/services/selects.service";

@Injectable()

export class SelectsEffect {

    constructor(private actions$: Actions, private selects: SelectsService) { }

    public loadSelects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SelectActions.getSelects),
            // tap(data => console.log("select effect tap =>", SelectActions.getSelects, data)),
            mergeMap((action) => {
                return this.selects.getSelectData().pipe(
                    // tap(data => console.log("select effect tap =>", data)),
                    map((selects: SelectModel[]) => {
                        return SelectActions.getSelectsSuccess({ selects });
                    }),
                    catchError(error => of(SelectActions.getSelectsFailure({ error })))
                );
            }),
        )
    );

}