import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safePercent'
})
export class SafePercentPipe implements PipeTransform {

  private percentPipe = new PercentPipe('es-CO');
  
  transform(value: any, digitsInfo?: string): any {
    if (typeof value === 'number' && !isNaN(value)) {
      return this.percentPipe.transform(value / 100, digitsInfo);
    }
    return value;
  }

}
