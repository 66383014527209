export class Description {
    id: number;
	postdated: number;
	quota: number;
	queryCode: number;
	topMaxCode: number;
	topeMaxContract: number;
	purchaseObject: string;
	noQuotasAffiliate: number;
	noQuotasReq: number;
	comercialValue: number;
	methodPayments: string;

	public static fromResponse(json: any): Description {

        const description: Description = {

		id: json['id'],
		postdated: json['postdated'],
		quota: json['quota'],
		queryCode: json['queryCode'],
		topMaxCode: json['topMaxCode'],
		topeMaxContract: json['topeMaxContract'],
		purchaseObject: json['purchaseObject'],
		noQuotasAffiliate: json['noQuotasAffiliate'],
		noQuotasReq: json['noQuotasReq'],
		comercialValue: json['comercialValue'],
		methodPayments: json['methodPayments'],
    };
    return description;
    }
}