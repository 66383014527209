import { Component, Input, OnInit } from '@angular/core';
import { PreBill } from '@app/models/global-billing.models';
import { SelectModel } from '@app/models/select.model';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportNoBillOrBill } from '../models/ReportNoBillorBill.mode';

@Component({
  selector: 'app-billing-generate-report',
  templateUrl: './billing-generate-report.component.html',
  styleUrls: ['./billing-generate-report.component.scss'],
})
export class BillingGenerateReportComponent implements OnInit {
  public report: string = 'FAC';
  public finalDate: any;
  public initDate: any;
  public max: any;

  cantPages = 0;
  currentPage = 1;
  typeSearch = null;
  tableHeard = [
    'Corte facturación',
    'Número de bolsa',
    'Contrato',
    'Nit',
    'Afiliado',
    'Mes cuota',
    'Cuota',
    'Valor cuota',
    'Factura interna',
    'Factura Net-suite',
    'Fecha facturación',
  ];

  public listOptions: SelectModel[] = [
    {
      value: 'NIT',
      description: 'Nit',
    },
    {
      value: 'AFILIADO',
      description: 'Nombre del afiliado',
    },
    {
      value: 'CONTRATO',
      description: 'Numero de contrato',
    },
  ];

  filter: boolean = false;
  value = null;

  typeSearchNumber = ['NIT', 'CONTRATO', 'id_bolsa', 'corte_facturacion'];

  globalBiling: string | ReportNoBillOrBill[] = [];

  constructor(
    private globalBillingService: GloballBillingService,
    public backupsHeaders: BackupsTableHeadersService,
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    /* this.backupsHeaders.loading$.subscribe(() => {
      this.getAll();
    });
    this.getAll(); */
    this.max = new Date();
  }

  getAll(isDownload: boolean = false) {
    const initialDate = this.transformDate(this.initDate);
    const finalDate = this.transformDate(this.finalDate);
    this.spinner.show();
    const body = {
      filtro: this.typeSearch,
      valor: this.value,
      reporte: this.report,
      pagina: this.currentPage,
      por_pagina: 20,
      descargar: isDownload,
      fecha_ini: initialDate,
      fecha_fin: finalDate,
    };
    if (!isDownload) {
      this.globalBillingService.bagsReport(body).subscribe(
        (resp: any) => {
          if (resp.ok && resp.data.length > 0) {
            this.globalBiling = resp.data;
          }
          this.spinner.hide();
          this.cantPages = resp.totalPages;
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    } else {
      this.globalBillingService.bagsReport(body).subscribe(
        (resp) => {
          this.helper.downloadFromBase64(resp.data, 'reporte', '.xls');
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
    }
  }

  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.getAll();
  }

  selectTypeSearch($event) {
    this.currentPage = 1;
    this.filter = this.typeSearchNumber.includes($event);
    this.typeSearch = $event;
    this.value = null;
  }

  refresh() {
    this.typeSearch = null;
    this.value = null;
    this.currentPage = 1;
    this.initDate = new Date;
    this.finalDate = new Date;
    this.globalBiling = [];
    this.getAll();
  }

  openView(prebil: PreBill) {
    this.modal.show('individualBilling', prebil);
  }

  transformDate(date: Date): string {
    if (!date) return '';
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString().slice(-4);
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    const day = ('0' + newDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
}
