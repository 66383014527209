import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-contact-result',
  templateUrl: './location-contact-result.component.html',
  styleUrls: ['./location-contact-result.component.scss']
})
export class LocationContactResultComponent implements OnInit {
  @Input() quotaData: any;
  constructor() { }

  ngOnInit() {
  }

}
