import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchAffiliateModel } from '@app/pages/backups/models/request/SearchAffiliateModel';
import { AffiliatesListModel, AffiliatesResponse } from '@app/pages/backups/models/response/SearchAffiliate.model';
import { BackupsStoreService } from '@app/pages/backups/services/backups-store.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-affiliates-table',
  templateUrl: './affiliates-table.component.html',
  styleUrls: ['./affiliates-table.component.scss']
})
export class AffiliatesTableComponent implements OnDestroy {

  public tableHeadings = ['Tipo Doc', 'Documento', 'Razón social', 'Ver afiliado'];
  public affiliateList$: Observable<AffiliatesResponse> = this.backupsStore.stateAffiliateList$;
  public searchAffiliate: Observable<SearchAffiliateModel> = this.backupsStore.stateSearchAffiliate$;

  constructor(private router: Router, private backupsStore: BackupsStoreService) {
  }
  
  ngOnDestroy() {
    this.backupsStore.changeStateAffiliateList(new AffiliatesResponse());
  }

  handleSelectAffiliate(affiliate: AffiliatesListModel) {
    const { affiliateDocumentType, affiliateDocument } = affiliate;
    this.backupsStore.changeStateAffiliate(affiliate);
    this.router.navigate(['pages/respaldos/afiliado',affiliateDocumentType, affiliateDocument]);
  }
}

