import { RelationEcBuyerResponse } from "./response/relationEcBuyerReponse.model";

export class RelationEcBuyer {
    ctid: string;
	docTypeGir: string;
	docGir: number;
	docTypeRela: string;
    docRela: number;
    name: string;
    relation: string;
    dateUpdate: string;
    userUpdate: string;

    public static fromResponse(json: any): RelationEcBuyer {

        const relationEcBuyer: RelationEcBuyer = {

        ctid: json['ctid'],
        docGir: json['docGir'],
        docTypeGir: json['docTypeGir'],
        docTypeRela: json['docTypeRela'],
        docRela: json['docRela'],
        name: json['name'],
        relation: json['relation'],
        dateUpdate: json['dateUpdate'],
        userUpdate: json['userUpdate'],

    };
    return relationEcBuyer;
    }

    public static fromResponseToArray(resp: RelationEcBuyerResponse): Array<RelationEcBuyer> {
        let relationEcBuyerList: Array<RelationEcBuyer> = [];
        resp.response.forEach(ele => {
            let relationEcBuyer: RelationEcBuyer = {
                ctid: ele.ctid,
                docGir: ele.docGir,
                docTypeGir: ele.docTypeGir,
                docRela: ele.docRela,
                docTypeRela: ele.docTypeRela,
                name: ele.name,
                relation: ele.relation,
                dateUpdate: ele.dateUpdate,
                userUpdate: ele.userUpdate,
            };
            relationEcBuyerList.push(relationEcBuyer);
        });
        return relationEcBuyerList;
    }
}