export class AddressAsrTemp {
  id: number;
  sequence: number;
  idContract: number;
  queryCodeId: number;
  address: string;
  addressType: string;
  department: string;
  city: string;
  departmentName: string;
  cityName: string;

  documentType:string;
  document:number;

  check: boolean;
}
