import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-admin-quota',
  templateUrl: './admin-quota.component.html',
  styleUrls: ['./admin-quota.component.scss']
})
export class AdminQuotaComponent implements OnInit {

  public navItemTabs: any[] = [];

  constructor(private tabs: TabsService, public helper: HelperService) { 
    this.navItemTabs = this.tabs.adminQuotaTabs;
  }

  ngOnInit() {
  }

}
