import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentRequestModel } from '@app/pages/backups/models/request/DocumentRequest.model';
import { SearchAffiliateModel } from '@app/pages/backups/models/request/SearchAffiliateModel';
import { AffiliatesListModel } from '@app/pages/backups/models/response/SearchAffiliate.model';
import { TransactionsToBackupResponse, TransactionsToBackUpResponseModel } from '@app/pages/backups/models/response/TransactionsToBackupResponse';
import { BackupsStoreService } from '@app/pages/backups/services/backups-store.service';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { TabsService } from '@app/services/tabs.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliates-tabs',
  templateUrl: './affiliates-tabs.component.html',
  styleUrls: ['./affiliates-tabs.component.scss']
})
export class AffiliatesTabsComponent implements OnInit {

  public affiliate: AffiliatesListModel = new AffiliatesListModel();
  public subs1: Subscription = new Subscription();
  public navItemTabs: any[] = [];

  public searchAffiliate: SearchAffiliateModel = new SearchAffiliateModel();
  public affiliateList: AffiliatesListModel[] = [];
  public searchBy: string = 'document';
  public getTransactionsRequest: DocumentRequestModel = new DocumentRequestModel();
  public transactionsToBackupResponse: TransactionsToBackUpResponseModel[] = [];

  constructor(public modal: ModalService, public helper: HelperService, public backupsStore: BackupsStoreService,
    private activatedRoute: ActivatedRoute, private router: Router, private backups: BackupsService, private tabs: TabsService) {
  }

  ngOnInit() {
    this.getParams();
    this.navItemTabs = this.tabs.backupsTabs;
  }

  getParams() {
    const { documentType, document } = this.activatedRoute.snapshot.params;
    if (documentType && document) {
      // this.searchAffiliate.type = 'document';
      this.searchAffiliate.documentType = documentType;
      this.searchAffiliate.document = document;
      this.getAffiliate();
    } else {
      this.handleNoUrlParams();
    }
  }

  getAffiliate() {
    this.backups.getAffiliate(this.searchAffiliate).subscribe(
      (affiliateList: AffiliatesListModel[]) => {
        this.backupsStore.changeStateAffiliate(affiliateList[0]);
      }, (error: any) => {
        console.log(error);
        this.handleNoUrlParams(error);
      }
    );
  }

  getTransactionsToBackup() {
    // this.backups.getTransactionsToBackUp(this.getTransactionsRequest).subscribe(
    //   (transactions: TransactionsToBackupResponse) => {
    //     this.transactionsToBackupResponse = transactions['data'];
    //   }, (error: any) => {
    //     this.transactionsToBackupResponse = [];
    //     console.log(error);
    //   }
    // );
  }

  handleNoUrlParams(error?: any) {
    this.router.navigate(['pages/respaldos']);
    if (error) {
      Swal.fire({
        type: 'info',
        text: `${error['error']['message']}`
      });
    }
  }
}
