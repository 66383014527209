export class ReturnedBackingsRequest {
    affiliateDocumentType: string = '0';
    affiliateDocument: string = '0';
    issuerDocumentType: string = '0';
    issuerDocument: string = '0';
    branchOffice: string = 'NACIONAL';
    contract: number = 0;
    draftNumber: number = 0;
    sinisterNumber: string = '0';
    initialDate: string = '';
    finalDate: string = '';
    currentPage: number = 1;
    perPage: number = 10;
    download: boolean = false;
}