import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { BagResponse, BagResponseModel } from '../models/response/bag.model';
import { ProductsTableHeadersService } from '../services/products-table-headers.service';
import { BagRequest } from '../models/request/bag.model';
import { HelperService } from '../../../services/helper.service';
import { ProductsService } from '@app/services/products.service';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsStoreService } from '../services/products-store.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bag-table',
  templateUrl: './bag-table.component.html',
  styleUrls: ['./bag-table.component.scss']
})
export class BagTableComponent implements OnInit, OnDestroy {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public counterRequest: number = 0;
  public cantPagesProducts: number = 5;
  public cantPagesBags: number = 5;
  public selectedRow: number | string;

  public reportResponse: any[] = [];
  public bags: BagResponseModel[] = [];
  public getBagRequest: BagRequest = new BagRequest();
  public bagsBD: BagResponseModel[] = [];
  public stateSelectedRowBag$ = this.productsStore.stateSelectRowbag$;
  public stateIdProduct$ = this.productsStore.stateIdProduct$;
  public allowBags$ = this.productsStore.allowBags$;
  public readonly unsubscribe$ = new Subject<void>();

  constructor(public tableHeaders: ProductsTableHeadersService,
    public modal: ModalService, private productsStore: ProductsStoreService,
    public helper: HelperService, private productsService: ProductsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.stateSelectedRowBag$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((row: number) => this.selectedRow = row);

    this.stateIdProduct$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((idProduct: number) => this.getBagById(idProduct));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getBagById(idProduct: number): void {
    this.getBagRequest.productId = idProduct;
    this.getBags(1);
  }

  editBag(selectedBag: BagResponseModel, idx: number): void {
    this.selectedRow = idx;
    this.modal.show('bag', {
      title: 'Editar bolsa',
      action: 'edit',
    });
    this.productsStore.setBag(selectedBag);
  }

  addBag(): void {
    this.modal.show('bag', {
      title: 'Agregar bolsa',
      action: 'add'
    });
    this.productsStore.setBag(new BagResponseModel());
  }

  getBags(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.getBagRequest.currentPage = currentPage;
    this.productsService.getBags(this.getBagRequest).subscribe(
      (bagsResponse: BagResponse) => {
        this.handleBagsResponse(bagsResponse);
      }, (error: any) => {
        this.helper.handleErrorHttp(error, 'Bolsas', this.paginationComponent);
        this.cantPagesBags = 0;
        this.bags = [];
      }
    );
  }

  handleBagsResponse(bagsResponse: BagResponse) {
    if (bagsResponse && bagsResponse.ok === false) {
      this.helper.handleErrorHttp({}, 'Bolsas', this.paginationComponent);
      return;
    }
    this.bags = bagsResponse['data'];
    this.bagsBD = this.bags
      .map(bag => Object.assign({}, bag));

    this.cantPagesBags = bagsResponse['totalPages'];
    this.spinner.hide();
    console.log('bags ->', bagsResponse);
  }

}
