import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-blocks-unlocks',
  templateUrl: './blocks-unlocks.component.html',
  styleUrls: ['./blocks-unlocks.component.scss']
})
export class BlocksUnlocksComponent implements OnInit {

  public blocksUnlocksTabs: Array<any>;

  constructor(private tabs: TabsService, public helper: HelperService) { }

  ngOnInit() {
    this.blocksUnlocksTabs = this.tabs.blocksUnlocksTabs;
  }

}
