import { GeneralInfo } from "./generalInfo.model";

export class Reference{

    id: number;
	name: string;
	nit: number;
	city: string;
	phone: number;
	person: string;
	position: string;
	clientsFrom: string;
	supplies: string;
	quota: number;
	term: number;
	paymentMethod: string;
	qualification: string;
	idQuota: GeneralInfo;

	public static fromResponse(json: any): Reference {

        const reference: Reference = {

			id: json['id'],
			name: json['name'],
			nit: json['nit'],
			city: json['city'],
			phone: json['phone'],
			person: json['person'],
			position: json['position'],
			clientsFrom: json['clientsFrom'],
			supplies: json['supplies'],
			quota: json['quota'],
			term: json['term'],
			paymentMethod: json['paymentMethod'],
			qualification: json['qualification'],
			idQuota: json['idQuota'],

    };
    return reference;
    }
}