import { Component, Input, OnInit } from '@angular/core';
import { BalancesQuotaAndValuesOfActiveObligations } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-balances-quota-values-of-active-obligations',
  templateUrl: './legal-balances-quota-values-of-active-obligations.component.html',
  styleUrls: ['./legal-balances-quota-values-of-active-obligations.component.scss']
})
export class LegalBalancesQuotaValuesOfActiveObligationsComponent implements OnInit {

  public headers: string[] = [
    "Tipo de obligación",
    "Entidad informante",
    "No de obligación",
    "Cupo o valor inicial",
    "Saldo actual",
    "Saldo en mora",
    "Valor cuota",
    "% de uso",
    "Día de pago",
    "Vigencia / N° cuotas",
    "Mod",
    "Persona"
  ];

  @Input() balancesQuotaAndValuesOfActiveObligations: BalancesQuotaAndValuesOfActiveObligations | undefined = undefined;
  @Input() isAPerson: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  getDayOfDate(date: string) {
    if(!date) return '-';
    const day: string = date.trim().split('-')[2] || date;
    return day;
  }

}
