import { Component, Input, OnInit } from '@angular/core';
import { IVectorAccount, IVectorAnalysis } from '../../services/natural-person-adapter.service';

@Component({
  selector: 'app-table-vector-analysis-history',
  templateUrl: './table-vector-analysis-history.component.html',
  styleUrls: ['./table-vector-analysis-history.component.scss']
})
export class TableVectorAnalysisHistoryComponent implements OnInit {

  @Input() vectorAnalysis: IVectorAnalysis = { sector: '', accounts: [], headers: [] };

  get getTitle(): string {
    return this.vectorAnalysis.sector;
  }

  get getHeaders(): string[] {
    return this.vectorAnalysis.headers;
  }

  get getAccounts(): IVectorAccount[] {
    return this.vectorAnalysis.accounts;
  }

  constructor() {
    this.vectorAnalysis.headers
  }

  ngOnInit() {
  }

}
