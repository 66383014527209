import { AffiliateInfFiscalAsrTempPK } from "./compositeKey/AffiliateInfFiscalAsrTempPK.model";

export class AffiliateInfFiscalAsrTemp {
  affiliateInfFiscalAsrTempPK: AffiliateInfFiscalAsrTempPK;
  idTaxLiability: number;
  idTaxRegime: number;
  dateRegistration: Date;
  user: string;
  dateUpdate: Date;
  userUpdate: string;
}
