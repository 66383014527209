import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cityName'
})
export class CityNamePipe implements PipeTransform {

  transform(value: any, department?: any): any {
    let valueCity = Number(value);
    let nameCity: string;
    
    let requestTypeList: any = JSON.parse(localStorage.getItem('form20m_municipalitiesList'));
    if(requestTypeList) {
      requestTypeList.forEach(item => {
        if(Number(item.department) === Number(department)) {
          if(Number(item.city) === valueCity) {
            nameCity = item.nameCity;
          }
        }
      })
      return nameCity;
    }
  }
}
