import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { THeadingComponent } from '../t-heading/t-heading.component';

@NgModule({
  declarations: [TableComponent, THeadingComponent],
  imports: [
    CommonModule, AppRoutingModule, SharedModule
  ], exports: [TableComponent, THeadingComponent],
})
export class TableModule { }
