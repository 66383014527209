import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { ApproveUpload, ApproveUploadModel, DataResAffilate, DataSearchRisk, ExcessRisk, ExpressReportModel, FilterUploadExpressReportModel } from '@app/pages/backups/models/response/ExpressReport';

import { FindAffiliateByDocument } from '@app/pages/backups/models/response/ExcessRiskModel';
import { ValidateDate } from '@app/models/validateDate';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';


@Injectable({
  providedIn: 'root'
})
export class ExpressReportService {

  public urlApi: string = '';
  public tokenFindAffiliate: string;
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient,
    private helper: HelperService ,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.dev04ApiUtl;
    this.timeout = environment.httpTimeout;
    this.tokenFindAffiliate = environment.tokenFindAffiliate;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, httpTimeout, tokenFindAffiliate, dev04ApiUtl} = apis
      this.urlApi = dev04ApiUtl;
      this.timeout = httpTimeout;
      this.tokenFindAffiliate = tokenFindAffiliate;
       console.log('config ci cd', apiUrl);
    });
  }


  searchUpload(ExpressReportModel: ExpressReportModel) {

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),
    })
    const options = {
      headers,
    };
    return this.http.post<ExpressReportModel>(`${this.urlApi}/expressreport/api/v1/searchUpload/findByStatusOrContract`, ExpressReportModel,options)
  }

  filterUpload(filterUploadExpressReport: FilterUploadExpressReportModel) {

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),

    })
    const options = {
      headers,
    };
    return this.http.post<FilterUploadExpressReportModel>(`${this.urlApi}/expressreport//api/v1/searchUpload/findByFilter`, filterUploadExpressReport,options)
  }

 approveUpload(approveUpload: ApproveUpload, paramsAprove: string) {

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),
    })
    const options = {
      headers,
    };
    return this.http.post<ApproveUpload>(`${this.urlApi}/expressreport/api/v1/approveUpload?function=${paramsAprove}`, approveUpload,options)
  }

  deniedUpload(deniedUpload: ApproveUpload) {

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),
    })
    const options = {
      headers,
    };
    return this.http.post<ApproveUpload>(`${this.urlApi}/expressreport/api/v1/deniedUpload/`, deniedUpload,options)
  }

  findAffiliateByDocument (dataAffiliate: DataSearchRisk) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),

    })
    const options = {
      headers,
    };
    return this.http.post(`${this.urlApi}/expressreport/api/v1/excessRisk/findAffiliateByDocument`, dataAffiliate, options)
  }

  updateExcessRisk (dataUpdate: ExcessRisk) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),
    })
    const options = {
      headers,
    };
    return this.http.post(`${this.urlApi}/expressreport/api/v1/excessRisk/updateExcessRisk`, dataUpdate, options)
  }


  validatePending(radication: any) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.tokenFindAffiliate),
    })
    const options = {
      headers,
    };
    return this.http.post(`${this.urlApi}/api/v1/notificationUpload/validatePending?uploadRadication=${radication}`,options)
  }
}


