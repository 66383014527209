import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-transactions-reports',
  templateUrl: './transactions-reports.component.html',
  styleUrls: ['./transactions-reports.component.scss']
})
export class TransactionsReportsComponent implements OnInit {

  public navItemTabs: Array<any>;

  constructor(public tabs: TabsService, public helper: HelperService) { 
    this.navItemTabs = tabs.transactionsReportsTabs
  }

  ngOnInit() {
  }

}
