import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlCleaner'
})
export class HtmlCleanerPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    return doc.body.textContent || value;
  }

  // transform(value: string): string {
  //   if (!value) {
  //     return value;
  //   }
  //   value.replace(/<[^>]*>/g, '');
  // }

}
