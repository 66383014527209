import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { CreditHistoryAlert } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alerts-history',
  templateUrl: './alerts-history.component.html',
  styleUrls: ['./alerts-history.component.scss']
})
export class AlertsHistoryComponent implements OnInit, OnDestroy {

  public headersTable: string[] = ['Fuente', 'Fecha', 'Novedad', 'Descripción'];
  public alerts: CreditHistoryAlert[] = [];

  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.alerts = response.getAlertsFromCreditHistory;
  }

}
