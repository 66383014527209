import { Component, OnInit } from "@angular/core";
import { SelectModel } from "@app/models/select.model";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { NetFactoringBackEndService } from "@app/services/net-factoring-back-end.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  FacturasNegociadas
} from "../models/NegotiatedInvoices";
import { NetFactoringService } from "../services/net-factoring.service";
import { ParamsAffiliateStoreService } from "../services/params-affiliate-store.service";
import { ContractService } from "@app/services/contract.service";

@Component({
  selector: "app-invoices-status",
  templateUrl: "./invoices-status.component.html",
  styleUrls: ["./invoices-status.component.scss"],
})
export class InvoicesStatusComponent implements OnInit {
  negotiatedInvoicesList: FacturasNegociadas[] = [];

  cantPages = 0;
  currentPage = 1;
  perPage = 10;

  filter = false;

  filterParams = {
    numberInvoices: null,
    typeDocument: '',
    document: null,
    state: 'P',
  };

  public statesInvoices: Array<SelectModel> = [
    { value: "A", description: "Aprobado" },
    {
      value: "P",
      description: "Pediente",
    },
    { value: "R", description: "Rechazado" },
  ];

  selectOptionsDocumentTypes:any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    public modal: ModalService,
    public netFactoringService: NetFactoringService,
    public netFactoringBackEndService: NetFactoringBackEndService,
    public invoicesParams: ParamsAffiliateStoreService,
    public contractService: ContractService
  ) {}

  ngOnInit() {
    this.getDocumentType();
    this.getInvoices();
    this.invoicesParams.stateInvoices$.subscribe((response) => {
      this.negotiatedInvoicesList = response;
    });
  }

  getInvoices() {
    this.spinner.show();
    let query = this.validFilter();
    query["currentPage"] = this.currentPage;
    query["perPage"] = this.perPage;

    this.netFactoringBackEndService.getInvoicesNegotiated(query).subscribe(
      (resp) => {
        this.invoicesParams.changeInvoices(resp.data);
        this.cantPages = resp.totalPages;
        this.spinner.hide();
      },
      (error) => {
        this.negotiatedInvoicesList = [];
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  getDocumentType() {
    let selectUtils = JSON.parse(localStorage.getItem("covi-utils"));
    if (selectUtils) {
      selectUtils.documentTypesCode.forEach((element) => {
        this.selectOptionsDocumentTypes.push(element);
      });
    }else{
      this.contractService.getSelectData().subscribe((resp) => {
         selectUtils = JSON.parse(localStorage.getItem("covi-utils"));
         selectUtils.documentTypesCode.forEach((element) => {
           this.selectOptionsDocumentTypes.push(element);
         });
      }, (error) => {console.error(error)})
    }
  }

  validFilter() {
    let query = {};
    let keys = Object.keys(this.filterParams);
    keys.forEach((element) => {
      if (this.filterParams[element]) {
        query[element] = this.filterParams[element];
      }
    });
    return query;
  }

  reload() {
    this.currentPage = 1;
    this.perPage = 10;
    this.filter = false;
    this.filterParams.document = null;
    this.filterParams.typeDocument = "";
    this.filterParams.state = "P";
    this.filterParams.numberInvoices = null;
    this.getInvoices();
  }

  editAffiliate(invoices: FacturasNegociadas) {
    this.modal.show("invoicesStatus", invoices);
  }
  goToPage($event) {
    this.currentPage = $event.currentPage;
    this.getInvoices();
  }

  selectTypeDocument($event) {
    this.filterParams.state = '';
    this.filterParams.typeDocument = $event;
  }

  selectState($event) {
    this.filterParams.state = $event;
    this.filterParams.document = null;
    this.filterParams.typeDocument = '';
    this.filterParams.numberInvoices = null;
    this.getInvoices();
  }

  searchByDocument() {
    this.filterParams.state = '';
    this.filterParams.numberInvoices = null;
    this.filter = true;
    this.getInvoices();
  }

  searchByInvoices() {
    this.filterParams.document = null;
    this.filterParams.typeDocument = '';
    this.filterParams.state = '';
    this.filter = true;
    this.getInvoices();
  }
}
