import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';

@Component({
  selector: 'app-legal-global-debt-classified-table',
  templateUrl: './legal-global-debt-classified-table.component.html',
  styleUrls: ['./legal-global-debt-classified-table.component.scss'],
})
export class LegalGlobalDebtClassifiedTableComponent
  implements OnInit, OnChanges
{
  @Input() globalDebtClassifiedDataTable: any;
  groupedData: any = {};
  headersGroupedData = [
    { text: 'Entidad Informante', colspan: 1 },
    { text: 'Calificación', colspan: 1 },
    { text: 'Número', colspan: 1 },
    { text: 'Saldo total', colspan: 1 },
    { text: 'Comercial', colspan: 2 },
    { text: 'Hipotecario', colspan: 2 },
    { text: 'Consumo y Tarjeta de Crédito', colspan: 2 },
    { text: 'Microcrédito', colspan: 2 },
    { text: 'Garantías', colspan: 3 },
    { text: 'Moneda', colspan: 1 },
    { text: 'Fuente', colspan: 1 },
  ];

  headersGroupedDataValue = [
    { text: '', colspan: 4 },
    { text: 'Número', colspan: 1 },
    { text: 'Miles $', colspan: 1 },
    { text: 'Número', colspan: 1 },
    { text: 'Miles $', colspan: 1 },
    { text: 'Número', colspan: 1 },
    { text: 'Miles $', colspan: 1 },
    { text: 'Número', colspan: 1 },
    { text: 'Miles $', colspan: 1 },
    { text: 'Tipo', colspan: 1 },
    { text: 'Fecha avalúo', colspan: 1 },
    { text: 'Valor', colspan: 1 },
  ];
  constructor(
    private helperCreditInfo: HelperCreditInfoService
  ) {}

  ngOnInit() {
    // console.log(
    //   'Global Debt Classified Data:',
    //   this.globalDebtClassifiedDataTable
    // );
    this.groupDataBySector();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Changes:', changes);
    if (
      changes.globalDebtClassifiedDataTable &&
      changes.globalDebtClassifiedDataTable.currentValue
    ) {
      this.groupDataBySector();
    }
  }

  groupDataBySector() {
    if (Array.isArray(this.globalDebtClassifiedDataTable)) {
      this.groupedData = this.globalDebtClassifiedDataTable.reduce(
        (acc: any, curr: any) => {
          const reportDate = new Date(curr.fechaReporte);
          const year = reportDate.getFullYear();
          const month = reportDate.getMonth() + 1;
          let trimestreEndMonth = '03';
          if (month >= 4 && month <= 6) {
            trimestreEndMonth = '06';
          } else if (month >= 7 && month <= 9) {
            trimestreEndMonth = '09';
          } else if (month >= 10 && month <= 12) {
            trimestreEndMonth = '12';
          }
          const quarterKey = `Trimestre ${year}/${trimestreEndMonth}`;
          if (!acc[quarterKey]) {
            acc[quarterKey] = {};
          }
          const sector = curr.Entidad.sector.toString().trim();
          if (!acc[quarterKey][sector]) {
            acc[quarterKey][sector] = {
              data: [],
              totalCreditos: 0,
              totalSaldoPendiente: 0,
              totalComercial: { numero: 0, saldo: 0 },
              totalHipotecario: { numero: 0, saldo: 0 },
              totalConsumo: { numero: 0, saldo: 0 },
              totalMicrocredito: { numero: 0, saldo: 0 },
            };
          }
          acc[quarterKey][sector].data.push(curr);
          acc[quarterKey][sector].totalCreditos += curr.numeroCreditos || 0;
          acc[quarterKey][sector].totalSaldoPendiente +=
            curr.saldoPendiente || 0;
            if (curr.tipoCredito === 'CMR') {
              acc[quarterKey][sector].totalComercial.numero += curr.numeroCreditos || 0;
              acc[quarterKey][sector].totalComercial.saldo += curr.saldoPendiente || 0;
            } else if (curr.tipoCredito === 'HIP') {
              acc[quarterKey][sector].totalHipotecario.numero += curr.numeroCreditos || 0;
              acc[quarterKey][sector].totalHipotecario.saldo += curr.saldoPendiente || 0;
            } else if (curr.tipoCredito === 'CNS') {
              acc[quarterKey][sector].totalConsumo.numero += curr.numeroCreditos || 0;
              acc[quarterKey][sector].totalConsumo.saldo += curr.saldoPendiente || 0;
            } else if (curr.tipoCredito === 'MIC') {
              acc[quarterKey][sector].totalMicrocredito.numero += curr.numeroCreditos || 0;
              acc[quarterKey][sector].totalMicrocredito.saldo += curr.saldoPendiente || 0;
            }

          return acc;
        },
        {}
      );
    } else if (
      typeof this.globalDebtClassifiedDataTable === 'object' &&
      this.globalDebtClassifiedDataTable !== null
    ) {
      const dataArray = Object.values(this.globalDebtClassifiedDataTable);
      this.groupedData = dataArray.reduce((acc: any, curr: any) => {
        const reportDate = new Date(curr.fechaReporte);
        const year = reportDate.getFullYear();
        const month = reportDate.getMonth() + 1;
        let trimestreEndMonth = '03';

        if (month >= 4 && month <= 6) {
          trimestreEndMonth = '06';
        } else if (month >= 7 && month <= 9) {
          trimestreEndMonth = '09';
        } else if (month >= 10 && month <= 12) {
          trimestreEndMonth = '12';
        }
        const quarterKey = `Trimestre ${year}/${trimestreEndMonth}`;
        if (!acc[quarterKey]) {
          acc[quarterKey] = {};
        }
        const sector = curr.Entidad.sector.toString().trim();
        if (!acc[quarterKey][sector]) {
          acc[quarterKey][sector] = {
            data: [],
            totalCreditos: 0,
            totalSaldoPendiente: 0,
          };
        }
        acc[quarterKey][sector].data.push(curr);
        acc[quarterKey][sector].totalCreditos += curr.numeroCreditos || 0;
        acc[quarterKey][sector].totalSaldoPendiente += curr.saldoPendiente || 0;
        return acc;
      }, {});
    } else {
      console.error(
        'globalDebtClassifiedDataTable no es un array ni un objeto válido:',
        this.globalDebtClassifiedDataTable
      );
      this.groupedData = {};
    }
    //    console.log('Grouped Data by Quarter:', this.groupedData);
  }

  traslateWarrantyType(code: string | number): string {
    const result = this.helperCreditInfo.findWarrantyOfGlobalIndebtednessClassified(code);
    return result.label;
  }
}
