import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { SelectModel } from '@app/models/select.model';
import { AppState } from '@app/redux/app.reducer';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { SelectsService } from '@app/services/selects.service';
import { TabsService } from '@app/services/tabs.service';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReturnedBackingsRequest } from '../models/request/returnedBackings.model';
import { AffiliatesListModel } from '../models/response/SearchAffiliate.model';
import { SearchAutocompleteAffiliateComponent } from '../search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { BackupsStoreService } from '../services/backups-store.service';

@Component({
  selector: 'app-returned-backings',
  templateUrl: './returned-backings.component.html',
  styleUrls: ['./returned-backings.component.scss']
})
export class ReturnedBackingsComponent implements OnInit {

  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;

  public activeTab: string = '';
  public parentTab: string = 'returned-backings';

  public navItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  public selectOptionsBranchOffices: SelectModel[] = [];

  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];
  public showModalOnError: boolean = false;

  public initDate: any;
  public finalDate: any;

  data: string;

  public cantPages = 0;
  public counterRequest: number = 0;

  public search: ReturnedBackingsRequest = new ReturnedBackingsRequest();

  public backingsToProcess: any[] = [];
  public backingsToProcessBD: ReturnedBackingsRequest[] = [];

  public selectedBackingToProcess: any[] = [];
  public filingStatusValidOptions: any[] = [];
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private tabs: TabsService, public helper: HelperService, private backupsService: BackupsService,
     private spinner: NgxSpinnerService, public selects: SelectsService, private cdRef: ChangeDetectorRef, private backupsStore: BackupsStoreService, private store: Store<AppState>) {
    this.navItemTabs = this.tabs.returnedBackingsTabs;
   
    /* this.backupsStore.stateBackupsActiveTab$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
    .subscribe(
      (stateBackupsActiveTab: string) => {
        this.activeTab = stateBackupsActiveTab;
        this.initilizeDateRangePicker();
        if (this.activeTab === this.parentTab) this.searchAutocompleteAffiliate.reset();
      });

  this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
    .subscribe((stateAffiliate: AffiliatesListModel) => {
      this.initAffiliate(stateAffiliate)
    });  */
  }

  ngOnInit(): void {
    this.store.select('selects').pipe(takeUntil(this.unsubscribe$)).subscribe(({ selects }) => this.getSelects(selects));
    this.backupsStore.stateBackupsActiveTab$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(
        (stateBackupsActiveTab: string) => {
          this.activeTab = stateBackupsActiveTab;
          this.initilizeDateRangePicker();
          if (this.activeTab === this.parentTab) {
            this.searchAutocompleteAffiliate.reset();
            this.showModalOnError = true;
            // this.getTransactionsToBackUp(1); 
          }
        });

    this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (stateAffiliate: AffiliatesListModel) => {
          //console.log('stateAffiliate on radicacion', stateAffiliate);
          this.initAffiliate(stateAffiliate);
        }
      );
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    
    const instanceModel = new ReturnedBackingsRequest();
    this.search = {
      ...this.helper.assignEnumerableKeysAndAssignDefaultValues(stateAffiliate, instanceModel),
      initialDate: this.search.initialDate,
      finalDate: this.search.finalDate
    };
    
    if (this.activeTab === this.parentTab && (this.search.affiliateDocument !== '0' || this.search.issuerDocument !== '0')) {
      this.showModalOnError = false;
      this.getBackingsToProcess(1);
    }
  }

  onSearch(textSearch: string) {
    // this.search.search = textSearch;
    this.getBackingsToProcess(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    //if (!this.validateAffiliate()) return;

    this.getBackingsToProcess(1);
  }

  validateAffiliate() {
    const { affiliateDocument, issuerDocument } = this.search;
    if (affiliateDocument && affiliateDocument !== '0' || issuerDocument && issuerDocument !== '0') {
      return true;
    }
    //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');
    return false;
  }

  getBackingsToProcess(currentPage: number): void {
    if(this.activeTab !== this.parentTab && (this.search.affiliateDocument === '0')) {
      return;
    }
      

    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = currentPage;
    this.backupsService.returnedBackings(this.search).subscribe(
      (backingsToProcess: any) => {
        if (backingsToProcess && backingsToProcess.ok === false) {
          this.handleGetBackingToProcessError();
          return;
        }
        this.notices = backingsToProcess.data;
        this.backingsToProcess = backingsToProcess['data'];
        this.backingsToProcessBD = this.backingsToProcess.map(backing => Object.assign({}, backing));
        this.fillValidOptions();
        // TODO: Revisar con project manager
        // if (this.backingsToProcess && this.backingsToProcess.length >= 1) {
        //   this.backingsToProcess.forEach((backingToProcess: BackingsToProcessModel) => {
        //     if (backingToProcess.filingStatus !== 'DEVUELTO') {
        //       backingToProcess.nonRefundCause = '';
        //     }
        //   });
        // }
        this.cantPages = backingsToProcess['totalPages'];
        this.selectedBackingToProcess = [];
        this.spinner.hide();
        this.searchAutocompleteAffiliate.toggleResults(false);
      }, (error: any) => {
        this.spinner.hide();
        this.handleGetBackingToProcessError(error);
      }
    );
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getBackingsToProcess(currentPage);
  }

  getSelects(selects: any) {
    this.selectOptionsBranchOffices = selects.branchOffices || [new SelectModel()];
  }

  onSelectBranch(selectedbranch: string) {
    this.search.branchOffice = selectedbranch;
    if (!this.validateAffiliate()) return;
    this.getBackingsToProcess(1);
  }

  fillValidOptions() {
    this.backingsToProcess.forEach((backing, idx) => {
      this.getValidFilingStatusOptions(backing, idx);
    });
  }

  getValidFilingStatusOptions(backing: any, idx: number) {
    const { filingStatus } = backing;
    this.selects.getSelectsWithParams('changeBackingsStatus', filingStatus)
      .subscribe(
        (changeBackingsStatus: SelectModel[]) => {
          this.filingStatusValidOptions[idx] = changeBackingsStatus || [];
          this.backingsToProcess[idx].filingStatus = this.backingsToProcess[idx].filingStatus;
          this.cdRef.detectChanges();
        },
        (error) => []
      );
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  handleGetBackingToProcessError(error?: any) {

    console.error(error);
    if (this.showModalOnError === false) {
      if (error && error.error && error.error.message && this.counterRequest > 1) {
        const { message } = error.error;
        Swal.fire({
          type: 'error', title: 'Respaldos', text: `${message}`, allowOutsideClick: false
        });
      } else if (this.counterRequest > 1) {
        this.helper.handleUndefinedError();
      }
    }
    
    this.backingsToProcess = [];
    this.selectedBackingToProcess = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
    this.fillValidOptions();
  }

}
