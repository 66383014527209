export class SelectModel {
  public value: string;
  public description: string;
  public state?: string;
  public disabled?: boolean;
}

export class SelectModelSubGroup{
  public id: number;
  public name: string;
  public subgroups: SelectModel [];
}

export class SelectModelPeriod{
  public value: string;
  public description: string;
}

export class NumberInstallments{
  public id: number;
  public value: number;
}

export class NumberInstallmentAmortizations{
  public id: number;
  public value: number;
}

export class BillingCuts{
  public id: number;
  public value: number;
}

export class StatusBillings{
  public id: number;
  description: string;
}
export class YesOrNot{
  public id: number;
  public description: string;
}