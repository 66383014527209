import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AntiguedadDesde, CaracterFechaAnalisisVectoresInfo, CaracterFechaCuentaAnalisisVectoresInfo, Consulta, ConsultaUlt6Meses, CreditosCerrados, CreditosReestructurados, CreditosRefinanciados, CreditosVigentes, CuentaAnalisisVectoresInfo, CuentaCartera, CuentaCarteraAdjetivo, CuentaCarteraValor, CuentaCarteraValores, CuentaEndeudamientoActual, Desacuerdos, EstadoPagoCuentaCartera, EstadosCuentaCartera, EvolucionDeuda, EvolucionDeudaSector, ResumenEndeudamiento, EvolucionDeudaTipoCuenta, EvolucionDeudaValorTrimestre, GetCreditHistoryResponse, PerfilGeneralInfoAgregadaMicrocredito, RazonScore, SaldosYmoraVectorSaldosYmoras, SectorAnalisisVectoresInfo, SectorEndeudamientoActual, TipoCuentaEndeudamientoActual, VectorSaldosYmorasInfoAgregadaMicrocredito, TarjetaCredito, TarjetaCreditoValores, TarjetaCreditoValor, CuentaAhorro, ValoresCuentaAhorro, ValorCuentaAhorro, AdjetivoCuentaAhorro, CuentaCorriente, CuentaCorrienteAdjetivo, TarjetaCreditoAdjetivo, TarjetaCreditoEstados, TarjetaCreditoEstadoPago, EstadoCuentaAhorro, EstadoCuentaCorriente, IdentificacionNaturalNacional, EdadNaturalNacional, UbicacionNaturalNacional, GetCreditHistoryInforme, Score } from '../models/credit-history.model';
import { ExpirianListsService } from './expirian-lists.service';
import { ExpirianListsResponse, SectorCode } from '../models/expirian-lists.model';
import { HelperCreditInfoService, ParamsToDetermineState } from './helper-credit-info.service';

export interface IBasicInformation {
  documentType: string
  document: string
  documentState: string
  expeditionLocation: string
  expeditionDate: string
  fullname: string
  ageRange: string
  gender: string
  rut: string
  locationAge: string
}

export interface IScoreInformation {
  type: string
  evaluation: string
  score: number
  reason: string
}

export interface IQueryHistory {
  lastConsultationDate: string
  entity: string
  numberQueriesPerMonth: string
}

export interface GeneralProfileRecord {
  category: string
  sectorTelcos?: number | string
  sectorCooperativo?: number | string
  sectorReal?: number | string
  sectorFinanciero?: number | string
  totalSectores?: number | string
  totalComoCodeudorYOtros?: number | string
  totalComoPrincipal?: number | string
}

export interface IVectorAnalysis {
  sector: string
  headers: string[]
  accounts: IVectorAccount[]
}

export interface IVectorAccount {
  values: any[]
}

export interface DebtEvolution {
  sector: string
  headers: string[]
  accounts: any[][]
}

export interface CreditNote {
  date: string
  description: string
}

export interface CreditHistoryAlert {
  source: string
  date: string
  novelty: string
  description: string
}

export interface CurrentDebt {
  initialQuota: number
  currentBalance: number
  outstandingBalance: number
  quotaValue: number
  percentagePart: number
  percentageDebt: number
}

export interface CurrentDebtSector {
  sector: string | number,
  headers: string[]
  accountTypes: CurrentDebtAccountType[]
}

export interface CurrentDebtAccountType {
  accountType: string
  accountPerQuality: CurrentDebtAccountPerQuality[]
}

export interface CurrentDebtAccountPerQuality {
  quality: string,
  accounts: AccountCurrentDebt[]
}

export interface AccountCurrentDebt {
  name: string
  quality: string
  num: number | string
  currentStatus: string
  category: string
  initialQuota: number | string
  currentBalance: number | string
  outstandingBalance: number | string
  quotaValue: number | string
  percentagePart: number | string
  percentageDebt: number | string
}

export interface DisagreementWithInformation {
  disagreement: string
  number: string
  status: string
  date: string
  entity: string
}

export interface ImpersonationClaim {
  legend: string
  accountNumber: string | number
  entity: string
}

export interface GlobalDebtSummaryRecord {
  cutoffDate: string
  sectores: GlobalDebtSummarySector[]
}

export interface GlobalDebtSummarySector {
  name: string
  commercialNum: number | string
  commercialMil: number | string
  mortgageNum: number | string
  mortgageMil: number | string
  consumerAndCreditCardNum: number | string
  consumerAndCreditCardMil: number | string
  microcreditNum: number | string
  microcreditMil: number | string
  participationPercentage: string | number
}

export interface GlobalClassifiedDebt {
  trimestre: string
  sectores: GlobalClassifiedDebtSector[]
}

export interface GlobalClassifiedDebtSector {
  name: string
  accounts: GlobalClassifiedDebtAccount[]
}

export interface GlobalClassifiedDebtAccount {
  entity: string
  clasification: string
  num: number | string
  totalBalance: number | string
  commercialNum: number | string
  commercialMil: number | string
  mortgageNum: number | string
  mortgageMil: number | string
  consumerAndCreditCardNum: number | string
  consumerAndCreditCardMil: number | string
  microcreditNum: number | string
  microcreditMil: number | string
  warrantyType: string
  warrantyDate: string
  warrantyValue: string | number
  currency: string
  source: string
}

export interface SummaryBalancesAndArrears {
  headers: string[]
  records: string[][]
}

export interface HabitOfPaymentActive {
  sector: string
  accounts: HabitOfPaymentActiveAccount[]
}

export interface HabitOfPaymentActiveAccount {
  sector: string | number
  entity: string
  accountType: string
  accountNumber: string | number
  clasification: string | number
  obligationStatus: string
  updateDate: string
  adjectiveDate: string
  openingDate: string
  expirationDate: string
  maxArrears: string | number
  behavior: string
  informationDisagreement: string
  ownerStatus: string | number
  brand: string
  warrantyType: string | number
  initialQuota: string | number
  currentBalance: string | number
  balanceInArrears: string | number
  quota: string | number
  paymentDeadline: string
  paymentDate: string
  perm: string | number
  numReturnChecks: string | number
  quotaAndTerms: string | number
  percentageDebt: string | number
  officeAndDebtor: string
}

export interface HabitOfPaymentInactive {
  sector: string
  accounts: HabitOfPaymentInactiveAccount[]
}

export interface HabitOfPaymentInactiveAccount {
  sector: number | string
  entity: string
  accountType: string
  obligationStatus: string
  clasification: string | number
  adjectiveDate: string
  accountNumber: string | number
  openingDate: string
  closingDate: string
  initialQuota: string | number
  cityAndDate: string
  officeAndDebtor: string
  informationDisagreement: string
  behavior: string
}

@Injectable({
  providedIn: 'root'
})
export class SubjectCreditHistoryService {

  /**
   * =============== README ===============
   * ¿Qué es este servicio? - Este servicio se encarga exlusivamnet de almacenar la respuesta del servicio HDC y a partir de este,
   * todos los componentes de la vista se suscriben para consultar la respuesta obtenidad al ejecutar el servicio y através
   * de funciones, obtener exclusivamente la data requerida para "X" componente.
   * 
   * ¿Qué son todos estos métodos o funciones? - Los métodos public son los métodos que se utilizan en los componentes que
   * requiere una información especifica de la respuesta. 
   * Los métodos private, son funciones auxiliares para generar data y evitar que las funciones publicas sean extensas
   * 
   * ¿Cuál es el proposito de las funciones? - El principal proposito de las funciones, es generar nuevos objetos que sean
   * recorridos en el template (HTML) de manera sencilla y que solo sean recorrerlos en el HTML. Por lo que se debe
   * de tener precaución en los tipos de retornos de cada función, ya que la vista espera esos tipos especificos para 
   * recorrer el resultado.
   * 
   * ¿Por que hay tanta lógica para obtener los datos? - Tener presente que la response es obtenidad a partir de un XML que luego
   * fue parseado a un JSON. Así, que hay posibilidad de que los datos varien, una consulta puede traer objetos como en otra puede
   * ser un array. Así, que toca ser muy precavido con la información que se trata de extraer. Y por naturaleza, la estructura
   * de la respuesta es compleja.
   * 
   * ¿Como utilizar el servicio? - Se debe de inyectar el servicio, hacer una susbcripción al responseGetCreditHistory 
   * y al momento de recibir la response, se debe llamar una función del servicio para obtener la data requerida.
   * 
   * ¿El tipado de la response es válido? - Si, en caso de detectar un nuevo atributo o un atributo variable, actualizar la interface
   * de la response :)
   * =============== README ===============
   */

  // En este BehaviorSubject se almacena la respuesta del servicio para generar la vista HDC
  private responseGetCreditHistory = new BehaviorSubject<GetCreditHistoryResponse | undefined>(undefined);
  public responseGetCreditHistory$ = this.responseGetCreditHistory.asObservable();

  constructor(
    private expirianListsService: ExpirianListsService,
    private helperInfoService: HelperCreditInfoService
  ) {

  }

  setResponseGetCreditHistory(newValue: GetCreditHistoryResponse | undefined) {
    this.responseGetCreditHistory.next(newValue);
  }

  /**
   * Función que genera los valores necesarios para generar la tabla de información básica
   * a partir de la response del servicio HDC
   * @param response 
   * @returns IBasicInformation
   */
  getBasicInformation(response: GetCreditHistoryResponse): IBasicInformation {
    const responseData = response.data[0];
    const defaultValue = '--';

    if (responseData.Informes.Informe.NaturalNacional) {
      const defaultIdentificacion: IdentificacionNaturalNacional = {
        ciudad: defaultValue,
        departamento: defaultValue,
        estado: defaultValue,
        fechaExpedicion: defaultValue,
        genero: defaultValue,
        numero: defaultValue
      }
      const defaultEdad: EdadNaturalNacional = {
        min: 0,
        max: 0
      }
      const defaultUbicacion: UbicacionNaturalNacional = {
        antiguedad: defaultValue,
        tipo: defaultValue
      };
      const { Identificacion = defaultIdentificacion, nombreCompleto = defaultValue, rut: rutOrigen = false, Edad = defaultEdad, Ubicacion = defaultUbicacion
      } = responseData.Informes.Informe.NaturalNacional;

      const documentType: string = responseData.desc_tipo_identificacion ? responseData.desc_tipo_identificacion : defaultValue;
      const document: string = responseData.identidad ? responseData.identidad : defaultValue;
      const documentState: string = Identificacion.estado ? Identificacion.estado : defaultValue;
      const expeditionLocation: string = Identificacion.ciudad ? Identificacion.ciudad : defaultValue;
      const expeditionDate: string = Identificacion.fechaExpedicion || defaultValue;
      const fullname: string = nombreCompleto;
      const gender: string = `${Identificacion.genero}`;
      const rut: string = rutOrigen ? 'Si' : 'No';
      const locationAge: string = Ubicacion.antiguedad;

      const minAgeRange: string = `${Edad.min}`;
      const maxAgeRange: string = `${Edad.max}`;
      const ageRange: string = `${minAgeRange} - ${maxAgeRange}`;

      return {
        documentType, document, documentState, expeditionLocation,
        expeditionDate, fullname, ageRange, gender, rut, locationAge
      };
    }

    return {
      documentType: defaultValue, document: defaultValue, documentState: defaultValue, expeditionLocation: defaultValue,
      expeditionDate: defaultValue, fullname: defaultValue, ageRange: defaultValue, gender: defaultValue, rut: defaultValue, locationAge: defaultValue
    };
  }


  /**
   * Función que genera los valores necesarios para generar la tabla del Score
   * a partir de la response del servicio HDC
   * @param response 
   * @returns 
   */
  getScoreInformation(response: GetCreditHistoryResponse): IScoreInformation[] {
    if (!response || !response.data[0] || !response.data[0].Informes || !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.Score
    ) return [];

    const responseData = response.data[0];
    const defaultValue = '--';

    let scoreFromResponse = responseData.Informes.Informe.Score ? responseData.Informes.Informe.Score : [];

    // Puede ser un objeto o un array
    if (!Array.isArray(scoreFromResponse)) scoreFromResponse = [scoreFromResponse];

    const result: IScoreInformation[] = scoreFromResponse.map((item: Score) => {

      const { tipo: type = defaultValue, puntaje: score = 0, Razon: originReasons = [] } = item;
      const evaluation: string = defaultValue;
      const reason: string = this.getReason(originReasons);

      return {
        type, evaluation, score, reason
      };
    });

    return result;
  }

  /**
   * Función que imprime los códigos de las razones del score
   * @param reasons 
   * @returns 
   */
  private getReason(reasons: RazonScore[]): string {
    if (!reasons || reasons.length === 0) return '--';

    const result: string = reasons.map((item: RazonScore) => (item.codigo || '--')).join(',');

    return result;
  }

  /**
 * Función que genera los valores necesarios para generar la tabla de historico de consultas
 * a partir de la response del servicio HDC
 * @param response 
 * @returns 
 */
  getQueriesHistory(response: GetCreditHistoryResponse): IQueryHistory[] {
    if (!response.data[0] || !response.data[0].Informes || !response.data[0].Informes.Informe || !response.data[0].Informes.Informe.Consulta) return [];

    let queries: Consulta[] = response.data[0].Informes.Informe.Consulta;
    if (!Array.isArray(queries)) queries = [queries];

    const result: IQueryHistory[] = queries.map((query: Consulta) => {
      const { fecha = '--', entidad = '--', cantidad = '--' } = query;
      return {
        lastConsultationDate: fecha,
        entity: entidad,
        numberQueriesPerMonth: cantidad
      }
    });

    return result;
  }


  /**
  * Función que genera los valores necesarios para generar la tabla del perfil general
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getGeneralProfile(response: GetCreditHistoryResponse): GeneralProfileRecord[] {

    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.PerfilGeneral) return [];

    const generalProfile: PerfilGeneralInfoAgregadaMicrocredito = response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.PerfilGeneral;

    const creditActive: GeneralProfileRecord = { category: 'Créditos Vigentes', ...generalProfile.CreditosVigentes };
    const creditInactive: GeneralProfileRecord = { category: 'Créditos Cerrados', ...generalProfile.CreditosCerrados };
    const restructuredCredits: GeneralProfileRecord = { category: 'Créditos Reestructurados', ...generalProfile.CreditosReestructurados };
    const refinancedCredits: GeneralProfileRecord = { category: 'Créditos Refinanciados', ...generalProfile.CreditosRefinanciados };
    const consultUlt6Months: GeneralProfileRecord = { category: 'Consultas en los últimos 6 Meses', ...generalProfile.ConsultaUlt6Meses };
    const disagreements: GeneralProfileRecord = { category: 'Desacuerdos Vigentes a la Fecha', ...generalProfile.Desacuerdos };
    const ageSince: GeneralProfileRecord = { category: 'Antigüedad desde', ...generalProfile.AntiguedadDesde };

    const result: GeneralProfileRecord[] = [
      creditActive, creditInactive, restructuredCredits,
      refinancedCredits, consultUlt6Months, disagreements, ageSince
    ]

    const resultWithTotal: GeneralProfileRecord[] = this.calculateResultWithTotalFromGeneralProfile(result);

    return resultWithTotal;
  }

  /**
   * Función que genera los totales del perfil general
  * @param response 
  * @returns 
  */
  private calculateResultWithTotalFromGeneralProfile(result: GeneralProfileRecord[]): GeneralProfileRecord[] {
    const resultWithTotal: GeneralProfileRecord[] = result.map((item: GeneralProfileRecord) => {
      const valueSectorCooperativo: number | string = item.sectorCooperativo;
      const valueSectorFinanciero: number | string = item.sectorFinanciero;
      const valueSectorReal: number | string = item.sectorReal;
      const valueSectorTelcos: number | string = item.sectorTelcos;

      const shouldDoSum: boolean = (typeof valueSectorCooperativo === 'number' && typeof valueSectorFinanciero === 'number' &&
        typeof valueSectorReal === 'number' && typeof valueSectorTelcos === 'number');

      if (shouldDoSum) {
        const total: number = Number(valueSectorCooperativo) + Number(valueSectorFinanciero) + Number(valueSectorReal) + Number(valueSectorTelcos);
        return { ...item, totalSectores: total }
      }

      return { ...item, totalSectores: '-' }
    })

    return resultWithTotal;
  }

  /**
  * Función que genera los valores necesarios para generar las tablas del analisis de vectores por sector
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getVectorAnalysis(response: GetCreditHistoryResponse): IVectorAnalysis[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.AnalisisVectores ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.AnalisisVectores.Sector
    ) return [];

    const sectores: SectorAnalisisVectoresInfo[] = response.data[0].Informes.Informe.InfoAgregadaMicrocredito.AnalisisVectores.Sector || [];
    const dates: string[] = this.getDatesLabelOfVectors(sectores[0]);
    const headers: string[] = ['Entidad', 'Número Cuenta 9 dígitos', 'Tipo Cuenta', 'Estado', ...dates];

    return sectores.map((item: SectorAnalisisVectoresInfo) => {
      let accountFromItem: CuentaAnalisisVectoresInfo | CuentaAnalisisVectoresInfo[] = item.Cuenta;

      if (accountFromItem && !Array.isArray(accountFromItem)) accountFromItem = [accountFromItem]; // Puede ser un objeto o un array

      const accounts: IVectorAccount[] = this.getAccountsOfVectors(accountFromItem);

      return {
        sector: item.nombreSector || '-',
        headers,
        accounts
      }
    });
  }

  /**
   * Función que obtiene las fecha para las columnas del analisis de vectores
   * @param vector 
   * @returns 
   */
  private getDatesLabelOfVectors(vector: SectorAnalisisVectoresInfo): string[] {
    if (!vector || !vector.MorasMaximas.CaracterFecha) return [];

    const dates: string[] = vector.MorasMaximas.CaracterFecha.map((item: CaracterFechaAnalisisVectoresInfo) => {
      return item.fecha || '-'
    })

    return dates;
  }

  /**
   * Función que obtiene las cuentas de los vectores
   * @param accounts 
   * @returns 
   */
  private getAccountsOfVectors(accounts: CuentaAnalisisVectoresInfo[]): IVectorAccount[] {
    if (!accounts || accounts.length === 0) return [];

    return accounts.map((item: CuentaAnalisisVectoresInfo) => {
      const entity: string = item.entidad || '-';
      const account: string = item.numeroCuenta || '-';
      const accountType: string = item.tipoCuenta || '-';
      const accountStatus: string = item.estado || '-';

      const dateValues: string[] = item.CaracterFecha.map((date: CaracterFechaCuentaAnalisisVectoresInfo) => {
        return date.saldoDeudaTotalMora || '-';
      }) || [];

      return { values: [entity, account, accountType, accountStatus, ...dateValues] }
    });
  }

  /**
  * Función que genera los valores necesarios para generar la tabla de evolución de la deuda
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getDebtEvolution(response: GetCreditHistoryResponse): DebtEvolution[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.EvolucionDeuda
    ) return [];

    const data: EvolucionDeuda = response.data[0].Informes.Informe.InfoAgregadaMicrocredito.EvolucionDeuda || {} as EvolucionDeuda;
    const defaultHeaders: string[] = ['Tipo cuenta', 'Valores'];

    let headers = (data.Trimestres.Trimestre || []).map((item: string) => (`Trimestre ${item}`));
    headers = [...defaultHeaders, ...headers];

    let sectores: EvolucionDeudaSector[] = data.EvolucionDeudaSector || [];

    // Puede ser un objeto o un array
    if (!Array.isArray(sectores)) sectores = [sectores];

    const evolutionsPerSector: DebtEvolution[] = sectores.map((item: EvolucionDeudaSector) => {
      const sector: string = item.nombreSector || '-';
      let accounts: any[][] = [];

      // Según la respuesta puede ser un array o un objeto
      if (Array.isArray(item.EvolucionDeudaTipoCuenta)) {
        item.EvolucionDeudaTipoCuenta.forEach((account: EvolucionDeudaTipoCuenta) => {
          accounts.push(this.getValuesFromEvolution(account));
        });
      } else {
        accounts.push(this.getValuesFromEvolution(item.EvolucionDeudaTipoCuenta));
      }

      const rowTotalPerSector: any[][] = this.calculateTotalEvolutionPerSector(sector, accounts);

      return {
        sector,
        headers,
        accounts: [
          ...accounts,
          rowTotalPerSector
        ]
      };
    });

    return evolutionsPerSector;
  }

  /**
   * Funcion que calcula el total de evolución por sector
   * @param sectorName 
   * @param accounts 
   * @returns 
   */
  private calculateTotalEvolutionPerSector(sectorName: string, accounts: any[][]): any[][] {
    let result: any[] = ['Total', `Saldo ${sectorName}`];

    accounts.forEach((account: any[], indexAccount: number) => {
      account.forEach((row: any[], indexRow: number) => {
        row.forEach((value: any, indexValue: number) => {

          // La fila 2, es la fila del saldo y el mayor > 1 es para tomar el valor de los trimestres excluyendo los labels del principio
          if (indexRow === 2 && indexValue > 1) {
            if (!result[indexValue]) result[indexValue] = 0;
            result[indexValue] += (typeof value === 'number' && !isNaN(Number(value))) ? Number(value) : 0;
          }
        })
      })
    });
    return [result];
  }


  /**
  * Función que extrae los valores de la evolución de la deuda
  * @param response 
  * @returns 
  */
  private getValuesFromEvolution(evolutionTipoCuenta: EvolucionDeudaTipoCuenta): any[][] {
    if (!evolutionTipoCuenta) return [];

    const rows: EvolucionDeudaValorTrimestre[] = evolutionTipoCuenta.EvolucionDeudaValorTrimestre || [];
    const accountType: string = evolutionTipoCuenta.tipoCuenta || '-';
    let num: any[] = [accountType, 'Num'];
    let initialQuota: any[] = [accountType, 'Valor o Cupo Inicial'];
    let balance: any[] = [accountType, 'Saldo'];
    let outstandingBalance: any[] = [accountType, 'Saldo en Mora'];
    let quotaValue: any[] = [accountType, 'Valor Cuota'];
    let debetPercentage: any[] = [accountType, '% Deuda'];
    let clasification: any[] = [accountType, '< Calificación'];

    rows.forEach((item: EvolucionDeudaValorTrimestre) => {
      num.push(item.num || 0);
      initialQuota.push(item.cupoInicial || 0);
      balance.push(item.saldo || 0);
      outstandingBalance.push(item.saldoMora || 0);
      quotaValue.push(item.cuota || 0);
      debetPercentage.push(item.porcentajeDeuda || '-');
      clasification.push(item.textoMenorCalificacion || '-');
    });

    const result: any[][] = [num, initialQuota, balance, outstandingBalance, quotaValue, debetPercentage, clasification];

    return result;
  }

  /**
  * Función que genera los valores necesarios para generar la tabla Información de endeudamiento actual
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getCurrentDebtInformation(response: GetCreditHistoryResponse): CurrentDebt | undefined {
    if (!response) return undefined;

    const currentDebtTotal: CurrentDebt = {
      initialQuota: 0,
      currentBalance: 0,
      outstandingBalance: 0,
      quotaValue: 0,
      percentagePart: 100,
      percentageDebt: 0
    };

    const sectores: CurrentDebtSector[] = this.getSectoresFromCurrentDebt(response);

    sectores.forEach((sector: CurrentDebtSector) => {
      sector.accountTypes.forEach((accountType: CurrentDebtAccountType) => {
        accountType.accountPerQuality.forEach((quality: CurrentDebtAccountPerQuality) => {
          quality.accounts.forEach((account: AccountCurrentDebt) => {
            currentDebtTotal.initialQuota += typeof account.initialQuota === 'number' ? account.initialQuota : 0;
            currentDebtTotal.currentBalance += typeof account.currentBalance === 'number' ? account.currentBalance : 0;
            currentDebtTotal.outstandingBalance += typeof account.outstandingBalance === 'number' ? account.outstandingBalance : 0;
            currentDebtTotal.quotaValue += typeof account.quotaValue === 'number' ? account.quotaValue : 0;
            // currentDebtTotal.percentagePart += typeof account.percentagePart === 'number' ? account.percentagePart : 0;
            // currentDebtTotal.percentageDebt += typeof account.percentageDebt === 'number' ? account.percentageDebt : 0;
          })
        })
      })
    });

    const percentageDebt: number = Math.round(((currentDebtTotal.currentBalance / currentDebtTotal.initialQuota) * 100) * 10) / 10;
    currentDebtTotal.percentageDebt = isNaN(percentageDebt) ? 0 : percentageDebt;

    return currentDebtTotal;
  }

  /**
  * Función que genera los valores necesarios para generar las tablas de endeudamiento actual por sector
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getSectoresFromCurrentDebt(response: GetCreditHistoryResponse): CurrentDebtSector[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.EndeudamientoActual ||
      !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.EndeudamientoActual.Sector
    ) return [];

    const sectoresFromResponse: SectorEndeudamientoActual[] | SectorEndeudamientoActual = response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.EndeudamientoActual.Sector || [];
    let sectores: SectorEndeudamientoActual[] = [];

    // El atributo puede ser un objeto en vez de un array cuando es un solo tiene sector
    (!Array.isArray(sectoresFromResponse)) ? sectores = [sectoresFromResponse] : sectores = sectoresFromResponse;

    if (!sectores || sectores.length === 0) return [];

    const headers: string[] = ['Carteras', 'Calidad', 'Número', 'Estado Actual', 'Calificación',
      'Valor o cupo inicial', 'Saldo Actual', 'Saldo en Mora', 'Valor Cuota', '% Part', '% Deuda'];

    const finalSectores: CurrentDebtSector[] = sectores.map((sector: SectorEndeudamientoActual) => {
      const searchedSector: SectorCode | undefined = this.expirianListsService.findSectorByCode(sector.codSector);
      const sectorName: string | number = (searchedSector && searchedSector.descripcion) ? searchedSector.descripcion : '--';
      const sectorTipoCuenta: TipoCuentaEndeudamientoActual | TipoCuentaEndeudamientoActual[] = sector.TipoCuenta || [];

      const accountTypes: TipoCuentaEndeudamientoActual[] = (!Array.isArray(sectorTipoCuenta)) ? [sectorTipoCuenta] : sectorTipoCuenta;
      // const accounts: AccountCurrentDebt[] = this.getAccountsPerSectorFromCurrentDebt(accountTypes);
      const accountsPerAccountType: CurrentDebtAccountType[] = this.getAccountsPerAccountType(accountTypes);

      return {
        headers,
        sector: sectorName,
        accountTypes: accountsPerAccountType
      }
    });

    console.log('Registros que se envian a la tabla para renderizar::', finalSectores);
    return finalSectores;
  }

  /**
   * Función que extraer las cuenta por tipo de cuenta del endeudamiento actual
   * @param accountTypes 
   * @returns 
   */
  private getAccountsPerAccountType(accountTypes: TipoCuentaEndeudamientoActual[]): CurrentDebtAccountType[] {
    if (!accountTypes || accountTypes.length === 0) return [];
    const results: CurrentDebtAccountType[] = accountTypes.map((accountType: TipoCuentaEndeudamientoActual) => {
      const name: string = accountType.tipoCuenta || '--';
      const quality: string = accountType.Usuario.tipoUsuario || '--';
      let accounts: CuentaEndeudamientoActual[] = accountType.Usuario.Cuenta || [];

      // Puede que venga en un objeto
      if (!Array.isArray(accounts)) accounts = [accounts];

      let accountsPerQuality: CurrentDebtAccountPerQuality[] = [];
      let accountsToTable: AccountCurrentDebt[] = [];

      accounts.forEach((account: CuentaEndeudamientoActual) => {
        const { estadoActual = '--',
          calificacion = '--',
          valorInicial = 0,
          saldoActual = 0,
          saldoMora = 0,
          cuotaMes = 0 } = account || {} as CuentaEndeudamientoActual;

        const num: string = `${accounts.length >= 0 ? accounts.length : '-'}`;
        const currentStatus: string = estadoActual;
        const category: string = calificacion;
        const initialQuota: number | string = valorInicial;
        const currentBalance: number | string = saldoActual;
        const outstandingBalance: number | string = saldoMora;
        const quotaValue: number | string = cuotaMes;
        const percentageDebt: string | number = (typeof saldoActual === 'number'
          && typeof valorInicial === 'number') ? Math.round(((saldoActual / valorInicial) * 100) * 10) / 10 : '-';

        const percentagePart: number | string = '--';

        const accountToTable: AccountCurrentDebt = {
          name, quality, num, currentStatus, category,
          initialQuota, currentBalance, outstandingBalance,
          quotaValue, percentagePart, percentageDebt
        };

        accountsToTable.push(accountToTable);
      });

      accountsPerQuality.push({
        quality,
        accounts: accountsToTable
      })

      return {
        accountType: name,
        accountPerQuality: accountsPerQuality
      }
    });
    return results;
  }

  // TODO: INTEGRAR DATA CORRECTAMENTE
  getDisagreementWithInformation(response: GetCreditHistoryResponse): DisagreementWithInformation[] {
    if (!response) return [];



    return [
      { disagreement: 'XX', number: 'XX', status: 'XX', date: 'XX', entity: 'XX' }
    ];
  }

  // TODO: INTEGRAR DATA CORRECTAMENTE
  getImpersonationClaims(response: GetCreditHistoryResponse): ImpersonationClaim[] {
    if (!response) return [];

    return [
      { legend: 'XX', accountNumber: 'XX', entity: 'XX' }
    ];
  }

  /**
   * Funcion que genera la data para generar la tabla de Resumen de endeudamiento global
   * @param response 
   * @returns 
   */
  getGlobalDebtSummary(response: GetCreditHistoryResponse): ResumenEndeudamiento | [] {
    console.log('response', response);
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregada ||
      !response.data[0].Informes.Informe.InfoAgregada.ResumenEndeudamiento
    ) return [];

    return response.data[0].Informes.Informe.InfoAgregada.ResumenEndeudamiento;
  }

  // TODO: INTEGRAR DATA CORRECTAMENTE
  getNotesFromCreditHistory(response: GetCreditHistoryResponse): CreditNote[] {
    if (!response) return [];


    return [
      { date: 'XX', description: 'XX' }
    ];
  }

  // TODO: INTEGRAR DATA CORRECTAMENTE
  getAlertsFromCreditHistory(response: GetCreditHistoryResponse): CreditHistoryAlert[] {
    if (!response) return [];

    return [
      { source: 'XX', date: 'XX', novelty: 'XX', description: 'XX' }
    ];
  }

  /**
   * Funcion que genera la data para generar la tabla de endeudamiento clasificado
   * @param response 
   * @returns 
   */
  getGlobalClassifiedDebt(response: GetCreditHistoryResponse): any {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.EndeudamientoGlobal
    ) return [];

    return response.data[0].Informes.Informe.EndeudamientoGlobal;
  }

  /**
  * Función que genera los valores necesarios para generar la tabla del vector de saldos y mora
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getSummaryBalancesAndArrears(response: GetCreditHistoryResponse): SummaryBalancesAndArrears {

    if (!response ||
      (!response.data[0] || !response.data[0].Informes ||
        !response.data[0].Informes.Informe || !response.data[0].Informes.Informe.InfoAgregadaMicrocredito ||
        !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen ||
        !response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.VectorSaldosYMoras)
    ) return { headers: [], records: [] }

    const vectorBalancesAndArrears: VectorSaldosYmorasInfoAgregadaMicrocredito = response.data[0].Informes.Informe.InfoAgregadaMicrocredito.Resumen.VectorSaldosYMoras || {} as VectorSaldosYmorasInfoAgregadaMicrocredito;

    const { SaldosYMoras: balancesAndArrears = [], poseeSectorFinanciero = false,
      poseeSectorCooperativo = false, poseeSectorReal = false,
      poseeSectorTelcos = false } = vectorBalancesAndArrears;

    let headers: string[] = ['Saldos y Moras'];
    let totalDebtArrears: string[] = ['Saldo Deuda Total en Mora (en miles)'];
    let totalDebt: string[] = ['Saldo Deuda Total (en miles)'];

    let maxArrearsSectorFinanciero: string[] = ['Moras máx Sector Financiero'];
    let maxArrearsSectorCooperativo: string[] = ['Moras máx Sector Cooperativo'];
    let maxArrearsSectorReal: string[] = ['Moras máx Sector Real'];
    let maxArrearsSectorTelcos: string[] = ['Moras máx Sector Telcos '];

    let totalMaxArrears: string[] = ['Total Moras Máximas'];
    let numCreditsInArrears30: string[] = ['Número créditos con mora = 30'];
    let numCreditsInArrears60: string[] = ['Número créditos con mora >= 60'];

    balancesAndArrears.forEach((item: SaldosYmoraVectorSaldosYmoras) => {
      const { fecha = 'xxxx-xx-xx', saldoDeudaTotalMora = 0, saldoDeudaTotal = 0,
        numCreditos30 = 0, numCreditosMayorIgual60 = 0, morasMaximas = '-',
        morasMaxSectorFinanciero = '-', morasMaxSectorCooperativo = '-',
        morasMaxSectorReal = '-', morasMaxSectorTelcos = '-',
      } = item;
      const date = this.formattedHeaderA(fecha);
      headers.push(date);
      totalDebtArrears.push(`${saldoDeudaTotalMora}`);
      totalDebt.push(`${saldoDeudaTotal}`);
      totalMaxArrears.push(`${morasMaximas}`);
      numCreditsInArrears30.push(`${numCreditos30}`);
      numCreditsInArrears60.push(`${numCreditosMayorIgual60}`);
      maxArrearsSectorFinanciero.push(`${morasMaxSectorFinanciero}`);
      maxArrearsSectorCooperativo.push(`${morasMaxSectorCooperativo}`)
      maxArrearsSectorReal.push(`${morasMaxSectorReal}`)
      maxArrearsSectorTelcos.push(`${morasMaxSectorTelcos}`)
    });

    let sectors: string[][] = [];

    if (poseeSectorFinanciero) sectors.push(maxArrearsSectorFinanciero);
    if (poseeSectorCooperativo) sectors.push(maxArrearsSectorCooperativo);
    if (poseeSectorReal) sectors.push(maxArrearsSectorReal);
    if (poseeSectorTelcos) sectors.push(maxArrearsSectorTelcos);

    const records: string[][] = [
      totalDebtArrears,
      totalDebt,
      ...sectors,
      totalMaxArrears,
      numCreditsInArrears30,
      numCreditsInArrears60
    ];

    return {
      headers,
      records
    }
  }

  /**
   * Función para parsear la fecha
   * @param date : Fecha a parsear
   * @returns 
   */
  private formattedHeaderA(date: string): string {
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const [year, month, day] = date.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    return `${monthNames[monthIndex]} ${year}`;
  }

  /**
  * Función que genera los valores necesarios para generar la tabla de habito de pago activos
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getHabitOfPaymentActive(response: GetCreditHistoryResponse): HabitOfPaymentActive[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    let results: HabitOfPaymentActive[] = [];

    const allPortfolios: CuentaCartera[] = response.data[0].Informes.Informe.CuentaCartera ? response.data[0].Informes.Informe.CuentaCartera : [];
    const allCreditCards: TarjetaCredito[] = response.data[0].Informes.Informe.TarjetaCredito ? response.data[0].Informes.Informe.TarjetaCredito : [];
    const allSavingAccounts: CuentaAhorro[] = response.data[0].Informes.Informe.CuentaAhorro ? response.data[0].Informes.Informe.CuentaAhorro : [];
    const allCheckingAccounts: CuentaCorriente[] = response.data[0].Informes.Informe.CuentaCorriente ? response.data[0].Informes.Informe.CuentaCorriente : [];

    const activatedPortfolios: HabitOfPaymentActiveAccount[] = this.getActivatedPortfoliosFromHabitOfPaymentActive(allPortfolios);
    const activatedCreditCards: HabitOfPaymentActiveAccount[] = this.getActivatedCreditCardsFromHabitOfPaymentActive(allCreditCards);
    const activatedSavingAccounts: HabitOfPaymentActiveAccount[] = this.getActivatedSavingsAccountFromHabitOfPaymentActive(allSavingAccounts);
    const activatedCheckingAccount: HabitOfPaymentActiveAccount[] = this.getActivatedCheckingAccountsFromHabitOfPaymentActive(allCheckingAccounts);

    // console.log('===HABITO DE PAGO ACTIVOS===');
    // console.log('CuentaCartera - all + actived:', allPortfolios, activatedPortfolios);
    // console.log('TajetaCredito - all + actived:', allCreditCards, activatedCreditCards);
    // console.log('Cuenta de ahorros - all + actived:', allSavingAccounts, activatedSavingAccounts);
    // console.log('Cuenta de corriente - all + actived:', allCheckingAccounts, activatedCheckingAccount);

    const allActivatedAccounts: HabitOfPaymentActiveAccount[] = [...activatedPortfolios, ...activatedCreditCards, ...activatedSavingAccounts, ...activatedCheckingAccount];

    const allActivatedAccountBySector: Record<string, HabitOfPaymentActiveAccount[]> = this.groupAccountsBySector(allActivatedAccounts);

    Object.entries(allActivatedAccountBySector).forEach(([sectorId, accounts]) => {
      const selectedSector: SectorCode | undefined = this.expirianListsService.findSectorByCode(sectorId);

      let sector: HabitOfPaymentActive = {
        sector: selectedSector ? selectedSector.descripcion : '-',
        accounts: []
      };

      sector.accounts.push(...accounts);
      results.push(sector);
    });

    return results;
  }

  /**
   * Función que agrupa cuentas por sector del habito de pagos activos
   * @param accounts 
   * @returns 
   */
  private groupAccountsBySector(accounts: HabitOfPaymentActiveAccount[]) {
    return accounts.reduce((grouped, item) => {
      const key = item.sector || 0;
      if (!grouped[key]) grouped[key] = [];

      grouped[key].push(item);

      return grouped;
    }, {} as Record<string, HabitOfPaymentActiveAccount[]>);
  }

  /**
   * Función que obtiene las CuentaCartera activas
   * @param portfolios 
   * @returns 
   */
  private getActivatedPortfoliosFromHabitOfPaymentActive(portfolios: CuentaCartera[]): HabitOfPaymentActiveAccount[] {
    if (!portfolios || portfolios.length === 0) return [];

    const activatedPortfolios: CuentaCartera[] = portfolios.filter((item: CuentaCartera) => {
      const paymentStatusCode: string = item.Estados.EstadoPago.codigo || '00';
      return this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedPortfolios.map((item: CuentaCartera) => {

      const defaultValueEstados: EstadosCuentaCartera = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, sector = -1,
        calificacion: clasification = '-',
        Estados = defaultValueEstados, formaPago, bloqueada
      } = item;

      const { tipoCuenta: accountType = '-', mesesPermanencia: perm = '-',
        garantia: warrantyType = '-', calidadDeudor: qualityOfDebtorCode = '-'
      } = Caracteristicas;

      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(qualityOfDebtorCode);

      const { Valor } = Valores || {} as CuentaCarteraValores;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as CuentaCarteraAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const {
        cuota: quota = '-', fechaLimitePago: paymentDeadline = '-',
        fechaPagoCuota: paymentDate = '-', fecha: updateDate = '-',
        saldoActual: currentBalance = '-', valorInicial: initialQuota = '-',
        saldoMora: balanceInArrears = '-',
        totalCuotas: totalQuotas = '-', cuotasCanceladas: canceledQuotas = '-'

      } = Valor || {} as CuentaCarteraValor;

      const percentageDebt: string | number = (typeof currentBalance === 'number'
        && typeof initialQuota === 'number') ? Math.round(((currentBalance / initialQuota) * 100) * 10) / 10 : '-';

      const officeAndDebtor: string = `${office} / ${qualityOfDebtor.portfolioDescription}`;

      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      const { EstadoCuenta, EstadoOrigen, EstadoPago } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '' } = EstadoPago;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState: undefined,
        isBloqued: bloqueada
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);

      const ownerStatusFinal: string = this.helperInfoService.findSituationOfTheOwnerByCode(ownerStatus);

      const account: HabitOfPaymentActiveAccount = {
        sector,
        accountNumber,
        accountType,
        adjectiveDate,
        brand: '-', // Solo aplica para tarjetas de credito
        clasification: clasificationFinal,
        currentBalance,
        officeAndDebtor,
        entity,
        informationDisagreement: '-',
        initialQuota,
        balanceInArrears,
        maxArrears: 'XX', // PREGUNTAR EXPIRIAN
        behavior,
        numReturnChecks: '-', // Solo aplica para cuentas corrientes
        obligationStatus,
        openingDate,
        expirationDate,
        updateDate,
        paymentDate,
        paymentDeadline,
        ownerStatus: ownerStatusFinal,
        percentageDebt, //(currentBalance / initialQuota) * 100)
        perm,
        quota,
        quotaAndTerms: '-/M/-', // TOCA REVISAR EL DOCUMENTO DE MANUAL DE INSUMO XML (Es complejo deducir el estado)
        warrantyType
      };

      return account;
    });
  }

  /**
   * Función que obtiene las TarjetaCrediro activas
   * @param creditCards 
   * @returns 
   */
  private getActivatedCreditCardsFromHabitOfPaymentActive(creditCards: TarjetaCredito[]): HabitOfPaymentActiveAccount[] {
    if (!creditCards || creditCards.length === 0) return [];

    const activatedCreditCards: TarjetaCredito[] = creditCards.filter((creditCard: TarjetaCredito) => {
      const paymentStatusCode: string = creditCard.Estados.EstadoPago.codigo || '00';
      return this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedCreditCards.map((creditCard: TarjetaCredito) => {

      const defaultValueEstados: TarjetaCreditoEstados = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
        EstadoPlastico: { codigo: '', fecha: '' }
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, calificacion: clasification = '-', sector = -1,
        Estados = defaultValueEstados, formaPago, bloqueada
      } = creditCard;

      const { marca: brand = '-', amparada = false, franquicia = '-',
        clase = '-', garantia: warrantyType = '-',
      } = Caracteristicas;

      const { Valor } = Valores || {} as TarjetaCreditoValores;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as TarjetaCreditoAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const {
        cuota: quota = '-',
        fechaPagoCuota: paymentDate = '-', fecha: updateDate = '-',
        saldoActual: currentBalance = '-', cupoTotal: initialQuota = '-',
        saldoMora: balanceInArrears = '-', fechaLimitePago: paymentDeadline = '-'

      } = Valor || {} as TarjetaCreditoValor;

      const percentageDebt: string | number = (typeof currentBalance === 'number'
        && typeof initialQuota === 'number') ? Math.round(((currentBalance / initialQuota) * 100) * 10) / 10 : '-';

      const officeAndDebtor: string = `${office} / ${amparada ? 'Amparada' : 'Principal'}`;

      const franquiciaFinal: string = this.helperInfoService.findFranchiseCardByCode(franquicia);
      const claseFinal: string = this.helperInfoService.findTypeOfCreditCardByCode(clase);
      const brandFinal: string = `${franquiciaFinal} / ${claseFinal}`;

      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      const { EstadoCuenta, EstadoOrigen, EstadoPago, EstadoPlastico } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '' } = EstadoPago;
      const { codigo: plasticState = '' } = EstadoPlastico;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState,
        isBloqued: bloqueada
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);

      const ownerStatusFinal: string = this.helperInfoService.findSituationOfTheOwnerByCode(ownerStatus);

      const account: HabitOfPaymentActiveAccount = {
        sector,
        entity,
        accountNumber,
        accountType: 'TDC', // Siempre es este valor para las tarjetas de crédito
        adjectiveDate,
        brand: brandFinal,
        clasification: clasificationFinal,
        currentBalance,
        officeAndDebtor,
        informationDisagreement: '-',
        initialQuota,
        balanceInArrears,
        maxArrears: 'XX', // PREGUNTAR EXPIRIAN
        behavior,
        numReturnChecks: '-', // Solo aplica para cuentas corrientes
        obligationStatus,
        openingDate,
        expirationDate,
        updateDate,
        paymentDate,
        paymentDeadline,
        ownerStatus: ownerStatusFinal,
        percentageDebt, //(currentBalance / initialQuota) * 100)
        perm: '-', // las tarjetas de crédito no tiene esto
        quota,
        quotaAndTerms: '-/M/-', // TOCA REVISAR EL DOCUMENTO DE MANUAL DE INSUMO XML (Es complejo deducir el estado)
        warrantyType
      };

      return account;
    });
  }

  /**
   * Función que obtiene las CuentaAhorro activas
   * @param savingAccounts 
   * @returns 
   */
  private getActivatedSavingsAccountFromHabitOfPaymentActive(savingAccounts: CuentaAhorro[]): HabitOfPaymentActiveAccount[] {
    if (!savingAccounts || savingAccounts.length === 0) return [];

    const activatedSavingAccounts: CuentaAhorro[] = savingAccounts.filter((account: CuentaAhorro) => {
      const code: string = account.Estado.codigo || '-';
      return this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    return activatedSavingAccounts.map((item: CuentaAhorro) => {
      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', entidad: entity = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        Adjetivo, calificacion: clasification = '-', sector = -1, Estado = { codigo: '-' }
      } = item;

      const { clase: brand = '-' } = Caracteristicas;

      const { Valor } = Valores || {} as ValoresCuentaAhorro;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as AdjetivoCuentaAhorro;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const {
        fecha: updateDate = '-'
      } = Valor || {} as ValorCuentaAhorro;

      const officeAndDebtor: string = `${office} / -`;

      const brandFinal: string = this.helperInfoService.findCheckingAndSavingAccountClassByCode(`${brand}`).name;

      const clasificationFinal: string = this.helperInfoService.findSavingAndCheckingAccountRatingByCode(clasification);

      const obligationStatus: string = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(Estado.codigo);

      const ownerStatusFinal: string = this.helperInfoService.findSituationOfTheOwnerByCode(ownerStatus);

      const account: HabitOfPaymentActiveAccount = {
        sector,
        entity,
        accountNumber,
        accountType: 'AHO', // Siempre es este valor para las cuentas de ahorro
        adjectiveDate,
        brand: brandFinal,
        clasification: clasificationFinal,
        currentBalance: '-',
        officeAndDebtor,
        informationDisagreement: '-',
        initialQuota: '-',
        balanceInArrears: '-',
        maxArrears: '-',
        behavior: '-',
        numReturnChecks: '-',
        obligationStatus,
        openingDate,
        expirationDate: '-',
        updateDate,
        paymentDate: '-',
        paymentDeadline: '-',
        ownerStatus: ownerStatusFinal,
        percentageDebt: '-',
        perm: '-',
        quota: '-',
        quotaAndTerms: '-',
        warrantyType: '-'
      };

      return account;
    });
  }

  /**
   * Funcion que obtiene las CuentaCorriente activas
   * @param accounts 
   * @returns 
   */
  private getActivatedCheckingAccountsFromHabitOfPaymentActive(accounts: CuentaCorriente[]): HabitOfPaymentActiveAccount[] {
    if (!accounts || accounts.length === 0) return [];

    const activatedAccounts: CuentaCorriente[] = accounts.filter((account: CuentaCorriente) => {
      const code: string = account.Estado.codigo || '00';
      return this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    return activatedAccounts.map((checkingAccount: CuentaCorriente) => {
      const { numero: accountNumber = -1, Caracteristicas,
        fechaApertura: openingDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-',
        Adjetivo, calificacion: clasification = '-', sector = -1,
        Estado = { codigo: '-' }
      } = checkingAccount;

      const { clase = '-' } = Caracteristicas;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as CuentaCorrienteAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const officeAndDebtor: string = `${office} / -`;

      const brandFinal: string = `- / ${clase}`;

      const clasificationFinal: string = this.helperInfoService.findSavingAndCheckingAccountRatingByCode(clasification);
      const obligationStatus: string = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(Estado.codigo);

      const ownerStatusFinal: string = this.helperInfoService.findSituationOfTheOwnerByCode(ownerStatus);

      const account: HabitOfPaymentActiveAccount = {
        sector,
        entity,
        accountNumber,
        accountType: 'CCB', // Siempre es este valor para las cuentas corrientes
        adjectiveDate,
        brand: brandFinal,
        clasification: clasificationFinal,
        currentBalance: '-',
        officeAndDebtor,
        informationDisagreement: '-',
        initialQuota: '-',
        balanceInArrears: '-',
        maxArrears: '-',
        behavior: '-',
        numReturnChecks: '-', // Solo aplica para cuentas corrientes
        obligationStatus,
        openingDate,
        expirationDate: '-',
        updateDate: '-',
        paymentDate: '-',
        paymentDeadline: '-',
        ownerStatus: ownerStatusFinal,
        percentageDebt: '-', //(currentBalance / initialQuota) * 100)
        perm: '-', // las tarjetas de crédito no tiene esto
        quota: '-',
        quotaAndTerms: '-',
        warrantyType: '-'
      };

      return account;
    });

  }

  /**
  * Función que genera los valores necesarios para generar la tabla de habito de pago inactivo
  * a partir de la response del servicio HDC
  * @param response 
  * @returns 
  */
  getHabitOfPaymentInactive(response: GetCreditHistoryResponse): HabitOfPaymentInactive[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    let results: HabitOfPaymentInactive[] = [];

    const allPortfolios: CuentaCartera[] = response.data[0].Informes.Informe.CuentaCartera ? response.data[0].Informes.Informe.CuentaCartera : [];
    const allCreditCards: TarjetaCredito[] = response.data[0].Informes.Informe.TarjetaCredito ? response.data[0].Informes.Informe.TarjetaCredito : [];
    const allSavingAccounts: CuentaAhorro[] = response.data[0].Informes.Informe.CuentaAhorro ? response.data[0].Informes.Informe.CuentaAhorro : [];
    const allCheckingAccounts: CuentaCorriente[] = response.data[0].Informes.Informe.CuentaCorriente ? response.data[0].Informes.Informe.CuentaCorriente : [];

    const inactivatedPortfolios: HabitOfPaymentInactiveAccount[] = this.getInactivatedPortfoliosFromHabitOfPaymentActive(allPortfolios);
    const inactivatedCreditCards: HabitOfPaymentInactiveAccount[] = this.getInactivatedCreditCardFromHabitOfPaymentActive(allCreditCards);
    const inactivatedSavingAccounts: HabitOfPaymentInactiveAccount[] = this.getInactivatedSavingAccountsFromHabitOfPaymentActive(allSavingAccounts);
    const inactivatedCheckingAccount: HabitOfPaymentInactiveAccount[] = this.getInactivatedCheckingAccountsFromHabitOfPaymentActive(allCheckingAccounts);

    // console.log('===HABITO DE PAGO INACTIVOS O CERRADOS===');
    // console.log('CuentaCartera - all + inactived:', allPortfolios, inactivatedPortfolios);
    // console.log('TajetaCredito - all + inactived:', allCreditCards, inactivatedCreditCards);
    // console.log('Cuenta de ahorros - all + inactived:', allSavingAccounts, inactivatedSavingAccounts);
    // console.log('Cuenta de corriente - all + inactived:', allCheckingAccounts, inactivatedCheckingAccount);

    const allInactivatedAccounts: HabitOfPaymentInactiveAccount[] = [...inactivatedPortfolios, ...inactivatedCreditCards, ...inactivatedSavingAccounts, ...inactivatedCheckingAccount];

    const allInactivatedAccountBySector: Record<string, HabitOfPaymentInactiveAccount[]> = this.groupInactiveAccountsBySector(allInactivatedAccounts);

    Object.entries(allInactivatedAccountBySector).forEach(([sectorId, accounts]) => {
      const selectedSector: SectorCode | undefined = this.expirianListsService.findSectorByCode(sectorId);

      let sector: HabitOfPaymentInactive = {
        sector: selectedSector ? selectedSector.descripcion : '-',
        accounts: []
      };

      sector.accounts.push(...accounts);
      results.push(sector);
    });

    return results;
  }

  /**
   * Función para agrupar las cuentas inactivas por sector
   * @param accounts 
   * @returns 
   */
  private groupInactiveAccountsBySector(accounts: HabitOfPaymentInactiveAccount[]) {
    return accounts.reduce((grouped, item) => {
      const key = item.sector || 0;
      if (!grouped[key]) grouped[key] = [];

      grouped[key].push(item);

      return grouped;
    }, {} as Record<string, HabitOfPaymentInactiveAccount[]>);
  }


  /**
   * Función para obtener las CuentaCartera inactivas
   * @param portfolios 
   * @returns 
   */
  private getInactivatedPortfoliosFromHabitOfPaymentActive(portfolios: CuentaCartera[]): HabitOfPaymentInactiveAccount[] {
    if (!portfolios || portfolios.length === 0) return [];

    const inactivatedPortfolios: CuentaCartera[] = portfolios.filter((item: CuentaCartera) => {
      const paymentStatusCode: string = item.Estados.EstadoPago.codigo || '00';
      return this.helperInfoService.isInactiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    // console.log('OBLIGACIONES TOTALES:', portfolios);
    // console.log('OBLIGACIONES INACTIVAS:', inactivatedPortfolios);

    return inactivatedPortfolios.map((item: CuentaCartera) => {
      const defaultValueEstados: EstadosCuentaCartera = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: -1, fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        oficina: office = '-', entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, Estados = defaultValueEstados, ciudad: city = '-', sector,
        calificacion: clasification = '-', formaPago, bloqueada
      } = item;

      const { tipoCuenta: accountType = '-', calidadDeudor: qualityOfDebtorCode = '-'
      } = Caracteristicas;

      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(qualityOfDebtorCode);

      const { Valor } = Valores || {} as CuentaCarteraValores;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as CuentaCarteraAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const {
        valorInicial: initialQuota = '-',
      } = Valor || {} as CuentaCarteraValor;

      const officeAndDebtor: string = `${office} / ${qualityOfDebtor.portfolioDescription}`;
      const cityAndDate: string = `${city} / ${expirationDate}`;

      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      const { EstadoCuenta, EstadoOrigen, EstadoPago } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago || {} as EstadoPagoCuentaCartera;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState: undefined,
        isBloqued: bloqueada
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);

      const account: HabitOfPaymentInactiveAccount = {
        sector,
        entity,
        accountType,
        obligationStatus,
        clasification: clasificationFinal,
        adjectiveDate,
        accountNumber,
        openingDate,
        closingDate,
        initialQuota,
        cityAndDate,
        officeAndDebtor,
        informationDisagreement: '-',
        behavior
      };

      return account;
    });


  }

  /**
   * Funcion para obtener las TarjetaCredito inactivas
   * @param creditCards 
   * @returns 
   */
  private getInactivatedCreditCardFromHabitOfPaymentActive(creditCards: TarjetaCredito[]): HabitOfPaymentInactiveAccount[] {
    if (!creditCards || creditCards.length === 0) return [];

    const inactivatedCreditCards: TarjetaCredito[] = creditCards.filter((item: TarjetaCredito) => {
      const code: string = item.Estados.EstadoPago.codigo || '00';
      return !this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(code);
    });

    return inactivatedCreditCards.map((creditCard: TarjetaCredito) => {

      const defaultValueEstados: TarjetaCreditoEstados = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
        EstadoPlastico: { codigo: '', fecha: '' }
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        oficina: office = '-', entidad: entity = '-',
        comportamiento: behavior = '-', Adjetivo,
        calificacion: clasification = '-', sector = -1,
        Estados = defaultValueEstados, ciudad: city = '-', formaPago, bloqueada
      } = creditCard;

      const { amparada = false } = Caracteristicas;

      const { Valor } = Valores || {} as TarjetaCreditoValores;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as TarjetaCreditoAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const {
        saldoActual: currentBalance = '-', cupoTotal: initialQuota = '-'
      } = Valor || {} as TarjetaCreditoValor;

      const officeAndDebtor: string = `${office} / ${amparada ? 'Amparada' : 'Principal'}`;

      const cityAndDate: string = `${city} / ${expirationDate}`;

      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      const { EstadoCuenta, EstadoOrigen, EstadoPago, EstadoPlastico } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago;
      const { codigo: plasticState = '' } = EstadoPlastico;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState,
        isBloqued: bloqueada
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);

      const account: HabitOfPaymentInactiveAccount = {
        sector,
        entity,
        accountType: 'TDC', // Siempre es este valor para las tarjetas de crédito
        obligationStatus,
        clasification: clasificationFinal,
        adjectiveDate,
        accountNumber,
        openingDate,
        closingDate,
        initialQuota,
        cityAndDate,
        officeAndDebtor,
        informationDisagreement: '-',
        behavior
      };

      return account;
    });


  }

  /**
   * Función para obtener las CuentaAhorro inactivas
   * @param savingAccounts 
   * @returns 
   */
  private getInactivatedSavingAccountsFromHabitOfPaymentActive(savingAccounts: CuentaAhorro[]): HabitOfPaymentInactiveAccount[] {
    if (!savingAccounts || savingAccounts.length === 0) return [];

    const inactivatedSavingAccounts: CuentaAhorro[] = savingAccounts.filter((account: CuentaAhorro) => {
      const code: string = account.Estado.codigo || '-';
      return !this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    return inactivatedSavingAccounts.map((item: CuentaAhorro) => {
      const { numero: accountNumber = -1,
        fechaApertura: openingDate = '-',
        oficina: office = '-', entidad: entity = '-',
        Adjetivo, calificacion: clasification = '-', sector = -1,
        Estado, ciudad: city = '-'
      } = item;

      const { fecha: closingDate = '-', codigo: stateCode = '-' } = Estado || {} as EstadoCuentaAhorro;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as AdjetivoCuentaAhorro;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const officeAndDebtor: string = `${office} / -`;

      const cityAndDate: string = `${city} / -`;

      const clasificationFinal: string = this.helperInfoService.findSavingAndCheckingAccountRatingByCode(clasification);
      const obligationStatus: string = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(stateCode);

      const account: HabitOfPaymentInactiveAccount = {
        sector,
        entity,
        accountType: 'AHO', // Siempre es este valor para las cuenta de ahorro
        obligationStatus,
        clasification: clasificationFinal,
        adjectiveDate,
        accountNumber,
        openingDate,
        closingDate,
        initialQuota: '-',
        cityAndDate,
        officeAndDebtor,
        informationDisagreement: '-',
        behavior: '-'
      };

      return account;
    });


  }

  /**
   * Función para obtener las CuentaCorriente inactivas
   * @param accounts 
   * @returns 
   */
  private getInactivatedCheckingAccountsFromHabitOfPaymentActive(accounts: CuentaCorriente[]): HabitOfPaymentInactiveAccount[] {
    if (!accounts || accounts.length === 0) return [];

    const inactivatedSavingAccounts: CuentaCorriente[] = accounts.filter((account: CuentaCorriente) => {
      const code: string = account.Estado.codigo || '00';
      return !this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    return inactivatedSavingAccounts.map((item: CuentaCorriente) => {
      const { numero: accountNumber = -1,
        fechaApertura: openingDate = '-',
        oficina: office = '-', entidad: entity = '-',
        Adjetivo, calificacion: clasification = '-', sector = -1,
        Estado, ciudad: city = '-'
      } = item;

      const { fecha: closingDate = '-', codigo: stateCode = '-' } = Estado || {} as EstadoCuentaCorriente;

      const { codigo: adjectiveCode = '', fecha: adjectiveDateOrigin = '' } = Adjetivo || {} as CuentaCorrienteAdjetivo;
      const adjectiveDate: string = `${adjectiveCode} - ${adjectiveDateOrigin}`;

      const officeAndDebtor: string = `${office} / -`;

      const cityAndDate: string = `${city} / -`;

      const clasificationFinal: string = this.helperInfoService.findSavingAndCheckingAccountRatingByCode(clasification);
      const obligationStatus: string = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(stateCode);

      const account: HabitOfPaymentInactiveAccount = {
        sector,
        entity,
        accountType: 'CCB', // Siempre es este valor para las cuentas corrientes
        obligationStatus,
        clasification: clasificationFinal,
        adjectiveDate,
        accountNumber,
        openingDate,
        closingDate,
        initialQuota: '-',
        cityAndDate,
        officeAndDebtor,
        informationDisagreement: '-',
        behavior: '-'
      };

      return account;
    });
  }

  /**
   * Función que retorna el valor del atributo esIndependiente
   * El atributo esIndependiente indica si una persona es TEC (true = es TEC y false = NO es TEC)
   * @returns 
   */
  public getIsIndependent(response: GetCreditHistoryResponse): boolean {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.esIndependiente
    ) return false;

    return true;
  }
}