import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-modal-upload-product-document',
  templateUrl: './modal-upload-product-document.component.html',
  styleUrls: ['./modal-upload-product-document.component.scss']
})
export class ModalUploadProductDocumentComponent implements OnInit {

  @Input() formatDescription: string = 'Word';
  @Input() accept: string = '.docx,.doc';
  @Input() multiple = false;
  public files: File[] = [];
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File> = new EventEmitter();
  public subs1: Subscription;
  public subsRemoveFiles: Observable<File[]> = this.subject.stateFileModal$;

  constructor(public modal: ModalService, public subject: SubjectService, private toastr: ToastrService) {
   this.subs1 = this.subsRemoveFiles.subscribe(() => {
      this.onRemove(0);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

  // DropZone
  onSelect(event) {
    console.log(event);
    if(event && event.rejectedFiles.length >= 1) {
      this.toastr.error('Archivo no válido.');
      return;
    }
    this.fileSelected = event.addedFiles[0];
    this.files[0] = event.addedFiles[0];
    // this.files.push(event.addedFiles);
  }

  onRemove(event) {
    // this.files.splice(this.files.indexOf(event), 1);
    this.files = [];
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.modal.close();
    this.chargeResult.emit(this.fileSelected);
    this.subject.addFiles([this.fileSelected]);
  }
}


