import { RolesService } from './../../../services/roles.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NgForm, NgModel } from '@angular/forms';
import { SelectModel } from '@app/models/select.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { SearchAutocompleteAffiliateComponent } from '@app/pages/backups/search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { NonPaymentNoticeToProcess, NonPaymentNoticeToProcessRequestModel } from '@app/pages/mark-non-payment/models/request/NonPaymentNoticeToProcess.model';
import { NonPaymentNoticeResponseModel } from '@app/pages/mark-non-payment/models/response/NonPaymentNoticeToProcess.model';
import { PaymentsPostBackingRequestModel } from '@app/pages/backups/models/request/PaymentsPostBackings.model';
import { NonPaymentNoticeResponse } from '@app/pages/mark-non-payment/models/response/NonPaymentNoticeToProcess.model';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { AffiliatesListModel } from '@app/pages/backups/models/response/SearchAffiliate.model';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { BackupsStoreService } from '@app/pages/backups/services/backups-store.service';
import { NonPaymentService } from '@app/services/non-payment.service';
import { SelectsService } from '@app/services/selects.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../../../globals/global';

@Component({
  selector: 'app-non-payment-table',
  templateUrl: './non-payment-table.component.html',
  styleUrls: ['./non-payment-table.component.scss'],
  providers: [CurrencyPipe, Globals]
})
export class NonPaymentTableComponent implements OnInit, AfterViewInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;
  @ViewChild('dateRange') dateRangeComponent: DateRangeComponent;
  @ViewChild('nonPaymentNoticeToProcess') nonPaymentNoticeToProcessForm: NgForm;
  @ViewChild('paymentInput') paymentInput: NgModel;

  @ViewChildren('ulView') ulView: QueryList<ElementRef>;

  public configModalConfirm: SweetAlertOptions = {
    title: 'Abono cancelado',
    text: "Al seleccionar otra radicación el abono se cancela.",
    type: 'warning',
  };

  public enablePaymentOptions: string[] = ['ACTIVO', 'REVISADO', 'SUBSANABLE', 'AUTORIZADO DE PAGO'];
  public enableClaimedValueOptions: string[] = ['DEVUELTO', 'CON PAGO TOTAL'];
  public processType: string = '';

  public nonPaymentNoticeToProcess: NonPaymentNoticeResponseModel[] = [];
  public nonPaymentNoticeToProcessBD: NonPaymentNoticeResponseModel[] = [];
  public selectOptionsNonPaymentNoticeTransactions: SelectModel[] = [];

  public paymentsPostBackingRequest: PaymentsPostBackingRequestModel = new PaymentsPostBackingRequestModel();
  public selectedNonPaymentNoticeMemorize: NonPaymentNoticeResponseModel = new NonPaymentNoticeResponseModel();

  public filingStatusValidOptions: any[] = [];

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;
  public activeTab: string = '';
  public parentTab: string = 'title-value';
  public invalidPaymentInput: boolean = false;
  public invalidClaimedInput: boolean = false;
  public isModifyNonPaymentNotices: boolean = false;

  public causalReturn: string = '';
  public onShow: boolean;
  public stateShowSearchResult$: Observable<boolean> = this.backupsStore.stateShowSearchResult$;
  public selectedNonPaymentNoticeToProcess: NonPaymentNoticeToProcess[] = [];
  public search: NonPaymentNoticeToProcessRequestModel = new NonPaymentNoticeToProcessRequestModel();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();


  constructor(private nonPayments: NonPaymentService, private backupsStore: BackupsStoreService,
    public selects: SelectsService, private spinner: NgxSpinnerService, public helper: HelperService,
    public tableHeaders: BackupsTableHeadersService, public modal: ModalService, private toastr: ToastrService,
    private cdRef: ChangeDetectorRef, private currencyPipe: CurrencyPipe, public globals: Globals, private roles: RolesService) {
    this.getSelects();

    this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe(
      (stateAffiliate: AffiliatesListModel) => {
        console.log('stateAffiliate on marcar aviso de no pago', stateAffiliate);
        this.initAffiliate(stateAffiliate);
      }
    );
  }

  ngOnInit(): void { 
    const user = JSON.parse(localStorage.getItem("user"));
    
    if(user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {
        response.data.forEach(item => {
          if(item.hasOwnProperty('actions')) {
            if(item.actions.modifyNonPaymentNotices === true) {
              this.isModifyNonPaymentNotices = true;              
            }
          } 
        })
      })
    }
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const instanceModel = new NonPaymentNoticeToProcessRequestModel();
    this.search = {
      ...this.helper.assignEnumerableKeysAndAssignDefaultValues(stateAffiliate, instanceModel),
      initialDate: this.search.initialDate,
      finalDate: this.search.finalDate
    };
    console.log("this.search on nonpayment", this.search);

    // this.initilizeDateRangePicker();
    //  Si recibe el document vacio no ejecuta una nueva búsqueda
    if ((this.search.affiliateDocument !== '0' || this.search.issuerDocument !== '')) {
      this.getNonPaymentNoticeToProcess(1);
    }
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getNonPaymentNoticeToProcess(currentPage);
  }

  onSearch(textSearch: string) {
    // this.search.search = textSearch;
    this.getNonPaymentNoticeToProcess(1);
  }

  convertToUppercase(value: string) {
    const textClean = value.replace(/[^a-z0-9]/gi, '');
    return textClean.toUpperCase();
  }

  onSelectBranch(selectedbranch: string) {
    // this.search.branchOffice = selectedbranch;
    if (!this.validateAffiliate()) return;
    this.getNonPaymentNoticeToProcess(1);
  }

  onSelectFilterState(selectedFilterState: string) {
    // this.search.sinisterStatus = selectedFilterState;
    if (!this.validateAffiliate()) return;
    this.getNonPaymentNoticeToProcess(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    if (!this.validateAffiliate()) return;

    if ((this.search.affiliateDocument !== '0' || this.search.issuerDocument !== '')) {

      this.getNonPaymentNoticeToProcess(1);
    }

  }

  getNonPaymentNoticeToProcess(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = currentPage;
    this.search.initialDate = moment(this.search.initialDate).
    set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss');
    this.search.finalDate = moment(this.search.finalDate)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss');
    this.nonPayments.getNonPaymentNoticeToProcess(this.search).subscribe(
      (nonPaymentNotice: NonPaymentNoticeResponse) => {
        if (nonPaymentNotice && nonPaymentNotice.ok === false) {
          this.handleGetBackingToProcessError();
          return;
        }
        this.nonPaymentNoticeToProcess = nonPaymentNotice['data'];
        this.nonPaymentNoticeToProcessBD = this.nonPaymentNoticeToProcess
          .map(nonPayment => Object.assign({}, nonPayment));

        this.clearStatusNonPaymentNoticeToProcess();
        this.cantPages = nonPaymentNotice['totalPages'];
        this.selectedNonPaymentNoticeToProcess = [];
        this.spinner.hide();
        this.searchAutocompleteAffiliate.toggleResults(false);
        console.log('onPaymentNotice ->', nonPaymentNotice);
      }, (error: any) => {
        this.handleGetBackingToProcessError(error);
      }
    );
  }

  selectNonPaymentNoticeToProcess(selectedNonPayment: NonPaymentNoticeResponseModel) {
    this.selectedNonPaymentNoticeMemorize = selectedNonPayment;
    this.assignNonPaymentNoticeToProcess(selectedNonPayment);
  }

  clearStatusNonPaymentNoticeToProcess() {
    this.nonPaymentNoticeToProcess = this.nonPaymentNoticeToProcess
      .map((nonPayment: NonPaymentNoticeResponseModel) => {
        nonPayment['status'] = '';
        return nonPayment;
      });
    this.cdRef.detectChanges();
  }

  assignNonPaymentNoticeToProcess(selectedNonPaymentNotice: NonPaymentNoticeResponseModel) {
    this.processType = selectedNonPaymentNotice['status'];

    const instanceModel = new NonPaymentNoticeToProcess();
    const data: NonPaymentNoticeToProcess = this.helper.assignEnumerableKeys(selectedNonPaymentNotice, instanceModel);

    // console.log('assignNonPaymentNoticeToProcess ->', data);
    this.selectedNonPaymentNoticeToProcess[0] = data;

    this.nonPaymentNoticeToProcess = this.nonPaymentNoticeToProcess
      .map((nonPayment: NonPaymentNoticeResponseModel) => {
        nonPayment['check'] =
          (this.helper.equalObjects(nonPayment, selectedNonPaymentNotice))
            ? true
            : false;
        // console.log('nonPayment check equal', (this.helper.equalObjects(nonPayment, selectedNonPaymentNotice)));
        return nonPayment;
      });

    this.invalidPaymentInput = false;
  }

  processNonPaymentNotice(): void {
    this.spinner.show();
    this.assignNonPaymentNoticeToProcess(this.selectedNonPaymentNoticeMemorize);
    this.nonPayments.processNonPaymentNotice(this.selectedNonPaymentNoticeToProcess[0], this.processType).subscribe(
      (nonPaymentNoticeResponse: NonPaymentNoticeResponse) => {
        if (nonPaymentNoticeResponse && nonPaymentNoticeResponse.ok === false) {
          this.handleProcessBackingsError();
          return;
        }
        this.selectedNonPaymentNoticeToProcess = [];
        this.handleResetPagination();
        this.spinner.hide();
        Swal.fire({
          type: 'success',
          title: 'Transacción exitosa',
          text: `${nonPaymentNoticeResponse['message']}`
        })
          .then(() => {
            this.getNonPaymentNoticeToProcess(1);
          });
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Error en transacción',
          text: `${error.error.message || 'Error no identificado.'}`
        });
      }
    );
  }

  handleGetBackingToProcessError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Aviso de no pago', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.nonPaymentNoticeToProcess = [];
    this.selectedNonPaymentNoticeToProcess = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleProcessBackingsError(error?: any) {
    console.error(error);
    this.getNonPaymentNoticeToProcess(1);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Radicación fallida', text: `${message}`, allowOutsideClick: false
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  clearReturnCause(filingStatus: string, idx: number) {
    if (filingStatus !== 'DEVUELTO') {
      this.nonPaymentNoticeToProcess[idx].issuerDocumentType = '';
    }
  }

  getSelects() {
    this.selects.getSelectTypes('nonPaymentNoticeTransactions')
      .subscribe((nonPaymentNoticeTransactions: SelectModel[]) =>
        (this.selectOptionsNonPaymentNoticeTransactions = nonPaymentNoticeTransactions || [new SelectModel()]));
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");
  }

  getNonPaymentNotice(backing: NonPaymentNoticeResponseModel) {
    const { issuerDocumentType, issuerDocument, draftNumber, } = backing;
    this.paymentsPostBackingRequest = { ...this.paymentsPostBackingRequest, issuerDocumentType };
    this.showPayments();
  }

  showPayments() {
    this.modal.show('showPayments', { title: 'Abonos realizados', paymentsPostBackingRequest: this.paymentsPostBackingRequest });
    this.backupsStore.setStatePaymentsPostBackingRequestModel(this.paymentsPostBackingRequest);
  }

  validateAffiliate() {
    const { affiliateDocument, issuerDocument } = this.search;
    console.log("validate =>", affiliateDocument, issuerDocument);
    if (affiliateDocument && affiliateDocument !== '0' || issuerDocument && issuerDocument !== '0') {
      return true;
    }
    //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');
    return false;
  }

  validatePayment(backing: NonPaymentNoticeResponseModel, idx: number) {
    const { issuerDocument } = backing;
    if (!issuerDocument || (Number(issuerDocument) > Number(issuerDocument))) {
      this.helper.showToastMessage('warning', 'El abono no puede ser mayor al valor reclamado.', '');
      this.invalidPaymentInput = true;
      return;
    }
    this.invalidPaymentInput = false;
  }

  validateClaimedValue(backing: NonPaymentNoticeResponseModel, idx: number) {
    const { } = backing;
    const { issuerDocument } = this.nonPaymentNoticeToProcessBD[idx];
    const claimedValueCurrency = this.currencyPipe.transform(issuerDocument, 'COP');
    if (Number(issuerDocument) > Number(issuerDocument)) {
      this.helper.showToastMessage(
        'warning', `El valor reclamado no puede ser mayor a ${claimedValueCurrency}`, ''
      );
      this.invalidClaimedInput = true;
      return;
    }
    this.invalidClaimedInput = false;
  }

  validateActionsToProcessNonPayments(selectedValue: string, reportDate: string, nonPaymentNoticeStatus: string): boolean {
    const isValidDate = (reportDate && reportDate !== this.helper.nullDateReplacer) ? true : false;
    const isValidNoticeStatus = nonPaymentNoticeStatus !== 'DESCARGADO';

    if (selectedValue === 'mark' && isValidDate === false) return true;
    if (selectedValue === 'lift' && isValidDate === true && isValidNoticeStatus) return true;
    if (selectedValue === 'cancel' && isValidDate === true) return true;

    return false;
  }

  getValidFilingStatus(filingStatus: string) {
    const validFilingStatus = ['CON PAGO TOTAL'];
    return validFilingStatus.includes(filingStatus);
  }

  uploadFile($event) {
    const file = $event.target.files[0];
  }

}





