export class SearchAffiliateModel {
    documentType?:          string = '';
    document?:              string = '';
    currentPage:             number = 1;
    perPage:                 number = 5;
    search?:                string = '';
    type?:                  string = '';
    filter:                   string = '';
    authorization?:     boolean = false;
    sinisterNumber?:    boolean = false;
}




