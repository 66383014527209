import { Component, OnInit } from "@angular/core";
@Component({
  selector: 'app-modal-special-cases',
  templateUrl: './modal-special-cases.component.html',
  styleUrls: ['./modal-special-cases.component.scss']
})
export class ModalSpecialCasesComponent implements OnInit {

  ngOnInit() {
  
  }
}
