import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankName'
})
export class BankNamePipe implements PipeTransform {

  transform(value: any): any {
    let bankName: string;
    
    let bankList: any = JSON.parse(localStorage.getItem('form20m_banksList'));
    if(bankList) {
      bankList.forEach(item => {      
        if(value === item.bankId) {
          bankName = item.name;
        }
      })
      return bankName;
    }
  }

}
