;import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { ProductModel } from '../models/request/product.model';
import { BagResponseModel } from '../models/response/bag.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsStoreService {

  private stateProduct = new BehaviorSubject<ProductModel>(new ProductModel());
  public stateProduct$ = this.stateProduct.asObservable();

  private stateBag = new BehaviorSubject<BagResponseModel>(new BagResponseModel());
  public statebag$ = this.stateBag.asObservable();

  private stateSelectedRowBag = new BehaviorSubject<number>(null);
  public stateSelectRowbag$ = this.stateSelectedRowBag.asObservable();

  private stateIdProduct = new BehaviorSubject<number>(null);
  public stateIdProduct$ = this.stateIdProduct.asObservable();

  private allowBags = new BehaviorSubject<boolean>(false);
  public allowBags$ = this.allowBags.asObservable();

  constructor() { }

  setProduct(product: ProductModel) {
    this.stateProduct.next(product);
    // console.log('producto', product);
  }

  setBag(bag: BagResponseModel) {
    this.stateBag.next(bag);
    // console.log('bag', bag);
  }

  setSelectedRowBag(row: number) {
    this.stateSelectedRowBag.next(row);
  }

  setIdProduct(idProduct: number) {
    this.stateIdProduct.next(idProduct);
    // console.log('idProduct', idProduct);
  }

  setAllowBags(allowBags: boolean) {
    this.allowBags.next(allowBags);
    console.log('state allow bags', allowBags)
  }
}
