import { Component, Input, OnInit } from '@angular/core';
// import { DescriptionRequest, QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-request-description',
  templateUrl: './request-description.component.html',
  styleUrls: ['./request-description.component.scss']
})
export class RequestDescriptionComponent implements OnInit {
  
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }
}
