import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { DetailContractAsrTemp } from '@app/models/DetailContractAsrTemp.model';
import { ModalService } from '@app/services/modal.service';
import { FormContractService } from '../services/form-contract.service';
import { ContractService } from '@app/services/contract.service';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss'],
})
export class ContractDetailComponent implements OnInit, DoCheck {
  @Input() stepperNext: any;

  public DataTableFake = [];

  numberContract = 0;

  listDetailContract: DetailContractAsrTemp[] = [];

  validChange = false;

  validateStep = false;

  constructor(
    public modal: ModalService,
    private formService: FormContractService,
    private contractService: ContractService
  ) {}

  ngOnInit() {
    this.numberContract = this.formService.numberContract;
    this.formService.init();
    this.getDetailContract();
    this.validateStep = this.formService.getCurrentStep() <= 4;
    this.formService.myDetailContract$.subscribe((details) => {
      this.listDetailContract = details;
    });

    this.formService.currentStep$.subscribe((detail) => {
      if (detail <= 4) this.validateStep = true;
    });
  }

  getDetailContract() {
    this.contractService.getDetailContract(this.numberContract).subscribe(
      (resp) => {
        this.formService.addDetail(resp.data[0]);
      },
      (error) => {
        this.formService.addDetail([]);
      }
    );
  }

  ngDoCheck(): void {
    if (this.modal.modal.contractDetailModal === false && !this.validChange) {
      console.log(this.modal.modal.contractDetailModal);

      this.validChange = true;
    }
  }

  showModalContractDetail() {
    this.validChange = false;
    let isCreate = {
      isEdit: false,
    };
    this.modal.show('contractDetailModal', isCreate);
  }

  showModalEditContractDetail(detailContract: DetailContractAsrTemp) {
    this.validChange = false;
    let isCreate = {
      isEdit: true,
      contractDetail: detailContract,
    };
    localStorage.setItem('contractDetailModal', JSON.stringify(detailContract));
    this.modal.show('contractDetailModal', isCreate);
  }
}
