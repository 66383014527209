import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NgForm, NgModel } from '@angular/forms';
import { BackupsService } from '@app/services/backups.service';
import { SelectsService } from '@app/services/selects.service';
import { SelectModel } from '@app/models/select.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { HelperService } from '@app/services/helper.service';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BackingsToProcessRequestModel } from '../../models/request/BackingsToProcess.model';
import { BackingsToProcessModel, BackingsToProcessResponse } from '../../models/response/BackingsToProcess.model';
import { AffiliatesListModel } from '../../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../../services/backups-store.service';
import { BackupsTableHeadersService } from '../../services/backups-table-headers.service';
import { ProcessBackingsRequestModel } from '../../models/request/ProcessBackings.model';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { SearchAutocompleteAffiliateComponent } from '../../search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { ModalService } from '../../../../services/modal.service';
import { PaymentsPostBackingRequestModel } from '../../models/request/PaymentsPostBackings.model';
import { CurrencyPipe } from '@angular/common';
import { Globals } from '@app/globals/global';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../redux/app.reducer';
import { RolesService } from '@app/services/roles.service';
import { ToastrService } from 'ngx-toastr';
declare let $: any;

@Component({
  selector: 'app-title-value-individual',
  templateUrl: './title-value-individual.component.html',
  styleUrls: ['./title-value-individual.component.scss'],
  providers: [CurrencyPipe, Globals]
})
export class TitleValueIndividualComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;
  @ViewChild('dateRange') dateRangeComponent: DateRangeComponent;
  @ViewChild('backingToProcessForm') backingToProcessForm: NgForm;
  @ViewChild('paymentInput') paymentInput: NgModel;

  public configModalConfirm: SweetAlertOptions = {
    title: 'Abono cancelado',
    text: "Al seleccionar otra radicación el abono se cancela.",
    type: 'warning',
  };

  public totalBackings = {
    reclam: '',
    reintg: '',
    recoveredPlusDeductible: '',
    balanceOverRefundValue: '',
    balanceOverClaimedValye: ''
  }

  public enablePaymentOptions: string[] = ['ACTIVO', 'REVISADO', 'SUBSANABLE','AUTORIZADO DE PAGO'];
  public enableClaimedValueOptions: string[] = ['DEVUELTO', 'CON PAGO TOTAL'];
  public enableClaimedValueAdminOptions: string[] = ['CON PAGO TOTAL', 'CON PAGO COBERTURA'];
  public disableRequiredPayment: Array<string> = ['REVISADO', 'SUBSANABLE', 'ACTIVO', 'AUTORIZADO DE PAGO'];

  public backingsToProcess: BackingsToProcessModel[] = [];
  public backingsToProcessBD: BackingsToProcessModel[] = [];
  public selectOptionsBranchOffices: SelectModel[] = [];
  public selectOptionsChangeBackingsStatus: SelectModel[] = [];
  public selectOptionsNonRefundCauses: SelectModel[] = [];
  public selectOptionsBackingsFilterState: SelectModel[] = [];
  public paymentsPostBackingRequest: PaymentsPostBackingRequestModel = new PaymentsPostBackingRequestModel();
  public selectedBackingMemorize: BackingsToProcessModel = new BackingsToProcessModel();

  public filingStatusValidOptions: any[] = [];

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;
  public activeTab: string = '';
  public parentTab: string = 'title-value';
  public invalidPaymentInput: boolean = false;
  public invalidClaimedInput: boolean = false;
  public isChangeValue: boolean = false;
  public hasActiveValueChanged: boolean = false;
  public permissionToView = true;
  public permissionToViewBackButton = true;
  public message = "";
  public userName:any;

  public causalReturn: string = '';
  public onShow: boolean;
  public stateShowSearchResult$: Observable<boolean> = this.backupsStore.stateShowSearchResult$;
  public selectedBackingToProcess: ProcessBackingsRequestModel[] = [];
  public search: BackingsToProcessRequestModel = new BackingsToProcessRequestModel();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();


  constructor(private backups: BackupsService, private backupsStore: BackupsStoreService,
    public selects: SelectsService, private spinner: NgxSpinnerService, public helper: HelperService,
    public tableHeaders: BackupsTableHeadersService, public modal: ModalService,
    private cdRef: ChangeDetectorRef, private currencyPipe: CurrencyPipe, private store: Store<AppState>, private roles: RolesService, private toastr: ToastrService) {
    this.store.select('selects').pipe(takeUntil(this.unsubscribe$)).subscribe(({ selects }) => this.getSelects(selects));

    this.backupsStore.stateBackupsActiveTab$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe(
      (stateBackupsActiveTab: string) => {
        this.activeTab = stateBackupsActiveTab;
        this.initilizeDateRangePicker();
        if (this.activeTab === this.parentTab&&this.searchAutocompleteAffiliate) this.searchAutocompleteAffiliate.reset();
      });

    this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe(
      (stateAffiliate: AffiliatesListModel) => {
        console.log('stateAffiliate on gestionar respaldos', stateAffiliate);
        this.initAffiliate(stateAffiliate);
      }
    );
  }

  ngOnInit(): void {
    this.findPermissions();
    //this.getProfile();
  }

  ngAfterViewInit() {

    // TODO: Revisar urgente. Enrique
    // if($) {
    //   $('[data-toggle="tooltip"]').tooltip();
    // }
  }

  findPermissions() {
    const user = JSON.parse(localStorage.getItem("user"));

    if(user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {
        response.data.forEach(item => {
          if(item.hasOwnProperty('actions')) {
            if(item.actions.changeValue === true) {
              this.isChangeValue = true;
            }
          }
        })
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const instanceModel = new BackingsToProcessRequestModel();
    this.search = {
      ...this.helper.assignEnumerableKeysAndAssignDefaultValues(stateAffiliate, instanceModel),
      initialDate: this.search.initialDate,
      finalDate: this.search.finalDate
    };
    if (this.activeTab === this.parentTab && (this.search.affiliateDocument !== '0' || this.search.issuerDocument !== "0")) {
      this.getBackingsToProcess(1);
    }
  }

  evalIsActiveState(status: string) {
    this.hasActiveValueChanged = (status === 'ACTIVO') ? true : false;
  }

  changeValidActiveState() {
    this.hasActiveValueChanged = false;
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getBackingsToProcess(currentPage);
  }

  onSearch(textSearch: string) {
    this.getBackingsToProcess(1);
  }

  convertToUppercase(value: string) {
    const textClean = value.replace(/[^a-z0-9]/gi, '');
    return textClean.toUpperCase();
  }

  onSelectBranch(selectedbranch: string) {
    this.search.branchOffice = selectedbranch;
    if (!this.validateAffiliate()) return;
    this.getBackingsToProcess(1);
  }

  onSelectFilterState(selectedFilterState: string) {
    console.log(selectedFilterState);

    this.search.sinisterStatus = selectedFilterState;
    if (!this.validateAffiliate()) return;
    this.getBackingsToProcess(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    if (!this.validateAffiliate()) return;

    this.getBackingsToProcess(1);
  }

  getBackingsToProcess(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = currentPage;
    console.log(this.search);

    this.backups.getBackingsToProcess(this.search).subscribe(
      (backingsToProcess: BackingsToProcessResponse) => {
        if (backingsToProcess && backingsToProcess.ok === false) {
          this.handleGetBackingToProcessError();
          return;
        }
        this.backups.getTotalBackings(this.search).subscribe((response: any) => {
          this.spinner.hide();
          this.totalBackings.reclam = response.data.totalClaimedValue;
          this.totalBackings.reintg = response.data.totalRefundValue;
          this.totalBackings.recoveredPlusDeductible = response.data.totalRecoveredDeducValue;
          this.totalBackings.balanceOverRefundValue = response.data.totalRefundBalance;
          this.totalBackings.balanceOverClaimedValye = response.data.totalClaimedBalance;
        }, (error: any) => {
          this.spinner.hide();
          this.toastr.error(error.error.message)

        });
        this.backingsToProcess = backingsToProcess['data'];
        this.backingsToProcessBD = this.backingsToProcess.map(backing => Object.assign({}, backing));
        this.fillValidOptions();
        this.cantPages = backingsToProcess['totalPages'];
        this.selectedBackingToProcess = [];
        this.searchAutocompleteAffiliate.toggleResults(false);
      }, (error: any) => {
        this.handleGetBackingToProcessError(error);
      }
    );
  }

  selectBackingToProcess(selectedBacking: BackingsToProcessModel) {
    const someItemToPay = this.backingsToProcess.some((backingToProcess: BackingsToProcessModel) => backingToProcess.newPayment > 0);
    if (someItemToPay) {
      Swal.fire(this.configModalConfirm);
      this.backingsToProcess.forEach((backingToProcess: BackingsToProcessModel) => backingToProcess.newPayment = 0);
    }
    this.selectedBackingMemorize = selectedBacking;
    console.log(this.selectedBackingMemorize);

    this.assignBackingsToProcess(selectedBacking);
  }

  assignBackingsToProcess(selectedBacking: BackingsToProcessModel) {
    const { issuerDocument, issuerDocumentType, filingNumber, guaranteedValue, filingStatus, draftNumber, affiliateDocumentType, affiliateDocument, claimedValue, nonRefundCause: returnCause, deductiblePercentage, newPayment, sinisterProduct, recoveryValue } = selectedBacking;
    this.selectedBackingToProcess[0] = { issuerDocument, issuerDocumentType, filingNumber, guaranteedValue, filingStatus, draftNumber, affiliateDocumentType, affiliateDocument, claimedValue, returnCause, deductiblePercentage, newPayment, sinisterProduct, recoveryValue };

    this.backingsToProcess = this.backingsToProcess.map((backing: BackingsToProcessModel) => {
      backing['check'] = backing.filingNumber === selectedBacking.filingNumber ? true : false;
      return backing;
    });

    this.invalidPaymentInput = false;
  }

  handleProcessBackings() {
    this.assignBackingsToProcess(this.selectedBackingMemorize);
    const { newPayment, claimedValue } = this.selectedBackingToProcess[0];
    if ((Number(newPayment) >= Number(claimedValue)) && (Number(newPayment) !== 0 && Number(claimedValue) !== 0)) {
      this.validateDesist(this.selectedBackingToProcess[0]).then((value) => {
        if (value && value.value === true) {
          this.processBackings();
        }
      });
      return;
    }
    this.processBackings();
  }

  processBackings(): void {
    this.spinner.show();
    this.assignBackingsToProcess(this.selectedBackingMemorize);
    this.backups.processBackings(this.selectedBackingToProcess[0]).subscribe(
      (backingsToProcess: BackingsToProcessResponse) => {
        if (backingsToProcess && backingsToProcess.ok === false) {
          this.handleProcessBackingsError();
          return;
        }
        this.selectedBackingToProcess = [];
        this.handleResetPagination();
        this.spinner.hide();
        Swal.fire({
          type: 'success',
          title: 'Transacción exitosa',
          text: `${backingsToProcess['message']}`
        }).then(() => {
          this.getBackingsToProcess(1);
        });
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Error en transacción',
          text: `${error.error.message || 'Error no identificado.'}`
        });
      }
    );
  }

  handleGetBackingToProcessError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Respaldos', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.backingsToProcess = [];
    this.selectedBackingToProcess = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
    this.fillValidOptions();
  }

  handleProcessBackingsError(error?: any) {
    console.error(error);
    this.getBackingsToProcess(1);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Radicación fallida', text: `${message}`, allowOutsideClick: false
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  clearReturnCause(filingStatus: string, idx: number) {
    if (filingStatus !== 'DEVUELTO') {
      this.backingsToProcess[idx].nonRefundCause = '';
    }
  }

  getSelects(selects: any) {
    console.log(selects);
    console.log(selects.changeBackingsStatus)
    this.selectOptionsBranchOffices = selects.branchOffices || [new SelectModel()];
    this.selectOptionsChangeBackingsStatus = selects.changeBackingsStatus || [new SelectModel()];
    this.selectOptionsNonRefundCauses = selects.nonRefundCauses || [new SelectModel()];
    this.selectOptionsBackingsFilterState = selects.manageBackingsStatus || [new SelectModel()];
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");
  }

  getPaymentsPostBacking(backing: BackingsToProcessModel) {
    const { affiliateDocumentType, affiliateDocument, issuerDocumentType, issuerDocument, draftNumber, filingNumber, contract } = backing;
    this.paymentsPostBackingRequest = { ...this.paymentsPostBackingRequest, affiliateDocumentType, affiliateDocument, issuerDocumentType, issuerDocument, draftNumber, filingNumber, contract };
    this.showPayments();
  }

  showPayments() {
    this.modal.show('showPayments', { title: 'Abonos realizados', paymentsPostBackingRequest: this.paymentsPostBackingRequest });
    this.backupsStore.setStatePaymentsPostBackingRequestModel(this.paymentsPostBackingRequest);
  }

  validateAffiliate() {
    const { affiliateDocument, issuerDocument } = this.search;
    console.log("validate =>", affiliateDocument, issuerDocument);
    if (affiliateDocument && affiliateDocument !== '0' || issuerDocument && issuerDocument !== '0') {
      return true;
    }
    //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');
    return false;
  }

  validatePayment(backing: BackingsToProcessModel, idx: number) {
    const { claimedValue, newPayment } = backing;
    if ((Number(newPayment) > Number(claimedValue))) {
      this.helper.showToastMessage('warning', 'El abono no puede ser mayor al valor reclamado.', '');
      this.invalidPaymentInput = true;
      return;
    }
    this.invalidPaymentInput = false;
  }

  validateClaimedValue(backing: BackingsToProcessModel, idx: number) {
    const { claimedValue } = backing;
    const { claimedValue: claimedValueDB } = this.backingsToProcessBD[idx];
    const claimedValueCurrency = this.currencyPipe.transform(claimedValueDB, 'COP');
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      if ((Number(claimedValue) > Number(claimedValueDB)) && !this.isChangeValue ) {
        this.helper.showToastMessage(
          'warning', `El valor reclamado no puede ser mayor a ${claimedValueCurrency}`, ''
        );
        this.invalidClaimedInput = true;
        return;
      }
    }

    this.invalidClaimedInput = false;
  }

  isPositive(event: any) { return event.key === '-' ? false : true; }

  validateDesist({ newPayment, claimedValue }: ProcessBackingsRequestModel): Promise<any> {
    console.log("desistimiento ->", newPayment, claimedValue);
    if (Number(newPayment) === Number(claimedValue)) {
      return Swal.fire({
        type: 'info',
        title: `La obligación será marcada como desistida.`,
        text: `¿Desea continuar?`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: `Confirmar`,
        allowOutsideClick: false
      });
    }
  }

  fillValidOptions() {
    this.backingsToProcess.forEach((backing, idx) => {
      this.getValidFilingStatusOptions(backing, idx);
    });
  }

  getValidFilingStatusOptions(backing: BackingsToProcessModel, idx: number) {
    const { filingStatus } = backing;
    this.selects.getSelectsWithParams('changeBackingsStatus', filingStatus)
      .subscribe(
        (changeBackingsStatus: SelectModel[]) => {
          this.filingStatusValidOptions[idx] = changeBackingsStatus || [];
          this.backingsToProcess[idx].filingStatus = this.backingsToProcess[idx].filingStatus;
          this.cdRef.detectChanges();
        },
        (error) => []
      );
  }

  getValidFilingStatus(filingStatus: string) {
    const validFilingStatus = ['CON PAGO TOTAL'];
    return validFilingStatus.includes(filingStatus);
  }

  handleCancelBacking() {
    this.modal.show("confirm");
  }

  cancelBacking() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      const body = {
        filingNumber: this.selectedBackingMemorize.filingNumber,
        contractProduct: this.selectedBackingMemorize.contractProduct,
        contract: this.selectedBackingMemorize.contract,
        user: user.user
      };

      this.backups.cancelBacking(body).subscribe((response: any) => {
        this.modal.close();
        this.getBackingsToProcess(1);
        this.handleResetPagination();
        this.selectedBackingMemorize = null;
        Swal.fire({
          type: 'success',
          title: `${response.message}`,
          text: ``
        })
        this.spinner.hide()
      }, (error) => {
        this.spinner.hide();
        this.modal.close();
        Swal.fire({
          type: 'error',
          title: 'Error en transacción',
          text: `${error.error.message || 'Error no identificado.'}`
        });
      });
    }
  }



getProfile() {
  this.userName = JSON.parse(localStorage.getItem("user"));
  let permissions = null;
  if (this.userName) {
    this.message += ` ${this.userName.user}`;
    this.roles.findByUsername(this.userName.user).subscribe(
      (resp) => {
        console.log(resp);
        let response: any = resp;
        permissions = response.data.find(
          (element) => element.description == "backing"
        );
        if (permissions && permissions.actions) {
          this.getPermissions(permissions)
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

getPermissions(permissions) {
  if (permissions.actions['back-button']) {
    this.permissionToViewBackButton = true;
  }
  if (permissions.actions['back-name-field']) {
    this.permissionToView = true;
  }
}
}


