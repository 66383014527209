import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HelperService } from './helper.service';
import { ProductRequest, ProductModel, ProductStateRequest } from '../pages/products/models/request/product.model';
import { ProductResponse, ProductResponseModel } from '../pages/products/models/response/product.model';
import { Observable } from 'rxjs';
import { BagRequest } from '../pages/products/models/request/bag.model';
import { BagResponse } from '../pages/products/models/response/bag.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public urlApi: string = '';
  public productsToken: string;
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private helper: HelperService,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.apiUrl;
    this.timeout = environment.httpTimeout;
    this.productsToken = environment.productsToken;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, httpTimeout, productsToken} = apis;
      this.urlApi = apiUrl;
      this.timeout = httpTimeout;
      this.productsToken = productsToken;
       console.log('config ci cd', apiUrl);
    });
  }

  public getProducts(productRequest: ProductRequest): Observable<ProductResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const params: any = Object.assign({}, productRequest);

    if (params.type === '0') {
      params.search = encodeURIComponent(params.search);
    }

    const options = {
      params,
      headers,
    };
    return this.http.get<ProductResponse>(`${this.urlApi}/products/products/getProducts`, options).pipe(
      // timeout(this.timeout),
      map((productsResponse: any) => productsResponse)
    );
  }

  public getBags(bagRequest: BagRequest): Observable<BagResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const params: any = Object.assign({}, bagRequest);

    if (params.type === '0') {
      params.search = encodeURIComponent(params.search);
    }

    const options = {
      params,
      headers,
    };
    return this.http.get<BagResponse>(`${this.urlApi}/products/products/getBags`, options).pipe(
      // timeout(this.timeout),
      map((bagsResponse: BagResponse) => bagsResponse)
    );
  }

  createProduct(createProductRequest: ProductModel): Observable<ProductResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const options = {
      headers,
    };

    createProductRequest.user = this.helper.getIdentity();
    return this.http.post<ProductResponse>(`${this.urlApi}/products/products/newProduct`, createProductRequest, options).pipe(
      // timeout(120000),
      map((productResponse: ProductResponse) => productResponse)
    );
  }

  createBag(createBagRequest: BagRequest): Observable<BagResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const options = {
      headers,
    };

    // createBagRequest.user = this.helper.getIdentity();
    return this.http.post<BagResponse>(`${this.urlApi}/products/products/newBag`, createBagRequest, options).pipe(
      // timeout(120000),
      map((bagsResponse: BagResponse) => bagsResponse)
    );
  }

  updateProduct(updateProductRequest: ProductModel): Observable<ProductResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const options = {
      headers,
    };

    updateProductRequest.user = this.helper.getIdentity();
    return this.http.post<ProductResponse>(`${this.urlApi}/products/products/setProduct`, updateProductRequest, options).pipe(
      // timeout(120000),
      map((updateProductResponse: ProductResponse) => updateProductResponse)
    );
  }

  updateBag(updateBagRequest: BagRequest): Observable<BagResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const options = {
      headers,
    };

    // createBagRequest.user = this.helper.getIdentity();
    return this.http.post<BagResponse>(`${this.urlApi}/products/products/setBag`, updateBagRequest, options).pipe(
      // timeout(120000),
      map((bagsResponse: BagResponse) => bagsResponse)
    );
  }

  updateProductState(stateRequest: ProductStateRequest): Observable<ProductResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.productsToken),
    });

    const options = {
      headers,
    };

    stateRequest.user = this.helper.getIdentity();
    return this.http.post<ProductResponse>(`${this.urlApi}/products/products/setState`, stateRequest, options).pipe(
      // timeout(120000),
      map((productsResponse: ProductResponse) => productsResponse)
    );
  }

}
