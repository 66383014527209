import { HttpHeaders } from "@angular/common/http";
import { DocumentResponse } from "@models/response/documentResponse.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  public urlApi: string;
  public userToken: string;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi} = apis
      this.urlApi = urlApi;
       console.log('config ci cd', apiUrl);
    });
  }

  readToken() {
    if (localStorage.getItem("tokenASR")) {
      this.userToken = localStorage.getItem("tokenASR");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getDocuments() {
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      headers,
    };
    return this.http.get(`${this.urlApi}/listado_documentos`, options).pipe(
      map((resp: any) => {
        let documents: Array<DocumentResponse> = [];
        documents =
          resp["DOCUMENTOS"] && resp["DOCUMENTOS"].length > 0
            ? resp["DOCUMENTOS"].map((document: DocumentResponse) =>
                DocumentResponse.fromResponse(document)
              )
            : [];
        console.log(documents);
        return documents;
      })
    );
  }
}
