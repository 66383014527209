import { Component, OnInit } from "@angular/core";
import { SelectModel } from "@app/models/select.model";
import { BulkUploadQuearyService } from "@app/services/bulk-upload-queary.service.";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AffiliateInfoResponse } from "../entry-transactions/models/response/entry-transactions.response";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { ContractService } from "@app/services/contract.service";
import { Response } from "@app/models/responseUtil.model";
import { QuotaGreaterService } from "@app/services/quota-greater.service";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: "app-bulk-upload-queary",
  templateUrl: "./bulk-upload-queary.component.html",
  styleUrls: ["./bulk-upload-queary.component.scss"],
})
export class BulkUploadQuearyComponent implements OnInit {
  public file: File;
  public stringFile: string;
  public validBulkUpload: boolean = false;

  public typeDoc: string = "";
  public numDoc: number;

  public fullName: string = "";

  data: any = [];

  public selectContract: any;

  public selectOptionsDocumentTypes: SelectModel[] = [];

  public invoiceData;

  public listData = [];

  public headersPreview: Array<string> = [];
  public headersBulkUpload: Array<string> = [];

  public isQuerySuccess: boolean = false;
  public validAffiliate: boolean = false;

  constructor(
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private modal: ModalService,
    private contractService: ContractService,
    private bulkUploadQuearyService: BulkUploadQuearyService,
    private quotaGreater: QuotaGreaterService
  ) {}

  ngOnInit() {
    if (!localStorage.getItem("covi-utils")) {
      this.getDataListCoviUtils();
    } else {
      this.getDocumentType();
    }

    this.headersPreview = this.bulkUploadQuearyService.headersPreview;
    this.headersBulkUpload = this.bulkUploadQuearyService.headersBulkUpload;
  }

  getDataListCoviUtils() {
    this.spinner.show();
    this.contractService.getSelectData().subscribe(
      (resp: Response<any>) => {
        this.getDocumentType();
        this.spinner.hide();
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  getDocumentType() {
    this.selectContract = JSON.parse(localStorage.getItem("covi-utils"));
    if (this.selectContract) {
      this.selectContract.documentTypesCode.forEach((element) => {
        if (
          !(
            element.value === "P" ||
            element.value === "T" ||
            element.value === "E"
          )
        )
          this.selectOptionsDocumentTypes.push(element);
      });
    }
  }

  getNameAffiliate() {
    if (this.typeDoc && this.numDoc) {
      this.spinner.show();
      this.bulkUploadQuearyService
        .getNameAffiliate(this.typeDoc, this.numDoc)
        .subscribe(
          (resp: AffiliateInfoResponse) => {
            if (resp.ok && resp.data) {
              this.fullName = resp.data[0].name;
              this.validAffiliate = true;
            }
            this.invoiceData = [];
            this.spinner.hide();
          },
          (error: any) => {
            this.validAffiliate = false;
            this.isQuerySuccess = false;
            this.invoiceData = [];
            this.fullName = "";
            this.file = null;
            if (error.status == 404) {
              Swal.fire({
                type: "info",
                title: "¡No se encontraron registros!",
                text: "En ninguna de las fuentes de información.",
              }).then(() => {});
              this.spinner.hide();
            }
            console.log(error);
            this.spinner.hide();
          }
        );
    }
  }

  downloadTemplate() {
    //window.open(http://desarrollo02.c0v.co:8080/ServiciosCovifacturaDSRL/ver/DocComerciales/formato_carga.xlsx, '_newtab');}
    window.open(
      `${environment.urlInvoicesTemplate}/ver/DocComerciales/formato_carga.xlsx`,
      "_newtab"
    );
  }

  seleccionArchivo(archivo: File) {
    this.file = archivo;
    this.toBase64(this.file).then((response: any) => {
      this.stringFile = response.split(",")[1];
    });
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });
  }

  handleShowModalUploadDocument() {
    this.modal.show("fileUploadPromisoryNotes");
  }

  previewFile() {
    this.spinner.show();
    this.invoiceData = [];
    this.bulkUploadQuearyService.uploadFileBulkQueary(this.file).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.invoiceData = response.data[0];

        if (this.invoiceData.correctRecords) {
          if (this.invoiceData.errorRecords.length > 0) {
            this.isQuerySuccess = false;
            this.validBulkUpload = false;

            this.listData = [];
            Swal.fire({
              title: `Tienes errores en algunos de los registros`,
              text: `Por favor corregir y subir el archivo nuevamente`,
              type: "error",
            });
          } else {
            this.isQuerySuccess = true;
            this.validBulkUpload = false;
            this.listData = [];
            Swal.fire({
              title: `${response.message}`,
              text: ``,
              type: "success",
            });
          }
        }
      },
      (error) => {
        this.spinner.hide();
        this.isQuerySuccess = false;
        if (error.error) {
          Swal.fire({
            title: `${error.error.message || "Error interno de servidor"}`,
            text: ``,
            type: "error",
          });
        }
      }
    );
  }

  saveAll() {
    Swal.fire({
      type: "info",
      title: ``,
      text: `¿Está seguro de ingresar todas las consultas?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: `Confirmar`,
      allowOutsideClick: false,
    }).then((value) => {
      if (value.value === true) {
        this.insertBulkUploadQueary();
      }
    });
  }

  insertBulkUploadQueary() {
    this.spinner.show();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.user) {
      const bodyParams = {
        user: user.user,
        document: this.numDoc,
        typeDocument: this.typeDoc,
      };
      this.bulkUploadQuearyService
        .insertBulkUploadQueary(this.invoiceData.correctRecords, bodyParams)
        .subscribe(
          (response: any) => {
            if (response.ok && response.data) {

              if (response.data && response.data.length > 0) {
                this.quotaGreater.findBulkUpload({filingNumber: response.data[0]}).subscribe((response: any) => {
                  this.validBulkUpload = true;
                  this.listData = response.data[0].records;
                  this.spinner.hide();
                  Swal.fire({
                    title: `${response.message}`,
                    text: ``,
                    type: 'success',
                  });
                });
              } else {
                Swal.fire({
                  title: `${response.message}`,
                  text: ``,
                  type: 'error',
                });
              }
            }

          },
          (error) => {
            this.spinner.hide();
            if (error) {
              Swal.fire({
                title: `${error.error.message}`,
                text: ``,
                type: "error",
              });
            }
          }
        );
    }
  }

  exportToExcel() {
    console.log(this.invoiceData.errorRecords);
    const dataToExportWithTitles = this.invoiceData.errorRecords.map(item => {
      return {
        "Tipo de documento": item.documentType,
        "Documento": item.document,
        "Título valor": item.recordTitle,
        "Valor": item.value,
        "# Radication" : item.bulkLoadSepPK.filing,
        "# AUTORIZACIÓN" : item.authorization || '--',
        "Fecha": item.recordDate,
        "Código consulta": item.queryCode,
        "Mensaje": Object.values(item.message).filter(val => val).join(', ')
      };
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExportWithTitles);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data_export");
  }

  exportToExcelCorrectRecords() {
    console.log(this.invoiceData.correctRecords);
    const dataToExportWithTitles = this.listData.map(item => {
      return {
        "FECHA": item.date || '--',
        "DOCUMENTO": item.document || '--',
        "TIPO DOCUMENTO": item.documentType || '--',
        "FECHA DE CARGA": item.loadDate || '--',
        "OBSERVACION": item.observation || '--',
        "CÓDIGO DE CONSULTA": item.queryCode || '--',
        "# RADICACIÓN" : item.bulkLoadSepPK.filing || '--',
        "# AUTORIZACIÓN" : item.authorization || '--',
        "NÚMERO CHEQUE ORIGINAL": item.originalCheckNumber || '--',
        "USUARIO RADICACIÓN": item.userFiling || '--',
      };
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExportWithTitles);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data_export");
  }

  getIsDenied(value: string) {
    return value !== "A" ? "Negada" : "Aprobada";
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
