import { DocumentTypes } from "../documentType.model";


export class DocumentTypeResponse {
    response: Array<DocumentTypes> = [];
    error?: {};

    public static fromResponse(json: any): DocumentTypeResponse {
        const documentTypeResponse: DocumentTypeResponse = {
        response: json.response ? json.response : [],
        error: json.error ? json.error : null
    };
        return documentTypeResponse;
    }
}
