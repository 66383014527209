import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HelperService } from '@app/services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private helper: HelperService) {}

  canActivate(): boolean {
    console.log(this.auth.sessionLimit())
    if (this.auth.isAutenticated() && this.auth.sessionLimit()) {
      console.log(this.auth.isAutenticated());
      return true;
    } else {
      this.helper.logOut();
      return false;
    }
  }
}
