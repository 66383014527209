import { Component, OnInit } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-backups-audit',
  templateUrl: './backups-audit.component.html',
  styleUrls: ['./backups-audit.component.scss']
})
export class BackupsAuditComponent implements OnInit {

  public navItemTabs: any[] = [];
  perPage : number = 10;
  currentPage : number = 1;
  totalPages: number = 1;
  documentType: string = '';
  document: number | null = null;
  notices: any[] = [];

  data: string;

  constructor(private tabs: TabsService, public helper: HelperService, private backupsService: BackupsService,
     private spinner: NgxSpinnerService) {
    this.navItemTabs = this.tabs.backupsAuditTabs;

  }

  ngOnInit() {}

  search(currentPage?: any) {
    console.log(this.data);
    
    this.spinner.show();

    const body: any = {
      currentPage: currentPage,
      perPage: this.perPage,
      filingNumber: this.data,
    }
    
    this.backupsService.backupsAudit(body).subscribe(
      (res: any) => {
        console.log(res);
        this.totalPages = res.totalPages;
        this.spinner.hide();
        /* if (res.status != 200) this.helper.badResponse(res.message, res.status); */
        this.notices = res.data;
        console.log(res.data);
      },
      (error) => {
        this.spinner.hide();
        this.helper.handleErrorHttp(error, '')
      } 
    );
  }

  goToPage($event: any){
    this.search($event.currentPage)
  }

}
