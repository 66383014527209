import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@app/models/responseUtil.model';
import { map } from 'rxjs/operators'
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { CreditStudy } from './models/credit-info.models';


@Injectable({
  providedIn: 'root'
})
export class CreditStudyService {

  public urlApi: string = '';
  public tokenBasicGlobalBilling: string = '';
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlGlobalBilling;
    this.timeout = environment.httpTimeout;
    this.tokenBasicGlobalBilling = environment.tokenBasicGlobalBilling;
    this.ciCdInit();
  }


  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { urlGlobalBilling, httpTimeout, tokenBasicGlobalBilling } = apis;
      this.urlApi = urlGlobalBilling;
      this.timeout = httpTimeout;
      this.tokenBasicGlobalBilling = tokenBasicGlobalBilling;
    });
  }

  postCreditStudy(body: any): Observable<Response<CreditStudy | string>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/credit-studies`,
        body,
        options
      )
      .pipe(
        map((resp: any) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }



}
