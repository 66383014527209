import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LevelOfIndebtednessAndOpenDebentureValues } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-debt-level-table',
  templateUrl: './legal-debt-level-table.component.html',
  styleUrls: ['./legal-debt-level-table.component.scss']
})
export class LegalDebtLevelTableComponent implements OnInit, OnChanges {

  @Input() levelOfIndebtednessAndOpenDebentureValues: LevelOfIndebtednessAndOpenDebentureValues[] = [];
  @Input() isAPerson: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('=============> Leveling', changes["levelOfIndebtednessAndOpenDebentureValues"].currentValue);
  }

  ngOnInit() {

  }

  printCustomerTypesBySector(sector: LevelOfIndebtednessAndOpenDebentureValues): string {
    const { customerTypesBySector } = sector;
    return customerTypesBySector.join(' + ') || '';
  }

}
