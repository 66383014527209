import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SelectsDataForm } from '@app/models/selectModel';
import { CoviResourceService } from '@app/services/covi-resource.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AffiliateInfoRequest, FindBuyerInfoRequest } from '../models/request/entry-transactions.request';
import { AffiliateInfoResponse, AffiliateInfoResponseData, FindBuyerInfoResponse, FindBuyerInfoResponseData } from '../models/response/entry-transactions.response';

@Component({
  selector: 'app-individual-transactions',
  templateUrl: './individual-transactions.component.html',
  styleUrls: ['./individual-transactions.component.scss']
})
export class IndividualTransactionsComponent implements OnInit {

  //public formGetCode: FormGroup;
  @ViewChild('formGetCode') formGetCode: NgForm;
  @ViewChild('BuyerInfoForm') BuyerInfoForm: NgForm;
  @ViewChild('dayCheckForm') dayCheckForm: NgForm;
  @ViewChild('postdatedCheckForm') postdatedCheckForm: NgForm;
  @ViewChild('invoiceForm') invoiceForm: NgForm;

  @ViewChild('dayCheckContainer') dayCheckContainer: ElementRef;
  @ViewChild('postdatedCheckContainer') postdatedCheckContainer: ElementRef;
  @ViewChild('invoiceContainer') invoiceContainer: ElementRef;

  public exonerationLists: any = [];

  public daysCheck: number[] = [];

  public docTypes: any;

  public dueDate: Date;

  public minDueDate: Date;

  public maxDueDate: any;

  public min = new Date();

  public max = new Date();

  public isSecondStep: boolean = false;

  public isThirdStep: boolean = false;

  public phoneType: boolean = false;

  public bankList: Array<any>;

  public bankName: string;

  public checks: Array<any> = [];

  public invoices: Array<any> = [];

  public cities: Array<any> = [];

  public departments: any;

  public listCities: any;

  public generalLists: SelectsDataForm;

  public checksNum: string;

  public quotaState = [
    {value: 'A', description: 'Activo'},
    {value: 'B', description: 'Bloqueado'},
    {value: 'S', description: 'Suspendido'},
    {value: 'R', description: 'Retirado'},
    {value: 'I', description: 'Inactivo'}
  ]

  public dataQueryCode: AffiliateInfoRequest = {
    queryCodeNo: '',
  };

  public dataAffiliate: AffiliateInfoResponseData = {
    name: '',
    product: '',
    branchOffice: '',
    options: '',
    contract: '',
  };

  public findBuyerInfoRequestData: FindBuyerInfoRequest = {
    documentType: '',
    document: '',
    contract: '',
    option: '',
    media: '',
    mediaValue: '',
    whoRequest: '',
  }

  public dataBuyferInfo: FindBuyerInfoResponseData = {
    name: '',
    quotaValue: '',
    totalValue: '',
    quotaState: '',
    quotaAvailable: '',
    quotaAvailableBuyer: '',
    checkValue: '',
    quotaValueBuyer: '',
    city: '',
    cityCode: '',
    municipally: '',
    address: '',
    phone: '',
    mobile: '',
    email: '',
    bank: '',
    account: '',
    demandsDayCheck: false,
    maxDays: '',
    docsMaxGuarantee: '',
  }

  public authorizeData: any = {
    phone: '',
    queryCode: '',
    titleNo: ''
  }

  private option = '';

  constructor(private quotaGreater: QuotaGreaterService, private listCovinoc: ListsCovinocService, private spinner: NgxSpinnerService, public helper: HelperService, private renderer: Renderer2, private coviResourceService: CoviResourceService) { }

  ngOnInit() {
    this.minDueDate = moment().subtract(1, 'days').toDate();

    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.exonerationLists = response.data[0];
    });
    this.quotaGreater.getBanksByCountry().subscribe((response: any) => {
      this.bankList = response.data[0];
    })
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      let allDocTypes = response.data[0].documentTypesCode;
      this.docTypes = allDocTypes.filter((item) => item.value !== 'P' && item.value !== 'T');
    });
    this.coviResourceService.getGeneralList().subscribe((response: any) => {
    });

    this.generalList();
    this.getDepartments();

  }

  generalList() {
    this.coviResourceService.getGeneralList().subscribe((response: any) => {
      this.generalLists = response;
    },
      (err) => {
        this.listCities = [];
      });
  }
  /*  */
  calculateChecks() {
    this.spinner.show();
    /* TODO */
    let daysCounter = 0;
    this.checks = [];
    this.daysCheck = [];
    this.quotaGreater.findCheckDay({
      query_code: this.dataQueryCode.queryCodeNo,
      buyer_document: this.findBuyerInfoRequestData.document,
      document_type_buyer: this.findBuyerInfoRequestData.documentType,
    }).subscribe((response: any) => {
      this.spinner.hide();
      if(response.data.length > 0) {
        const totalValue = parseFloat(this.dataBuyferInfo.totalValue);
        const checksNum = parseInt(this.checksNum, 10);
        const baseValue = Math.floor(totalValue / checksNum);
        let sumValues = 0;
        if(response.data[0].cuotas_CHEQUE && response.data[0].cuotas_CHEQUE !== " ") {
          for(let i = 0; i <= parseInt(response.data[0].cuotas_CHEQUE) - 1; i++) {
            this.daysCheck.push(i);
          }
        }
        daysCounter = parseInt(response.data[0].dias_CHEQUE);
        for (let i = 0; i < checksNum; i++) {
          let value = baseValue;
          if (i === checksNum - 1) {
            value = totalValue - sumValues;
          } else {
            sumValues += value;
          }

          this.checks.push({
            checkNumber: i + 1,
            value: value,
            postdatedDate: moment(new Date()).add(daysCounter, 'days').format("YYYY-MM-DD"),
            days: daysCounter
          });
          daysCounter += parseInt(response.data[0].dias_CHEQUE);
        }

        /* console.log(this.checks)
        if(this.checks.length > 0) {
          this.dueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
          this.minDueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
          console.log(this.minDueDate);
        } */
      }
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'error',
      });
    });
  }

  changeDate(index, $event) {
    let daysCounter = 0;

    for(let i = index; i < this.checks.length; i++) {
      let min;
      let max;
      let postdatedDate;
      let days;

      if (i === 0) {
        const currentDay = moment(new Date()).add(1, 'days').startOf('day').toDate();
        postdatedDate = moment($event).toDate();
        min = moment(currentDay).toDate();
        max = moment(currentDay).add(36, 'days').toDate();
        days = 0;
      } else {
        let previousDate = this.checks[i - 1].postdatedDate;
        let initialDate = this.checks[0].postdatedDate;
        postdatedDate = moment($event).add(daysCounter, 'days').toDate();
        min = moment(previousDate).add(1, 'days').toDate();
        max = moment(previousDate).add(36, 'days').toDate();
        days = moment(postdatedDate).diff(initialDate, 'days');
      }

      const check = this.checks[i];
      check.postdatedDate = postdatedDate;
      check.min = min;
      check.max = max;
      check.days = days;

      daysCounter += 30;
    }

    this.dueDate = this.checks[this.checks.length - 1].postdatedDate;
  }

  changeValue(index: any, value: any) {
    let totalRest = 0;
    let box = value;
    if (value >= 1000 ) {
      if (value <= this.dataBuyferInfo.totalValue) {
        totalRest = (parseInt(this.dataBuyferInfo.totalValue) - value)/(parseInt(this.checksNum)-1);
        for(let i=0; i< this.checks.length; i++){
          if(i !== index) {
            this.checks[i].value = totalRest;
            this.checks[i].disabled = true;
            //valueRest=  parseInt(this.checks[i].value) + valueRest;
          }
      }
    } else {
          Swal.fire({
            title: `Valor máximo`,
            text: `${this.dataBuyferInfo.totalValue}`,
            type: 'error',
          });
        }
    } else {
      Swal.fire({
        title: `Valor mínimo`,
        text: `1.000`,
        type: 'error',
      });
    }
  }

  calculateInvoices() {
    let daysCounter = 30 + Math.round(parseInt(this.dataBuyferInfo.maxDays));
    let totalValue = parseInt(this.dataBuyferInfo.totalValue);
    let baseValue = Math.floor(totalValue / 3);  
    let remainder = totalValue % 3;  
    this.invoices = [];

    for (let i = 0; i < 3; i++) {
      let value = baseValue;
      if (i < remainder) {  
        value += 1;
      }
      
      this.invoices.push({
        checkNumber: i + 1,
        value: value,
        postdatedDate: moment(new Date()).add(daysCounter, 'days').format("YYYY-MM-DD"),
        days: daysCounter
      });
      
      daysCounter += 30;
    }
  }

  selectTransactionOption(option: string): void {
    this.resetDataBuyerInfo();
    this.option = option;
    if(option === 'D') {
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
      this.renderer.addClass(this.dayCheckContainer.nativeElement, 'show');
    } else if(option === 'P') {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
      this.renderer.addClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.calculateChecks();
    } else if(option === 'F') {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.addClass(this.invoiceContainer.nativeElement, 'show');
    } else {
      this.renderer.removeClass(this.dayCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.postdatedCheckContainer.nativeElement, 'show');
      this.renderer.removeClass(this.invoiceContainer.nativeElement, 'show');
    }
  }

  validateQueryCode(): void {
    this.spinner.show();
    this.selectTransactionOption('C');
    const user = JSON.parse(localStorage.getItem('user'));
    this.quotaGreater.affiliateInfo(this.dataQueryCode)
      .subscribe((response: AffiliateInfoResponse) => {
        if(user.user) {

          this.spinner.hide()
          this.isSecondStep = true;
          this.findBuyerInfoRequestData.whoRequest = user.user;
          this.dataAffiliate = response.data[0];

          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }
    }, (error) => {
      this.spinner.hide()
      this.isSecondStep = false;
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'error',
      });
    });
  }

  resetDataBuyerInfo() {
    this.dataBuyferInfo = {
      name: '',
      quotaValue: '',
      totalValue: '',
      quotaState: '',
      quotaAvailable: '',
      quotaAvailableBuyer: '',
      checkValue: '',
      quotaValueBuyer: '',
      city: '',
      cityCode: '',
      municipally: '',
      address: '',
      phone: '',
      mobile: '',
      email: '',
      bank: '',
      account: '',
      demandsDayCheck: false,
      maxDays: '',
      docsMaxGuarantee: '',
    };
  }

  findBuyerInfo(): void {
    this.spinner.show()
    const user = JSON.parse(localStorage.getItem("user"));

    //reset dataBuyerInfo
    //this.resetDataBuyerInfo();


    if(user) {
      const body: FindBuyerInfoRequest = {
        documentType: this.findBuyerInfoRequestData.documentType,
        document: this.findBuyerInfoRequestData.document,
        contract: this.dataAffiliate.contract,
        option: this.findBuyerInfoRequestData.option,
        media: this.findBuyerInfoRequestData.media,
        mediaValue: this.findBuyerInfoRequestData.mediaValue,
        whoRequest: this.findBuyerInfoRequestData.whoRequest,
      }

      this.quotaGreater.findBuyerInfo(body).subscribe((response: FindBuyerInfoResponse) => {
        this.selectTransactionOption(this.findBuyerInfoRequestData.option)
        console.log(this.quotaState);
        /* this.dayCheckForm.resetForm({

        }); */
        console.log(this.quotaState);
        this.spinner.hide();
        this.isThirdStep = true;
        this.dataBuyferInfo = response.data[0];
        this.checks = []; /* moment(new Date()).add(daysCounter, 'days').format("YYYY-MM-DD") */
        this.maxDueDate = new Date(moment(new Date()).add(parseInt(this.dataBuyferInfo.maxDays) + 60, 'days').format("YYYY-MM-DD"))
        this.authorizeData.titleNo = '';
        if(this.dataBuyferInfo.municipally) {
          this.selectDeparment(this.dataBuyferInfo.municipally);
        }
        if(this.dataBuyferInfo.phone) {
          this.dataBuyferInfo.phone = this.dataBuyferInfo.phone.split(' ')[1];
        }
        if(this.dataBuyferInfo.mobile) {
          this.dataBuyferInfo.mobile = this.dataBuyferInfo.mobile.split(' ')[1];
        }

        this.dueDate = moment(this.checks[this.checks.length - 1].postdatedDate).subtract(1, 'days').toDate();
        this.getBankFromId();

        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide()
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      })
    }
  }

  authorize() {
    this.spinner.show();
    /* if(this.option === "P") {
      this.calculateChecks();
    } */
    const user = JSON.parse(localStorage.getItem("user"));
    let checks = [];
    console.log(this.checks)
    this.checks.forEach((item, key) => {
      checks.push({
        valueTitle: key,
        dateToPaid: moment(item.postdatedDate).format("YYYYMMDD"),
        recordValue: item.value
      })
    });

    if(user) {
      let body: any = {
        documentType: this.findBuyerInfoRequestData.documentType,
        document: this.findBuyerInfoRequestData.document,
        user: this.findBuyerInfoRequestData.whoRequest,
        webUser: user.user,
        queryCode: this.dataQueryCode.queryCodeNo,
        name: this.dataBuyferInfo.name,
        bank: this.dataBuyferInfo.bank,
        account: this.dataBuyferInfo.account || 0,
        checkValue: this.dataBuyferInfo.checkValue || 0,
        phone: this.dataBuyferInfo.phone || 0,
        mobile: this.dataBuyferInfo.mobile || 0,
        address: this.dataBuyferInfo.address,
        city: this.dataBuyferInfo.city,
        municipally: this.dataBuyferInfo.municipally,
        cityCode: this.dataBuyferInfo.cityCode,
        totalValue: (this.option === 'D') ? this.dataBuyferInfo.checkValue : this.dataBuyferInfo.totalValue,
        titleNo: this.authorizeData.titleNo,
        dueDate: moment(this.dueDate).format('YYYYMMDD'),
        option: this.findBuyerInfoRequestData.option,
      }

      if(this.option === 'P') {
        body.records = checks;
      }
      console.log(this.checks);

      let product = '';
      this.quotaGreater.findCheckDay({
        query_code: this.dataQueryCode.queryCodeNo,
        buyer_document: this.findBuyerInfoRequestData.document,
        document_type_buyer: this.findBuyerInfoRequestData.documentType,
      }).subscribe((response: any) => {
        product = response.data[0].producto || '';
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      });

      this.quotaGreater.authorize(body).subscribe((response: any) => {
        this.spinner.hide();
      
        // Verificar la condición de this.option
        if ((this.option === 'F') || product === 'TRESPG') {
          
          // Parsear el campo 'message' a un objeto JSON
          let parsedMessage;
          try {
            // Elimina las llaves y convierte el string en un objeto JSON
            parsedMessage = JSON.parse(response.data[0].message
              .replace(/(\w+)=/g, '"$1":').replace(/'/g, '"'));
          } catch (e) {
            console.error('Error al parsear el mensaje:', e);
          }
      
          if (parsedMessage) {
            // Formatear las fechas usando moment.js
            const formattedFecha1 = moment(parsedMessage.Fecha1, 'YYYYMMDD').format('DD/MM/YYYY');
            const formattedFecha2 = moment(parsedMessage.Fecha2, 'YYYYMMDD').format('DD/MM/YYYY');
            const formattedFecha3 = moment(parsedMessage.Fecha3, 'YYYYMMDD').format('DD/MM/YYYY');
      
            // Valores a pagar
            const valor1 = parsedMessage.valor1;
            const valor2 = parsedMessage.valor2;
            const valor3 = parsedMessage.valor3;
      
            Swal.fire({
              title: `Código Autorización: ${parsedMessage.CodigoAutorizacion}`,
              html: `
                <strong>Fechas de pago y valores:</strong><br>
                1. ${formattedFecha1} - Valor: $${valor1}<br>
                2. ${formattedFecha2} - Valor: $${valor2}<br>
                3. ${formattedFecha3} - Valor: $${valor3}
              `,
              type: 'success',
            });
          } else {
            Swal.fire({
              title: `${response.message}`,
              text: `${response.data[0].message}`,
              type: 'success',
            });
          }
        } else {
          Swal.fire({
            title: `${response.message}`,
            text: `${response.data[0].message}`,
            type: 'success',
          });
        }
      
      }, (error) => {
        this.spinner.hide();
        if (error.error.data) {
          Swal.fire({
            title: `${error.error.message}`,
            text: `${error.error.data[0].message}`,
            type: 'error',
          });
        } else {
          Swal.fire({
            title: `${error.error.message}`,
            text: `${error.error.data[0].message}`,
            type: 'error',
          });
        }
      });

    }
  }

  changeMediaType() {
    this.findBuyerInfoRequestData.mediaValue = '';
  }

  changePhoneType(event): void {
    const { value } = event.target;
    this.phoneType = (value === 'phone') ? true : false;
    //this.dataBuyferInfo.phone = '';
  }

  changePhone() {
    this.dataBuyferInfo.phone = '';
  }

  private getBankFromId() {
    if(this.dataBuyferInfo.bank) {
      this.bankList.forEach((item) => {
        if(this.dataBuyferInfo.bank.toString() === item.value) {
          this.bankName = item.description;
        }
      });
    }
  }

  getDepartments() {
    this.coviResourceService.getMunicipalities().subscribe((departments: any) => {
      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  selectDeparment(selectedDepartment) {
    const departmentToSearch = this.departments.filter((item ) => item.description === selectedDepartment);
    this.dataBuyferInfo.city = "";
    this.coviResourceService.getCities(departmentToSearch[0].value).subscribe(
      (municipalities: any) => {
        this.listCities = municipalities.data[0].cities || [];
      },
      (err) => {
        this.listCities = [];
      });
  }

  selectCityCode() {
    let cityDescription = this.listCities.filter((item) => item.description === this.dataBuyferInfo.city);
    this.dataBuyferInfo.cityCode = cityDescription[0].value;
  }
}
