import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services/helper.service';
import { TabsService } from '@app/services/tabs.service';

@Component({
  selector: 'app-title-modify.component',
  templateUrl: './title-modify.component.html',
  styleUrls: ['./title-modify.component.scss']
})
export class TitleModifyComponent implements OnInit {

  public navItemTabs: any[] = [];
  public titlesData: any;
  public exonerationsData: any;
  public titlesWithBackingData: any;
  public tabState: string = "#modify";
  public personalInformation: any;

  constructor(private tabs: TabsService, public helper: HelperService) { 
    this.navItemTabs = this.tabs.modifyTitlesTabs
  }

  ngOnInit() {
  }

  onGetTitlesData($event) {
    if(this.tabState === "#modify") {
      this.titlesData = $event;
      
    }
  }

  onGetPersonalInformation($event) {
    console.log($event);
    
    this.personalInformation = $event;
  }

  onGetExonerationsData($event) {
    if(this.tabState === "#reverse-exonerations") {
      this.exonerationsData = $event;
    }
  }

  onGetTitlesWithBackingData($event) {
    if(this.tabState === "#modify-title-with-backing") {
      console.log($event);
      
      this.titlesWithBackingData = $event;
    }
  }

  toggleIsReserve(href) {
    console.log(href);
    this.tabState = href;
  }

}
