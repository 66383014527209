import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  BodyGracePeriod,
  GlobalBilling,
  GracePeriod,
  PreBill,
  SpecialBill,
} from '@app/models/global-billing.models';
import { Observable } from 'rxjs';
import { CutBilling } from '@app/models/cutBillingmodel';
import { BagUpdate } from '@app/models/bag-update.model';
import { Response, ResponseObject } from '@app/models/responseUtil.model';
import { map } from 'rxjs/operators';
import { InformationContract } from '@app/pages/affiliates/models/ResponseContract';
import { ISemaforo } from '@app/pages/affiliates/models/Semaforo.model';
import { UpdateDateBill } from '@app/pages/affiliates/models/UpdateDateBill';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { ReportNoBillOrBill } from '@app/pages/affiliates/models/ReportNoBillorBill.mode';
import { FixedBranch } from '@app/models/branch.model';
import { IndividualBilling } from '@app/models/individual-billing.model';
import { HistoryConsumptionCutDate } from './history-consumption-cut-date';


@Injectable({
  providedIn: 'root',
})
export class GloballBillingService {
  public urlApi: string = '';
  public tokenBasicGlobalBilling: string = '';
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlGlobalBilling;
    this.timeout = environment.httpTimeout;
    this.tokenBasicGlobalBilling = environment.tokenBasicGlobalBilling;
    this.ciCdInit();
  }

  postGlobalBilling(dataGLobalBilling: GlobalBilling) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.post(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/save`,
      dataGLobalBilling,
      options
    );
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { urlGlobalBilling, httpTimeout, tokenBasicGlobalBilling } = apis;
      this.urlApi = urlGlobalBilling;
      this.timeout = httpTimeout;
      this.tokenBasicGlobalBilling = tokenBasicGlobalBilling;
    });
  }

  /*  getGlobalBilling () {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + this.tokenBasicGlobalBilling,
    })
    const options = {
      headers,
    };
    return this.http.get(`${this.urlApi}BackendFacturacion/api/v1/fglobal/findAll?pagina=1&por_pagina=10`,options)
  } */

  getGlobalBilling(body: any): Observable<Response<any>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      body,
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/findAll?pagina=${body.pagina}&por_pagina=20`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  filterGlobalBilling(body: any): Observable<Response<GlobalBilling>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/find-filtered`,
        body,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  getSimulationService(id_bolsa: any, tipo: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const url = `${
      this.urlApi
    }BackendFacturacion/api/v1/fglobal/simulate-amortize?id_bolsa=${encodeURIComponent(
      id_bolsa
    )}&tipo=${encodeURIComponent(tipo)}`;
    const options = {
      headers,
    };
    return this.http.get(url, options);
  }

  getContract(
    typeDocument: string,
    document: number
  ): Observable<Response<InformationContract>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
      params: {
        tipo_documento: typeDocument,
        documento: document.toString(),
      },
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/affiliate-info`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  postCutBilling(dataCutBilling: CutBilling) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.post(
      `${this.urlApi}/BackendFacturacion/api/v1/fglobal/outage-billing`,
      dataCutBilling,
      options
    );
  }

  verifyContractBilling(numberContract: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http.get(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/validate-contract?contrato=${numberContract}`,
      options
    );
  }

  patchRulesCreated(dataUpdate: BagUpdate) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.patch(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/outage-billing`,
      dataUpdate,
      options
    );
  }

  patchBillUpdateState(dataUpdate): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .patch(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/bag-update`,
        dataUpdate,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  bagUpdateSuspended(dataUpdate) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .patch(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/bag-update-suspended`,
        dataUpdate,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getBillingTrafficLigth(id): Observable<ResponseObject<ISemaforo>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/traffic-lights?id_bolsa=${id}`,
        options
      )
      .pipe(
        map((resp: any) => {
          if (resp.ok) {
            const data = resp.data[0];
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }
  patchEditBag(body, path): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http
      .patch(
        `${this.urlApi}/BackendFacturacion/api/v1/fglobal/${path}`,
        body,
        options
      )
      .pipe(
        map((resp: ResponseObject<any>) => {
          return resp;
        })
      );
  }

  getOrigin(id): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/origin-list?id_bolsa=${id}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  /*
  patchChangeDebtorName(body: any): Observable<any> {
    const options = { headers: this.Headers, params: body as any };
    return this.http.patch(
      `${this.urlApiChangeName}/persons/update`,
      {},
      options
    );
  } */
  getDestination(id): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/destination-list?id_bolsa=${id}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getToBilling(id): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/traffic-lights/to-not-bill-list?id_bolsa=${id}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  updateDates(dataGLobalBilling: UpdateDateBill) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.post(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/traffic-lights/update-dates`,
      dataGLobalBilling,
      options
    );
  }

  changeValueBill(query: {
    id_bolsa: number;
    valor: number;
    observacion: string;
  }) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.patch(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/traffic-lights/change-installment-value`,
      query,
      options
    );
  }

  toBill(query: {
    id_bolsa: number;
    body: { cuota: string; fecha: string };
    observacion: string;
  }) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http.patch(
      `${this.urlApi}BackendFacturacion/api/v1/fglobal/traffic-lights/to-not-bill?id_bolsa=${query.id_bolsa}
      &observacion=${query.observacion}`,
      query.body,
      options
    );
  }

  getPreBills(body: any): Observable<Response<PreBill | string>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/pre-billing-report`,
        body,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  postInstallmentActiveSuspended(body: any): Observable<Response<PreBill | string>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/quotes-report`,body,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  getBagsReport(body: any): Observable<Response<PreBill | string>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/bags-report`,
        body,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  getSpecialBill(
    currentPage: number,
    forPage: number
  ): Observable<Response<SpecialBill>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/find-specials?pagina=${currentPage}&por_pagina=${forPage}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  getSpecialCases(
    currentPage: number,
    forPage: number,
    id: number
  ): Observable<Response<any>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/find-installments?pagina=${currentPage}&por_pagina=${forPage}&id_bolsa=${id}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  patchEditInstallments(body): Observable<Response<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}/BackendFacturacion/api/v1/fglobal/update-installment`,
        body,
        options
      )
      .pipe(
        map((resp: ResponseObject<any>) => {
          return resp;
        })
      );
  }

  individualBill(body: any): Observable<Response<any>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/individual-billing`,
        body,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getConsecutives() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/consecutives`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  consecutive(numberContract) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/consecutive-of?contract=${numberContract}`,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }
  getBranch(numberContract) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/branch-offices/${numberContract}`,
        options
      )
      .pipe(
        map((resp: Response<FixedBranch>) => {
          return resp;
        })
      );
  }
  /* Bags report  */

  bagsReport(body: {
    filtro:string | null;
    valor: number | string;
    reporte: string;
    descargar: boolean
  }): Observable<Response<string | ReportNoBillOrBill []>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/bags-report`,
        body,
        options
      )
      .pipe(
        map((resp: Response<string | ReportNoBillOrBill []>) => {
          return resp;
        })
      );
  }

  gracePeriod(body:BodyGracePeriod): Observable<Response<GracePeriod>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });

    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/grace-period`,
        body,
        options
      )
      .pipe(
        map((resp: Response<GracePeriod>) => {
          return resp;
        })
      );
  }

  getGracePeriodCondition(id): Observable<Response<GracePeriod>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/grace-period/${id}`,
        options
      )
      .pipe(
        map((resp: Response<GracePeriod>) => {
          return resp;
        })
      );
  }


  postMasiveBilling(body: any): Observable<IndividualBilling> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/masive`,
        body,
        options
      )
      .pipe(
        map((resp: IndividualBilling) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  postIndividualBilling(body: any): Observable<IndividualBilling> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .post(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/masive`,
        body,
        options
      )
      .pipe(
        map((resp: IndividualBilling) => {
          if (resp.ok) {
            const data = resp.data;
            resp.data = data;
            return resp;
          }
          return resp;
        })
      );
  }

  getHistoryConsumptionCutDate(): Observable<HistoryConsumptionCutDate> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + this.tokenBasicGlobalBilling,
    });
    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}BackendFacturacion/api/v1/fglobal/historical-billing-cut`,
        options
      )
      .pipe(
        map((resp:HistoryConsumptionCutDate) => {
          return resp;
        })
      );
  }
}
