

// Códigos de Estados de pago para Tarjeta/Cartera
export const PAYMENT_STATUS_CODES_CREDIT_CARD_AND_PORTFOLIO: { [key: string]: PaymentStatus } = {
    "00": {
        code: "00",
        name: 'No disponible',
        behavior: 'N/A',
        state: 'N/A',
        description: 'Información no disponible o no reportada por el suscriptor'
    },
    "01": {
        code: "01",
        name: 'Al día',
        behavior: 'N',
        state: 'Vigente',
        description: 'Aplica a todas aquellas obligaciones que se encontraban al día en el momento de extraer la información que fue enviada a DataCrédito (fecha de corte)'
    },
    "02": {
        code: "02",
        name: 'Tarjeta no entregada',
        behavior: 'N',
        state: 'Cerrada',
        description: 'Cuando la tarjeta no ha sido entregada por el banco o no ha sido reclamada por el usuario'
    },
    "03": {
        code: "03",
        name: "Cancelada por mal manejo",
        behavior: "",
        state: "Cerrada",
        description: "Cuando el cliente hace mal uso de la tarjeta y el suscriptor toma la decisión de cancelarla"
    },
    "04": {
        code: "04",
        name: "Tarjeta robada",
        behavior: "N",
        state: "Cerrada",
        description: "Cuando el usuario de la tarjeta de crédito reporta a la entidad emisora, que el plástico ha sido robado"
    },
    "05": {
        code: "05",
        name: "Cancelada vol.",
        behavior: "N",
        state: "Cerrada",
        description: "Cuando el cliente cancela por voluntad propia el derecho a usar la tarjeta"
    },
    "06": {
        code: "06",
        name: "Cancelada MX",
        behavior: "",
        state: "Cerrada",
        description: "Cuando la tarjeta fue retirada del archivo maestro del suscriptor, sin especificar el motivo. Adicionalmente muestra la mora máxima en que incurrió la obligación"
    },
    "07": {
        code: "07",
        name: "Tarjeta extraviada",
        behavior: "",
        state: "Cerrada",
        description: "Cuando el usuario de la tarjeta de crédito reporta a la entidad emisora que el plástico se ha extraviado"
    },
    "08": {
        code: "08",
        name: "Pago voluntario",
        behavior: "N",
        state: "Cerrada",
        description: "Pago total de la Deuda (cancelación final de la obligación). Esta novedad se presenta cuando la obligación ha llegado a su final, su saldo es igual a cero y no presenta mora en el vector de comportamiento"
    },
    "09": {
        code: "09",
        name: "Pago voluntario MX 30",
        behavior: "N",
        state: "Cerrada",
        description: "Pago total de la Deuda (cancelación final de la obligación). Esta novedad se presenta cuando la obligación ha llegado a su final, su saldo es igual a cero y en el vector de comportamiento presenta una mora máxima de 30 días"
    },
    "10": {
        code: "10",
        name: "Pago voluntario MX 60",
        behavior: "N",
        state: "Cerrada",
        description: "Pago total de la Deuda (cancelación final de la obligación). Esta novedad se presenta cuando la obligación ha llegado a su final, su saldo es igual a cero y en el vector de comportamiento presenta una mora máxima de 60 días"
    },
    "11": {
        code: "11",
        name: "Pago voluntario MX 90",
        behavior: "N",
        state: "Cerrada",
        description: "Pago total de la Deuda (cancelación final de la obligación). Esta novedad se presenta cuando la obligación ha llegado a su final, su saldo es igual a cero y en el vector de comportamiento presenta una mora máxima de 90 días"
    },
    "12": {
        code: "12",
        name: "Pago voluntario MX 120",
        behavior: "N",
        state: "Cerrada",
        description: "Pago total de la Deuda (cancelación final de la obligación). Esta novedad se presenta cuando la obligación ha llegado a su final, su saldo es igual a cero y en el vector de comportamiento presenta una mora máxima de 120 días"
    },
    "13": {
        code: "13",
        name: "Al día Mora 30",
        behavior: "N",
        state: "Vigente",
        description: "En el vector de comportamiento presenta una mora máxima de 30 días y actualmente se encuentra al día"
    },
    "14": {
        code: "14",
        name: "Al día Mora 60",
        behavior: "N",
        state: "Vigente",
        description: "En el vector de comportamiento presenta una mora máxima de 60 días y actualmente se encuentra al día"
    },
    "15": {
        code: "15",
        name: "Al día Mora 90",
        behavior: "N",
        state: "Vigente",
        description: "En el vector de comportamiento presenta una mora máxima de 90 días y actualmente se encuentra al día"
    },
    "46": {
        code: "46",
        name: "Cartera recuperada",
        behavior: "D",
        state: "Cerrada",
        description: "Cuando la cancelación final de la deuda se logró por vías anormales como cobro judicial, embargo, arreglo entre deudor y acreedor, etc. Normalmente se trata de deudas que han pasado por dudoso recaudo o cartera castigada"
    },
    "49": {
        code: "49",
        name: "Tarjeta renovada",
        behavior: "N",
        state: "Cerrada",
        description: "Cuando la entidad emisora de la tarjeta decide no renovarla para un próximo periodo"
    }
};

export const QUALITY_OF_DEBTOR: { [key: string]: QualityOfDebtor } = {
    "00": {
        portfolioDescription: "Deudor Principal",
        cardDescription: "Deudor Principal"
    },
    "01_03": {
        portfolioDescription: "Codeudor",
        cardDescription: "Amparada"
    },
    "04": {
        portfolioDescription: "Avalista",
        cardDescription: "Amparada"
    },
    "05": {
        portfolioDescription: "Deudor solidario",
        cardDescription: "Amparada"
    },
    "06": {
        portfolioDescription: "Coarrendatario",
        cardDescription: "Amparada"
    },
    "07": {
        portfolioDescription: "Otros Garantes",
        cardDescription: "Amparada"
    },
    "08": {
        portfolioDescription: "Fiador",
        cardDescription: "Amparada"
    },
    "09_95": {
        portfolioDescription: "No Aplica",
        cardDescription: "Amparada"
    },
    "96": {
        portfolioDescription: "Cotitular",
        cardDescription: "Amparada"
    },
    "97": {
        portfolioDescription: "Comunal (Solo para cuentas Microcredito - MCR)",
        cardDescription: "Amparada"
    },
    "98_99": {
        portfolioDescription: "No Aplica",
        cardDescription: "Amparada"
    }
}

export const GUARANTEE_OF_HABIT_OF_PAYMENT_OF_CURRENT_OBLIGATIONS: { [key: string]: WarrantyOfHabitOfPaymentOfCurrentObligation } = {
    "0": {
        code: "0",
        name: "SIN GAR",
        description: "Sin garantía"
    },
    "1": {
        code: "1",
        name: "ADMIS",
        description: "Admisible"
    },
    "2": {
        code: "2",
        name: "OTR GAR",
        description: "Otras garantías idóneas"
    },
    "A": {
        code: "A",
        name: "NO IDON",
        description: "No idónea"
    },
    "B": {
        code: "B",
        name: "BIEN RAICES",
        description: "Bienes raíces comerciales y residenciales, fiducias hipotecarias"
    },
    "C": {
        code: "C",
        name: "OTR PREND",
        description: "Otras prendas."
    },
    "D": {
        code: "D",
        name: "PIGN RENTA",
        description: "Pignoración de rentas de entidades territoriales y descentralizadas de todos los órdenes"
    },
    "E": {
        code: "E",
        name: "GAR SOBER NACION",
        description: "Garantía Soberana de la Nación"
    },
    "F": {
        code: "F",
        name: "CONT IRREV FIDUC",
        description: "Contratos irrevocables de fiducia mercantil de garantía, inclusive aquellos que versen sobre rentas derivadas de contratos de concesión"
    },
    "G": {
        code: "G",
        name: "FNG",
        description: "Garantías otorgadas por el Fondo Nacional de Garantías S.A"
    },
    "H": {
        code: "H",
        name: "CARTA CRÉD",
        description: "Cartas de crédito"
    },
    "I": {
        code: "I",
        name: "FAG",
        description: "FAG (Fondo Agropecuario de Garantías)"
    },
    "J": {
        code: "J",
        name: "PERSONAL",
        description: "Personal"
    },
    "K": {
        code: "K",
        name: "BIEN LEASI NO INMOB",
        description: "Bienes dados en Leasing diferente a inmobiliario"
    },
    "L": {
        code: "L",
        name: "BIEN LEASI INMOB",
        description: "Bienes dados en Leasing inmobiliario"
    },
    "M": {
        code: "M",
        name: "PRENDA TITULO",
        description: "Prenda sobre títulos valores emitidos por instituciones financieras"
    },
    "N": {
        code: "N",
        name: "DEPOSITOS",
        description: "Depósitos de dinero en garantía colateral"
    },
    "O": {
        code: "O",
        name: "SEG CREDITO",
        description: "Seguros de crédito"
    },
    "P": {
        code: "P",
        name: "OTRAS GARANTÍAS IDÓNEAS",
        description: "Otras garantías idóneas"
    },
    "Q": {
        code: "Q",
        name: "NO IDÓNEA",
        description: "Garantías no idóneas"
    }
}

export const CHECKING_AND_SAVINGS_ACCOUNT_STATEMENTS: { [key: string]: CheckingAndSavingsAccountStatement } = {
    "01": {
        code: "01",
        name: "Activa",
        state: "Vigente",
        description: "Cuando la cuenta presenta un manejo normal durante el mes de corte"
    },
    "02": {
        code: "02",
        name: "Cancelada por mal manejo",
        state: "Cerrada",
        description: "Cuando la entidad toma la decisión de cancelar la cuenta por mal uso por parte del titular"
    },
    "05": {
        code: "05",
        name: "Saldada",
        state: "Cerrada",
        description: "Cuando la cuenta se encuentra saldada"
    },
    "06": {
        code: "06",
        name: "Embargada",
        state: "Vigente",
        description: "Cuando la cuenta se encuentra embargada"
    },
    "07": {
        code: "07",
        name: "Embargada-Activa",
        state: "Vigente",
        description: "Cuando la cuenta estuvo embargada pero ya se encuentra de nuevo activa"
    },
    "09": {
        code: "09",
        name: "Inactiva",
        state: "Cerrada",
        description: "Cuando por decisión interna de la entidad una cuenta no ha tenido movimiento alguno durante un lapso"
    }
}

export const CHECKING_AND_SAVINGS_ACCOUNT_CLASS: { [key: string]: CheckingAndSavingsAccountClass } = {
    "0": {
        code: "0",
        name: "NOR",
        description: "Normal"
    },
    "1": {
        code: "1",
        name: "NOM",
        description: "Nomina"
    },
    "2": {
        code: "2",
        name: "GMF",
        description: "GMF (Gravamen Movimiento Financiero)"
    },
    "3": {
        code: "3",
        name: "NGM",
        description: "Nomina GMF"
    },
    "4": {
        code: "4",
        name: "ELE",
        description: "Electrónica"
    },
    "5": {
        code: "5",
        name: "AFC/ACF",
        description: "AFC"
    }
}

export const GENDER_AND_MARITAL_STATUS: { [key: string]: GenderAndMaritalStatus } = {
    "1": {
        code: "1",
        maritalStatus: "Casada",
        gender: "Femenino",
    },
    "2": {
        code: "2",
        maritalStatus: "Viuda",
        gender: "Femenino",
    },
    "3": {
        code: "3",
        maritalStatus: "Mujer",
        gender: "Femenino",
    },
    "4": {
        code: "4",
        maritalStatus: "Hombre",
        gender: "Masculino",
    }
}

export const PORTFOLIO_AND_CREDIT_CARD_ACCOUNT_RATING: PortfolioAndCreditCardAccountRating[] = [
    { code: '1', rating: 'A' },
    { code: '2', rating: 'B' },
    { code: '3', rating: 'C' },
    { code: '4', rating: 'D' },
    { code: '5', rating: 'E' },
    { code: '6', rating: 'AA' },
    { code: '7', rating: 'BB' },
    { code: '8', rating: 'CC' },
    { code: '9', rating: 'K' },
    { code: '-', rating: 'No reporta información' }
];

export const SAVINGS_AND_CHECKING_ACCOUNT_RATING: SavingsAndCheckingAccountRating[] = [
    { code: '0', rating: '-', description: 'No reporta información' },
    { code: '1', rating: 'A', description: 'Normal' },
    { code: '2', rating: 'B', description: 'Sobregiro mayor a 31 días' },
    { code: '3', rating: 'C', description: 'Sobregiro mayor a 61 días' }
]

export const STATE_OF_ORIGIN: StateOfOrigin[] = [
    { code: '0', description: 'Normal' },
    { code: '1', description: 'Reestructurada' },
    { code: '2', description: 'Refinanciada' },
    { code: '3', description: 'Transferida de otro producto' },
    { code: '4', description: 'Comprada' }
];

export const PLASTIC_CONDITION: PlastiCondition[] = [
    { code: '0', description: 'No reportado' },
    { code: '1', description: 'Entregado' },
    { code: '2', description: 'Renovado' },
    { code: '3', description: 'No Renovado' },
    { code: '4', description: 'Reexpedido' },
    { code: '5', description: 'Robado' },
    { code: '6', description: 'Extraviado' },
    { code: '7', description: 'No Entregado' },
    { code: '8', description: 'Devuelto' },
];

export const ACCOUNT_STATUS: AccountStatus[] = [
    { code: '00', description: 'Entidad no reportó' },
    { code: '01', description: 'Al día' },
    { code: '02', description: 'En Mora' },
    { code: '03', description: 'Pago Total' },
    { code: '04', description: 'Pago Judicial' },
    { code: '05', description: 'Dudoso Recaudo' },
    { code: '06', description: 'Castigada' },
    { code: '07', description: 'Dación en Pago' },
    { code: '08', description: 'Cancelada Voluntariamente' },
    { code: '09', description: 'Cancelada por mal manejo' },
    { code: '10', description: 'Prescripción' },
    { code: '11', description: 'Cancelada por la entidad' },
    { code: '12', description: 'Cancelada por reestructuración/refinanciación' },
    { code: '13', description: 'Cancelada por venta' },
    { code: '14', description: 'Insoluta' },
    { code: '15', description: 'Cancelada por siniestro' },
    { code: '16', description: 'Cancelada por Liquidación Patrimonial.' },
];

export const SITUATION_OF_THE_OWNER: SituationOfTheOwner[] = [
    { code: '0', description: 'Normal' },
    { code: '1', description: 'Concordato' },
    { code: '2', description: 'Liquidación Forzosa' },
    { code: '3', description: 'Liquidación Voluntaria' },
    { code: '4', description: 'Proceso de Reorganización' },
    { code: '5', description: 'Ley 550' },
    { code: '6', description: 'Ley 1116' },
    { code: '7', description: 'Otra' },
    { code: '8', description: 'Liquidación Patrimonial' },
];

export const TYPE_OF_CREDIT_CARD: TypeOfCreditCard[] = [
    { code: '0', description: 'No reportado' },
    { code: '1', description: 'Clásica' },
    { code: '2', description: 'Gold' },
    { code: '3', description: 'Platinum' },
    { code: '4', description: 'Otra' }
];

export const FRANCHISE_CARD: FranchiseCard[] = [
    { code: '0', description: 'No reportado' },
    { code: '1', description: 'American Express' },
    { code: '2', description: 'Visa' },
    { code: '3', description: 'Master Card' },
    { code: '4', description: 'Diners' },
    { code: '5', description: 'Privada' },
];

export const RESPONSE_CODE_FROM_HDC_AND_PJ: IResponseCodeExperian[] = [
    { code: "01", description: "Código de suscriptor no existe", isValid: false },
    { code: "02", description: "Clave de consulta errada", isValid: false },
    { code: "03", description: "Numero de terminal no existe. No aplica para WS (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "04", description: "Tipo de documento errado", isValid: false },
    { code: "05", description: "Numero de documento errado", isValid: false },
    { code: "06", description: "Primer apellido errado", isValid: false },
    { code: "07", description: "Fin-consulta tipo 2. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "08", description: "Fin-consulta tipo 4. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "09", description: "Fin-consulta tipo 7. No existe este número de identificación en los archivos de validación de la base de datos", isValid: false },
    { code: "10", description: "Fin-consulta tipo 6. El apellido No coincide con el registrado en la Registraduría Nacional del Estado Civil", isValid: false },
    { code: "11", description: "Terminal desactivada. No aplica para WS (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "12", description: "Clave de consulta bloqueada", isValid: false },
    { code: "13", description: "Fin-consulta tipo 1. La consulta fue efectiva. (Cliente válido Con información comercial en la base de datos de DataCrédito)", isValid: true },
    { code: "14", description: "Fin-consulta tipo 5. El apellido digitado si coincide con el registrado en la base de datos para ese número de cédula, pero la persona NO tiene información comercial para entregar por este canal de consulta", isValid: false },
    { code: "15", description: "Ingreso correcto. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "16", description: "Su prepago ya está agotado. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "17", description: "Clave de consulta vencida", isValid: false },
    { code: "18", description: "Clave de consulta no habilitada", isValid: false },
    { code: "19", description: "Modalidad de prepagos bloqueada. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "20", description: "Modalidad de prepagos aún no habilitada. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "21", description: "Su prepago se encuentra vencido. No aplica para (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "23", description: "No se pudo realizar la consulta, vuelva a intentar", isValid: false },
    { code: "24", description: "Clave de de cifra sin tabla. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false }
];

export const RESPONSE_CODE_FROM_HDC: IResponseCodeExperian[] = [
    { code: "01", description: "Código de suscriptor no existe", isValid: false },
    { code: "02", description: "Clave de consulta errada", isValid: false },
    { code: "03", description: "Numero de terminal no existe. No aplica para WS (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "04", description: "Tipo de documento errado", isValid: false },
    { code: "05", description: "Numero de documento errado", isValid: false },
    { code: "06", description: "Primer apellido errado", isValid: false },
    { code: "07", description: "Fin-consulta tipo 2. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "08", description: "Fin-consulta tipo 4. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "09", description: "Fin-consulta tipo 7. El número de identificación enviado no existe en los archivos de validación de la base de datos", isValid: false },
    { code: "10", description: "Fin-consulta tipo 6. El apellido enviado no coincide con el registrado en la Registraduría Nacional", isValid: false },
    { code: "11", description: "Terminal desactivada. No aplica para WS (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "12", description: "Clave de consulta bloqueada", isValid: false },
    { code: "13", description: "Fin-consulta tipo 1. La consulta fue efectiva (Cliente válido CON información comercial en la base de datos de DataCrédito)", isValid: true },
    { code: "14", description: "Fin-consulta tipo 5. La consulta fue efectiva, pero no hay información disponible para entregar por este canal de consulta", isValid: false },
    { code: "15", description: "Ingreso correcto. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "16", description: "Su prepago ya está agotado. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "17", description: "Clave de consulta vencida", isValid: false },
    { code: "18", description: "Clave de consulta no habilitada", isValid: false },
    { code: "19", description: "Modalidad de prepagos bloqueada. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "20", description: "Modalidad de prepagos aún no habilitada. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "21", description: "Su prepago se encuentra vencido. No aplica para (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false },
    { code: "23", description: "No se pudo realizar la consulta, vuelva a intentar", isValid: false },
    { code: "24", description: "Clave de Dcifra sin tabla. No aplica (En caso de obtener esta respuesta notificar a DataCrédito)", isValid: false }
];

export const RESPONSE_CODE_FROM_RECONOCER: IResponseCodeExperian[] = [
    { code: "02", description: "Clave errada", isValid: false },
    { code: "04", description: "Tipo de documento errado", isValid: false },
    { code: "05", description: "Número de documento errado", isValid: false },
    { code: "06", description: "Primer apellido errado", isValid: false },
    { code: "09", description: "Fin-consulta tipo 7. NO existe este número de identificación en los archivos de validación de la base de datos.", isValid: false },
    { code: "10", description: "Fin-consulta tipo 6. El apellido NO coincide con el registrado en la Registraduría Nacional del Estado Civil.", isValid: false },
    { code: "12", description: "Clave de consulta bloqueada.", isValid: false },
    { code: "13", description: "Fin-consulta tipo 1. La consulta fue efectiva.", isValid: true },
    { code: "14", description: "Fin-consulta tipo 5. El apellido digitado si coincide con el registrado en la base de datos para ese número de cédula, pero la persona NO tiene información comercial en la base de datos de DataCrédito", isValid: false },
    { code: "25", description: "Suscriptor no tiene derecho a ver la información de la consulta ( p. Ej. Debido a que no ha firmado contrato)", isValid: false },
    { code: "26", description: "Se ha utilizado un parámetro inválido para la transacción (e. Ej. Se proporcionó un producto inválido o no se proporcionó alguno).", isValid: false },
    { code: "70", description: "ERROR EN EL WEBSERVICE LOCALIZACION (LOCALIZACION)", isValid: false },
    { code: "71", description: "ERROR EN LA CONEXIÓN CON HOST.", isValid: false },
    { code: "72", description: "ERROR EN LA CONEXIÓN CON HOST TIMEOUT", isValid: false },
    { code: "73", description: "ERROR EN LA CREACION DE LA TRAMA DE CONSULTA A HOST", isValid: false },
    { code: "74", description: "ERROR EN LA LECTURA DE LA CONFIGURACION", isValid: false },
    { code: "75", description: "ERROR EN EL PARSER DE LOCALIZACION A XML", isValid: false },
    { code: "76", description: "ERROR EN LA LOCALIZACION DE LA SOLICITUD", isValid: false }
];

export interface IResponseCodeExperian {
    code: string
    description: string
    isValid?: boolean
}

export interface FranchiseCard {
    code: string
    description: string
}

export interface TypeOfCreditCard {
    code: string
    description: string
}

export interface SituationOfTheOwner {
    code: string
    description: string
}

export interface AccountStatus {
    code: string
    description: string
}

export interface PlastiCondition {
    code: string
    description: string
}

export interface StateOfOrigin {
    code: string
    description: string
}

export interface SavingsAndCheckingAccountRating {
    code: string
    rating: string
    description: string
}

export interface PortfolioAndCreditCardAccountRating {
    code: string
    rating: string
}

export interface GenderAndMaritalStatus {
    code: string
    gender: string
    maritalStatus: string
}

export interface CheckingAndSavingsAccountClass {
    code: string
    name: string
    description: string
}

export interface CheckingAndSavingsAccountStatement {
    code: string
    name: string
    state: CheckingAndSavingsAccountStatementType
    description: string
}

export type CheckingAndSavingsAccountStatementType = '' | 'Vigente' | 'Cerrada';

export interface WarrantyOfHabitOfPaymentOfCurrentObligation {
    code: string
    name: string
    description: string
}

export interface PaymentStatus {
    code: string
    name: string
    behavior: string
    state: PaymentStatusType
    description: string
}

export type PaymentStatusType = '' | 'N/A' | 'Vigente' | 'Cerrada';

export interface QualityOfDebtor {
    portfolioDescription: string
    cardDescription: string
}
