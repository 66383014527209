// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  urlApi: "http://200.41.76.20:8080/ServiciosCovifactura/servicios",
  apiUrl: "https://admincovi.c0v.co/",
  devNodeApiUrl: "https://dev.node.covi.pw/",
  dev07ApiUtl: "https://desarrollo07.c0v.co/",
  dev04ApiUtl: "https://desarrollo04.c0v.co/",
  coviUtilsUrl: "https://admincovi.c0v.co/covi-utils/",
  coviResourceUrl: "http://192.168.13.60:8106/",
  entryTransactionsApi: "https://desarrollo07.c0v.co/covifacturatransactions/api/v1",
  apiUrlPayzen: "http://200.41.76.20:8080/PayzenCorporativo/servicios",
  urlEndpoint:
    "https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos",
  urlInvoicesTemplate:
    "http://desarrollo02.c0v.co:8080/ServiciosCovifacturaDSRL",
  //urlQuotasApi: 'http://200.41.76.20:8080/demo-0.0.1-SNAPSHOT/',
  //urlQuotasApi: 'http://localhost:8096/api/v1/', // local para externos con el back, ya que no me conecta a los servidores covinoc
  urlQuotasApi: 'https://desarrollo07.c0v.co/ASRForm20M/api/v1/', // no borrar, comentar esta y descomentar la de arriba
  //urlAffiliateApi: 'http://localhost:8103/',
  /* urlQuotasApi: "https://admincovi.c0v.co/veinte-millones/api/v1/", */ // no borrar, comentar esta y descomentar la de arriba
  urlDownloadApi:"http://192.168.70.24:8098/api/v1/",
  urlRequest: "https://dev.node.covi.pw/request-management/",
  urlBoxNow: "https://desarrollo07.c0v.co/CajaYa/api/v1/",
  urlaffiliateLink: "https://desarrollo07.c0v.co/affiliateLink/api/v1/",
  urlAffiliateApi: "https://covidev.c0v.co/affiliates/",
  urlCoviFacturaTransactionsApi: 'https://desarrollo07.c0v.co/covifacturatransactions/api/v1/',
  urlBuyerApi:"https://desarrollo07.c0v.co/",
  urlGlobalBilling:"https://dev.node.covi.pw/",
  tokenBasicGlobalBilling: btoa("CLIENT_APP-Buy3RF4cT:qjBuy3R7]Z5@4cT"),
  tokenBasic: btoa("CLIENT_APP-210116ARtVSfJ:aVPtuE22145"),
  tokenBuyer: "CLIENT_APP-210116ARtVSfJ:aVPtuE22145",
  tokenFindAffiliate: "CLIENT_APP-exPr33xx:F7lTr0ND4@a2",
  commonToken: "CLIENT_APP-c0mm0n5:42#@hj78rt%3",
  permissionsToken: "CLIENT_APP-p3rm15510n5:85%fb&2i4b#@",
  loginToken: "CLIENT_APP-s35510n:13%bf&4d9u@#",
  backingsToken: "CLIENT_APP-b4ck1ng5:78%df&0j4v#@",
  boxNowToken: "CLIENT_APP-Cay3RAhy:kjJanpbff@4",
  productsToken: "CLIENT_APP-pr0dct5:07%gl&2j2b@#",
  tokenAffiliate: "CLIENT_APP-Buy3RAhy:kjJanpbff@4",
  tokenDownload:"CLIENT_APP-Aff1L1n7:As55wfhjyt",
  tokenNodeDevApiUrl: "CLIENT_APP-Buy3RF4cT:qjBuy3R7]Z5@4cT",
  tokenGlobalBilling:"CLIENT_APP-qscdr93741:Us3r4cc3ss951753",
  coviResourceToken: "CLIENT_APP-210116ARtVSfJ:aVPtuE22145",
  entryTransactionsApiToken: "CLIENT_APP-1992aaA7:v10Liinfa3@3",
  apiUrlTags:'https://desarrollo04.c0v.co/tags/api/v1',
  apiUrlMassiveCharge: 'https://covidev.c0v.co/Portafolio-Management/api/v1',
  apiAuthMassiveCharge: 'CLIENT_APP-157390@4sRPor7f0l10:BEEmUSTiAF4SePRFm6OJ',
  app: "CoviFactura",
  httpTimeout: 30000,
  production: false,
};
/* */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
