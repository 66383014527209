import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContractAsrTemp } from "@app/models/ContractAsrTemp.model";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Response } from "@app/models/responseUtil.model";
import { map } from "rxjs/operators";
import { Adviser } from "@app/models/adviser.model";
import { ContractEmailAsrTemp } from "@app/models/contractEmailAsrTemp.model";
import { PhoneAsrTemp } from "@app/models/phoneAsrTemp.model";
import { AddressAsrTemp } from "@app/models/addressAsrTemp.model";
import { SelectModel } from "@app/models/select.model";
import { AffiliateAsrTemp } from "@app/models/affiliateAsrTemp.model";
import { QuearyCodeAsrTemp } from "@app/models/quearyCodeAsrTemp.model";
import { Taxpayer } from "@app/models/request/taxpayer.model";
import { CodeSpecialParams } from "@app/models/codeSpecialParams.model";
import { SpecialFactorAsrTemp } from "@app/models/specialFactorAsrTemp.model";
import { FilterCodeSpecialContract } from "@app/models/request/filterCodeSpecialContract.model";
import { ColumnsParamsResponse } from "@app/models/response/columnsParamResponse.model";
import { DelimiterOperativeRequest } from "@app/models/request/DelimiteOperativeRequest.model";
import { TariffsProduct } from "@app/models/TariffsProduct.model";
import { DetailContractAsrTemp } from "@app/models/DetailContractAsrTemp.model";
import { Products } from "@app/models/products.model";
import { DelimiteAsrTemp } from "@app/models/DelimiterAsrTemp.model";
import { ControlContractAsrTemp } from "@app/models/ControlContractAsrTemp.model";
import { DeleteDelimiteroOperativeRequest } from "@app/models/request/deleteDelimiterRequest.model";
import { Bag } from "@app/models/Bag.model";
import { DetailDataDefaultResponse } from "@app/models/response/DetailDataDefaultResponse.model";
import { FacturationPeriod } from "@app/models/facturationPeriod.model";
import { ContractStepper } from "@app/models/contractStepper.model";
import { ContractResponse } from "@app/models/response/ContractResponse.model";
import { OrchestratorResponse } from "@app/models/orchestratorResponse.model";
import { FormContractService } from "@app/pages/contracts/services/form-contract.service";
import { ReferenceMunicipalities } from "@app/pages/quotas/models/referenceMunicipalities.model";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class ContractService {
  private urlApi: string;
  private urlCoviUtils: string;
  private urlApiCoviUtils: string;
  private paramsIndex: null;

  constructor(
    private http: HttpClient,
    private formService: FormContractService,
    private ciCd: CiCdConfigService
  ) {
    this.urlApi = environment.urlAffiliateApi;
    this.urlCoviUtils = environment.apiUrl;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlAffiliateApi, coviUtilsUrl} = apis
      this.urlApi = urlAffiliateApi;
      this.urlCoviUtils = apiUrl;
      this.urlApiCoviUtils = coviUtilsUrl;
       console.log('config ci cd', apiUrl,'urlApi:', this.urlApi);
    });
  }

  postContractAsrTemp(
    contractAsrTemp: ContractAsrTemp
  ): Observable<Response<ContractAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .post(`${this.urlApi}contractAsrTemp/contract`, contractAsrTemp, options)
      .pipe(
        map((resp: Response<ContractAsrTemp>) => {
          return resp;
        })
      );
  }

  postContractReal(
    contractAsrTemp: ContractAsrTemp
  ): Observable<Response<ContractAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .post(`${this.urlApi}contract/contract`, contractAsrTemp, options)
      .pipe(
        map((resp: Response<ContractAsrTemp>) => {
          return resp;
        })
      );
  }

  getContractAsrTempByDocument(
    documentType: string,
    document: number
  ): Observable<Response<ContractResponse>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      typeDocument: documentType,
      document: document.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/getContracts`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractResponse>) => {
          return resp;
        })
      );
  }

  getPersonAsrTempByDocument(
    documentType: string,
    document: number
  ): Observable<Response<AffiliateAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      typeDocument: documentType,
      document: document.toString(),
    };
    return this.http
      .get(`${this.urlApi}contractAsrTemp/getPersonDocument`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<AffiliateAsrTemp>) => {
          return resp;
        })
      );
  }

  getPersonByDocument(
    documentType: string,
    document: number
  ): Observable<Response<AffiliateAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      typeDocument: documentType,
      document: document.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/affiliate`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<AffiliateAsrTemp>) => {
          return resp;
        })
      );
  }

  getContractByNumber(
    numContract: number
  ): Observable<Response<ContractAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contractAsrTemp/getContract`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractAsrTemp>) => {
          return resp;
        })
      );
  }

  getContractRealByNumber(
    numContract: number
  ): Observable<Response<ContractAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/getContract`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractAsrTemp>) => {
          return resp;
        })
      );
  }

  getProduct(): Observable<Response<any>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(`${this.urlApi}product/getProducts`, {
        headers: headers,
      })
      .pipe(
        map((resp: Response<any>) => {
          localStorage.setItem("product", JSON.stringify(resp.data[0]));
          return resp;
        })
      );
  }

  getAdviserActive(): Observable<Response<Adviser>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(`${this.urlApi}adviser/getAdvisersActive`, {
        headers: headers,
      })
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getAdviser(): Observable<Response<Adviser>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(`${this.urlApi}adviser/getAdvisers`, {
        headers: headers,
      })
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getAffiliate(idAffiliate: number): Observable<Response<AffiliateAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: idAffiliate.toString(),
    };
    return this.http
      .get(`${this.urlApi}affiliate/getAffiliateAsrTemp`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<AffiliateAsrTemp>) => {
          return resp;
        })
      );
  }

  postContractPhoneAsrTemp(
    phoneAsrTemp: PhoneAsrTemp
  ): Observable<Response<PhoneAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/phonesAffiliates`
      : `${this.urlApi}contractAsrTemp/contractPhone`;

    return this.http.post(`${url}`, phoneAsrTemp, options).pipe(
      map((resp: Response<PhoneAsrTemp>) => {
        return resp;
      })
    );
  }

  postContractAddressAsrTemp(
    addressAsrTemp: AddressAsrTemp
  ): Observable<Response<AddressAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/addressesAffiliates`
      : `${this.urlApi}contractAsrTemp/contractAddress`;

    return this.http.post(url, addressAsrTemp, options).pipe(
      map((resp: Response<AddressAsrTemp>) => {
        return resp;
      })
    );
  }

  postContractEmailAsrTemp(
    contractEmailAsrTemp: ContractEmailAsrTemp
  ): Observable<Response<ContractEmailAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status ? "contract" : "contractAsrTemp";

    return this.http
      .post(`${this.urlApi}${url}/contractEmail`, contractEmailAsrTemp, options)
      .pipe(
        map((resp: Response<ContractEmailAsrTemp>) => {
          return resp;
        })
      );
  }

  getContractEmailtByNumber(
    numContract: number
  ): Observable<Response<ContractEmailAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
    };

    let url = this.formService.status ? "contract" : "contractAsrTemp";

    return this.http
      .get(`${this.urlApi}${url}/getContractEmail`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractEmailAsrTemp>) => {
          return resp;
        })
      );
  }

  getContractAddressByNumber(
    numberContract: number
  ): Observable<Response<AddressAsrTemp[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numberContract.toString(),
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/getContractAddressesAffiliates`
      : `${this.urlApi}contractAsrTemp/getContractAddresses`;

    return this.http
      .get(url, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<AddressAsrTemp[]>) => {
          return resp;
        })
      );
  }

  getContractPhoneByNumber(
    numberContract: number
  ): Observable<Response<PhoneAsrTemp[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numberContract.toString(),
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/getContractPhoneAffiliates`
      : `${this.urlApi}contractAsrTemp/getContractPhones`;

    return this.http
      .get(url, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<PhoneAsrTemp[]>) => {
          return resp;
        })
      );
  }

  postQuearyCodeAsrTemp(
    quearyCodeAsrTemp: QuearyCodeAsrTemp
  ): Observable<Response<QuearyCodeAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .post(
        `${this.urlApi}contractAsrTemp/quearyCode`,
        quearyCodeAsrTemp,
        options
      )
      .pipe(
        map((resp: Response<QuearyCodeAsrTemp>) => {
          return resp;
        })
      );
  }

  postQuearyCodeReal(
    quearyCodeAsrTemp: QuearyCodeAsrTemp
  ): Observable<Response<any>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .post(`${this.urlApi}contract/quearyCode`, quearyCodeAsrTemp, options)
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getContractQuearyCodeByNumber(
    numberContract: number
  ): Observable<Response<QuearyCodeAsrTemp[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numberContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contractAsrTemp/getContractQuearyCode`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<QuearyCodeAsrTemp[]>) => {
          return resp;
        })
      );
  }

  getContractQuearyCodeRealByNumber(
    numberContract: number
  ): Observable<Response<QuearyCodeAsrTemp[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numberContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/getContractQuearyCodes`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<QuearyCodeAsrTemp[]>) => {
          return resp;
        })
      );
  }

  getQuearyCodeByIdAsrTemp(
    id: number
  ): Observable<Response<QuearyCodeAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      idQuearyCode: id.toString(),
    };
    return this.http
      .get(`${this.urlApi}contractAsrTemp/getContractQuearyCodeId`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<QuearyCodeAsrTemp>) => {
          return resp;
        })
      );
  }

  getQuearyCodeByIdReal(id: number): Observable<Response<QuearyCodeAsrTemp>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberCode: id.toString(),
    };
    return this.http
      .get(`${this.urlApi}contract/getContractQuearyCodeId`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<QuearyCodeAsrTemp>) => {
          return resp;
        })
      );
  }

  postTaxpayerAsrTemp(taxPayer: Taxpayer): Observable<Response<Taxpayer>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status ? "contract" : "contractAsrTemp";

    return this.http
      .post(`${this.urlApi}${url}/taxpayer`, taxPayer, options)
      .pipe(
        map((resp: Response<Taxpayer>) => {
          return resp;
        })
      );
  }

  getTaxpayerAsrTemp(
    numContract: number,
    documentType: string,
    document: number
  ): Observable<Response<Taxpayer>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
      typeDocument: documentType,
      document: document.toString(),
    };

    let url = this.formService.status ? "contract" : "contractAsrTemp";

    return this.http
      .get(`${this.urlApi}${url}/getTaxpayer`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<Taxpayer>) => {
          return resp;
        })
      );
  }

  getSelectData() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get<any>(`${this.urlApiCoviUtils}/lists`, options)
      .pipe(
        map((listsResponse: any) => {
          if (listsResponse["data"] && listsResponse["data"].length > 0) {
            localStorage.setItem(
              "covi-utils",
              JSON.stringify(listsResponse["data"][0])
            );
          }
          return listsResponse;
        })
      );
  }

  getSelectCity(codMunicipaly: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      municipaly: codMunicipaly,
    };
    return this.http
      .get<Response<any>>(`${this.urlCoviUtils}/covi-utils/lists/cities`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getSelectActivityAndGroup() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get<Response<any>>(
        `${this.urlCoviUtils}/covi-utils/lists/activitiesWithGroups`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getSelectActivityGroup(codActivity: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      activity: codActivity.toString(),
    };
    return this.http
      .get<Response<any>>(
        `${this.urlCoviUtils}/covi-utils/lists/activityGroup`,
        {
          headers: headers,
          params: params,
        }
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getAllCovifacturaTax() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get<Response<any>>(
        `${this.urlCoviUtils}/covi-utils/lists/findAllCovifactura`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<any>) => {
          let taxResponsabilities = resp.data[0].taxResponsabilities;
          let taxRegimes = resp.data[0].taxRegimes;
          let applyDays = resp.data[0].applyDays;
          if (taxResponsabilities) {
            localStorage.setItem(
              "taxResponsabilities",
              JSON.stringify(taxResponsabilities)
            );
          }

          if (taxRegimes) {
            localStorage.setItem("taxRegimes", JSON.stringify(taxRegimes));
          }
          if (applyDays) {
            localStorage.setItem("applyDays", JSON.stringify(applyDays));
          }
          return resp;
        })
      );
  }

  getCodeSpecial() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get<Response<CodeSpecialParams[]>>(
        `${this.urlApi}codeSpecial/getCodeSpecial`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<CodeSpecialParams[]>) => {
          return resp;
        })
      );
  }

  getCodeSpecialDescription(description: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      description: description,
    };
    return this.http
      .get<Response<CodeSpecialParams[]>>(
        `${this.urlApi}codeSpecial/getCodeSpecialDescription`,
        {
          headers: headers,
          params: params,
        }
      )
      .pipe(
        map((resp: Response<CodeSpecialParams[]>) => {
          return resp;
        })
      );
  }

  saveAnotherYes(
    specialFactorTemp: SpecialFactorAsrTemp[]
  ): Observable<Response<SpecialFactorAsrTemp[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/contractAnotherYes`
      : `${this.urlApi}contractAsrTemp/contractAnotherYes`;

    return this.http.post(url, specialFactorTemp, options).pipe(
      map((resp: Response<SpecialFactorAsrTemp[]>) => {
        return resp;
      })
    );
  }

  getAnotherYes(filterCodeSpecialContract: FilterCodeSpecialContract) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params: any = filterCodeSpecialContract;
    const options = {
      params: params,
    };

    let url = this.formService.status
      ? `${this.urlApi}contract/contractAnotherYes`
      : `${this.urlApi}contractAsrTemp/contractAnotherYes`;

    return this.http
      .get<Response<SpecialFactorAsrTemp[]>>(url, {
        headers: headers,
        params: options.params,
      })
      .pipe(
        map((resp: Response<SpecialFactorAsrTemp[]>) => {
          return resp;
        })
      );
  }

  setParamsIndex(paramsIndex: any) {
    this.paramsIndex = paramsIndex;
  }

  getParamsIndex(): any {
    return this.paramsIndex;
  }

  getColumnOperative() {
    return this.http
      .get<Response<ColumnsParamsResponse[]>>(
        `${this.urlApi}columParam/getColumParam`
      )
      .pipe(
        map((resp: Response<ColumnsParamsResponse[]>) => {
          return resp;
        })
      );
  }

  sendDelimiterOperative(delimiterOperative: DelimiterOperativeRequest) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let path = this.formService.status
      ? "operativeDelimiter"
      : "operativeDelimiterAsrTemp";

    return this.http
      .post(
        `${this.urlApi}${path}/operativeDelimiter`,
        delimiterOperative,
        options
      )
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getPriceProduct(product: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params: any = product;

    return this.http
      .get(`${this.urlApi}product/getProductValue?product=${product}`, {
        headers: headers,
      })
      .pipe(
        map((resp: Response<Bag[]>) => {
          return resp;
        })
      );
  }

  getTariff() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .get(`${this.urlApi}product/getProductTariffs`, {
        headers: headers,
      })
      .pipe(
        map((resp: Response<TariffsProduct[]>) => {
          return resp;
        })
      );
  }

  downloadExcel(numberContract: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params: any = { numberContract: numberContract };
    const options = {
      params: params,
    };

    let path = this.formService.status
      ? "operativeDelimiter"
      : "operativeDelimiterAsrTemp";

    return this.http
      .get<Response<string[]>>(
        `${this.urlApi}${path}/downloadReportDelimiter`,
        {
          headers: headers,
          params: options.params,
        }
      )
      .pipe(
        map((resp: Response<string[]>) => {
          return resp;
        })
      );
  }

  sendDetailContract(detailContract: DetailContractAsrTemp, isEdit: boolean) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status
      ? `${this.urlApi}contractDetail`
      : `${this.urlApi}contractDetailAsrTemp`;

    return this.http
      .post(`${url}?isEdit=${isEdit}`, detailContract, options)
      .pipe(
        map((resp: Response<DetailContractAsrTemp>) => {
          return resp;
        })
      );
  }

  getDetailContract(numberContract: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    let url = this.formService.status
      ? `${this.urlApi}contractDetail`
      : `${this.urlApi}contractDetailAsrTemp`;

    return this.http
      .get<Response<DetailContractAsrTemp[]>>(
        `${url}?numberContract=${numberContract}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<DetailContractAsrTemp[]>) => {
          return resp;
        })
      );
  }

  getDelimiterOperative(numberContract: number, user: string, product: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let path = this.formService.status
      ? "operativeDelimiter"
      : "operativeDelimiterAsrTemp";
    return this.http
      .get<Response<DelimiterOperativeRequest>>(
        `${this.urlApi}${path}?numberContract=${numberContract}&user=${user}&product=${product}`,
        { headers: headers }
      )
      .pipe(
        map((resp: Response<DelimiterOperativeRequest>) => {
          return resp;
        })
      );
  }

  deleteDelimiterOperative(
    operative: DelimiteAsrTemp,
    controlContract: ControlContractAsrTemp
  ) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let request: DeleteDelimiteroOperativeRequest = {
      delimiters: operative,
      tempControl: controlContract,
    };

    let path = this.formService.status
      ? "operativeDelimiter"
      : "operativeDelimiterAsrTemp";

    return this.http
      .post(`${this.urlApi}${path}/operativeDelimiterColumn`, request, options)
      .pipe(
        map((resp: Response<Number[]>) => {
          return resp;
        })
      );
  }

  getPercentage(numberContract: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };

    let url = this.formService.status
      ? `${this.urlApi}contractDetail`
      : `${this.urlApi}contractDetailAsrTemp`;

    return this.http
      .get(`${url}/getPercentage?numberContract=${numberContract}`, options)
      .pipe(
        map((resp: Response<DetailDataDefaultResponse>) => {
          return resp;
        })
      );
  }

  geFacturationPeriod() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http
      .get<Response<FacturationPeriod[]>>(
        `${this.urlApi}itemSelect/facturationPeriod`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((resp: Response<FacturationPeriod[]>) => {
          return resp;
        })
      );
  }

  getSequenceQuearyCode(numContract: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}itemSelect/getSequence`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractStepper>) => {
          return resp;
        })
      );
  }

  getContractStepperByNumber(
    numContract: number
  ): Observable<Response<ContractStepper>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numContract.toString(),
    };
    return this.http
      .get(`${this.urlApi}contractStepper/getContractStepper`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: Response<ContractStepper>) => {
          return resp;
        })
      );
  }

  postContractOrchestrator(
    numberContract: number
  ): Observable<Response<OrchestratorResponse>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const params = {
      numberContract: numberContract.toString(),
    };
    const options = {
      headers,
      params,
    };
    return this.http
      .post(
        `${this.urlApi}contract/contractOrchestrator?numberContract=${numberContract}`,
        options
      )
      .pipe(
        map((resp: Response<OrchestratorResponse>) => {
          return resp;
        })
      );
  }

  getDepartmentsCities(): Observable<Response<ReferenceMunicipalities>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}referenceMunicipality/getDepartmentsMunicipalities`,
        options
      )
      .pipe(
        map((resp: Response<ReferenceMunicipalities>) => {
          if (resp.data && resp.data.length > 0) {
            localStorage.setItem(
              "form20m_municipalitiesList",
              JSON.stringify(resp.data)
            );
          }
          return resp;
        })
      );
  }
}
