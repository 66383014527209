import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { MockService } from '@app/services/mock.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { SelectsService } from '@app/services/selects.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reserves',
  templateUrl: './reserves.component.html',
  styleUrls: ['./reserves.component.scss']
})
export class ReservesComponent implements OnInit {

  quotaVsAffiliateSearchForm: FormGroup;

  saveReserveForm: FormGroup;

  searchBySettlement: Boolean = true;

  isQuerySuccess: boolean = false;

  isReservesHistory: boolean = false;

  reservationList: any;

  quotaVsAffiliateData: any;

  quotaMedia: any = 0;

  quotaGlobal: any = 0;

  docTypes: any;

  reservationDays: number;

  reservationListId: number;

  stateList = [
    { value: "A", description: "Activo" },
    { value: "R", description: "Retirado" },
  ]

  quotaDummyData = {
    ok: true,
    message: "Finalizó correctamente la consulta.",
    data: {
        documentType: "C",
        document: 37391823,
        name: "SANDRA LILIANA MANTILLA CUADRADO",
        classification: "NUEVO",
        quotas: [
            {
                contract: 522297,
                name: "HEAVY MOGUL S.A.S",
                product: "SEP",
                state: "A",
                quotaAffiliate: 30000000,
                checkDay: 0,
                postDatedCheck: 0,
                invoice: 10000000,
                availableQuota: 30000000,
                toPay: 0,
                lyric: 0,
                ownDoc: 0,
                date: "2019-11-18 15:28:31",
                inUser: "DIVORT",
                dateUpdate: "2021-05-03 14:53:52",
                userUpdate: "COVIWEB"
            },
            {
                contract: 23423,
                name: "HEAVY MOGUL S.A.S",
                product: "SEP",
                state: "A",
                quotaAffiliate: 4353453,
                checkDay: 0,
                postDatedCheck: 0,
                invoice: 10000000,
                availableQuota: 657567,
                toPay: 0,
                lyric: 0,
                ownDoc: 0,
                date: "2019-11-18 15:28:31",
                inUser: "DIVORT",
                dateUpdate: "2021-05-03 14:53:52",
                userUpdate: "COVIWEB"
            },
        ]
    },
    status: 200,
    currentPage: 1,
    totalPages: 1
}

  public selectedQuota: any;

  public selectedReserve: any;
  public validationReserveList: Array<any> = [];
  public validationQuotaList: Array<any> = [];
  public reserveDaysList: Array<any> = [];

  constructor(private helper: HelperService, private formBuilder: FormBuilder, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService, private mockService: MockService, public selects: SelectsService, private toastr: ToastrService) { }

  ngOnInit() {
    this.quotaVsAffiliateData = this.quotaDummyData.data;
    console.log(this.quotaVsAffiliateData);
    
    this.quotaVsAffiliateSearchForm = this.formBuilder.group({
      idPayer: ['', Validators.required],
      typeDocument: ['', Validators.required]
    });

    this.saveReserveForm = this.formBuilder.group({
      documentType: ['', Validators.required],
      document: ['', Validators.required],
      queryCode: ['', Validators.required],
      contract: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.quotaGreater.getDocTypes().subscribe((resp: any) => {
      this.docTypes = resp.data
    });

    
    this.initSelect();
  }

  get getIsQuerySuccess() {
    return this.isQuerySuccess;
  }

  initSelect() {
    
    console.log(this.validationReserveList);
    
    

    console.log(this.validationQuotaList);
  }

  onSubmitSearch() {
    this.spinner.show();
    this.isQuerySuccess = true;
    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: 1,
      perPage: 10
    }
    this.quotaGreater.getBuyerQuotas(body).subscribe((resp: any) => {
      this.spinner.hide();
      this.quotaVsAffiliateData = resp.data[0];
      this.getGlobalAndMediaQuota(this.quotaVsAffiliateData);
      for(let i = 0; i <= this.quotaVsAffiliateData.quotas.length - 1; i++) {
        this.validationQuotaList[i] = false;
      }
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  onSubmitSaveReserve() {
    this.saveReserveForm.value;
  }

  selectQuota(id: number) {
    this.selectedQuota = this.quotaVsAffiliateData.quotas[id];
    this.validationQuotaList[id] = true;
    this.disableQuotaValidations(id);
  }

  selectReserve(id) {
    this.selectedReserve = this.reservationList[id];
    console.log(this.selectedReserve);
    this.validationReserveList[id] = true;
    this.disableReserveValidations(id);
    this.changeReservationId(id);
  }

  disableReserveValidations(currentId) {
    this.validationReserveList.forEach((validation, id) => {
      if(currentId !== id) {
        this.validationReserveList[id] = false;
      }
    });
  }

  disableQuotaValidations(currentId) {
    this.validationQuotaList.forEach((validation, id) => {
      if(currentId !== id) {
        this.validationQuotaList[id] = false;
      }
    });
  }

  changeState(id, $event) {
    console.log($event.target.value);
    this.quotaVsAffiliateData.quotas[id].state = $event.target.value;
    console.log(this.quotaVsAffiliateData.quotas);
  }

  changeQuota(id, $event) {
    console.log($event);
    this.quotaVsAffiliateData.quotas[id].quota = $event;
    console.log(this.quotaVsAffiliateData.quotas);
  }

  changeReserveValue(id, $event) {
    console.log($event);
    //this.quotaVsAffiliateData.quotas[id].quota = $event;
    this.reservationList[id].value = $event;
    console.log(this.reservationList);
  }

  changeReservationDays(id, $event) {
    this.reserveDaysList[id] = $event;
    console.log(this.reserveDaysList);
    
  }

  changeReservationId(id) {
    this.reservationListId = id;
  }

  changeReserveQueryCode(id, $event) {
    console.log($event);
    //this.quotaVsAffiliateData.quotas[id].quota = $event;
    this.reservationList[id].queryCode = $event;
    console.log(this.reservationList);
  }

  getReserves() {
    this.spinner.show();
    this.isReservesHistory = true;
    this.saveReserveForm.get('documentType').setValue(this.quotaVsAffiliateSearchForm.get('typeDocument').value);
    this.saveReserveForm.get('document').setValue(this.quotaVsAffiliateSearchForm.get('idPayer').value);

    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: 1,
      perPage: 10,
    }

    this.quotaGreater.getReserves(body).subscribe((response: any) => {
      this.spinner.hide();
      this.reservationList = response.data;
      for(let i = 0; i <= this.reservationList.length - 1; i++) {
        this.validationReserveList[i] = false;
      }
      for(let i = 0; i <= response.data.length - 1; i++) {
        this.reserveDaysList[i] = 5;
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  onUpdateQuota(quota) {
    this.spinner.show();
    console.log(quota);
    console.log(this.selectedQuota);
    
    const body = {
      contract: quota.contract,
      documentType: this.quotaVsAffiliateData.documentType,
      document: this.quotaVsAffiliateData.document,
      state: quota.state,
      quotaValue: quota.quotaAffiliate,
    }

    this.quotaGreater.updateQuotas(body).subscribe((resp: any) => {
      this.spinner.hide();
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })

  }

  onSaveReserve() {
    this.spinner.show();
    console.log(this.selectedQuota);
    
    console.log(this.saveReserveForm.value);

    let identity = JSON.parse(localStorage.getItem('identity'));

    if(identity) {
      console.log(identity);
      
      const body =  {
        document: this.saveReserveForm.get('document').value,
        documentType: this.saveReserveForm.get('documentType').value,
        queryCode: this.saveReserveForm.get('queryCode').value,
        value: this.saveReserveForm.get('value').value,
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        user: identity.username,
        webUser: identity.username,
      }
  
      this.quotaGreater.saveReserve(body).subscribe((response: any) => {
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      });
    }

    
  }

  backToSearch() {
    this.isReservesHistory = false;
  }

  onCancelReserve() {
    this.spinner.show();
    const params = {
      id: this.selectedReserve.id
    }

    this.quotaGreater.cancelReserve(params).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  onModifyReserve(selectedReserve) {

    const body = {
      id: selectedReserve.id,
      days: this.reserveDaysList[this.reservationListId],
      state: selectedReserve.state
    }
    console.log(body);
    
    this.quotaGreater.updateReserve(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })

  }

  getGlobalAndMediaQuota(quotas: any) {
    quotas.quotas.forEach(quota => {
      this.quotaGlobal += quota.quotaAffiliate;
    });
    this.quotaMedia = this.quotaGlobal / quotas.quotas.length;
  }

}
