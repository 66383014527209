import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-debt-level-table',
  templateUrl: './legal-debt-level-table.component.html',
  styleUrls: ['./legal-debt-level-table.component.scss']
})
export class LegalDebtLevelTableComponent implements OnInit {

  @Input() levelOfIndebtednessAndOpenDebentureValues: any[] = [];
  public accumulatedTotalSaldo: number[] = [];
  public accumulatedTotalSaldoMora: number[] = [];

  constructor() { }

  ngOnInit() {
    console.log('Input de....', this.levelOfIndebtednessAndOpenDebentureValues);
    this.levelOfIndebtednessAndOpenDebentureValues.forEach((sector) => {

    });
  }

  ngOnChanges() {
    console.log('ngOnChanges', this.levelOfIndebtednessAndOpenDebentureValues);

    const numColumns = this.levelOfIndebtednessAndOpenDebentureValues[0].headers.length - 4 || 0;
    this.accumulatedTotalSaldo = new Array(numColumns).fill(0);
    this.accumulatedTotalSaldoMora = new Array(numColumns).fill(0);

    this.levelOfIndebtednessAndOpenDebentureValues.forEach(sector => {
      sector.totalSaldo = new Array(numColumns).fill(0);
      sector.totalSaldoMora = new Array(numColumns).fill(0);

      sector.entities.forEach(entity => {
        entity.records.forEach(record => {
          if (record[3] === "Saldo") {
            for (let i = 4; i < record.length; i++) {
              sector.totalSaldo[i - 4] += record[i];
              this.accumulatedTotalSaldo[i - 4] += record[i]; 
            }
          } else if (record[3] === "Saldo Mora") {
            for (let i = 4; i < record.length; i++) {
              sector.totalSaldoMora[i - 4] += record[i];
              this.accumulatedTotalSaldoMora[i - 4] += record[i]; 
            }
          }
        });
      });
    });
  }

}
