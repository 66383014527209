import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsTableHeadersService {

  public tableHeadersBags: string[] = [
    "id",
    "Editar",
    "Valor de la fianza",
    "Porcentaje del servicio",
    "Fianza mensual",
    "Facturación mínima mensual",
    "Facturación mensual",
    "Facturación semestral",
    "Facturación anual",
    "Tarifa de servicio",
    "# de cuotas",
    "Valor a pagar por bolsa",
    "Plazo de uso"
  ];

  constructor() { }
}
