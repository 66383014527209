export class OperationItem {
  agreement: string;
  exonerateDays: string;
  daysMarkNotice: string;
  documentNumber: string;
  documentType: string;
  status: string;
  date: string;
  name: string;
  numberChecks: string;
  reclaimNumber: string;
  numberRecords: string;
  rn: string;
  balance: string;
  titleValue: string;
  valueToExonerate: string;
  guaranteedValue: string;
  expiration: string;
  authorization: string;
  check: boolean;
  amount: number;
  titleValueType?: any;

  public static toExonerate(operationItem: OperationItem): any {
    return {
      tipo_documento: operationItem.documentType,
      documento: operationItem.documentNumber,
      autorizacion: operationItem.authorization,
      titulo_valor: operationItem.titleValue,
      valor_a_exonerar:
        operationItem.amount > 0
          ? operationItem.amount
          : operationItem.valueToExonerate,
    };
  }

  public static toNotify(operationItem: OperationItem): any {
    return {
      tipo_documento: operationItem.documentType,
      documento: operationItem.documentNumber,
      autorizacion: operationItem.authorization,
      titulo_valor: operationItem.titleValue,
      valor_garantizado:
        operationItem.amount > 0
          ? operationItem.amount
          : operationItem.guaranteedValue,
    };
  }

  public static fromResponse(json: any): OperationItem {
    const operationItem: OperationItem = {
      agreement: json['ACUERDO'],
      exonerateDays: json['DIAS_EXONERAR'],
      daysMarkNotice: json['DIAS_MARCAR_AVISO'] ? json['DIAS_MARCAR_AVISO'] : 0,
      documentNumber: json['DOCUMENTO'],
      documentType: json['TIPO_DOCUMENTO'],
      status: json['ESTADO'],
      date: json['FECHA'],
      name: json['NOMBRE'],
      numberChecks: json['NUMERO_CHEQUES'],
      reclaimNumber: json['NUMERO_RECLAMACION'],
      numberRecords: json['N_REGISTROS'],
      rn: json['RN'],
      balance: json['SALDO'],
      titleValue: json['TITULO_VALOR'],
      valueToExonerate: json['VALOR_A_EXONERAR'],
      guaranteedValue: json['VALOR_GARANTIZADO'],
      expiration: json['VENCIMIENTO'],
      authorization: json['AUTORIZACION'],
      check: false,
      amount: 0,
    };
    return operationItem;
  }
}
