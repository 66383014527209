import { Component, OnInit } from "@angular/core";
import { RadicationService } from "@app/pages/quota-radication/services/radication-quota.service";
import { HelperService } from "@app/services/helper.service";
import { RolesService } from "@app/services/roles.service";

@Component({
  selector: "app-global-billing",
  templateUrl: "./global-billing.component.html",
  styleUrls: ["./global-billing.component.scss"],
})
export class GlobalBillingComponent implements OnInit {
  public dateRange: Array<any> = [];

  permissionToView = false;

  public imageData: Array<any> = [];
  public message = "";
  public userName:any;

  public tabs: Array<any> = [
    {
      title: "Consultas pendientes por facturar",
      active: true,
    },
    {
      title: "Facturación por cortes",
      active: false,
    },
    {
      title: "Facturación casos especiales",
      active: false,
    },
    {
      title: "Reportes de facturación",
      active: false,
    },
    /* {
      title: "Variable por consumo",
      active: false,
    }, */
  ];

  constructor(
    public helper: HelperService,
    private roles: RolesService,
    private radicationQuota: RadicationService
  ) {
    this.message = this.radicationQuota.messager;
  }

  ngOnInit() {
    this.getProfile();
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.forEach((tab) => (tab.active = false));
    // activate the tab the user has clicked on.
    tab.active = true;
  }

  getProfile() {
    this.userName = JSON.parse(localStorage.getItem("user"));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == "contract"
          );
          if (permissions && permissions.actions) {
            this.getPermissions(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getPermissions(permissions) {
    if (permissions.actions.globalBill) {
      this.permissionToView = true;
    }
  }
}
