import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { IQueryForm } from '../../components/query-form-history/query-form-history.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditHistoryService } from '../../services/credit-history.service';
import { GetCreditHistoryRequest, GetCreditHistoryResponse } from '../../models/credit-history.model';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { HelperService } from '@app/services/helper.service';
import Swal from 'sweetalert2';
import { HelperCreditInfoService } from '../../services/helper-credit-info.service';
import { IResponseCodeExperian } from '../../constants/global-credit-info';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit, AfterViewInit {

  public showResults: boolean = false;

  // Scroll
  @ViewChild("basicInformationHistory") basicInformationHistory: ElementRef<HTMLElement>;
  @ViewChild("articleHistory") articleHistory: ElementRef<HTMLElement>;
  @ViewChild("summaryHistory") summaryHistory: ElementRef<HTMLElement>;
  @ViewChild("habitOfPaymentActiveHistory") habitOfPaymentActiveHistory: ElementRef<HTMLElement>;
  @ViewChild("habitOfPaymentInactiveHistory") habitOfPaymentInactiveHistory: ElementRef<HTMLElement>;
  @ViewChild("vectorAnalysisHistory") vectorAnalysisHistory: ElementRef<HTMLElement>;
  @ViewChild("debtEvolutionHistory") debtEvolutionHistory: ElementRef<HTMLElement>;
  @ViewChild("historyQueriesHistory") historyQueriesHistory: ElementRef<HTMLElement>;
  @ViewChild("globalClassifiedDebtHistory") globalClassifiedDebtHistory: ElementRef<HTMLElement>;
  @ViewChild("globalDebtSummaryHistory") globalDebtSummaryHistory: ElementRef<HTMLElement>;
  //  @ViewChild("notesHistory") notesHistory: ElementRef<HTMLElement>;
  @ViewChild("scoresHistory") scoresHistory: ElementRef<HTMLElement>;

  public scrollButtons: Array<{
    description: string;
    target: HTMLElement | string;
  }> = [];

  // offset page
  public isShowAnFixedBar: boolean;
  topPosToStartShowing = 100;
  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // console.log('scrollPosition', scrollPosition);
    if (window && window.pageYOffset >= 110) {
      this.isShowAnFixedBar = true;
    } else {
      this.isShowAnFixedBar = false;
    }
  }
  // ./Scroll

  constructor(
    private spinner: NgxSpinnerService,
    private creditHistoryService: CreditHistoryService,
    private subjectCreditHistory: SubjectCreditHistoryService,
    public helper: HelperService,
    private helperCreditInfo: HelperCreditInfoService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.handleInitializeScrollButtons();
  }

  handleInitializeScrollButtons() {
    this.scrollButtons = [
      {
        description: "Información básica",
        target: this.basicInformationHistory.nativeElement,
      },
      {
        description: "Artículos",
        target: this.articleHistory.nativeElement,
      },
      {
        description: "Resúmenes",
        target: this.summaryHistory.nativeElement,
      },
      {
        description: "Hábito de pago activo",
        target: this.habitOfPaymentActiveHistory.nativeElement,
      },
      {
        description: "Hábito de pago inactivo",
        target: this.habitOfPaymentInactiveHistory.nativeElement,
      },
      {
        description: "Análisis de vectores",
        target: this.vectorAnalysisHistory.nativeElement,
      },
      {
        description: "Evolución de deuda",
        target: this.debtEvolutionHistory.nativeElement,
      },
      {
        description: "Consultas históricas",
        target: this.historyQueriesHistory.nativeElement,
      },
      {
        description: "Deuda clasificada global",
        target: this.globalClassifiedDebtHistory.nativeElement,
      },
      {
        description: "Resumen global de deuda",
        target: this.globalDebtSummaryHistory.nativeElement,
      },
      // {
      //   description: "notas",
      //   target: this.notesHistory.nativeElement,
      // },
    ];
  }

  handleSubmitQueryForm(queryForm: IQueryForm) {
    const { documentType, document, lastname, includeScore } = queryForm;
    const request: GetCreditHistoryRequest = {
      identificationType: documentType,
      identification: document,
      firstSurname: lastname,
      force: false,
      service: includeScore ? 'CONSULTAR_HC2_SCORE' : 'CONSULTAR_HC2'
    };
    this.getCreditHistory(request);
  }

  getCreditHistory(request: GetCreditHistoryRequest) {
    this.spinner.show();
    this.creditHistoryService.getCreditHistory(request).subscribe({
      next: (response: GetCreditHistoryResponse) => {
        this.handleValidateError(response);
        // this.handleGetCreditHistorySuccesfully(response);
      },
      error: (error: any) => {
        this.handleGetCreditHistoryError(error);
      }
    })
  }

  handleGetCreditHistorySuccesfully(response: GetCreditHistoryResponse) {
    console.log('Se obtuvó el historial correctamente', response);
    this.subjectCreditHistory.setResponseGetCreditHistory(response);
    // this.showResults = true;
    this.spinner.hide();
  }

  handleValidateError(response: any) {
    const { data } = response;
    if (data) {
      const [fisrtElement = {}] = data;
      const { codigoRespuesta = '' } = fisrtElement;
      const responseCode: IResponseCodeExperian = this.helperCreditInfo.findResponseCodeFromHdc(codigoRespuesta);

      if (responseCode.isValid) {
        console.log('ok');
        this.handleGetCreditHistorySuccesfully(response);
        this.showResults = true;
        this.spinner.hide();
      } else {
        console.log('error al consultar');
        this.showResults = false;
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          html: `No hay resultados para la consulta realizada <br/><br/> Error HDC_${responseCode.code}: ${responseCode.description}`,
        });
        this.spinner.hide();
      }

    }
  }

  handleGetCreditHistoryError(error: any) {
    console.log('Error al procesar servicio de HDC', error);
    this.subjectCreditHistory.setResponseGetCreditHistory(undefined);
    this.showResults = false;
    this.spinner.hide();

    Swal.fire({
      type: 'error',
      title: 'Alerta:',
      html: `Este servicio no se encuentra disponible en este momento.`,
    });
  }

}















