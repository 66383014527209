import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { IScoreInformation } from '../../services/natural-person-adapter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scores-history',
  templateUrl: './scores-history.component.html',
  styleUrls: ['./scores-history.component.scss']
})
export class ScoresHistoryComponent implements OnInit, OnDestroy {

  public headersTable: string[] = ['Tipo de score', 'Evaluación', 'Puntaje', 'Códigos/razones'];
  public scoresInformation: IScoreInformation[] = [];
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  get hasScore(): boolean {
    return this.scoresInformation.length > 0;
  }

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.scoresInformation = response.scoresInformation;
  }

}
