import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdaptedGetCreditHistoryResponse } from '../models/natural-person-adapter.model';

@Injectable({
  providedIn: 'root'
})
export class SubjectCreditHistoryService {

  // En este BehaviorSubject almacena la respuesta adaptada del servicio de HDC PN.
  private responseGetCreditHistory = new BehaviorSubject<AdaptedGetCreditHistoryResponse | undefined>(undefined);
  public responseGetCreditHistory$ = this.responseGetCreditHistory.asObservable();

  constructor() {

  }

  setResponseGetCreditHistory(newValue: AdaptedGetCreditHistoryResponse | undefined) {
    this.responseGetCreditHistory.next(newValue);
  }

}