import { QuotaFormResponse } from "./response/quotaFormResponse.model";

export class QuotaForm {

    ctid: string;
    settlement: number;
    documentTypeAffiliate: string;
    affiliateDocument: number;
    idCountry: number;
    name1: string;
    name2: string;
    lastName1: string;
    lastName2: string;
    businessName: string;
    documentTypeBuyer: string;
    buyerDocument: number;
    expiditionDate: Date;
    codeDaneExpedition: number;
    codeDane: number;
    address: string;
    cellphone: string;
    phone: number;
    email: string;
    birthDate: Date;
    economicActivity: string;
    startActivity: string;
    companyType: string;
    pointOfSale: string;
    requestedQuota: number;
    preferWhatsapp: string;
    preferEmail: string;
    preferSms: string;
    preferCall: string;
    representativeDocumentType: string;
    documentRepresentative: number;
    chargeContact: string;
    phoneContact: number;
    emailContact: string;
    name1Contact: string;
    name2Contact: string;
    lastName1Contact: string;
    lastName2Contact: string;
    referenceType1: string;
    name1Reference1: string;
    name2Reference1: string;
    lastName1Reference1: string;
    lastName2Reference1: string;
    businessNameReference1: string;
    codeDaneReference1: number;
    addressReference1: string;
    phoneReference1: number;
    emailReference1: string;
    referenceType2: string;
    name1Reference2: string;
    name2Reference2: string;
    lastName1Reference2: string;
    lastName2Reference2: string;
    businessNameReference2: string;
    codeDaneReference2: number;
    addressReference2: string;
    phoneReference2: number;
    emailReference2: string;
    queryCode: string;
    user: string;
    updateDate: Date;
    ip: string;
    state: string;
    registrationDate: Date;

    public static fromResponse(json: any): QuotaForm {

        const quotaForm: QuotaForm = {

            ctid: json['ctid'],
            settlement: json['settlement'],
            documentTypeAffiliate: json['documentTypeAffiliate'],
            affiliateDocument: json['affiliateDocument'],
            idCountry: json['idCountry'],
            name1: json['name1'],
            name2: json['name2'],
            lastName1: json['lastName1'],
            lastName2: json['lastName2'],
            businessName: json['businessName'],
            documentTypeBuyer: json['documentTypeBuyer'],
            buyerDocument: json['buyerDocument'],
            expiditionDate: json['expiditionDate'],
            codeDaneExpedition: json['codeDaneExpedition'],
            codeDane: json['codeDane'],
            address: json['address'],
            cellphone: json['cellphone'],
            phone: json['phone'],
            email: json['email'],
            birthDate: json['birthDate'],
            economicActivity: json['economicActivity'],
            startActivity: json['startActivity'],
            companyType: json['companyType'],
            pointOfSale: json['pointOfSale'],
            requestedQuota: json['requestedQuota'],
            preferWhatsapp: json['preferWhatsapp'],
            preferEmail: json['preferEmail'],
            preferSms: json['preferSms'],
            preferCall: json['preferCall'],
            representativeDocumentType: json['representativeDocumentType'],
            documentRepresentative: json['documentRepresentative'],
            chargeContact: json['chargeContact'],
            phoneContact: json['phoneContact'],
            emailContact: json['emailContact'],
            name1Contact: json['name1Contact'],
            name2Contact: json['name2Contact'],
            lastName1Contact: json['lastName1Contact'],
            lastName2Contact: json['lastName2Contact'],
            referenceType1: json['referenceType1'],
            name1Reference1: json['name1Reference1'],
            name2Reference1: json['name2Reference1'],
            lastName1Reference1: json['lastName1Reference1'],
            lastName2Reference1: json['lastName2Reference1'],
            businessNameReference1: json['businessNameReference1'],
            codeDaneReference1: json['codeDaneReference1'],
            addressReference1: json['addressReference1'],
            phoneReference1: json['phoneReference1'],
            emailReference1: json['emailReference1'],
            referenceType2: json['referenceType2'],
            name1Reference2: json['name1Reference2'],
            name2Reference2: json['name2Reference2'],
            lastName1Reference2: json['lastName1Reference2'],
            lastName2Reference2: json['lastName2Reference2'],
            businessNameReference2: json['businessNameReference2'],
            codeDaneReference2: json['codeDaneReference2'],
            addressReference2: json['addressReference2'],
            phoneReference2: json['phoneReference2'],
            emailReference2: json['emailReference2'],
            queryCode: json['queryCode'],
            user: json['user'],
            updateDate: json['updateDate'],
            ip: json['ip'],
            state: json['state'],
            registrationDate: json['registrationDate'],

    };
    return quotaForm;
    }

    public static fromResponseToArray(resp: QuotaFormResponse): Array<QuotaForm> {
        let quotaFormList: Array<QuotaForm> = [];
        resp.response.forEach(ele => {
            let quotaForm: QuotaForm = {

            ctid: ele.ctid,
            settlement: ele.settlement,
            documentTypeAffiliate: ele.documentTypeAffiliate,
            affiliateDocument: ele.affiliateDocument,
            idCountry: ele.idCountry,
            name1: ele.name1,
            name2: ele.name2,
            lastName1: ele.lastName1,
            lastName2: ele.lastName2,
            businessName: ele.businessName,
            documentTypeBuyer: ele.documentTypeBuyer,
            buyerDocument: ele.buyerDocument,
            expiditionDate: ele.expiditionDate,
            codeDaneExpedition: ele.codeDaneExpedition,
            codeDane: ele.codeDane,
            address: ele.address,
            cellphone: ele.cellphone,
            phone: ele.phone,
            email: ele.email,
            birthDate: ele.birthDate,
            economicActivity: ele.economicActivity,
            startActivity: ele.startActivity,
            companyType: ele.companyType,
            pointOfSale: ele.pointOfSale,
            requestedQuota: ele.requestedQuota,
            preferWhatsapp: ele.preferWhatsapp,
            preferEmail: ele.preferEmail,
            preferSms: ele.preferSms,
            preferCall: ele.preferCall,
            representativeDocumentType: ele.representativeDocumentType,
            documentRepresentative: ele.documentRepresentative,
            chargeContact: ele.chargeContact,
            phoneContact: ele.phoneContact,
            emailContact: ele.emailContact,
            name1Contact: ele.name1Contact,
            name2Contact: ele.name2Contact,
            lastName1Contact: ele.lastName1Contact,
            lastName2Contact: ele.lastName2Contact,
            referenceType1: ele.referenceType1,
            name1Reference1: ele.name1Reference1,
            name2Reference1: ele.name2Reference1,
            lastName1Reference1: ele.lastName1Reference1,
            lastName2Reference1: ele.lastName2Reference1,
            businessNameReference1: ele.businessNameReference1,
            codeDaneReference1: ele.codeDaneReference1,
            addressReference1: ele.addressReference1,
            phoneReference1: ele.phoneReference1,
            emailReference1: ele.emailReference1,
            referenceType2: ele.referenceType2,
            name1Reference2: ele.name1Reference2,
            name2Reference2: ele.name2Reference2,
            lastName1Reference2: ele.lastName1Reference2,
            lastName2Reference2: ele.lastName2Reference2,
            businessNameReference2: ele.businessNameReference2,
            codeDaneReference2: ele.codeDaneReference2,
            addressReference2: ele.addressReference2,
            phoneReference2: ele.phoneReference2,
            emailReference2: ele.emailReference2,
            queryCode: ele.queryCode,
            user: ele.user,
            updateDate: ele.updateDate,
            ip: ele.ip,
            state: ele.state,
            registrationDate: ele.registrationDate,
            };
            quotaFormList.push(quotaForm);
        });
        return quotaFormList;
    }
}