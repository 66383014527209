import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { HelperService } from '../../../services/helper.service';
import { BackupsStoreService } from '../services/backups-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BackupsService } from '../../../services/backups.service';
import { TabsService } from '../../../services/tabs.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public showInfo = false;


  public data = [
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
  ];

  public headers = [
    "Ver información",
    "concepto_comite_decisorio",
    "consneg",
    "antecedentes_oferta_nego",
    "portafolio",
    "tipo_negociacion",
    "estado_decision",
    "usuario",
    "instancia_decision",
    "fecha_presentacion_caso_comite",
    "tipodocumento",
    "documento",
    "visualizacion_concepto_jur",
  ];

  public data2 = [
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
    { text: '--', value: '1' },
  ];

  public headers2 = [
    "valor_negociacion",
    "tipo_negociacion",
    "marca_judicializado",
    "rango_cuantia",
    "fecha_presentacion_caso_comite",
    "rango_edad_morar",
    "estado_acuerdo",
    "producto",
    "cantidad_obligaciones",
    "decision_comite",
    "numcuotas",
    "consneg",
    "tipo_gestion",
    "marca_de_garantía",
    "usuario_presento_nego",
    "portafolio",
    "pagos_asociados",
    "upb",
  ];

  public navItemTabs: any[] = [];
  public searchAffiliate: any = {};


  public cantPages = 3;

  constructor(public modal: ModalService, public helper: HelperService, public backupsStore: BackupsStoreService, private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute, private router: Router, private backups: BackupsService, private tabs: TabsService) {
    this.navItemTabs = [
      {
        href: '#payments-table3',
        description: 'Obligaciones por negocio',
        icon: 'icon-tab-reserves.svg',
        key: 'payments-table3'
      },
      {
        href: '#settlement-table',
        description: 'Abonos negociación',
        icon: 'icon-tab-individual.svg',
        key: 'settlement-table'
      },
      {
        href: '#title-value',
        // description: 'Títulos valor',
        description: 'Garantias negociación',
        icon: 'icon-tab-massive.svg',
        key: 'title-value'
      },
      {
        href: '#refunds-table',
        description: 'Obligaciones',
        icon: 'icon-tab-reserves.svg',
        key: 'refunds-table'
      },
      {
        href: '#payments-table',
        description: 'Plan pagos negociación',
        icon: 'icon-tab-reserves.svg',
        key: 'payments-table'
      },
      {
        href: '#payments-table2',
        description: 'Tercero oferente',
        icon: 'icon-tab-reserves.svg',
        key: 'payments-table2'
      },
     
    ];
  }

  ngOnInit() {
  }

  setTab(){}

  show() {
   this.showInfo = true;
  }

  handleSearch() {

  }

  cleanLetters() {}


}
