import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  DoCheck,
  AfterViewChecked,
} from "@angular/core";
import { ModalService } from "../services/modal.service";
import { UsersService } from "../services/users.service";
import { PermissionModel } from "@models/permission.model";
import { UserModel } from "../models/user.model";
import { CONSTANTS } from "@config/constants";
import { SubjectService } from "@app/services/subject.service";
import { Observable } from "rxjs";
import { SelectsService } from "@app/services/selects.service";
import { RolesService } from "@app/services/roles.service";
import { environment } from "../../environments/environment";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit, AfterViewChecked {
  public navigation = <any>[
    {
      idOption: null,
      isNeedPermissions: true,
      alt: "Aviso de no pago",
      img: "icon-agreements.svg",
      path: "/pages/respaldos/marcar-avis-de-no-pago",
      name: "non-payment-notices",
    },
    {
      idOption: null,
      isNeedPermissions: true,
      alt: "Usuarios Admin Covifactura",
      img: "icon-agreements.svg",
      path: "/pages/admin-usuarios-covifactura",
      name: "admin-covifactura-users",
    },
    {
      idOption: "8",
      isNeedPermissions: true,
      alt: "Respaldos",
      img: "icon-my-payments.svg",
      path: "/pages/respaldos",
      name: "backing",
      subItems: [
        {
          idOption: "11",
          alt: "Reporte",
          img: "icon-reports.svg",
          path: "/pages/respaldos/reporte",
        },
        {
          idOption: null,
          alt: "Transacciones",
          img: "icon-transactions.svg",
          path: "/pages/respaldos/transacciones-respaldos",
        },
        {
          idOption: null,
          alt: "Auditorias",
          img: "icon-transactions.svg",
          path: "/pages/respaldos/auditorias-respaldos",
        },
        {
          idOption: null,
          alt: "Cambio masivo de estados",
          img: "icon-transactions.svg",
          path: "/pages/respaldos/cambio-masivo-estados",
        },
        {
          idOption: null,
          alt: "Deducibles por afiliados",
          img: "icon-transactions.svg",
          path: "/pages/respaldos/deducibles",
        },
        {
          idOption: null,
          alt: "Facturas",
          img: "icon-transactions.svg",
          path: "/pages/respaldos/facturas",
        },
      ],
    },
    {
      idOption: null,
      isNeedPermissions: true,
      alt: "Productos",
      img: "icon-agreements.svg",
      path: "/pages/productos",
      name: "product",
    },
    {
      idOption: "9",
      isNeedPermissions: true,
      alt: "Cupos",
      img: "icon-claims.svg",
      path: "/pages/cupos",
      name: "quota",
      subItems: [
        {
          idOption: "14",
          alt: "Formulario 20 millones",
          img: "icon-claims.svg",
          path: "/pages/cupos/formulario-20-m",
        },
        {
          idOption: "15",
          alt: "Historial cupos",
          img: "icon-claims.svg",
          path: "/pages/cupos/historial-cupos",
        },
        {
          idOption: "16",
          alt: "Recursos cupos",
          img: "icon-claims.svg",
          path: "/pages/cupos/recursos-cupos",
        },
      ],
    },
    {
      idOption: null,
      isNeedPermissions: true,
      alt: "Administrador de compradores",
      img: "icon-administration.svg",
      path: "/pages/transacciones-cupos",
      name: "transactions",
      subItems: [
        {
          idOption: "14",
          alt: "Avisos de no pago",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/non-payment-notice",
        },
        {
          idOption: "15",
          alt: "Generación de informes",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/transaction-reports",
        },
        {
          idOption: "16",
          alt: "Modificacion de titulos ",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/title-modify",
        },
        {
          idOption: "17",
          alt: "Reportes tres y seis pagos",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/tres-y-seis-pagos",
        },
        {
          idOption: "18",
          alt: "Ingreso de transacciones",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/ingreso-transacciones",
        },
        {
          idOption: "19",
          alt: "Pagares",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/pagares",
        },
        {
          idOption: "20",
          alt: "bloqueos",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/bloqueos",
        },
        {
          idOption: "20",
          alt: "Localizacion",
          img: "icon-claims.svg",
          path: "/pages/transacciones-cupos/localizacion-compradores",
        },
      ],
    },
    {
      idOption: null,
      isNeedPermissions: true,
      alt: "Link Afiliado",
      img: "icon-administration.svg",
      path: "/pages/afiliados/link-afiliado",
      name: "affiliate",
    },
    {
      idOption: null,
      alt: "Afiliado",
      img: "icon-my-payments.svg",
      path: "/pages/contrato",
      name: "contract",
      subItems: [
        {
          idOption: null,
          alt: "Contrato",
          img: "icon-reports.svg",
          path: "/pages/contrato",
        },
        {
          idOption: null,
          alt: "Facturación Global",
          img: "icon-reports.svg",
          path: "/pages/facturacion-global",
        },
        {
          idOption: null,
          alt: "Administrador Bolsas",
          img: "icon-reports.svg",
          path: "/pages/administrador-bolsa",
        },
        {
          idOption: null,
          alt: "Facturas Netsuite",
          img: "icon-transactions.svg",
          path: "/pages/facturas-netsuite",
        },
       /*  {
          idOption: null,
          alt: "Facturación Estudios de Crédito",
          img: "icon-transactions.svg",
          path: "/pages/credit-study",
        }, */
      ],
    },
    {
      idOption: null,
      alt: "Cargue masivo de consultas",
      img: "icon-transactions.svg",
      path: "/pages/cargue-masivo-consultas",
      name: "bulk-upload.queary",
    },
    {
      idOption: null,
      alt: "Experian",
      img: "icon-transactions.svg",
      path: "/pages/experian",
      name: "experian",
      subItems: [
        {
          idOption: null,
          alt: "Reconocer",
          img: "icon-reports.svg",
          path: "/pages/experian/reconocer",
        },
        {
          idOption: null,
          alt: "Historia de Crédito - Persona jurídica",
          img: "icon-reports.svg",
          path: "/pages/experian/personas-juridicas",
        },
        {
          idOption: null,
          alt: "Historia de Crédito - Persona natural",
          img: "icon-reports.svg",
          path: "/pages/experian/historia-de-credito",
        },
      ],
    },
    /* Transunion */
    {
      idOption: "10",
      isNeedPermissions: false,
      alt: "Solicitudes",
      img: "icon-transactions.svg",
      path: "/pages/gestion-solicitud",
      name: "request-management",
      subItems: [
        {
          idOption: "15",
          alt: "Admin",
          img: "icon-claims.svg",
          path: "/pages/gestion-solicitud/admin-gestion",
        },
        {
          idOption: "16",
          alt: "Radicaciones",
          img: "icon-administration.svg",
          path: "/pages/gestion-solicitud/radicaciones",
        },
      ],
    },
    /* {
      idOption: null,
      isNeedPermissions: false,
      alt: "Cargues para procesos de riesgo",
      img: "icon-transactions.svg",
      path: "/pages/cargues-proceso-de-riesgo",
      name: "risks-processes-charges",
      subItems: [
        {
          idOption: "18",
          alt: "Políticas por afiliado",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/affiliate-policies",
        },
        {
          idOption: "20",
          alt: "Riesgo del afiliado",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/affiliate-risks",
        },
        {
          idOption: "21",
          alt: "Ajuste de cupo",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/quota-adjustment",
        },
        {
          idOption: "22",
          alt: "Cambio de estado",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/risks-state-change",
        },
        {
          idOption: "24",
          alt: "Habeas data",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/risks-habeas-data",
        },
        {
          idOption: "25",
          alt: "Evaluación masiva de compradores",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/buyers-massive-evaluation",
        },
        {
          idOption: "26",
          alt: "Listas restrictivas",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/restrictive-lists",
        },
        {
          idOption: "27",
          alt: "Cupos comprador",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/risks-buyer-quotas",
        },
        {
          idOption: "28",
          alt: "Calificación de compradores",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/buyers-qualification",
        },
        {
          idOption: "29",
          alt: "Cubrimiento",
          img: "icon-claims.svg",
          path: "/pages/cargues-proceso-de-riesgo/risks-covering",
        },
      ],
    }, */
    {
      idOption: null,
      isNeedPermissions: false,
      alt: "Caja ya",
      img: "icon-transactions.svg",
      path: "/pages/netFactoring/invoices-status",
      name: "net-factoring",
      subItems: [
        {
          idOption: "32",
          alt: "Finaciera",
          img: "icon-administration.svg",
          path: "/pages/netFactoring/financial-invoices",
        },
      ],
    },
    {
      idOption: null,
      isNeedPermissions: false,
      alt: "Fau Digital",
      img: "icon-administration.svg",
      path: "/pages/respaldos/fau-digital",
      name: "fau-digital",
    },
    {
      idOption: null,
      isNeedPermissions: false,
      alt: "Documentos relacionados",
      img: "icon-transactions.svg",
      path: "/pages/related-documents",
      name: "related-documents",
    },
    {
      idOption: null,
      isNeedPermissions: false,
      alt: "Solicitud Información Comprador",
      img: "icon-transactions.svg",
      path: "/pages/informacion-comprador",
      name: "buyer-report",
    },
    {
      idOption: null,
      isNeedPermissions: false,
      alt: "Reporte Express ",
      img: "icon-documents.svg",
      path: "/pages/reporte-express",
      name: "express-report",
      subItems: [
        {
          idOption: "41",
          alt: "Excesos riesgo",
          img: "icon-transactions.svg",
          path: "/pages/excesos-riesgo",
        },
      ],
    },
    {
      idOption: "31",
      isNeedPermissions: false,
      alt: "Reporte Afiliado",
      img: "icon-reports.svg",
      path: "/pages/reporte-afiliado",
      name: "affiliate-report",
    },
    {
      idOption: "32",
      isNeedPermissions: false,
      alt: "Reporte Comprador",
      img: "icon-reports.svg",
      path: "/pages/reporte-comprador",
      name: "history-buyer-report",
    },
    {
      idOption: "32",
      isNeedPermissions: false,
      alt: "Navitrans",
      img: "icon-reports.svg",
      path: "/pages/carga-masiva",
      name: "navitrans",
    },
    /* {
      idOption: "33",
      isNeedPermissions: true,
      alt: "Informa",
      img: "icon-reports.svg",
      path: "/pages/informa",
      name: "informa-report",
    }, */
  ];

  public isProductionEnv: boolean = false;
  public collapseMenu: Observable<boolean> = this.subject.stateMenu$;
  public showSubMenu: boolean = false;
  public showwSubMenu: Array<boolean> = [];
  public permissions: Array<PermissionModel>;
  public identity: UserModel;
  public constants = CONSTANTS;
  public innerWidth: number;
  public onLaptopBreakpoint: boolean = true;
  public onMobileBreakpoint: boolean = false;
  public showMenu: boolean = false;
  public rolesList: any;
  public hideBackingsSubItems: boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.updatedResize();
  }

  constructor(
    private modal: ModalService,
    private usersService: UsersService,
    private selects: SelectsService,
    public subject: SubjectService,
    private cdRef: ChangeDetectorRef,
    public roles: RolesService,
    private ciCd: CiCdConfigService
  ) {
    this.ciCdInit();
    this.getProfile();
  }

  ngOnInit() {
    this.updatedResize();
    this.handlePermissions();
    this.roles.getRoles().subscribe((resp: any) => {
      this.rolesList = resp.data;
      this.rolesList.push(
        {
          id: 24,
          users: "andrea.alvarez,diana.rayo,brayan.ramos,kevin.devia,matilde.negrete,juseph.diaz,diego.vergara,brayan.urrea,mariaj.rodriguez",
          description: "bulk-upload.queary",
          path: "cargue-masivo-consultas",
          state: true
        },
      );
      console.log(this.rolesList);
    });
    this.navigation.forEach((item, key) => {
      this.showwSubMenu[key] = false;
    });
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlMicrofrontUsersCovifactura, production} = apis;
      this.isProductionEnv = this.handleValidate(production);
      //this.isProductionEnv = true;
       console.log('config ci cd login', apiUrl, urlMicrofrontUsersCovifactura);
    });
  }

  handleValidate(production: any): boolean {
    //return true
    if (production && typeof production === 'string') {
      return production.toUpperCase() === 'TRUE' ? true : false;
    }
    if (production && typeof production === 'boolean') {
      return production;
    }

    return false;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  isAuthorized(page: string) {
    if (this.rolesList) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (this.isProductionEnv === false) {
        return true;
      }

      if (
        page === "quota" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }

      if (
        page === "backing" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }

      if (
        page === "non-payment-notices" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "affiliate" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "contract" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "product" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "transactions" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "request-management" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "net-factoring" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "risks-processes-charges" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "gestion-solicitud" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "fau-digital" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "related-documents" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "buyer-report" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "express-report" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "history-buyer-report" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "affiliate-report" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "admin-covifactura-users" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "experian" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }
      if (
        page === "bulk-upload.queary" &&
        this.isProductionEnv === true &&
        this.roles.isAuthorized(user.user, page, this.rolesList)
      ) {
        return true;
      }

      return false;
    }
  }

  updatedResize() {
    this.innerWidth = window.innerWidth;
    this.onLaptopBreakpoint =
      this.innerWidth > 200 && this.innerWidth < 1600 ? true : false;
    this.onMobileBreakpoint =
      this.innerWidth > 0 && this.innerWidth < 768 ? true : false;
    this.subject.menuCollapse(this.onLaptopBreakpoint);
    this.subject.isMobile(this.onMobileBreakpoint);
  }

  handlePermissions() {}

  handleDropdown(item?: string, subItems?: any, index?: number) {
    const findSubItems =
      this.navigation.filter((navItem: any) => navItem["subItems"]) || [];
    const menuWithSubItems =
      findSubItems.map((subItem: any) => subItem["alt"]) || [];
    const flatSubItems =
      findSubItems.flatMap((subItem: any) => subItem["subItems"]) || [];
    const arrSubItems =
      flatSubItems.map((flatItem: any) => flatItem["alt"]) || [];

    if (menuWithSubItems.includes(item) && this.showwSubMenu[index] === false) {
      this.showwSubMenu[index] = true;
    } else if (menuWithSubItems.includes(item) && this.showMenu === true) {
      this.showwSubMenu[index] = false;
    } else if (arrSubItems.includes(item)) {
      this.showwSubMenu[index] = true;
    } else {
      this.showwSubMenu[index] = false;
    }

    if (
      (this.onMobileBreakpoint && !this.showwSubMenu[index]) ||
      (this.onMobileBreakpoint && subItems)
    ) {
      this.collapseSidebar();
    }
  }

  enter(path: string) {
    this.collapseMenu.subscribe((show: boolean) => (this.showMenu = show));
    if (this.onLaptopBreakpoint && this.showMenu) {
    }
  }

  leave() {
    if (this.onLaptopBreakpoint && this.showMenu) {
    }
  }

  showModalLogOut() {
    this.modal.show("logOut");
  }

  collapseSidebar() {
    this.showMenu = !this.showMenu;
    this.subject.menuCollapse(this.showMenu);
  }


  //

  getProfile() {
    const userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (userName) {
      let message = '';
      message += ` ${userName.user}`;
      this.roles.findByUsername(userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'backing'
          );

          if (permissions && permissions.actions) {
            this.handleBackingsTab(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleBackingsTab(permissions: any) {
    if (permissions.actions['back-name-field'] === true ||  permissions.actions['view-document'] === true) {
      this.hideBackingsSubItems = true;
    }
  }
}
