import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectModel } from '@app/models/select.model';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { TabsService } from '@app/services/tabs.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TitlesResponse } from './models/response/titles-response';

@Component({
  selector: 'app-table-modify-titles',
  templateUrl: './table-modify-titles.component.html',
  styleUrls: ['./table-modify-titles.component.scss']
})
export class TableModifyTitlesComponent implements OnInit, OnChanges {

  public navItemTabs: any[] = [];

  actionType : string = '';
  totalPages : number = 1;
  pageRequest : number = 1;

  public titleValueList: Array<any> = [];
  public titleNumberList: Array<any> = [];
  public titleNewDueDateList: Array<any> = [];
  public exonerationValueList: Array<any> = [];
  public totalExonerationValueList: Array<any> = [];
  public actionTypeList: Array<string> = [];
  public extensionList: Array<string> = [];
  public prorrogateDateList: Array<string> = [];
  public approbeTitleList: Array<any> = [];
  public selectedValueTitle: string;
  public selectedNumberTitle: string;
  public selectedExonerationValueTitle: string;
  public selectedNewDueDateTitle: string;

  public actionTypeSelected: string = '';

  public actionTypes = [
    'Exoneración Parcial', 
    'Exoneración Total', 
    'Prorrogar', 
    'Cambiar Valor Titulo',
    'Cambiar Número Titulo',
    'Cambiar Fecha Vencimiento', 
    'Anular Titulo', 
    'Aprobar Titulo'
  ];

  public titleSelected: any;

  public indexTitleSelected: any;

  @Input() titles : any;

  @Input() personalInformation: any;

  @Output() changeCurrentPage: EventEmitter<any>;

  mediaTypes : SelectModel[] = [
    {
      value: 'C',
      description: 'CARTA',
      state: 'SHOW'
    },
    {
        value: 'M',
        description: 'FAX',
        state: 'SHOW'
    },
    {
        value: 'W',
        description: 'PLATAFORMA WEB',
        state: 'SHOW'
    }
  ];
  
  public exonerationValue : number;

  public prorrogateDate : Date;

  public mediaType: string;

  public reason: string;

  public indexSelected: number;

  constructor(private tabs: TabsService, public helper: HelperService, public tableHeaders: TransactionsHeadersService, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService) { 
    this.navItemTabs = this.tabs.adminQuotaTabs;
    this.changeCurrentPage = new EventEmitter();
  }

  ngOnInit() { }

  logTitleValue() {
    console.log(this.titleValueList)
  }

  selectActionType(actionType) {
    this.actionTypeSelected = actionType;
  }

  loDate() {
    console.log(this.titleNewDueDateList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initLists();
    if(this.titles) {
      this.totalPages = this.titles.totalPages;
    }
  }

  initLists() {
    this.resetLists();
    if(this.titles && this.titles.length > 0) {
      console.log(this.titles.data);
      this.titles.forEach((item) => {
        this.titleValueList.push(item.value);
      });
      
      this.titles.forEach((item) => {
        this.titleNumberList.push(item.tittleNo);
      });
      
      this.titles.forEach((item) => {
        this.titleNewDueDateList.push(new Date());
      });
      
      this.titles.forEach((item) => {
        this.exonerationValueList.push(item.value);
      });

      this.titles.forEach((item) => {
        this.totalExonerationValueList.push(item.value)
      });

      this.titles.forEach((item) => {
        this.approbeTitleList.push(item.value)
      });
      
      this.titles.forEach((item) => {
        this.actionTypeList.push('');
      });

      this.titles.forEach((item) => {
        this.extensionList.push('');
      });

      this.titles.forEach((item) => {
        this.prorrogateDateList.push('');
      });

      this.indexTitleSelected = undefined;;
    }
    
  }

  resetLists() {
    this.titleValueList = [];
    this.titleNumberList = [];
    this.titleNewDueDateList = [];
    this.exonerationValueList = [];
    this.actionTypeList = [];
    this.extensionList = [];
    this.prorrogateDateList = [];
  }

  public get validActionTypes() {

    let isValidAction: boolean = false;

    switch(this.actionTypeSelected) {
      case 'Exoneración Parcial':
        isValidAction = (this.exonerationValueList[this.indexTitleSelected] === null || this.exonerationValueList[this.indexTitleSelected] === '') || this.extensionList[this.indexTitleSelected] === '';
        console.log((this.exonerationValueList[this.indexTitleSelected] === null || this.exonerationValueList[this.indexTitleSelected] === ''));
        console.log(this.extensionList[this.indexTitleSelected] === '');
        return isValidAction;
      case 'Exoneración Total':
        isValidAction = (this.totalExonerationValueList[this.indexTitleSelected] === null || this.totalExonerationValueList[this.indexTitleSelected] === '') || this.extensionList[this.indexTitleSelected] === '';
        return isValidAction;
      case 'Prorrogar':
        isValidAction = this.extensionList[this.indexTitleSelected] === '' || !this.reason || this.prorrogateDateList[this.indexTitleSelected] === '0';
        return isValidAction;
      case 'Cambiar Valor Titulo':
        isValidAction = this.titleValueList[this.indexTitleSelected] === null || this.titleValueList[this.indexTitleSelected] === '';
        return isValidAction;
      case 'Cambiar Número Titulo':
        isValidAction = this.titleNumberList[this.indexTitleSelected] === null || this.titleNumberList[this.indexTitleSelected] === '';
        return isValidAction;
      case 'Cambiar Fecha Vencimiento':
        isValidAction = this.titleNewDueDateList[this.indexTitleSelected] === null || this.titleNewDueDateList[this.indexTitleSelected] === '';
        return isValidAction;
      case 'Anular Titulo':
        console.log("Ok");
        return isValidAction;
        
      case 'Aprobar Titulo':
        isValidAction = this.approbeTitleList[this.indexTitleSelected] === null || this.approbeTitleList[this.indexTitleSelected] === '';
        return isValidAction;
    }
  }

  selectTitleValue(titleValue) {
    this.selectedValueTitle = titleValue;
  }

  selectNumberValue(titleValue) {
    this.selectedNumberTitle = titleValue;
  }

  selectNewDueDate(titleValue) {
    console.log(titleValue);
    
    this.selectedNewDueDateTitle = titleValue;
  }

  selectExonerationValue(titleValue) {
    this.selectedExonerationValueTitle = titleValue;
  }

  getTitleSelected(title, index) {
    console.log(title);
    this.indexTitleSelected = index;
    this.titleSelected = title;
    this.actionTypeSelected = this.actionTypeList[index];
  }
  
  goToPage($event) {
    this.changeCurrentPage.emit($event.currentPage);
    const body = {
      documentType: this.personalInformation.documentType,
      document: this.personalInformation.document,
      authorization: this.titleSelected.authorization,
      option: this.personalInformation.transactionType,
      currentPage: $event.currentPage,
      perPage: '10'
    }

    this.quotaGreater.findBuyerTitles(body).subscribe((response: any) => {
      this.spinner.hide();
      this.titles = response;
      this.totalPages = response.totalPages
      this.pageRequest = response.currentPage
      this.initLists();
      this.actionTypeSelected = '';
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  executeAction() {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;
    
    if(userUpdate) {
      if(this.actionTypeSelected === 'Exoneración Parcial') {
        console.log(this.selectedExonerationValueTitle);
        const body = {
          date: this.titleSelected.date,
          exonerationValue: this.exonerationValueList[this.indexTitleSelected],
          guarantedValue: this.titleSelected.guaranteedValue,
          balance: this.titleSelected.balance,
          media: this.extensionList[this.indexTitleSelected],
          authorization: this.titleSelected.authorization,
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          titleNumber: this.titleSelected.tittleNo,
          queryCode: this.titleSelected.queryCode,
          requestingUser: userUpdate
        }
        this.quotaGreater.exonerate(body).subscribe((response: any) => {
          this.spinner.hide();
          this.goToPage({currentPage: this.pageRequest.toString()});
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Exoneración Total') {

        const body = {
          date: this.titleSelected.date,
          exonerationValue: this.totalExonerationValueList[this.indexTitleSelected],
          guarantedValue: this.titleSelected.guaranteedValue,
          balance: this.titleSelected.balance,
          media: this.extensionList[this.indexTitleSelected],
          authorization: this.titleSelected.authorization,
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          titleNumber: this.titleSelected.tittleNo,
          queryCode: this.titleSelected.queryCode,
          requestingUser: userUpdate
        }

        this.quotaGreater.exonerate(body).subscribe((response: any) => {
          console.log(response);
          this.spinner.hide();
          this.goToPage({currentPage: this.pageRequest.toString()});
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Prorrogar') {
        const body = {
          date: moment(this.titleSelected.date).format("YYYY-MM-DD HH:mm:ss"),
          extensionDate: moment(this.prorrogateDateList[this.indexTitleSelected]).format("YYYY-MM-DD HH:mm:ss"),
          dueDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          whoRequest: "AFILIADO",
          user: userUpdate,
          reason: this.reason,
          media: this.extensionList[this.indexTitleSelected],
          state: this.titleSelected.titleState,
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          queryCode: this.titleSelected.queryCode,
          authorization: this.titleSelected.authorization,
          checksNumber: this.titleSelected.checksNumber,
          titleValue: this.titleSelected.value,
          tittleNo: this.titleSelected.tittleNo,
          posDated: null,
        }
        console.log(body);
        this.quotaGreater.extendExoneration(body).subscribe((response: any) => {
          console.log(response);
          this.reason = '';
          this.goToPage({currentPage: this.pageRequest.toString()});
          this.spinner.hide();
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Cambiar Valor Titulo') {
        const body = {
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          checksNumber: this.titleSelected.checksNumber,
          date: this.titleSelected.date,
          queryCode: this.titleSelected.queryCode,
          value: this.titleValueList[this.indexTitleSelected],
          authorization: this.titleSelected.authorization,
          tittleNo: this.titleSelected.tittleNo,
          newDueDate: null
        };

        this.quotaGreater.updateTitleValue(body).subscribe((response: any) => {
          this.spinner.hide();
          this.goToPage({currentPage: this.pageRequest.toString()});
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })

      } else if(this.actionTypeSelected === 'Cambiar Número Titulo') {
        const body = {
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          checksNumber: this.titleSelected.checksNumber,
          date: this.titleSelected.date,
          queryCode: this.titleSelected.queryCode,
          tittleNo: this.titleNumberList[this.indexTitleSelected],
          value: this.titleSelected.value,
          authorization: this.titleSelected.authorization,
          newDueDate: null
        };
        this.quotaGreater.updateTitleNo(body).subscribe((response: any) => {
          console.log(response);
          this.goToPage({currentPage: this.pageRequest.toString()});
          this.spinner.hide();
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Cambiar Fecha Vencimiento') {
        const body = {
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          checksNumber: this.titleSelected.checksNumber,
          date: this.titleSelected.date,
          queryCode: this.titleSelected.queryCode,
          newDueDate: moment(this.selectedNewDueDateTitle).format("YYYY-MM-DD HH:mm:ss")
        };
        this.quotaGreater.updateTitleDueDate(body).subscribe((response: any) => {
          console.log(response);
          this.goToPage({currentPage: this.pageRequest.toString()});
          this.spinner.hide();
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Anular Titulo') { 
        const body = {
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          checksNumber: this.titleSelected.checksNumber,
          date: this.titleSelected.date,
          queryCode: this.titleSelected.queryCode
        };

        this.quotaGreater.disableTitle(body).subscribe((response: any) => {
          this.goToPage({currentPage: this.pageRequest.toString()});
          this.spinner.hide();
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      } else if(this.actionTypeSelected === 'Aprobar Titulo') {
        console.log(this.approbeTitleList);
        console.log(this.approbeTitleList[this.indexSelected]);
        
        const body = {
          documentType: this.personalInformation.documentType,
          document: this.personalInformation.document,
          checksNumber: this.titleSelected.checksNumber,
          date: this.titleSelected.date,
          queryCode: this.titleSelected.queryCode,
          value: this.approbeTitleList[this.indexTitleSelected],
          authorization: this.titleSelected.authorization,
          tittleNo: this.titleSelected.tittleNo,
          newDueDate: null,
        };

        this.quotaGreater.approveTitle(body).subscribe((response: any) => {
          console.log(response);
          this.goToPage({currentPage: this.pageRequest.toString()});
          this.spinner.hide();
          Swal.fire({
            title: `${response.message}`,
            text: `${response.data[0]}`,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      }
    }
  }

}
