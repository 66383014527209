import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { MyPaymentsRequest, MyPaymentsResponse, MyPaymentExportRequest } from '@models/myPayments.model';
import { MyPaymentsService } from '@services/my-payments.service';
import { NetsuiteRequest } from '@models/netsuiteBill.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-tab-bill-history',
  templateUrl: './tab-bill-history.component.html',
  styleUrls: ['./tab-bill-history.component.scss']
})
export class TabBillHistoryComponent implements OnInit {

  public activeButtons = false;
  public selectedBill: string;
  public myPaymentsRequest: MyPaymentsRequest = new MyPaymentsRequest();
  public myPaymentExportRequest: MyPaymentExportRequest = new MyPaymentExportRequest();
  public netsuiteRequest: NetsuiteRequest = new NetsuiteRequest();
  @Input() payments: Array<MyPaymentsResponse>;

  constructor(private spinner: NgxSpinnerService,
    private myPaymentsService: MyPaymentsService,
    private toastr: ToastrService,
    public modal: ModalService) { }

  ngOnInit() {
    this.myPayments();
  }

  myPayments() {
    this.spinner.show();
    this.myPaymentsService.getMyPayments(this.myPaymentsRequest).subscribe(
      (resp: Array<MyPaymentsResponse>) => {
        this.payments = resp.filter(({ state }) => state === 'CANCELADA');
      }, (err: any) => {
        console.error(err);
      }, () => { this.spinner.hide() }
    )
  }

  exportMyPaymentDetails() {
    this.spinner.show();
    this.myPaymentExportRequest.bill = this.selectedBill;
    this.myPaymentsService.exportMyPayment(this.myPaymentExportRequest).subscribe(
      (paymentDetails: Blob) => {
        const blob = new Blob([paymentDetails], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `${this.selectedBill}.xlsx`);
      }, (err: any) => {
        console.error(err);
        this.toastr.error('No pudimos exportar el informe.', '', {
          timeOut: 3000,
        });
      }, () => { this.spinner.hide() }
    )
  }

  handleItemSelected(selectedBill?: string) {
    this.selectedBill = selectedBill;
    this.activeButtons = true;
  }

  netsuiteBill() {
    this.spinner.show();
    this.myPaymentsService.getNetsuiteBill(this.netsuiteRequest, this.selectedBill).subscribe(
      (base64: string) => {
        if (base64) {
          this.modal.show('pdfBill', {
            base64
          });
        }
      }, (err: any) => {
        console.error(err);
        this.toastr.error('Esta factura no está disponible.', '', {
          timeOut: 3000,
        });
      }, () => { this.spinner.hide() }
    )
  }

}
