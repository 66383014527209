import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getBankName'
})
export class GetBankNamePipe implements PipeTransform {

  transform(value: any): any {
    let bankName: string;
    
    let bankList: any = JSON.parse(localStorage.getItem('BANK_LIST'));
    if(bankList) {
      bankList.forEach(item => {   
        if(value.toString() === item.value.toString()) {
          bankName = item.description;
        }
      })
      return bankName;
    }
  }

}
