import { Injectable } from "@angular/core";
import { TermType } from "../models/termType.model";
import { DocumentTypes } from "../models/documentType.model";

@Injectable({
  providedIn: "root",
})
export class QuotaService {
  states = [
    { name: "SI", abbrev: "S", id: 1 },
    { name: "NO", abbrev: "N", id: 2 },
    { name: "NO FUNCIONA", abbrev: "NF", id: 3 },
  ];

  public statesExhibition = [
    { name: "AUMENTO", abbrev: "AUMENTO", id: 1 },
    { name: "DISMINUCION", abbrev: "DISMINUCION", id: 2 },
  ];

  convertDocumentTypeToCompleteName(value: String) {
    let typeDocument: Array<DocumentTypes> = JSON.parse(
      localStorage.getItem("form20m_documentTypes")
    );

    let documentType = typeDocument.find(
      (element) => element.description == value
    );

    return documentType ? documentType.type : " ";
  }

  convertDocumentType(value: String) {
    switch (value) {
      case "N":
        return "Nit";
      case "C":
        return "CC";
      case "E":
        return "CE";
      case "P":
        return "Pasaporte";
      default:
        return "";
    }
  }

  converQuotaState(state: string) {
    switch (state) {
      case "A":
        return "ACTIVO";
      case "S":
        return "SUSPENDIDO";
      case "R":
        return "RETIRADO";
      case "B":
        return "BLOQUEADO";

      default:
        return state;
    }
  }

  convertFind(value: string): string {
    let typeDocument: Array<DocumentTypes> = JSON.parse(
      localStorage.getItem("form20m_documentTypes")
    );

    let documentType = typeDocument.find((element) => element.type == value);

    return documentType ? documentType.description : " ";
  }

  public thousands(value: any) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  public getIdFromList(description: string, list: Array<TermType>) {
    let value = 0;
    list.forEach((element) => {
      if (element.description == description) {
        value = element.idTerm;
      }
    });
    return value;
  }

  stateToDB(state: string = null, id: number = 0): string {
    if (state && id == 0) {
      switch (state) {
        case "SI":
          return "S";

        case "NO":
          return "N";

        case "NO FUNCIONA":
          return "NF";
      }
    } else if (id > 0) {
      return id + "";
    }
    return "N/A";
  }

  stateToShow(state: string = null, id: number = 0): string {
    if (state && id == 0) {
      switch (state) {
        case "S":
          return "SI";

        case "N":
          return "NO";

        case "NF":
          return "NO FUNCIONA";
      }
    } else if (id > 0) {
      switch (id) {
        case 1:
          return "SI";

        case 2:
          return "NO";

        case 3:
          return "NO FUNCIONA";
      }
    }
    return "NO";
  }
}
