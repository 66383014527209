import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import {
  Response,
  ResponseObject,
} from "@app/models/responseUtil.model";
import { environment } from "src/environments/environment";
import { Affiliate } from "@app/pages/net-factoring/models/Affiliate.model";
import { FacturasNegociadas, NegotiatedInvoices } from "@app/pages/net-factoring/models/NegotiatedInvoices";
import { FilterAffiate } from "@app/pages/net-factoring/models/Request/FilterAffiliate";
import { PossibleInvoices } from "@app/pages/net-factoring/models/PossibleInvoices";
import { CiCdConfigService } from "@app/cicd/ci-cd-config.service";

@Injectable({
  providedIn: "root",
})
export class NetFactoringBackEndService {
  private urlApi: string;
  private boxNowToken: string;
  private headers = {
    headers: new HttpHeaders().set("Content-Type", "application/json"),
  };

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = `${environment.urlBoxNow}`;
    this.boxNowToken = `${environment.boxNowToken}`;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, boxNowToken, urlBoxNow } = apis
      this.urlApi = `${urlBoxNow}`;
      this.boxNowToken = `${boxNowToken}`;
       console.log('config ci cd', apiUrl);
    });
  }

  getAffiliates(currentPage: number): Observable<Response<Affiliate>> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.boxNowToken),
    });

    const options = {
      headers,
    };

    return this.http
      .get(
        `${this.urlApi}getNames?currentPage=${currentPage}&perPage=10`,
        options
      )
      .pipe(
        map((resp: Response<Affiliate>) => {
          return resp;
        })
      );
  }

  getInvoices(
    currentPage: number,
    report: string
  ): Observable<Response<NegotiatedInvoices | PossibleInvoices>> {
    return this.http
      .get(
        `${this.urlApi}historicReport?currentPage=${currentPage}&perPage=10&report=${report}`,
        this.headers
      )
      .pipe(
        map((resp: Response<NegotiatedInvoices | PossibleInvoices>) => {
          return resp;
        })
      );
  }

  getDocumentDownload(): Observable<Response<any>> {
    return this.http
      .get(`${this.urlApi}/historicReportExcel`, this.headers)
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  getFilerAffiliate(
    body: FilterAffiate
  ): Observable<Response<NegotiatedInvoices | PossibleInvoices>> {
    return this.http
      .post(`${this.urlApi}negotiatedByName`, body, this.headers)
      .pipe(
        map((resp: Response<NegotiatedInvoices>) => {
          return resp;
        })
      );
  }

  getInvoicesNegotiated(
    query
  ): Observable<Response<FacturasNegociadas>> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.boxNowToken),
    });

    const options = {
      headers,
      params: query,
    };

    return this.http
      .get(`${this.urlApi}facturas/negotiatedinvoices`, options)
      .pipe(
        map((resp: Response<FacturasNegociadas>) => {
          return resp;
        })
      );
  }

  getInvoicesNegotiatedFinancia(
    query
  ): Observable<Response<FinalInvoicesModel>> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.boxNowToken),
    });

    const options = {
      headers,
      params: query,
    };

    return this.http.get(`${this.urlApi}facturas/informe`, options).pipe(
      map((resp: Response<FinalInvoicesModel>) => {
        return resp;
      })
    );
  }

  getInvoicesNegotiatedFinanciaDocument(
    query
  ): Observable<Response<any>> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.boxNowToken),
    });

    const options = {
      headers,
      params: query,
    };

    return this.http
      .get(`${this.urlApi}facturas/descargar-informe`, options)
      .pipe(
        map((resp: Response<any>) => {
          return resp;
        })
      );
  }

  updateInvoices(id, body): Observable<ResponseObject<FacturasNegociadas>> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(this.boxNowToken),
    });

    const options = {
      headers,
    };

    return this.http
      .put(`${this.urlApi}facturas/estados?id=${id}`, body, options)
      .pipe(
        map((resp: ResponseObject<FacturasNegociadas>) => {
          return resp;
        })
      );
  }

  postSaveFiles(body: any, idFile) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.boxNowToken)
    );

    const options = { headers};
    return this.http.post(`${this.urlApi}facturas/notificardocumento/${idFile}`,body, options)
  }

  correctDocuments(body: any) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.boxNowToken)
    );

    const options = { headers};
    return this.http.post(`${this.urlApi}facturas/corregirdocumentos`,body, options)
  }
}
