import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SelectModel } from "@app/models/select.model";
import { DocsFilingQuota } from "@app/pages/quota-radication/models/docsFilingQuota.model";
import { QuotaRadication } from "@app/pages/quota-radication/models/quotaRadication.model";
import { RadicationService } from "@app/pages/quota-radication/services/radication-quota.service";
import { HelperService } from "@app/services/helper.service";

@Component({
  selector: "app-check-request",
  templateUrl: "./check-request.component.html",
  styleUrls: ["./check-request.component.scss"],
})
export class CheckRequestComponent implements OnInit {
  @Input() quotaSettlement: QuotaRadication = null;
  @Output() setQuotaSettlement = new EventEmitter<QuotaRadication>();
  public requestStatesAll: SelectModel[] = [];

  transTime: string = "";

  timeResponse: string = "";

  constructor(
    public helper: HelperService,
    private router: Router,
    public radicationService: RadicationService
  ) {
    this.requestStatesAll = radicationService.requestStatesAll;
  }

  ngOnInit() {
    this.quotaSettlement.managementDate = new Date();
    this.getTimeExecute();
  }

  getTimeExecute() {
    if (
      this.quotaSettlement.requestType != 3 &&
      this.quotaSettlement.requestType != 4
    ) {
      this.transTime = this.helper.calculateWorkingHours(
        new Date(this.quotaSettlement.requestDate),
        this.quotaSettlement.managementDate
      );
    } else {
      this.transTime = this.helper.calculateWorkingHours(
        new Date(this.quotaSettlement.requestDate),
        this.quotaSettlement.managementDate,
        true
      );
    }
    this.timeResponse = this.radicationService.timeToResponse(
      this.quotaSettlement.requestType
    );
  }

  downloadFile(document: DocsFilingQuota) {
    let type: string[] = document.documentName.split(".");
    let typeDocument = `.${type[1]}`;
    this.helper.downloadFromBase64(
      document.document,
      document.documentName,
      typeDocument
    );
  }

  goToQuota(settlementNumber: any) {
    localStorage.setItem("settlementNumber", settlementNumber);
    this.router.navigateByUrl("/pages/cupos");
  }
}
