import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AffiliateLinkService } from "src/app/services/affiliate-link.service";
import { HelperService } from "src/app/services/helper.service";
import { SelectsService } from "src/app/services/selects.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-quotas-link",
  templateUrl: "./quotas-link.component.html",
  styleUrls: ["./quotas-link.component.scss"],
})
export class QuotasLinkComponent implements OnInit {
  public dateRange: Array<any> = [];

  public imageData: Array<any> = [];

  constructor(
    private affiliate: AffiliateLinkService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public helper: HelperService,
    public select: SelectsService
  ) {}

  ngOnInit() {}
  public getDownloadReport() {
    this.spinner.show();
      this.affiliate.getDownloadReport().subscribe(
      (response: any) => {
        this.spinner.hide();
        this.imageData = response.data;
        if (this.imageData) {
          this.helper.downloadFromBase64(
            this.imageData[0],
            "Informe_Link_Cupos",
            ".xlsx"
          );
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: "success",
          });
        }
      },
      (error) => {
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: "info",
        });
        this.spinner.hide();
        this.toastr.error(error.error.message);
      })
  }
}
