import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionModel } from '../models/permission.model';
import { LoginModel, RecoverPasswordModel } from '@models/login.model';
import { UserModel } from '@models/user.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // properties
  private urlApi: string;
  private devNodeApiUrl: string;
  public loginToken: string;
  public headers: HttpHeaders;
  public identity: UserModel;
  public userToken: any;
  public permissions: Array<PermissionModel>;
  public ip: string;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlGlobalBilling;
    // this.loginToken = environment.loginToken;
    // this.loginToken = '';
    // this.devNodeApiUrl = environment.devNodeApiUrl;
    this.initialize();
    this.ciCdInit()
  }

  public initialize() {
    this.readToken();
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, loginToken, devNodeApiUrl} = apis
      this.loginToken = loginToken;
      this.urlApi = apiUrl;
      this.devNodeApiUrl = devNodeApiUrl;
       console.log('config ci cd', loginToken);
    });
  }

  login(user: LoginModel) {
    this.isAutenticated();
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.loginToken),
    });
    console.log(this.devNodeApiUrl)
    const options = { headers};
    const userLogin = {
        username: user.username,
        password: user.password,
    };

    return this.http.post(`${this.devNodeApiUrl}/sessionManagement/user/login`, userLogin, options).pipe(
      map((resp: any) => {
        // Expires
        const momentNow = moment();
        const expiraToken = new Date(resp.data.user.expirationTime);
        localStorage.setItem('expiresCF', expiraToken.getTime().toString());
        localStorage.setItem('identity', JSON.stringify(resp.data.user));
        localStorage.setItem('loginTime', momentNow.toString());
        localStorage.setItem(
          'user',
          JSON.stringify({
            user: userLogin.username,
            token: resp.data.user['token'],
          })
        );

        //this.quotaRadication.getUserProfile(resp.data.user.document);
        this.saveToken(resp.data.user['token']);
        return resp;
      })
    );
  }

  recoverPassword(username: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const recoverPassword: RecoverPasswordModel = {
      username,
    };
    const options = {
      params: RecoverPasswordModel.toResponse(recoverPassword),
      headers,
    };

    return this.http.get(`${this.urlApi}/envio_olvide_contrasena`, options);
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem('tokenASR', token);
  }

  readToken() {
    if (localStorage.getItem('tokenASR')) {
      this.userToken = localStorage.getItem('tokenASR');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  logout() {
    localStorage.removeItem('tokenASR');
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  sessionLimit(): boolean {
    const loginTime = localStorage.getItem('loginTime');
    const momentNow = moment();
    const loginTimeMoment = moment(loginTime);
    const diff = momentNow.diff(loginTimeMoment, 'hours');
    return diff <= 12;
  }

  isAutenticated(): boolean {
    if (
      localStorage.getItem('tokenASR') === undefined ||
      localStorage.getItem('tokenASR') === null
      // || expira < today
    ) {
      return false;
    } else {
      return this.userToken;
    }
  }

  // Examples:
  post(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  put(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  getUserInfo() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user;
  }
}
