import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RulesCreatedService {
public registerModifyReceived: any ={}
currentPage = 1;
  constructor() { }
  sendDataEvent = new EventEmitter<any>();
 
sendRegister(register: any) {
  this.sendDataEvent.emit(register);
}

sendModifyRegister(register: any) {
  this.registerModifyReceived = register;
}

getModifyRegister(){
  return this.registerModifyReceived;
}
}


