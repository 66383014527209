
import { Component, Input, OnChanges } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '../../../services/helper.service';
import { BackingsToProcessRequestModel } from '../models/request/BackingsToProcess.model';
import { BackingsToProcessResponse } from '../models/response/BackingsToProcess.model';

@Component({
  selector: 'app-download-backings-to-process',
  templateUrl: './download-backings-to-process.component.html',
  styleUrls: ['./download-backings-to-process.component.scss']
})
export class DownloadBackingsToProcessComponent implements OnChanges {

  @Input() type: string;
  @Input() title: string = 'Descargar excel';
  @Input() fileName: string = 'gestion-respaldos';
  @Input() fileFormat: string = '.xlsx';
  @Input() data: any;
  @Input() contract: any;
  @Input() disabled: boolean = true;
  @Input() search: BackingsToProcessRequestModel = new BackingsToProcessRequestModel();

  public backingsToProcessRequest: BackingsToProcessRequestModel;

  public counterRequest: number = 0;


  constructor(private spinner: NgxSpinnerService, private backups: BackupsService,
    public helper: HelperService) { }

  ngOnChanges() {    
    this.backingsToProcessRequest = Object.assign({}, this.search);
  }

  downloadExcel(): void {
    this.spinner.show();
    this.search.download = true;
    let backingToProccessRequest = Object.assign({}, this.search);
    backingToProccessRequest.download = true;
    this.backups.getBackingsToProcess(backingToProccessRequest).subscribe(
      (download: BackingsToProcessResponse) => {
        if (download && download.ok === false) {
          this.helper.handleErrorHttp({}, this.title);
          return;
        }

        console.log('download backing to process ->', download);

        const excelFileBase64 = download['file'];
        this.helper.downloadFromBase64(excelFileBase64, this.fileName, this.fileFormat);
        this.spinner.hide();

      }, (error: any) => this.helper.handleErrorHttp(error, this.title)
    );
  }
}
