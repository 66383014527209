export class RefundsModel {
    affiliateDocumentType:    string = '0';
    affiliateDocument:        string = '0';
    issuerDocumentType: string;
    issuerDocument:           number = 0;
    authorization: number = 0;
    contract: number = 0;
    sinisterNumber: number = 0;
    draftNumber: number = 0;
    initialDate: string;
    finalDate: string;
    currentPage: number = 0;
    perPage: number = 0;
    download: boolean = false;
}

export class RefundsModel2 {
    affiliateDocumentType: string = '0';
    affiliateDocument:     string = '0';
    initialDate:                 string;
    finalDate:                   string;
    search:                string = '0';
    currentPage:             number = 1;
    perPage:                number = 10;
    contract?:               number = 0; 
}