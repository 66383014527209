import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-billing-report',
  templateUrl: './global-billing-report.component.html',
  styleUrls: ['./global-billing-report.component.scss'],
})
export class GlobalBillingReportComponent implements OnInit {
  selectedReport: string = '1';
  constructor() {}
  ngOnInit() {}
  onSelectChange(event: Event): void {
    this.selectedReport = (event.target as HTMLSelectElement).value;
  }
}
