import * as moment from 'moment';

export class RequestCredit {
  bodyRequest: BodyRequestCredit = {
    radication: null,
    promissoryNote: "",
    radicationType: "",
    registrationDate: null,
    arrivalTime: "",
    affiliate: "",
    establishment: "",
    amount: 0,
    formDocument: "",
    identityDocument: "",
    incomeCertificate: "",
    otherDocument: "",
    propertyCard: "",
    soatDocument: "",
    thirdPartyAuthorization: "",
    financialStatement: "",
    bankStatements: "",
    rutDocument: "",
    commerceDocument: "",
    incomeTaxDeclaration: "",
    coDebtorDocumentType: "",
    coDebtorDocument: 0,
    studentDocumentType: "",
    studentDocument: 0,
    code: 0,
    career: "",
    semester: "",
    status: "",
    responseMedium: "",
    terminationDate: moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
    terminationTime: "",
    observations: "",
    billing: "",
    promissoryNoteLetter: "",
    updateDate: moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
    updateUser: "",
    creditAuthorizations: 0,
    receptionTime: "",
    receptionDate: null,
    radicationTime: "",
    radicationDate: null,
    deliveryTimeToCustomer: "",
    deliveryDateToCustomer: null,
    deliveryDateToAdviserSac: null,
    deliveryTimeToAdviserSac: "",
    customerSendingTime: "",
    customerSendingDate: null,
    contractNumber: 0,
    denialReason: "",
    denialReason2: "",
    rectifiable: "",
    approvedAmount: 0,
    affiliateName: "",
    estimatedResponseTime: "",
    revalidation: "",
    increase: "",
    studentName: "",
    radicationUser: "",
    analystName: "",
    status2: "",
    revalidationTime: "",
    revalidationDate: null,
    responseTime: "",
    responseDate: null,
    approvedAmount2: 0,
  }
  bodyNatural: RequestCreditNatural = {
    document: "",
    documentType: "",
    document2: 0,
    documentType2: " ",
    socialReason: "",
    lastname1: "",
    lastname2: "",
    name1: "null",
    name2: "null",
    coDebtorDocumentType: "",
    coDebtorDocument: null,
  };
}

class BodyRequestCredit {
  radication: number;
  promissoryNote: string;
  radicationType: string;
  registrationDate: string;
  arrivalTime: string;
  affiliate: string;
  establishment: string;
  amount: number;

  formDocument: string;
  identityDocument: string;
  incomeCertificate: string;
  otherDocument: string;
  propertyCard: string;
  soatDocument: string;
  thirdPartyAuthorization: string;
  financialStatement: string;
  bankStatements: string;
  rutDocument: string;
  commerceDocument: string;
  incomeTaxDeclaration: string;
  coDebtorDocumentType: string;
  coDebtorDocument: number;
  studentDocumentType: string;
  studentDocument: number;
  code: number;
  career: string;
  semester: string;
  status: string;
  responseMedium: string;
  terminationDate: string;
  terminationTime: string;
  observations: string;
  billing: string;
  promissoryNoteLetter: string;
  updateDate: string;
  updateUser: string;
  creditAuthorizations: number;
  receptionTime: string;
  receptionDate: string;
  radicationTime: string;
  radicationDate: string;
  deliveryTimeToCustomer: string;
  deliveryDateToCustomer: string;
  deliveryDateToAdviserSac: string;
  deliveryTimeToAdviserSac: string;
  customerSendingTime: string;
  customerSendingDate: string;
  contractNumber: number;
  denialReason: string;
  denialReason2: string;
  rectifiable: string;
  approvedAmount: number;
  affiliateName: string;
  estimatedResponseTime: string;
  revalidation: string;
  increase: string;
  studentName: string;
  radicationUser: string;
  analystName: string;
  status2: string;
  revalidationTime: string;
  revalidationDate: string;
  responseTime: string;
  responseDate: string;
  approvedAmount2: number;
}

class RequestCreditNatural {
  document: string | null;
  documentType: string | null;
  document2?: number | null;
  documentType2?: string | null;
  socialReason?: string | null;
  lastname1?: string | null;
  lastname2?: string | null;
  name1?: string | null;
  name2?: string | null;
  coDebtorDocumentType?: string | null;
  coDebtorDocument?: number | null;
}
