import { SubjectService } from '@app/services/subject.service';
import Swal from 'sweetalert2';
import { ModalService } from '@app/services/modal.service';
import { HelperService } from '@services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectsDataForm } from '@app/models/selectModel';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { AffiliateLinkService } from '@app/services/affiliate-link.service';
import { RelatedDocumentsService } from '../services/related-documents.service';
import { RelatedDocumentsHeadersService } from '../services/related-documents-headers.service';

@Component({
  selector: 'app-modal-create-related-document',
  templateUrl: './modal-create-related-document.component.html',
  styleUrls: ['./modal-create-related-document.component.scss']
})
export class ModalCreateRelatedDocumentComponent implements OnInit, OnChanges {

  @ViewChild('createRelatedDocumentForm') createRelatedDocumentForm: NgForm;

  @Input() documentInfo: any = {};

  @Output() handleResetPhoneList: EventEmitter<any> = new EventEmitter();

  @Input() isResetAddressForm: boolean = false;

  public phoneStates: Array<any> = [];

  public phoneTypes: Array<any> = [];

  public phoneRelations: Array<any> = [];

  public docTypes: Array<any> = [];

  public generalLists: SelectsDataForm;

  public department = "";

  public city: string;

  public departments: any;

  public listCities: any;

  public cities: any;

  public isValidAddress: boolean = false;

  public documentPattern: string;

  public form = {
    documentType: "",
    document: "",
    relatedDocumentType: "",
    relatedDocument: "",
    name: "",
    relation: "",
  }

  constructor(private quotaGreater: RelatedDocumentsService, private toastr: ToastrService, public helper: HelperService, private modal: ModalService, private listCovinoc: ListsCovinocService, private affiliateLink: AffiliateLinkService, private spinner: NgxSpinnerService, private subject: SubjectService, public relatedDocumentHeaders: RelatedDocumentsHeadersService) { }

  public get setDocumentPattern() {
    if(this.form.relatedDocumentType === 'C') {
      return '^(\\d{8}|\\d{10})$';
    } else if(this.form.relatedDocumentType === 'E') {
      return '^\\d{5,7}$';
    } else if (this.form.relatedDocumentType === 'N') {
      return '^\\d{10}$';
    } else {
      return ''
    }
  }

  closeModal() {
    this.createRelatedDocumentForm.resetForm();
    this.subject.changeHandleIsResetAddress(false);

    this.department = "";
    this.modal.close();
  }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
      console.log("DOCTYPES", this.docTypes);

    });
    this.generalList();
    this.getDepartments();
  }

  ngOnChanges() {
    this.isResetAddressForm = true;
    this.form.documentType = this.documentInfo.documentType;
    this.form.document = this.documentInfo.document;
  }

  handleValidAddress($event) {
    this.isValidAddress = $event;
  }


  generalList() {
    this.affiliateLink.getGeneralList().subscribe((response: SelectsDataForm) => {
      this.generalLists = response;
      console.log("Departments: ", response);

    },
      (err) => {
        this.listCities = [];
      });
  }

  getDepartments() {
    this.affiliateLink.getMunicipalities().subscribe((departments: any) => {
      console.log(departments);

      this.departments = departments;
    },
      (err) => {
        this.listCities = [];
      });
  }

  handleSubmit() {
    this.spinner.show();
      this.quotaGreater.createRelatedDocument(this.form).subscribe((response: any) => {
        this.createRelatedDocumentForm.resetForm();
        this.isResetAddressForm = true;
        this.handleResetPhoneList.emit();
        this.spinner.hide();
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
        this.modal.close();
      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });
  }
}
