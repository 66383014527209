import { Component, Input, OnInit } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { PaymentManagementRequestModel } from '../models/request/PaymentManagement.model';
import Swal from 'sweetalert2';
import { HelperService } from '../../../services/helper.service';

import * as moment from 'moment';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  @Input() type: string;
  @Input() data: any;
  @Input() contract: any;
  @Input() disabled: true;

  public counterRequest: number = 0;
  @Input() search: PaymentManagementRequestModel = new PaymentManagementRequestModel();


  constructor(private spinner: NgxSpinnerService, private backups: BackupsService,
    private toastr: ToastrService, public helper: HelperService) { }

  ngOnInit() {
  }

  downloadPaymentManagementExcel(): void {
    this.spinner.show();
    this.search.download = true;
    this.backups.getPaymentToProcess(this.search).subscribe(
      (paymentManagementDownload: any) => {
        if (paymentManagementDownload && paymentManagementDownload.ok === false) {
          this.handleDownloadPaymentManagementError();
          return;
        }

        const excelFileBase64 = paymentManagementDownload['file'];

        if (excelFileBase64) {
          this.helper.downloadFromBase64(excelFileBase64, 'gestion-pagos', '.xlsx');
        }

        this.spinner.hide();
        // console.log('paymentManagementDwonload ->', paymentManagementDownload);
      }, (error: any) => {
        this.handleDownloadPaymentManagementError(error);
      }
    );
  }

  handleDownloadPaymentManagementError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Descargar excel', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.spinner.hide();
  }

}
