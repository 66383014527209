import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

import { CodeSpecialParams } from '@app/models/codeSpecialParams.model';
import { ContractService } from '@app/services/contract.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Response } from '@app/models/responseUtil.model';
import { FormControl } from '@angular/forms';

import { SpecialFactorAsrTemp } from '@app/models/specialFactorAsrTemp.model';
import { SpecialFactorAsrTempPK } from '@app/models/compositeKey/SpecialFactorAsrTempPK.model';
import { AffiliateInfFiscalAsrTempPK } from '@app/models/compositeKey/AffiliateInfFiscalAsrTempPK.model';
import { FilterCodeSpecialContract } from '@app/models/request/filterCodeSpecialContract.model';
import { FormContractService } from '../services/form-contract.service';

@Component({
  selector: 'app-another-yes-component',
  templateUrl: './another-yes-component.component.html',
  styleUrls: ['./another-yes-component.component.scss'],
})
export class AnotherYesComponentComponent implements OnInit {
  public anotherYesForm: FormGroup;
  public specialConditionsForm: FormGroup;

  @Input() stepperNext: any;
  @Input() access: boolean = false;

  //Object's code special
  public listCodeSpecial: CodeSpecialParams[] = [];

  //List's code special saved
  public listSpecialFactorAsrTemp: SpecialFactorAsrTemp[] = [];

  //List's number special current
  public listCodeSpecialCurrents: number[] = [];

  numberContract;
  affiliateInfFiscalAsrTempPK: AffiliateInfFiscalAsrTempPK;

  success = false;

  siAplica = false;

  user: string;

  document: 0;
  typeDocument: 'N';

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    public formService: FormContractService
  ) {
    this.anotherYesForm = this.fb.group({});

    this.specialConditionsForm = this.fb.group({
      valorNatural: [{ value: null, disabled: true }],
      valorJuridica: [{ value: null, disabled: true }],
      aplica: [{ value: false, disabled: true }, Validators.required],
    });

    this.specialConditionsForm.get('valorNatural').disable();
    this.specialConditionsForm.get('valorJuridica').disable();

    this.specialConditionsForm.get('aplica').valueChanges.subscribe((value) => {
      if (value) {
        this.specialConditionsForm.get('valorNatural').enable();
        this.specialConditionsForm.get('valorJuridica').enable();
      } else {
        this.specialConditionsForm.get('valorNatural').disable();
        this.specialConditionsForm.get('valorJuridica').disable();
      }
    });
  }

  ngOnInit() {
    this.formService.init();
    let { user } = JSON.parse(localStorage.getItem('user'));

    this.user = user ? user : 'SYSTEM';

    this.numberContract = Number(localStorage.getItem('numberContract'));
    this.affiliateInfFiscalAsrTempPK = JSON.parse(
      localStorage.getItem('AffiliateData')
    );
    this.getCodeSpecial();
    if (this.formService.status) this.getPaymentsForAfiliate();
  }

  get f() {
    return this.anotherYesForm.controls;
  }

  //Get code special
  getCodeSpecial() {
    this.spinner.show();
    this.contractService.getCodeSpecialDescription('OTRO_SI').subscribe(
      (resp: Response<CodeSpecialParams[]>) => {
        if (resp.ok && resp.data) {
          this.listCodeSpecial = resp.data[0];
          if (this.numberContract) {
            // validated if the contract is saved
            this.getSpecialSaved();
          } else {
            // config data for default
            this.setForm();

            this.spinner.hide();
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  //Get factor especial
  getPaymentsForAfiliate() {
    this.contractService.getPaymentsForAfiliate().subscribe((resp) => {
      this.siAplica = true;
      this.specialConditionsForm.get('aplica').enable();
      this.specialConditionsForm
        .get('aplica')
        .setValue(resp.data[0].factorEspecial);
      this.specialConditionsForm
        .get('valorNatural')
        .setValue(resp.data[0].valorNatural);
      this.specialConditionsForm
        .get('valorJuridica')
        .setValue(resp.data[0].valorJuridica);
    });
  }

  getSpecialSaved() {
    this.contractService.getAnotherYes(this.getCurrentCodeSpecial()).subscribe(
      (resp) => {
        //if list have data
        this.listSpecialFactorAsrTemp = resp.data[0];
        //execute setData of the form
        this.setForm();
        //View true

        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.setForm();
      }
    );
  }

  //saved
  stepTwoSubmit() {
    if (this.numberContract) {
      this.spinner.show();
      this.contractService.saveAnotherYes(this.mapperService()).subscribe(
        () => {
          if (this.siAplica) {
            const data = {
              tipoDocumento: this.formService.typeDocument,
              documento: this.formService.document,
              valorNatural:
                this.specialConditionsForm.get('valorNatural').value,
              valorJuridica:
                this.specialConditionsForm.get('valorJuridica').value,
              factorEspecial: this.specialConditionsForm.get('aplica').value,
            };
            this.contractService
              .postPaymentsForAfiliate(data, this.user)
              .subscribe((resp) => {
                Swal.fire({
                  type: 'success',
                  title: 'Transacción exitosa',
                }).then(() => {
                  this.stepperNext.next();
                });
                this.spinner.hide();
              });
          } else {
            Swal.fire({
              type: 'success',
              title: 'Transacción exitosa',
            }).then(() => {
              this.stepperNext.next();
            });
            this.spinner.hide();
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }
  }

  //get data for filter
  getCurrentCodeSpecial(): FilterCodeSpecialContract {
    this.listCodeSpecial.map((element) => {
      this.listCodeSpecialCurrents.push(element.codeSpecial);
    });
    let filterCodeSpecialContract: FilterCodeSpecialContract = {
      codeSpecial: this.listCodeSpecialCurrents,
      numberContract: this.numberContract,
    };
    return filterCodeSpecialContract;
  }

  //config form
  setForm() {
    this.listCodeSpecial.map((resp) => {
      let checked = this.findCodeSpecial(resp.codeSpecial);
      console.log(checked);
      this.anotherYesForm.addControl(
        `${resp.codeSpecial}`,
        new FormControl(checked)
      );
    });
    this.formDisable();
    this.anotherYesForm.disable();
    this.formDisableStep();
    this.success = true;
  }

  formDisable() {
    this.listCodeSpecial.map((resp) => {
      if (this.access) {
        this.f[resp.codeSpecial].enable();
      } else {
        this.f[resp.codeSpecial].disable();
      }
    });
  }

  formDisableStep() {
    if (this.formService.currentPages >= 7) {
      this.anotherYesForm.enable();
    }
  }

  findCodeSpecial(code: number): number {
    let valid = this.listSpecialFactorAsrTemp.find(
      (resp) => resp.specialFactorAsrTempPK.specialCode === code
    );
    if (valid) {
      return valid.factor;
    }

    return 0;
  }

  //get data for send the DB
  mapperService(): SpecialFactorAsrTemp[] {
    let valueForm = this.anotherYesForm.value;
    let specialFactor: SpecialFactorAsrTemp[] = [];
    for (const key in valueForm) {
      let specialFactorTemp: SpecialFactorAsrTemp = new SpecialFactorAsrTemp();
      let specialFactorAsrTempPK: SpecialFactorAsrTempPK =
        new SpecialFactorAsrTempPK();
      specialFactorAsrTempPK.idContract = this.numberContract;
      specialFactorAsrTempPK.specialCode = Number(key);
      specialFactorTemp.specialFactorAsrTempPK = specialFactorAsrTempPK;
      specialFactorTemp.factor = Number(this.f[key].value);
      specialFactor.push(specialFactorTemp);
    }
    return specialFactor;
  }
}
