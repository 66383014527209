import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safePercent'
})
export class SafePercentPipe implements PipeTransform {

  private percentPipe = new PercentPipe('es-CO');

  transform(value: any, digitsInfo: string = '1.0-0'): any {

    if (isNaN(value) || !isFinite(value)) return '-';

    if (!isNaN(Number(value))) {
      const numericalValue: number = Number(value);
      const roundedValue = Math.round(numericalValue);  // Se redondea al entero más cercano

      // Dividir entre 100 para utilizar PercentPipe y mostrar como porcentaje 
      return this.percentPipe.transform(roundedValue / 100, digitsInfo);
    }

    return value;
  }

}
