import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public permissionsToken: string;
  public apiUrl: string;
  public quotaRolUsers = [
    'sandra.prada', 'john.montanez', 'giovanny.vargas', 'estibel.mancera', 'solangie.agudelo', 'wendy.gomez',
    'paola.estupinan', 'maria.vasquez', 'zugey.jauregui', 'cindy.sarmiento', 'ingrid.mora', 'katherine.acosta',
    'rosaura.godoy', 'arabelly.cortes', 'carlosd.cardenas', 'ana.ardila', 'diana.suniaga', 'yeizi.quintero',
    'jose.garcia', 'daniela.cuervo', 'angie.silva', 'tatiana.rodriguez', 'diana.sora', 'yonely.arias',
    'angely.gomez', 'ingrid.bernal', 'brayana.garzon', 'juan.escobar', 'yesica.calderon', 'julieth.jimenez',
    'edgar.manchego','claudia.grau', 'liliana.perez', 'rafael.ramirezs'
  ];

  public backingRolUsers = [
    'yerram', 'nicoso', 'esperanz'
  ];

  public affiliateLinkRolUsers = [
    'diana.rayo', 'jennipher.acosta', 'carolina.gallego'
  ];

  public adminRoles = [
    { id: 1, name: 'quota', pages: this.quotaRolUsers, authorized: false, users: [] },
    { id: 2, name: 'backing', pages: this.backingRolUsers, authorized: false, users: [] },
    { id: 3, name: 'affiliate', pages: this.affiliateLinkRolUsers, authorized: false, users: [] },
  ];

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
      this.permissionsToken = environment.permissionsToken;
      this.apiUrl = environment.apiUrl;
      this.ciCdInit()
    }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {urlAccessPermissions, permissionsToken, devNodeApiUrl } = apis; // devNodeApiUrl
      this.permissionsToken = permissionsToken;
      /* this.apiUrl = devNodeApiUrl; */ //this.apiUrl = urlAccessPermissions;
      this.apiUrl = urlAccessPermissions;
       console.log('config ci cd', urlAccessPermissions);
    });
  }

  public findByUsername(httpParams: string) {

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.permissionsToken),
    });

    let params = new HttpParams().set('username', httpParams);

    const options = {
      headers,
      params
    }

    /* return this.http.get(`${this.apiUrl}/asr-permissions/permissions/findByUsername`, options) */ //return this.http.get(`${this.apiUrl}/permissions/findByUsername`, options)
    return this.http.get(`${this.apiUrl}/permissions/findByUsername`, options)
  }

  public isAuthorized(user: string, page: string, roles?: any): boolean {
    if (user && page) {
      roles.forEach((rol, indexRoles) => {
        const authorized = rol.users.split(',').includes(user);
        const index = roles.findIndex(role => role.description.toLowerCase() === rol.description.toLowerCase());

        roles[index].state = authorized;
      });
      if(roles.find(rol => rol.description.toLowerCase() === page)) {
        return roles.find(rol => rol.description.toLowerCase() === page).state || false
      }
    } else {
      return false;
    }
  }

  public getRoles() {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.permissionsToken),
    });

    const options = { headers};
    /* return this.http.get(`${this.apiUrl}/asr-permissions/permissions`, options) */ //return this.http.get(`${this.apiUrl}/permissions`, options)
    return this.http.get(`${this.apiUrl}/permissions`, options)
  }
}
