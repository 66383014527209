import { Pipe, PipeTransform } from '@angular/core';
import { QuotaGreaterService } from '@app/services/quota-greater.service';

@Pipe({
  name: 'stateName'
})
export class StateNamePipe implements PipeTransform {

  states = [
    {name: 'SI', abbrev: 'S', id: 1},
    {name: 'NO', abbrev: 'N', id: 2},
    {name: 'NO FUNCIONA', abbrev: 'NF', id: 3}
  ];

  transform(value: any, isAbbrev?: boolean): any {
    let stateName: string;
    if(isAbbrev) {
      this.states.forEach(item => {
        if(value === item.abbrev) {
          stateName = item.name;
        }
      });
    } else {
      this.states.forEach(item => {
        if(value === item.id) {
          stateName = item.name;
        }
      });
    }
    return stateName;
  }
}
