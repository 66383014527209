import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { SelectsService } from '@app/services/selects.service';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackupsService } from '@app/services/backups.service';
import { takeUntil } from 'rxjs/operators';
import { SelectModel } from '@app/models/select.model';
import { HelperService } from '@app/services/helper.service';
import Swal from 'sweetalert2';
import { ReportResponse, ReportResponseModel } from '../models/response/Report.model';
import { ReportRequest } from '../models/request/Report.model';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';
import { RolesService } from '@app/services/roles.service';
@Component({
  selector: 'app-backups-report-table',
  templateUrl: './backups-report-table.component.html',
  styleUrls: ['./backups-report-table.component.scss']
})
export class BackupsReportTableComponent implements OnInit, OnDestroy {

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('bodyTable') bodyTable: ElementRef;

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public search: ReportRequest = new ReportRequest();

  public reportResponse: ReportResponseModel[] = [];
  public selectOptionsBranchOffices: SelectModel[] = [];

  public subs1: Subscription;
  public selectOptions: SelectModel[] = [];
  public branchOffices$ = this.selects.getSelectTypes('branchOffices');

  public initDateInput: Date;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  public totalAccessActionBackingsSac = true;
  public isLoadingPermissions=true;
  public message = '';
  public userName: any;


  constructor(public selects: SelectsService, private spinner: NgxSpinnerService,
    public backupsService: BackupsService, public helper: HelperService, public tableHeaders: BackupsTableHeadersService, private roles: RolesService) {

      this.getSelects();
  }

  ngOnInit(): void {
    this.dateInitialize();
    this.getBranchOffices();
    this.getProfile();
    /* this.getReport(1); */
  }

  dateInitialize(): void {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').format("YYYY-MM-DD HH:mm:ss");
    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSelects() {
    this.selects.getSelectTypes('branchOffices')
      .subscribe((branchOffices) =>  this.selectOptionsBranchOffices = branchOffices || [new SelectModel()]);
  }

  getBranchOffices(): void {
    this.selects.getBranchOffices().pipe(takeUntil(this.unsubscribe$))
      .subscribe((branchOffices) => this.selectOptions = branchOffices);
  }

  goToPage({ currentPage }): void {
    this.currentPage = currentPage;
    this.getReport(currentPage);
  }

  onSelect(selectedOption: string): void {
    this.search.branchOffice = selectedOption;
    this.getReport(1);
  }

  onSearch(textSearch: string) {
    // this.search.search = textSearch;
    // this.getRefunds(this.currentPage);
  }

  onDateRange([startDate, endDate]): void {
    this.search.initialDate = moment(startDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss");
    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss");
    this.getReport(1);
  }

  getReport(currentPage: number): void {
    this.spinner.show();
    this.search.currentPage = currentPage;
    this.backupsService.getReport(this.search).subscribe(
      (reportResponse: ReportResponse) => {
        // (reportResponse: any) => {
        if (reportResponse && reportResponse.ok === false) {
          this.handleGetReportError();
          return;
        }
        this.reportResponse = reportResponse['data'];
        this.cantPages = reportResponse['totalPages'];
        console.log('reportResponse', reportResponse);
        this.spinner.hide();
      }, (error: any) => {
        this.handleGetReportError(error);
      }
    );
  }

  handleGetReportError(error?: any): void {
    console.error(error);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({ 'type': 'error', 'title': 'Reporte respaldos', 'text': `${message}` });
    } else {
      this.helper.handleUndefinedError();
    }
    this.reportResponse = [];
    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination(): void {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  getProfile() {
    this.userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'backing'
          );
          if (permissions && permissions.actions) {
            this.handleBackingsTab(permissions);
          }
          this.isLoadingPermissions=false;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleBackingsTab(permissions: any) {
    if (permissions.actions['view-document'] === true) {
      this.totalAccessActionBackingsSac = false;
    }
  }
}

