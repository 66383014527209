import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SubjectService } from '../../../services/subject.service';
import { ProductResponseModel } from '../models/response/product.model';
import { ProductsStoreService } from '../services/products-store.service';
import { SelectModel } from '../../../models/select.model';
import { SelectsService } from '@app/services/selects.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/redux/app.reducer';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {

  @Input() dataCards: ProductResponseModel[];
  @Output() edit: EventEmitter<ProductResponseModel> = new EventEmitter<ProductResponseModel>();
  @Output() delete: EventEmitter<ProductResponseModel> = new EventEmitter<ProductResponseModel>();
  @Output() enable: EventEmitter<ProductResponseModel> = new EventEmitter<ProductResponseModel>();
  @Output() disable: EventEmitter<ProductResponseModel> = new EventEmitter<ProductResponseModel>();

  public selectOptionsBondPeriods: SelectModel[] = [];
  public selectOptionsProductTypes: SelectModel[] = [];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public message = 'El usuario debe cambiar la contraseña.';

  constructor(public subject: SubjectService, public productsStore: ProductsStoreService,
     public selects: SelectsService, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select('selects').pipe(takeUntil(this.unsubscribe$)).subscribe(({selects}) => this.getSelects(selects));
  }

  ngOnDestroy(): void {
   this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editProduct(selectedProduct: ProductResponseModel): void {
    this.edit.emit(selectedProduct);
  }

  deleteProduct(selectedProduct: ProductResponseModel): void {
    this.delete.emit(selectedProduct);
  }

  enableProduct(selectedProduct: ProductResponseModel): void {
    this.enable.emit(selectedProduct);
  }

  disableProduct(selectedProduct: ProductResponseModel): void {
    this.disable.emit(selectedProduct);
  }

  enter(): void {
    this.subject.sendText(this.message);
  }

  leave(): void {
    this.subject.sendText('');
  }

  getSelects(selects: any) {
    this.selectOptionsProductTypes = selects.productTypes || [new SelectModel()];
    this.selectOptionsBondPeriods = selects.bondPeriods || [new SelectModel()];
  }

}
