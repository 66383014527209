import { Component, OnInit } from '@angular/core';
import { GeneralProfileRecord, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse, PerfilGeneralInfoAgregadaMicrocredito } from '../../models/credit-history.model';

@Component({
  selector: 'app-table-general-profile-history',
  templateUrl: './table-general-profile-history.component.html',
  styleUrls: ['./table-general-profile-history.component.scss']
})
export class TableGeneralProfileHistoryComponent implements OnInit {

  public headersTable: string[] = ['Sectores', 'Sector Financiero', 'Sector Cooperativo', 'Sector Real', 'Sector Telcos',
    'Total Sectores', 'Total como Principal', 'Total como Codeudor y Otros'
  ];

  public generalProfileRecords: GeneralProfileRecord[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.generalProfileRecords = this.subjectCreditHistory.getGeneralProfile(response);
  }

}
