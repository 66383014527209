import { TelephoneType } from "../telephoneType.model";

export class TelephoneTypeResponse {
    data: Array<TelephoneType> = [];
    error?: {};

    public static fromResponse(json: any): TelephoneTypeResponse {
        const telephoneTypeResponse: TelephoneTypeResponse = {
        data: json.data ? json.data : [],
        error: json.error ? json.error : null
    };
        return telephoneTypeResponse;
    }
}
