import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function hourValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    
    if (!value) {
      return null;
    }
    
    /* const hourMinutePattern = /^([01]\d|2[0-3]):([0-5]\d)$/; */
    /* const hourMinutePattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/; */
    const hourMinutePattern = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;
    const isValidFormat = hourMinutePattern.test(value);

    if (!isValidFormat) {
      return { invalidHourFormat: true };
    }

    return null;
  };
}

