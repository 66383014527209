import { Component, OnInit, Output, Input, EventEmitter, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HelperService } from '@services/helper.service';
import { ProductModel } from '../models/request/product.model';
import { ProductsStoreService } from '../services/products-store.service';
import { ProductsService } from '../../../services/products.service';
import { ProductResponse } from '../models/response/product.model';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import { SelectsService } from '@app/services/selects.service';
import { SelectModel } from '../../../models/select.model';
import { NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/redux/app.reducer';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('formProduct') formProduct: NgForm;

  public productRequest: ProductModel = new ProductModel();
  public counterRequest: number = 0;
  public title: string = 'Productos';

  public selectOptionsBondPeriods: SelectModel[] = [];
  public selectOptionsProductTypes: SelectModel[] = [];

  public allowBags: boolean = false;
  public enableUploadDocument: boolean = false;

  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: string;
  public edit: boolean = false;
  public stateProduct$: Observable<ProductModel> = this.productStore.stateProduct$;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public get existContractFile(): boolean {
    return this.productRequest.contractFile && this.helper.isBase64(this.productRequest.contractFile);
  }

  public get existContractFileText(): string {
    return (this.existContractFile === true)
      ? `Ya existe un documento cargado: `
      : 'No ha cargado ningún documento.';
  }

  public get existContractFileTextButton(): string {
    return (this.existContractFile === true) ? 'Cargar nuevo documento' : 'Cargar documento'
  }

  constructor(public helper: HelperService, private productStore: ProductsStoreService,
    private productsService: ProductsService, private spinner: NgxSpinnerService,
    public selects: SelectsService, private store: Store<AppState>, private modal: ModalService) { }

  ngOnInit() {
    this.stateProduct$.pipe(takeUntil(this.unsubscribe$)).subscribe((product: ProductModel) => this.handleProductState(product));
    this.store.select('selects').pipe(takeUntil(this.unsubscribe$)).subscribe(({ selects }) => this.getSelects(selects));
    this.helper.markForm(this.formProduct, true);
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleAllowBags(allowBags: boolean) {
    // console.log('handleAllowbagsr ->', allowBags);
    this.allowBags = allowBags;
    this.productStore.setAllowBags(this.allowBags);
  }

  handleShowModalUploadDocument() {
    this.enableUploadDocument = !this.enableUploadDocument;
    this.modal.show('fileUploadDocumentProduct');
  }

  handleDownloadDocument() {
    // console.log('download base64');
    this.spinner.show();
    const base64File = this.productRequest.contractFile;
    this.helper.downloadFromBase64(
      base64File,
      `${this.productRequest.contractFileName}${this.helper.getDateId}` || 'formato',
      this.productRequest.contractFileExtension || '.doc'
    );
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
  }

  backToProductsList() {
    this.back.emit();
  }

  handleProductState(product: ProductModel) {
    this.productRequest = this.type === 'edit' ? product : new ProductModel();
    // console.log('product state', this.productRequest.allowBags);
    this.handleAllowBags(this.productRequest.allowBags || false);
  }

  async handleBase64File(file: File) {
    // console.log('handleBase64File ->', file);
    const base64File = await this.helper.convertFileToBase64(file);
    this.productRequest.contractFile = this.helper.base64ReplacePath(file, base64File);
    this.productRequest.contractFileName = this.helper.removeFileExtension(file.name);
    this.productRequest.contractFileName += this.helper.getDateId();
    this.productRequest.contractFileExtension = this.helper.getFileExtension(file);
    this.formProduct.form.markAsDirty();
    this.formProduct.form.markAsTouched();
  }

  addProduct(): void {
    this.spinner.show();
    this.counterRequest++;
    this.productsService.createProduct(this.productRequest).subscribe(
      (productsResponse: ProductResponse) => {
        this.handleAddProductResponse(productsResponse);
        // console.log('setStateProduct ->', productsResponse);
      }, (error: any) => {
        this.counterRequest = this.helper.handleErrorHttp(error, this.title, this.paginationComponent);
      }
    );
  }

  handleAddProductResponse(productsResponse: ProductResponse) {
    if (productsResponse && productsResponse.ok === false) {
      this.counterRequest = this.helper.handleErrorHttp({}, this.title, this.paginationComponent);
      return;
    }

    Swal.fire({
      type: 'success',
      title: `Producto ${'Agregado'}.`,
      text: `${productsResponse['message']}`
    });

    this.backToProductsList();
    this.spinner.hide();
  }

  editProduct(): void {
    this.spinner.show();
    this.counterRequest++;
    this.productsService.updateProduct(this.productRequest).subscribe(
      (productsResponse: ProductResponse) => {
        this.handleEditProductResponse(productsResponse);
        console.log('setStateUpdateProduct ->', productsResponse);
      }, (error: any) => {
        this.counterRequest = this.helper.handleErrorHttp(error, this.title, this.paginationComponent);
      }
    );
  }

  handleEditProductResponse(productsResponse: ProductResponse) {
    if (productsResponse && productsResponse.ok === false) {
      this.counterRequest = this.helper.handleErrorHttp({}, this.title, this.paginationComponent);
      return;
    }

    Swal.fire({
      type: 'success',
      title: `Producto ${'Guardado'}.`,
      text: `${productsResponse['message']}`
    });

    this.helper.handlePristineForm(this.formProduct);
    this.spinner.hide();
  }

  enableEdit() {
    return this.edit = this.type === 'edit' ? true : false;
  }

  getSelects(selects?: any) {
    this.selectOptionsProductTypes = selects.productTypes || [new SelectModel()];
    this.selectOptionsBondPeriods = selects.bondPeriods || [new SelectModel()];
  }


}
