import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NonPaymentNoticeResponse } from '@app/pages/mark-non-payment/models/response/NonPaymentNoticeToProcess.model';
import { markNonPaymentNoticeToProcessRequest, NonPaymentNoticeToProcessRequestModel } from '@app/pages/mark-non-payment/models/request/NonPaymentNoticeToProcess.model';
import { NonPaymentNoticeToProcess } from '../pages/mark-non-payment/models/request/NonPaymentNoticeToProcess.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

export interface processTypeModel { mark: string, lift: string, cancel: string }

@Injectable({
  providedIn: 'root'
})
export class NonPaymentService {

  public urlApi: string = '';
  public apiUrlBackings: string = '';
  public backingsToken: string;
  public timeout: number = 0;
  public processType: processTypeModel = {
    mark: 'markNonPaymentNotice',
    lift: 'liftUpNonPaymentNotice',
    cancel: 'cancelNonPaymentNotice'
  };
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient,
     private helper: HelperService,
     private ciCd: CiCdConfigService) {
    this.urlApi = environment.apiUrl;
    this.timeout = environment.httpTimeout;
    this.backingsToken = environment.backingsToken;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, backingsToken, httpTimeout, apiUrlBackings} = apis
      this.urlApi = apiUrl;
      this.apiUrlBackings = apiUrlBackings;
      this.timeout = httpTimeout;
      this.backingsToken = backingsToken;
       console.log('config ci cd', apiUrl);
    });
  }


  getNonPaymentNoticeToProcess(getNonPaymentsRequest: NonPaymentNoticeToProcessRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers
    };
    return this.http.post<NonPaymentNoticeResponse>(
      `${this.apiUrlBackings}/nonPaymentNotice/nonPaymentNoticeToProcess`,
      getNonPaymentsRequest, options
    ).pipe(
      // timeout(this.timeout),
      map((nonPaymentsResponse: NonPaymentNoticeResponse) => nonPaymentsResponse)
    );
  }

  processNonPaymentNotice(selectedNonPaymentNoticeRequest: NonPaymentNoticeToProcess, type: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = { headers };

    const nonPaymentNoticeRequest: markNonPaymentNoticeToProcessRequest = new markNonPaymentNoticeToProcessRequest();

    nonPaymentNoticeRequest.user = this.helper.getUser();
    nonPaymentNoticeRequest.nonPaymentNoticeToProcess = [selectedNonPaymentNoticeRequest];
    const endpointProcess = this.getProcessType(type);

    return this.http.post<NonPaymentNoticeResponse>(
      `${this.apiUrlBackings}/nonPaymentNotice/${endpointProcess}`,
      nonPaymentNoticeRequest,
      options
    ).pipe(
      // timeout(this.timeout),
      map((backingsToProcess: NonPaymentNoticeResponse) => backingsToProcess)
    );
  }

  getProcessType(type: string) {
    return this.processType[type] ? this.processType[type] : '';
  }
}
