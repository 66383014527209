import { Component, Input, OnInit } from '@angular/core';
// import { CurrentExhibitionResponse, QuotaDataForm } from 'src/app/models/quote-form-model';
import { QuotaHeadingTablesService } from '../../services/quota-heading-tables.service';

@Component({
  selector: 'app-current-exhibitions',
  templateUrl: './current-exhibitions.component.html',
  styleUrls: ['./current-exhibitions.component.scss']
})
export class CurrentExhibitionsComponent implements OnInit {
  
  @Input() quotaData: any;
  
  constructor(public tableHeaders: QuotaHeadingTablesService) { }

  ngOnInit() {
  }

}
