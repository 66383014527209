import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { TabsService } from '@app/services/tabs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import Swal from 'sweetalert2';
import { TitlesToReverse } from './models/response/titles-to-reverse';

@Component({
  selector: 'app-table-reverse-exonerations',
  templateUrl: './table-reverse-exonerations.component.html',
  styleUrls: ['./table-reverse-exonerations.component.scss']
})
export class TableReverseExonerationsComponent implements OnInit, OnChanges {

  public navItemTabs: any[] = [];

  public titleSelected: any;

  totalPages : number = 1;
  pageRequest : number = 1;

  @Input() titlesToReverse: any;;

  @Input() personalInformation: any;

  constructor(private tabs: TabsService, public helper: HelperService, public tableHeaders: TransactionsHeadersService, private quotaGrater: QuotaGreaterService, private spinner: NgxSpinnerService) { 
    this.navItemTabs = this.tabs.adminQuotaTabs;
  }

  ngOnInit() {
  }

  selectItem(title) {
    this.titleSelected = title;
    console.log(this.titleSelected);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.titlesToReverse) {
      this.totalPages = this.titlesToReverse.totalPages;
    }
  }

  goToPage($event){
    const body = {
      documentType: this.personalInformation.documentType,
      document: this.personalInformation.document,
      authorization: this.personalInformation.authorization,
      currentPage: $event.currentPage,
      perPage: '10'
    }
    this.quotaGrater.findExonerationLists(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.titlesToReverse = response;
      this.totalPages = response.totalPages;
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      })
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  reverse() {
    this.spinner.show();
    console.log(this.personalInformation);
    
    const body = {
      documentType: this.personalInformation.documentType,
      document: this.personalInformation.document,
      authorization: this.personalInformation.authorization,
      titleDate: this.titleSelected.titleDate,
      titleNumber: this.titleSelected.titleNumber,
      titleValue: this.titleSelected.titleValue,
      exoneratedValue: this.titleSelected.exoneratedValue,
      exoneratedDate: this.titleSelected.exoneratedDate,
      registerNumber: this.titleSelected.registerNumber,
      checksNumber: this.titleSelected.checksNumber,
      user: this.titleSelected.whoRequest,
      type: this.titleSelected.type,
    }
    this.quotaGrater.reverseExoneration(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      })
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    });
  }
}
