import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutLargeTextPipe implements PipeTransform {

  transform(value: any) {
    if (value.length > 10) {
      let textResult = "";
       for (let i = 0; i <= 10; i++) {
         textResult += value[i];
       }
       return textResult + "...";
    } else {
      return '--';
    }
  }

}


