import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';


import { environment } from "src/environments/environment";
import { AffiliateLinkDataForm } from '../models/afiliate-link';
import { SelectsDataForm } from '../models/selectModel';
import { HelperService } from './helper.service';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateLinkService {
  private urlApi: string;
  private apiUrl: string;
  private dev07ApiUtl: string;
  private apiUrlLink: string;
  private tokenDownload: string;
  private coviUtilsUrl: string;
  private urlDownloadApi: string;
  public identity: any;
  public token: any;
  public userData: any;
  public rol: string;
  public headers: any;



  constructor(private http: HttpClient,
     private helper: HelperService,
     private ciCd: CiCdConfigService
     ) {
    this.urlApi = environment.apiUrl;
    this.dev07ApiUtl = environment.dev07ApiUtl;
    this.apiUrl = environment.apiUrl;
    this.apiUrlLink = environment.urlBuyerApi;
    this.tokenDownload = environment.tokenDownload;
    this.coviUtilsUrl = environment.coviUtilsUrl;
    this.urlDownloadApi = environment.urlDownloadApi;
    this.userData = JSON.parse(localStorage.getItem("identityMA"));
    this.rol = localStorage.getItem("RolMA");
    this.headers = new HttpHeaders();
    this.headers = this.headers.set("Content-Type", "application/json");
    this.headers = this.headers.set("token", localStorage.getItem("TokenMA"));
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {urlApi, apiUrl, tokenDownload, coviUtilsUrl, urlDownloadApi, dev07ApiUtl} = apis;
      this.apiUrlLink = dev07ApiUtl;
      this.urlApi = urlApi;
      this.apiUrl = apiUrl;
      this.dev07ApiUtl = dev07ApiUtl;
      this.tokenDownload = tokenDownload;
      this.coviUtilsUrl = coviUtilsUrl;
      this.urlDownloadApi = urlDownloadApi;
    });
  }

  public userNotifyForm(body:any): Observable<AffiliateLinkDataForm> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.tokenDownload),
    });

    let options = { headers }

    return this.http.post<AffiliateLinkDataForm>(`${this.apiUrlLink}/AffiliateLink/sendLink/sendUserNotify`,body,options);
  }

  public userAffiliateLink(body:any): Observable<AffiliateLinkDataForm> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.tokenDownload),
    });

    let options = { headers }

    return this.http.post<AffiliateLinkDataForm>(`${this.apiUrlLink}AffiliateLink/sendLink/sendLinkAffiliate`,body,options);
  }

  public getGeneralList(): Observable<SelectsDataForm> {
    const headers = new HttpHeaders(
      this.helper.getHeadersAuthorization()
    );

    let options = { headers }

    return this.http.get<any>(`${this.dev07ApiUtl}/commons/lists`, options)
  }

  getMunicipalities() {
    return this.http.get<any>(`${this.coviUtilsUrl}lists`).pipe(
      map(response => {
        return response.data[0].departaments
      })
    );
  }

  getBanks() {
    return this.http.get<any>(`${this.coviUtilsUrl}lists`).pipe(
      map(response => {
        return response.data[0].banks
      })
    );
  }

  getCities(departmentId: string) {
    const params = { municipaly: departmentId }
    const options = {
      params
    };
    return this.http.get(`${this.coviUtilsUrl}lists/cities`, options);
  }

  getDownloadQuota(params) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.tokenDownload),
    });

    const options = {
      headers,
      params
    };

    return this.http.get(`${this.apiUrlLink}/AffiliateLink/sendLink/downloadQuota`, options);
  }

  getDownloadReport() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: "Basic " + btoa(this.tokenDownload),
    });

    const options = {
      headers
    };

    return this.http.get(`${this.urlDownloadApi}/sendLink/downloadReport`, options);
  }
}
