import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { SelectsService } from '@app/services/selects.service';
import { SelectModel } from '@app/models/select.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';
import { HelperService } from '@app/services/helper.service';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';
import { BackupsStoreService } from '../services/backups-store.service';
import { AffiliatesListModel } from '../models/response/SearchAffiliate.model';
import { PaymentManagementModel, PaymentManagementResponse } from '../models/response/PaymentManagement.model';
import { PaymentManagementRequestModel } from '../models/request/PaymentManagement.model';
import { ProcessPaymentsRequestModel } from '../models/request/ProcessPayments.model';
import { ProcessPaymentsResponse } from '../models/response/ProcessPayments.model';
import { SearchAutocompleteAffiliateComponent } from '../search-autocomplete-affiliate/search-autocomplete-affiliate.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/redux/app.reducer';

@Component({
  selector: 'app-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss']
})
export class PaymentsTableComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('dateRange') dateRangeComponent: DateRangeComponent;
  @ViewChild('PaymentManagementForm') PaymentManagementForm: NgForm;
  @ViewChild('searchAutocompleteAffiliate') searchAutocompleteAffiliate: SearchAutocompleteAffiliateComponent;

  public paymentsToProcess: PaymentManagementModel[] = [];
  public selectOptionsBranchOffices: SelectModel[] = [];
  public selectOptionsChangeBackingsStatus: SelectModel[] = [];
  public selectOptionsNonRefundCauses: SelectModel[] = [];
  public selectOptionsBackingsFilterState: SelectModel[] = [];
  public selectOptionsManagePaymentsStatus: SelectModel[] = [];

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;
  public activeTab: string = '';
  public parentTab: string = 'payments-table';
  public excelFile: string = '';

  public causalReturn: string = '';
  public onShow: boolean;
  public stateShowSearchResult$: Observable<boolean> = this.backupsStore.stateShowSearchResult$;
  public selectedPaymentManagement: ProcessPaymentsRequestModel[] = [];
  public search: PaymentManagementRequestModel = new PaymentManagementRequestModel();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private backups: BackupsService, private backupsStore: BackupsStoreService,
    public selects: SelectsService, private spinner: NgxSpinnerService, public helper: HelperService,
    public tableHeaders: BackupsTableHeadersService, private store: Store<AppState>) {
    this.store.select('selects').pipe(takeUntil(this.unsubscribe$)).subscribe(({ selects }) => this.getSelects(selects));
    this.backupsStore.stateBackupsActiveTab$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(
        (stateBackupsActiveTab: string) => {
          this.activeTab = stateBackupsActiveTab;
          this.initilizeDateRangePicker();
          if (this.activeTab === this.parentTab) this.searchAutocompleteAffiliate.reset();
        });

    this.backupsStore.stateAffiliate$.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((stateAffiliate: AffiliatesListModel) => this.initAffiliate(stateAffiliate));
  }

  ngOnInit(): void {
    // this.initialize();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initAffiliate(stateAffiliate: AffiliatesListModel) {
    const instanceModel = new PaymentManagementRequestModel();
    this.search = {
      ...this.helper.assignEnumerableKeysAndAssignDefaultValues(stateAffiliate, instanceModel),
      initialDate: this.search.initialDate,
      finalDate: this.search.finalDate
    };
    console.log("this.search payments", this.search);

    // this.initilizeDateRangePicker();
    //  Si recibe el document vacio no ejecuta una nueva búsqueda
    if (this.activeTab === this.parentTab && (this.search.affiliateDocument !== '0')) {
      this.getPaymentManagement(1);
    }
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getPaymentManagement(currentPage);
  }

  onSearch(textSearch: string) {
    // this.search.search = textSearch;
    this.getPaymentManagement(1);
  }

  convertToUppercase(value: string) {
    const textClean = value.replace(/[^a-z0-9]/gi, '');
    return textClean.toUpperCase();
  }

  onSelectBranch(selectedbranch: string) {
    this.search.branchOffice = selectedbranch;
     if (!this.validateAffiliate()) return;
    this.getPaymentManagement(1);
  }

  onSelectFilterState(selectedFilterState: string) {
    this.search.sinisterStatus = selectedFilterState;
    if (!this.validateAffiliate()) return;
    this.getPaymentManagement(1);
  }

  onDateRange([startDate, endDate]) {
    this.search.initialDate = moment(startDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(endDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");

    if (!this.validateAffiliate()) return;

    this.getPaymentManagement(1);
  }

  getPaymentManagement(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = currentPage;
    this.search.download = false;
    this.backups.getPaymentToProcess(this.search).subscribe(
      (paymentManagement: PaymentManagementResponse) => {
        console.log(paymentManagement);
        
        if (paymentManagement && paymentManagement.ok === false) {
          this.handleGetPaymentManagementError();
          return;
        }
        this.paymentsToProcess = paymentManagement['data'];
        this.cantPages = paymentManagement['totalPages'];
        this.selectedPaymentManagement = [];
        this.spinner.hide();
        console.log('paymentManagement ->', paymentManagement);
      }, (error: any) => {
        this.handleGetPaymentManagementError(error);
      }
    );
  }

  downloadPaymentManagementExcel(currentPage: number): void {
    this.spinner.show();
    this.search.download = true;
    this.backups.getPaymentToProcess(this.search).subscribe(
      (paymentManagement: PaymentManagementResponse) => {
        if (paymentManagement && paymentManagement.ok === false) {
          this.handleGetPaymentManagementError();
          return;
        }
        this.excelFile = paymentManagement['file'];
        this.spinner.hide();
        console.log('paymentManagementDwonload ->', paymentManagement);
      }, (error: any) => {
        this.handleDownloadPaymentManagementError(error);
      }
    );
  }

  selectPaymentToProcess(selectedPayment: PaymentManagementModel) {
    const {
      issuerDocument,
      issuerDocumentType,
      filingNumber,
      guaranteedValue,
      filingStatus,
      draftNumber,
      affiliateDocumentType,
      affiliateDocument,
      claimedValue,
      nonRefundCause: returnCause,
      deductiblePercentage
    } = selectedPayment;

    this.selectedPaymentManagement[0] = {
      issuerDocument: (issuerDocument as any),
      issuerDocumentType,
      filingNumber,
      guaranteedValue,
      filingStatus,
      draftNumber,
      affiliateDocumentType,
      affiliateDocument: (affiliateDocument as any),
      claimedValue,
      returnCause,
      deductiblePercentage
    };

    this.paymentsToProcess = this.paymentsToProcess.map((backing: PaymentManagementModel) => {
      backing['check'] = backing.filingNumber === selectedPayment.filingNumber ? true : false;
      return backing;
    });
    // console.log('selected', this.selectedBackingToProcess[0]);
  }

  processPayments(): void {
    this.backups.processPayments(this.selectedPaymentManagement[0]).subscribe(
      (processPaymentsResponse: ProcessPaymentsResponse) => {
        if (processPaymentsResponse && processPaymentsResponse.ok === false) {
          this.handleProcessPaymentsError();
          return;
        }
        this.selectedPaymentManagement = [];
        this.handleResetPagination();
        Swal.fire({
          type: 'success',
          title: 'Transacción exitosa',
          text: `${processPaymentsResponse['message']}`
        }).then(() => {
          this.getPaymentManagement(1);
        });
      }, (error) => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Error en transacción',
          text: `${error.error.message || 'Error no identificado.'}`
        });
      }
    );
  }

  handleGetPaymentManagementError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Gestionar pagos', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.paymentsToProcess = [];
    this.selectedPaymentManagement = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleProcessPaymentsError(error?: any) {
    console.error(error);
    this.getPaymentManagement(1);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Radicación fallida', text: `${message}`, allowOutsideClick: false
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleDownloadPaymentManagementError(error?: any) {
    console.error(error);
    if (error && error.error && error.error.message && this.counterRequest > 1) {
      const { message } = error.error;
      Swal.fire({
        type: 'error', title: 'Descargar excel', text: `${message}`, allowOutsideClick: false
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.excelFile = '';
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  clearReturnCause(filingStatus: string, idx: number) {
    if (filingStatus !== 'DEVUELTO') {
      this.paymentsToProcess[idx].nonRefundCause = '';
    }
  }

  getValidOptions(selectedValue: string) {
    return this.selectOptionsChangeBackingsStatus;

    this.selects.getSelectsWithParams('changeBackingsStatus', selectedValue)
      .subscribe((changeBackingsStatus: SelectModel[]) => {
        return changeBackingsStatus || [new SelectModel()];
      });
  }

  getSelects(selects: any) {
    this.selectOptionsBranchOffices = selects.branchOffices || [new SelectModel()];
    this.selectOptionsChangeBackingsStatus = selects.changeBackingsStatus || [new SelectModel()];
    this.selectOptionsNonRefundCauses = selects.nonRefundCauses || [new SelectModel()];
    this.selectOptionsBackingsFilterState = selects.manageBackingsStatus || [new SelectModel()];
    this.selectOptionsManagePaymentsStatus = selects.managePaymentsStatus || [new SelectModel()];
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate).
      set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).
      subtract(30, 'days').
      format("YYYY-MM-DD HH:mm:ss");

    this.search.finalDate = moment(this.finalDate).
      set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).
      format("YYYY-MM-DD HH:mm:ss");
  }

  validateAffiliate() {
    const { affiliateDocument, issuerDocument } = this.search;
    console.log("validate =>", affiliateDocument, issuerDocument);
    if (affiliateDocument && affiliateDocument === '0' || issuerDocument && issuerDocument === '0') {
      //this.helper.showToastMessage('warning', 'Debe seleccionar un afiliado o comprador', '');
      return true;
    } 
  }

}
