import { CurrentExhibition } from "./currentExhibition.model";
import { Description } from "./description.model";
import { ExecutionProject } from "./executionProject.model";
import { HolderValidations } from "./holderValidations.model";
import { Macros } from "./macros.model";
import { MainClients } from "./mainClients.model";
import { MainProviders } from "./mainProviders.model";
import { Reference } from "./reference.model";

export class GeneralInfo {

    id: number;
    studyType: string;
    product: string;
    affiliate: string;
    state: string;
    requestType: number;
    settlement: number;
    receivedDate: Date;
    receivedHour: string;
    startDate: Date;
    startHour: string;
    responseDate: Date;
    responseHour: string;
    affiliateRisk: string;
    classification: string;
    minimumQuota: number;
    mediumQuota: number;
    maxQuota: number;
    maxDocsQuota: number;
    bankId: number;
    transactionAccount: string;
    stateCentralRisk: string;
    opening: Date;
    ciuCode: string;
    financialSector: string;
    totalNoFS: number;
    totalFS: number;
    inArrearsNoFS: number;
    inArrearsFS: number;
    maxHeightFS: number;
    realSector: string;
    totalNoRS: number;
    totalRS: number;
    inArrearsNoRS: number;
    inArrearsRS: number;
    maxHeightRS: number;
    totalPaymentValue: number;
    percentageUse: number;
    creditCards: string;
    totalNoCC: number;
    totalCC: number;
    inArrearsNoCC: number;
    inArrearsCC: number;
    maxHeightCC: number;
    observationsCentralRisk: string;
    currentAccounts: string;
    garnishedAccounts: string;
    totalIndebtedness: string;
    currentBalance: number;
    mainBalance: number;
    mainPercentage: number;
    balanceInArrears: number;
    maxRating: string;
    share: number;
    documentType: string;
    buyerDoc: number;
    names: string;
    expeditionDate: Date;
    expeditionPlace: string;
    nameLegalRepresentative: string;
    typeDocLegalRepresentative: string;
    documentLegalRepresentative: number;
    expeditionDateLegalRepresentative: Date;
    expeditionPlaceLegalRepresentative: string;
    birthDateLegalRepresentative: Date;
    address: string;
    idCity: string;
    idDepartment: string;
    covering: string;
    phone: number;
    cellphone: number;
    email: string;
    socialObject: string;
    constitution: string;
    validity: string;
    assets: string;
    authorizedCapital: number;
    paidInCapital: number;
    personContact: string;
    position: string;
    typePhone: number;
    phoneContact: number;
    emailContact: string;
    employees: string;
    pointsOfSale: number;
    halfContact: string;
    seniorityAffiliateLink: string;
    whatProvides: string;
    phoneWhereLocated: number;
    typePhoneContact: number;
    departmentContact: string;
    cityContact: string;
    observationsContact: string;
    comments: string;
    cvsNo: number;
    maxRecoveryDays: number;
    cvsLastYear: number;
    maxRecoveryLastYear: number;
    noticePaymentLastYearNo: number;
    extensionsLastYearNo: number;
    initialQuota: number;
    initialTotalGuarantee: number;
    initialTotalCurrentGuarantee: number;
	finalQuota: number;
	finalTotalCurrentGuarantee: number;
    generalObservationsCurrentExhibition: string;
    currentExhibition: Array<CurrentExhibition> = new Array<CurrentExhibition>();
    references: Array<Reference> = new Array<Reference>();
    holderValidations: Array<HolderValidations> = new Array<HolderValidations>();
    idDescripcion: Description;
    idMacro: Macros;
    executionProjects: Array<ExecutionProject> = new Array<ExecutionProject>();
    mainSuppliersResponses: Array<MainProviders> = new Array<MainProviders>();
    mainClientsResponses: Array<MainClients> = new Array<MainClients>();

public static fromResponse(json: any): GeneralInfo {

    const generalInfo: GeneralInfo = {

    id: json['id'],
    studyType: json['studyType'],
    product: json['product'],
    affiliate: json['affiliate'],
    state: json['state'],
    requestType: json['requestType'],
    settlement: json['settlement'],
    receivedDate: json['receivedDate'],
    receivedHour: json['receivedHour'],
    startDate: json['startDate'],
    startHour: json['startHour'],
    responseDate: json['responseDate'],
    responseHour: json['responseHour'],
    affiliateRisk: json['affiliateRisk'],
    classification: json['classification'],
    minimumQuota: json['minimumQuota'],
    mediumQuota: json['mediumQuota'],
    maxQuota: json['maxQuota'],
    maxDocsQuota: json['maxDocsQuota'],
    bankId: json['bankId'],
    transactionAccount: json['transactionAccount'],
    stateCentralRisk: json['stateCentralRisk'],
    opening: json['opening'],
    ciuCode: json['ciuCode'],
    financialSector: json['financialSector'],
    totalNoFS: json['totalNoFS'],
    totalFS: json['totalFS'],
    inArrearsNoFS: json['inArrearsNoFS'],
    inArrearsFS: json['inArrearsFS'],
    maxHeightFS: json['maxHeightFS'],
    realSector: json['realSector'],
    totalNoRS: json['totalNoRS'],
    totalRS: json['totalRS'],
    inArrearsNoRS: json['inArrearsNoRS'],
    inArrearsRS: json['inArrearsRS'],
    maxHeightRS: json['maxHeightRS'],
    totalPaymentValue: json['totalPaymentValue'],
    percentageUse: json['percentageUse'],
    creditCards: json['creditCards'],
    totalNoCC: json['totalNoCC'],
    totalCC: json['totalCC'],
    inArrearsNoCC: json['inArrearsNoCC'],
    inArrearsCC: json['inArrearsCC'],
    maxHeightCC: json['maxHeightCC'],
    observationsCentralRisk: json['observationsCentralRisk'],
    currentAccounts: json['currentAccounts'],
    garnishedAccounts: json['garnishedAccounts'],
    totalIndebtedness: json['totalIndebtedness'],
    currentBalance: json['currentBalance'],
    mainBalance: json['mainBalance'],
    mainPercentage: json['mainPercentage'],
    balanceInArrears: json['balanceInArrears'],
    maxRating: json['maxRating'],
    share: json['share'],
    documentType: json['documentType'],
    buyerDoc: json['buyerDoc'],
    names: json['names'],
    expeditionDate: json['expeditionDate'],
    expeditionPlace: json['expeditionPlace'],
    nameLegalRepresentative: json['nameLegalRepresentative'],
    typeDocLegalRepresentative: json['typeDocLegalRepresentative'],
    documentLegalRepresentative: json['documentLegalRepresentative'],
    expeditionDateLegalRepresentative: json['expeditionDateLegalRepresentative'],
    expeditionPlaceLegalRepresentative: json['expeditionPlaceLegalRepresentative'],
    birthDateLegalRepresentative: json['birthDateLegalRepresentative'],
    address: json['address'],
    idCity: json['idCity'],
    idDepartment: json['idDepartment'],
    covering: json['covering'],
    phone: json['phone'],
    cellphone: json['cellphone'],
    email: json['email'],
    socialObject: json['socialObject'],
    constitution: json['constitution'],
    validity: json['validity'],
    assets: json['assets'],
    authorizedCapital: json['authorizedCapital'],
    paidInCapital: json['paidInCapital'],
    personContact: json['personContact'],
    position: json['position'],
    typePhone: json['typePhone'],
    phoneContact: json['phoneContact'],
    emailContact: json['emailContact'],
    employees: json['employees'],
    pointsOfSale: json['pointsOfSale'],
    halfContact: json['halfContact'],
    seniorityAffiliateLink: json['seniorityAffiliateLink'],
    whatProvides: json['whatProvides'],
    phoneWhereLocated: json['phoneWhereLocated'],
    typePhoneContact: json['typePhoneContact'],
    departmentContact: json['departmentContact'],
    cityContact: json['cityContact'],
    observationsContact: json['observationsContact'],
    comments: json['comments'],
    cvsNo: json['cvsNo'],
    maxRecoveryDays: json['maxRecoveryDays'],
    cvsLastYear: json['cvsLastYear'],
    maxRecoveryLastYear: json['maxRecoveryLastYear'],
    noticePaymentLastYearNo: json['noticePaymentLastYearNo'],
    extensionsLastYearNo: json['extensionsLastYearNo'],
    currentExhibition: json['currentExhibition'],
    references: json['references'],
    holderValidations: json['holderValidations'],
    idDescripcion: json['idDescripcion'],
    idMacro: json['idMacro'],
    executionProjects: json['executionProjects'],
    mainSuppliersResponses: json['mainProviders'],
    mainClientsResponses: json['mainClients'],
    initialQuota: json['initialQuota'],
    initialTotalGuarantee: json['initialTotalGuarantee'],
    initialTotalCurrentGuarantee: json['initialTotalCurrentGuarantee'],
	finalQuota: json['finalQuota'],
	finalTotalCurrentGuarantee: json['finalTotalCurrentGuarantee'],
    generalObservationsCurrentExhibition: json['generalObservationsCurrentExhibition'],
    };

    return generalInfo;

    }
}