import { Component, OnInit, Input } from "@angular/core";
import {
  Simulation,
  Datum,
  Response,
  Tipo,
  Clase,
} from "@app/models/simulation";
import { BackupsTableHeadersService } from "@app/pages/backups/services/backups-table-headers.service";
import { GloballBillingService } from "@app/services/globall-billing.service";
import { HelperService } from "@app/services/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-simulation-table",
  templateUrl: "./simulation-table.component.html",
  styleUrls: ["./simulation-table.component.scss"],
})
export class SimulationTableComponent implements OnInit {
  @Input() registerSelected: any = null;
  public idBag: number = 0;
  public tipo: any;
  public data: Simulation;
  public arrayAmortization: any[] = [];
  public dataTable: any[] = [];
  public footerTable: any[] = [];
  public uniqueAmortization: any[] = [];
  public uniqueBilling: any[] = [];

  public tableHeard: any[] = [];

  constructor(
    public backupsHeaders: BackupsTableHeadersService,
    private spinner: NgxSpinnerService,
    private simulationService: GloballBillingService,
    public helper: HelperService
  ) {}
  ngOnInit() {
    this.idBag = this.registerSelected ? this.registerSelected.id : 0;
    this.tipo = "SIMULACION";
    this.backupsHeaders.loading$.subscribe(() => {
      this.tipo = "SIMULACION";
      this.generalGlobalBillingReport();
    });
  }

  generalGlobalBillingReport() {
    this.spinner.show();
    this.simulationService
      .getSimulationService(this.idBag, this.tipo)
      .subscribe(
        (amortizationResponse: Simulation) => {
          if (this.tipo == "GRABAR") {
            Swal.fire({
              type: "success",
              title: "Correcto",
              text: `Se guardo correctamente`,
            });
            this.returnAmortization();
            this.spinner.hide();
            this.backupsHeaders.setLoad(true);
            return;
          }
          if (!amortizationResponse || amortizationResponse.data.length == 0) {
            this.handleGetReportError();
            return;
          }
          this.data = amortizationResponse;
          this.dataTable = amortizationResponse.data[0].response;
          if (this.dataTable.length > 0) {
            this.viewDataTable();
          }

          this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide();
          console.error("Error al obtener datos de facturación:", error);
          if (this.tipo == "GRABAR") {
            this.handleGetReportError(error);
          }
        }
      );
  }
  handleGetReportError(error?: any): void {
    console.error(error);
    if (error && error.error && error.error.message) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title: "Tabla de simulación",
        text: `${message}`,
      });
    } else {
      this.helper.handleUndefinedError();
    }
    this.spinner.hide();
  }

  viewDataTable() {
    let response = this.dataTable;

    console.log(response);
    let quota: any = Array.from(
      new Set(response.map((element) => element.no_cuota_factura))
    );

    this.uniqueAmortization = response.map((element) => {
      const date = new Date(
        element.fecha_amortiza ? element.fecha_amortiza : new Date()
      );
      const month = date.toLocaleString("default", {
        month: "long",
      });
      return {
        cuotaAmortization: element.no_cuota_amortiza,
        date: `${month} ${date.getFullYear()}`,
      };
    });

    const jsonObject = this.uniqueAmortization.map((element) =>
      JSON.stringify(element)
    );
    const uniqueSet = new Set(jsonObject);

    this.uniqueAmortization = Array.from(uniqueSet).map((element) =>
      JSON.parse(element)
    );

    quota = quota.map((element) => {
      let bills = response.filter((items) => items.no_cuota_factura == element);
      const billDate = Array.from(
        new Set(bills.map((bills) => bills.fecha_factura))
      );
      const stringDate = billDate.length > 0 ? billDate[0].toString() : "";
      const date = new Date(stringDate);

      const month = date.toLocaleString("default", { month: "long" });
      const bodyTable = [];
      let income = 0;

      bills.forEach((bill) => {
        income += bill.vr_cuota;
      });

      this.uniqueAmortization.forEach((element) => {
        const bill = bills.find(
          (item) => item.no_cuota_amortiza == element.cuotaAmortization
        );
        if (bill) {
          bodyTable.push(bill);
        } else {
          bodyTable.push(null);
        }
      });

      return {
        quota: element,
        bills: bodyTable,
        date: `${month} ${date.getFullYear()}`,
        income: income,
      };
    });

    this.tableHeard = quota;

    this.footerTable = this.sumFooterTable();
  }

  sumObject(items: Response[]) {
    let count = 0;
    items.forEach((element) => {
      count += element.vr_cuota;
    });

    return count;
  }

  sumFooterTable() {
    const count: any[] = [];
    this.uniqueAmortization.forEach((element) => {
      let value = 0;
      const bills = this.dataTable.filter(
        (item) => item.no_cuota_amortiza === element.cuotaAmortization
      );
      bills.forEach((element) => {
        value += element.vr_cuota;
      });
      count.push(value);
    });
    let total = 0;
    this.tableHeard.forEach((element) => {
      element.bills.forEach((item) => {
        if (item) {
          total += item.vr_cuota;
        }
      });
    });
    count.push(total);
    return count;
  }

  returnAmortization() {
    this.backupsHeaders.viewTableData = true;
  }
  saveSimulation() {
    this.tipo = "GRABAR";
    this.generalGlobalBillingReport();
  }
}
