import { Component, OnInit } from "@angular/core";

import * as moment from "moment";

import { Response } from "@app/models/responseUtil.model";
import { HelperService } from "@app/services/helper.service";
import { NetFactoringBackEndService } from "@app/services/net-factoring-back-end.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NegotiatedInvoices } from "../models/NegotiatedInvoices";
import { FilterAffiate } from "../models/Request/FilterAffiliate";
import { NetFactoringService } from "../services/net-factoring.service";

@Component({
  selector: "app-negotiated-invoices",
  templateUrl: "./negotiated-invoices.component.html",
  styleUrls: ["./negotiated-invoices.component.scss"],
})
export class NegotiatedInvoicesComponent implements OnInit {
  finalDate: any;
  initDate: any;

  negotiatedInvoicesList: Array<NegotiatedInvoices> = [];
  currentPage: number = 1;
  cantPages = 0;

  filterAffiliate: FilterAffiate;
  reload = false;

  public typePerson: string = "natural";

  filterDate = false;

  fromDate: string = "";
  toDate: string = "";

  constructor(
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    public netFactoringService: NetFactoringService,
    public netFactoringBackendService: NetFactoringBackEndService
  ) {
    //Start-up data for filter
    this.filterAffiliate = this.netFactoringService.filterAfilliate;
  }

  ngOnInit() {
    //Not filter
    this.getData();
  }

  getData() {
    this.filterAffiliate.typeReport = this.netFactoringService.typeReport[1];
    this.spinner.show();
    this.reload = false;
    this.clearData();
    this.netFactoringBackendService
      .getInvoices(this.currentPage, this.filterAffiliate.typeReport)
      .subscribe(
        (response: Response<NegotiatedInvoices>) => {
          this.negotiatedInvoicesList = response.data;
          this.cantPages = response.totalPages;
          this.filterDate = false;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  downloadFile() {
    this.spinner.show();
    this.netFactoringBackendService.getDocumentDownload().subscribe(
      (response: Response<any>) => {
        let dataCurrent = response.data[0];
        this.helper.downloadFromBase64(dataCurrent, "informe", ".xls");
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
  getDataFilterAffiliate(typeQuery: boolean) {
    if (typeQuery) {
      this.filterAffiliate.typeQuery = this.netFactoringService.typeQuery[0];
    } else {
      this.filterAffiliate.typeQuery = this.netFactoringService.typeQuery[1];
    }
    this.reload = true;
    this.clearData();
    this.spinner.show();
    this.filterAffiliate.currentPage = this.currentPage;
    this.filterAffiliate.perPage = 10;
    this.netFactoringBackendService
      .getFilerAffiliate(this.filterAffiliate)
      .subscribe(
        (response: Response<NegotiatedInvoices>) => {
          console.log(response);
          this.negotiatedInvoicesList = response.data;
          this.cantPages = response.totalPages;
          this.spinner.hide();
        },
        (error) => {
          this.negotiatedInvoicesList = [];
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getData();
  }

  reLoad() {
    this.filterAffiliate.socialReason = null;
    this.filterAffiliate.firstName = null;
    this.filterAffiliate.secondName = null;
    this.filterAffiliate.firstSurname = null;
    this.filterAffiliate.secondSurname = null;
    this.initDate = null;
    this.finalDate = null;
    this.toDate = null;
    this.fromDate = null;
    this.getData();
    this.reload = false;
  }

  clearData() {
    this.filterAffiliate.socialReason
      ? true
      : (this.filterAffiliate.socialReason = null);
    this.filterAffiliate.firstName
      ? true
      : (this.filterAffiliate.firstName = null);
    this.filterAffiliate.secondName
      ? true
      : (this.filterAffiliate.secondName = null);
    this.filterAffiliate.firstSurname
      ? true
      : (this.filterAffiliate.firstSurname = null);
    this.filterAffiliate.secondSurname
      ? true
      : (this.filterAffiliate.secondSurname = null);
  }

  onDateRange() {
    this.filterDate = true;
    this.filterAffiliate.from = moment(this.fromDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss.SSS");

    this.filterAffiliate.to = moment(this.toDate)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm:ss.SSS");
    console.log(this.filterAffiliate.to, this.filterAffiliate.from);
    this.filterAffiliate.socialReason = null;
    this.filterAffiliate.firstName = null;
    this.filterAffiliate.secondName = null;
    this.filterAffiliate.firstSurname = null;
    this.filterAffiliate.secondSurname = null;
    this.filterAffiliate.typeReport = this.netFactoringService.typeReport[1];
    this.getDataFilterAffiliate(false);
  }
}
