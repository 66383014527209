import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Response, ResponseObject,
} from "@app/models/responseUtil.model";
import { BackingsToProcessRequestModel } from '@app/pages/backups/models/request/BackingsToProcess.model';
import { CreateBackingsRequest } from '@app/pages/backups/models/request/CreateBackings.model';
import { DocumentRequestModel } from '@app/pages/backups/models/request/DocumentRequest.model';
import { PaymentManagementRequestModel } from '@app/pages/backups/models/request/PaymentManagement.model';
import { ProcessBackingsRequestModel } from '@app/pages/backups/models/request/ProcessBackings.model';
import { ProcessPaymentsRequestModel } from '@app/pages/backups/models/request/ProcessPayments.model';
import { RefundsModel } from '@app/pages/backups/models/request/Refunds.model';
import { ReportRequest } from '@app/pages/backups/models/request/Report.model';
import { SearchAffiliateModel } from '@app/pages/backups/models/request/SearchAffiliateModel';
import { TransactionsToBackUpRequestModel } from '@app/pages/backups/models/request/TransactionsToBackupModel';
import { BackingsToProcessModel, BackingsToProcessResponse } from '@app/pages/backups/models/response/BackingsToProcess.model';
import { CreateBackingsResponse } from '@app/pages/backups/models/response/CreateBackings.model';
import { PaymentManagementResponse } from '@app/pages/backups/models/response/PaymentManagement.model';
import { ProcessPaymentsResponse } from '@app/pages/backups/models/response/ProcessPayments.model';
import { RefundsListResponse } from '@app/pages/backups/models/response/RefundsList.model';
import { ReportResponse } from '@app/pages/backups/models/response/Report.model';
import { AffiliatesListModel, AffiliatesResponse } from '@app/pages/backups/models/response/SearchAffiliate.model';
import { TransactionsToBackupResponse } from '@app/pages/backups/models/response/TransactionsToBackupResponse';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { BackupsTransactionsRequest } from '@app/pages/backups/models/request/BackupsTransactions.model';
import { DeductibleRequest } from '@app/pages/backups/models/request/DeductibleRequest.model';
import { DataSearchMultipleFiles, ImagesFiles } from '@app/pages/backups/models/request/claims';
import { BackupsTransactionsResponse } from '@app/pages/backups/models/response/BackupsTransactionsResponse.model';
import { Deductible } from '@app/pages/backups/models/response/Deductibles.model';
import { Observable } from 'rxjs';
import { PaymentsPostBackingRequestModel } from '../pages/backups/models/request/PaymentsPostBackings.model';
import { PaymentsPostBackingResponse } from '../pages/backups/models/response/PaymentsPostBacking.model';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xls";

@Injectable({
  providedIn: "root",
})
export class BackupsService {
  public urlApi: string = "";
  public apiUrlBackings: string = "";
  public backingsToken: string;
  public timeout: number = 0;
  public headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private helper: HelperService,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.apiUrl;
    this.apiUrlBackings = "";
    this.backingsToken = environment.backingsToken;
    this.timeout = environment.httpTimeout;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, backingsToken, httpTimeout, apiUrlBackings } = apis
      this.urlApi = apiUrl;
      this.apiUrlBackings = apiUrlBackings;
      this.backingsToken = backingsToken;
      this.timeout = httpTimeout;
       console.log('config ci cd', apiUrl);
    });
  }

  public getAffiliate(searchAffiliate: SearchAffiliateModel): any {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const params: any = Object.assign({}, searchAffiliate);

    for (const keyParam in params) {
      if (!params[keyParam]) {
        searchAffiliate[keyParam] = "";
        delete params[keyParam];
      }
    }

    delete params.authorization;
    delete params.sinisterNumber;

    if (params.type === "0") {
      params.search = encodeURIComponent(params.search);
    }

    const options = {
      headers,
    };

    return this.http.post<AffiliatesResponse>(`${this.apiUrlBackings}/searching/searchFilter`, params, options).pipe(
      map((affiliatesListResponse: AffiliatesResponse) => affiliatesListResponse)
    );
  }

  getTransactionsToBackUp(getTransactionsRequest: TransactionsToBackUpRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };
    return this.http.post<TransactionsToBackupResponse>(`${this.apiUrlBackings}/backings/getTransactionsToBackUp`, getTransactionsRequest, options).pipe(
      map((transactionsToBackUp: TransactionsToBackupResponse) => transactionsToBackUp)
    );
  }

  createBacking(createBackingsRequest: CreateBackingsRequest) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers
    };

    createBackingsRequest.user = this.helper.getIdentity();
    return this.http
      .post<CreateBackingsResponse>(
        `${this.apiUrlBackings}/backings/createBacking`,
        createBackingsRequest,
        options
      )
      .pipe(
        // timeout(120000),
        map(
          (createBackingsResponse: CreateBackingsResponse) =>
            createBackingsResponse
        )
      );
  }

  // TODO ready

  getBackingsToProcess(backingsToProcessRequest: BackingsToProcessRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      search: backingsToProcessRequest,
      headers,
    };

    return this.http.post<BackingsToProcessResponse>(`${this.apiUrlBackings}/backings/backingsToProcess`, backingsToProcessRequest, options).pipe(
      map((backingsToProcess: BackingsToProcessResponse) => BackingsToProcessModel.fromResponse(backingsToProcess))
    );
  }

  // TODO ready

  processBackings(backingsToProcess: ProcessBackingsRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    console.log(backingsToProcess);


    const options = {
      headers,
    };

    const user = JSON.parse(localStorage.getItem("identity"));
    if (user) {
      backingsToProcess["user"] = user.username;
    } else {
      let user = JSON.parse(localStorage.getItem("identity"));
      backingsToProcess["user"] = user.username;
    }

    return this.http.post<BackingsToProcessResponse>(`${this.apiUrlBackings}/backings/processBackings`, backingsToProcess, options).pipe(
      map((backingsToProcess: BackingsToProcessResponse) => backingsToProcess)
    );
  }

  getTotalBackings(bodyRequest) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers
    };

    return this.http.post<any[]>(`${this.apiUrlBackings}/backings/getTotalBackings`, bodyRequest, options);
  }

  uploadMassiveBackings(fileRequest: DocumentRequestModel) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/x-www-form-urlencoded");
    const options = {
      headers,
    };
    const file = fileRequest.excelFile[0];
    const fileName = "excelFile";
    const formData: FormData = new FormData();
    formData.append(fileName, file, file.name);
    for (const key in fileRequest) {
      if (key !== fileName) {
        formData.append(key, fileRequest[key]);
      }
    }
    return this.http
      .post<any[]>(`${this.urlApi}/uploadMassiveBackings`, formData, options)
      .pipe(
        // timeout(this.timeout),
        map(
          (affiliatesListResponse: any[]) =>
            affiliatesListResponse["affiliatesList"]
        )
      );
  }

  processMassiveBackings(searchAffiliate: SearchAffiliateModel) {
    const headers = new HttpHeaders();
    const options = {
      searchAffiliate,
      headers,
    };
    return this.http
      .post<AffiliatesListModel[]>(
        `${this.urlApi}/processMassiveBackings`,
        {},
        options
      )
      .pipe(
        // timeout(this.timeout),
        map(
          (affiliatesListResponse: AffiliatesListModel[]) =>
            affiliatesListResponse["affiliatesList"]
        )
      );
  }

  // TODO ready

  getRefunds(search: RefundsModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(this.backingsToken),
      'Content-Type':  'application/json',
    });

    const options = {
      search: search as any,
      headers: headers,
    };

    return this.http.post<RefundsListResponse>(`${this.apiUrlBackings}/refunds/getRefunds`, search, options).pipe(
      map((refundsListResponse: RefundsListResponse) => refundsListResponse)
    );
  }

  getPaymentToProcess(paymentManagementRequest: PaymentManagementRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      search: paymentManagementRequest,
      headers,
    };
    return this.http
      .post<PaymentManagementResponse>(
        `${this.apiUrlBackings}/payments/paymentsToProcess`,
        paymentManagementRequest,
        options
      )
      .pipe(
        map((paymentManagement: PaymentManagementResponse) => paymentManagement)
      );
  }

  downloadPaymentToProcess(paymentManagementRequest: PaymentManagementRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options: any = {
      search: paymentManagementRequest,
      headers,
      responseType: "blob",
    };
    return this.http
      .post<PaymentManagementResponse>(
        `${this.apiUrlBackings}/payments/paymentsToProcess`,
        paymentManagementRequest,
        options
      )
      .pipe(
        // timeout(this.timeout),
        map((paymentManagement: any) => paymentManagement)
      );
  }

  processPayments(processPaymentsRequest: ProcessPaymentsRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post<ProcessPaymentsResponse>(`${this.apiUrlBackings}/backings/processPayments`, processPaymentsRequest, options).pipe(
      map((processPayments: ProcessPaymentsResponse) => processPayments)
    );
  }

  getReport(reportRequest: ReportRequest) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };
    return this.http.post<ReportResponse>(`${this.apiUrlBackings}/backings/backingsReport`, reportRequest, options).pipe(
      map((reportResponse: ReportResponse) => reportResponse)
    );
  }


  getPaymentsPostBacking(paymentsPostBackingRequest: PaymentsPostBackingRequestModel) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post<PaymentsPostBackingResponse>(`${this.apiUrlBackings}/payments/paymentsPostBacking`, paymentsPostBackingRequest, options).pipe(
      map((paymentsPostBackingResponse: PaymentsPostBackingResponse) => paymentsPostBackingResponse)
    );
  }

  getHistory(searchAffiliate: SearchAffiliateModel) {
    const headers = new HttpHeaders();
    const options = {
      searchAffiliate,
      headers,
    };
    return this.http
      .get<AffiliatesListModel[]>(`${this.urlApi}/paymentsPostBacking`, options)
      .pipe(
        // timeout(this.timeout),
        map(
          (affiliatesListResponse: AffiliatesListModel[]) =>
            affiliatesListResponse["affiliatesList"]
        )
      );
  }

  getTransactions(transactionsRequest: BackupsTransactionsRequest): Observable<BackupsTransactionsResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers
    };

    return this.http.post<BackupsTransactionsResponse>(
      `${this.apiUrlBackings}/transactions/allTransactions`,
      transactionsRequest,
      options).pipe(
        // timeout(this.timeout),
        map(
          (refundsListResponse: BackupsTransactionsResponse) =>
            refundsListResponse
        )
      );
  }

  downloadTransactions(transactionsRequest: BackupsTransactionsRequest): Observable<BackupsTransactionsResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options: any = {
      search: transactionsRequest,
      headers,
    };

    return this.http
      .post<BackupsTransactionsResponse>(
        `${this.apiUrlBackings}/transactions/allTransactions`,
        transactionsRequest,
        options
      )
      .pipe(
        map((transactionFileResponse: any) => transactionFileResponse)
      );
  }

  downloadReport(contract: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    return this.http.get(
      `${this.apiUrlBackings}/backings/downloadReport`,
      {
        headers,
        responseType: "blob",
      }
    );
  }

  backupsAudit(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.urlApi}backingsPostgreSQL/backings/audit`,
      body,
      options
    );
  }

  returnedBackings(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.urlApi}backingsPostgreSQL/backings/returnedBackings`,
      body,
      options
    );
  }

  cancelBacking(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.urlApi}backingsPostgreSQL/backings/cancelBacking`,
      body,
      options
    );
  }

  getTemplate() {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.backingsToken)
    );

    const options = { headers };

    return this.http
      .get(`${this.urlApi}backingsPostgreSQL/payments/getTemplate`, {
        headers: options.headers,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  validateMassiveChangeStatus(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.urlApi}backingsPostgreSQL/payments/validateMassiveChangeStatus`,
      body,
      options
    );
  }

  massiveChangeStatus(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };

    return this.http.post(
      `${this.urlApi}backingsPostgreSQL/payments/massiveChangeStatus`,
      body,
      options
    );
  }

  getSearchCv(dataCv: string, numberPage: number, size: number) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.backingsToken)
    );

    const params = {
      CV: dataCv,
      pageNo: `${numberPage}`,
      size: `${size}`,
    }

    const options = { headers, params };

    return this.http.get(`${this.urlApi}backingsPostgreSQL/respaldos/getImageByCV`, options)
  }

  postSearchFiles(data: DataSearchMultipleFiles) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.backingsToken)
    );

    const options = { headers};
    return this.http.post(`${this.urlApi}backingsPostgreSQL/respaldos/covifactura/findImageByCriteria`,data, options)
  }

  postSaveFiles(data: ImagesFiles) {
    let headers = new HttpHeaders();

    headers = headers.append("enctype", "multipart/form-data");

    headers = headers.append(
      "Authorization",
      "Basic " + btoa(this.backingsToken)
    );

    const options = { headers};
    return this.http.post(`${this.urlApi}backingsPostgreSQL/respaldos/saveMassiveImages`,data, options)
  }

  getDeductible(
    request: DeductibleRequest
  ): Observable<Response<Deductible[]>> {

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": "Basic " + btoa(this.backingsToken),
    });

    const options = {
      headers,
    };
    return this.http
      .get(
        `${this.urlApi}backingsPostgreSQL/deductible/information?startDate=${request.startDate}&endDate=${request.endDate}&filter=${request.filter}&currentPage=${request.currentPage}`,
        options
      )
      .pipe(
        map((resp: Response<Deductible[]>) => {
          return resp;
        })
      );
  }

  downloadDeductibles(
    request: DeductibleRequest
  ): Observable<ResponseObject<any[]>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),

    });
     const options = {
       headers,
     };
    return this.http
      .get(
        `${this.urlApi}backingsPostgreSQL/deductible/downloadInformation?startDate=${request.startDate}&endDate=${request.endDate}&filter=${request.filter}`,
        options
      )
      .pipe(
        map((resp: ResponseObject<any[]>) => {
          return resp;
        })
      );
  }
}
