import { Component } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss']
})
export class MyPaymentsComponent {

  public navItemTabs = [
    { href: '#bill-to-pay', description: 'Factura por pagar', icon: 'icon-tab-bill-to-pay.svg' },
    { href: '#bill-history', description: 'Historial de facturas', icon: 'icon-tab-bill-history.svg' },
  ];

  constructor(public spinner: NgxSpinnerService,
    public helper: HelperService,
    public modal: ModalService) { }

}
