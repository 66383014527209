import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ChargedAndNonChargedInvoice } from '../../models/response/netsuite-invoices-response.model';
import { NetsuiteInvoicesService } from '../../services/netsuite-invoices.service';

@Component({
  selector: 'app-chargued-netsuite-invoices',
  templateUrl: './chargued-netsuite-invoices.component.html',
  styleUrls: ['./chargued-netsuite-invoices.component.scss']
})
export class CharguedNetsuiteInvoicesComponent implements OnInit {

  @Input() invoiceSelected: any = null;
  @Input() chargedInvoices: Array<ChargedAndNonChargedInvoice> = [];
  @Input() chargedInvoicesTableHeaders: Array<ChargedAndNonChargedInvoice> = [];
  @Output() outputCharguedInvoice: EventEmitter<any> = new EventEmitter<any>();

  constructor(private netsuiteInvoiceService: NetsuiteInvoicesService) { }

  ngOnInit() {
    this.netsuiteInvoiceService.invoiceSelected$.subscribe((invoice: any) => {
      if(invoice === null) {
        this.invoiceSelected = null;
        this.chargedInvoices = [];
      }
    });
  }

  selectInvoice(invoice: any) {
    this.outputCharguedInvoice.emit(invoice.factura);
  }

}
