import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@guards/auth.guard";
import { AdministrationComponent } from "@pages/administration/administration.component";
import { DocumentsComponent } from "@pages/administration/documents/documents.component";
import { MyPaymentsComponent } from "@pages/administration/my-payments/my-payments.component";
import { ProfileComponent } from "@pages/administration/profile/profile.component";
import { AffiliatesSearchComponent } from "./affiliates/affiliates-search/affiliates-search.component";
import { AffiliatesTabsComponent } from "./affiliates/affiliates-tabs/affiliates-tabs.component";
import { AffiliatesComponent } from "./affiliates/affiliates.component";
import { SetupAffiliateLinkComponent } from "./affiliates/setup-affiliate-link/setup-affiliate-link.component";
import { BackupsAuditComponent } from "./backups/backups-audit/backups-audit.component";
import { BackupsReportTableComponent } from "./backups/backups-report-table/backups-report-table.component";
import { BackupsTabsComponent } from "./backups/backups-tabs/backups-tabs.component";
import { BackupsComponent } from "./backups/backups.component";
import { MassiveChangeStatusComponent } from "./backups/massive-change-status/massive-change-status.component";
import { TestComponent } from "./backups/test/test.component";
import { BackupsTransactionsComponent } from "./backups/transactions/backups-transactions.component";
import { BuyersComponent } from "./buyers/buyers.component";
import { ClientStudyComponent } from "./client-study/client-study.component";
import { ActivateQuotaComponent } from "./get-quota/activate-quota/activate-quota.component";
import { AdminQuotaComponent } from "./get-quota/admin-quota/admin-quota.component";
import { GetQuotaComponent } from "./get-quota/get-quota.component";
import { QuotaCreateComponent } from "./get-quota/quota-create/quota-create.component";
import { QuotaVsAffiliateComponent } from "./get-quota/quota-vs-affiliate/quota-vs-affiliate.component";
import { QuotaWithoutContract } from "./get-quota/quota-without-contract/quota-without-contract.component";
import { ReservesComponent } from "./get-quota/reserves/reserves.component";
import { BlocksUnlocksComponent } from "./get-quota/transactions/blocks-unlocks/blocks-unlocks.component";
import { EntryTransactionsComponent } from "./get-quota/transactions/entry-transactions/entry-transactions.component";
import { NonPaymentNoticeComponent } from "./get-quota/transactions/non-payment-notice/non-payment-notice.component";
import { PromisoryNotes } from "./get-quota/transactions/promisory-notes/promisory-notes.component";
import { QuotaTransactionsComponent } from "./get-quota/transactions/quota-transactions/quota-transactions.component";
import { TitleModifyComponent } from "./get-quota/transactions/title-modify/title-modify.component";
import { TransactionPromisoryNoteComponent } from "./get-quota/transactions/transaction-promisory-note/transaction-promisory-note.component";
import { MarkNonPaymentComponent } from "./mark-non-payment/mark-non-payment.component";
import { OperationsTabsComponent } from "./operations/operations-tabs/operations-tabs.component";
import { OperationsComponent } from "./operations/operations.component";
import { PagesComponent } from "./pages.component";
import { ProductsComponent } from "./products/products.component";
import { QuotaRadicationComponent } from "./quota-radication/quota-radication.component";
import { RequestAdminComponent } from "./quota-radication/request-admin/request-admin.component";
import { RequestManagmentComponent } from "./quota-radication/request-managment/request-managment.component";
import { QuotasComponent } from "./quotas/quotas.component";
import { TransactionsThreeAndSixPaymentsComponent } from "./transactions/three-and-six-payments/three-and-six-payments.component";
import { TransactionsReportsComponent } from "./transactions/transactions-reports/transactions-reports.component";
import { TransactionsComponent } from "./transactions/transactions.component";
/* import { TabsComponent } from '@app/shared/tabs/tabs.component'; */
import { ConsultIndexComponent } from "./contracts/consult-index/consult-index.component";
import { StepperContractComponent } from "./contracts/stepper-contract/stepper-contract.component";
import { NetFactoringRouterComponent } from "./net-factoring/net-factoring-router/net-factoring-router.component";
import { NetFactoringComponent } from "./net-factoring/net-factoring.component";
import { RisksProcessesChargesComponent } from "./risks-processes-charges/risks-processes-charges.component";
import { MassQuotaLoadComponent } from "./risks-processes-charges/mass-quota-load/mass-quota-load.component";
import { AffiliatePoliciesComponent } from "./risks-processes-charges/affiliate-policies/affiliate-policies.component";
import { AffiliateRisksComponent } from "./risks-processes-charges/affiliate-risks/affiliate-risks.component";
import { BuyersMassiveEvaluationComponent } from "./risks-processes-charges/buyers-massive-evaluation/buyers-massive-evaluation.component";
import { QuotaAdjustmentComponent } from "./risks-processes-charges/quota-adjustment/quota-adjustment.component";
import { RestrictiveListsComponent } from "./risks-processes-charges/restrictive-lists/restrictive-lists.component";
import { RisksBuyerQuotasComponent } from "./risks-processes-charges/risks-buyer-quotas/risks-buyer-quotas.component";
import { RisksCoveringComponent } from "./risks-processes-charges/risks-covering/risks-covering.component";
import { RisksHabeasDataComponent } from "./risks-processes-charges/risks-habeas-data/risks-habeas-data.component";
import { StateChangeComponent } from "./risks-processes-charges/state-change/state-change.component";
import { QuotaCalificationsComponent } from "./risks-processes-charges/quota-califications/quota-califications.component";
import { CoviResourceComponent } from "./get-quota/covi-resource/covi-resource.component";
import { RelatedDocumentsComponent } from "./get-quota/quota-vs-affiliate/related-documents/related-documents.component";
import { FauDigitalComponent } from "@pages/fau-digital/fau-digital.component";
import { BulkUploadQuearyComponent } from "./get-quota/transactions/bulk-upload-queary/bulk-upload-queary.component";

import { BuyerReportComponent } from './buyer-report/buyer-report.component';
import { DeductibleComponent } from './backups/deductible/deductible.component';
import { ParamsAffiliateComponent } from './net-factoring/params-affiliate/params-affiliate.component';

import { ExpressReportComponent } from './express-report/express-report.component';
import { ExcessRiskComponent } from './excess-risk/excess-risk.component';
import { InvoicesStatusComponent } from './net-factoring/invoices-status/invoices-status.component';
import { ReportAffiliateComponent } from './affiliate-buyer/components/report-affiliate/report-affiliate.component';
import { ReportBuyerComponent } from './report-buyer/report-buyer.component';
import { FinancialInvoicesComponent } from './net-factoring/financial-invoices/financial-invoices.component';
import { FilingsComponent } from './quota-radication/filings/filings.component';
import { RadicationComponent } from './quota-radication/radication/radication.component';
import { LocalizationCustomerComponent } from './get-quota/transactions/localization-customer/localization-customer.component';
import { NetsuiteInvoicesComponent } from './backups/netsuite-invoices/netsuite-invoices.component';
import { BackingInvoicesComponent } from './backups/backings-invoices/backing-invoices.component';
import { AdminCovifacturaUsersComponent } from './admin-covifactura-users/admin-covifactura-users.component';


import { BagsManagerComponent } from "./affiliates/bags-manager/bags-manager.component";
import { BillingTrafficLightComponent } from "./affiliates/billing-traffic-light/billing-traffic-light.component";
import { GlobalBillingComponent } from "./affiliates/global-billing/global-billing.component";
import { FormComponent } from "./massive-charge/form/form.component";
import { CreditInfoComponent } from "./credit-info/credit-info.component";
import { ExperianComponent } from "./credit-info/experian/experian.component";
import { CreditHistoryComponent } from "./credit-info/experian/credit-history/credit-history.component";
import { LegalPerson } from "./quotas/models/legalPerson.model";
import { RecognizeComponent } from "./credit-info/experian/recognize/recognize.component";
import { LegalPersonComponent } from "./credit-info/experian/legal-person/legal-person.component";
import { CreditStudyComponent } from "./affiliates/credit-study/credit-study.component";

const routes: Routes = [
  {
    path: "test",
    component: RequestAdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cupo",
    component: QuotasComponent,
  },

  {
    path: "pages",
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'carga-masiva',
        component: FormComponent
      },
      {
        path: "transacciones",
        component: TransactionsComponent,
        children: [
          {
            path: "tres-y-seis-pagos",
            component: TransactionsThreeAndSixPaymentsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "admin-usuarios-covifactura",
        component: AdminCovifacturaUsersComponent,
      },
      {
        path: "contrato",
        component: BackupsComponent,
        children: [
          { path: "", component: ConsultIndexComponent },
          { path: "stepper", component: StepperContractComponent },
        ],
      },
      {
        path: "cargue-masivo-consultas",
        component: BulkUploadQuearyComponent,
      },
      {
        path: "estudio-de-cliente",
        component: ClientStudyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "afiliados",
        component: AffiliatesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: AffiliatesSearchComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "afiliados/:documentType/:document",
            component: AffiliatesTabsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "link-afiliado",
            component: SetupAffiliateLinkComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "cupos",
        component: GetQuotaComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: QuotaCreateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "administrador-cupos",
            component: AdminQuotaComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "historial-cupos",
            component: QuotaVsAffiliateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "activacion-cupos",
            component: ActivateQuotaComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "activacion-cupos-sin-contrato",
            component: QuotaWithoutContract,
            canActivate: [AuthGuard],
          },
          {
            path: "formulario-20-m",
            component: QuotaCreateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "reservas",
            component: ReservesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "recursos-cupos",
            component: CoviResourceComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "transacciones-cupos",
        component: QuotaTransactionsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: NonPaymentNoticeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "transaction-reports",
            component: PromisoryNotes,
            canActivate: [AuthGuard],
          },
          {
            path: "title-modify",
            component: TitleModifyComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "non-payment-notice",
            component: NonPaymentNoticeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "tres-y-seis-pagos",
            component: TransactionsReportsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "ingreso-transacciones",
            component: EntryTransactionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "pagares",
            component: TransactionPromisoryNoteComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "bloqueos",
            component: BlocksUnlocksComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "localizacion-compradores",
            component: LocalizationCustomerComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "reporte-express",
        component: ExpressReportComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: NonPaymentNoticeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "transaction-reports",
            component: PromisoryNotes,
            canActivate: [AuthGuard],
          },
          {
            path: "title-modify",
            component: TitleModifyComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "non-payment-notice",
            component: NonPaymentNoticeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "tres-y-seis-pagos",
            component: TransactionsReportsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "ingreso-transacciones",
            component: EntryTransactionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "pagares",
            component: TransactionPromisoryNoteComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "bloqueos",
            component: BlocksUnlocksComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      //credit info
      {
        path: "experian",
        component: ExperianComponent,
        children: [
          { path: "", component: RecognizeComponent },
          { path: "reconocer", component: RecognizeComponent },
          { path: "historia-de-credito", component: CreditHistoryComponent },
          { path: "personas-juridicas", component: LegalPersonComponent },
        ],
      },

      {
        path: "excesos-riesgo",
        component: ExcessRiskComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: RequestManagmentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "admin-gestion",
            component: RequestAdminComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "gestion-solicitud",
        component: QuotaRadicationComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: RequestManagmentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "admin-gestion",
            component: RequestAdminComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "radicaciones",
            component: FilingsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "radicado",
            component: RadicationComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      // {
      //   path: 'consultar-cupo',
      //   component: GetQuotaComponent,
      //   canActivate: [AuthGuard],
      // },
      {
        path: "compradores",
        component: BuyersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "operaciones",
        component: OperationsComponent,
        children: [
          {
            path: "",
            component: OperationsTabsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      //
      {
        path: "respaldos",
        component: BackupsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: BackupsTabsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "reporte",
            component: BackupsReportTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "transacciones-respaldos",
            component: BackupsTransactionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "marcar-avis-de-no-pago",
            component: MarkNonPaymentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "auditorias-respaldos",
            component: BackupsAuditComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "cambio-masivo-estados",
            component: MassiveChangeStatusComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "fau-digital",
            component: FauDigitalComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "facturas",
            component: BackingInvoicesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "deducibles",
            component: DeductibleComponent,
            canActivate: [AuthGuard],
          },
          // { path: 'afiliado/:documentType/:document', component: BackupsTabsComponent, canActivate: [AuthGuard] },
        ],
      },
      {
        path: "productos",
        component: BackupsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", component: ProductsComponent, canActivate: [AuthGuard] },
          // { path: 'afiliado/:documentType/:document', component: BackupsTabsComponent, canActivate: [AuthGuard] },
        ],
      },
      //
      {
        path: "administracion",
        component: AdministrationComponent,
        children: [
          {
            path: "mis-pagos",
            component: MyPaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "documentos",
            component: DocumentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "perfil",
            component: ProfileComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "netFactoring",
        component: NetFactoringComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: NetFactoringRouterComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "params-affiliate",
            component: ParamsAffiliateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "invoices-status",
            component: InvoicesStatusComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "financial-invoices",
            component: FinancialInvoicesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "cargues-proceso-de-riesgo",
        component: RisksProcessesChargesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: MassQuotaLoadComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "affiliate-policies",
            component: AffiliatePoliciesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "affiliate-risks",
            component: AffiliateRisksComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "buyers-massive-evaluation",
            component: BuyersMassiveEvaluationComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "quota-adjustment",
            component: QuotaAdjustmentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "restrictive-lists",
            component: RestrictiveListsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "risks-buyer-quotas",
            component: RisksBuyerQuotasComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "risks-covering",
            component: RisksCoveringComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "risks-habeas-data",
            component: RisksHabeasDataComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "risks-state-change",
            component: StateChangeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "buyers-qualification",
            component: QuotaCalificationsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "related-documents",
        component: RelatedDocumentsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "informacion-comprador",
        component: BuyerReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reporte-express",
        component: ExpressReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "excesos-riesgo",
        component: ExcessRiskComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reporte-afiliado",
        canActivate: [AuthGuard],
        component: ReportAffiliateComponent,
      },
      {
        path: "reporte-comprador",
        component: ReportBuyerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "facturacion-global",
        component: BackupsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: GlobalBillingComponent,
            canActivate: [AuthGuard],
          }
        ],
      },
      {
        path: "facturas-netsuite",
        component: NetsuiteInvoicesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "credit-study",
        component: CreditStudyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "administrador-bolsa",
        component: BackupsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: BagsManagerComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "semaforo-bolsa",
            component: BillingTrafficLightComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      { path: "**", pathMatch: "full", redirectTo: "/not-found" },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
