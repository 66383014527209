import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { CurrentDebtSector } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-current-debt-history',
  templateUrl: './current-debt-history.component.html',
  styleUrls: ['./current-debt-history.component.scss']
})
export class CurrentDebtHistoryComponent implements OnInit, OnDestroy {

  public sectoresFromCurrentDebt: CurrentDebtSector[] = [];
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.sectoresFromCurrentDebt = response.sectoresFromCurrentDebt;
  }

}
