import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestType'
})
export class RequestTypePipe implements PipeTransform {

  transform(value: any): any {
    let requestTypeList: any = JSON.parse(localStorage.getItem('form20m_requestTypeList'));
    
    let requestTypeDescription: string;
    if(requestTypeList) {
      requestTypeList.forEach(item => {
        if(item.idReqType === value) {
          requestTypeDescription = item.description
        }
      })
      return requestTypeDescription;
    }
  }
}
