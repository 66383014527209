import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'experianStateDocument'
})
export class ExperianStateDocumentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const experianLists = localStorage.getItem('EXPERIAN_LISTS');
    if (experianLists) {
      const data = JSON.parse(experianLists);
      const vigenciaCedula = data[0]['vigenciaCedula'].find((item: any) => item.codigoEstado === value);
      return vigenciaCedula ? vigenciaCedula.descripcion : 'Código no encontrado';
    }
    return null;
  }

}
