import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class MockService {

  public reservationList: any = {
    ok: true,
    message: "Finalizó correctamente la consulta.",
    data: [
      {
        id: 44432,
        purchaseOrder: 48653,
        date: "2021-09-24 20:18:11",
        contract: 521764,
        queryCode: 870501,
        dcoumentType: "N",
        document: 9002193033,
        value: 480000,
        requestUser: "COVIWEB",
        authorization: 569534,
        dateOfUse: null,
        documentNo: null,
        valueOfUse: null,
        dueDate: null,
        userOfUse: null,
        exonerated: null,
        bookingDue: "2021-09-29 20:18:11",
        state: "VENCIDA",
        bookingDays: 12
      },
      {
        id: 45658,
        purchaseOrder: 48346,
        date: "2021-09-22 19:22:52",
        contract: 521764,
        queryCode: 870501,
        dcoumentType: "N",
        document: 9002193033,
        value: 40000,
        requestUser: "COVIWEB",
        authorization: 524578,
        dateOfUse: "2021-09-23 21:46:38",
        documentNo: "64084",
        valueOfUse: 36626,
        dueDate: "2021-11-22 05:00:00",
        userOfUse: "COVIWEB",
        exonerated: null,
        bookingDue: "2021-09-27 19:22:52",
        state: "VIGENTE",
        bookingDays: 5
      },
    ],
    status: 200,
    totalPages: 12
  };

}
