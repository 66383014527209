import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obligationBehavior'
})
export class ObligationBehaviorPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (!value || value.length < 3) {
      return value;
    }

    const regex = /.{1,12}/g;
    const groups = value.match(regex) || [];

    return groups.map(group => `[${group}]`).join(' ');
  }

}
