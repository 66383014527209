import { Component, OnInit } from '@angular/core';
import { IQueryHistory, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-history-queries-history',
  templateUrl: './history-queries-history.component.html',
  styleUrls: ['./history-queries-history.component.scss']
})
export class HistoryQueriesHistoryComponent implements OnInit {

  public headersTable: string[] = ['Fecha última Consulta', 'Consultante', '# de Consultas mes'];
  public queriesHistory: IQueryHistory[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.queriesHistory = this.subjectCreditHistory.getQueriesHistory(response);
  }

}
