
// Fase 2 Ok fm
export class CreateBackingsRequest {
    // affiliateDocumentType:  string;
    // affiliateDocument:      string;
    user:                   string;
    transactionsToBackUp:   CreateBackingsRequestModel[];
}

// export class CreateBackingsRequestModel {
//     issuerDocumentType: string = '';
//     issuerDocument:     string = '';
//     authorization:      number =  0;
//     draftNumber:        string = '';
//     backingValue:       number =  0;
//     limitBackingDate:   string = '';
//     statusBacking:      string = '';
//     guaranteedValue:    number =  0;
//     queryCode:          number =  0;
//     branchOffice:       string = '';
//     product:            string = '';
// }


export class CreateBackingsRequestModel {
    affiliateDocumentType: string = '';
    affiliateDocument:     string = '';
    issuerDocumentType:    string = '';
    issuerDocument:        string = '';
    authorization:          number = 0;
    draftNumber:           string = '';
    backingValue:           number = 0;
    limitBackingDate:      string = '';
    statusBacking:         string = '';
    queryCode:              number = 0;
    guaranteedValue:        number = 0;
    branchOffice:          string = '';
    sinisterProduct:       string = '';
    contract:               number = 0;
    draftDate:              string = '';
    draftsNumber:           number = 0;
    contractProduct:        string = '';
    dueDate:                string = '';
    bankCode:               number = 0;
    account:                string = '';
    cellphone?:             number = 0;
}
