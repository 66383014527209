/* import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RulesCreatedService } from '@app/pages/affiliates/rules-created/rules-created.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-rules-create',
  templateUrl: './modal-rules-create.component.html',
  styleUrls: ['./modal-rules-create.component.scss'],
})
export class ModalRulesCreateComponent implements OnInit {
  public rulesCreateForm: FormGroup;
  public title = 'Editar bolsa';
  public registerReceived: any = null;
  public identity: any;
  public user: string = '';

  public monthsDurationBag: number = 0;
  public periods: number[] = []; // Arreglo para las cuotas

  // Opciones fijas para periodicidad
  public periodicidadOptions = [
    { value: 'MENSUAL', label: 'Mensual' },
    { value: 'BIMENSUAL', label: 'Bimensual' },
    { value: 'TRIMESTRAL', label: 'Trimestral' },
    { value: 'SEMESTRAL', label: 'Semestral' },
    { value: 'ANUAL', label: 'Anual' }
  ];

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    public helper: HelperService,
    public backupsHeaders: BackupsTableHeadersService,
    public getRegister: RulesCreatedService,
    public sendModifyRegister: RulesCreatedService,
    public modifyBag: GloballBillingService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getRegister.sendDataEvent.subscribe((event) => {
      this.registerReceived = event;
      this.monthsDurationBag = this.registerReceived.meses_duracion_bolsa;
      console.log('Registro recibido', this.registerReceived);
      this.setData();
      // Calcular las cuotas iniciales según la periodicidad que viene del servicio
      this.onCalculate();
    });
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
  }

  createForm() {
    this.rulesCreateForm = this.fb.group({
      value: [0, Validators.required],
      formattedComision: [0, Validators.required],
      periodicidad: [null, Validators.required],
      cuotas: [null, Validators.required],
    });
  }

  setData() {
    console.log('Set data');
    this.f.value.setValue(this.registerReceived.valor_bolsa/1000000);
    this.f.formattedComision.setValue(this.registerReceived.p_comision);
    this.f.periodicidad.setValue(this.registerReceived.periodicidad_cuotas);
    this.f.cuotas.setValue(this.registerReceived.no_cuotas_facturar);
  }

  get f() {
    return this.rulesCreateForm.controls;
  }

  closeModal() {
    this.backupsHeaders.setLoad(true);
    this.modal.close();
  }

  onSubmit() {
    if (this.registerReceived.editar) {
      this.createOtherBag();
    } else {
      this.updateBag();
    }
  }

  createOtherBag() {
    const body = {
      id: this.registerReceived.id,
      estado_anterior: this.registerReceived.estado_anterior,
      estado_nuevo: 'RELIQUIDAR',
      usuario: this.user,
      condiciones: this.mapperOtherBill(),
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-update').subscribe(
      (resp) => {
        Swal.fire({
          title: 'Se creó la bolsa',
          text: resp.message,
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Error en la creación de la Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.closeModal();
  }

  updateBag() {
    const body = {
      id: this.registerReceived.id,
      valor_bolsa: this.f.value.value *1000000,
      p_comision: this.f.formattedComision.value,
      periodicidad_cuotas: this.f.periodicidad.value,
      no_cuotas_facturar: this.f.cuotas.value,
      no_cuotas_amortizar: this.f.cuotas.value,
      usuario_act: this.user,
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-general-update').subscribe(
      () => {
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: 'La bolsa fue actualizada con éxito',
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.modal.close();
  }

  mapperOtherBill() {
    return `${this.f.value.value};${this.f.formattedComision.value};${this.f.cuotas.value}`;
  }

  calculatePeriods(): number[] {
    const period = this.f.periodicidad.value;
    let divisor = 1;
    switch (period) {
      case 'MENSUAL':
        divisor = 1;
        break;
      case 'BIMENSUAL':
        divisor = 2;
        break;
      case 'TRIMESTRAL':
        divisor = 3;
        break;
      case 'SEMESTRAL':
        divisor = 6;
        break;
      case 'ANUAL':
        return this.generateIntervals(Math.floor(this.monthsDurationBag / 12), 1);
      default:
        divisor = 1;
    }
    return this.generateIntervals(this.monthsDurationBag, divisor);
  }

  generateIntervals(total: number, divisor: number): number[] {
    const numOfIntervals = Math.floor(total / divisor);
    let intervals = [];
    for (let i = 1; i <= numOfIntervals; i++) {
      intervals.push(i);
    }
    return intervals;
  }


  onCalculate() {
    this.periods = this.calculatePeriods();
    if (!this.periods.includes(Number(this.f.cuotas.value))) {
      this.f.cuotas.setValue(null);
    }
  }
}
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RulesCreatedService } from '@app/pages/affiliates/rules-created/rules-created.service';
import { BackupsTableHeadersService } from '@app/pages/backups/services/backups-table-headers.service';
import { GloballBillingService } from '@app/services/globall-billing.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-rules-create',
  templateUrl: './modal-rules-create.component.html',
  styleUrls: ['./modal-rules-create.component.scss'],
})
export class ModalRulesCreateComponent implements OnInit {
  public rulesCreateForm: FormGroup;
  public title = 'Editar bolsa';
  public registerReceived: any = null;
  public identity: any;
  public user: string = '';

  public monthsDurationBag: number = 0;
  public periods: number[] = []; // Arreglo para las cuotas

  // Opciones fijas para periodicidad
  public periodicidadOptions = [
    { value: 'MENSUAL', label: 'Mensual' },
    { value: 'BIMENSUAL', label: 'Bimensual' },
    { value: 'TRIMESTRAL', label: 'Trimestral' },
    { value: 'CUATRIMESTRAL', label: 'Cuatrimestral' },
    { value: 'SEMESTRAL', label: 'Semestral' },
    { value: 'ANUAL', label: 'Anual' },
  ];

  constructor(
    private fb: FormBuilder,
    public modal: ModalService,
    public helper: HelperService,
    public backupsHeaders: BackupsTableHeadersService,
    public getRegister: RulesCreatedService,
    public sendModifyRegister: RulesCreatedService,
    public modifyBag: GloballBillingService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getRegister.sendDataEvent.subscribe((event) => {
      this.registerReceived = event;
      this.monthsDurationBag = this.registerReceived.meses_duracion_bolsa;
      console.log('Registro recibido', this.registerReceived);
      this.setData();
      this.onCalculate();
    });
    this.identity = JSON.parse(localStorage.getItem('user'));
    this.user = this.identity.user;
  }

  createForm() {
    this.rulesCreateForm = this.fb.group({
      value: [0, Validators.required],
      formattedComision: [0, Validators.required],
      periodicidad: [null, Validators.required],
      cuotas: [null, Validators.required],
    });
  }

  setData() {
    console.log('Set data');
    this.f.value.setValue(this.registerReceived.valor_bolsa / 1000000);
    this.f.formattedComision.setValue(this.registerReceived.p_comision);
    this.f.periodicidad.setValue(this.registerReceived.periodicidad_cuotas);
    this.f.cuotas.setValue(this.registerReceived.no_cuotas_facturar);
  }

  get f() {
    return this.rulesCreateForm.controls;
  }
  get availablePeriodicidadOptions() {
    return this.periodicidadOptions.filter((opt) => {
      if (opt.value === 'ANUAL' && this.monthsDurationBag < 12) {
        return false;
      }
      return true;
    });
  }

  closeModal() {
    this.backupsHeaders.setLoad(true);
    this.modal.close();
  }

  onSubmit() {
    if (this.registerReceived.editar) {
      this.createOtherBag();
    } else {
      this.updateBag();
    }
  }

  createOtherBag() {
    const body = {
      id: this.registerReceived.id,
      estado_anterior: this.registerReceived.estado_anterior,
      estado_nuevo: 'RELIQUIDAR',
      usuario: this.user,
      condiciones: this.mapperOtherBill(),
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-update').subscribe(
      (resp) => {
        Swal.fire({
          title: 'Se creó la bolsa',
          text: resp.message,
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Error en la creación de la Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.closeModal();
  }

  updateBag() {
    const body = {
      id: this.registerReceived.id,
      valor_bolsa: this.f.value.value * 1000000,
      p_comision: this.f.formattedComision.value,
      periodicidad_cuotas: this.f.periodicidad.value,
      no_cuotas_facturar: this.f.cuotas.value,
      no_cuotas_amortizar: this.f.cuotas.value,
      usuario_act: this.user,
    };
    console.log('This body', body);
    this.modifyBag.patchEditBag(body, 'bag-general-update').subscribe(
      () => {
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: 'La bolsa fue actualizada con éxito',
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.backupsHeaders.setLoad(true);
      },
      (error) => {
        Swal.fire({
          title: 'Actualización de Bolsa',
          text: error.error.message,
          type: 'error',
          confirmButtonText: 'OK',
        });
      }
    );

    this.modal.close();
  }

  mapperOtherBill() {
    return `${this.f.value.value};${this.f.formattedComision.value};${this.f.cuotas.value}`;
  }

  calculatePeriods(): number[] {
    const period = this.f.periodicidad.value;
    let divisor = 1;
    switch (period) {
      case 'MENSUAL':
        divisor = 1;
        break;
      case 'BIMENSUAL':
        divisor = 2;
        break;
      case 'TRIMESTRAL':
        divisor = 3;
        break;
      case 'CUATRIMESTRAL':
        divisor = 4;
        break;
      case 'SEMESTRAL':
        divisor = 6;
        break;
      case 'ANUAL':
        return this.generateIntervals(
          Math.floor(this.monthsDurationBag / 12),
          1
        );
      default:
        divisor = 1;
    }
    return this.generateIntervals(this.monthsDurationBag, divisor);
  }

  generateIntervals(total: number, divisor: number): number[] {
    const numOfIntervals = Math.floor(total / divisor);
    let intervals = [];
    for (let i = 1; i <= numOfIntervals; i++) {
      intervals.push(i);
    }
    return intervals;
  }

  onCalculate() {
    this.periods = this.calculatePeriods();
    const currentCuota = Number(this.f.cuotas.value);
    if (!this.periods.includes(currentCuota)) {
      this.f.cuotas.setValue(this.periods.length > 0 ? this.periods[0] : null);
    }
  }
}
