export class Claims {
    static headersCv = [
        {
            name: 'Tipo Documento'
        },
        {
            name: 'Documento'
        },
        {
            name: 'Usuario'
        },
        {
            name: 'Nombre Archivo'
        },
        {
            name: 'Fecha'
        },
        {
            name: 'Categoria'
        },
        {
            name: 'visualizar'
        }
    
    ]
}

export class ClaimsFile {
    static headersFile = [
        {
            name: 'Tipo Documento'
        },
        {
            name: 'Documento'
        },
        {
            name: 'Fuente'
        },
        {
            name: 'Usuario'
        },
        {
            name: 'Nombre Archivo'
        },
        {
            name: 'Fecha'
        },
        {
            name: 'Categoria'
        },
        {
            name: 'visualizar'
        }
    ]
}

export class SourcesData {
    static sources = [
        {
            name: 'AFILIADO'
        },
        {
            name: 'COMPRADOR'
        },
        {
            name: 'FAU DIGITAL'
        },
        {
            name: 'RECLAMACIONES'
        },
        {
            name: 'SOLICITUD DE INFORMACION DEL COMPRADOR'
        }
    ]
}

export class dataFilter {
    
    documentType: string;
    document: number;
    images = []
}

export class DataSearchMultipleFiles {
    id = null;
    source = null;
    user = null;
    documentType: string;
    document: number;
    originalName = null;
    creationDate = null;
    category = null;
    infoFile = null;
    originalPath = null;
    originalFinalName = null;
    resourceId = null
    currentPage: number;
    totalPages = 10;

}

export class FileData {
    fileName: string;
    fileExtension: string;
    image: string;
}

export class AssignationFileData {
    document: number;
    documentType: string;
    uploads: FileData[]
}

export class SendFilesMultiples {
    document: number;
    documentType: string;
    userName: string;
    fileName: string;
    fileExtension: string;
    category: string;
    image: any;
    source: string;
}

export class ImagesFiles {
    images: SendFilesMultiples[];
}
