import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '@models/user.model';
import { IndividualOperationRequest } from '@models/request/individualOperationRequest.model';
import { OperationItem } from '@models/operationItem.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient,
    private ciCd: CiCdConfigService) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
     this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, urlApi} = apis
      this.urlApi = urlApi;
       console.log('config ci cd', apiUrl);

    });
  }

  readToken() {
    if (localStorage.getItem('tokenASR')) {
      this.userToken = localStorage.getItem('tokenASR');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getOperationList(individualOperationRequest: IndividualOperationRequest) {
    individualOperationRequest.documentNumber = this.identity.userInfo.document;
    individualOperationRequest.documentType = this.identity.userInfo.documentType;
    console.log(individualOperationRequest);

    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: IndividualOperationRequest.toRequest(individualOperationRequest),
      headers,
    };
    return this.http
      .get(`${this.urlApi}/pr_operaciones_individuales`, options)
      .pipe(
        map((resp: any) => {
          const operations: Array<OperationItem> = resp.OPERACIONES_INDIVIDUALES
            ? resp.OPERACIONES_INDIVIDUALES.map((operationItem) =>
                OperationItem.fromResponse(operationItem)
              )
            : [];
          return operations;
        })
      );
  }
}
