import { Component, OnInit } from '@angular/core';
import { CurrentDebt, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-current-debt-information-history',
  templateUrl: './current-debt-information-history.component.html',
  styleUrls: ['./current-debt-information-history.component.scss']
})
export class CurrentDebtInformationHistoryComponent implements OnInit {

  public currentDebtInformation: CurrentDebt | undefined = undefined;

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.currentDebtInformation = this.subjectCreditHistory.getCurrentDebtInformation(response);    
  }
}
