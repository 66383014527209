
const RECOGNIZE_GENRE: { respuesta: number; genero: string; descripcion: string }[] = [
  { respuesta: 1, genero: 'F', descripcion: 'Mujer Casada' },
  { respuesta: 2, genero: 'F', descripcion: 'Mujer Viuda' },
  { respuesta: 3, genero: 'F', descripcion: 'Mujer Soltera' },
  { respuesta: 4, genero: 'M', descripcion: 'Hombre Soltero' },
  { respuesta: 9, genero: '', descripcion: 'No Informado' }
];

export default RECOGNIZE_GENRE;
