import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { NetsuiteInvoicesGetIntegration3PkgRequest, NetsuiteInvoicesRequest } from '../models/request/netsuite-invoices-request.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetsuiteInvoicesService {

  public nodeDevApiUrl: string;
  public tokenBasicGlobalBilling: string;
  public invoiceSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public invoiceSelected$ = this.invoiceSelected.asObservable();

  constructor(
    private http: HttpClient,
    private ciCd: CiCdConfigService
    ) {
    this.nodeDevApiUrl = environment.devNodeApiUrl;
    this.tokenBasicGlobalBilling = environment.tokenBasicGlobalBilling;
    this.ciCdInit();
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {devNodeApiUrl, tokenBasicGlobalBilling } = apis;
      this.nodeDevApiUrl = devNodeApiUrl;
      this.tokenBasicGlobalBilling = tokenBasicGlobalBilling;
      console.log(tokenBasicGlobalBilling)
    });
  }

  changeInvoiceSelected(invoice: any) {
    this.invoiceSelected.next(invoice);
  }

  getAffiliateByDocument(affiliateRequest: NetsuiteInvoicesRequest) {
    let headers = new HttpHeaders();

    let params = new HttpParams().set("fechaProceso", affiliateRequest.fechaProceso);

    headers = headers.set(
      "Authorization",
      "Basic " + this.tokenBasicGlobalBilling
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.nodeDevApiUrl}/BackendFacturacion/api/v1/invoices?`, options);
  }

  getIntegration3Pkg(paramsRequest: NetsuiteInvoicesGetIntegration3PkgRequest) {
    let headers = new HttpHeaders();

    let params = new HttpParams().set("fecha", paramsRequest.fecha);

    headers = headers.set(
      "Authorization",
      "Basic " + this.tokenBasicGlobalBilling
    );

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.nodeDevApiUrl}/BackendFacturacion/api/v1/callPkgIntegracion3?`, options);
  }

  getInvoiceRejection(factura: string): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams().set('factura', factura);

    headers = headers.set('Authorization', 'Basic ' + this.tokenBasicGlobalBilling);

    const options = {
      headers,
      params,
    };

    return this.http.get(`${this.nodeDevApiUrl}/BackendFacturacion/api/v1/invoices/rejection`, options);
  }


}
