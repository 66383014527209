import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransactionsHeadersService } from '@app/pages/get-quota/services/transactions-headers.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transaction-list-by-authorization',
  templateUrl: './transaction-list-by-authorization.component.html',
  styleUrls: ['./transaction-list-by-authorization.component.scss']
})
export class TransactionListByAuthorizationComponent implements OnInit {

  @ViewChild('formAccount') formAccount: NgForm;

  public headers: Array<string>;

  public accounts: Array<any>;

  public docTypes;

  public currentPage: number = 1;

  public totalPages: number = 1;

  public isBlock: boolean = false;

  public isOtherReason: boolean = false;

  public selectList: Array<boolean> = [];

  public blockOption: string = 'Bloquear';

  public accountSelected: any;

  public isQuerySuccess: boolean = false;

  public form = {
    authorization: '',
  }

  public reasonList: Array<any>;

  public blockAccountOptions = [
    {
      description: 'Bloquear',
      value: 'L'
    },
    {
      description: 'Desbloquear',
      value: 'U'
    },
  ]

  constructor(private quotaGreater: QuotaGreaterService, private listCovinoc: ListsCovinocService, public helper: HelperService, private tableHeaders: TransactionsHeadersService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.listCovinoc.findAllLists().subscribe((response: any) => {
      this.docTypes = response.data[0].documentTypesCode;
    });
    this.quotaGreater.exonerationLists().subscribe((response: any) => {
      this.reasonList = response.data[0].locksReason;
    })
    this.headers = this.tableHeaders.tableHeadersTitlesByAuthorization
  }

  selectAccount(id: number) {
    this.accountSelected = this.selectList[id];
    this.selectList[id] = true;
  }

  searchAccounts() {
    console.log(this.form.authorization);
    
    this.spinner.show();
    const body = {
      authorization: this.form.authorization,
      currentPage: this.currentPage,
      perPage: 10
    }

    this.quotaGreater.titlesByAuthorization(body).subscribe((response: any) => {
      this.spinner.hide();
      this.accounts = response.data[0];
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.isQuerySuccess = true;
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      console.error(error)
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  goToPage($event) {
    this.spinner.show();

    const body = {
      authorization: this.form.authorization,
      currentPage: $event.currentPage,
      perPage: 10
    }

    this.quotaGreater.titlesByAuthorization(body).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.accounts = response.data[0];
      this.currentPage = response.currentPage
      this.totalPages = response.totalPages
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      console.log(error)
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

}
