import { NgForm } from '@angular/forms';
import { ChangePasswordRequest } from './../../../models/changePassword.model';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-profile-change-password',
  templateUrl: './modal-profile-change-password.component.html',
  styleUrls: ['./modal-profile-change-password.component.scss']
})
export class ModalProfileChangePasswordComponent implements OnDestroy {

  @ViewChild('formChangePass') formChangePass: NgForm;
  public changePassRequest: ChangePasswordRequest = new ChangePasswordRequest();
  public message = `La contraseña debe ser mínimo de 8 caracteres y 
                    tener al menos una letra minúscula, una mayúscula y un número`;
  public message2 = `Las contraseñas no coinciden.`;

  @Output() confirm: EventEmitter<object> = new EventEmitter<object>();
  @Output() onSave: EventEmitter<ChangePasswordRequest> = new EventEmitter<ChangePasswordRequest>();
  public continue: boolean = false;
  public passAreEquals: boolean = false;

  constructor(public modal: ModalService) { }

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }

  passwordIsEqual() {
    const pass1 = this.changePassRequest.confirmNewPass;
    const pass2 = this.changePassRequest.newPass;
    const pass1Isvalid = this.formChangePass.form.controls['newPass'].valid;
    const pass2IsValid = this.formChangePass.form.controls['confirmNewPass'].valid;

    if(!pass1Isvalid && !pass2IsValid) {
      this.continue = false;
      return;
    }
    
    if (pass1 === pass2 && this.formChangePass.form.valid) {
      this.continue = true;
      this.passAreEquals = true;
    } else if(pass1 !== pass2 && this.formChangePass.form.valid) {
      this.continue = false;
      this.passAreEquals = false;
    } else {
      this.continue = false;
    }
  }

  changePass() {
    this.modal.close();
    this.onSave.emit(this.changePassRequest);
    this.formChangePass.reset();
  }

}
