import { Component, OnInit } from '@angular/core';
import { DisagreementWithInformation, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-disagreements-with-information-history',
  templateUrl: './disagreements-with-information-history.component.html',
  styleUrls: ['./disagreements-with-information-history.component.scss']
})
export class DisagreementsWithInformationHistoryComponent implements OnInit {

  public headersTable: string[] = ['Desacuerdo', 'No. Desacuerdo', 'Estado', 'Fecha', 'Entidad'];
  public disagreements: DisagreementWithInformation[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.disagreements = this.subjectCreditHistory.getDisagreementWithInformation(response);
  }

}
