import { RolByIdPipe } from './../pipes/rol-by-id.pipe';
import { NullDateReplacerPipe } from './../pipes/null-date-replacer.pipe';
import { FallbackImgDirective } from './../directives/fallback-img.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentTypePipe } from '../pipes/document-type.pipe';
import { BgUrlPipe } from '../pipes/bg-url.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AlertColorDirective } from '../directives/alert-color.directive';
import { OutsideDirective } from '../directives/outside.directive';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { ReplacePipe } from '../pipes/replace.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { SumPipe } from '../pipes/sum.pipe';
import { PercentagePipe } from '../pipes/percentage.pipe';
import { ThousandsPipe } from '../pipes/thousands.pipe';
import { DescriptionSelectModelPipe } from '@app/pipes/description-select-model.pipe';
import { CutLargeTextPipe } from '@app/pipes/cut-large-text.pipe';
import { TooltipDirective } from '../directives/tooltip.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { StateNamePipe } from '@app/pipes/state-name.pipe';
import { FormatDatePipe } from '@app/pipes/format-date.pipe';

/*DatePicker*/
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { LOCALE_ID } from '@angular/core';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentStateDirective } from '../directives/payment-state.directive';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TableEmptyStateComponent } from './components/table-empty-state/table-empty-state.component';
import { TabsComponent } from './tabs/tabs.component';

import { PaginationComponent } from '@app/components/layout/pagination/pagination.component';

import { AddPipe } from '../pipes/add.pipe';
import { TransactionStatusDirective } from '../directives/transaction-status.directive';
import { ProductTypeDescriptionPipe } from '@app/pipes/product-type-description.pipe';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";

import { DropDragDirective } from '@app/directives/drop-drag.directive';
//  Redux
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from '@app/redux/app.reducer';
import { effectsArray } from '@app/redux/effects/index.effects';

// stepper
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { IndicativeNum } from '@app/pipes/indicative-num.pipe';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left', allowNegative: true, decimal: ',', precision: 0, prefix: '$ ', suffix: '', thousands: '.'
};
registerLocaleData(localeEsCO, 'es-Co');

export class DefaultIntl extends OwlDateTimeIntl {
  rangeFromLabel = 'Fecha inicial';
  rangeToLabel = 'Fecha final';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
  prevMultiYearLabel = '21 años atrás';
  nextMultiYearLabel = 'Próximos 21 años';
}


@NgModule({
  declarations: [
    DocumentTypePipe,
    BgUrlPipe,
    RolByIdPipe,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    FallbackImgDirective,
    ReplacePipe,
    FilterPipe,
    SumPipe,
    AddPipe,
    IndicativeNum,
    PercentagePipe,
    ThousandsPipe,
    DescriptionSelectModelPipe,
    CutLargeTextPipe,
    TooltipDirective,
    TransactionStatusDirective,
    PaymentStateDirective,
    TableEmptyStateComponent,
    TabsComponent,
    PaginationComponent,
    NullDateReplacerPipe,
    ProductTypeDescriptionPipe,
    DropDragDirective,
    StateNamePipe,
    FormatDatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CurrencyMaskModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CdkStepperModule,
    NgStepperModule,
    UiSwitchModule.forRoot({
      // size: 'small',
      color: 'rgb(61, 160, 48)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(151, 151, 151)',
      defaultBoColor: '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No'
    }),
    NgxDropzoneModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(effectsArray),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production, 
    })
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: LOCALE_ID, useValue: 'es-Co' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  exports: [
    DocumentTypePipe,
    RolByIdPipe,
    BgUrlPipe,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ImageCropperModule,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    FallbackImgDirective,
    ReplacePipe,
    UiSwitchModule,
    FilterPipe,
    AddPipe,
    SumPipe,
    PercentagePipe,
    ThousandsPipe,
    DescriptionSelectModelPipe,
    CutLargeTextPipe,
    TooltipDirective,
    TransactionStatusDirective,
    PaymentStateDirective,
    NgxDropzoneModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxExtendedPdfViewerModule,
    TableEmptyStateComponent,
    PaginationComponent,
    NullDateReplacerPipe,
    ProductTypeDescriptionPipe,
    CurrencyMaskModule,
    DropDragDirective,
    StateNamePipe,
    FormatDatePipe,    
    CdkStepperModule,
    NgStepperModule,
    IndicativeNum,
  ]
})
export class SharedModule { }
