import { Injectable } from '@angular/core';
import { LegalCuentaAhorro, LegalCuentaCartera, LegalCuentaCarteraValor, LegalCuentaCarteraValores, LegalCuentaCorriente, LegalEdadNaturalNacional, LegalEstadoPagoCuentaCartera, LegalEstadosCuentaCartera, LegalIdentificacionNaturalNacional, LegalJuridicaNacional, LegalMesEndeudamiento, LegalNaturalNacional, LegalPorcentajeMoraXTipoObligacion, LegalTarjetaCredito, LegalTarjetaCreditoCaracteristicas, LegalTarjetaCreditoEstados, LegalUbicacionNaturalNacional } from '../models/legal-credit-history.model';
import { HelperCreditInfoService, ParamsToDetermineState } from './helper-credit-info.service';
import { HtmlCleanerPipe } from '../pipes/html-cleaner.pipe';

export interface RecognizeResponse {
  globalDebt: any;
  summaryBalance: SummaryBalance | undefined
  summariesOfPaymentHabits: SummaryOfPaymentHabits[]
  creditHistory: any;
  portfolioAccount: any;
  scores: any;
  basicInfo: any;
  consultedBy: ConsultedBy | undefined
  levelOfIndebtednessAndOpenDebentureValues: LevelOfIndebtednessAndOpenDebentureValues[]
  codigoRespuesta: string
  activeSavingAndCheckingAccounts: ActiveSavingAndCheckingAccounts[]
  inactiveSavingAndCheckingAccounts: InactiveSavingAndCheckingAccounts[]
  paymentHabitOfActiveObligations: PaymentHabitOfActiveObligations[]
  paymentHabitOfInactiveObligations: PaymentHabitOfInactiveObligations[]
  balancesQuotaAndValuesOfActiveObligations: BalancesQuotaAndValuesOfActiveObligations | undefined
  completeBasicInformation: CompleteBasicInformation | undefined
  percentageOfArrearsByTypeOfObligation: PercentageOfArrearsByTypeOfObligation | undefined
}

export interface PercentageOfArrearsByTypeOfObligation {
  months: string[]
  accountTypes: AccountTypeFromPercentageOfArrearsByTypeOfObligation[]
  totalesPerMonth: MonthValueOfAccoutTypeFromPercentageOfArrearsByTypeOfObligation[]
}

export interface AccountTypeFromPercentageOfArrearsByTypeOfObligation {
  accountTypeName: string
  monthsWithValue: MonthValueOfAccoutTypeFromPercentageOfArrearsByTypeOfObligation[]
}

export interface MonthValueOfAccoutTypeFromPercentageOfArrearsByTypeOfObligation {
  month: string
  value: number
}

export interface CompleteBasicInformation {
  isNaturalPerson: boolean
  personInformation: PersonInformationFromCompleteBasicInformation | undefined
  companyInformation: CompanyInformationFromCompleteBasicInformation | undefined
}

export interface PersonInformationFromCompleteBasicInformation {
  isTecPerson: boolean
  fullName: string
  documentType: string
  document: string
  documentState: string
  expeditionLocation: string
  expeditionDate: string
  ageRange: string
  gender: string
  hasRut: boolean
  locationAge: string
  queryDate: string
}

export interface CompanyInformationFromCompleteBasicInformation {
  companyName: string
  identificationType: string
  identification: string
  queryDate: string
}

export interface BalancesQuotaAndValuesOfActiveObligations {
  obligations: ActiveObligationFromBalancesQuotaAndValues[]
  totales: TotalFromBalancesQuotaAndValuesOfActiveObligations
}

export interface ActiveObligationFromBalancesQuotaAndValues {
  obligationType: string
  entity: string
  obligationNumber: string | number
  initialQuota: string | number
  currentBalance: string | number
  balanceInArrears: string | number
  quotaValue: string | number
  percentageOfUsage: string | number
  payday: string | number
  termAndQuota: string
  mod: string | number
}

export interface TotalFromBalancesQuotaAndValuesOfActiveObligations {
  totalInitialQuota: number
  totalCurrentBalance: number
  totalBalanceInArrears: number
  totalQuotaValue: number
  totalPercentageOfUsage: number
}

export interface PaymentHabitOfInactiveObligations {
  sector: string | number
  obligations: InactiveObligationFromPaymentHabit[]
}

export interface InactiveObligationFromPaymentHabit {
  sector: string | number
  status: string
  type: string
  entity: string
  closingDate: string
  obligationNumber: string | number
  office: string
  quality: string
  openingDate: string
  expireDate: string
  moraMax: string | number
  disagreementWithInformation: string
  behavior: string
}

export interface PaymentHabitOfActiveObligations {
  sector: string | number
  obligations: ActiveObligationFromPaymentHabit[]
}

export interface ActiveObligationFromPaymentHabit {
  sector: string | number
  status: string
  type: string
  entity: string
  currentDate: string
  obligationNumber: string | number
  office: string
  quality: string
  openingDate: string
  expireDate: string
  perm: string | number
  clasification: string | number
  moraMax: string | number
  disagreementWithInformation: string
  behavior: string
}

export interface ActiveSavingAndCheckingAccounts {
  accountStatus: string
  accountType: string
  entity: string
  openingDate: string
  currentDate: string
  accountNumber: string | number
  city: string
  office: string
  returnedChecks: string | number
  overdraftQuota: string | number
  autoOverdraftDays: string | number
  disagreementWithInformation: string
}

export interface InactiveSavingAndCheckingAccounts {
  accountStatus: string
  accountType: string
  entity: string
  openingDate: string
  closingDate: string
  accountNumber: string | number
  city: string
  office: string
  returnedChecks: string | number
  overdraftQuota: string | number
  autoOverdraftDays: string | number
  disagreementWithInformation: string
}

export interface SummaryBalance {
  headers: string[]
  records: string[][]
}

export interface SummaryOfPaymentHabits {
  headers: string[]
  records: string[][]
}

export interface ConsultedBy {
  headers: string[]
  records: string[][]
}

interface GroupedByEntityConsult {
  [entidad: string]: {
    fecha: string;
    cantidad: string;
  }[];
  creditHistory: any;
  portfolioAccount: any;
}

export interface LevelOfIndebtednessAndOpenDebentureValues {
  sector: string
  headers: string[]
  entities: LevelOfIndebtednessAndOpenDebentureValuesEntity[]
  totalBalanceBySector: number[]
  totalBalanceInArrearsBySector: number[]
}

export interface LevelOfIndebtednessAndOpenDebentureValuesEntity {
  entity: string
  accountTypes: AccountTypeFromLevelOfIndebtednessAndOpenDebenture[]
  totalBalanceByEntity: number[]
  totalBalanceInArrearsByEntity: number[]
}

export interface AccountTypeFromLevelOfIndebtednessAndOpenDebenture {
  obligationType: string
  obligationQuantity: number
  arrayQuota: number[]
  arrayInitialQuota: number[]
  arrayBalance: number[]
  arrayBalanceInArrears: number[]
  arrayAvailableValue: number[]
}

export interface ValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues {
  arrayQuota: number[]
  arrayQuotaInitialValue: number[]
  arrayBalance: number[]
  arrayBalanceInArrears: number[]
  arrayAvailableValue: number[]

}

@Injectable({
  providedIn: 'root'
})
export class LegalPersonAdapterService {

  constructor(
    private helperInfoService: HelperCreditInfoService,
    private htmlCleanerPipe: HtmlCleanerPipe
  ) { }

  // INFO: SIRVE PARCIALMENTE
  // replaceValues(response, -1, null, ['valorInicial'])
  // replaceValues(
  //   obj: any,
  //   targetValue: any,
  //   newValue: any,
  //   keysToReplace: string[] = []
  // ): any {
  //   if (Array.isArray(obj)) {
  //     // Si es un array, iteramos sobre sus elementos
  //     return obj.map(item => this.replaceValues(item, targetValue, newValue, keysToReplace));
  //   } else if (obj !== null && typeof obj === 'object') {
  //     // Si es un objeto, recorremos sus claves
  //     return Object.entries(obj).reduce((acc, [key, value]) => {
  //       if (keysToReplace.includes(key) && value === targetValue) {
  //         // Reemplazamos el valor si coincide con la clave y el valor objetivo
  //         acc[key] = newValue;
  //       } else {
  //         // Recurre para los valores que son objetos o arrays
  //         acc[key] = this.replaceValues(value, targetValue, newValue, keysToReplace);
  //       }
  //       return acc;
  //     }, {} as any);
  //   } else {
  //     // Si no es objeto ni array, devolvemos el valor tal cual
  //     return obj;
  //   }
  // }

  /*  */
  public adapt(response: any): RecognizeResponse {
    const informe = response && response.data && response.data[0] && response.data[0].Informes && response.data[0].Informes.Informe || {};
    // console.log(informe.CuentaCartera)
    return {
      globalDebt: informe.EndeudamientoGlobal || {},
      summaryBalance: this.getSummaryBalance(response),
      summariesOfPaymentHabits: this.getSummariesOfPaymentHabits(response),
      consultedBy: this.getConsultedBy(response),
      creditHistory: informe.InfoAgregadaPJ || {},
      portfolioAccount: informe.CuentaCartera || {},
      levelOfIndebtednessAndOpenDebentureValues: this.getLevelOfIndebtednessAndOpenDebentureValues(response),
      scores: informe.Score || {},
      basicInfo: {
        identidad: response && response.data && response.data[0] && response.data[0].identidad || '',
        tipoIdentificacion: response && response.data && response.data[0] && response.data[0].tipoIdentificacion || '',
        razonSocial: informe.JuridicaNacional && informe.JuridicaNacional.razonSocial || '',
      },
      codigoRespuesta: response && response.data && response.data[0] && response.data[0].codigoRespuesta || '',
      activeSavingAndCheckingAccounts: this.getActiveSavingAndCheckingAccounts(response),
      inactiveSavingAndCheckingAccounts: this.getInactiveSavingAndCheckingAccounts(response),
      paymentHabitOfActiveObligations: this.getPaymentHabitOfActiveObligations(response),
      paymentHabitOfInactiveObligations: this.getPaymentHabitOfInactiveObligations(response),
      balancesQuotaAndValuesOfActiveObligations: this.getBalancesQuotaAndValuesOfActiveObligations(response),
      completeBasicInformation: this.getCompleteBasicInformation(response),
      percentageOfArrearsByTypeOfObligation: this.getPercentageOfArrearsByTypeOfObligation(response)
    };
  }

  getPercentageOfArrearsByTypeOfObligation(response: any): PercentageOfArrearsByTypeOfObligation | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.EndeudamientoUltimosMeses ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.EndeudamientoUltimosMeses.MesEndeudamiento
    ) return undefined;

    let mesesEndeudamiento: LegalMesEndeudamiento[] = response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.EndeudamientoUltimosMeses.MesEndeudamiento;
    if (!Array.isArray(mesesEndeudamiento)) mesesEndeudamiento = [mesesEndeudamiento];

    let result: PercentageOfArrearsByTypeOfObligation = {
      months: [],
      accountTypes: [],
      totalesPerMonth: []
    };

    mesesEndeudamiento.forEach((mesEndeudamiento: LegalMesEndeudamiento) => {
      const { mes = '-', PorcentajesMoraXTipoObligacion = { PorcentajeMoraXTipoObligacion: [] } } = mesEndeudamiento;
      const { PorcentajeMoraXTipoObligacion = [] } = PorcentajesMoraXTipoObligacion;

      result.months.push(mes);

      let totalFromMonth: number = 0;

      // PorcentajeMoraXTipoObligacion: Puede que en cierto mes, no exista cuentas con registro aquí
      PorcentajeMoraXTipoObligacion.forEach((item: LegalPorcentajeMoraXTipoObligacion) => {
        const { tipoObligacion = '-', valor = 0 } = item;
        totalFromMonth+= valor;

        const monthToAddIntoAccountType: MonthValueOfAccoutTypeFromPercentageOfArrearsByTypeOfObligation = { month: mes, value: valor };

        const indexOfAccountTypeFounded = result.accountTypes.findIndex((search) => search.accountTypeName === tipoObligacion);

        if (indexOfAccountTypeFounded < 0) result.accountTypes.push({ accountTypeName: tipoObligacion, monthsWithValue: [monthToAddIntoAccountType] });

        if(indexOfAccountTypeFounded >= 0) result.accountTypes[indexOfAccountTypeFounded].monthsWithValue.push(monthToAddIntoAccountType);
      });

      result.totalesPerMonth.push({month: mes, value: totalFromMonth});
    });

    // Ordenar los meses por mes y año más reciente
    result.months = this.sortMonthOfPercentageOfArrearsByTypeOfObligation(result.months);

    return result;
  }

  private sortMonthOfPercentageOfArrearsByTypeOfObligation(months: string[]) {
    if(months.length === 0) return months;

    const sortedMonths = months.sort((a, b) => {
      const [monthA, yearA] = a.split(' ');
      const [monthB, yearB] = b.split(' ');

      // Lógica para ajustar el año según su prefijo
      const fullYearA = parseInt(yearA) <= 50 ? 2000 + parseInt(yearA) : 1900 + parseInt(yearA);
      const fullYearB = parseInt(yearB) <= 50 ? 2000 + parseInt(yearB) : 1900 + parseInt(yearB);

      const dateA = new Date(fullYearA, this.helperInfoService.getValueOfMonthFromPercentageOfArrearsByTypeOfObligation(monthA));
      const dateB = new Date(fullYearB, this.helperInfoService.getValueOfMonthFromPercentageOfArrearsByTypeOfObligation(monthB));

      // Ordenar de más reciente a más antigua
      return dateB.getTime() - dateA.getTime();
    })
    return sortedMonths;
  }

  getCompleteBasicInformation(response: any): CompleteBasicInformation | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return undefined;

    const { desc_tipo_identificacion: documentType = '-', fechaHora: queryDate = '-' } = response.data[0];
    const { esIndependiente: isTecPerson = false, NaturalNacional, JuridicaNacional } = response.data[0].Informes.Informe;

    const isNaturalPerson: boolean = (NaturalNacional && NaturalNacional !== null && NaturalNacional !== undefined) ? true : false;
    const personInformation: PersonInformationFromCompleteBasicInformation | undefined = this.getPersonInformationFromCompleteBasicInformation(NaturalNacional, documentType, queryDate, isTecPerson);
    const companyInformation: CompanyInformationFromCompleteBasicInformation | undefined = this.getCompanyInformationFromCompleteBasicInformation(JuridicaNacional, documentType, queryDate);

    return {
      isNaturalPerson,
      personInformation: personInformation,
      companyInformation: companyInformation
    }
  }

  private getCompanyInformationFromCompleteBasicInformation(juridicaNacional: LegalJuridicaNacional, documentType: string, queryDate: string): CompanyInformationFromCompleteBasicInformation | undefined {
    if (!juridicaNacional) return undefined;

    const { razonSocial: companyName = '-', identificacion: identification = '-' } = juridicaNacional;

    return {
      companyName,
      identificationType: documentType,
      identification,
      queryDate
    }
  }

  private getPersonInformationFromCompleteBasicInformation(naturalNacional: LegalNaturalNacional, documentType: string, queryDate: string, isTecPerson: boolean): PersonInformationFromCompleteBasicInformation | undefined {
    if (!naturalNacional) return undefined;

    const defaultValueIdentificacion: LegalIdentificacionNaturalNacional = { ciudad: '-', departamento: '-', estado: '-', fechaExpedicion: '-', genero: '-', numero: '-' };
    const defaultValueEdad: LegalEdadNaturalNacional = { min: 0, max: 0 };
    const defaultValueUbicacion: LegalUbicacionNaturalNacional = { antiguedad: '-', tipo: '-' };

    const {
      rut: hasRut = false, nombreCompleto: fullName = '-',
      genero: genderCode = '-',
      Identificacion = defaultValueIdentificacion,
      Edad = defaultValueEdad,
      Ubicacion = defaultValueUbicacion
    }
      = naturalNacional;

    const {
      fechaExpedicion: expeditionDate = '-',
      numero: document = '-',
      ciudad: expeditionLocation = '-',
      estado: documentState = '-'
    } = Identificacion;

    const { antiguedad: locationAge = '-' } = Ubicacion;

    const { min = 0, max = 0 } = Edad;

    const ageRange: string = `${(min > 0) ? min : ''} - ${(max > 0) ? max : ''}`;

    const paramToDetermineGender: string = (genderCode !== undefined && genderCode !== null) ? `${genderCode}` : '-';
    const gender: string = this.helperInfoService.findGenderByCode(paramToDetermineGender);


    return {
      fullName, documentType, document,
      documentState, expeditionLocation, expeditionDate,
      ageRange, gender, hasRut,
      locationAge, queryDate, isTecPerson
    }
  }

  getBalancesQuotaAndValuesOfActiveObligations(response: any): BalancesQuotaAndValuesOfActiveObligations | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return undefined;

    const portfolios = this.getAllPortfoliosFromOriginalResponse(response);
    const creditCards = this.getAllCreditCardsFromOriginalResponse(response);

    const activePortfolios = this.getActivePortfoliosFromBalancesQuotaAndValues(portfolios);
    const activeCreditCards = this.getActiveCreditCardsFromBalancesQuotaAndValues(creditCards);

    const obligations: ActiveObligationFromBalancesQuotaAndValues[] = [...activePortfolios, ...activeCreditCards];
    const totales: TotalFromBalancesQuotaAndValuesOfActiveObligations = this.calculateTotalesFromBalancesQuotaAndValuesOfActiveObligations(obligations);

    const result: BalancesQuotaAndValuesOfActiveObligations = {
      obligations,
      totales
    };

    return result;
  }

  private getActiveCreditCardsFromBalancesQuotaAndValues(creditCards: LegalTarjetaCredito[]): ActiveObligationFromBalancesQuotaAndValues[] {
    if (!creditCards || creditCards.length === 0) return [];

    const activatedCreditCards: LegalTarjetaCredito[] = this.getActiveCreditCardsFromAllCreditCards(creditCards);

    const result: ActiveObligationFromBalancesQuotaAndValues[] = activatedCreditCards.map((creditCard) => {

      const { numero: obligationNumber = -1, Caracteristicas,
        Valores,
        entidad: entity = '-'
      } = creditCard;

      // const { garantia: warrantyType = '-' } = Caracteristicas;

      const hasValuesProperty: boolean = Valores && Valores.Valor ? true : false;

      const initialQuota = (hasValuesProperty && Valores.Valor.cupoTotal) ? Valores.Valor.cupoTotal : 0;
      const currentBalance = (hasValuesProperty && Valores.Valor.saldoActual) ? Valores.Valor.saldoActual : 0;
      const balanceInArrears = (hasValuesProperty && Valores.Valor.saldoMora) ? Valores.Valor.saldoMora : 0;
      const quotaValue = (hasValuesProperty && Valores.Valor.cuota) ? Valores.Valor.cuota : 0;
      const percentageOfUsage = (initialQuota > 0) ? (currentBalance / initialQuota) * 100 : 0;
      const payday = (hasValuesProperty && Valores.Valor.fechaPagoCuota) ? Valores.Valor.fechaPagoCuota : '-';

      return {
        obligationType: 'TDC', // Siempre es este valor para las tarjetas de crédito,
        entity,
        obligationNumber,
        initialQuota,
        currentBalance,
        balanceInArrears,
        quotaValue,
        percentageOfUsage,
        payday,

        // Siguiendo la definición en PN, que indica "Cuotas/M/Vigencia siempre es -/M/-", se deduce que siempre debe ir "-/-" y el Valor de "M" por modalidad
        // Siendo Cuotas(a de b cuotas) / M(modalidad) / Vigencia(el tipo de contrato D|I|-)
        termAndQuota: '-/-', 
        mod: 'M'
      }
    })

    return result;
  }

  private getActivePortfoliosFromBalancesQuotaAndValues(portfolios: LegalCuentaCartera[]): ActiveObligationFromBalancesQuotaAndValues[] {
    if (!portfolios || portfolios.length === 0) return [];

    const activatedPortfolios: LegalCuentaCartera[] = this.getActivePortfoliosFromAllPortfolios(portfolios);

    const result: ActiveObligationFromBalancesQuotaAndValues[] = activatedPortfolios.map((item) => {

      const {
        numero: accountNumber = -1, Caracteristicas,
        Valores, entidad: entity = '-'
      } = item;

      const { 
        tipoCuenta: accountType = '-', 
        mesesPermanencia: perm = '-', 
        garantia: warrantyType = '-',
        tipoContrato: contractType = -1
      } = Caracteristicas;

      const hasValuesInValueProperty: boolean = (Valores && Valores.Valor) ? true : false;
      const initialQuota = (hasValuesInValueProperty && Valores.Valor.valorInicial) ? Valores.Valor.valorInicial : 0;
      const currentBalance = (hasValuesInValueProperty && Valores.Valor.saldoActual) ? Valores.Valor.saldoActual : 0;
      const balanceInArrears = (hasValuesInValueProperty && Valores.Valor.saldoMora) ? Valores.Valor.saldoMora : 0;
      const quotaValue = (hasValuesInValueProperty && Valores.Valor.cuota) ? Valores.Valor.cuota : 0;
      const percentageOfUsage = (initialQuota > 0) ? (currentBalance / initialQuota) * 100 : 0;
      const payday = (hasValuesInValueProperty && Valores.Valor.fechaPagoCuota) ? Valores.Valor.fechaPagoCuota : '-';
      const canceledQuotas = (hasValuesInValueProperty && Valores.Valor.cuotasCanceladas >= 0) ? Valores.Valor.cuotasCanceladas : -1;
      const totalQuotas = (hasValuesInValueProperty && Valores.Valor.totalCuotas >= 0) ? Valores.Valor.totalCuotas : -1;

      const mod = (hasValuesInValueProperty && Valores.Valor.periodicidad) ? Valores.Valor.periodicidad : '-';
      const modFound = this.helperInfoService.findPaymentMontFromPortfolioByCode(mod);
      const definitiveMod = modFound ? modFound.letter : mod;

      // const labelQuotaProgress: string = ((canceledQuotas === 0 && totalQuotas === 0) || (canceledQuotas <= 0 && currentBalance === 0 && totalQuotas <= 0)) ? '-' : `${canceledQuotas} de ${totalQuotas}`;
      const labelQuotaProgress: string = ((canceledQuotas <= 0 && totalQuotas <= 0) || (!canceledQuotas && !totalQuotas)) ? '-' : `${canceledQuotas} de ${totalQuotas}`;
      const contractLetters: string = this.helperInfoService.getDescriptionOfTypeOfContractByObligationByCode(contractType).letters;
      const termAndQuota = `${contractLetters} / ${labelQuotaProgress}`;

      return {
        obligationType: accountType,
        entity,
        obligationNumber: accountNumber,
        initialQuota,
        currentBalance,
        balanceInArrears,
        quotaValue,
        percentageOfUsage,
        payday,
        termAndQuota,
        mod: definitiveMod
      }
    })

    return result;
  }

  private calculateTotalesFromBalancesQuotaAndValuesOfActiveObligations(obligations: ActiveObligationFromBalancesQuotaAndValues[]): TotalFromBalancesQuotaAndValuesOfActiveObligations {
    let totalInitialQuota: number = 0;
    let totalCurrentBalance: number = 0;
    let totalBalanceInArrears: number = 0;
    let totalQuotaValue: number = 0;
    let totalPercentageOfUsage: number = 0;

    obligations.forEach((obligation) => {
      const { initialQuota, currentBalance, balanceInArrears, quotaValue } = obligation;

      totalInitialQuota += !isNaN(Number(initialQuota)) ? Number(initialQuota) : 0;
      totalCurrentBalance += !isNaN(Number(currentBalance)) ? Number(currentBalance) : 0;
      totalBalanceInArrears += !isNaN(Number(balanceInArrears)) ? Number(balanceInArrears) : 0;
      totalQuotaValue += !isNaN(Number(quotaValue)) ? Number(quotaValue) : 0;
    });

    totalPercentageOfUsage = totalInitialQuota > 0 ? (totalCurrentBalance / totalInitialQuota) * 100 : 0;

    return {
      totalBalanceInArrears,
      totalCurrentBalance,
      totalInitialQuota,
      totalPercentageOfUsage,
      totalQuotaValue
    };
  }

  getPaymentHabitOfInactiveObligations(response: any): PaymentHabitOfInactiveObligations[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    const portfolios = this.getAllPortfoliosFromOriginalResponse(response);
    const creditCards = this.getAllCreditCardsFromOriginalResponse(response);

    const inactivePortfolios: InactiveObligationFromPaymentHabit[] = this.getInactivePortfolioFromPaymentHabit(portfolios);
    const inactiveCreditCards: InactiveObligationFromPaymentHabit[] = this.getInactiveCreditCardsFromPaymentHabit(creditCards);

    const obligations: InactiveObligationFromPaymentHabit[] = [...inactivePortfolios, ...inactiveCreditCards];

    const obligationBySector: PaymentHabitOfInactiveObligations[] = this.groupActiveAndInactiveObligationsFromPaymentHabitBySector(obligations);

    return obligationBySector;
  }

  private getInactiveCreditCardsFromPaymentHabit(creditCards: LegalTarjetaCredito[]): InactiveObligationFromPaymentHabit[] {
    if (!creditCards || creditCards.length === 0) return [];

    const inactivatedCreditCards: LegalTarjetaCredito[] = this.getInactiveCreditCardsFromAllCreditCards(creditCards);

    const obligations: InactiveObligationFromPaymentHabit[] = inactivatedCreditCards.map((creditCard) => {

      const defaultValueEstados: LegalTarjetaCreditoEstados = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
        EstadoPlastico: { codigo: '', fecha: '' }
      };

      const defaultCaracteristicas: LegalTarjetaCreditoCaracteristicas = {
        amparada: false, clase: 0, codigoAmparada: '-', franquicia: 0, garantia: 0, marca: '-'
      };

      const { numero: obligationNumber = -1, Caracteristicas = defaultCaracteristicas,
        Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, calificacion: clasification = '--', sector = -1,
        Estados = defaultValueEstados, formaPago, bloqueada
      } = creditCard;

      const { codigoAmparada = '-' } = Caracteristicas;

      const hasValuesProperty: boolean = Valores && Valores.Valor ? true : false;

      const currentDate = (hasValuesProperty && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      const diasMora = (hasValuesProperty && Valores.Valor.diasMora) ? Valores.Valor.diasMora : '-';
      const hasMora: boolean = !isNaN(Number(diasMora)) && Number(diasMora) > 0;
      // const disagreementWithInformation = bloqueada ? 'SI' : 'NO';
      const disagreementWithInformation = '-';


      const { EstadoCuenta, EstadoOrigen, EstadoPago, EstadoPlastico } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago;
      const { codigo: plasticState = '' } = EstadoPlastico;
      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState,
        isBloqued: bloqueada,
        hasMora
      };
      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);
      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(codigoAmparada);
      // const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      return {
        sector,
        status: obligationStatus,
        type: 'TDC', // Siempre es este valor para las tarjetas de crédito,
        entity,
        closingDate,
        obligationNumber,
        office,
        quality: qualityOfDebtor.cardDescription,
        openingDate,
        expireDate: expirationDate,
        moraMax: 'XX',
        disagreementWithInformation,
        behavior
      }
    });

    return obligations;
  }

  private getInactivePortfolioFromPaymentHabit(portfolios: LegalCuentaCartera[]): InactiveObligationFromPaymentHabit[] {
    if (!portfolios || portfolios.length === 0) return [];

    const inactivatedPortfolios: LegalCuentaCartera[] = this.getInactivePortfoliosFromAllPortfolios(portfolios);

    const result: InactiveObligationFromPaymentHabit[] = inactivatedPortfolios.map((item) => {

      const defaultValueEstados: LegalEstadosCuentaCartera = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, sector = -1,
        calificacion: clasification = '--',
        Estados = defaultValueEstados, formaPago, bloqueada
      } = item;

      const { tipoCuenta: accountType = '-', mesesPermanencia: perm = '-',
        garantia: warrantyType = '-', calidadDeudor: qualityOfDebtorCode = '-'
      } = Caracteristicas;

      const hasValuesProperty: boolean = Valores && Valores.Valor ? true : false;

      const currentDate = (hasValuesProperty && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      const diasMora = (hasValuesProperty && Valores.Valor.diasMora) ? Valores.Valor.diasMora : 0;
      const hasMora: boolean = !isNaN(Number(diasMora)) && Number(diasMora) > 0;
      // const disagreementWithInformation = bloqueada ? 'SI' : 'NO';
      const disagreementWithInformation = '-';
      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(qualityOfDebtorCode);

      const { EstadoCuenta, EstadoOrigen, EstadoPago } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago || {} as LegalEstadoPagoCuentaCartera;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState: undefined,
        isBloqued: bloqueada,
        hasMora
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);
      // const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      return {
        sector,
        status: obligationStatus,
        type: accountType,
        entity,
        closingDate,
        obligationNumber: accountNumber,
        office,
        quality: qualityOfDebtor.portfolioDescription,
        openingDate,
        expireDate: expirationDate,
        moraMax: 'XX',
        disagreementWithInformation,
        behavior,
      }
    })

    return result;
  }

  getPaymentHabitOfActiveObligations(response: any): PaymentHabitOfActiveObligations[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    const portfolios = this.getAllPortfoliosFromOriginalResponse(response);
    const creditCards = this.getAllCreditCardsFromOriginalResponse(response);

    const activePorfoltios: ActiveObligationFromPaymentHabit[] = this.getActivePortfolioFromPaymentHabit(portfolios);
    const activeCreditCards: ActiveObligationFromPaymentHabit[] = this.getActiveCreditCardsFromPaymentHabit(creditCards);

    const obligations: ActiveObligationFromPaymentHabit[] = [...activeCreditCards, ...activePorfoltios];

    const obligationBySector: PaymentHabitOfActiveObligations[] = this.groupActiveAndInactiveObligationsFromPaymentHabitBySector(obligations);

    return obligationBySector;
  }

  /**
  * Función que agrupa las obligaciones actives e inactivas por sector
  * Nota: Al momento de reutilizar la función para agrupar activas e inactivas, arrojaba error al tipar
  * @param obligations Obligaciones activas o inactivas. Cada obligación tiene el atributo sector por el cual se realiza la agrupación
  * @returns 
  */
  private groupActiveAndInactiveObligationsFromPaymentHabitBySector(obligations: any[]): any[] {
    if (!obligations || obligations.length === 0) return [];

    let sectores: any[] = [];

    obligations.forEach((obligation) => {
      const sector = sectores.find((sector) => sector.sector === obligation.sector);

      if (!sector) sectores.push({ sector: obligation.sector, obligations: [obligation] })

      if (sector) sector.obligations.push(obligation);
    });

    return sectores;
  }

  private getActivePortfolioFromPaymentHabit(portfolios: LegalCuentaCartera[]): ActiveObligationFromPaymentHabit[] {
    if (!portfolios || portfolios.length === 0) return [];

    const activatedPortfolios: LegalCuentaCartera[] = this.getActivePortfoliosFromAllPortfolios(portfolios);

    const result: ActiveObligationFromPaymentHabit[] = activatedPortfolios.map((item) => {

      const defaultValueEstados: LegalEstadosCuentaCartera = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
      };

      const { numero: accountNumber = -1, Caracteristicas, Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, sector = -1,
        calificacion: clasification = '--',
        Estados = defaultValueEstados, formaPago, bloqueada
      } = item;

      const { tipoCuenta: accountType = '-', mesesPermanencia: perm = '-',
        garantia: warrantyType = '-', calidadDeudor: qualityOfDebtorCode = '-'
      } = Caracteristicas;

      const hasValuesProperty: boolean = Valores && Valores.Valor ? true : false;

      const currentDate = (hasValuesProperty && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      const diasMora = (hasValuesProperty && Valores.Valor.diasMora) ? Valores.Valor.diasMora : '-';
      const hasMora: boolean = !isNaN(Number(diasMora)) && Number(diasMora) > 0;
      // const disagreementWithInformation = bloqueada ? 'SI' : 'NO';
      const disagreementWithInformation = '-';
      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(qualityOfDebtorCode);

      const { EstadoCuenta, EstadoOrigen, EstadoPago } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago || {} as LegalEstadoPagoCuentaCartera;

      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState: undefined,
        isBloqued: bloqueada,
        hasMora
      };

      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);
      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);

      return {
        sector,
        status: obligationStatus,
        type: accountType,
        entity,
        currentDate,
        obligationNumber: accountNumber,
        office,
        quality: qualityOfDebtor.portfolioDescription,
        openingDate,
        expireDate: expirationDate,
        perm,
        clasification: clasificationFinal,
        moraMax: 'XX',
        disagreementWithInformation,
        behavior
      }
    })

    return result;
  }

  private getActiveCreditCardsFromPaymentHabit(creditCards: LegalTarjetaCredito[]): ActiveObligationFromPaymentHabit[] {
    if (!creditCards || creditCards.length === 0) return [];

    const activatedCreditCards: LegalTarjetaCredito[] = this.getActiveCreditCardsFromAllCreditCards(creditCards);

    const obligations: ActiveObligationFromPaymentHabit[] = activatedCreditCards.map((creditCard) => {

      const defaultValueEstados: LegalTarjetaCreditoEstados = {
        EstadoCuenta: { codigo: '', fecha: '' },
        EstadoOrigen: { codigo: '', fecha: '' },
        EstadoPago: { codigo: '', fecha: '', meses: -1 },
        EstadoPlastico: { codigo: '', fecha: '' }
      };

      const defaultCaracteristicas: LegalTarjetaCreditoCaracteristicas = {
        amparada: false, clase: 0, codigoAmparada: '-', franquicia: 0, garantia: 0, marca: '-'
      };

      const { numero: obligationNumber = -1,
        Caracteristicas = defaultCaracteristicas,
        Valores,
        fechaApertura: openingDate = '-', fechaVencimiento: expirationDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-', comportamiento: behavior = '-',
        Adjetivo, calificacion: clasification = '--', sector = -1,
        Estados = defaultValueEstados, formaPago, bloqueada
      } = creditCard;

      const { codigoAmparada = '-' } = Caracteristicas;

      const hasValuesProperty: boolean = Valores && Valores.Valor ? true : false;

      const currentDate = (hasValuesProperty && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      const diasMora = (hasValuesProperty && Valores.Valor.diasMora) ? Valores.Valor.diasMora : '-';
      const hasMora: boolean = !isNaN(Number(diasMora)) && Number(diasMora) > 0;
      // const disagreementWithInformation = bloqueada ? 'SI' : 'NO';
      const disagreementWithInformation = '-';


      const { EstadoCuenta, EstadoOrigen, EstadoPago, EstadoPlastico } = Estados;
      const { codigo: accountState = '' } = EstadoCuenta;
      const { codigo: originState = '' } = EstadoOrigen;
      const { codigo: paymentState = '', fecha: closingDate = '-' } = EstadoPago;
      const { codigo: plasticState = '' } = EstadoPlastico;
      const params: ParamsToDetermineState = {
        accountState,
        originState,
        paymentState,
        paymentMethod: formaPago,
        plasticState,
        isBloqued: bloqueada,
        hasMora
      };
      const obligationStatus: string = this.helperInfoService.determinePortfolioAndCreditCardStatus(params);
      const clasificationFinal: string = this.helperInfoService.findPortfolioAndCreditCardAccountRatingByCode(clasification);
      const qualityOfDebtor = this.helperInfoService.findQualityOfDebtor(codigoAmparada);

      return {
        sector,
        status: obligationStatus,
        type: 'TDC', // Siempre es este valor para las tarjetas de crédito,
        entity,
        currentDate,
        obligationNumber,
        office,
        quality: qualityOfDebtor.cardDescription,
        openingDate,
        expireDate: expirationDate,
        perm: '-', // las tarjetas de crédito no tiene esto
        clasification: clasificationFinal,
        moraMax: 'XX',
        disagreementWithInformation,
        behavior
      }
    });

    return obligations;
  }

  getInactiveSavingAndCheckingAccounts(response: any): InactiveSavingAndCheckingAccounts[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    const allCheckingAccounts = this.getAllCheckingAccountsFromOriginalResponse(response);
    const allSavingAccounts = this.getAllSavingAccountsFromOriginalResponse(response);

    const inactiveSavingAccounts = this.getInactiveSavigAccounts(allSavingAccounts);
    const inactiveCheckingAccounts = this.getInactiveCheckingAccounts(allCheckingAccounts);

    const allInactiveAccounts = [...inactiveSavingAccounts, ...inactiveCheckingAccounts]

    return allInactiveAccounts;
  }

  private getInactiveSavigAccounts(savingAccounts: LegalCuentaAhorro[]): InactiveSavingAndCheckingAccounts[] {
    if (!savingAccounts || savingAccounts.length === 0) return [];

    const inactivatedSavingAccounts: LegalCuentaAhorro[] = savingAccounts.filter((account: LegalCuentaAhorro) => {
      const code: string = account.Estado.codigo || '-';
      return !this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    const result: InactiveSavingAndCheckingAccounts[] = inactivatedSavingAccounts.map((item) => {
      const {
        numero: accountNumber = -1,
        Valores = { Valor: { fecha: '', calificacion: '', moneda: '' } },
        fechaApertura: openingDate = '-', entidad: entity = '-',
        oficina: office = '-',
        Estado: { fecha: closingDate = '-', codigo: stateCode = '-' },
        ciudad: city = '-', bloqueada
      } = item;

      const accountStatus = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(stateCode);
      // const disagreementWithInformation = bloqueada ? 'Sí' : 'No';
      const disagreementWithInformation = '-';

      return {
        accountStatus,
        accountType: 'AHO', // Siempre es este valor para las cuentas de ahorro
        entity,
        openingDate,
        closingDate,
        accountNumber,
        city,
        office,
        returnedChecks: '-',
        overdraftQuota: 0,
        autoOverdraftDays: 0,
        disagreementWithInformation,
      }
    });

    return result;
  }

  private getInactiveCheckingAccounts(checkingAccounts: LegalCuentaCorriente[]): InactiveSavingAndCheckingAccounts[] {
    if (!checkingAccounts || checkingAccounts.length === 0) return [];

    const inactivatedAccounts: LegalCuentaCorriente[] = checkingAccounts.filter((account: LegalCuentaCorriente) => {
      const code: string = account.Estado.codigo || '00';
      return !this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    const result: InactiveSavingAndCheckingAccounts[] = inactivatedAccounts.map((item) => {

      const { numero: accountNumber = -1, Caracteristicas,
        fechaApertura: openingDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-',
        Valores = { Valor: { fecha: '', calificacion: '', moneda: '', cantidadChequesDevueltos: '', cantidadChequesPagados: '', valorChequesDevueltos: '', valorChequesPagados: '' } },
        Adjetivo, calificacion: clasification = '-', sector = -1,
        Estado: { fecha: closingDate = '-', codigo: stateCode = '-' },
        ciudad: city = '-', bloqueada,
        Sobregiro = { fecha: '', dias: '', valor: '' }
      } = item;

      const accountStatus = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(stateCode);
      // const disagreementWithInformation = bloqueada ? 'Sí' : 'No';
      const disagreementWithInformation = '-';
      const returnedChecks = (Valores && Valores.Valor && (!isNaN(Number(Valores.Valor.valorChequesDevueltos)))) ? Valores.Valor.valorChequesDevueltos : '-';
      const overdraftQuota = (Sobregiro && !isNaN(Number(Sobregiro.valor))) ? Sobregiro.valor : '-';
      const autoOverdraftDays = (Sobregiro && !isNaN(Number(Sobregiro.dias))) ? Sobregiro.dias : '-';

      return {
        accountStatus,
        accountType: 'CCB', // Siempre es este valor para las cuentas corrientes
        entity,
        openingDate,
        closingDate,
        accountNumber,
        city,
        office,
        returnedChecks,
        overdraftQuota,
        autoOverdraftDays,
        disagreementWithInformation,
      }
    })

    return result;
  }

  getActiveSavingAndCheckingAccounts(response: any): ActiveSavingAndCheckingAccounts[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];

    const allCheckingAccounts = this.getAllCheckingAccountsFromOriginalResponse(response);
    const allSavingAccounts = this.getAllSavingAccountsFromOriginalResponse(response);

    const activeSavingAccounts = this.getActiveSavigAccounts(allSavingAccounts);
    const activeCheckingAccounts = this.getActiveCheckingAccounts(allCheckingAccounts);

    const allActiveAccounts = [...activeSavingAccounts, ...activeCheckingAccounts]

    return allActiveAccounts;
  }

  private getActiveCheckingAccounts(checkingAccounts: LegalCuentaCorriente[]): ActiveSavingAndCheckingAccounts[] {
    if (!checkingAccounts || checkingAccounts.length === 0) return [];

    const activatedAccounts: LegalCuentaCorriente[] = checkingAccounts.filter((account: LegalCuentaCorriente) => {
      const code: string = account.Estado.codigo || '00';
      return this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    const result: ActiveSavingAndCheckingAccounts[] = activatedAccounts.map((item) => {

      const { numero: accountNumber = -1, Caracteristicas,
        fechaApertura: openingDate = '-',
        situacionTitular: ownerStatus = '-', oficina: office = '-',
        entidad: entity = '-',
        Valores = { Valor: { fecha: '', calificacion: '', moneda: '', cantidadChequesDevueltos: '', cantidadChequesPagados: '', valorChequesDevueltos: '', valorChequesPagados: '' } },
        Adjetivo, calificacion: clasification = '--', sector = -1,
        Estado = { codigo: '-' }, ciudad: city = '-', bloqueada,
        Sobregiro = { fecha: '', dias: '', valor: '' }
      } = item;

      const accountStatus = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(Estado.codigo);
      const currentDate = (Valores && Valores.Valor && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      // const disagreementWithInformation = bloqueada ? 'Sí' : 'No';
      const disagreementWithInformation = '-';
      const returnedChecks = (Valores && Valores.Valor && (!isNaN(Number(Valores.Valor.valorChequesDevueltos)))) ? Valores.Valor.valorChequesDevueltos : '-';
      const overdraftQuota = (Sobregiro && !isNaN(Number(Sobregiro.valor))) ? Sobregiro.valor : '-';
      const autoOverdraftDays = (Sobregiro && !isNaN(Number(Sobregiro.dias))) ? Sobregiro.dias : '-';


      return {
        accountStatus,
        accountType: 'CCB', // Siempre es este valor para las cuentas corrientes
        entity,
        openingDate,
        currentDate,
        accountNumber,
        city,
        office,
        returnedChecks,
        overdraftQuota,
        autoOverdraftDays,
        disagreementWithInformation,
      }
    })

    return result;
  }

  private getActiveSavigAccounts(savingAccounts: LegalCuentaAhorro[]): ActiveSavingAndCheckingAccounts[] {
    if (!savingAccounts || savingAccounts.length === 0) return [];

    const activatedSavingAccounts: LegalCuentaAhorro[] = savingAccounts.filter((account: LegalCuentaAhorro) => {
      const code: string = account.Estado.codigo || '-';
      return this.helperInfoService.isActiveCheckingAndSavingAccount(code);
    });

    const result: ActiveSavingAndCheckingAccounts[] = activatedSavingAccounts.map((item) => {
      const {
        numero: accountNumber = -1,
        Valores = { Valor: { fecha: '', calificacion: '', moneda: '' } },
        fechaApertura: openingDate = '-', entidad: entity = '-',
        oficina: office = '-',
        Estado = { codigo: '-' },
        ciudad: city = '-', bloqueada
      } = item;

      const accountStatus = this.helperInfoService.findCheckingAndSavingAccountStatementByCode(Estado.codigo);
      const currentDate = (Valores && Valores.Valor && Valores.Valor.fecha) ? Valores.Valor.fecha : '-';
      // const disagreementWithInformation = bloqueada ? 'Sí' : 'No';
      const disagreementWithInformation = '-';

      return {
        accountStatus,
        accountType: 'AHO', // Siempre es este valor para las cuentas de ahorro
        entity,
        openingDate,
        currentDate,
        accountNumber,
        city,
        office,
        returnedChecks: '-',
        overdraftQuota: 0,
        autoOverdraftDays: 0,
        disagreementWithInformation,
      }
    });

    return result;
  }

  getSummaryBalance(response: any): SummaryBalance | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos.Trimestre
    ) return undefined;

    let headers: string[] = ['Saldos'];
    let totalBalance: string[] = ['Saldo total'];
    let totalBalanceInArrears: string[] = ['Saldo en mora total']
    let data: any[] = [];
    if (response.data[0].Informes.Informe.InfoAgregadaPJ && response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos) {
      data = response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenesSaldos.ResumenSaldos.Trimestre || [];
    }

    data.forEach((item: any) => {
      const { trimestre, saldoTotal, saldoMoraTotal } = item;
      headers.push(trimestre);
      totalBalance.push(saldoTotal);
      totalBalanceInArrears.push(saldoMoraTotal)
    });

    return {
      headers,
      records: [totalBalance, totalBalanceInArrears]
    }
  }

  getSummariesOfPaymentHabits(response: any): SummaryOfPaymentHabits[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago.TipoCuenta
    ) return [];

    let data: any[] = [];

    if (response.data[0].Informes.Informe.InfoAgregadaPJ &&
      response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago) {
      const tipoCuentaData = response.data[0].Informes.Informe.InfoAgregadaPJ.ResumenHabitoPago.TipoCuenta;

      data = Array.isArray(tipoCuentaData) ? tipoCuentaData : [tipoCuentaData];
    }

    const headers: string[] = ['Tipo de cuenta y estado', 'Número'];

    const summaries: SummaryOfPaymentHabits[] = data.map((item: any) => {
      let { tipoCuenta, cantidad, Estado: accounts } = item;

      const formatteedTipoCuenta = this.helperInfoService.getDescriptionAccountTypeByCode(tipoCuenta);
      const rowTotal: string[] = [formatteedTipoCuenta, cantidad];

      if (!Array.isArray(accounts)) accounts = [accounts];
      const rowStates: string[][] = this.getStatesFromSummaryOfPaymentsHabits(accounts);

      return {
        headers,
        records: [rowTotal, ...rowStates]
      }
    });

    return summaries;
  }

  private getStatesFromSummaryOfPaymentsHabits(accounts: any[]): string[][] {
    return accounts.map((item: any) => {
      const { codigo, cantidad } = item;
      const formattedCodigo: string = this.htmlCleanerPipe.transform(codigo);
      return [formattedCodigo, cantidad];
    })
  }

  getConsultedBy(response: any): ConsultedBy | undefined {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.Consulta
    ) return undefined;

    const queries: any[] = response.data[0].Informes.Informe.Consulta || [];

    const dates: string[] = this.getDatesFromConsultedBy(queries);
    const headers: string[] = ['Consultante', ...dates];
    const groupByEntity: GroupedByEntityConsult = this.groupByEntityToConsultBy(queries);
    // console.log('======><><><><>Datos agrupados por entidad:: ', groupByEntity);

    let recordsOfTable: string[][] = [];

    Object.entries(groupByEntity).forEach(([entity, records]) => {
      // console.log('Entidad actual y valores asociados ===><><>', entity, records);
      let recordOfTable: string[] = [entity];

      dates.forEach((date: string) => {
        const queriesByDate = records.filter((item) => item.fecha.includes(date));
        if (queriesByDate.length === 0) recordOfTable.push('0');

        if (queriesByDate.length > 0) {
          let total: number = 0;
          queriesByDate.forEach((item) => {
            total += !isNaN(Number(item.cantidad)) ? Number(item.cantidad) : 0;
          })
          recordOfTable.push(`${total}`);
        }
      });

      recordsOfTable.push(recordOfTable);
    });

    const rowTotal: any[] = this.calculateTotalFromConsultedBy(recordsOfTable);

    const result: ConsultedBy = {
      headers,
      records: [...recordsOfTable, rowTotal]
    };

    return result;
  }

  private groupByEntityToConsultBy(queries: any[]): GroupedByEntityConsult {
    return queries.reduce((acc, item) => {
      if (!acc[item.entidad]) {
        acc[item.entidad] = [];
      }
      const [year, month, day] = item.fecha.split('-'); // "2024-07-03"
      acc[item.entidad].push({
        fecha: `${month}-${year}`,
        cantidad: item.cantidad
      })
      return acc;
    }, {} as GroupedByEntityConsult);
  }

  private getDatesFromConsultedBy(queries: any[]): string[] {
    let dates: string[] = [];
    queries.forEach((item: any) => {
      const { entidad, fecha, cantidad } = item;
      const [year, month, day] = fecha.split('-'); // "2024-07-03"
      dates.push(`${month}-${year}`);
    })

    // NOTA: En caso de requerir crear los meses faltantes de las fechas de consulta, 
    // agregar los meses aquí e inmediatamente al otro lado se hacen los cálculos si fue consultado en X fecha

    return Array.from(new Set(dates));
  }

  private calculateTotalFromConsultedBy(records: string[][]): string[] {
    if (records.length === 0) return [];
    let result = new Array(records[0].length).fill(0);
    result[0] = "Total";

    records.forEach((record: string[]) => {
      record.forEach((value: string, index: number) => {
        if (index > 0) {
          result[index] += !isNaN(Number(value)) ? Number(value) : 0;
        }
      });
    });

    return result;
  }

  /**
   * Funcion que construye la información necesaria a visualizar sobre la tabla NIVEL DE ENDEUDAMIENTO Y VALORES DE OBLIGACIONES ABIERTAS/VIGENTES
   * @param response : Respuesta en bruto recibida del servicio de Experian
   * @returns 
   */
  getLevelOfIndebtednessAndOpenDebentureValues(response: any): LevelOfIndebtednessAndOpenDebentureValues[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas ||
      !response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.SectorNivelEndValoresAbiertas
    ) return [];

    let sectores: any[] = [];

    if (response.data[0].Informes.Informe.InfoAgregadaPJ && response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas) {
      sectores = response.data[0].Informes.Informe.InfoAgregadaPJ.NivelEndValoresCtasAbiertas.SectorNivelEndValoresAbiertas || [];
    }

    const result: LevelOfIndebtednessAndOpenDebentureValues[] = sectores.map((sector) => {
      const sectorName: string = sector.nombreSector || '--';

      let entities: any[] = sector.Entidad ? sector.Entidad : [];

      // Puede ser un objeto y no un array
      if (!Array.isArray(entities)) entities = [entities];

      const headers: string[] = this.getHeadersFromSectorFromLevelOfIndebtednessAndOpenDebentureValues(entities);
      const entitiesFromSector: LevelOfIndebtednessAndOpenDebentureValuesEntity[] = this.getRecordFromEntityFromLevelOfIndebtednessAndOpenDebentureValues(entities);
      const totalBalanceBySector = this.calculateTotalBalanceBySectorFromLevelOfIndebtedness(entitiesFromSector);
      const totalBalanceInArrearsBySector = this.calculateTotalBalanceInArrearsBySectorFromLevelOfIndebtedness(entitiesFromSector);

      return {
        sector: sectorName,
        headers,
        entities: entitiesFromSector,
        totalBalanceBySector,
        totalBalanceInArrearsBySector
      }
    });

    return result;
  }

  private calculateTotalBalanceBySectorFromLevelOfIndebtedness(entitiesFromSector: LevelOfIndebtednessAndOpenDebentureValuesEntity[]): number[] {
    if (!entitiesFromSector || entitiesFromSector.length === 0) return [];

    let totalBalanceBySector: number[] = [];

    entitiesFromSector.forEach((entity, index) => {
      const { totalBalanceByEntity } = entity;
      if (index === 0) totalBalanceBySector = [...totalBalanceByEntity];

      if (index > 0) {
        totalBalanceByEntity.forEach((value, index) => {
          totalBalanceBySector[index] += value;
        })
      }
    });

    return totalBalanceBySector;
  }

  private calculateTotalBalanceInArrearsBySectorFromLevelOfIndebtedness(entitiesFromSector: LevelOfIndebtednessAndOpenDebentureValuesEntity[]): number[] {
    if (!entitiesFromSector || entitiesFromSector.length === 0) return [];

    let totalBalanceInArrearsBySector: number[] = [];

    entitiesFromSector.forEach((entity, index) => {
      const { totalBalanceInArrearsByEntity } = entity;
      if (index === 0) totalBalanceInArrearsBySector = [...totalBalanceInArrearsByEntity];

      if (index > 0) {
        totalBalanceInArrearsByEntity.forEach((value, index) => {
          totalBalanceInArrearsBySector[index] += value;
        })
      }
    });

    return totalBalanceInArrearsBySector;
  }

  private getHeadersFromSectorFromLevelOfIndebtednessAndOpenDebentureValues(entities: any[]): string[] {
    const prefixHeaders: string[] = ['Entidad', 'Tipo de obligación', 'Número obligación', 'Valor', 'Prom'];
    if (!entities) return prefixHeaders;

    let tipoCuenta: any = (entities[0] && entities[0].TipoCuenta) ? entities[0].TipoCuenta : [];

    // Puede ser un array o un objeto
    if (!Array.isArray(tipoCuenta)) tipoCuenta = [tipoCuenta];

    const months: any[] = (tipoCuenta[0] && tipoCuenta[0].Mes) ? tipoCuenta[0].Mes : [];

    const dates: string[] = months.map((mes) => {
      return `${mes.mes}`;
    });

    return [...prefixHeaders, ...dates];
  }

  /**
   * Función que construye las filas de la tabla de niveles de endeudamiento y valores de obligaciones abiertas/vigentes
   * @param entities 
   * @returns 
   */
  private getRecordFromEntityFromLevelOfIndebtednessAndOpenDebentureValues(entities: any[]): LevelOfIndebtednessAndOpenDebentureValuesEntity[] {
    if (!entities || entities.length === 0) return [];
    const completeEntities: LevelOfIndebtednessAndOpenDebentureValuesEntity[] = [];

    entities.forEach((entity) => {
      const entityName: string = entity.nombreEntidad || '--';
      const resultAccountTypes: AccountTypeFromLevelOfIndebtednessAndOpenDebenture[] = [];

      let accountTypes = entity.TipoCuenta || [];
      if (!Array.isArray(accountTypes)) accountTypes = [accountTypes]; // Puede ser un array

      accountTypes.forEach((account) => {
        const recordsFromAccount: AccountTypeFromLevelOfIndebtednessAndOpenDebenture = this.getRecordFromAccountSectorFromLevelOfIndebtednessAndOpenDebentureValues(account, entityName);
        resultAccountTypes.push(recordsFromAccount);
      });

      completeEntities.push({
        entity: entityName,
        accountTypes: resultAccountTypes,
        totalBalanceByEntity: this.calculateTotalBalanceByEntityFromLevelOfIndebtedness(resultAccountTypes),
        totalBalanceInArrearsByEntity: this.calculateTotalBalanceInArrearsByEntityFromLevelOfIndebtedness(resultAccountTypes)
      })
    })

    return completeEntities;
  }

  private calculateTotalBalanceByEntityFromLevelOfIndebtedness(accountTypesFromEntity: AccountTypeFromLevelOfIndebtednessAndOpenDebenture[]): number[] {
    if (!accountTypesFromEntity || accountTypesFromEntity.length === 0) return [];

    let totalBalanceByEntity: number[] = [];

    accountTypesFromEntity.forEach((accountType, index) => {
      const { arrayBalance } = accountType;

      if (index === 0) totalBalanceByEntity = [...arrayBalance]

      if (index > 0) {
        arrayBalance.forEach((value, index) => {
          totalBalanceByEntity[index] += value;
        })
      }
    });

    return totalBalanceByEntity;
  }

  private calculateTotalBalanceInArrearsByEntityFromLevelOfIndebtedness(accountTypesFromEntity: AccountTypeFromLevelOfIndebtednessAndOpenDebenture[]): number[] {
    if (!accountTypesFromEntity || accountTypesFromEntity.length === 0) return [];

    let totalBalanceInArrears: number[] = [];

    accountTypesFromEntity.forEach((accountType, index) => {
      const { arrayBalanceInArrears } = accountType;

      if (index === 0) totalBalanceInArrears = [...arrayBalanceInArrears];

      if (index > 0) {
        arrayBalanceInArrears.forEach((value, index) => {
          totalBalanceInArrears[index] += value;
        })
      }
    });

    return totalBalanceInArrears;
  }

  private getRecordFromAccountSectorFromLevelOfIndebtednessAndOpenDebentureValues(tipoCuenta: any, entityName: string): AccountTypeFromLevelOfIndebtednessAndOpenDebenture {
    if (!tipoCuenta) return {} as AccountTypeFromLevelOfIndebtednessAndOpenDebenture;

    const { tipo, cantidad, promedioCuota, promedioCupoInicial,
      promedioSaldo, promedioSaldoMora, promedioValorDisponible } = tipoCuenta;

    const obligationType: string = tipo || '--';
    const obligationQuantity: number = cantidad || 0;
    const prefixLabels: any[] = [entityName, obligationType, obligationQuantity];

    const averageQuota: number = promedioCuota || 0;
    const averageQuotaInitialValue: number = promedioCupoInicial || 0;
    const averageBalance: number = promedioSaldo || 0;
    const averageBalanceInArrears: number = promedioSaldoMora || 0;
    const averageAvailableValue: number = promedioValorDisponible || 0;

    const valuesPerMonth = this.getValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues(tipoCuenta.Mes);

    const arrayQuota: number[] = [averageQuota, ...valuesPerMonth.arrayQuota];
    const arrayInitialQuota: number[] = [averageQuotaInitialValue, ...valuesPerMonth.arrayQuotaInitialValue];
    const arrayBalance: number[] = [averageBalance, ...valuesPerMonth.arrayBalance];
    const arrayBalanceInArrears: number[] = [averageBalanceInArrears, ...valuesPerMonth.arrayBalanceInArrears];
    const arrayAvailableValue: number[] = [averageAvailableValue, ...valuesPerMonth.arrayAvailableValue];

    const result: AccountTypeFromLevelOfIndebtednessAndOpenDebenture = {
      obligationType,
      obligationQuantity,
      arrayQuota,
      arrayInitialQuota,
      arrayBalance,
      arrayBalanceInArrears,
      arrayAvailableValue
    };

    return result;

    // return [
    //   arrayQuota,
    //   arrayQuotaInitialValue,
    //   arrayBalance,
    //   arrayBalanceInArrears,
    //   arrayAvailableValue
    // ]
  }

  private getValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues(months: any[]): ValuesPerMonthFromLevelOfIndebtednessAndOpenDebentureValues {
    let arrayQuota: number[] = [];
    let arrayQuotaInitialValue: number[] = [];
    let arrayBalance: number[] = [];
    let arrayBalanceInArrears: number[] = [];
    let arrayAvailableValue: number[] = [];

    months.forEach((month) => {
      const { cuota, cupoInicial, valorDisponible, saldo, saldoMora } = month;
      arrayQuota.push(cuota || 0);
      arrayQuotaInitialValue.push(cupoInicial || 0);
      arrayBalance.push(saldo || 0);
      arrayBalanceInArrears.push(saldoMora || 0);
      arrayAvailableValue.push(valorDisponible || 0);
    });

    return {
      arrayQuota,
      arrayQuotaInitialValue,
      arrayBalance,
      arrayBalanceInArrears,
      arrayAvailableValue
    }
  }

  private getActivePortfoliosFromAllPortfolios(portfolios: LegalCuentaCartera[]): LegalCuentaCartera[] {
    const activatedPortfolios: LegalCuentaCartera[] = portfolios.filter((item: LegalCuentaCartera) => {
      const paymentStatusCode: string = item.Estados.EstadoPago.codigo || '00';
      return this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedPortfolios;
  }

  private getInactivePortfoliosFromAllPortfolios(portfolios: LegalCuentaCartera[]): LegalCuentaCartera[] {
    const activatedPortfolios: LegalCuentaCartera[] = portfolios.filter((item: LegalCuentaCartera) => {
      const paymentStatusCode: string = item.Estados.EstadoPago.codigo || '00';
      return !this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedPortfolios;
  }

  private getActiveCreditCardsFromAllCreditCards(creditCards: LegalTarjetaCredito[]): LegalTarjetaCredito[] {
    const activatedCreditCards: LegalTarjetaCredito[] = creditCards.filter((creditCard: LegalTarjetaCredito) => {
      const paymentStatusCode: string = creditCard.Estados.EstadoPago.codigo || '00';
      return this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedCreditCards;
  }

  private getInactiveCreditCardsFromAllCreditCards(creditCards: LegalTarjetaCredito[]): LegalTarjetaCredito[] {
    const activatedCreditCards: LegalTarjetaCredito[] = creditCards.filter((creditCard: LegalTarjetaCredito) => {
      const paymentStatusCode: string = creditCard.Estados.EstadoPago.codigo || '00';
      return !this.helperInfoService.isActiveCardOrPortfolioByPaymentStatusCode(paymentStatusCode);
    });

    return activatedCreditCards;
  }

  private getAllPortfoliosFromOriginalResponse(response: any): LegalCuentaCartera[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];
    const result: LegalCuentaCartera[] = response.data[0].Informes.Informe.CuentaCartera ? response.data[0].Informes.Informe.CuentaCartera : [];

    return !Array.isArray(result) ? [result] : result;
  }

  private getAllCreditCardsFromOriginalResponse(response: any): LegalTarjetaCredito[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];
    const result: LegalTarjetaCredito[] = response.data[0].Informes.Informe.TarjetaCredito ? response.data[0].Informes.Informe.TarjetaCredito : [];

    return !Array.isArray(result) ? [result] : result;
  }

  private getAllSavingAccountsFromOriginalResponse(response: any): LegalCuentaAhorro[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];
    const result: LegalCuentaAhorro[] = response.data[0].Informes.Informe.CuentaAhorro ? response.data[0].Informes.Informe.CuentaAhorro : [];

    return !Array.isArray(result) ? [result] : result;
  }

  private getAllCheckingAccountsFromOriginalResponse(response: any): LegalCuentaCorriente[] {
    if (!response ||
      !response.data[0] ||
      !response.data[0].Informes ||
      !response.data[0].Informes.Informe
    ) return [];
    const result: LegalCuentaCorriente[] = response.data[0].Informes.Informe.CuentaCorriente ? response.data[0].Informes.Informe.CuentaCorriente : [];

    return !Array.isArray(result) ? [result] : result;
  }



}
