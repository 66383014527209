import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class RelatedDocumentsService {
  public entryTransactionsApi: string;
  public entryTransactionsApiToken: string;

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.entryTransactionsApi = environment.entryTransactionsApi;
    this.entryTransactionsApiToken = environment.entryTransactionsApiToken;
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {apiUrl, entryTransactionsApi,entryTransactionsApiToken} = apis
      this.entryTransactionsApi = entryTransactionsApi;
      this.entryTransactionsApiToken = entryTransactionsApiToken;
       console.log('config ci cd', apiUrl);
    });
  }

  public createRelatedDocument(body) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.entryTransactionsApiToken));

    const options = {
      headers,
    };

    return this.http.post(
      `${this.entryTransactionsApi}/buyerrelations`,
      body,
      options
    );
  }

  public getRelatedDocuments(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.entryTransactionsApiToken));

    const options = {
      headers,
      params
    };

    return this.http.get(
      `${this.entryTransactionsApi}/buyerrelations`,
      options
    );
  }

  public validateAutexKey(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.entryTransactionsApiToken));

    const options = {
      headers,
      params
    };

    return this.http.get(
      `${this.entryTransactionsApi}/transactions/autexpassword`,
      options
    );
  }

  public deleteRelatedDocument(params) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    headers = headers.set("Authorization", 'Basic ' + btoa(this.entryTransactionsApiToken));

    const options = {
      headers,
      params
    };

    return this.http.delete(
      `${this.entryTransactionsApi}/buyerrelations`,
      options
    );
  }
}
