import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-open-obligations-table',
  templateUrl: './legal-open-obligations-table.component.html',
  styleUrls: ['./legal-open-obligations-table.component.scss']
})
export class LegalOpenObligationsTableComponent implements OnInit {

  @Input() openObligationsDataTable: any;

  totalValorInicial: number = 0;
  totalSaldoActual: number = 0;
  totalSaldoMora: number = 0;
  totalValorCuota: number = 0;
  totalUso: number = 0;

  constructor() { }

  ngOnInit() {
    this.calculateTotals();
  }

  calculateTotals() {
    let totalInicial = 0;
    let totalActual = 0;
    let totalMora = 0;
    let totalCuota = 0;
    let totalUso = 0;
  
    const obligations = Array.isArray(this.openObligationsDataTable) ? this.openObligationsDataTable : [this.openObligationsDataTable];
  
    obligations.forEach(obligation => {
      if (obligation.Valores && obligation.Valores.Valor) {
        const valorInicial = obligation.Valores.Valor.valorInicial || 0;
        const saldoActual = obligation.Valores.Valor.saldoActual || 0;
        const saldoMora = obligation.Valores.Valor.saldoMora || 0;
        const cuota = obligation.Valores.Valor.cuota || 0;
  
        const porcentajeUso = valorInicial > 0 ? (saldoActual / valorInicial) * 100 : 0;
        
        obligation.porcentajeUso = porcentajeUso.toFixed(2);
  
        totalInicial += valorInicial;
        totalActual += saldoActual;
        totalMora += saldoMora;
        totalCuota += cuota;
        totalUso += porcentajeUso;  
      }
    });
  
    this.totalValorInicial = totalInicial;
    this.totalSaldoActual = totalActual;
    this.totalSaldoMora = totalMora;
    this.totalValorCuota = totalCuota;
    this.totalUso = totalInicial !== 0 ? (totalActual / totalInicial) * 100 : 0;  
  }
  

}
