import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { Observable, Subscription } from 'rxjs';



@Component({
  selector: 'app-upload-excel-document',
  templateUrl: './upload-excel-document.component.html',
  styleUrls: ['./upload-excel-document.component.scss']
})
export class UploadExcelDocumentComponent implements OnInit {

  public files: File[] = [];
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File> = new EventEmitter();
  public subs1: Subscription;
  public subsRemoveFiles: Observable<any> = this.subject.stateFileModal$;

  constructor(public modal: ModalService, public subject: SubjectService) {
   this.subs1 = this.subsRemoveFiles.subscribe(() => {
      this.onRemove(0);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs1.unsubscribe();
  }

  // DropZone
  onSelect(event) {
    this.fileSelected = event.addedFiles[0];
    this.files.push(event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileSelected = null;
  }

  // DropZone
  uploadFile() {
    this.modal.close();
    this.chargeResult.emit(this.fileSelected);
    this.subject.addFiles([this.fileSelected]);
  }
}

