import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntryTransactionsService {

  constructor() { }

  public entryTransactionsTabs: any[] = [
    {
      href: '#individualTransactions',
      description: 'Transacciones individuales',
      icon: 'icon-tab-individual.svg',
      key: 'individual-transactions'
    },
    {
      href: '#specialTransactions',
      description: 'Transacciones Especiales',
      icon: 'icon-tab-individual.svg',
      key: 'special-transactions'
    },
  ];

  public mockInvoiceChargues = [
    {
      documentType: "C",
      document: 70785063,
      recordTitle: "PRB63220922",
      value: 2500,
      recordDate: "2022-10-19",
      queryCode: 517144,
      error: null,
      message: null
    },
    {
      documentType: "C",
      document: 70785063,
      recordTitle: "PRB64220922",
      value: 3000,
      recordDate: "2022-09-17",
      queryCode: 517144,
      error: null,
      message: null
    }
  ]
}
