import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AppState } from "@app/redux/app.reducer";
import { Store } from "@ngrx/store";
import { GetQuotaResponseModel } from "../models/request/QuotaRequest.model";
import * as QuotaActions from "@app/redux/actions/quota.actions";
import Swal from "sweetalert2";
import { HelperService } from "@app/services/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import { QuotaHeadingTablesService } from "../services/quota-heading-tables.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuotaGreaterService } from "@app/services/quota-greater.service";

@Component({
  selector: "app-query-quota",
  templateUrl: "./query-quota.component.html",
  styleUrls: ["./query-quota.component.scss"],
})
export class QueryQuotaComponent implements OnInit, OnDestroy {
  @ViewChild("backingStudy") backingStudy: ElementRef<HTMLElement>;
  @ViewChild("buyerInformation") buyerInformation: ElementRef<HTMLElement>;
  @ViewChild("holderValidation") holderValidation: ElementRef<HTMLElement>;
  @ViewChild("centralRiskSummary") centralRiskSummary: ElementRef<HTMLElement>;
  @ViewChild("locationContactResult")
  locationContactResult: ElementRef<HTMLElement>;
  @ViewChild("mainCustomers") mainCustomers: ElementRef<HTMLElement>;
  @ViewChild("runningProyects") runningProyects: ElementRef<HTMLElement>;
  @ViewChild("references") references: ElementRef<HTMLElement>;
  @ViewChild("requestDescription") requestDescription: ElementRef<HTMLElement>;
  @ViewChild("currentExhibitions") currentExhibitions: ElementRef<HTMLElement>;
  @ViewChild("profitAndLoss") profitAndLoss: ElementRef<HTMLElement>;
  @ViewChild("financialReasons") financialReasons: ElementRef<HTMLElement>;
  @ViewChild("descriptionChange") descriptionChange: ElementRef<HTMLElement>;

  public quotaRequest: number;
  public getQuotaResponse: GetQuotaResponseModel;

  //
  public quotaData: any = {};
  public generalBalanceList: Array<any> = [];
  public stateList: Array<any> = [];
  public financialReasonsList: Array<any> = [];

  public scrollButtons: Array<{
    description: string;
    target: HTMLElement | string;
  }> = [];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  // offset page
  public isShowAnFixedBar: boolean;
  topPosToStartShowing = 100;
  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // console.log('scrollPosition', scrollPosition);
    if (window.pageYOffset >= 110) {
      this.isShowAnFixedBar = true;
    } else {
      this.isShowAnFixedBar = false;
    }
  }

  constructor(
    private store: Store<AppState>,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public titles: QuotaHeadingTablesService,
    private quotaGreater: QuotaGreaterService
  ) {}

  ngOnInit() {
    this.store
      .select("quotas")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => this.processQuotaResponse(data));
    this.initializeScrollButtons();

    //Saca lo centrales de riesgos
    this.quotaGreater.getBanks().subscribe((resp) => {});
    this.quotaGreater.getRequestType().subscribe((resp) => {});
    this.quotaGreater.getTelephoneType().subscribe((resp) => {});
    this.quotaGreater.getDocumentsType().subscribe((resp) => {});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(QuotaActions.getQuotasReset());
  }

  initializeScrollButtons() {
    this.scrollButtons = [
      {
        description: "Estudio de respaldo",
        target: this.backingStudy.nativeElement,
      },
      {
        description: "Información de comprador",
        target: this.buyerInformation.nativeElement,
      },
      {
        description: "Validaciones de titular y relacionados",
        target: this.holderValidation.nativeElement,
      },
      {
        description: "Resumen centrales de riesgo",
        target: this.centralRiskSummary.nativeElement,
      },
      {
        description: "Resultado de localización y contacto",
        target: this.locationContactResult.nativeElement,
      },
      {
        description: "Principales clientes",
        target: this.mainCustomers.nativeElement,
      },
      {
        description: "Proyectos en ejecución",
        target: this.runningProyects.nativeElement,
      },
      { description: "Referencias", target: this.references.nativeElement },
      {
        description: "Descripción de solicitud",
        target: this.requestDescription.nativeElement,
      },
      {
        description: "Exposición",
        target: this.currentExhibitions.nativeElement,
      },
      { description: "P&G", target: this.profitAndLoss.nativeElement },
      {
        description: "Estudio Cupo",
        target: this.financialReasons.nativeElement,
      },
      {
        description: "Descripción cambio de partida",
        target: this.descriptionChange.nativeElement,
      },
    ];
  }

  getQuotas() {
    this.spinner.show();
    this.store.dispatch(QuotaActions.getQuotas({ id: this.quotaRequest }));
    this.spinner.hide();
  }

  processQuotaResponse(data: any) {
    // console.log('processQuotaResponse', data);

    if (!data.quota) {
      this.quotaData = {};
    }

    if (data.loaded) {
      this.quotaData = data.quota.data[0];
      let list = this.quotaData.idMacro.generalBalance
        ? this.quotaData.idMacro.generalBalance
        : [];
      this.generalBalanceList = this.helper.convertToArray(list);
      this.stateList = this.helper.convertToArray(
        this.quotaData.idMacro.statesPG
      );
      this.financialReasonsList = this.helper.convertToArray(
        this.quotaData.idMacro.financialReasons
      );
      this.setTitles();
      this.setTitlesState();
      this.setFinancialReasonos();
      this.spinner.hide();
    }

    if (data.error) {
      this.quotaData = {};
      this.spinner.hide();
      Swal.fire({
        type: "error",
        title: "No hay datos asociados al radicado",
        text: "Por favor verifique el número de radicado",
      });
    }
  }

  get isQuotaDataNull(): boolean {
    return Object.entries(this.quotaData).length === 0;
  }

  private setTitles() {
    this.generalBalanceList.forEach((item, index) => {
      this.generalBalanceList[index] = {
        ...this.generalBalanceList[index],
        title: this.titles.generalBalanceTitles[index],
      };
    });
  }

  private setTitlesState() {
    this.stateList.forEach((item, index) => {
      this.stateList[index] = {
        ...this.stateList[index],
        title: this.titles.stateTitles[index],
      };
    });
  }

  private setFinancialReasonos() {
    this.financialReasonsList.forEach((item, index) => {
      this.financialReasonsList[index] = {
        ...this.financialReasonsList[index],
        title: this.titles.financialReasonsTitles[index],
      };
    });
  }
}
