import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ModalService } from '@app/services/modal.service';
import { TabsService } from '@app/services/tabs.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { SearchAffiliateModel } from '../models/request/SearchAffiliateModel';
import {
  AffiliatesListModel,
  AffiliatesResponse,
} from '../models/response/SearchAffiliate.model';
import { BackupsStoreService } from '../services/backups-store.service';
import { TransactionsToBackUpResponseModel } from '../models/response/TransactionsToBackupResponse';
import { TransactionsToBackUpRequestModel } from '../models/request/TransactionsToBackupModel';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-backups-tabs',
  templateUrl: './backups-tabs.component.html',
  styleUrls: ['./backups-tabs.component.scss'],
})
export class BackupsTabsComponent implements OnInit, OnDestroy {
  public affiliate: AffiliatesListModel = new AffiliatesListModel();
  public subs1: Subscription = new Subscription();
  public navItemTabs: any[] = [];
  public navItemTabsRestringed: any[] = [];
  public navItemTabsRestringedSac: any[] = [];

  public searchAffiliate: SearchAffiliateModel = new SearchAffiliateModel();
  public affiliateList: AffiliatesListModel[] = [];
  public searchBy: string = 'document';
  public getTransactionsRequest: TransactionsToBackUpRequestModel =
    new TransactionsToBackUpRequestModel();
  public transactionsToBackupResponse: TransactionsToBackUpResponseModel[] = [];
  public isChangeValue: boolean = false;
  public totalAccessActionBackings = true;
  public totalAccessActionBackingsSac = true;
  public onlyBackingsRead = false;
  public message = '';
  public userName: any;

  public activeTab$: Observable<string> =
    this.backupsStore.stateBackupsActiveTab$;
    public backupsTabsRestringed: any[] = [
      {
        href: '#title-value',
        // description: 'Títulos valor',
        description: 'Gestionar respaldos',
        icon: 'icon-tab-massive.svg',
        key: 'title-value'
      }
    ]

    public backupsTabsRestringedSac: any[] = [
      {
        href: '#claims',
        description: 'Documentos',
        icon: 'icon-tab-reserves.svg',
        key: 'claims'
      },
    ]

  constructor(
    public modal: ModalService,
    public helper: HelperService,
    public backupsStore: BackupsStoreService,
    public backupsStoreRestringed: BackupsStoreService,
    public backupsStoreRestringedSac: BackupsStoreService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private backups: BackupsService,
    private tabs: TabsService,
    private roles: RolesService
  ) {
    this.navItemTabs = this.tabs.backupsTabs;
    this.setTab(this.navItemTabs[0]);
  }

  ngOnInit() {
    this.findPermissions();
    this.getProfile();
    if (this.totalAccessActionBackings === false) {
      this.navItemTabsRestringed = this.backupsTabsRestringed;
    }
    if (this.totalAccessActionBackingsSac === false) {
      this.navItemTabsRestringedSac = this.backupsTabsRestringedSac;
    }
  }

  ngOnDestroy() {}

  findPermissions() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {
        response.data.forEach((item) => {
          if (item.hasOwnProperty('actions')) {
            if (item.actions.hideBackingsOperations === true) {
              this.isChangeValue = true;
            }
          }
        });
      });
    }
  }

  getParams() {
    const { documentType, document } = this.activatedRoute.snapshot.params;
    if (documentType && document) {
      this.searchAffiliate.documentType = documentType;
      this.searchAffiliate.document = document;
      this.getAffiliate();
    } else {
      this.handleNoUrlParams();
    }
  }

  getAffiliate() {
    this.backups.getAffiliate(this.searchAffiliate).subscribe(
      (affiliatesResponse: AffiliatesResponse) => {
        if (affiliatesResponse && affiliatesResponse.ok === false) {
          this.handleGetAffiliateError();
          return;
        }
        const affiliate: AffiliatesListModel = affiliatesResponse['data'][0];
        this.backupsStore.changeStateAffiliate(affiliate);
      },
      (error: any) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  handleGetAffiliateError(error?: any) {
    console.error(error);
    if (error && error.error) {
      const { message } = error.error;
      Swal.fire({ type: 'error', title: 'Error', text: `${message}` });
    } else {
      this.helper.handleUndefinedError();
    }
    this.spinner.hide();
  }

  setTab(tab: any) {
    this.backupsStore.setActiveTab(tab.key);
    //console.log(tab.key);
    this.backupsStore.changeStateAffiliate(new AffiliatesListModel());
  }

  handleNoUrlParams(error?: any) {
    this.router.navigate(['pages/respaldos']);
    if (error) {
      Swal.fire({
        type: 'info',
        text: `${error['error']['message']}`,
      });
    }
  }
  getProfile() {
    this.userName = JSON.parse(localStorage.getItem('user'));
    let permissions = null;
    if (this.userName) {
      this.message += ` ${this.userName.user}`;
      this.roles.findByUsername(this.userName.user).subscribe(
        (resp) => {
          console.log(resp);
          let response: any = resp;
          permissions = response.data.find(
            (element) => element.description == 'backing'
          );

          if (permissions && permissions.actions) {
            this.handleBackingsTab(permissions);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  handleBackingsTab(permissions: any) {
    if (permissions.actions['back-name-field'] === true) {
      this.totalAccessActionBackings = false;
    }
    if (permissions.actions['view-document'] === true) {
      this.totalAccessActionBackingsSac = false;
    }
    if(permissions.actions['only-backings-read'] === true){
      this.onlyBackingsRead = true;
    }
  }
}
