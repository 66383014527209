export class LegalNatural {
    ctid: string;
    document: number;
    documentType: string;
    document2: number;
    documentType2: string;
    socialReason: string;
    lastname1: string;
    lastname2: string;
    name1: string;
    name2: string;
    verificationCode: string;
}
