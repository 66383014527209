
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PaginationComponent } from "@app/components/layout/pagination/pagination.component";
import { BackupsService } from "@app/services/backups.service";
import { ExpressReportService } from "@app/services/express-report.service";
import { HelperService } from "@app/services/helper.service";
import { SelectsService } from "@app/services/selects.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { DataResAffilate, DataSearchRisk, OptionsPercentage, ResAffilate } from "../backups/models/response/ExpressReport";
import { BackupsTableHeadersService } from "../backups/services/backups-table-headers.service";

@Component({
  selector: 'app-excess-risk',
  templateUrl: './excess-risk.component.html',
  styleUrls: ['./excess-risk.component.scss']
})



export class ExcessRiskComponent implements OnInit{
  @ViewChild("paginationComponent") paginationComponent: PaginationComponent;
  @ViewChild("bodyTable") bodyTable: ElementRef;

  docTypes: any;
  dataSearchRisk: DataSearchRisk = new DataSearchRisk();
  dataTable: DataResAffilate[] = [];
  optionsPercentage = OptionsPercentage;
  user: string;
  dataUpdatePercentage: DataResAffilate = new DataResAffilate();
  totalPages: number;
  page = 1;
  errorTextDocumentType: boolean = false;
  errorTextDocument: boolean = false;
  public showButton: boolean=false;

  constructor(
    public selects: SelectsService,
    private spinner: NgxSpinnerService,
    private backupsService: BackupsService,
    public helper: HelperService,
    private expressReport: ExpressReportService,
    public  tableHeaders: BackupsTableHeadersService,

  ) {}

  ngOnInit(): void {
    this.getDoccuments();
    this.user = localStorage.getItem('usuarioLog');
  }

  getDoccuments() {
    this.selects.getSelectData().subscribe((resp: any) => {
      console.log(resp)
      this.docTypes = resp.documentTypes;
    });
  }

  findAffiliateByDocument() {
    this.spinner.show();
    this.dataSearchRisk.currentPage = this.page;
    this.expressReport.findAffiliateByDocument(this.dataSearchRisk).subscribe(
      (res: ResAffilate) => {
       this.spinner.hide();
       this.dataTable = res.data;
       this.totalPages = res.totalPages;
      },
      (error: any) => {
        this.error(error);
      })
  }

  error(error: any): void {
    this.spinner.hide();
      Swal.fire({
        type: "error",
        title: "",
        text: `${error.error.message}`,
      });
  }

  onSelectChange( percentage: string, dataTable: DataResAffilate) {
    this.spinner.show();
    dataTable.excessRisk.percentage = parseInt(percentage, 10);;
    dataTable.excessRisk.updateUser = this.user;
    const dataSendUpdate = dataTable.excessRisk;

    this.expressReport.updateExcessRisk(dataSendUpdate).subscribe(
      (res: ResAffilate) => {
       this.spinner.hide();
       Swal.fire({
        type: "info",
        title: "",
        text: `${res.message}`,
      });
      this.findAffiliateByDocument();
      },
      (error: any) => {
        this.error(error);
      })
  }

  goToPage(page: any) {
    console.log(page)
    this.page = page.currentPage;
    this.findAffiliateByDocument()
  }

  refresh() {
    this.dataTable =[];
    this.dataSearchRisk.documentType=this.docTypes;
    this.dataSearchRisk.document=null;

  }
}
