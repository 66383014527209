import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "docTypeName",
})
export class DocTypeNamePipe implements PipeTransform {
  transform(value: any): any {
    let docTypeName: string;

    let docTypeList: any = JSON.parse(
      localStorage.getItem("form20m_documentTypes")
    );

    if (docTypeList) {
      docTypeList.forEach((item) => {
        if (value === item.type) {
          docTypeName = item.description;
        }
      });

      return docTypeName;
    }
  }
}
