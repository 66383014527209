import { Component, Input, OnInit } from '@angular/core';
import { CreditInfoHeadersService } from '../../services/credit-info-headers.service';

@Component({
  selector: 'app-legal-basic-information-table',
  templateUrl: './legal-basic-information-table.component.html',
  styleUrls: ['./legal-basic-information-table.component.scss']
})
export class LegalBasicInformationTableComponent implements OnInit {

  @Input() dataTable: any;

  constructor(private creditInfoHeades: CreditInfoHeadersService) { }

  ngOnInit() {
  }

}
