import { Component, Input, OnInit } from '@angular/core';
import { ConsultedBy } from '../../services/legal-person-adapter.service';

@Component({
  selector: 'app-legal-historic-query-table',
  templateUrl: './legal-historic-query-table.component.html',
  styleUrls: ['./legal-historic-query-table.component.scss']
})
export class LegalHistoricQueryTableComponent implements OnInit {

  @Input() consultedBy: ConsultedBy | undefined = undefined;

  get getHeaders(): string[] {
    return this.consultedBy ? this.consultedBy.headers : [];
  }

  get getRecords(): string[][] {
    return this.consultedBy ? this.consultedBy.records : [];
  }

  constructor() { }

  ngOnInit() {
  }

  formattedHeader(date: string): string {
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const [month, year] = date.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    return `${monthNames[monthIndex]} ${year}`;
  }

}
