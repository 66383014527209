import { Pipe, PipeTransform } from '@angular/core';

// Importamos la constante que contiene las respuestas y descripciones
import RESPUESTAS_GENERO from '../pages/credit-info/constants';

@Pipe({
  name: 'experianRecognizeGenre'
})
export class ExperianRecognizeGenrePipe implements PipeTransform {

  transform(value: number | string): string {
    // Filtramos la constante para encontrar el objeto correspondiente según el valor de entrada
    const respuesta = RESPUESTAS_GENERO.find(item => item.respuesta === value || item.genero === value);

    // Si encontramos una coincidencia, devolvemos la descripción; si no, devolvemos un valor por defecto
    return respuesta ? respuesta.descripcion : 'N/A';
  }

}
