import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CodeSpecialParams } from "@app/models/codeSpecialParams.model";
import { DelimiteAsrTemp } from "@app/models/DelimiterAsrTemp.model";
import { ControlContractAsrTemp } from "@app/models/ControlContractAsrTemp.model";
import { DelimiterOperativeRequest } from "@app/models/request/DelimiteOperativeRequest.model";
import { ColumnsParamsResponse } from "@app/models/response/columnsParamResponse.model";
import { Response } from "@app/models/responseUtil.model";
import { ContractService } from "@app/services/contract.service";
import { HelperService } from "@app/services/helper.service";
import { FormContractService } from "../services/form-contract.service";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { DaysRefundAsrTemp } from "@app/models/DaysRetundAsrTemp";
import { TariffsProduct } from "@app/models/TariffsProduct.model";
import { ContractAddicControlsAsrTemp } from "@app/models/ContractAddicControlsAsrTemp";
import { ModalService } from "@app/services/modal.service";
import { Bag } from "@app/models/Bag.model";

@Component({
  selector: "app-operative-delimiter",
  templateUrl: "./operative-delimiter.component.html",
  styleUrls: ["./operative-delimiter.component.scss"],
})
export class OperativeDelimiterComponent implements OnInit {
  @Input() stepperNext: any;

  public operativeDelimiterForm: FormGroup;
  numberContract = 0;
  productName = "";

  public columnParam: ColumnsParamsResponse[] = [];
  public codeSpecial: CodeSpecialParams[] = [];
  public tariffsProduct: Bag[] = [];

  public quotaMax: TariffsProduct[] = [];

  private requestDelimiter: DelimiterOperativeRequest = null;
  private operativeDelimiter: DelimiteAsrTemp[] = [];
  private controlContract: ControlContractAsrTemp;
  private daysRetunds: DaysRefundAsrTemp[] = [];
  private contractAddicControlsAsrTemp: ContractAddicControlsAsrTemp;

  isView = false;

  globalVarious = false;

  constructor(
    private contractService: ContractService,
    private spinner: NgxSpinnerService,
    private formContract: FormContractService,
    public helper: HelperService,
    private fb: FormBuilder,
    public modal: ModalService,
    private FormContract: FormContractService
  ) {
    this.operativeDelimiterForm = this.fb.group({
      priceTariffs: [0],
      day: [{ value: 0, disabled: true }],
      postdated: [{ value: 0, disabled: true }],
      invoice: [{ value: 0, disabled: true }],
      letter: [{ value: 0, disabled: true }],
      pay: [{ value: 0, disabled: true }],
      own: [{ value: 0, disabled: true }],
      sales: [{ value: 0, disabled: true }],
      returnDeductible: [false],
      totalQuota: [{ value: 0, disabled: true }],
    });
  }

  ngOnInit() {
    this.formContract.init();
    this.numberContract = this.formContract.numberContract;
    this.productName = this.formContract.productName;
    this.operativeDelimiterForm.disable();
    if (this.productName) {
      this.getNumberTariffs();
    }

    if (this.productName === "GLOBALTO") {
      this.f.sales.enable();
      this.f.sales.setValue(0);
      this.f.sales.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.maxLength(13),
      ]);
    } else {
      this.f.totalQuota.setValidators(Validators.required);
    }

    this.f.priceTariffs.valueChanges.subscribe((value) => {
      this.f.totalQuota.setValue(value);
    });

    let reg_sep = new RegExp(/SEP\w+/g);
    let reg_glo = new RegExp(/GLO\w+/g);

    if (
      reg_sep.test(this.productName.toUpperCase()) ||
      reg_glo.test(this.productName.toLocaleUpperCase())
    ) {
      this.globalVarious = true;
    }

    if (this.formContract.currentPages >= 4) {
      this.operativeDelimiterForm.enable();
    }
    this.getTabColumn();
  }
  /* SETTING THE COLUMN OR INPUT */
  getNumberTariffs() {
    this.contractService.getPriceProduct(this.productName.toString()).subscribe(
      (resp) => {
        this.tariffsProduct = resp.data[0];
      },
      () => {
        this.tariffsProduct = [];
      }
    );

    this.contractService.getTariff().subscribe(
      (resp) => {
        this.quotaMax = resp.data[0];
      },
      () => {
        this.quotaMax = [];
      }
    );
  }

  getTabColumn() {
    this.contractService.getColumnOperative().subscribe(
      (resp: Response<ColumnsParamsResponse[]>) => {
        if (resp.ok && resp.data) {
          this.columnParam = resp.data[0];
          this.getCodeSpecial();
          this.setFormType(this.codeSpecial);
          console.log(this.formContract.currentPages);
          this.getDelimiterSaved();
          this.isView = true;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  //config form
  setFormType(listCodeSpecial: CodeSpecialParams[]) {
    listCodeSpecial.map((resp) => {
      this.operativeDelimiterForm.addControl(
        `${resp.codeSpecial}`,
        new FormControl(0)
      );

      this.f[resp.codeSpecial].disable();
      if (this.formContract.currentPages >= 4 && resp.subData === "product") {
        this.f[resp.codeSpecial].enable();
      }

      if (resp.maxValue) {
        this.f[resp.codeSpecial].setValidators([
          Validators.maxLength(resp.maxValue),
        ]);
      }
    });
  }

  isInputDisable(event, columnName: string, subData: string, value?: string) {
    if (subData != "product" && event == true) {
      return;
    }

    let columnsActived = this.codeSpecial.filter(
      (element) =>
        element.subData === subData && this.f[element.codeSpecial].value == 1
    );

    if (columnsActived.length == 1 && value != undefined && value != null && event == false) {
      Swal.fire({
        type: "error",
        title: "Error en Transacción",
        text: `Estimado usuario no puedes eliminar el producto`,
      }).then(() => {});
      this.f[columnsActived[0].codeSpecial].setValue(1);
      return;
    }

    switch (columnName) {
      case "CHECK_DAY":
        this.enableOrDisable(event, "day");
        break;
      case "POSTDATED":
        this.enableOrDisable(event, "postdated");
        break;
      case "INVOICES":
        this.enableOrDisable(event, "invoice");
        break;
      case "LETTER":
        this.enableOrDisable(event, "letter");
        break;
      case "PAY":
        this.enableOrDisable(event, "pay");
        break;
      case "OWN":
        this.enableOrDisable(event, "own");
        break;
    }
    //delete data

    this.deleteValueDelimiter(event, value);

    let codeSpecial = this.codeSpecial.filter(
      (element) => element.descModule === columnName
    );

    codeSpecial.map((element) => {
      if (element.subData != "product" && element.codeSpecial != 942) {
        this.enableOrDisable(event, element.codeSpecial);
      }
    });
  }

  getColumnsActived() {
    let columnsActived = this.codeSpecial.filter(
      (element) =>
        element.subData === 'product' && this.f[element.codeSpecial].value == 1
    );

    if(columnsActived.length > 0){
      return true
    }

    return false;
  }

  enableOrDisable(event: boolean, codeSpecial: string | number) {
    if (event) {
      this.f[codeSpecial].enable();
    } else {
      this.f[codeSpecial].setValue(0);
      this.f[codeSpecial].disable();
    }
  }

  setValueForm() {
    this.requestDelimiter.delimiters.map((element) => {
      let codeSpecial = this.getCodeSpecialColumn(element.pk.product);
      this.operativeDelimiter.concat(this.requestDelimiter.delimiters);

      if (element.pk.product === "V") {
        this.f.sales.setValue(element.documentValue);
      }

      codeSpecial.map((codeSpecial) => {
        if (
          codeSpecial.subData != "requiresQuotaDay" &&
          codeSpecial.subData != "product"
        ) {
          this.f[codeSpecial.codeSpecial].setValue(
            element[codeSpecial.subData]
          );
        }

        if (codeSpecial.subData == "requiresQuotaDay") {
          let requiresQuotaDay = element.requiresQuotaDay == "S" ? 1 : 0;
          this.f[codeSpecial.codeSpecial].setValue(requiresQuotaDay);
        }

        if (codeSpecial.subData == "product") {
          let product = element.pk.state == "A" ? 1 : 0;
          this.f[codeSpecial.codeSpecial].setValue(product);
        }

        let daysRetund: DaysRefundAsrTemp =
          this.requestDelimiter.daysRetundAsrTemps.find(
            (daysRetund) => daysRetund.pk.product == element.pk.product
          );

        if (daysRetund && codeSpecial.subData == "daysRefund_max") {
          this.f[codeSpecial.codeSpecial].setValue(daysRetund.superior);
        }

        if (daysRetund && codeSpecial.subData == "daysRefund_min") {
          this.f[codeSpecial.codeSpecial].setValue(daysRetund.lower);
        }

        this.f.returnDeductible.setValue(
          element.returnDeductible == "S" ? 1 : 0
        );

        this.isInputDisable(true, codeSpecial.descModule, "product");
      });
    });

    if (this.requestDelimiter.tempControl) {
      this.f.day.setValue(this.requestDelimiter.tempControl.day);
      this.f.postdated.setValue(this.requestDelimiter.tempControl.postdated);
      this.f.pay.setValue(this.requestDelimiter.tempControl.pay);
      this.f.invoice.setValue(this.requestDelimiter.tempControl.invoice);
      this.f.letter.setValue(this.requestDelimiter.tempControl.letter);
      this.f.own.setValue(this.requestDelimiter.tempControl.own);
    }

    if (this.requestDelimiter.addicControlsAsrTemp) {
      this.f.priceTariffs.setValue(
        this.requestDelimiter.addicControlsAsrTemp.quotaTotal
      );

      this.f.totalQuota.setValue(
        this.requestDelimiter.addicControlsAsrTemp.quotaTotal
      );
    }
  }

  changeValueSelect(event) {
    console.log(event.target.value);
    let value = this.quotaMax.find(
      (element) => element.price == event.target.value
    );
    this.columnParam.map((element) => {
      let codeSpecial = this.validateColumn(element.columnsParam.subData);
      codeSpecial.map((element) => {
        if (element.subData == "documentValue" && value) {
          this.f[element.codeSpecial].setValue(value.quotaMax);
        }
      });
    });
  }

  get f() {
    return this.operativeDelimiterForm.controls;
  }

  getCodeSpecial() {
    this.columnParam.map((resp) => {
      this.codeSpecial = this.codeSpecial.concat(resp.codeSpecialParams);
    });
  }
  /* GET THE INFORMATION */
  mapperService() {
    this.columnParam.map((element) => {
      let column = element.columnsParam.subData;
      let product = element.columnsParam.value;
      let delimiter = this.operativeDelimiter.find(
        (element) => element.pk.product == product
      );
      if (!delimiter) {
        this.getValueDelimiter(column, product);
        this.getValueDay(column, product);
      }
    });

    this.getValueControl();
    //Save get total quota
    this.contractAddicControlsAsrTemp =
      this.formContract.getStructContractAddicControlsAsrTemp(
        this.operativeDelimiterForm
      );
    this.contractAddicControlsAsrTemp.quotaTotal = this.f.priceTariffs.value;
    //Get value of sales
    if (this.productName === "GLOBALTO") {
      this.getValueSales();
    }
  }

  getValueSales() {
    let operative = this.requestDelimiter.delimiters.find(
      (element) => element.pk.product == "V"
    );
    let delmiter = this.formContract.getStructDelimiter("V", operative);

    delmiter.documentValue = this.f.sales.value;

    this.operativeDelimiter.push(delmiter);
  }

  //Delimiter saved
  getDelimiterSaved() {
    if (this.numberContract) {
      this.contractService
        .getDelimiterOperative(
          this.numberContract,
          this.formContract.user,
          this.productName
        )
        .subscribe(
          (resp) => {
            if (resp.ok == true && resp.data.length > 0) {
              this.requestDelimiter = resp.data[0];
              this.setValueForm();
              console.log(this.requestDelimiter);
            }
          },
          (error) => {
            console.error(error.error.message);
          }
        );
    }
  }

  //Delimiter build data
  getValueDelimiter(columnName: string, product: string) {
    let operative = this.requestDelimiter.delimiters.find(
      (element) => element.pk.product == product
    );
    //The exit is value por default
    let delimiter: DelimiteAsrTemp = this.formContract.getStructDelimiter(
      product,
      operative
    );
    //Validating if has a column
    let data: CodeSpecialParams[] = this.validateColumn(columnName);

    data.map((element) => {
      //Save data general
      if (
        element.subData != "product" &&
        element.subData != "daysRefund_max" &&
        element.subData != "daysRefund_min"
      ) {
        delimiter[element.subData] = this.f[element.codeSpecial].value;
      }

      //Valid the subData for save
      if (element.subData == "requiresQuotaDay") {
        delimiter[element.subData] = this.isYesOrNot(
          this.f[element.codeSpecial].value
        );
      }
      //Save day refund max
      if (element.subData == "daysRefund_max") {
        delimiter.daysRefund = this.f[element.codeSpecial].value;
      }
      //it's true return a S or return a N
      delimiter.returnDeductible = this.isYesOrNot(
        this.f.returnDeductible.value
      );
    });
    if (data.length > 0) {
      //add new object
      this.operativeDelimiter.push(delimiter);
      //console.log(delimiter);
    }
  }
  //Delete delimiter
  deleteValueDelimiter(event, columnName: string) {
    if (!event) {
      //Find delimiter operative and get position of object
      let index = this.operativeDelimiter.findIndex(
        (element) => element.pk.product == columnName
      );
      let indexSaved = this.requestDelimiter.delimiters.findIndex(
        (element) => element.pk.product == columnName
      );

      //if have one object, continue with process of delete
      if (index >= 0 || indexSaved >= 0) {
        //Valid that object exists
        let operative =
          index >= 0
            ? this.operativeDelimiter[index]
            : this.requestDelimiter.delimiters[indexSaved];
        //delete on memory's component
        this.operativeDelimiter.slice(index, 1);
        this.requestDelimiter.delimiters.slice(indexSaved, 1);
        this.getValueControl();
        //Call service of delete
        this.contractService
          .deleteDelimiterOperative(operative, this.controlContract)
          .subscribe((resp) => {});
      }
    }
  }

  //controlContract
  getValueControl() {
    this.controlContract = this.formContract.getStructControlContract(
      this.operativeDelimiterForm,
      this.requestDelimiter
    );
  }
  //Days Retund
  getValueDay(columnName: string, product: string) {
    let daysRefund: DaysRefundAsrTemp =
      this.formContract.getStructDayRetund(product);

    let data: CodeSpecialParams[] = this.validateColumn(columnName);

    data.map((element) => {
      if (element.subData == "daysRefund_min") {
        daysRefund.lower = this.f[element.codeSpecial].value;
      }

      if (element.subData == "daysRefund_max") {
        daysRefund.superior = this.f[element.codeSpecial].value;
      }
    });
    if (data.length > 0) {
      this.daysRetunds.push(daysRefund);
    }
  }
  /* FUNCTION GENERAL*/
  validateColumn(columnName: string): CodeSpecialParams[] {
    let data: CodeSpecialParams[] = this.formContract.filterCodeSpecial(
      columnName,
      this.codeSpecial
    );
    //Find product for save
    let isCheck: CodeSpecialParams = data.find(
      (element) => element.subData === "product"
    );
    if (!isCheck) {
      return [];
    }
    //get value for save
    let isSave = Number(this.f[isCheck.codeSpecial].value);
    if (!isSave) {
      return [];
    }
    return data;
  }

  getCodeSpecialColumn(columnName: string): CodeSpecialParams[] {
    let codeSpecial = this.columnParam.find(
      (element) => element.columnsParam.value == columnName
    );
    return codeSpecial != null ? codeSpecial.codeSpecialParams : [];
  }

  isYesOrNot(value: boolean): string {
    return value ? "S" : "N";
  }
  /* FUNCTION'S EXITING */
  save() {
    if (this.numberContract) {
      this.spinner.show();
      if(!this.getColumnsActived()){
        Swal.fire({
          type: "error",
          title: "Error en Transacción",
          text: `Estimado usuario debe seleccionar un producto`,
        }).then(() => {});
        this.spinner.hide()
        return;
      }
      this.mapperService();
      this.structDataSend();
      this.contractService
        .sendDelimiterOperative(this.requestDelimiter)
        .subscribe(
          (resp: Response<any>) => {
            if (resp.ok) {
              Swal.fire({
                type: "success",
                title: "Transacción exitosa",
              }).then(() => {
                this.formContract.setCurrentStep(5);
                this.stepperNext.next();
              });
            }
            this.spinner.hide();
          },
          (error: any) => {
            Swal.fire({
              type: "error",
              title: "Error en Transacción",
              text: `${error.error.message}`,
            }).then(() => {});
            this.spinner.hide();
          }
        );
    }
  }

  structDataSend() {
    this.requestDelimiter = {
      delimiters: this.operativeDelimiter,
      tempControl: this.controlContract,
      daysRetundAsrTemps: this.daysRetunds,
      addicControlsAsrTemp: this.contractAddicControlsAsrTemp,
    };
  }

  downloadExcel() {
    this.spinner.show();
    this.contractService.downloadExcel(this.numberContract).subscribe(
      (resp) => {
        this.helper.downloadFromBase64(resp.data[0], "reporte", ".xls");
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          type: "error",
          title: "Error en Transacción",
          text: `${error.error.message}`,
        }).then(() => {});
      }
    );
  }

  /* MODALS */
  openGlobalVarous() {
    this.modal.show("globalVarious");
  }

  openAmortization() {
    this.modal.show("amortization");
  }
}
