export class BagRequest {
    perPage:      number = 10;
    currentPage:   number = 1;
    productId:         number;
}

export class BagUpdateRequestModel {
    id:                      number;
    bondValue:               number;
    excessServicePercentage: number;
    bondMonth:               number;
    minimumMonthlyBilling:   number;
    serviceRate:             number;
    monthlyBilling:          number;
    semiannualBilling:       number;
    annualBilling:           number;
    numQuotas:           number = 0;
    paymentValue:            number;
    createdAt:               string;
    updatedAt:               string;
    productBag:              number;
    termOfUse:               number;
}
