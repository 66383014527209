import { CoviResourceService } from '@app/services/covi-resource.service';
import { ModalService } from '@app/services/modal.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { QuotaGreaterService } from '@app/services/quota-greater.service';
import { SelectsService } from '@app/services/selects.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { QuotaVsAffiliateHeadingTablesService } from '../services/quota-vs-affiliate-heading-tables.service';

@Component({
  selector: 'app-quota-vs-affiliate',
  templateUrl: './quota-vs-affiliate.component.html',
  styleUrls: ['./quota-vs-affiliate.component.scss']
})
export class QuotaVsAffiliateComponent implements OnInit {

  quotaVsAffiliateSearchForm: FormGroup;

  @ViewChild('formPqr') formPqr: NgForm;

  quotaVsAffiliateCreateForm: FormGroup;

  saveReserveForm: FormGroup;

  searchBySettlement: Boolean = true;

  isQuerySuccess: boolean = false;

  isReservesHistory: boolean = false;

  isContactPhone: boolean = false;

  reservationList: any;

  quotaVsAffiliateData: any;

  contactDataList: Array<any>;

  quotaMedia: any = 0;

  quotaGlobal: any = 0;

  docTypes: any;

  reservationDays: number;

  repeatedPhonesData: Array<any> = [];

  reservationListId: number;

  public totalPages = 1;

  public currentPage = 1;

  public totalPagesReserves = 1;

  public currentPageReserves = 1;

  public totalPagesPhones = 1;

  public currentPagePhones = 1;

  public totalPagesRepeatedPhones = 1;

  public currentPageRepeatedPhones = 1;

  public phoneRepeated;

  public documentInfo = {};

  stateList = [
    { value: "A", description: "Activo" },
    { value: "R", description: "Retirado" },
    { value: "S", description: "Suspendido" },
    { value: "B", description: "Bloqueado" },
  ]

  createFormData = {
    contract: "",
    state: "A",
    quotaValue: "",
    quotaAvailable: ""
  }

  quotaDummyData = {
    ok: true,
    message: "Finalizó correctamente la consulta.",
    data: {
      documentType: "C",
      document: 37391823,
      name: "SANDRA LILIANA MANTILLA CUADRADO",
      classification: "NUEVO",
      quotas: [
        {
          contract: 522297,
          name: "HEAVY MOGUL S.A.S",
          product: "SEP",
          state: "A",
          quotaAffiliate: 30000000,
          checkDay: 0,
          postDatedCheck: 0,
          invoice: 10000000,
          availableQuota: 30000000,
          toPay: 0,
          lyric: 0,
          ownDoc: 0,
          date: "2019-11-18 15:28:31",
          inUser: "DIVORT",
          dateUpdate: "2021-05-03 14:53:52",
          userUpdate: "COVIWEB"
        },
        {
          contract: 23423,
          name: "HEAVY MOGUL S.A.S",
          product: "SEP",
          state: "A",
          quotaAffiliate: 4353453,
          checkDay: 0,
          postDatedCheck: 0,
          invoice: 10000000,
          availableQuota: 657567,
          toPay: 0,
          lyric: 0,
          ownDoc: 0,
          date: "2019-11-18 15:28:31",
          inUser: "DIVORT",
          dateUpdate: "2021-05-03 14:53:52",
          userUpdate: "COVIWEB"
        },
      ]
    },
    status: 200,
    currentPage: this.currentPage,
    totalPages: this.totalPages
  }

  public isQuotaWithoutContract: boolean = false;

  public selectedQuota: any;

  public selectedReserve: any;
  public validationReserveList: Array<any> = [];
  public validationQuotaList: Array<any> = [];
  public reserveDaysList: Array<any> = [];
  public quotaLogList: Array<any> = [];

  public isCreateQuota: boolean = false;
  
  public showCreateQuotaTab: boolean = false;

  public citiesAndDepartments = [];

  constructor(private formBuilder: FormBuilder, private quotaGreater: QuotaGreaterService, private spinner: NgxSpinnerService, public selects: SelectsService, private toastr: ToastrService, public helper: HelperService, public headingTable: QuotaVsAffiliateHeadingTablesService, private listCovinoc: ListsCovinocService, private modal: ModalService, private coviResources: CoviResourceService) { }

  ngOnInit() {
    this.quotaVsAffiliateSearchForm = this.formBuilder.group({
      idPayer: ['', Validators.required],
      typeDocument: ['', Validators.required]
    });

    this.saveReserveForm = this.formBuilder.group({
      documentType: ['', Validators.required],
      document: ['', Validators.required],
      queryCode: ['', Validators.required],
      value: ['', Validators.required],
    });

    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesCode;
    });

    this.coviResources.initializeLists().subscribe((response) => {
      this.citiesAndDepartments = response['CIUDAD-DEPARTAMENTO'];
    });
  }

  get getIsQuerySuccess() {
    return this.isQuerySuccess;
  }

  get isValidCreateFormData() {
    let validCreateFormData: boolean = false;
    if(this.isQuotaWithoutContract === true) {
      return this.createFormData.quotaValue !== '';
    } else {
      return this.createFormData.quotaValue !== '' || this.createFormData.contract !== '';
    }
  }

  getDescriptionDocumentType(): string {
    const documentType = this.quotaVsAffiliateSearchForm.get('typeDocument').value;
    let selectedDocumentType: string;
    this.docTypes.forEach((document) => {
      if (document.value === documentType) {
        selectedDocumentType = document.description;
      }
    });
    return selectedDocumentType;
  }

  public goToPage($event) {
    this.spinner.show();
    this.isQuerySuccess = true;
    
    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: $event.currentPage,
      perPage: 10
    }
    this.quotaGreater.getBuyerQuotas(body).subscribe((resp: any) => {
      this.spinner.hide();
      this.selectedQuota = undefined;
      this.currentPage = resp.currentPage;
      this.quotaVsAffiliateData = resp.data[0];
      this.getGlobalAndMediaQuota(this.quotaVsAffiliateData);
      this.initList(this.validationQuotaList, this.quotaVsAffiliateData.quotas);
      console.log(this.validationQuotaList);
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  initList(listToInit: Array<any>, referenceList: Array<any>): void {
    referenceList.forEach((item, index) => {
      listToInit[index] = false
    });
  }

  toggleCreateQuota(isQuotaWithoutContract: boolean) {
    this.resetCreateFormData();
    this.isQuotaWithoutContract = isQuotaWithoutContract;
    this.showCreateQuotaTab = true;
  }

  onSubmitSearch() {
    this.spinner.show();
    this.isQuerySuccess = true;
    this.isReservesHistory = false;
    this.isContactPhone = false;
    
    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: this.currentPage,
      perPage: 10
    }
    this.quotaGreater.getBuyerQuotas(body).subscribe((resp: any) => {
      this.spinner.hide();
      this.currentPage = resp.currentPage;
      this.isReservesHistory = false;
      this.totalPages = resp.totalPages;
      this.quotaVsAffiliateData = resp.data[0];
      this.documentInfo = {documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value, document: this.quotaVsAffiliateSearchForm.get('idPayer').value }
      this.isAnyQuotaWithoutContract();
      this.getGlobalAndMediaQuota(this.quotaVsAffiliateData);
      this.initList(this.validationQuotaList, this.quotaVsAffiliateData.quotas);      
      this.logQuery(this.quotaLogList, this.quotaVsAffiliateData.quotas, 'SEE');
      this.quotaGreater.sendQuotaLog(this.quotaLogList).subscribe((response) => {
      });
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  logQuery(listToLog: Array<any>, listReference: Array<any>, actionLog: string) {
    
    listReference.forEach((item, index) => {
      if(item.id === 0) {
        listToLog.push({
          action: actionLog,
          documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
          document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
          contract: item.contract,
          checkDay: item.checkDay,
          postDatedCheck: item.postDatedCheck,
          invoice: item.invoice,
          toPay: item.toPay,
          lyric: item.lyric,
          ownDoc: item.ownDoc,
          topPensions: item.topPensions,
          state: item.state,
          oldState: item.state,
          quotaAffiliate: item.quotaAffiliate,
          quotaAffiliateOld: item.quotaAffiliate,
          userUpdate: item.userUpdate,
          userUpdateOld: item.userUpdate
        });
      }
    });
    
  }

  isAnyQuotaWithoutContract(): void {
    let quotaWithoutContractCounter: number = 0;
    this.quotaVsAffiliateData.quotas.forEach((quota) => {
      if(quota.id > 0) {
        quotaWithoutContractCounter += 1;
      }
    });
    
    this.isCreateQuota = quotaWithoutContractCounter > 0;
  }

  onSubmitSaveReserve() {
    this.saveReserveForm.value;
  }

  selectQuota(id: number) {
    this.selectedQuota = this.quotaVsAffiliateData.quotas[id];
    this.validationQuotaList[id] = true;
    this.disableQuotaValidations(id);
  }

  selectReserve(id) {
    this.selectedReserve = this.reservationList[id];
    console.log(this.selectedReserve);
    this.validationReserveList[id] = true;
    this.disableReserveValidations(id);
    this.changeReservationId(id);
  }

  disableReserveValidations(currentId) {
    this.validationReserveList.forEach((validation, id) => {
      if (currentId !== id) {
        this.validationReserveList[id] = false;
      }
    });
  }

  disableQuotaValidations(currentId) {
    this.validationQuotaList.forEach((validation, id) => {
      if (currentId !== id) {
        this.validationQuotaList[id] = false;
      }
    });
  }

  changeState(id, $event) {
    console.log($event.target.value);
    this.quotaVsAffiliateData.quotas[id].state = $event.target.value;
    console.log(this.quotaVsAffiliateData.quotas);
  }

  changeQuota(id, $event) {
    console.log($event);
    this.quotaVsAffiliateData.quotas[id].quota = $event;
    console.log(this.quotaVsAffiliateData.quotas);
  }

  changeReserveValue(id, $event) {
    console.log($event);
    //this.quotaVsAffiliateData.quotas[id].quota = $event;
    this.reservationList[id].value = $event;
    console.log(this.reservationList);
  }

  changeReservationDays(id, $event) {
    this.reserveDaysList[id] = $event;
    console.log(this.reserveDaysList);

  }

  changeReservationId(id) {
    this.reservationListId = id;
  }

  changeReserveQueryCode(id, $event) {
    console.log($event);
    //this.quotaVsAffiliateData.quotas[id].quota = $event;
    this.reservationList[id].queryCode = $event;
    console.log(this.reservationList);
  }

  goToPageReserves($event) {
    this.spinner.show();
    console.log("Selected Quota:", this.selectedQuota);
    
    this.saveReserveForm.get('documentType').setValue(this.quotaVsAffiliateSearchForm.get('typeDocument').value);
    this.saveReserveForm.get('document').setValue(this.quotaVsAffiliateSearchForm.get('idPayer').value);

    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: $event.currentPage,
      perPage: 10,
    }

    this.quotaGreater.getReserves(body).subscribe((response: any) => {
      this.spinner.hide();
      this.isReservesHistory = true;
      this.isQuerySuccess = false;
      this.isContactPhone = false;
      this.selectedReserve = undefined;
      this.totalPagesReserves = response.totalPages;
      this.currentPageReserves = response.currentPage;
      this.reservationList = response.data[0];
      this.initList(this.validationReserveList, this.reservationList);
      for (let i = 0; i <= this.quotaVsAffiliateData.quotas.length - 1; i++) {
        this.reserveDaysList[i] = this.reservationList[i].bookingDays;
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }


  getReserves() {
    this.spinner.show();
    console.log("Selected Quota:", this.selectedQuota);
    
    this.saveReserveForm.get('documentType').setValue(this.quotaVsAffiliateSearchForm.get('typeDocument').value);
    this.saveReserveForm.get('document').setValue(this.quotaVsAffiliateSearchForm.get('idPayer').value);

    const body = {
      documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      currentPage: 1,
      perPage: 10,
    }

    this.quotaGreater.getReserves(body).subscribe((response: any) => {
      this.spinner.hide();
      this.isReservesHistory = true;
      this.totalPagesReserves = response.totalPages;
      this.currentPageReserves = response.currentPage;
      this.reservationList = response.data[0];
      this.initList(this.validationReserveList, this.reservationList);
      for (let i = 0; i <= this.quotaVsAffiliateData.quotas.length - 1; i++) {
        this.reserveDaysList[i] = this.reservationList[i].bookingDays;
      }
    }, (error) => {
      console.log("Reserves");
      this.spinner.hide();
      this.isReservesHistory = true;
      this.toastr.error(error.error.message);
    });
  }

  getContactData() {

    this.isReservesHistory = false;
    this.isQuerySuccess = false;
    this.isContactPhone = true;
  }

  goToPagePhones($event) {
    const bodyParams = {
      tipoDocumento: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
      documento: this.quotaVsAffiliateSearchForm.get('idPayer').value,
      page: $event.currentPage,
      pageSize: 10,
    }

    this.isReservesHistory = false;
    this.isQuerySuccess = false;
    this.isContactPhone = true;
    this.quotaGreater.getContactData(bodyParams).subscribe((response: any) => {
      console.log(response);
      this.totalPagesPhones = response.totalPages;
      this.currentPagePhones = response.currentPage;
      this.contactDataList = response.data;
    });
  }

  showCreatePhoneModal() {
    this.modal.show('createPhone');
  }

  createQuota() {
    if(this.isQuotaWithoutContract) {
      this.createQuotaWithoutContract();
    } else {
      this.createQuotaContract();
    }
  }

  resetCreateFormData() {
    if(!this.isQuotaWithoutContract) {
      this.createFormData.contract = '';
    }
    this.createFormData.quotaValue = '';
  }

  createQuotaWithoutContract() {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;
    if(userUpdate) {
      const body = {
        id: null,
        documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
        document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
        state: this.createFormData.state,
        date: null,
        quota: this.createFormData.quotaValue,
        available: null,
        user: userUpdate,
      }
  
      this.quotaGreater.createBuyerQuotasWithoutContract(body).subscribe((resp:any) => {
        console.log(resp);
        this.spinner.hide();
        this.resetCreateFormData();
        this.isCreateQuota = true;
        this.goToPage({currentPage: this.currentPage});
        Swal.fire({
          title: `${resp.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
  }

  clickCell(phone) {
    this.phoneRepeated = phone;
    this.spinner.show();
    console.log("Click cell");
    const bodyParams = {
      telefono: phone,
      page: 1,
      pageSize: 10,
    }
    this.quotaGreater.getRepeatedPhones(bodyParams).subscribe((resp: any) => {
      console.log(resp);
      this.repeatedPhonesData = resp;
      this.spinner.hide();
      this.modal.show('repeated-phones');
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  goToRepeatedPhones($event) {
    this.spinner.show();
    console.log("Click cell");
    const bodyParams = {
      telefono: $event.phone,
      page: $event.currentPage,
      pageSize: 10,
    }
    this.quotaGreater.getRepeatedPhones(bodyParams).subscribe((resp: any) => {
      console.log(resp);
      this.repeatedPhonesData = resp;
      this.spinner.hide();
      this.modal.show('repeated-phones');
      Swal.fire({
        title: `${resp.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  createQuotaContract() {
    this.spinner.show();
    console.log(this.selectedQuota);
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;
    if(userUpdate) {
      const body = {
        contract: this.createFormData.contract,
        documentType: this.quotaVsAffiliateSearchForm.get('typeDocument').value,
        document: this.quotaVsAffiliateSearchForm.get('idPayer').value,
        state: this.createFormData.state,
        assignedQuota: this.createFormData.quotaValue,
        userUpdate: userUpdate,
      }
  
      this.quotaGreater.updateAssignedQuota(body).subscribe((resp: any) => {
        this.spinner.hide();
        this.resetCreateFormData();
        this.goToPage({currentPage: this.currentPage});
        Swal.fire({
          title: `${resp.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'info',
        });
      })
    }
    
  }

  onUpdateQuota(quota) {
    this.spinner.show();
    const userUpdate = JSON.parse(localStorage.getItem('user')).user;

    if(this.selectedQuota.name === "CUPO SIN CONTRATO") {
      if(userUpdate) {

        const body = {
          id: this.selectedQuota.id,
          state: this.selectedQuota.state,
          quota: this.selectedQuota.quotaAffiliate,
          available: this.selectedQuota.available,
          user: userUpdate
        }
        this.quotaGreater.updateBuyerQuotasWithoutContract(body).subscribe((response: any) => {
          this.spinner.hide();
          this.goToPage({currentPage: this.currentPage});
          Swal.fire({
            title: `${response.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      }
    } else {
      if(userUpdate) {
        const body = {
          contract: quota.contract,
          documentType: this.quotaVsAffiliateData.documentType,
          document: this.quotaVsAffiliateData.document,
          state: quota.state,
          quotaValue: quota.quotaAffiliate,
          userUpdate: userUpdate
        }
    
        this.quotaGreater.updateQuotas(body).subscribe((resp: any) => {
          this.spinner.hide();
          this.goToPage({currentPage: this.currentPage});
          Swal.fire({
            title: `${resp.message}`,
            text: ``,
            type: 'success',
          });
        }, (error) => {
          this.spinner.hide();
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        })
      }
      
    }
  }

  onSaveReserve() {
    this.spinner.show();

    const userUpdate = JSON.parse(localStorage.getItem('user')).user;
    if(userUpdate) {
      const body = {
        document: this.saveReserveForm.get('document').value,
        documentType: this.saveReserveForm.get('documentType').value,
        queryCode: this.saveReserveForm.get('queryCode').value,
        value: this.saveReserveForm.get('value').value,
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        user: userUpdate,
        webUser: userUpdate,
      }
  
      this.quotaGreater.saveReserve(body).subscribe((response: any) => {
        this.goToPageReserves({currentPage: this.currentPage});
        this.spinner.hide();
        this.selectedReserve = undefined;
        this.goToPageReserves({currentPage: this.currentPageReserves});
        Swal.fire({
          title: `${response.message}`,
          text: ``,
          type: 'success',
        });
      }, (error) => {
        this.spinner.hide();
        if(error.error.status === 418) {
          Swal.fire({
            title: `${error.error.data[0].message}`,
            text: ``,
            type: 'info',
          });
        } else {
          Swal.fire({
            title: `${error.error.message}`,
            text: ``,
            type: 'info',
          });
        }
      })
    }
  }

  backToSearch() {
    this.isReservesHistory = false;
    this.selectedQuota = undefined;
    this.saveReserveForm.reset();
    this.initList(this.validationQuotaList, this.quotaVsAffiliateData.quotas);
  }

  onCancelReserve() {
    this.spinner.show(); 
    const params = {
      purchaseOrder: this.selectedReserve.purchaseOrder
    }

    this.quotaGreater.cancelReserve(params).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response);
      this.goToPageReserves({currentPage: this.currentPageReserves});
      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })
  }

  onModifyReserve(selectedReserve) {
    const body = {
      purchaseOrder: selectedReserve.purchaseOrder,
      bookingDays: this.reserveDaysList[this.reservationListId],
      dateDO: selectedReserve.dateDO,
      stateDO: selectedReserve.stateDO
    }
    console.log(selectedReserve.stateDO);

    this.quotaGreater.updateReserve(body).subscribe((response: any) => {
      this.spinner.hide();
      this.selectedReserve = undefined;
      this.goToPageReserves({currentPage: this.currentPageReserves});
      console.log(response);

      Swal.fire({
        title: `${response.message}`,
        text: ``,
        type: 'success',
      });
    }, (error) => {
      this.spinner.hide();
      Swal.fire({
        title: `${error.error.message}`,
        text: ``,
        type: 'info',
      });
    })

  }

  getGlobalAndMediaQuota(quotas: any) {
    this.quotaGlobal = 0;
    quotas.quotas.forEach(quota => {
      this.quotaGlobal += quota.quotaAffiliate;
    });
    this.quotaMedia = this.quotaGlobal / quotas.quotas.length;
  }

}
