import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackupsTransactionsRequest } from '../../models/request/BackupsTransactions.model';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class BackingsInvoicesService {

  private apiUrl: string = '';
  private backingsToken: string = '';

  constructor(private http: HttpClient, private ciCd: CiCdConfigService) {
    this.apiUrl = environment.apiUrl
    this.backingsToken = environment.backingsToken;
    this.ciCdInit();
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({apis}: any) => {
      const {
        apiUrl,
        backingsToken
      } = apis;
      this.apiUrl = apiUrl
      this.backingsToken = backingsToken
    });
  }

  getBillsInfo(body: BackupsTransactionsRequest) {
    const authToken = localStorage.getItem('tokenASR');
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa(this.backingsToken),
      'token': authToken
    });

    const options = {
      headers,
    };

    return this.http.post<any>(
      `${this.apiUrl}backingsPostgreSQL/backings/getBillsInfo`,
      body,
      options
    );
  }
}
