import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AccountTypeFromPercentageOfArrearsByTypeOfObligation, PercentageOfArrearsByTypeOfObligation } from '../../services/legal-person-adapter.service';
import { SafePercentPipe } from '../../pipes/safe-percent.pipe';

@Component({
  selector: 'app-legal-percentage-of-arrears-by-type-of-obligation',
  templateUrl: './legal-percentage-of-arrears-by-type-of-obligation.component.html',
  styleUrls: ['./legal-percentage-of-arrears-by-type-of-obligation.component.scss']
})
export class LegalPercentageOfArrearsByTypeOfObligationComponent implements OnInit, OnChanges {

  @Input() percentageOfArrearsByTypeOfObligation: PercentageOfArrearsByTypeOfObligation | undefined = undefined;

  constructor(
    private safePercentagePipe: SafePercentPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('=====> LLENANDO NUEVA TABLAAAA:::', changes["percentageOfArrearsByTypeOfObligation"].currentValue)
  }

  ngOnInit() {
  }

  getValueOfAccountTypeInSpecificMonth(accountType: AccountTypeFromPercentageOfArrearsByTypeOfObligation, month: string): any {
    const { monthsWithValue = [] } = accountType;
    const foundMonth = monthsWithValue.find((item) => item.month === month);
    const value = foundMonth ? foundMonth.value : 0;
    const formatPercentage = this.safePercentagePipe.transform(value, '1.0-1');
    return (value > 0) ? formatPercentage : '-';
  }

  getTotalForSpecificMonth(month: string): any {
    const { totalesPerMonth = [] } = this.percentageOfArrearsByTypeOfObligation;
    const foundMonth = totalesPerMonth.find((item) => item.month === month);
    const value = foundMonth ? foundMonth.value : 0;
    const formatPercentage = this.safePercentagePipe.transform(value, '1.0-1');
    return (value > 0) ? formatPercentage : '-';
  }

  formatAccountName(accountName: string): string {
    const array = accountName.split('-');

    // Según la respuesta esta compuesto por "Numero-Letras", sin embargo en el pdf solo se muestra las letras
    if(array[0] && array[1]) return array[1];

    return accountName;
  }

}
