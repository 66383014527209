import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SpecialBill } from "@app/models/global-billing.models";
import { GloballBillingService } from "@app/services/globall-billing.service";
import { ModalService } from "@app/services/modal.service";
import { SpecialCaseBillingService } from "./special-case-billing.service";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { HelperService } from "@app/services/helper.service";

@Component({
  selector: "app-special-case-billing",
  templateUrl: "./special-case-billing.component.html",
  styleUrls: ["./special-case-billing.component.scss"],
  providers: [DatePipe],
})
export class SpecialCaseBillingComponent implements OnInit {
  @ViewChild('editInvoice') editInvoice!: ElementRef
  public registerSelected: any[] = [];
  public newNumberInvoice: any = "";
  public idBag: number;
  public idInstallment: number;
  public invoiceDate: any;
  cantPages = 1;
  currentPage = 1;
  tableHeard = [
    "Número de bolsa",
    "Número de cuota",
    "Nit",
    "Afiliado",
    "Contrato",
    "Primera factura",
    "Sucursal",
    "Tipo de facturación",
    "Corte de facturación",
    "Fecha factura",
    "Valor cuota",
    "Número de factura",
    "Actualizar factura",
  ];
  specialBills: SpecialBill[] = [];

  constructor(
    private globalBillingService: GloballBillingService,
    public modal: ModalService,
    public communication: SpecialCaseBillingService,
    public modifyInstallment: GloballBillingService,
    private datePipe: DatePipe,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.getSpecialBill();
  }

  refresh() {
    this.currentPage = 1;
    this.getSpecialBill();
  }

  getSpecialBill() {
    this.globalBillingService.getSpecialBill(this.currentPage, 10).subscribe(
      (resp) => {
        this.specialBills = resp.data;
        this.cantPages = resp.totalPages;
        console.log("Estos son los datos de la cuota", this.specialBills);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  goToPage($event) {
    const { currentPage } = $event;
    this.currentPage = currentPage;
    this.getSpecialBill();
  }

  onNumberInvoiceEdit(event: any, report: any) {
    this.registerSelected = report;
    const newValue = event.target.textContent.trim();
    report.numero_factura = newValue ? parseInt(newValue, 10) : 0; 
    if (isNaN(report.numero_factura)) {
      report.numero_factura = 0; 
    }
 
    this.newNumberInvoice = report.numero_factura;
    this.idBag = report.id_bolsa;
    this.idInstallment = report.id_fglobal_cuotas;
    this.invoiceDate = report.fecha_factura;
  }
  

  isInvoiceNumberValid(report: any): boolean {
    return (this.newNumberInvoice)!==0
  }
  isInvoiceNumber(report: any): boolean {
    return this.newNumberInvoice!==null
  }

  saveChangesInstallment(report: any) {
    const body = {
      id_bolsa: this.idBag,
      id_cuota: this.idInstallment,
      fecha_factura: this.formatDate(this.invoiceDate),
      numero_factura: parseInt(this.newNumberInvoice),
    };
    this.modifyInstallment.patchEditInstallments(body).subscribe(
      () => {
        Swal.fire({
          title: "Actualización de Cuota",
          text: "La cuota fue actualizada con éxito",
          type: "success",
          confirmButtonText: "OK",
        });
      },
      (error) => {
        Swal.fire({
          title: "Actualización de Cuota",
          text: error.error.message,
          type: "error",
          confirmButtonText: "OK",
        });
      }
    );
    this.refresh();
  }

  formatDate(date: string): string {
    const dateFormat = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    return dateFormat;
  }
}
