import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { GeneralProfileRecord } from '../../services/natural-person-adapter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-table-general-profile-history',
  templateUrl: './table-general-profile-history.component.html',
  styleUrls: ['./table-general-profile-history.component.scss']
})
export class TableGeneralProfileHistoryComponent implements OnInit, OnDestroy {

  public headersTable: string[] = ['Sectores', 'Sector Financiero', 'Sector Cooperativo', 'Sector Real', 'Sector Telcos',
    'Total Sectores', 'Total como Principal', 'Total como Codeudor y Otros'
  ];

  public generalProfileRecords: GeneralProfileRecord[] = [];
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
    .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.generalProfileRecords = response.generalProfileRecords;
  }

}
