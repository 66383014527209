import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntryTransactionsTabsService {

  public invoicesMassiveChargues = [
    'Código de consulta del afiliado',
    'Tipo de documento comprador',
    'Documento comprador',
    'Número del título valor',
    'Valor',
    'Fecha de vencimiento',
    'Observaciones',
  ];

  constructor() { }
}
