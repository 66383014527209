import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackupsService } from '@app/services/backups.service';
import { ModalService } from '@app/services/modal.service';
import { SubjectService } from '@app/services/subject.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DocumentRequestModel } from '../../models/request/DocumentRequest.model';

@Component({
  selector: 'app-title-value-masive',
  templateUrl: './title-value-masive.component.html',
  styleUrls: ['./title-value-masive.component.scss'],
})
export class TitleValueMasiveComponent implements OnInit, OnDestroy {
  public activeContent = 'empty-state';
  public radioSelected = '';

  private files$: Observable<File[]> = this.subject.stateFileModal$;
  private filesCharged: File[] = [];
  private fileRequest: DocumentRequestModel = new DocumentRequestModel();
  private readonly unsubscribe$: Subject<void> = new Subject();


  constructor(private spinner: NgxSpinnerService,
    public modal: ModalService,
    public subject: SubjectService,
    private backups: BackupsService,
    
    public router: Router) { }

  ngOnInit() {
    this.files$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (files: File[]) => {
        this.filesCharged = files;
        this.fileRequest.excelFile = this.filesCharged.map((file: File) => file) || [];
        if (this.fileRequest.excelFile) {
          this.uploadMassiveBackings();
        } else {
          this.handleNoFileCharged();
        }
      }
    );
   
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  downloadExcel() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.modal.show('fileUpload');
    }, 3000);
  }

  showModalUpload() {
    this.modal.show('fileUpload');
  }

  uploadMassiveBackings() {
    this.activeContent = 'massive-charge-state';
    this.backups.uploadMassiveBackings(this.fileRequest).subscribe(
      (resp: any) => {
        console.log(resp);
        this.activeContent = 'massive-charge-state';
      }, (error: any) => {
        console.error(error);
      }
    );
  }

  goToStart() {
    this.activeContent = 'empty-state';
  }

  handleNoFileCharged() {
    Swal.fire({
      type: 'error',
      title: 'Cargue fallido',
      text: 'No se cargó ningún archivo',
    });
  }

}