import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetsuiteInvoicesHeadersService {

  public netsuiteInvoicesHeaders: any[] = [
    {
      href: '#invoices-chargued',
      description: 'Facturas disponibles para envio nuevas',
      icon: 'icon-tab-individual.svg',
      key: 'invoices-chargued'
    },
    {
      href: '#invoices-non-chargued',
      description: 'Facturas no Cargadas',
      icon: 'icon-tab-individual.svg',
      key: 'invoices-non-chargued'
    },
    {
      href: '#invoices-rejected',
      description: 'Info Rechazadas',
      icon: 'icon-tab-individual.svg',
      key: 'invoices-rejected'
    },
  ]

  public tableHeadersNetsuiteInvoicesLoaded: string[] = [
    "Contrato",
    "Código consulta",
    "Forma",
    "Fecha",
    "Valor",
    "Factura",
    "Fecha facturación",
    "Fecha límite pago",
    "Fecha proceso",
    "Iva"
]

  public tableHeadersNetsuiteInvoicesNotLoaded: string[] = [
    'Seleccionar',
    "Contrato",
    "Código consulta",
    "Forma",
    "Fecha",
    "Valor",
    "Factura",
    "Fecha facturación",
    "Fecha límite pago",
    "Fecha proceso",
    "Iva"
]

  constructor() { }
}
