import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectModel } from '@app/models/select.model';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { TabsService } from '@app/services/tabs.service';
import { PersonDocument } from './models/person-document';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss']
})
export class SearcherComponent implements OnInit {

  public navItemTabs: any[] = [];
  documentTypes: SelectModel[] = [];
  @Input() isNonPayment: boolean = true;

  @Output() personData: EventEmitter<PersonDocument> = new EventEmitter<PersonDocument>();
  @Output() isFormValidate: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('formSearchAffiliate') formSearchAffiliate: NgForm;

  data: PersonDocument = {
    documentType : '',
    document     : null
  }
    

  constructor(private tabs: TabsService, public helper: HelperService, private listService: ListsCovinocService) {
    this.navItemTabs = this.tabs.adminQuotaTabs;
    this.findAllLists();
  }

  ngOnInit() {
  }

  getIsValidateForm(): void {
    this.isFormValidate.emit(this.formSearchAffiliate.form.invalid)
  }

  onSubmit(){
    this.personData.emit(this.data);
  }

  findAllLists(): void {
    this.listService.findAllLists().subscribe(
      (res) => {
        if(res.status != 200) this.helper.badResponse(res.message, res.status);
        this.documentTypes = res.data[0]['documentTypesCode'];
      },
      error => this.helper.handleErrorHttp(error, 'Ocurrió un Error')
    );
  }

  validRequest(): boolean {
    return this.data.documentType && this.data.document && parseInt(this.data.document) > 0;
  }

}
