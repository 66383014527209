export class QuotaRadicationRequest {
    perPage: string = '10';
    currentPage: string = '1';
    requestType: string;
    requestState: string;
    idRadQuota: string;
    initialDate: string;
    finalDate: string;
    docAffiliate: string;
    user: string;
    states: Array<number>;
    types: Array<number>;
    assignedBag: string;
}
