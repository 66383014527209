import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { RadicationQuotaAdminService } from "@app/services/radication-quota-admin.service";
import Swal from "sweetalert2";
import { Profiles } from "../../models/response/responseProfile";
import { ProfileCurrent } from "../../models/response/responseProfileRequest";
import { Response } from "../../../../models/responseUtil.model";
import { UserProfile } from "../../models/userProfile.model";
import { RadicationService } from "../../services/radication-quota.service";

@Component({
  selector: "app-assig-profile",
  templateUrl: "./assig-profile.component.html",
  styleUrls: ["./assig-profile.component.scss"],
})
export class AssigProfileComponent implements OnInit {
  @ViewChild("formCreate") formCreate: NgForm;

  @Input() isTab: string = "";
  @Output() setTab = new EventEmitter<string>();

  @Output() setEdit = new EventEmitter<boolean>();

  @Output() setView = new EventEmitter<boolean>();

  filterPost = "";

  public assigUserProfile: UserProfile;

  public userName = "";
  public profileManagment: Array<ProfileCurrent>;
  public data: Array<Profiles> = [];

  public navItemTabs: any[] = [];

  constructor(
    private radicationServiceAdmin: RadicationQuotaAdminService,
    public radicationService: RadicationService
  ) {
    this.navItemTabs = this.radicationService.radicationTabsAdmin;
  }

  ngOnInit() {
    this.assigUserProfile = this.radicationServiceAdmin.userProfile;
    this.getDataCurrentProfile();
    localStorage.removeItem("editProfile");
    this.setEdit.emit(false);
    this.setView.emit(false);
  }

  getDataCurrentProfile() {
    this.radicationServiceAdmin.getProfileCurrent(1, 50).subscribe(
      (response: Response<ProfileCurrent>) => {
        this.profileManagment = response.data;
        this.profileManagment.forEach((element) => {
          let profile: Profiles = {
            id: element.id,
            description: element.name,
            isActive: element.isActive,
          };
          this.data.push(profile);
        });
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  checkAllAssig(event) {
    let isCheck = event.target.checked;
    this.data.forEach((element) => {
      element.isActive = isCheck;
      if (isCheck) {
        this.assigUserProfile.profiles.push(element.id);
      } else {
        this.assigUserProfile.profiles = [];
      }
    });
  }

  saveForm() {
    this.radicationServiceAdmin
      .saveAssigProfileUser(this.assigUserProfile)
      .subscribe(
        (response: Response<any>) => {
          Swal.fire({
            type: "success",
            title: "Felicidades",
            text: `${response.message}`,
          }).then(() => {
            this.setTab.emit(this.navItemTabs[0].key);
          });
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: `${error.error.message}`,
          });
          localStorage.setItem(
            "ERROR_ADMIN_ASSIG",
            JSON.stringify(this.assigUserProfile)
          );
        }
      );
  }

  checkProfile(data: ProfileCurrent, event) {
    let isCheck = event.target.checked;
    if (isCheck) {
      this.assigUserProfile.profiles.push(data.id);
    } else {
      this.assigUserProfile.profiles = this.assigUserProfile.profiles.filter(
        (element) => element != data.id
      );
    }
  }
}
