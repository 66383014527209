import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';
import { DocumentValidity, ExpirianListsRequest, ExpirianListsResponse, SectorCode } from '../models/expirian-lists.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpirianListsService {

  private responseExpirianList = new BehaviorSubject<ExpirianListsResponse | undefined>(undefined);
  public responseExpirianList$ = this.responseExpirianList.asObservable();

  public dev07ApiUtl: string = '';
  public headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private ciCd: CiCdConfigService
  ) {
    this.ciCdInit()
  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { dev07ApiUtl, experianToken } = apis;
      this.dev07ApiUtl = dev07ApiUtl;
      this.headers = this.headers.set(
        "Authorization",
        "Basic " + btoa(experianToken)
      );
    });
  }

  setResponseExpirianList(newValue: ExpirianListsResponse | undefined) {
    this.responseExpirianList.next(newValue);
  }

  /**
   * Servicio que obtiene todas las listas de códigos que posee expirian para interpretar datos
   * Ejemplo: 00 = vencido, así con diferentes listas
   * @param request 
   * @returns Un array de listas de valores claves y sus significados
   */
  getExpirianLists(request?: ExpirianListsRequest): Observable<ExpirianListsResponse> {
    const options = { headers: this.headers, params: { ...request } };
    return this.http.get<ExpirianListsResponse>(`${this.dev07ApiUtl}covifacturatransactions/api/v1/transactions/getAllListsExperian`, options);
  }

  findSectorByCode(codeSector: string | number): SectorCode | undefined {
    // console.log('Código del sector recibido: ', codeSector);
    if(codeSector === null || codeSector === undefined) return undefined;

    const response: ExpirianListsResponse | undefined = this.responseExpirianList.getValue();
    if(!response) return undefined;

    const searched: SectorCode | undefined = response.data[0]['codigo-sector'].find((item: SectorCode) => item.codigo == codeSector);
    // console.log('Sector encontrado::', searched);
    return searched;
  }

  findDocumentValidityByCode(code: string): DocumentValidity | undefined {
    if(code === null || code === undefined) return undefined;

    const response: ExpirianListsResponse | undefined = this.responseExpirianList.getValue();
    if(!response) return undefined;

    const searched: DocumentValidity | undefined = response.data[0].vigenciaCedula.find((item: DocumentValidity) => item.codigoEstado == code);

    return searched;
  }

  

}
