import * as moment from 'moment';

export class BackingsToProcessResponse {
    ok:                    boolean;
    data: BackingsToProcessModel[];
    status:                 number;
    currentPage:            number;
    totalPages:             number;
    file?:                  string;
    message?:               string;
}

export class BackingsToProcessModel {
    // fase 2  OK fm
    affiliateName:         string;
    refundValue:         string;
    affiliateDocumentType: string;
    affiliateDocument:     string;
    draftNumber:           string;
    issuerName:            string;
    deductible:            string;
    issuerDocumentType:    string;
    issuerDocument:        string;
    guaranteedValue:       number;
    payments:              number;
    claimedValue:          number;
    contractProduct:       string;
    deductiblePercentage:  number;
    exonerations:          number;
    nonRefundCause:   string = '';
    postDated:               Date;
    filingNumber:          number;
    filingStatus:     string = '';
    maxClaimDate:          string;
    paymentDate:           string;
    claimDays:             number;
    refundDays:            number;
    filingDate:            string;
    contract:              number;
    newPayment:            number;
    sinisterProduct:        string;
    recoveredDeductible:    string;
    branchOffice:           string;
    recoveredRefund:        string;
    nonPaymentNoticeDays:   string;
    recoveryValue:          number;

    public static parseDate(date: any): any {
        if (date) {
            return moment(date).toDate() || '';
        }
        return '';
    }

    public static fromResponse(json: any): BackingsToProcessResponse {
        const response: BackingsToProcessResponse = {
            ok: json.ok,
            data: json.data ? json.data.map((backing: BackingsToProcessModel) => {
                return {
                    ...backing,
                    filingDate: this.parseDate(backing.filingDate),
                }
            }) : [],
            status: json.status || 0,
            currentPage: json.currentPage,
            totalPages: json.totalPages,
            file: json.file,
            message: json.message
        }
        return response;
    }

}
