import { Component, Input, OnInit } from '@angular/core';
// import { idMacro, QuotaDataForm } from 'src/app/models/quote-form-model';

@Component({
  selector: 'app-description-change',
  templateUrl: './description-change.component.html',
  styleUrls: ['./description-change.component.scss']
})
export class DescriptionChangeComponent implements OnInit {
 
  @Input() quotaData: any;

  constructor() { }

  ngOnInit() {
  }
}
