import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AssignationFileData, DataSearchMultipleFiles, FileData, ImagesFiles, SendFilesMultiples, SourcesData } from '../models/request/claims';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {
  dataGetFiles:  DataSearchMultipleFiles = new  DataSearchMultipleFiles();
  sourcesData = SourcesData;
  
  dataUser: any;
  category: string = 'RECLAMACIONES';
  docTypes: any;
  dataCv: any = [];
  cv: string;
  dataFile: any = [];
  numberPageFile = 1;
  numberPageCv = 1;
  totalPages = 0;
  totalPagesCv: number;
  files: File[] = [];
  fileSelected: File;
  filterSelect: string;
  assignationFileDataList: AssignationFileData[] = [
    {
      document: 0,
      documentType: '',
      uploads: []
    }
  ];
  isChangeValue: boolean = false;
  selectedAssignationFileData: AssignationFileData;
  

  constructor 
  (
    private listCovinoc: ListsCovinocService, 
    public helper: HelperService, 
    private backups: BackupsService,
    private spinner: NgxSpinnerService,
    private roles: RolesService
  ) { }

  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem('identity'));
    this.getDoccuments();
  }

  

  getDoccuments() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesNew;
    });
  }

  searchCv(cv: string) {
    this.cv = cv;
    this.searchDataCv();
  }

  searchDataCv() {
    this.spinner.show();
    this.backups.getSearchCv(this.cv,this.numberPageCv,10).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.dataCv = data.data[0];
        this.dataFile = [];
        this.totalPagesCv = data.totalPages;
        if((data.data[0].length === 0 )|| (data.data === null)) {
          Swal.fire({
            title: ``,
            text: `no se encuentra información`,
            type: 'info',
          });
        }
        //console.log(data,'data cv');
        
      }, (error: any) => {
        this.dataCv = [];
        this.spinner.hide();
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
        //console.log(error)
      }
    );
  }

  searchDocuments(dataFiles:  DataSearchMultipleFiles){
    this.dataGetFiles.document = dataFiles.document;
    this.dataGetFiles.documentType = dataFiles.documentType;
    this.dataGetFiles.currentPage = this.numberPageFile;
    this.searchFiles();
  }

  searchFiles() {
    this.spinner.show();
    this.dataGetFiles.category = 'RECLAMACIONES';
    this.backups.postSearchFiles(this.dataGetFiles).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.dataFile = data.data;
        this.dataCv = [];
        this.totalPages = data.totalPages;
        
        if(data.data.length === 0 || (data.data === null)) {
          Swal.fire({
            title: ``,
            text: `no se encuentra información`,
            type: 'info',
          });
        }
        //console.log(data,'files');
     
      }, (error: any) => {
        this.dataFile = [];
        this.spinner.hide();
        //console.log(error)
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
      }
    );
  }

  changePage(page: number) {
    this.dataGetFiles.currentPage = page;
    this.searchFiles();
  }
  
  changePageFile(page: number) {
    this.numberPageCv = page;
    this.searchDataCv();
  }

  allRemove(event) {
    this.files = [];
  }

  // DropZone
  uploadFile() {
    //this.chargeResult.emit(this.fileSelected);
    //this.subject.addFiles([this.fileSelected]);
  }

  postSaveFiles() {
    this.spinner.show();
    const sendFilesMultiples: ImagesFiles = {
      images:  [],
    }
    for(let element of this.assignationFileDataList) {
      for(let file of element.uploads) {
        sendFilesMultiples.images.push({
          document: element.document,
          documentType: element.documentType,
          userName: this.dataUser.username,
          fileName: file.fileName,
          fileExtension: file.fileExtension,
          category: this.category,
          source: "ASR",
          image: file.image
        })
      }
     
    }

    this.backups.postSaveFiles(sendFilesMultiples).subscribe(
      (data: any) => {
        this.spinner.hide();
       // console.log(data,'files');
        this.files = [];
        this.assignationFileDataList= [
          {
            document: 0,
            documentType: '',
            uploads: []
          }
        ];
        Swal.fire({
          title: `Los datos se guardaron correctamente`,
          text: ``,
          type: 'info',
        });
     
      }, (error: any) => {
        this.spinner.hide();
        //console.log(error)
        Swal.fire({
          title: `${error.error.message}`,
          text: ``,
          type: 'error',
        });
        this.files = [];
        this.assignationFileDataList= [
          {
            document: 0,
            documentType: '',
            uploads: []
          }
        ];
      }
    );
  }

  searchFilter(filter) {
    this.files = [];
    this.assignationFileDataList= [
      {
        document: 0,
        documentType: '',
        uploads: []
      }
    ];
    this.filterSelect = filter;
    this.dataFile = [];
    this.dataCv = [];
  }

  openModal(assignationFileData: AssignationFileData) {
    this.selectedAssignationFileData = assignationFileData;
  }

  closeModal() {
    this.selectedAssignationFileData = null;
  }

  deletefractionate(id: number) {
    this.assignationFileDataList.splice(id, 1);
  }

  addFractionate() {
     const fractionate = {
      document:0,
      documentType: "C",
      uploads: []
    };

    this.assignationFileDataList.push(fractionate);
  }

  saveDataFile(uploads: FileData[]) {
     this.selectedAssignationFileData.uploads = uploads;
     //console.log(this.assignationFileDataList);
  }
}
