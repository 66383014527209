import { Component, EventEmitter, Input, Output } from "@angular/core";
import { element } from "@angular/core/src/render3";
import { SubjectService } from "@app/services/subject.service";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  @Input() bytes: number;
  @Input() extensions: string;
  @Input() fileLimit: number; // New input for file limit
  public files: File[] = [];
  private filesCorrect: File[] = [];
  private filesWErrors: File[] = [];
  public fileSelected: File;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  @Output() chargeResultWErrors: EventEmitter<File[]> = new EventEmitter();
  public subs1: Subscription;
  public subsRemoveFiles: Observable<any> = this.subject.stateFileModal$;
  constructor(public subject: SubjectService, private toastr: ToastrService) {}

  ngOnInit() {}

  onSelect(event) {
    if (this.fileLimit && (event.addedFiles.length > this.fileLimit) || (this.filesCorrect.length + 1) > this.fileLimit) {
      event.addedFiles = event.addedFiles.slice(0, this.fileLimit);
      this.toastr.error(`Solo puedes seleccionar hasta ${this.fileLimit} archivos.`);
      return;
    }
    this.fileSelected = event.addedFiles[0];
    this.files = event.addedFiles;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileSelected = null;
    this.chargeResultWErrors.emit(this.files)
  }

  onRemoveAll(){
    this.files = null;
  }

  uploadFile() {
    if ((this.filesCorrect.length + 1) > this.fileLimit) {
      Swal.fire({
        type: "warning",
        title: "Carga de Archivos",
        text: `Solo puedes cargar hasta ${this.fileLimit} archivos.`,
      });
      this.filesCorrect = [];
      return;
    }
  
    let messageFileSize = "";
    this.files.forEach((element) => {
      if (element.size > this.bytes) {
        this.filesWErrors.push(element);
        messageFileSize = messageFileSize + " " + element.name + ",";
      } else {
        this.filesCorrect.push(element);
      }
    });
  
    this.files = this.filesCorrect;
    this.chargeResult.emit(this.filesCorrect);
    this.chargeResultWErrors.emit(this.filesWErrors);
    this.subject.addFiles([this.fileSelected]);
    if (this.filesWErrors.length > 0) {
      Swal.fire({
        type: "warning",
        title: "Carga de Archivos",
        text:
          "Los siguientes archivos excenden el límite de bytes permitidos " +
          messageFileSize,
      }).then(() => {});
    }
    this.fileSelected = null;
  }
}
