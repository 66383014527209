import { CurrencyPipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { DateRangeComponent } from "@app/components/date-range/date-range.component";
import { PaginationComponent } from "@app/components/layout/pagination/pagination.component";
import { ModalEditSettlementComponent } from "@app/components/modals/modal-edit-settlement/modal-edit-settlement.component";
import { ListResponse } from "@app/models/listResponse.model";
import { SelectModel } from "@app/models/select.model";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Subject } from "rxjs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Response } from "../../../models/responseUtil.model";
import { DocsFilingQuota } from "../models/docsFilingQuota.model";
import { LegalNatural } from "../models/legalNatural.model";
import { QuotaRadication } from "../models/quotaRadication.model";
import { QuotaRadicationRequest } from "../models/request/quotaRadicationRequest.model";
import { RadicationService } from "../services/radication-quota.service";

@Component({
  selector: "app-my-requests",
  templateUrl: "./my-requests.component.html",
  styleUrls: ["./my-requests.component.scss"],
  providers: [CurrencyPipe],
})
export class MyRequestsComponent implements OnInit {
  @Input() status: boolean = false;
  @Output() setStatus = new EventEmitter<Boolean>();

  @ViewChild("paginationComponent") paginationComponent: PaginationComponent;
  @ViewChild("dateRange") dateRangeComponent: DateRangeComponent;

  public requestTypes: Array<SelectModel>;
  public requestStates: Array<SelectModel>;

  public configModalConfirm: SweetAlertOptions = {
    title: "Abono cancelado",
    text: "Al seleccionar otra radicación el abono se cancela.",
    type: "warning",
    // showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    // confirmButtonText: 'Aceptar y continuar',
    // cancelButtonText: 'Cancelar'
  };

  public quotaRadications: QuotaRadication[] = [];
  public quotaRadicationRequest: QuotaRadicationRequest =
    new QuotaRadicationRequest();

  public finalDateInput: Date;
  public finalDate: any;
  public initDate: any;
  public cantPages = 0;
  public currentPage = 1;
  public counterRequest: number = 0;
  public activeTab: string = "";
  public parentTab: string = "title-value";
  public listResponse: ListResponse;

  public onShow: boolean = false;
  public onShowPag: boolean = false;
  public search: QuotaRadicationRequest = new QuotaRadicationRequest();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public extensions: string =
    ".gif,.jpeg,.jpg,.png,pdf,.docx,.doc,.xlsx,.xls,.txt,.msg";
  public bytes: number = 7340032;

  public profile: number;

  public quotaRadicationsToAssign: QuotaRadication[] = [];

  public searchAffiliate: boolean = false;
  public searchUser: boolean = false;
  private states: Array<number> = [];
  private types: Array<number> = [];

  @ViewChild("modalSettlement") modalSettlement: ModalEditSettlementComponent;

  constructor(
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    public radicationService: RadicationService,
    private quotaRadicationService: QuotaRadicationService,
    public modal: ModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initilizeDateRangePicker();
    this.quotaRadicationService.getLists().subscribe((resp) => {
      if (resp.status == 200 && resp.data) {
        let listResponse = resp.data[0];

        this.requestTypes = listResponse.requestTypeSettlements;

        this.requestStates = listResponse.requestStatus;

        this.requestStates.forEach((element) => {
          this.states.push(Number(element.value));
        });

        this.requestTypes.forEach((element) => {
          this.types.push(Number(element.value));
        });

        let optionAll: SelectModel = new SelectModel();
        optionAll.value = "0";
        optionAll.description = "TODOS";

        this.requestStates.push(optionAll);
        this.requestTypes.push(optionAll);

        this.getQuotaRadications(1);

        this.status = false;
        this.setStatus.emit(this.status);
      }
    });
    this.profile = JSON.parse(localStorage.getItem("profile"));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToPage({ currentPage }) {
    this.currentPage = currentPage;
    this.getQuotaRadications(currentPage);
  }

  onSelectRequestType(requestType: string) {
    this.search.requestType = requestType;
    delete this.search.user;
    delete this.quotaRadicationRequest.user;
    this.getQuotaRadications(1);
  }

  onSelectFilterState(requestState: string) {
    this.search.requestState = requestState;
    delete this.search.user;
    delete this.quotaRadicationRequest.user;
    this.getQuotaRadications(1);
  }

  onDateRange([startDate, endDate]) {
    console.log("REPEAT 2")
    this.search.initialDate = moment(startDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");

    this.search.finalDate = moment(endDate)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    delete this.search.user;
    delete this.quotaRadicationRequest.user;
    this.getQuotaRadications(1);
  }

  onAffiliate(docAffiliate: any) {
    this.search.docAffiliate = docAffiliate;
    this.searchAffiliate =
      this.search.docAffiliate &&
      this.search.docAffiliate != "" &&
      this.search.docAffiliate.length >= 8 &&
      this.search.docAffiliate.length <= 10
        ? true
        : false;
  }

  searchByAffiliate() {
    this.getQuotaRadications(1);
  }

  onUser(user: any) {
    this.search.user = user;
    this.searchUser = this.search.user && this.search.user != "" ? true : false;
  }

  searchByUser() {
    this.getQuotaRadications(1);
  }

  getQuotaRadications(currentPage: number): void {
    this.spinner.show();
    this.counterRequest++;
    this.search.currentPage = "" + currentPage;
    if (this.search.requestState == "0") delete this.search.requestState;
    if (this.search.requestType == "0") delete this.search.requestType;
    if (this.search.docAffiliate == "") delete this.search.docAffiliate;
    this.search.states = this.states;
    this.search.types = this.types;
    this.quotaRadications = [];
    if (this.profile == 5 || this.profile == 6) {
      this.search.assignedBag = "1";
    }
    this.quotaRadicationService
      .getQuotaRadicationByFilter(this.search)
      .subscribe(
        (response: Response<QuotaRadication>) => {
          if (response && response.ok === false && response.status != 404) {
            this.handleGetQuotaRadicationsError();
            return;
          }
          this.quotaRadications = response.data;
          this.onShowPag = true;
          this.cantPages = response.totalPages;
          this.spinner.hide();
        }, (error) => {
          console.log(error.error.message);
          setTimeout(() => {
            this.toastr.clear();
            this.toastr.error(error.error.message);
            this.spinner.hide();
          }, 1000);
        }
      )};

  handleGetQuotaRadicationsError(error?: any) {
    console.error(error);
    if (
      error &&
      error.error &&
      error.error.message &&
      this.counterRequest > 1
    ) {
      const { message } = error.error;
      Swal.fire({
        type: "error",
        title: "Radicaciones",
        text: `${message}`,
        allowOutsideClick: false,
      });
    } else if (this.counterRequest > 1) {
      this.helper.handleUndefinedError();
    }
    this.quotaRadications = [];

    this.cantPages = 0;
    this.handleResetPagination();
    this.spinner.hide();
  }

  handleResetPagination() {
    if (this.paginationComponent) {
      this.paginationComponent.reset();
    }
  }

  initilizeDateRangePicker() {
    this.search.initialDate = moment(this.initDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .subtract(30, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
      .toString();

    this.search.finalDate = moment(this.finalDate)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
      .toString();
  }

  changeValueSelect(event: any, quotaRadication: QuotaRadication) {
    if (event.target.value == "DETALLE") {
      this.openSettlement(quotaRadication, true);
    }
    let { user } = JSON.parse(localStorage.getItem('user'));
    if (event.target.value == "ASIGNAR" && user) {
      this.spinner.show();
      quotaRadication.assignedBag = "1";
      quotaRadication.persHist = false;
      this.quotaRadicationService
        .postQuotaRadicationForm(quotaRadication)
        .subscribe((resp: Response<QuotaRadication>) => {
          if (resp.ok) {
            this.spinner.hide();
            Swal.fire({
              type: "success",
              title: "Asignación exitosa",
              text:
                "Se asignó correctamente la solicitud " +
                resp.data[0].idRadQuota,
            }).then(() => {});
          }
          this.spinner.hide();
        });
    }
    if (event.target.value == "GESTIONAR") {
      this.openSettlement(quotaRadication, false, true);
    }
  }

  openSettlement(
    quotaRadication: QuotaRadication,
    verification: boolean = false,
    management: boolean = false
  ) {
    this.spinner.show();
    if (management) {
      let user: {} = localStorage.getItem("identity")
        ? JSON.parse(localStorage.getItem("identity"))
        : {};
      let username = null;
      if (Object.keys(user).length > 0) username = this.radicationService.getDataUser();
      if (quotaRadication.requestState != 6) {
        //quotaRadication.requestState = 6;
        quotaRadication.managementDate = new Date();
        quotaRadication.user = username;
        quotaRadication.persHist = true;
        let { user } = JSON.parse(localStorage.getItem('user'));
        if (user) {
          this.quotaRadicationService
          .postQuotaRadicationForm(quotaRadication)
          .subscribe((resp: Response<QuotaRadication>) => {
            if (resp.ok) {
            }
          });
        }

      }
    }
    let affiliateName;
    let verificationCode;
    let legalNatural: LegalNatural = new LegalNatural();
    legalNatural.document = quotaRadication.docAffiliate;
    legalNatural.documentType = quotaRadication.docTypeAffiliate;
    this.quotaRadicationService.getAffiliateByNit(legalNatural).subscribe(
      (resp: Response<LegalNatural>) => {
        if (resp.status == 200) {
          affiliateName = resp.data[0].socialReason;
          quotaRadication.nameAffiliate = affiliateName;
          verificationCode = resp.data[0].verificationCode;
          let managementDate = quotaRadication.managementDate
            ? new Date(quotaRadication.managementDate)
            : new Date();
          let transTime;
          if (
            quotaRadication.requestType != 3 &&
            quotaRadication.requestType != 4
          ) {
            transTime = this.helper.calculateWorkingHours(
              new Date(quotaRadication.requestDate),
              managementDate
            );
          } else {
            transTime = this.helper.calculateWorkingHours(
              new Date(quotaRadication.requestDate),
              managementDate,
              true
            );
          }
          const payload = {
            quotaRadication: quotaRadication,
            listRequestType: this.requestTypes,
            affiliateName: affiliateName,
            verificationCode: "" + quotaRadication.docAffiliate,
            documents: [],
            verification: verification,
            transTime: transTime,
            responseTime: this.radicationService.timeToResponse(
              quotaRadication.requestType
            ),
          };

          this.spinner.hide();

          this.modal.show("editSettlement", payload);
          this.modalSettlement.ngOnInit();
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  updateAssignedAll(event: any) {
    this.spinner.show();
    this.quotaRadicationsToAssign = [];
    let value = (event.target.checked) ? '1' : '0';
    this.quotaRadications.forEach((element) => {
      element.assignedBag = value;
      if (element.assignedBag) this.quotaRadicationsToAssign.push(element);
    });
    this.spinner.hide();
  }

  updateAssigned(quotaRadication: QuotaRadication) {
    quotaRadication.assignedBag = quotaRadication.assignedBag ? "0" : "1";
    if (quotaRadication.assignedBag) {
      this.quotaRadicationsToAssign.push(quotaRadication);
    } else {
      let index = this.quotaRadicationsToAssign.indexOf(quotaRadication);
      this.quotaRadicationsToAssign.splice(index, 1);
    }
  }

  buttonUpdateAssigned() {
    this.spinner.show();
    let assgined = true;
    this.quotaRadicationsToAssign.forEach((element) => {
      let { user } = JSON.parse(localStorage.getItem('user'));
      if (user) {
        element.persHist = false;
      this.quotaRadicationService
        .postQuotaRadicationForm(element)
        .subscribe((resp: Response<QuotaRadication>) => {
          if (!resp.ok) {
            assgined = false;
            this.spinner.hide();
          }
        });
      }
    });
    if (assgined) {
      Swal.fire({
        type: "success",
        title: "Asiganciones exitosa",
        text: "Se asignaron correctamente las solicitudes ",
      }).then(() => {});
      this.spinner.hide();
    }
    this.spinner.hide();
  }

  updateQuotaPriority(event: any, quotaRadication: QuotaRadication) {
    this.spinner.show();
    quotaRadication.priority = (event.target.checked) ? "1" : "0";
    quotaRadication.persHist = false;
    let { user } = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.quotaRadicationService
      .postQuotaRadicationForm(quotaRadication)
      .subscribe((response: Response<QuotaRadication>) => {
        if (response.ok) {
          this.quotaRadications.sort((a, b) => {
            let valueToReturn = 0;
            if (a.priority) {
              valueToReturn = -1;
            }
            if (b.priority) {
              valueToReturn = 1;
            }
            if (a.priority && b.priority) {
              if (a.requestDate.getTime > b.requestDate.getTime) {
                valueToReturn = -1;
              } else {
                valueToReturn = 1;
              }
            }
            this.spinner.hide();
            return valueToReturn;
          });
        }
        this.spinner.hide();
      });
    }
  }

  convertDocumentsToModal(list: DocsFilingQuota[]): File[] {
    let documentsList: File[] = [];
    list.forEach((element) => {
      if (element.document) {
        let blobFromBase64: Blob = this.helper.base64ToBlob(element.document);
        let fileDocument = new File(
          [blobFromBase64],
          element.documentName ? element.documentName : "Documento"
        );
        documentsList.push(fileDocument);
      }
    });
    return documentsList;
  }

  reloadRequest() {
    this.getQuotaRadications(1);
  }
}
