import { Component, Input, OnInit } from '@angular/core';
import { CurrentDebt, CurrentDebtSector, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-current-debt-history',
  templateUrl: './current-debt-history.component.html',
  styleUrls: ['./current-debt-history.component.scss']
})
export class CurrentDebtHistoryComponent implements OnInit {
  
  public sectoresFromCurrentDebt: CurrentDebtSector[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.sectoresFromCurrentDebt = this.subjectCreditHistory.getSectoresFromCurrentDebt(response);
  }

}
