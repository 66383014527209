export class AffiliateLinkDataForm {
    issuerDocumentTypeId?: string ='';
    issuerDocumentId?:     string ='';
    userAct:               string ='';
    name1?:                string ='';
    name2?:                string ='';
    lastName1?:            string ='';
    lastName2?:            string ='';
    issuerDocumentTypeUs?: string ='';
    issuerDocumentUs?:     string ='';
    officeEmail?:          string ='';
    directPhone?:          string ='';
    daneCode?:             string ='';
    branch?:               string ='';
    idRol:                 number;
    urlAffiliate?:         string ='';
    applicative?:          string ='';
    message?:              string ='';
    roleAssignment?:       string='';
    city?:                 string='';
    municipalities?:               Array<BondPeriod>;
    dateBirthday?:         string ='';
}

export class DateLink{
    max?:                  Date = new Date;
    dateBirthday?:         string ='';
}

export class DateLinkGlobalBilling{
    max?:                  Date = new Date;
    initialDate?:          string ='';
    finalDate?:            string ='';
    invoiceDate?:          string ='';
}

export class BondPeriod {
    value?:      string;
    description?: string;
    state?:       string;
}

