import { GeneralInfo } from "./generalInfo.model";

export class HolderValidations {

    id: number;
    partnerTypeDoc: string;
    partnerDoc: number;
    partnerName: string;
    idRelationship: string;
    idRailingSs: number;
    idBeenInArrears: number;
    observations: string;
    affiliateState: string;
    promissoryNote: string;
    cobra: string;
    sagralaft: string;
    calification: string;
    centralState: string;
    quotaId: GeneralInfo;
}
