import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BackupsService } from '@app/services/backups.service';
import { HelperService } from '@app/services/helper.service';
import { ListsCovinocService } from '@app/services/lists-covinoc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataSearchMultipleFiles } from '../models/request/claims';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-filter-claims',
  templateUrl: './filter-claims.component.html',
  styleUrls: ['./filter-claims.component.scss']
})
export class FilterClaimsComponent implements OnInit {
  @Output() getDataFilterCv  =  new EventEmitter<any>();
  @Output() getDataFilterFiles  =  new EventEmitter<any>();
  @Output() filterSelect  =  new EventEmitter<any>();
  dataGetFiles:  DataSearchMultipleFiles = new  DataSearchMultipleFiles();
  filterData = '1';
  docTypes: any;
  filter: string; 
  cv: string;

  filtersOptions = [
    {
      id:'1',
      name:'Consulta por CV'
    },
    {
      id:'2',
      name:'Consulta por Documentos'
    },
    {
      id:'3',
      name:'Cargue Documentos'
    },
  ]

  constructor(  
    private listCovinoc: ListsCovinocService, 
    public helper: HelperService, 
    private roles: RolesService
    ) { }

  ngOnInit() {
    this.changeFilter(this.filterData)
    this.getDoccuments();
    this.findPermissions();
  }

  findPermissions() {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {
        response.data.forEach(item => {
          if(item.hasOwnProperty('actions') && item.description === 'backing') {
            //console.log("Item actions: ", item.actions);
            
            if(!item.actions.hasOwnProperty('loadBackingsDocuments')) {
              //console.log("No deberia cargar opcion ");
              
              this.filtersOptions = this.filtersOptions.filter((item) => item.name !== 'Cargue Documentos');       
            }
          } 
        })
      })
    }
  }

  getDoccuments() {
    this.listCovinoc.findAllLists().subscribe((resp: any) => {
      this.docTypes = resp.data[0].documentTypesNew;
    });
  }

  searchCv() {
    this.getDataFilterCv.emit(this.cv)
  }

  searchDocuments() {
    this.getDataFilterFiles.emit(this.dataGetFiles)
  }

  changeValue(event: any) {
    this.filter = event;
    //this.filter = this.quotaVsAffiliateSearchForm.get('idPayer').value;
  }

  changeFilter(filter: any) {
    this.dataGetFiles = new  DataSearchMultipleFiles();
    this.cv = undefined;
    this.filterSelect.emit(filter);
    //console.log(filter)
  }
}
