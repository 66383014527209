import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { AdaptedGetCreditHistoryResponse } from '../../models/natural-person-adapter.model';
import { ImpersonationClaim } from '../../services/natural-person-adapter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-impersonation-claims-history',
  templateUrl: './impersonation-claims-history.component.html',
  styleUrls: ['./impersonation-claims-history.component.scss']
})
export class ImpersonationClaimsHistoryComponent implements OnInit, OnDestroy {

  public headersTable: string[] = ['Leyenda', 'No.de Cuenta', 'Entidad'];
  public claims: ImpersonationClaim[] = [];
  private readonly $unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.pipe(takeUntil(this.$unsubscribe))
      .subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  handleResponseGetCreditHistorySubs(response: AdaptedGetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: AdaptedGetCreditHistoryResponse) {
    this.claims = response.getImpersonationClaims;
  }
}
