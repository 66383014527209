import { Component, OnInit, DoCheck, Input } from "@angular/core";
import { QuearyCodeAsrTemp } from "@app/models/quearyCodeAsrTemp.model";
import { Response } from "@app/models/responseUtil.model";
import { ContractService } from "@app/services/contract.service";
import { ModalService } from "@app/services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FormContractService } from "../services/form-contract.service";

@Component({
  selector: "app-add-queary-code",
  templateUrl: "./add-queary-code.component.html",
  styleUrls: ["./add-queary-code.component.scss"],
})
export class AddQuearyCodeComponent implements OnInit, DoCheck {
  public dataTableQuearyCode: QuearyCodeAsrTemp[] = [];
  validChange = false;

  validDisabledAddCode = true;

  numberContract = 0;
  isReal = false;

  @Input() stepperNext: any;

  listState = [
    { key: "A", description: "Activo" },
    { key: "S", description: "Suspendido" },
    { key: "R", description: "Retirado" },
  ];

  constructor(
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private formService: FormContractService
  ) {
    this.isReal = this.formService.status;
    this.numberContract = Number(localStorage.getItem("numberContract"));
  }

  ngOnInit() {
    this.formService.currentStep$.subscribe((current) => {
      if (current >= 2) {
        this.validDisabledAddCode = false;
      }
    });

    this.validQueryCode();

    if (this.modal.modal.addQueryCodeModal === false)
      delete this.modal.modal.addQueryCodeModal;
  }

  ngDoCheck(): void {
    if (this.modal.modal.addQueryCodeModal === false && !this.validChange) {
      this.validQueryCode();
      this.validChange = true;
    }
  }
  /* Valid the status of contract */
  validQueryCode() {
    if (this.numberContract && !this.isReal) {
      this.getQuearyCode();
    } else {
      this.getQuearyCodeReal();
    }
  }

  getQuearyCode() {
    if (this.numberContract) {
      this.spinner.show();
      this.contractService
        .getContractQuearyCodeByNumber(this.numberContract)
        .subscribe(
          (resp: Response<QuearyCodeAsrTemp[]>) => {
            if (resp.ok && resp.data) {
              this.dataTableQuearyCode = resp.data[0];
            }
            this.spinner.hide();
          },
          (error: any) => {
            console.log(error);
            this.dataTableQuearyCode = [];
            this.spinner.hide();
          }
        );
    }
  }

  getQuearyCodeReal() {
    if (this.numberContract) {
      this.spinner.show();
      this.contractService
        .getContractQuearyCodeRealByNumber(this.numberContract)
        .subscribe(
          (resp: Response<QuearyCodeAsrTemp[]>) => {
            if (resp.ok && resp.data) {
              this.dataTableQuearyCode = resp.data[0];
            }
            this.spinner.hide();
          },
          (error: any) => {
            console.log(error);
            this.dataTableQuearyCode = [];
            this.spinner.hide();
          }
        );
    }
  }

  showModalAddQuearyCode() {
    this.validChange = false;
    this.modal.show("addQueryCodeModal", {
      label: `Crear`,
    });
  }

  validNextStepper() {
    if (this.dataTableQuearyCode.length > 0) this.stepperNext.next();
  }

  public get isValidQuearyCode(): boolean {
    return this.dataTableQuearyCode.length <= 0;
  }

  showEditModalAddQuearyCode(idQuearyCode: number, sequence: number) {
    this.validChange = false;
    localStorage.setItem("idQuearyCode", String(idQuearyCode));
    this.modal.show("addQueryCodeModal", {
      label: `Editar`,
      sequence: sequence
    });
  }

  getState(states: string): string {
    let state = this.listState.find((element) => element.key === states);
    return state.description;
  }
}
