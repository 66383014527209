import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneTypeName'
})
export class PhoneTypeNamePipe implements PipeTransform {

  transform(value: any): any {
    let phoneName: string;
    
    let phoneList: any = JSON.parse(localStorage.getItem('form20m_telephoneList'));
    if(phoneList) {
      phoneList.forEach(item => {      
        if(value === item.idTelephone) {
          phoneName = item.description;
        }
      })
      return phoneName;
    }
  }
}
