import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { ModalComponent } from './modal/modal.component';
import { ModalSuccessContentComponent } from './modal-success-content/modal-success-content.component';
import { ModalListActiveComponent } from './modal-list-active/modal-list-active.component';
import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file.component';
import { ModalSettlementResponseComponent } from './modal-settlement-response/modal-settlement-response.component';
import { ModalShowPaymentsComponent } from './modal-show-payments/modal-show-payments.component';
import { ModalBagComponent } from './modal-bag/modal-bag.component';
import { ModalExpressReportComponent } from './modal-express-report/modal-express-report.component';
import { ModalUploadProductDocumentComponent } from './modal-upload-product-document/modal-upload-product-document.component';
import { UploadExcelDocumentComponent } from './upload-excel-document/upload-excel-document.component';
import { ModalUploadPromisoryNotesComponent } from './modal-upload-promisory-notes/modal-upload-promisory-notes.component';
import { ModalChargueErrorLogComponent } from './modal-charge-error-log/modal-charge-error-log.component';
import { CutBillingModalComponent } from './cut-billing-modal/cut-billing-modal.component';
import { ModalRulesCreateComponent } from './modal-rules-create/modal-rules-create.component';
import { ModalSpecialCasesComponent } from './modal-special-cases/modal-special-cases.component';
import { FacturaIndividualComponent } from './factura-individual/factura-individual.component';

@NgModule({
  declarations: [
    ModalComponent,
    ModalSuccessContentComponent,
    ModalListActiveComponent,
    ModalUploadFileComponent,
    ModalSettlementResponseComponent,
    ModalShowPaymentsComponent,
    ModalBagComponent,
    ModalExpressReportComponent,
    ModalUploadProductDocumentComponent,
    UploadExcelDocumentComponent,
    ModalUploadPromisoryNotesComponent,
    ModalChargueErrorLogComponent,
    CutBillingModalComponent,
    ModalRulesCreateComponent,
    ModalSpecialCasesComponent,
    FacturaIndividualComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ], exports: [
    ModalComponent,
    ModalSuccessContentComponent,
    ModalListActiveComponent,
    ModalUploadFileComponent,
    ModalSettlementResponseComponent,
    ModalBagComponent,
    ModalUploadProductDocumentComponent,
    ModalUploadPromisoryNotesComponent,
    ModalChargueErrorLogComponent,
    ModalExpressReportComponent,
    FacturaIndividualComponent
  ],
})
export class ModalModule { }
