import { Component, OnInit } from '@angular/core';
import { ImpersonationClaim, SubjectCreditHistoryService } from '../../services/subject-credit-history.service';
import { GetCreditHistoryResponse } from '../../models/credit-history.model';

@Component({
  selector: 'app-impersonation-claims-history',
  templateUrl: './impersonation-claims-history.component.html',
  styleUrls: ['./impersonation-claims-history.component.scss']
})
export class ImpersonationClaimsHistoryComponent implements OnInit {

  public headersTable: string[] = ['Leyenda', 'No.de Cuenta', 'Entidad'];
  public claims: ImpersonationClaim[] = [];

  constructor(
    private subjectCreditHistory: SubjectCreditHistoryService
  ) { }

  ngOnInit() {
    this.subjectCreditHistory.responseGetCreditHistory$.subscribe(this.handleResponseGetCreditHistorySubs.bind(this));
  }

  handleResponseGetCreditHistorySubs(response: GetCreditHistoryResponse | undefined) {
    if (response) this.handleInitData(response);
  }

  handleInitData(response: GetCreditHistoryResponse) {
    this.claims = this.subjectCreditHistory.getImpersonationClaims(response);
  }
}
