
export class AllocationLimit {

    ctid: string;
    documentType: string;
    document: number;
    especial: number;
    minium: number;
    middle: number
    basicMaxium: number;
    docsMaxium: number;
    user: string;
    dateUpdated: string;

    public static fromResponse(json: any): AllocationLimit {
        const allocationLimit: AllocationLimit = {

        ctid: json['ctid'],
        documentType: json['documentType'],
        document: json['document'],
        especial: json['especial'],
        minium: json['minium'],
        middle: json['middle'],
        basicMaxium: json['basicMaxium'],
        docsMaxium: json['docsMaxium'],
        user: json['user'],
        dateUpdated: json['dateUpdated'],
    };
    return allocationLimit;
    }
}
