import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { fromEvent, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from "rxjs/operators";
import { HelperService } from "../../../services/helper.service";

@Component({
  selector: "app-search-autocomplete",
  templateUrl: "./search-autocomplete.component.html",
  styleUrls: ["./search-autocomplete.component.scss"],
})
export class SearchAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild("formSearchAutocomplete") formSearchAutocomplete: NgForm;
  @ViewChild("inputSearchAutocomplete") inputSearchAutocomplete: ElementRef;
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() handleFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelectedFilter: EventEmitter<string> = new EventEmitter<string>();
  @Output() onOpenSelect: EventEmitter<void> = new EventEmitter<void>();
  @Input() minLength = 5;
  @Input() maxLength = 11;
  @Input() label: string = "";
  @Input() searchOnKeypress: boolean = false;
  @Input() required: boolean = true;
  @Input() filterList: Array<any> = [];
  @Input() authorization: boolean = false;
  @Input() sinisterNumber: boolean = false;

  public searchStringAutocomplete: string = "";
  public selectedOptionFilter: string = "";
  public filter: any = "";
  public numericInputs: Array<string> = ["Contrato", "Autorización", "Documento afiliado", "Documento comprador", "Número de radicación"];

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef, public helper: HelperService) {}

  private get isNumericInput(): boolean  {
    return this.numericInputs.includes(this.selectedOptionFilter);
  }

  ngOnInit() {
    const searchText$ = fromEvent(
      this.inputSearchAutocomplete.nativeElement,
      "keyup"
    );
    if (this.searchOnKeypress) {

      searchText$
        .pipe(
          debounceTime(2000),
          takeUntil(this.unsubscribe$),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.search(this.searchStringAutocomplete);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search(searchString: string) {
    if (
      searchString &&
      searchString.length > this.minLength &&
      this.searchStringAutocomplete !== "" &&
      this.formSearchAutocomplete.valid === true
    ) {
      const trimValue = this.helper.trimValue(searchString);
      this.onSelectedFilter.emit(this.selectedOptionFilter);
      this.onSearch.emit(trimValue);
    } else {
      this.onSearch.emit("");
    }
  }

  handleSelectFilter($event) {}

  reset() {
    this.searchStringAutocomplete = "";
  }

  focusElement() {
    this.inputSearchAutocomplete.nativeElement.focus();
  }

  focus() {
    this.handleFocus.emit(true);
  }

  focusOut() {
    this.handleFocus.emit(false);
    if (!this.searchStringAutocomplete) {
      this.markAspristine();
    }
  }

  markAspristine() {
    this.formSearchAutocomplete.form.markAsPristine();
    this.formSearchAutocomplete.form.markAsUntouched();
  }

  markAsDirty() {
    this.formSearchAutocomplete.form.markAsDirty();
    this.formSearchAutocomplete.form.markAsTouched();
    this.focusElement();
  }

  clearFocus() {
    this.inputSearchAutocomplete.nativeElement.blur();
  }

  getMinLength() {
    this.reset();
    this.minLength = (this.selectedOptionFilter === 'Título valor') ? 3 : 5;
  }

  getMinAndMaxLength() {
    this.reset();
    this.getMinLength();
    this.getMaxlength();
  }

  getMaxlength() {
    this.maxLength = (this.isNumericInput) ? 11 : 150;
  }

  getPattern(event): void {
    if (this.isNumericInput) {
      this.helper.onlyNumber(event);
    } else {
      console.log("onlyAlphaNumericAddress")
      this.helper.validateSocialReasonInput(event);

    }
  }

  getPastePattern(event) {
    if (this.isNumericInput) {
      this.searchStringAutocomplete = this.helper.pasteOnlyNumber(event);
    } else {
      this.searchStringAutocomplete = this.helper.validateSocialReasonInput(event);
    }
  }

  handleOpenSelect() {
    this.onOpenSelect.emit();
  }
}
