import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ButtonFloat } from "@app/models/buttonFloat.model";

@Component({
  selector: "app-floating-button",
  templateUrl: "./floating-button.component.html",
  styleUrls: ["./floating-button.component.scss"],
})
export class FloatingButtonComponent implements OnInit {
  @Input() listItem: Array<ButtonFloat> = [];

  @Output()
  eventData = new EventEmitter<string>();

  checked = false;

  constructor() {}

  ngOnInit() {}

  valueData(item:ButtonFloat) {
    this.checked = !this.checked;
    this.eventData.emit(item.value.toString());
  }
}
