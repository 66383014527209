import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RequestCredit } from "@app/models/RequestCredit.model";
import { SelectModel } from "@app/models/select.model";
import { HelperService } from "@app/services/helper.service";
import { ListsCovinocService } from "@app/services/lists-covinoc.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { RadicationService } from "../services/radication-quota.service";
import { hourValidator } from "../utils/hour-validator.validator";
import { conditionalRequiredValidator } from "../utils/radication.validators";

@Component({
  selector: "app-radication",
  templateUrl: "./radication.component.html",
  styleUrls: ["./radication.component.scss"],
})
export class RadicationComponent implements OnInit {
  formRadication = {
    typeRadication: "ESTUDIO",
    typeStudy: "INFORME COMERCIAL",
    numberRadication: null,
  };

  public searchType: Array<string> = [
    'Contrato',
    'Tipo de documento'
  ]

  isShowContractOrDocument = false;

  radicationForm: FormGroup;
  numberRadication = localStorage.getItem("radication");

  typeLabel = "Tipo de estudio";

  public isShowNegationOrApproveReason = false;
  public isShowNegationOrApproveReasonStatus = false;
  public isShowNegationOrApproveReasonEdit = false;
  public isShowNegationOrApproveReasonStatusEdit = false;


  employee: SelectModel[] = [];

  typeDocuments: SelectModel[] = [];
  queryCodes: any[] = [];
  radication: any | null = new RequestCredit();

  contractNotFound = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public helper: HelperService,
    public radicationService: RadicationService,
    public quotaRadicationService: QuotaRadicationService,
    private spinner: NgxSpinnerService,
    public listsCovinocService: ListsCovinocService,
    private toastr: ToastrService
  ) {
    this.employee = this.employee.concat([
      { value: "", description: `Seleccionar` },
    ]);

  }

  ngOnInit(): void {
    this.getDocuments();
    this.createForm();

    if (this.numberRadication) {
      this.getRadication();
    }
    this.changeQueryCode();
    this.getAllEmployee();
    this.showNegationOrApproveReason();
    this.changeReasonByInitialState();
    this.resetReasonField();
    this.f.searchType.valueChanges.subscribe((value) => {
      if(value === 'Contrato') {
        this.f.typeDocuments.setValue('C');
        this.f.documentTypeBuyer.setValue('C');
      } else {
        this.f.typeDocuments.setValue('P');
        this.f.documentTypeBuyer.setValue('P');
      }
    });
    this.f.searchType.setValue('Tipo de documento');
    this.f.searchType.valueChanges.subscribe((value) => {
      if(value === 'Contrato') {
        this.isShowContractOrDocument = true;
      } else {
        this.isShowContractOrDocument = false;
      }
    })
    this.f.documentTypeBuyer.valueChanges.subscribe((value) => {
      console.log(value)
      if(value === 'N') {
        this.f.socialReasonBuyer.setValidators([Validators.required]);
        this.f.name1Buyer.setValidators(null);
        this.f.lastname1Buyer.setValidators(null);
        this.f.name1Buyer.setValue(null);
        this.f.lastname1Buyer.setValue(null);
      } else {
        this.f.socialReasonBuyer.setValidators(null);
        this.f.name1Buyer.setValidators([Validators.required]);
        this.f.lastname1Buyer.setValidators([Validators.required]);
        this.f.socialReasonBuyer.setValue(null);
      }
    });
  }

  createForm() {
    this.radicationForm = this.fb.group({
      typeRadication: [
        { value: "ESTUDIO", disabled: true },
        Validators.required,
      ],
      typeLabel: [null, Validators.required],
      selectedDocuments: [["ID", "FORM"]],
      typeDocuments: [null, Validators.required],
      name1Buyer: [null],
      name2Buyer: [null],
      lastname1Buyer: [null],
      lastname2Buyer: [null],
      documentTypeBuyer: [null, Validators.required],
      documentBuyer: [null, [Validators.required, Validators.maxLength(11), , Validators.minLength(5)]],
      socialReasonBuyer: [null],
      document: [null, Validators.required],
      nameBuyer: [{ value: "", disabled: true }, Validators.required],
      establishment: [{ value: "", disabled: true }, Validators.required],
      queryCode: [null, Validators.required],
      value: [null, Validators.required],
      state: [{ value: "PENDIENTE", disabled: true }, Validators.required],
      responseMedium: [null, Validators.required],
      invoice: [null],
      /* Additional form of radication */
      deliverDateAc: [null,],
      deliverHourAc: [null, [hourValidator()]],
      deliverDateSac: [null, ],
      deliverHourSac: [null,  [hourValidator()]],
      sendAffiliateDate: [{ value: null, disabled: true }, [Validators.required,]],
      sendHourAffiliate: [{ value: null, disabled: true },[ Validators.required, hourValidator()]],
      valueSuccess: [null],
      negation: [null],
      negation2: [null],
      approveReason: [null],
      approveReason2: [null],
      rectifiable: [null],
      timeRta: [null],
      numberContract: [{ value: "", disabled: true }, Validators.required],
      revaidation: [null],
      increase: [null],
      /* Additional form success */
      renewalDate: [null],
      renewalHour: [null, [ hourValidator()]],
      statusTwo: [null],
      requestDate: [null,],
      requestHour: [null, [hourValidator()]],
      valueSuccessTwo: [null],
      searchType: [null],
      searchContract: [null],
      employee: ["", Validators.required],
      contractState: [{ value: null, disabled: true }],
    });
    console.log(this.radicationForm.value)
  }

  searchContract() {
    const bodyParams = {
      numberContract: this.f.searchContract.value,
    }
    this.quotaRadicationService.getStateContract(bodyParams).subscribe((resp: any) => {
      if(resp.data ) {
        console.log("Here")
        this.f.typeDocuments.setValue(resp.data.typeDocument);
        this.f.document.setValue(resp.data.document.toString());
        this.f.nameBuyer.setValue(resp.data.nameAfiliated);

        this.f.contractState.setValue(resp.data.state);
        this.f.state.enable()
        this.searchBuyer();
        /* this.f.establishment.setValue(resp.data[0].establishment);
        this.f.numberContract.setValue(resp.data[0].contract); */
      }
    })
  }

  //When negative form field is selected, the approveReason field is reset, and when the approveReason field is selected, the negation field is reset
  resetReasonField() {
    console.log(this.f.negation.value)
    this.f.negation.valueChanges.subscribe((value) => {
      if (value && this.radication.bodyRequest.valueSuccess) {
        this.f.approveReason.reset();
      }
    });
    this.f.approveReason.valueChanges.subscribe((value) => {
      if (value && this.radication.bodyRequest.valueSuccess) {
        console.log("negation")
        this.f.negation.reset();
      }
    });
    this.f.negation2.valueChanges.subscribe((value) => {
      if (value) {
        this.f.approveReason2.reset();
      }
    });
    this.f.approveReason2.valueChanges.subscribe((value) => {
      if (value) {
        this.f.negation2.reset();
      }
    });
  }

  showNegationOrApproveReason() {
    this.f.valueSuccess.valueChanges.subscribe((value) => {
      console.log(value)
      if(value === null || value === 0) {
        console.log("if")
        this.f.approveReason.setValue(null);
        console.log(this.radication.bodyRequest.denialReason)
        this.f.negation.setValue(this.radication.bodyRequest.denialReason);
        this.isShowNegationOrApproveReason = false;
      } else {
        console.log("else")
        console.log(this.radication.bodyRequest.denialReason)
        this.f.negation.setValue(null);
        this.f.approveReason.setValue(this.radication.bodyRequest.denialReason);
        this.isShowNegationOrApproveReason = true;
      }
    });

  }

  changeReasonByInitialState() {
    this.f.statusTwo.valueChanges.subscribe((value) => {
      if(value === "APROBADO") {
        console.log("APPROVE")
        this.f.approveReason2.setValue(this.radication.bodyRequest.denialReason2);
        this.f.negation2.setValue(null);
        this.isShowNegationOrApproveReasonStatusEdit = true;
      } else {
        this.f.negation2.setValue(this.radication.bodyRequest.denialReason2);
        this.f.approveReason2.setValue(null);
        this.isShowNegationOrApproveReasonStatusEdit = false;
      }
    });
  }

  getRadication() {
    this.spinner.show();
    this.quotaRadicationService
      .getFiligs(
        "ESTUDIO",
        this.numberRadication,

        this.radication.bodyRequest.promissoryNote,
        1
      )
      .subscribe(
        (resp) => {
          this.radication = resp.data[0];
          this.setForm();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
  }

  getDocuments() {
    this.listsCovinocService.findAllLists().subscribe(
      (resp) => {
        this.typeDocuments = resp.data[0]["documentTypesCode"];
      },
      (error) => {
        console.error(error);
      }
    );
  }

  changeQueryCode() {
    this.f.queryCode.valueChanges.subscribe((val) => {
      if (val) {
        let queryCode = this.queryCodes.find(
          (element) => element.queryCode == val
        );
        this.f.establishment.setValue(queryCode.establishment);
        this.f.numberContract.setValue(queryCode.contract);
      }
    });
  }

  onRemove(event: any) {
    let eventValue = event.value.value;
    let valueForm: string[] = this.f.selectedDocuments.value;
    if (
      this.radicationService.documents[5].value === eventValue ||
      eventValue == this.radicationService.documents[2].value
    ) {
      valueForm = [eventValue].concat(valueForm);
      this.f.selectedDocuments.setValue(valueForm);
    }
  }

  get f() {
    return this.radicationForm.controls;
  }

  getAllEmployee() {
    this.quotaRadicationService.getEmployee().subscribe(
      (resp) => {
        for (const element of resp) {
          if (element && element.email && element.email.includes("@")) {
            const userName = element.email.split("@");
            const selectModel: SelectModel = {
              value: userName[0],
              description: `${element.firstName || ""} ${
                element.secondFirstName || ""
              } ${element.firstLastName || ""} ${element.secondLastName || ""}`,
            };

            if (this.employee) {
              this.employee = this.employee.concat([selectModel]);
            } else {
              this.employee = [selectModel];
            }
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  searchBuyer() {
    console.log("HERE")
    this.spinner.show();
    this.quotaRadicationService
      .getQueryCodes(this.f.typeDocuments.value, this.f.document.value)
      .subscribe(
        (resp) => {

          this.f.nameBuyer.setValue(resp.data[0].nameAfiliated);
          const data = [];

          for (let item of resp.data) {
            for (let queryCode of item.queryCode) {
              const queryCodeData = {
                queryCode: queryCode.queryCode,
                name: `${item.contract.product} - ${queryCode.queryCode}`,
                establishment: queryCode.establishment,
                contract: queryCode.contract,
              };

              data.push(queryCodeData);
            }
          }
          this.queryCodes = data;
          this.contractNotFound = null;
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.contractNotFound = error.error.message;
          this.spinner.hide();
        }
      );
  }

  searchBuyerEdit() {
    this.spinner.show();
    this.quotaRadicationService
      .getQueryCodesEdit(this.radication.bodyRequest.affiliate)
      .subscribe(
        (resp) => {

          this.f.nameBuyer.setValue(resp.data[0].nameAfiliated);
          const data = [];
          if(resp.data.length > 0) {
            this.f.typeDocuments.setValue(resp.data[0].typeDocument);
            this.f.document.setValue(resp.data[0].document);
          }
          for (let item of resp.data) {
            for (let queryCode of item.queryCode) {
              const queryCodeData = {
                queryCode: queryCode.queryCode,
                name: `${item.contract.product} - ${queryCode.queryCode}`,
                establishment: queryCode.establishment,
                contract: queryCode.contract,
              };

              data.push(queryCodeData);
            }
          }
          this.queryCodes = data;
          this.contractNotFound = null;
          this.spinner.hide();
        },
        (error) => {
          console.error(error);
          this.contractNotFound = error.error.message;
          this.spinner.hide();
        }
      );
  }

  setForm() {
    /* console.log(this.radication.bodyRequest) */
    this.f.typeRadication.setValue(this.radication.bodyRequest.radicationType);
    this.f.typeLabel.setValue(this.radication.bodyRequest.promissoryNote);
    this.f.selectedDocuments.setValue(this.getDocumentsSave());
    this.f.typeDocuments.setValue(this.radication.bodyRequest.coDebtorDocumentType);
    this.f.document.setValue(this.radication.bodyRequest.coDebtorDocument);
    this.f.establishment.setValue(this.radication.bodyRequest.establishment);
    this.f.value.setValue(this.radication.bodyRequest.amount);
    this.f.state.setValue(this.radication.bodyRequest.status);
    this.f.state.enable();
    this.f.responseMedium.setValue(this.radication.bodyRequest.responseMedium);
    this.f.invoice.setValue(this.radication.bodyRequest.promissoryNoteLetter);
    this.f.queryCode.disable();

    if(this.radication.bodyNatural) {
      this.f.name1Buyer.setValue(this.radication.bodyNatural.name1);
      this.f.name2Buyer.setValue(this.radication.bodyNatural.name2);
      this.f.lastname1Buyer.setValue(this.radication.bodyNatural.lastname1);
      this.f.lastname2Buyer.setValue(this.radication.bodyNatural.lastname2);
      this.f.documentTypeBuyer.setValue(this.radication.bodyNatural.documentType);
      this.f.documentBuyer.setValue(this.radication.bodyNatural.document);
      this.f.socialReasonBuyer.setValue(this.radication.bodyNatural.socialReason);
    }

    this.f.deliverDateAc.setValue(this.radication.bodyRequest.deliveryDateToCustomer);
    this.f.deliverHourAc.setValue(this.radication.bodyRequest.deliveryTimeToCustomer);
    this.f.deliverDateSac.setValue(this.radication.bodyRequest.deliveryDateToAdviserSac);
    this.f.deliverHourSac.setValue(this.radication.bodyRequest.deliveryTimeToAdviserSac);
    this.f.sendAffiliateDate.setValue(this.radication.bodyRequest.customerSendingDate);
    this.f.sendHourAffiliate.setValue(this.radication.bodyRequest.customerSendingTime);
    this.f.sendAffiliateDate.enable();
    this.f.sendHourAffiliate.enable();
    this.f.valueSuccess.setValue(this.radication.bodyRequest.approvedAmount);
    this.f.rectifiable.setValue(this.radication.bodyRequest.rectifiable);
    this.f.timeRta.setValue(this.radication.bodyRequest.estimatedResponseTime);
    this.f.numberContract.setValue(this.radication.bodyRequest.contractNumber);
    this.f.increase.setValue(this.radication.bodyRequest.increase);
    this.f.revaidation.setValue(this.radication.bodyRequest.revalidation);

    this.f.renewalDate.setValue(this.radication.bodyRequest.receptionDate);
    this.f.renewalHour.setValue(this.radication.bodyRequest.receptionTime);
    this.f.renewalDate.enable();
    this.f.renewalHour.enable();
    this.f.statusTwo.setValue(this.radication.bodyRequest.status2);
    this.f.requestDate.setValue(this.radication.bodyRequest.responseDate);
    this.f.requestHour.setValue(this.radication.bodyRequest.responseTime);
    this.f.valueSuccessTwo.setValue(this.radication.bodyRequest.approvedAmount2);
    if(this.isShowNegationOrApproveReason) {
      console.log("if")
      this.f.approveReason.setValue(this.radication.bodyRequest.denialReason);
    } else {
      console.log("else")
      console.log(this.radication.bodyRequest.denialReason)
      this.f.negation.setValue(this.radication.bodyRequest.denialReason);
    }

    if(this.isShowNegationOrApproveReasonStatusEdit) {

      this.f.approveReason2.setValue(this.radication.bodyRequest.denialReason2);
      console.log("apporve2")
    } else {
      console.log(this.isShowNegationOrApproveReasonEdit)
      console.log("negate")
      this.f.negation2.setValue(this.radication.bodyRequest.denialReason2);
    }

    this.f.employee.setValue(this.radication.bodyRequest.analystName);
    if (this.numberRadication) {
      this.searchBuyerEdit();
    } else {
      this.searchBuyer();
    }
  }

  mapperService() {
    /*  */
    this.radication.bodyRequest.radicationType = this.f.typeRadication.value;
    this.radication.bodyRequest.promissoryNote = this.f.typeLabel.value;
    this.mapperDocuments();

    this.radication.bodyRequest.coDebtorDocumentType = this.f.documentTypeBuyer.value;
    this.radication.bodyRequest.coDebtorDocument = this.f.documentBuyer.value;

    /* this.radication.bodyRequest.affiliate = this.f.document.value; */
    this.radication.bodyRequest.affiliate = this.queryCodes[0].queryCode;
    this.queryCodes.forEach((element: any) => {
      if (element.contract === this.f.numberContract.value) {
        this.radication.bodyRequest.affiliate = element.queryCode;
      }
    })
    this.radication.bodyRequest.establishment = this.f.nameBuyer.value;

    this.radication.bodyRequest.amount = this.f.value.value;
    this.radication.bodyRequest.status = this.f.state.value;
    this.radication.bodyRequest.responseMedium = this.f.responseMedium.value;
    this.radication.bodyRequest.promissoryNoteLetter = this.f.invoice.value;

    if(this.radication.bodyNatural) {

    }

    this.radication.bodyNatural = (this.radication.bodyNatural === null) ? {} : this.radication.bodyNatural;

    this.radication.bodyNatural.name1 = this.isNotSocialReason(this.radicationForm) ? this.f.name1Buyer.value : '';
      this.radication.bodyNatural.name2 = this.isNotSocialReason(this.radicationForm) ? this.f.name2Buyer.value : '';

      this.radication.bodyNatural.lastname1 = this.isNotSocialReason(this.radicationForm) ? this.f.lastname1Buyer.value : '';
      this.radication.bodyNatural.lastname2 = this.isNotSocialReason(this.radicationForm) ?  this.f.lastname2Buyer.value : '';
      this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
      this.radication.bodyNatural.document = this.f.documentBuyer.value;
      this.radication.bodyNatural.documentType2 = this.f.documentTypeBuyer.value;
      this.radication.bodyNatural.document2 = this.f.documentBuyer.value;
      /* this.radication.bodyRequest.studentDocumentType = this.f.documentTypeBuyer.value;
      this.radication.bodyRequest.studentDocument = this.f.documentBuyer.value; */
      this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
      this.radication.bodyNatural.document = this.f.documentBuyer.value;
      this.radication.bodyNatural.socialReason = !this.isNotSocialReason(this.radicationForm) ? this.f.socialReasonBuyer.value : '';
      console.log(this.radication.bodyNatural)


    this.radication.bodyNatural.lastname1 = this.isNotSocialReason(this.radicationForm) ? this.f.lastname1Buyer.value : '';
    this.radication.bodyNatural.lastname2 = this.isNotSocialReason(this.radicationForm) ?  this.f.lastname2Buyer.value : '';
    this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document = this.f.documentBuyer.value;
    this.radication.bodyNatural.documentType2 = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document2 = this.f.documentBuyer.value;
    /* this.radication.bodyRequest.studentDocumentType = this.f.documentTypeBuyer.value;
    this.radication.bodyRequest.studentDocument = this.f.documentBuyer.value; */
    this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document = this.f.documentBuyer.value;
    this.radication.bodyNatural.socialReason = !this.isNotSocialReason(this.radicationForm) ? this.f.socialReasonBuyer.value : null;
    console.log(this.radication.bodyNatural)


    this.radication.bodyNatural.lastname1 = this.isNotSocialReason(this.radicationForm) ? this.f.lastname1Buyer.value : '';
    this.radication.bodyNatural.lastname2 = this.isNotSocialReason(this.radicationForm) ?  this.f.lastname2Buyer.value : '';
    this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document = this.f.documentBuyer.value;
    this.radication.bodyNatural.documentType2 = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document2 = this.f.documentBuyer.value;
    /* this.radication.bodyRequest.studentDocumentType = this.f.documentTypeBuyer.value;
    this.radication.bodyRequest.studentDocument = this.f.documentBuyer.value; */
    this.radication.bodyNatural.documentType = this.f.documentTypeBuyer.value;
    this.radication.bodyNatural.document = this.f.documentBuyer.value;
    this.radication.bodyNatural.socialReason = !this.isNotSocialReason(this.radicationForm) ? this.f.socialReasonBuyer.value : '';

    this.radication.bodyRequest.deliveryDateToCustomer = this.f.deliverDateAc.value;
    this.radication.bodyRequest.deliveryTimeToCustomer = this.f.deliverHourAc.value;
    this.radication.bodyRequest.deliveryDateToAdviserSac = this.f.deliverDateSac.value;
    this.radication.bodyRequest.deliveryTimeToAdviserSac = this.f.deliverHourSac.value;
    this.radication.bodyRequest.customerSendingDate = this.f.sendAffiliateDate.value;

    this.radication.bodyRequest.customerSendingTime = this.f.sendHourAffiliate.value;
    this.radication.bodyRequest.approvedAmount = this.f.valueSuccess.value;

    this.radication.bodyRequest.rectifiable = this.f.rectifiable.value;

    this.radication.bodyRequest.estimatedResponseTime = this.f.timeRta.value;
    this.radication.bodyRequest.contractNumber = this.f.numberContract.value;
    this.radication.bodyRequest.increase = this.f.increase.value;
    this.radication.bodyRequest.revalidation = this.f.revaidation.value;

    this.radication.bodyRequest.receptionDate = this.f.renewalDate.value;
    this.radication.bodyRequest.receptionTime = this.f.renewalHour.value;
    this.radication.bodyRequest.status2 = this.f.statusTwo.value;
    this.radication.bodyRequest.responseDate = this.f.requestDate.value;
    this.radication.bodyRequest.responseTime = this.f.requestHour.value;
    this.radication.bodyRequest.approvedAmount2 = this.f.valueSuccessTwo.value;
    this.radication.bodyRequest.denialReason = (this.f.negation.value !== null) ? this.f.negation.value : this.f.approveReason.value;
    this.radication.bodyRequest.denialReason2 = (this.f.negation2.value !== null) ? this.f.negation2.value : this.f.approveReason2.value;

    this.radication.bodyRequest.radicationUser = this.radicationService.getDataUser();
    this.radication.bodyRequest.analystName =
      this.f.employee.value === "USER"
        ? this.radicationService.getDataUser()
        : this.f.employee.value;

    return {
      bodyRequest: this.radication.bodyRequest,
      bodyNatural: this.radication.bodyNatural,
    };
  }


  isNotSocialReason(form: FormGroup): boolean {
    return form.get('documentTypeBuyer').value !== 'N';
  }

  mapperDocuments() {
    let value: string[] = this.f.selectedDocuments.value;
    this.radication.bodyRequest.formDocument = value.includes("FORM") ? "x" : null;
    this.radication.bodyRequest.identityDocument = value.includes("ID") ? "x" : null;
    this.radication.bodyRequest.rutDocument = value.includes("RUT") ? "x" : null;
    this.radication.bodyRequest.otherDocument = value.includes("OTHER") ? "x" : null;
    this.radication.bodyRequest.incomeTaxDeclaration = value.includes("RETURN_INCOME")
      ? "x"
      : null;
    this.radication.bodyRequest.bankStatements = value.includes("BANK_STATEMENT")
      ? "x"
      : null;
    this.radication.bodyRequest.financialStatement = value.includes("FINANCIAL_STATEMENT")
      ? "X"
      : null;
    this.radication.bodyRequest.commerceDocument = value.includes("CHAMBER_OF_COMMERCE")
      ? "X"
      : null;
  }

  getDocumentsSave(): string[] {
    const listDocument = [];
    if (this.radication.bodyRequest.formDocument) listDocument.push("FORM");
    if (this.radication.bodyRequest.identityDocument) listDocument.push("ID");
    if (this.radication.bodyRequest.rutDocument) listDocument.push("RUT");
    if (this.radication.bodyRequest.otherDocument) listDocument.push("OTHER");
    if (this.radication.bodyRequest.incomeTaxDeclaration)
      listDocument.push("RETURN_INCOME");
    if (this.radication.bodyRequest.bankStatements) listDocument.push("BANK_STATEMENT");
    if (this.radication.bodyRequest.financialStatement)
      listDocument.push("FINANCIAL_STATEMENT");
    if (this.radication.bodyRequest.commerceDocument)
      listDocument.push("CHAMBER_OF_COMMERCE");
    return listDocument;
  }

  getBuyerInformation() {
    this.quotaRadicationService.getRadicationBuyerInfo({
      documentType: this.f.documentTypeBuyer.value,
      document: this.f.documentBuyer.value,
    }).subscribe((resp: any) => {
      this.spinner.hide();
      if(resp.data.length > 0) {
        console.log(resp.data)
        this.f.documentBuyer.setValue(resp.data[0].document);
        this.f.documentTypeBuyer.setValue(resp.data[0].documentType);
        this.f.name1Buyer.setValue(resp.data[0].name1);
        this.f.name2Buyer.setValue(resp.data[0].name2);
        this.f.lastname1Buyer.setValue(resp.data[0].lastname1);
        this.f.lastname2Buyer.setValue(resp.data[0].lastname2);
        this.f.socialReasonBuyer.setValue(resp.data[0].socialReason);
      }
    }, (error) => {
      this.spinner.hide();
      console.error(error);
      this.toastr.error('No se encontraron datos del comprador');
    });
  }

  createRadication() {
    this.spinner.show();
    this.quotaRadicationService
      .createRadication(this.mapperService())
      .subscribe(
        (resp) => {
          let radication: any = resp.data;
          let radicationId = (radication.bodyRequest) ? radication.bodyRequest.radication : radication.radication;
          console.log(radication)
          this.spinner.hide();
          Swal.fire({
            type: "success",
            title: "Solicitud exitosa",
            text: `Se almacenó correctamente la radicación con el Id ${radicationId}`,
          }).then(() => {
            this.router.navigate(["pages/gestion-solicitud/radicaciones"]);
          });
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: "Error en Transacción",
            text: "Ocurrio un error en la transacción",
          }).then(() => {});
          this.spinner.hide();
          console.error(error);
        }
      );
  }
}
