import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { UploadFileComponent } from "@app/components/upload-file/upload-file.component";
import { SelectModel } from "@app/models/select.model";
import { DocsFilingQuota } from "@app/pages/quota-radication/models/docsFilingQuota.model";
import { QuotaRadication } from "@app/pages/quota-radication/models/quotaRadication.model";
import { RadicationService } from "@app/pages/quota-radication/services/radication-quota.service";
import { HelperService } from "@app/services/helper.service";
import { ModalService } from "@app/services/modal.service";
import { QuotaRadicationService } from "@app/services/radication-quota.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-management-request",
  templateUrl: "./management-request.component.html",
  styleUrls: [
    "./management-request.component.scss",
    "../check-request/check-request.component.scss",
  ],
})
export class ManagementRequestComponent implements OnInit, OnChanges {
  @Input() quotaSettlement: QuotaRadication = null;
  @Output() chargeList: EventEmitter<any> = new EventEmitter();

  @ViewChild("document") document: UploadFileComponent;

  public extensions: string =
    ".gif,.jpeg,.jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.txt,.msg";
  public bytes: number = 7340032;

  public requestStatesAll: SelectModel[] = [];
  public requestStates: SelectModel[];
  public requestNegation: string[];

  public files: any[] = [];
  public filesWErrors: any[] = [];
  public docsFilingQuota: Array<DocsFilingQuota> = [];
  public docToUpload: Array<DocsFilingQuota> = [];

  timeResponse: string = "";
  stateRequest: SelectModel;

  constructor(
    public helper: HelperService,
    public radicationService: RadicationService,
    private quotaRadicationService: QuotaRadicationService,
    public modal: ModalService,
  ) {
    this.requestStatesAll = radicationService.requestStatesAll;
    this.requestStates = this.radicationService.requestStates;
    this.requestNegation = this.radicationService.negationStates;
  }

  ngOnInit() {
    this.getTimeExecute();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.modal.payload.quotaRadication);
    console.log(this.quotaSettlement);
    console.log(this.quotaSettlement.requestState);
    if(this.quotaSettlement.requestState === 2) {
      this.requestStates = this.requestStates.filter(item => item.value !== '2');
      console.log(this.requestStates)
    }
  }

  getTimeExecute() {
    this.timeResponse = this.radicationService.timeToResponse(
      this.quotaSettlement.requestType
    );
  }

  getStateToUpdate(event: any) {
    this.quotaSettlement.assignedQuota = null;
    this.quotaSettlement.reasonDenial = null;
    let data = event.target.value;
    this.stateRequest = this.requestStates.find(
      (element) => data == element.description
    );
    this.quotaSettlement.requestState = Number(this.stateRequest.value);
  }

  getNegationToUpdate(event) {
    let data = event.target.value;
    let motiveNegation: string = this.requestNegation.find(
      (element) => data == element
    );
    this.quotaSettlement.reasonDenial = motiveNegation;
  }

  getFilesFromComponent(event) {
    this.files = event;
    this.files.forEach((file) => {
      let docFilingQuota: DocsFilingQuota = new DocsFilingQuota();
      Promise.resolve(
        this.helper.convertFileToBase64(file).then(function (value) {
          docFilingQuota.document = value;
          docFilingQuota.documentName = file.name;
        })
      );
      
      this.docsFilingQuota.push(docFilingQuota);
    });
  }

  getAddDocument() {
    /* this.quotaSettlement.docs = this.quotaSettlement.docs.concat(
      this.docsFilingQuota
    ); */
    if (this.docsFilingQuota.every(element => element.document !== null)) {
      this.docsFilingQuota.forEach(element => {
        element.document = this.helper.base64ToString(element.document);
      });
    }
  }

  downloadFile(document: DocsFilingQuota) {
    let type: string[] = document.documentName.split(".");
    let typeDocument = `.${type[1]}`;
    this.helper.downloadFromBase64(
      document.document,
      document.documentName,
      typeDocument
    );
  }

  getFilesWErrorFromComponent(event) {
    this.filesWErrors = event;
  }

  saveForm() {
    let { user } = JSON.parse(localStorage.getItem('user'));
    if(user) {
      if (this.document.files.length > 0) {
        this.getAddDocument();
      }
      this.quotaRadicationService
        .postQuotaRadicationForm({...this.quotaSettlement, docs: this.docsFilingQuota})
        .subscribe(
          (resp) => {

            Swal.fire({
              type: "success",
              title: "Transacción exitosa",
              text:
                "Se actualizó correctamente la solicitud con el Id " +
                resp.data[0].idRadQuota,
            }).then(() => {
              this.chargeList.emit();
            });
          },
          () => {
            Swal.fire({
              type: "error",
              title: "Error en Transacción",
              text: "Ocurrio un error en la transacción",
            }).then(() => {});
          }
        );
    }
  }
}
