import { AffiliateAsrTempPK } from "./compositeKey/AffiliateAsrTempPK.model";
import { RepLegalAsrTemp } from "./repLegalAsrTemp.model";

export class AffiliateAsrTemp {
  id: number;
  affiliateAsrTempPK: AffiliateAsrTempPK;
  socialReason: string;
  societyType: string;
  coverage: string;
  economicActivity: string;
  subSector: string;
  firstName: string;
  secondName: string;
  firstLastName: string;
  secondLastName: string;
  affiliationDate: Date;
  rePreLegalAsrTemps: RepLegalAsrTemp[];
  economicActivityName: string;
  subSectorName: string;
}
